import React, { useState, useEffect } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const CreatePackageForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result,
    selectedProducts,
    handleAddRemoveProduct,
    handleChangeQty,
    products,
    isEdit
  } = props;

  const options = (products || [])
    .filter(({ id }) => {
      return !(selectedProducts || [])
        .find(({ productId }) => productId === id);
    })
    .map(({ id, model }) => {
      return {
        label: model,
        value: { id, model }
      };
    });

  const invalidProducts = !(selectedProducts || []).length;

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">

        <Field
          required
          name="name"
          type="text"
          component={FormField.Input}
          label="Package Name"
          validate={[required]}
          insetLabel
        />

        <h5>
          Included Products
        </h5>
        {invalidProducts &&
          <span
            style={{
              fontSize: 'x-small',
              color: 'red',
              verticalAlign: 'middle'
            }}
          >
            {' '} At lease one product required
          </span>}
        <Field
          name="searchProducts"
          type="text"
          component={FormField.SelectReact}
          label="Search Products"
          placeholder=""
          options={options}
          onChange={({ id, model }) => handleAddRemoveProduct(id, true, model)}
          insetLabel
        />
        <div className="pill-group-items">
          {(selectedProducts || []).map(({ model, qty, productId }, index) => {
            return (
              <div key={index} className="pill">
                <div className="name">{model ? model : 'Unknown Product'}</div>
                <div className="controls">
                  <Icon
                    name="minus"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      const newQty = qty - 1;
                      const newQtyGreaterThanZero = newQty > 0;
                      if (newQtyGreaterThanZero) {
                        handleChangeQty(productId, qty - 1);
                      } else {
                        handleAddRemoveProduct(productId, false);
                      }
                    }}
                  />
                  <input
                    key={index}
                    type="text"
                    size="2"
                    style={{
                      textAlign: 'center',
                      borderRadius: '5px',
                      borderStyle: 'solid',
                      borderWidth: '2px',
                      margin: '0px 5px 0px 5px'
                    }}
                    onChange={e => {
                      const qty = e.target.value;
                      handleChangeQty(productId, parseInt(qty));
                    }}
                    onBlur={e => {
                      const qty = e.target.value;
                      if (isNaN(parseInt(qty))) {
                        handleChangeQty(productId, 1);
                        return;
                      }
                      const newQtyGreaterThanZero = qty > 0;
                      if (!newQtyGreaterThanZero) {
                        handleAddRemoveProduct(productId, false);
                      }
                    }}
                    value={`${parseInt(qty) < 10 ? `0${qty}` : qty}`}
                  />
                  <Icon
                    name="plus"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      handleChangeQty(productId, qty + 1);
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <Field
          name="description"
          rows={6}
          component={FormField.Textarea}
          label="Package Description"
          insetLabel
        />

        <h5>Feature Package</h5>

        <Field
          size="sm"
          name="featured"
          component={FormField.InputToggle}
          label="Highlight this package on the E-commerce website"
        />
        <Field
          size="sm"
          name="hidePrice"
          component={FormField.InputToggle}
          label="Hide package price on store"
        />

      </div>

      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={submitting || !(selectedProducts || []).length}
        >
          {isEdit ? 'Update Package' : 'Create Package'}
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'create-package-form'
})(CreatePackageForm);
