import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';

import { actionCreators } from '../../../../state/modals-dux';
import ReplaceProductCatalogForm from './ReplaceProductCatalogForm';
import ImportProductsResults from './ImportProductsResults';
import Notification from '../../../ui/Notification';

class ModalCustomersCompanyReplaceProducts extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmit,
      postProductsJson,
      clearPostProductsJson,
      postProductsJsonInProgress,
      postProductsJsonError,
      postProductsJsonResult,
      companyId,
      postSaveProducts,
      postSaveProductsInProgress,
      getCompanyById,
      postSaveProductsError,
      clearPostSaveProducts,
      getProductImportStatus,
      getProductImportStatusResult,
      getProductImportStatusError,
      clearGetProductImportStatus,
      cancelProductImport
    } = this.props;

    const {
      statusID,
      insertedLineItems,
      insertedVendors
    } = getProductImportStatusResult || {};

    const {
      noChangeProducts,
      deletedproducts,
      addedProducts,
      updatedProducts,
      existedProducts
    } = postProductsJsonResult || {};
    const handleCancelImport = () => {
      cancelProductImport(companyId, null, null, () =>
        getProductImportStatus(companyId));
    };

    const getJobStatus = id => {
      getProductImportStatus(id, null, true, ({
        statusID
      }) => {
        if (statusID === 1) {
          setTimeout(() => getJobStatus(id), 3000);
        }
      });
    };

    const handleProductsUpload = payload => {
      postProductsJson(
        { payload, id: companyId },
        'replace-product-catalog-form',
        null,
        () => {
          getJobStatus(companyId);
        }
      );
    };

    const handleProductsSave = values => {
      const { vendors } = values || {};

      let vendorsInfo = (vendors || [])
        .map((value, idx) => ({
          ID: idx,
          c_SelectedPurchaseOrderSageVendorID: value
        }))
        .filter(v => !!v);
      const payload = { vendorsInfo, addedProducts, deletedproducts, updatedProducts }
      postSaveProducts(
        { id: companyId, payload },
        'replace-product-catalog-form',
        null,
        () => {
          //get Company by ID with products
          getCompanyById(
            { id: companyId, query: { getProducts: 1 } },
            null,
            true
          );
          //clear the upload results
          clearPostProductsJson();
          clearGetProductImportStatus();
        }
      );
    };

    const handleHideModal = () => {
      clearPostProductsJson();
      // clearGetProductImportStatus();
      closeModal();
    };

    const {
      message: messageSave
    } = postSaveProductsError || {};

    const {
      message: messageParse
    } = postProductsJsonError || {};

    const {
      message: messageStatus
    } = getProductImportStatusError || {};

    return (
      <Modal
        show={openModal === name}
        onHide={handleHideModal}
        className="modal-alt"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Product Catalog</Modal.Title>
        </Modal.Header>
        {statusID !== 2 &&
          <ReplaceProductCatalogForm
            onSubmit={handleProductsUpload}
            closeModal={handleHideModal}
            postProductsJsonError={postProductsJsonError}
            postProductsJsonResult={postProductsJsonResult}
            getProductImportStatusResult={getProductImportStatusResult}
            postProductsJsonInProgress={postProductsJsonInProgress}
            handleCancelImport={handleCancelImport}
          />}
        {statusID === 2 &&
          <ImportProductsResults
            productsResult={insertedLineItems}
            vendorsResult={insertedVendors}
            noChangeProducts={noChangeProducts}
            deletingResult={deletedproducts}
            addingResult={addedProducts}
            updatingResult={updatedProducts}
            existedProducts={existedProducts}
            handleSubmit={handleProductsSave}
            closeModal={handleHideModal}
            submitting={postSaveProductsInProgress}
            handleCancelImport={handleCancelImport}
          />}
        {postSaveProductsError &&
          <Notification
            key="product-save-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostSaveProducts}
          >
            {messageSave
              ? messageSave
              : 'Failed to save products.Please try again.'}
          </Notification>}
        {postProductsJsonError &&
          <Notification
            key="product-save-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostProductsJson}
          >
            {messageParse
              ? messageParse
              : 'Failed to process products.Please try again.'}
          </Notification>}
        {getProductImportStatusError &&
          <Notification
            key="product-save-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetProductImportStatus}
          >
            {messageStatus ? messageStatus : 'Error retrieving import status.'}
          </Notification>}
        {statusID === 3 &&
          <Notification
            key="product-parse-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={() => {
              clearGetProductImportStatus();
              clearPostProductsJson();
            }}
          >
            {messageStatus
              ? messageStatus
              : 'An error occurred while importing products. Please try again'}
          </Notification>}
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    result: postProductsJsonResult,
    inProgress: postProductsJsonInProgress,
    error: postProductsJsonError
  } = state.postProductsJson.toJS();

  const {
    result: postSaveProductsResult,
    inProgress: postSaveProductsInProgress,
    error: postSaveProductsError
  } = state.postSaveProducts.toJS();

  const {
    result: getProductImportStatusResult,
    inProgress: getProductImportStatusInProgress,
    error: getProductImportStatusError
  } = state.getProductImportStatus.toJS();

  return {
    postProductsJsonResult,
    postProductsJsonInProgress,
    postProductsJsonError,
    postSaveProductsInProgress,
    postSaveProductsError,
    getProductImportStatusResult,
    getProductImportStatusError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postProductsJson: fetchDux.postProductsJson.createAction,
      clearPostProductsJson: fetchDux.postProductsJson.clearAction,
      postSaveProducts: fetchDux.postSaveProducts.createAction,
      clearPostSaveProducts: fetchDux.postSaveProducts.clearAction,
      getCompanyById: fetchDux.getCompanyById.createAction,
      getProductImportStatus: fetchDux.getProductImportStatus.createAction,
      clearGetProductImportStatus: fetchDux.getProductImportStatus.clearAction,
      cancelProductImport: fetchDux.cancelProductImport.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(
  ModalCustomersCompanyReplaceProducts
);
