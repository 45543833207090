import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Table from '../../../ui/Table';
import moment from 'moment-timezone';

const ModalShipmentLogs = (
  {
    openModal,
    closeModal,
    name,
    result
  }
) => {
  const dateColumns = [
    'UpdateTime',
    'ActualShipDate',
    'ActualShipDatePersonDate',
    'CarrierDeliveryDate',
    'CarrierSchdDeliveryDate',
    'CarrierUpdateDate',
    'SchdDeliveryDate',
    'SchdShipDate',
    'SchdShipDatePersonDate'
  ];
  const columns = (result || []).length > 0
    ? [
      ...Object.keys(result[0]).filter(s => s === 'UpdateUser').map(name => {
        if (dateColumns.includes(name)) {
          return {
            Header: name, accessor: name, Cell: ({ value }) => {
              if (!value || !moment(value).isValid()) return '';
              return moment(value).tz('America/Chicago').format('MM/DD/YYYY h:mm A zz');
            }
          }
        } else {
          return { Header: name, accessor: name }
        }

      }),
      ...Object.keys(result[0]).filter(s => s != 'UpdateUser').map(name => {
        if (dateColumns.includes(name)) {
          return {
            Header: name, accessor: name, Cell: ({ value }) => {
              if (!value || !moment(value).isValid()) return '';
              return moment(value).tz('America/Chicago').format('MM/DD/YYYY h:mm A zz');
            }
          }
        } else {
          return { Header: name, accessor: name }
        }
      })
    ]
    : [];
  return (
    <Modal show={openModal === name} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Shipment Logs</Modal.Title>
      </Modal.Header>
      <div className="shipment-logs-table">
        <Table data={result} columns={columns} defaultPageSize={10} />
      </div>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalShipmentLogs);
