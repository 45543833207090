import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import SendConfirmationForm from './SendConfirmationForm';
import Notification from '../../../ui/Notification';
import { formValueSelector } from 'redux-form';

class ModalSendConfirmation extends Component {
  constructor(props) {
    super(props);
    this.state = { showPreview: false };
  }
  render() {
    const {
      openModal,
      closeModal,
      postError,
      WarehouseId,
      sendConfirmationEmail,
      sendConfirmationEmailError,
      inProgress,
      formSubject,
      formBody,
      formFooter,
      user
    } = this.props;
    const { showPreview } = this.state;

    const initialValues = {
      subject: 'Rates & Contact Details – Requesting Confirmation ',
      body: 'Concept Services is a kitchen equipment supplier and installation company for chain restaurants. ' +
        'We have used your facility in the past to receive, consolidate, and deliver our equipment packages to our projects. ' +
        'I am reaching out to you to confirm your current rates and contact details so we may more easily partner with ' +
        'your facility again for future projects in your area.  ',
      footer: 'Please use the link below to review/update the information we have on ' +
        'file for addresses, contact information and rates.'
    };

    // Fix show preview bug on close
    const cancelModal = () => {
      this.setState({ showPreview: false });
      closeModal();
    };
    const handleSubmit = payload => {
      if (showPreview) {
        sendConfirmationEmail(
          { id: WarehouseId, payload: { ...payload } },
          'send-confirmation-form',
          null,
          () => {
            cancelModal();
          }
        );
      } else {
        this.setState({ showPreview: true });
      }
    };

    const closePreview = () => {
      this.setState({ showPreview: false });
    };

    return (
      <Modal
        show={openModal === 'SendConfirmationModal'}
        onHide={cancelModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Confirmation Email</Modal.Title>
        </Modal.Header>
        <SendConfirmationForm
          onSubmit={handleSubmit}
          submitting={inProgress}
          closeModal={cancelModal}
          initialValues={initialValues}
          showPreview={showPreview}
          closePreview={closePreview}
          formValues={{ formSubject, formBody, formFooter }}
          user={user}
        />
        {sendConfirmationEmailError &&
          <Notification
            key="send-confirmation-error"
            duration={5}
            closable={true}
            type="danger"
          >
            Error sending confirmation, please try again.
          </Notification>}
      </Modal>
    );
  }
}

const selector = formValueSelector('send-confirmation-form');

const mapState = state => {
  const formSubject = selector(state, 'subject');
  const formBody = selector(state, 'body');
  const formFooter = selector(state, 'footer');
  const {
    inProgress,
    error: sendConfirmationEmailError
  } = state.sendConfirmationEmail.toJS();
  const {
    result: user
  } = state.validateLogin.toJS();
  return {
    sendConfirmationEmailError,
    inProgress,
    openModal: state.modals,
    formSubject,
    formBody,
    formFooter,
    user
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      sendConfirmationEmail: fetchDux.sendConfirmationEmail.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalSendConfirmation);
