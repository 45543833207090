import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';

const ModalConfirmDeleteWarehouse = (
  {
    openModal,
    name,
    closeModal,
    onDeleteWarehouse,
    inProgress,
    error,
    warehouse
  }
) => {
  const show = openModal === 'DeleteWarehouseModal';
  const { Name: warehouseName } = warehouse || {};
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          &nbsp;
          Delete Warehouse
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete warehouse
          {' '}
          <b>{warehouseName}</b>
          ?
        </p>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'normal' }}>
        <Col style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          This action cannot be undone!
        </Col>
        <Col sm="auto">
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={closeModal}
            disabled={inProgress}
          >
            Cancel
          </Button>
          <Button
            type="button"
            size="sm"
            variant="danger"
            onClick={onDeleteWarehouse}
            disabled={inProgress}
            style={{ marginLeft: '10px' }}
          >
            Yes, Delete Warehouse
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => {
  const {
    inProgress,
    error
  } = state.delWarehouse.toJS();

  return {
    inProgress,
    error,
    openModal: state.modals
  };
};

const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalConfirmDeleteWarehouse);
