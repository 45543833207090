import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const CreateCustomersLocationForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result,
    isEdit,
    getCompanyResults,
    companyUsers,
    selectedCompany,
    handleCompanyChange,
    getCompanyByIdInProgress,
    handleUserChange,
    usersDisplay,
    userIds,
    handleRemoveUser,
    change,
    isFromConcepts
  } = props;

  const companyOptions = (getCompanyResults || [])
    .map(({ name, id }) => ({
      value: id,
      label: name
    }))
    .sort((a, b) => {
      var nameA = a.label.toUpperCase(); // ignore upper and lowercase
      var nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    });

  const userOptions = (companyUsers || [])
    .map(({ nameFirst, nameLast, id }) => ({
      value: id,
      label: `${nameFirst} ${nameLast}`
    }))
    .sort((a, b) => {
      var nameA = a.label.toUpperCase(); // ignore upper and lowercase
      var nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    })
    .filter(({ value }) => !(userIds || []).includes(value));

  const disableSelectUsers = !selectedCompany || !companyUsers;
  const noUsers = selectedCompany && (companyUsers || []).length < 1;

  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        <Field
          required
          name="name"
          type="text"
          component={FormField.Input}
          label="Location Name"
          validate={[required]}
          insetLabel
        />

        <Field
          required
          name="address1"
          type="text"
          component={FormField.Input}
          label="Address 1"
          validate={[required]}
          insetLabel
        />

        <Field
          name="address2"
          type="text"
          component={FormField.Input}
          label="Address 2"
          insetLabel
        />

        <Field
          required
          name="city"
          type="text"
          component={FormField.Input}
          label="City"
          validate={[required]}
          insetLabel
        />

        <Row>
          <Col sm={6}>

            <Field
              required
              name="stateProvince"
              type="text"
              component={FormField.Input}
              label="State"
              validate={[required]}
              insetLabel
            />

          </Col>
          <Col sm={6}>

            <Field
              required
              name="postalCode"
              type="text"
              component={FormField.Input}
              label="ZIP"
              validate={[required]}
              insetLabel
            />

          </Col>
        </Row>

        <Field
          required
          name="companyID"
          type="text"
          component={FormField.SelectReact}
          placeholder=""
          label="Assign Concept"
          validate={[required]}
          options={companyOptions}
          isClearable
          isSearchable
          isDisabled={isFromConcepts}
          insetLabel
          onChange={e => {
            if (handleCompanyChange) {
              handleCompanyChange(e);
              change('userIDs', '');
            }
          }}
        />
        {!isEdit &&
          <Field
            name="userIDs"
            type="text"
            component={FormField.SelectReact}
            label={noUsers ? 'No Users for Selected Company' : 'Assign Users'}
            isDisabled={disableSelectUsers || noUsers}
            insetLabel
            placeholder=""
            options={userOptions}
            onChange={e => {
              handleUserChange(e);
              change('userIDs', ''); //TODO figure out why it does not reset form value
            }}
            isLoading={getCompanyByIdInProgress}
            isSearchable
          />}
        {!isEdit &&
          <div className="pill-group">
            {(usersDisplay || []).map(({ nameFirst, nameLast, id }, index) => (
              <span key={index} className="pill">
                {nameFirst} {nameLast}
                <Icon
                  name="times"
                  onClick={() => handleRemoveUser(id)}
                  style={{ cursor: 'pointer' }}
                />
              </span>
            ))}
          </div>}

      </div>

      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          disabled={submitting || !getCompanyResults}
        >
          {!isEdit && <span>Create Location</span>}
          {isEdit && <span>Save</span>}
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'create-customers-location-form'
})(CreateCustomersLocationForm);
