import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import WarehouseSearch from './warehouses-search/WarehouseSearch';
import WarehouseNew from './warehouses-new/WarehouseNew';
import WarehouseEdit from './warehouses-edit/WarehouseEdit';
import WarehouseDetail from './warehouses-detail/WarehouseDetail';

export default ({ match, location, history }) => {
  const { pathname } = location || {};

  return (
    <Switch>
      <Route exact path={`${match.url}`} component={WarehouseSearch} />
      <Route exact path={`${match.url}/new`} component={WarehouseNew} />
      <Route exact path={`${match.url}/:id`} component={WarehouseDetail} />
      <Route exact path={`${match.url}/:id/edit`} component={WarehouseEdit} />
      <Redirect to={`${match.url}`} />
    </Switch>
  );
};
