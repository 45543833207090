import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import fetchDux from "../../../state/fetch-dux";
import Box from "../../ui/Box";
import Layout from "../../ui/Layout";
import ShipmentsResult from "../shipments/shipments-search/ShipmentsResult";
import queryString from 'query-string';
import Notification from "../../ui/Notification";


const formatQuery = (
    {
        page,
        pageSize,
        search,
        ...rest
    }
) => ({
    page: page || '0',
    pageSize: pageSize || '20',
    search: search || '',
    ...rest
});

const formatValue = (value, label) => {
    const intValues = ['deliveryStatus'];
    if (intValues.includes(label)) return parseInt(value);
    return value;
};

class MissingSchdShipDateShipments extends Component {

    parseQuery(search) {
        const params = queryString.parse(search);
        const multiSelect = ['sortBy'];
        return Object.keys(params).reduce(
            (t, p) => {
                const value = formatValue(params[p], p);
                return !multiSelect.includes(p)
                    ? Object.assign({}, t, { [p]: value })
                    : Object.assign({}, t, {
                        [p]: Array.isArray(value) ? value : [value]
                    });
            },
            {}
        );
    }

    componentWillMount() {
        const { location: { search }, location: { pathname }, history } = this.props;
        const query = formatQuery(queryString.parse(search));
        const qs = queryString.stringify(query);
        history.push({ pathname, search: qs });
        this.callSearch(qs);
    }

    onResultColumnChange(activeColumns) {
        this.props.setActiveColumns(activeColumns)
    }

    componentWillReceiveProps(nextProps) {
        const { location: { search } } = nextProps;
        if (this.props.location.search !== search) {
            this.callSearch(search);
        }
    }

    callSearch = (search) => {
        const { getHotlistMissingSchdShipDateShipments } = this.props;
        getHotlistMissingSchdShipDateShipments(this.parseQuery(search));
    }

    handleFetchData = (state) => {
        const {
            location: { search },
            history,
            location: { pathname }
        } = this.props;
        const {
            page,
            pageSize,
            sorted
        } = state || {};
        if (!search) return;
        const [sort] = sorted || [];
        const {
            id: sortBy,
            desc
        } = sort || {};

        const params = {
            ...queryString.parse(search),
            page,
            pageSize,
            sortBy,
            desc
        };
        const query = formatQuery(params);
        const qs = queryString.stringify(query);
        history.push({ pathname, search: qs });
    }

    handleSearchChange = (value) => {
      const {
        location: { search },
        history,
        location: { pathname }
      } = this.props;
      const query = queryString.parse(search) || {};
      query.search = value;
      const queryFormatted = formatQuery(query);
      const qs = queryString.stringify(queryFormatted);
      history.push({ pathname, search: qs });
    }

    render() {
        const { result, activeColumns, inProgress, error, location: { search } } = this.props;
        const paging = formatQuery(queryString.parse(search));
        return (
            <Layout
                route="hotlist"
                alt>
                <div className="layout-app-header">
                    <Container>
                        <div>
                            <h2>
                                Missing Scheduled Ship Date
                            </h2>
                        </div>
                    </Container>
                </div>
                <Container>
                  <span style={{fontSize:'small'}}>
                    Use the search box below to filter down on PO Numbers
                  </span>
                  <Box className="box-table">
                      <ShipmentsResult
                          serverSearch
                          result={result || {}}
                          paging={paging}
                          activeColumns={(activeColumns || [])}
                          onColumnChange={this.onResultColumnChange.bind(this)}
                          inProgress={inProgress}
                          error={error}
                          onFetchData={this.handleFetchData}
                          handleSearchChange={this.handleSearchChange}
                      />
                  </Box>
                </Container>

                {error &&
                    <Notification
                        key="schdship-shipments"
                        duration={5}
                        closable={true}
                        type="danger"
                    >
                        Failed to get shipments.
                    </Notification>
                }
            </Layout>
        )
    }
}


const mapState = state => {
    const {
        result,
        inProgress,
        error
    } = state.getHotlistMissingSchdShipDateShipments.toJS();

    const activeColumns = state.setActiveColumns.toJS();

    return {
        result,
        inProgress,
        error,
        activeColumns: activeColumns.result
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        getHotlistMissingSchdShipDateShipments: fetchDux.getHotlistMissingSchdShipDateShipments.createAction,
        setActiveColumns: fetchDux.setActiveColumns.createAction
    }, dispatch)


export default connect(mapState, mapDispatch)(MissingSchdShipDateShipments);