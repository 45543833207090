import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const styleRed = {
  backgroundColor: '#ffebe6',
  color: '#172B4D',
  padding: '5px 15px',
  borderRadius: '4px 0 0 4px'
};
const styleGreen = {
  backgroundColor: '#e3fcef',
  color: '#172B4D',
  padding: '5px 15px',
  borderRadius: '0 4px 4px 0'
};

const DiffRender = (
  {
    actions,
    field,
    render,
    renderDiff,
    diffStyle,
    value: v1,
    placement
  }
) => {
  const v = !!v1 || v1 === false || v1 === 0 ? v1 : '';
  const action = (actions || []).find(a => a.field === field);
  if (!action) return render ? render(v) : v;
  const { type, value, originalValue } = action;
  const r = renderDiff ? renderDiff : v => v;
  const overlay = (
    <Popover id="popover-diff" className="popover-diff">
      <div style={{ ...styleRed, ...(diffStyle || {}) }}>
        {r(originalValue)}
      </div>
      <div style={{ ...styleGreen, ...(diffStyle || {}) }}>
        {r(value)}
      </div>
    </Popover>
  );

  if (type === 'modify')
    return (
      <span>
        <OverlayTrigger
          trigger="click"
          placement={placement || 'auto'}
          overlay={overlay}
        >
          <span
            style={{
              backgroundColor: '#ffffc7',
              cursor: 'pointer'
            }}
          >
            {render ? render(value) : value}
          </span>
        </OverlayTrigger>
      </span>
    );

  return render ? render(value) : value;
};

export default DiffRender;
