import React from 'react';
import classNames from 'classnames';

const Loading = (
  {
    message,
    fullPage,
    style,
    messageStyle,
    containerStyle,
    innercontainerStyle,
    color
  }
) => (
  <div
    className={classNames('loading', { 'full-page': fullPage })}
    style={containerStyle || {}}
  >
    <div style={innercontainerStyle || {}}>
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        style={style || {}}
      >
        <path
          fill={color ? color : '#5EA2C9'}
          stroke={color ? color : '#5EA2C9'}
          strokeWidth="0.6"
          strokeMiterlimit="1"
          opacity="0.5"
          d="M5.203,20c0-8.159,6.638-14.797,14.797-14.797V5C11.729,5,5,11.729,5,20s6.729,15,15,15v-0.203C11.841,34.797,5.203,28.159,5.203,20z"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            calcMode="spline"
            keySplines="0.4, 0, 0.2, 0.8"
            keyTimes="0;1"
            dur="2s"
            repeatCount="indefinite"
          />
        </path>
        <path
          fill={color ? color : '#5EA2C9'}
          stroke={color ? color : '#5EA2C9'}
          opacity="0.2"
          strokeWidth="0.35"
          strokeMiterlimit="1"
          d="M7.078,20c0-7.125,5.797-12.922,12.922-12.922V6.875C12.763,6.875,6.875,12.763,6.875,20S12.763,33.125,20,33.125v-0.203C12.875,32.922,7.078,27.125,7.078,20z"
        >
          <animateTransform
            attributeName="transform"
            type="rotate"
            from="0 20 20"
            to="360 20 20"
            dur="1.8s"
            repeatCount="indefinite"
          />
        </path>
      </svg>
      {message && <div style={messageStyle || {}}>{message}</div>}
    </div>
  </div>
);

export default Loading;
