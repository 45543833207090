import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';

const ModalDeleteCustomersLocation = (
  {
    openModal,
    name,
    closeModal,
    inProgress,
    deleteLocationByIdError,
    location,
    deleteLocationById,
    history,
    clearDeleteLocationById
  }
) => {
  const {
    id
  } = location || {};

  const handleDeleteCompany = () => {
    deleteLocationById(id, null, null, () => {
      history.push(`/customers/locations/`);
    });
  };

  return (
    <Modal
      show={openModal === name}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          Delete Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: 'center', marginTop: '10px' }}>
          Are you sure you want to delete location?
        </p>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Row>
          <Col style={{ color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>
          <Col sm="auto">
            <Button
              type="button"
              size="sm"
              variant="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="sm"
              variant="danger"
              onClick={handleDeleteCompany}
              disabled={inProgress}
              style={{ marginLeft: '10px' }}
            >
              Yes, Delete Location
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
      {deleteLocationByIdError &&
        <Notification
          key="location-delete-fail"
          duration={3}
          closable={true}
          type="danger"
          onClose={clearDeleteLocationById}
        >
          Failed to delete location. Please try again.
        </Notification>}
    </Modal>
  );
};

const mapState = state => {
  const {
    result: deleteLocationByIdResult,
    inProgress: deleteLocationByIdInProgress,
    error: deleteLocationByIdError
  } = state.deleteLocationById.toJS();

  return {
    deleteLocationByIdResult,
    deleteLocationByIdInProgress,
    deleteLocationByIdError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      deleteLocationById: fetchDux.deleteLocationById.createAction,
      clearDeleteLocationById: fetchDux.deleteLocationById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalDeleteCustomersLocation);
