import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import fetchDux from '../../../state/fetch-dux';
import Layout from '../../ui/Layout';
import Box from '../../ui/Box';
import OrdersSearchResults from './OrdersSearchResults';
import statusDict from '../projects/statusDict';

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const status = [
  statusDict.INSERT_COMPLETE,
  statusDict.SEND_INPROGRESS,
  statusDict.SEND_COMPLETE,
  statusDict.TAX_INPROGRESS,
  statusDict.COMPLETE
].join(',');

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  sortBy: sortBy || 'c_ImportedDate',
  desc: getDesc(desc),
  search: search || '',
  status
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class OrdersSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchProjects = this.handleSearchProjects.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = { mountComplete: false };
  }
  componentDidMount() {
    if (this.state.mountComplete) return;
    this.setState({ mountComplete: true }, () => {
      const { location: { search } } = this.props;
      const query = getQueryFromSearch(search);
      const qs = queryString.stringify(query);
      this.props.searchProjects(qs);
    });
  }

  sendSearchProjectRequest = (nextSearch, sendImmediately) => {
    if (!sendImmediately) {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = window.setTimeout(
        () => {
          this.sendSearchProjectRequest(nextSearch, true);
        },
        1000
      );
      return;
    }
    const query = getQueryFromSearch(nextSearch);
    const qs = queryString.stringify(query);
    this.props.searchProjects(qs);
  };

  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    const { location: { search: search2 } } = this.props;
    if (search === search2) return;
    if (!this.state.mountComplete) return;

    const currentSearch = getQueryFromSearch(search2);
    const nextSearch = getQueryFromSearch(search);
    if (nextSearch.search === currentSearch.search) {
      this.sendSearchProjectRequest(search, true);
      return;
    }
    this.sendSearchProjectRequest(search, false);
  }

  handleSearchProjects(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    if (!this.state.mountComplete) return;
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchProjects({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    if (!this.state.mountComplete) return;
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchProjects(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      error,
      result,
      location: { search }
    } = this.props;

    const paging = getQueryFromSearch(search);

    return (
      <Layout route="projects-search">
        <h2>
          Orders Search
        </h2>
        <Box className="box-table">
          <OrdersSearchResults
            paging={paging}
            result={result}
            error={error}
            onFetchData={this.handleTableChange}
            handleSearchChange={this.handleSearchChange}
            inProgress={inProgress}
          />
        </Box>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: searchProjectsInProgress,
    error: searchProjectsError
  } = state.searchProjects.toJS();

  return {
    result,
    inProgress: searchProjectsInProgress,
    error: searchProjectsError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchProjects: fetchDux.searchProjects.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(OrdersSearch);
