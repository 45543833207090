import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Row, Col, Form } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';

const SalesRepForm = props => {
  const {
    handleSubmit,
    submitting
  } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={6}>
          <Field
            name="FirstName"
            component={FormField.Input}
            label="First Name"
            disabled={submitting}
            validate={[required]}
          />
        </Col>
        <Col sm={6}>
          <Field
            name="LastName"
            component={FormField.Input}
            label="Last Name"
            disabled={submitting}
            validate={[required]}
          />
        </Col>
        <Col sm={12}>
          <Field
            name="Email"
            component={FormField.Input}
            label="Email"
            disabled={submitting}
            validate={[required]}
          />
        </Col>
        <Col sm={6}>
          <Field
            name="Code"
            component={FormField.Input}
            label="Code"
            disabled={submitting}
          />
        </Col>
        <Col sm={6}>
          <Field
            name="ParentCode"
            component={FormField.Input}
            label="Parent Code"
            disabled={submitting}
          />
        </Col>
        <Col sm={12}>
          <Field
            name="RootFileShareDirectory"
            component={FormField.Input}
            label="File Share Directory"
            disabled={submitting}
          />
        </Col>
      </Row>
    </Form>
  );
};

const formName = 'sales-rep-form';

const form = reduxForm({
  form: formName
})(SalesRepForm);

export default form;
