import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import CreateQuoteForm from './CreateQuoteForm';
import fetchDux from '../../../../state/fetch-dux';

class ModalCreateQuote extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      postQuote,
      history,
      inProgress,
      error
    } = this.props;

    const handleUpload = value => {
      postQuote(value, null, null, result => {
        closeModal();
        history.push(`/quotes/${result.Id}/edit`);
      });
    };

    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-alt"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Quote by JSON</Modal.Title>
        </Modal.Header>
        <CreateQuoteForm
          uploadError={error}
          inProgress={inProgress}
          closeModal={closeModal}
          onSubmit={handleUpload}
        />
      </Modal>
    );
  }
}

const mapState = state => ({
  openModal: state.modals,
  ...state.postQuote.toJS()
});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postQuote: fetchDux.postQuote.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalCreateQuote);
