import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';
import numeral from 'numeral';
import moment from 'moment';

import QuoteStatus from '../../../ui/QuoteStatus';
import { formatMoney } from '../quotes-edit/ProposalSummaryForm';

const formatTimeDiff = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).fromNow(true);
};

const columns = [
  {
    Header: 'Quote',
    accessor: 'QuoteNumber',
    Cell: ({ value, original }) => (
      <Link to={`/quotes/${original.ID}`}>{value}</Link>
    )
  },
  {
    Header: 'Project',
    accessor: 'ProjectName'
  },
  {
    Header: 'Customer',
    accessor: 'customer'
  },
  {
    Header: 'Sale',
    accessor: 'GrandSellTotal',
    className: 'text-right',
    Cell: ({ value }) => <span>{formatMoney(value)}</span>
  },
  {
    Header: 'Items',
    accessor: 'numItems',
    className: 'text-right'
  },
  {
    Header: 'Vendors',
    accessor: 'numVendors',
    className: 'text-right'
  },
  {
    Header: 'Owner',
    accessor: 'salesRep'
  },
  {
    Header: 'Created',
    accessor: 'CreateDate',
    className: 'text-center',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  },
  {
    Header: 'Status',
    accessor: 'Status',
    width: 160,
    Cell: ({ value, original }) => <QuoteStatus status={value} quote={original}/>,
    className: 'text-center'
  }
];

const QuotesSearchResults = (
  {
    error,
    result,
    onFetchData,
    paging,
    handleSearchChange,
    inProgress
  }
) => {
  const {
    numPages,
    results: projects
  } = result || {};

  const {
    sortBy,
    desc,
    page,
    pageSize,
    search
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];
  return (
    <Table
      manual
      showColumnsSelector
      showSearch
      serverSearch
      search={search}
      onSearchChange={handleSearchChange}
      data={projects}
      columns={columns}
      onFetchData={onFetchData}
      noDataText={inProgress ? 'Loading...' : 'No quotes found'}
      defaultSorted={sorted}
      page={error ? 0 : Number(page)}
      pages={error ? 0 : numPages}
      defaultPageSize={Number(pageSize)}
    />
  );
};

export default QuotesSearchResults;
