import React, { Component, useState } from 'react';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import numeral from 'numeral';
import moment from 'moment';
import Icon from '../../../../ui/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state/modals-dux';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../../forms/FormField';
import POLocked from '../POLocked';

import ModalEditShippingInfo from './ModalEditShippingInfo';

const formatMoney = value => numeral(value).format('0,0.00');

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY');
};

const getAddress = (
  {
    shippingAddress,
    projectAddress,
    confirmed,
    override
  }
) => {
  if (override) return override;
  if (confirmed) return projectAddress;
  return shippingAddress;
};

const getWarehouse = (
  {
    c_WarehouseID,
    warehouses
  }
) => {
  const warehouse = (warehouses || []).find(({ ID }) => ID === c_WarehouseID);
  if (!warehouse) return {};
  return {
    State: warehouse.StateProvince,
    Address1: warehouse.Address1,
    Address2: warehouse.Address2,
    City: warehouse.City,
    Name: warehouse.Name,
    Zip: warehouse.PostalCode,
    PhoneContact: warehouse.Phone
  };
};

const getShipper = (
  {
    carriers,
    c_ShipViaId
  }
) => {
  const shipper = (carriers || []).find(({ Code }) => Code === c_ShipViaId);
  if (!shipper) return {};
  return {
    CarrierName: shipper.Name
  };
};

const getAddressValues = (
  {
    shippingAddress,
    projectAddress,
    confirmed,
    lookups,
    override
  }
) => {
  const address = getAddress({
    shippingAddress,
    projectAddress,
    confirmed,
    override
  }) || {};

  const { c_ShipViaId } = address;

  const {
    c_WarehouseID
  } = address;

  const {
    carriers,
    warehouses
  } = lookups || {};

  const shipper = getShipper({
    c_ShipViaId,
    carriers
  });
  const warehouse = getWarehouse({
    c_WarehouseID,
    warehouses
  });

  return { ...address, ...shipper, ...warehouse };
};

const getShippingAddress = (
  {
    value,
    projectAddressValues,
    address
  }
) => {
  if (value.override) return value.override;
  if (value.confirmed) return projectAddressValues;
  return address;
};

const getShippingAddressEdit = (
  {
    addresses,
    activePOEdit,
    purchaseOrderAddressValues,
    projectAddressValues
  }
) => {
  const { c_PurchaseOrderAddressId, ID: POID } = activePOEdit || {};
  const value = (purchaseOrderAddressValues || [])
    .find(({ ID }) => ID === POID) || {};
  const address = (addresses || [])
    .find(({ ID }) => ID === c_PurchaseOrderAddressId);
  const shippingAddress = getShippingAddress({
    value,
    projectAddressValues,
    address
  });
  return shippingAddress;
};

const ShippingRow = (
  {
    purchaseOrder,
    i,
    lookups,
    addresses,
    purchaseOrderAddressValues,
    projectAddressValues,
    handleUndo,
    handleEdit,
    handleLocked
  }
) => {
  const {
    c_PurchaseOrderAddressId,
    ID: POID
  } = purchaseOrder || {};

  const [status, setLocked] = useState({ loaded: false });

  const handleSetLocked = props => {
    if (props.locked) handleLocked(i);
    setLocked(props);
  };

  const {
    confirmed,
    override
  } = (purchaseOrderAddressValues || [])
    .find(({ ID }) => ID === purchaseOrder.ID) || {};

  const shippingAddress = (addresses || [])
    .find(({ ID }) => ID === c_PurchaseOrderAddressId) || {};
  const {
    c_ShipDate,
    c_SpecialInstructions,
    Address1,
    Address2,
    City,
    State,
    CarrierName,
    c_AttnNameOnly,
    c_Attn,
    Name,
    Zip,
    c_Zip_4,
    PhoneContact
  } = getAddressValues({
    shippingAddress,
    projectAddress: projectAddressValues,
    confirmed,
    lookups,
    override
  });

  const showUndo = confirmed || override;

  return (
    <tr className={showUndo ? 'has-changed' : ''}>
      <td>
        {status.loaded &&
          !status.locked &&
          <Field
            name={`purchaseOrders.[${i}].checked`}
            component={FormField.InlineCheckbox}
            checkBoxProps={{ style: { border: '1px solid #17a2b8' } }}
          />}
        <POLocked
          purchaseOrderId={POID}
          setLocked={handleSetLocked}
          loadingStyle={{
            width: '30px',
            height: '30px'
          }}
          hideUnlocked
        />
      </td>
      <td>{purchaseOrder.c_PurchaseOrderNumber}</td>
      <td>
        {purchaseOrder.VendorNum &&
          <OverlayTrigger
            key="vendorName"
            placement="top"
            overlay={
              <Tooltip id="tooltip-vendorName">
                {purchaseOrder.VendorName}
              </Tooltip>
            }
          >
            <span>
              {purchaseOrder.VendorNum}
              {' '}
              <Icon name="question-circle" className="text-info" />
            </span>
          </OverlayTrigger>}
      </td>
      <td>{getDate(c_ShipDate)}</td>
      <td>
        {c_SpecialInstructions &&
          <OverlayTrigger
            key="specialInstructions"
            placement="top"
            overlay={
              <Tooltip id="tooltip-specialInstructions">
                {c_SpecialInstructions}
              </Tooltip>
            }
          >
            <span>
              {CarrierName}
              {' '}
              <Icon name="hand-point-right" className="text-danger" />
            </span>
          </OverlayTrigger>}
        {!c_SpecialInstructions && <span>{CarrierName}</span>}
      </td>
      <td>
        {c_Attn && <span>{c_Attn}<br /></span>}
        {Name}
        {PhoneContact && <span><br />{PhoneContact}</span>}
      </td>
      <td>
        {Address1}
        {Address2 && <span><br />{Address2}</span>}
        <br />
        {City}, {State} {Zip}{c_Zip_4 && `-${c_Zip_4}`}
      </td>
      <td className="table-actions">
        {showUndo &&
          <Button
            variant="link btn-undo"
            size="sm"
            onClick={() => handleUndo(i)}
          >
            <Icon name="undo" />
          </Button>}
        <Button variant="link" size="sm" onClick={() => handleEdit(i)}>
          <Icon name="edit" />
        </Button>
      </td>
    </tr>
  );
};

class ManagePOShippingInfoTable extends Component {
  constructor(props) {
    super(props);
    this.state = { editing: null };
  }
  render() {
    const {
      purchaseOrders,
      addresses,
      showModal,
      lookups,
      projectAddressValues,
      purchaseOrderAddressValues,
      change,
      handleSubmit: handleSubmitPOShipping
    } = this.props;
    const { editing } = this.state;
    const handleEdit = idx => {
      this.setState({ editing: idx });
      showModal('EditShippingInfo');
    };

    const activePOEdit = (purchaseOrders || [])[editing] || {};

    const shippingAddressEdit = getShippingAddressEdit({
      addresses,
      activePOEdit,
      purchaseOrderAddressValues,
      projectAddressValues
    });

    const handleApplyDefaultAddress = () => {
      (purchaseOrderAddressValues || []).map(({ checked }, idx) => {
        if (!checked) return;
        change(`purchaseOrders[${idx}].confirmed`, true);
      });
    };

    const handlePOEditSubmit = values => {
      change(`purchaseOrders[${editing}].override`, values);
      this.setState({ editing: null });
    };

    const handleUndo = idx => {
      change(`purchaseOrders[${idx}].confirmed`, false);
      change(`purchaseOrders[${editing}].override`, null);
    };

    const handleChangeAll = (e, value) => {
      (purchaseOrderAddressValues || []).map((v, idx) => {
        if (v.locked) return;
        change(`purchaseOrders[${idx}].checked`, value);
      });
    };

    const handleLocked = idx => {
      change(`purchaseOrders[${idx}].locked`, true);
    };

    const numChecked = (purchaseOrderAddressValues || []).reduce(
      (prev, next) => {
        if (next.checked) return prev + 1;
        return prev;
      },
      0
    );

    return (
      <div className="manage-po-shipping-info-table">

        <div className="default-shipping">
          <div className="default-shipping-actions">
            <div style={{ display: 'flex', width: '100%' }}>
              {/* <div>
                Check Purchase Orders to Copy Default Shipping Details
              </div> */}
              <div>
                <Button
                  variant="info"
                  onClick={handleApplyDefaultAddress}
                  disabled={numChecked === 0}
                >
                  Copy Default Shipping{numChecked > 0 && ` (${numChecked})`}
                </Button>
              </div>
              <div
                style={{ marginLeft: 'auto', fontWeight: 'bold' }}
                className="text-muted"
              >
                Modify PO Shipping Details in two ways:
              </div>
              <div
                className="text-muted"
                style={{ margin: '0 15px 0 30px', fontWeight: 'bold' }}
              >
                1. Select POs and click
                "Copy Default Shipping"
                button
                <br />
                2. Click the Edit icon
                <Button variant="link" size="sm" style={{ padding: '0 3px' }}>
                  <Icon name="edit" />
                </Button>
                to INDIVIDUALLY edit Shipping Details
                {/* <br />
                POs that have been modified are highlighted <span className="action modify">Yellow</span> */}
              </div>
            </div>
          </div>
        </div>

        <table className="table table-shipping-information">
          <thead>
            <tr>
              <th>
                <Field
                  name={`allPurchaseOrders`}
                  component={FormField.InlineCheckbox}
                  onChange={handleChangeAll}
                  checkBoxProps={{ style: { border: '1px solid #17a2b8' } }}
                />
              </th>
              <th>P.O. Number</th>
              <th>Vendor No.</th>
              <th>Ship Date</th>
              <th>Ship Via</th>
              <th>Ship To Name</th>
              <th>Ship To Address</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {(purchaseOrders || [])
              .map((purchaseOrder, i) => (
                <ShippingRow
                  key={i}
                  purchaseOrder={purchaseOrder}
                  i={i}
                  lookups={lookups}
                  addresses={addresses}
                  purchaseOrderAddressValues={purchaseOrderAddressValues}
                  projectAddressValues={projectAddressValues}
                  handleUndo={handleUndo}
                  handleEdit={handleEdit}
                  handleLocked={handleLocked}
                />
              ))}
          </tbody>
        </table>

        <div style={{ display: 'flex', marginTop: '15px' }}>
          <div
            className="text-muted"
            style={{ fontWeight: 'bold', margin: '5px 0 0 auto' }}
          >
            POs that have been modified are highlighted
            {' '}
            <span className="action modify">Yellow</span>
          </div>
          <Button
            variant="success"
            style={{ marginLeft: '30px' }}
            onClick={handleSubmitPOShipping}
          >
            Save & Continue
          </Button>
        </div>
        <ModalEditShippingInfo
          name="EditShippingInfo"
          purchaseOrder={activePOEdit}
          shippingAddress={shippingAddressEdit}
          lookups={lookups}
          handleSubmit={handlePOEditSubmit}
        />

      </div>
    );
  }
}

const form = reduxForm({
  form: 'shipping-po-checked'
})(ManagePOShippingInfoTable);

const mapState = state => {
  return {
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(form);
