import React from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import { getStatusLabel } from '../statusDict';
import Table from '../../../ui/Table';

const formatMoney = value => numeral(value).format('0,0.00');

const columns = [
  {
    Header: 'JobRef',
    accessor: 'JobRef',
    width: 150,
    Cell: ({ value, original }) => (
      <Link to={`/projects/${original.ID}`}>{value}</Link>
    )
  },
  {
    Header: 'Project',
    accessor: 'ProjectName' // String-based value accessors!
  },
  {
    Header: 'Customer',
    accessor: 'customer'
  },
  {
    Header: 'Sale',
    accessor: 'GrandSellTotal',
    className: 'text-right',
    Cell: ({ value }) => <span>${formatMoney(value)}</span>
  },
  {
    Header: 'Items', // TODO should this include items * qty?
    accessor: 'numItems',
    className: 'text-right'
  },
  {
    Header: 'Vendors',
    accessor: 'numVendors',
    className: 'text-right'
  },
  {
    Header: 'Owner',
    accessor: 'salesRep'
  },
  {
    Header: 'Last Updated',
    accessor: 'c_ImportedDate',
    className: 'text-center',
    Cell: ({ value }) => <span>{moment(value).format('MM/DD/YYYY')}</span>
  },
  {
    Header: 'Status',
    accessor: 'c_SageIntegrationStatus',
    Cell: ({ value }) => <span>{getStatusLabel(value)}</span>
  }
];

const ProjectSearchResults = (
  {
    error,
    result,
    onFetchData,
    paging,
    handleSearchChange,
    inProgress
  }
) => {
  const {
    numPages,
    results: projects
  } = result || {};

  const {
    sortBy,
    desc,
    page,
    pageSize,
    search
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];
  return (
    <Table
      manual
      keepTable
      showColumnsSelector
      showSearch
      serverSearch
      search={search}
      onSearchChange={handleSearchChange}
      data={projects}
      columns={columns}
      onFetchData={onFetchData}
      noDataText="No projects found"
      defaultSorted={sorted}
      page={error ? 0 : Number(page)}
      pages={error ? 0 : numPages}
      defaultPageSize={Number(pageSize)}
      inProgress={inProgress}
    />
  );
};

export default ProjectSearchResults;
