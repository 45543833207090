const shipmentsDict = {
  UNSHIPPED: "-1",
  NOTFOUND: 0,
  NOT_SHIPPED: 1,
  IN_TRANSIT: 2,
  CARRIER_DELIVERED: 3,
  WAREHOUSE_DELIVERED: 4,
  OUT_FOR_DELIVERY: 5,
  FAILED_ATTEMPT: 6,
  DELIVERED: 7,
  AVAILABLE_FOR_PICKUP: 8,
  EXCEPTION: 9,
  EXPIRED: 10,
  INFO_RECEIVED: 12
};

export const getStatusLabel = status => {
  switch (status) {
    // we don't want to display this status on the grid
    case shipmentsDict.UNSHIPPED:
      return '';
    case shipmentsDict.NOTFOUND:
      return 'Not Found';
    case shipmentsDict.IN_TRANSIT:
      return 'In Transit';
    // case shipmentsDict.CARRIER_DELIVERED:
    //   return 'Carrier Says Delivered';
    case shipmentsDict.WAREHOUSE_DELIVERED:
      return 'Received By Warehouse';
    case shipmentsDict.OUT_FOR_DELIVERY:
      return 'Out for Delivery'
    case shipmentsDict.FAILED_ATTEMPT:
      return 'Failed Attempt'
    case shipmentsDict.DELIVERED:
      return 'Delivered'
    case shipmentsDict.AVAILABLE_FOR_PICKUP:
      return 'Available for Pickup'
    case shipmentsDict.EXCEPTION:
      return 'Exception'
    case shipmentsDict.EXPIRED:
      return 'Expired'
    case shipmentsDict.INFO_RECEIVED:
      return 'Info Received'
    default:
      return '';
  }
};

export const getCarrierLabel = carrier => {
  switch (carrier) {
    case 'AAA COOPER':
      return 'AAA Cooper';
    case 'A DUIE PYLE':
      return 'A Duie Pyle';
    case 'AVERITT':
      return 'Averitt';
    case 'ABF':
      return 'ABF';
    case 'ESTES':
      return 'Estes';
    case 'FEDEX':
      return 'FedEx';
    case 'OLD DOMINION':
      return 'Old Dominion';
    case 'RLC':
      return 'R&L Logistics';
    case 'SAIA':
      return 'SAIA';
    case 'SOUTHEASTERN':
      return 'Southeastern';
    case 'UPS':
      return 'UPS';
    case 'USF':
      return 'USF';
    case 'XPO':
      return 'XPO';
    case 'YRC':
      return 'YRC';
    default:
      return carrier;
  }
};

export default shipmentsDict;
