import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container } from 'react-bootstrap';
import moment from 'moment';
import numeral from 'numeral';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Icon from '../../../ui/Icon';
import { actionCreators } from '../../../../state/modals-dux';

import EditQuoteForm from './EditQuoteForm';
import Loading from '../../../ui/Loading';

const formatTimeDiff = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).fromNow();
};

class QuotesEdit extends Component {
  componentDidMount() {
    const {
      match,
      getQuoteById,
      getQuoteTemplates,
      getLocations,
      getLocationById
    } = this.props;
    const { id } = match.params || {};
    getQuoteById(id, null, false, (result) => {
      const { LocationID } = result;
      getLocationById({ id: LocationID, query: {} });
    });
    getQuoteTemplates();
    getLocations();
  }

  onLocationChange = (id) => {
    const { getLocationById } = this.props;
    getLocationById({ id, query: {} });
  }
  render() {
    const {
      inProgress,
      error,
      result,
      match,
      history,
      putQuote,
      putQuoteJS,
      locations,
      quoteTemplates,
      location,
      locationLoading
    } = this.props;
    const { id } = match.params || {};
    const { users, items } = result || {};

    let SubLineItemShowOnPdf;
    let SubLineItemShowOnWeb;

    (items || []).map((item) => {
      const { lineItems } = item;
      SubLineItemShowOnWeb = !lineItems.some(({ Show }) => !Show);
      SubLineItemShowOnPdf = !lineItems.some(({ ShowOnPDF }) => !ShowOnPDF);
    })

    const getInitialValues = () => result ?
      {
        ...result,
        SalesTax: (result.SalesTax === 0 ? '0' : result.SalesTax || '').toString(),
        Deposit: (result.Deposit === 0 ? '0' : result.Deposit || '').toString(),
        users: (users || {}).map(({ Id }) => Id),
        SubLineItemShowOnWeb,
        SubLineItemShowOnPdf
      } :
      { quoteSections: [{}] };

    const handleSubmit = payload => {
      const { id } = match.params || {};
      putQuote({ id, payload }, null, null, () =>
        history.push(`/quotes/${id}`));
    };

    const { users: locationUsers } = location || {};

    return (
      <Layout route="quotes-edit" alt>
        {inProgress && <Loading fullPage />}
        {!inProgress &&
          <>
            <div className="layout-app-header">
              <Container>
                <div>
                  <Link to={`/quotes/${id}`} className="back">
                    <Icon name="arrow-left" />
                  </Link>
                  <h2>
                    {(result || {}).Name}
                  </h2>
                  <h3>
                    Uploaded {formatTimeDiff((result || {}).CreateDate)}
                  </h3>
                </div>
              </Container>
            </div>

            <Container>
              <EditQuoteForm
                initialValues={getInitialValues()}
                result={putQuoteJS}
                onSubmit={handleSubmit}
                locations={locations}
                quoteTemplates={quoteTemplates}
                locationUsers={locationUsers}
                onLocationChange={this.onLocationChange}
                locationLoading={locationLoading}
                fullQuote={result || {}}
              />
            </Container>
          </>
        }

      </Layout>
    );
  }
}

const mapState = state => {
  const quote = state.getQuoteById.toJS();
  const putQuoteJS = state.putQuote.toJS();
  const {
    result: quoteTemplates
  } = state.getQuoteTemplates.toJS();
  const {
    result: locations
  } = state.searchCustomersLocations.toJS();

  const {
    result: location,
    inProgress: locationLoading,
  } = state.getLocationById.toJS();

  return { ...quote, putQuoteJS, quoteTemplates, locations, location, locationLoading };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getQuoteById: fetchDux.getQuoteById.createAction,
      putQuote: fetchDux.putQuote.createAction,
      getLocations: fetchDux.searchCustomersLocations.createAction,
      getQuoteTemplates: fetchDux.getQuoteTemplates.createAction,
      getLocationById: fetchDux.getLocationById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QuotesEdit);
