import React, { Component } from 'react';
import { getOrderLocked as getOrderLockedApi } from '../../../../api/api';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const iconStyle = {
  float: 'right',
  marginRight: '10px',
  display: 'inline-block',
  fontSize: '24px'
};

class OrderLocked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locked: true,
      loaded: false
    };
    this.getLocked = this.getLocked.bind(this);
    this.timeout = null;
  }
  componentDidMount() {
    const { projectId } = this.props;
    if (projectId) this.getLocked(projectId);
  }
  componentWillReceiveProps(nextProps) {
    const { projectId: p1 } = nextProps;
    const { projectId: p2 } = this.props;
    if (p1 === p2) return;
    if (!p1) return;
    clearTimeout(this.timeout);
    this.getLocked(p1);
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  async getLocked(id) {
    try {
      const { setLocked, getOrderLocked, useLocalState } = this.props;

      if (useLocalState) {
        const status = await getOrderLockedApi(id);
        const { locked, partialLocked, allLocked } = status || {};
        this.setState({ locked, partialLocked, allLocked, loaded: true });
        if (setLocked)
          setLocked({
            locked,
            loaded: true,
            editable: !locked,
            allLocked,
            partialLocked
          });
        if (locked) return;
        clearTimeout(this.timout);
        return;
      }

      getOrderLocked(id, null, true, status => {
        const { locked, partialLocked, allLocked } = status || {};
        this.setState({ locked, partialLocked, allLocked, loaded: true });
        if (setLocked)
          setLocked({
            locked,
            loaded: true,
            editable: !locked,
            allLocked,
            partialLocked
          });
        if (locked) return;
        clearTimeout(this.timout);
        // this.timeout = setTimeout(() => this.getLocked(id), 10000);
      });
    } catch (err) {}
  }
  render() {
    const {
      loaded,
      locked,
      allLocked,
      partialLocked
    } = this.state;

    const { error } = this.props;

    if (error)
      return (
        <div
          style={{
            ...iconStyle,
            color: '#dc3545', // red
            opacity: '.75'
          }}
        >
          <Icon name="warning" />
        </div>
      );

    if (!loaded)
      return (
        <Loading
          containerStyle={{
            float: 'right',
            marginRight: '10px',
            display: 'inline-block'
          }}
          style={{ width: '40px', height: '40px' }}
        />
      );

    if (allLocked && !locked)
      return (
        <div
          style={{
            ...iconStyle,
            color: '#04844B', // green
            opacity: '.75'
          }}
        >
          <Icon name="lock-open" />
        </div>
      );

    if (locked)
      return (
        <div
          style={{
            ...iconStyle,
            color: '#dc3545', // red
            opacity: '.75'
          }}
        >
          <Icon name="lock" />
        </div>
      );

    if (!locked)
      return (
        <div
          style={{
            ...iconStyle,
            ...(partialLocked ? { color: '#ab8106' } : {}), // yellow or gray
            opacity: '.5'
          }}
        >
          <Icon name="lock-open" />
        </div>
      );
    return false;
  }
}

const mapState = state => {
  const {
    result,
    error
  } = state.getOrderLocked.toJS();
  return {
    result,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getOrderLocked: fetchDux.getOrderLocked.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(OrderLocked);
