import React, { Component } from 'react';
import { Alert, Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import Layout from '../../../ui/Layout';
import Loading from '../../../ui/Loading';
import queryString from 'query-string';
import moment from 'moment';
import ShipmentsGroupForm from './ShipmentsGroupForm';
import ModalEditLineItem from './ModalEditLineItem';
import ModalRemoveAssignments from './ModalRemoveAssignments';
import ModalShipmentLogs
  from '../../shipments/shipments-detail/ModalShipmentLogs';
import ModalWebTrack from '../../shipments/shipments-detail/ModalWebTrack';
import ModalDeleteMiscShipmentGroup from './ModalDeleteMiscShipmentGroup';
import Icon from '../../../ui/Icon';
import { cloneDeep } from 'lodash';

const DELIVERY_OPTIONS = [
  {
    value: 12,
    label: 'Info Received'
  },
  {
    value: 2,
    label: 'In Transit'
  },
  {
    value: 8,
    label: 'Available for Pickup'
  },
  {
    value: 5,
    label: 'Out for Delivery'
  },
  {
    value: 6,
    label: 'Failed Attempt'
  },
  {
    value: 7,
    label: 'Delivered'
  },

  {
    value: 4,
    label: 'Received By Warehouse'
  },
  {
    value: 0,
    label: 'Not Found'
  },
  {
    value: 9,
    label: 'Exception'
  },
  {
    value: 10,
    label: 'Expired'
  },
  {
    value: -1,
    label: 'Unshipped'
  }
];

const CARRIERS = [
  {
    value: 'aaacooper',
    label: 'AAA Cooper Transportation'
  },
  {
    value: 'A DUIE PYLE',
    label: 'A Duie Pyle' //Not found
  },
  {
    value: 'averittexpress',
    label: 'AVERITT Express'
  },
  {
    value: 'abf',
    label: 'ABF Freight'
  },
  {
    value: 'estes',
    label: 'Estes'
  },
  {
    value: 'fedex',
    label: 'Fedex'
  },
  {
    value: 'old-dominion',
    label: 'Old Dominion Freight Line'
  },
  {
    value: 'rl-carriers',
    label: 'RL Carriers'
  },
  {
    value: 'saia-freight',
    label: 'Saia LTL Freight'
  },
  {
    value: 'se-freightlines',
    label: 'Southeastern Freightlines'
  },
  {
    value: 'ups',
    label: 'UPS'
  },
  {
    value: 'USF',
    label: 'USF Holland' //Not found
  },
  {
    value: 'usps',
    label: 'USPS'
  },
  {
    value: 'xpoweb',
    label: 'XPO Logistics'
  },
  {
    value: 'YRC',
    label: 'YRC' //Not found
  }
];


const getShipmentGroup = ({ shipments, data, type, misc, rank }) => {
  const shipment = (shipments || []).find(s => {
    const { TrackingNum, SchdShipDate, groupName, rank: shipmentRank } = s;
    if (type === 'tracking' && TrackingNum === data) {
      if (misc && groupName.startsWith('misc') && shipmentRank === rank)
        return s;
      if (!misc) return s;
    } else if (
      type === 'scheduled' &&
      moment.utc(SchdShipDate).isSame(moment(data).utc(true)) &&
      !TrackingNum &&
      TrackingNum !== ''
    ) {
      if (misc && groupName.startsWith('misc') && shipmentRank === rank)
        return s;
      if (!misc) return s;
    } else if (type === 'unassigned') {
      if (misc && groupName.startsWith('misc') && shipmentRank === rank)
        return s;
      if (
        !misc &&
        (!TrackingNum ||
          TrackingNum === 'null' ||
          (TrackingNum || '').trim().length === 0) &&
        !SchdShipDate
      )
        return s;
    }
  });
  return shipment || {};
};
const getInitialValues = (
  {
    shipments = [],
    data,
    type,
    lineItems,
    copy,
    CARRIERS,
    purchaseOrder,
    poId,
    shipmentId,
    misc,
    rank,
    totalCarriers,
    defaultCarriers
  }
) => {
  const {
    c_PurchaseOrderNumber,
    VendorName
  } = purchaseOrder || {};

  const shipment = getShipmentGroup({ shipments, data, type, misc, rank });

  let { Carrier } = shipment || {};

  let c = (defaultCarriers || []).find(({ value }) => value === Carrier);
  Carrier = (Carrier || '').toLowerCase();
  const carrierName = Carrier === 'saia'
    ? 'saia-freight'
    : Carrier === 'dayton'
      ? 'dayton-freight'
      : Carrier === 'rlc' ? 'rl-carriers' :
        Carrier === 'southeastern' ?
          'se-freightlines' :
          Carrier === 'usf' ?
            'usf-reddaway' :
            Carrier === 'xpo' ?
              'xpoweb' :
              Carrier === 'averitt' ?
                'averittexpress' :
                Carrier || '';

  const courier = (totalCarriers || []).find((c) => {
    const { courier_code, courier_name } = c || {};
    if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('-')) ||
      (courier_name || '').toLowerCase() === (carrierName.split(' ').join('-'))) {
      return c;
    }
    else if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('')) ||
      (courier_name || '').toLowerCase() === (carrierName.split(' ').join(''))) {
      return c;
    }
  })
  // let trackingMore = !!courier;
  // const isDefaultCarrier = !!c;
  if (!c) {
    c = courier;
    if (c) {
      CARRIERS.splice(0, 0, { label: c.courier_name, value: c.courier_code });
    }
  }

  const carrierValues = Carrier
    ? {
      Carrier: {
        value: c ? c.courier_code ? c.courier_code : carrierName : carrierName,
        label: c ? c.label || c.courier_name : Carrier
      }
    }
    : {};

  const { ManualTracking } = shipment;
  return {
    ...(shipment || {}),
    ...carrierValues,
    // ...additionalFields,
    poNumber: c_PurchaseOrderNumber,
    VendorName,
    originalCarrier: carrierValues.Carrier ? carrierValues.Carrier.value : '',
    // isDefaultCarrier,
    defaultCarriers,
    // trackingMore,
    manualTracking: ManualTracking || false
  };
};

class ShipmentsGroupDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openTabs: {
        poForm: false,
        generalForm: true,
        vendorInformation: true,
        carrierForm: true,
        warehouseForm: true,
        shipmentLineItemsForm: true
      },
      defaultCarriers: cloneDeep(CARRIERS)
    };
  }

  componentWillUnmount() {
    this.props.clearTrackingMoreTracking()
  }

  componentWillReceiveProps(nextProps) {
    const {
      location: { search },
      match: { params: { poId, type, data } },
      getShipmentsLineItems,
      getLogs
    } = this.props;

    const {
      match: { params: { type: nextType, data: nextData } }
    } = nextProps;
    if (search != nextProps.location.search) {
      this.fetchData(nextProps);
      return;
    }
    if (poId !== nextProps.match.params.poId) {
      this.fetchData(nextProps);
      return;
    }

    if (type !== nextType || data !== nextData)
      getShipmentsLineItems({ poId, type: nextType, data: nextData });
  }

  handleShipmentGroupChange = value => {
    const [typeData, data, rank] = value.split('#');
    const type = typeData.indexOf('@') >= 0 ? typeData.split('@')[1] : typeData;
    const { match, history, location: { search } } = this.props;
    const query = queryString.parse(search);
    const nextSearch = queryString.stringify({
      ...query,
      misc: typeData.indexOf('@') >= 0,
      rank
    });
    const { poId } = match.params;
    history.push({
      pathname: `/shipments-groups/${poId}/${type}/${data}`,
      search: nextSearch
    });
  };

  handleSubmit = value => {
    const {
      match,
      history,
      result,
      updateShipmentGroup,
      location: { search: locationSearch },
      shipmentId
    } = this.props;
    const query = queryString.parse(locationSearch);
    const { misc: miscSearch, rank } = query;
    const misc = miscSearch === 'true';
    const { vendors, purchaseOrder, shipments } = result;
    const { c_PurchaseOrderNumber } = purchaseOrder;
    const { poId, type, data } = match.params;
    const { ID, ...payload } = value;

    const shipment = getShipmentGroup({ shipments, data, type, misc, rank });

    const search = queryString.stringify({
      t: new Date().getTime(),
      misc,
      rank: payload.rank || rank
    });
    if (payload.Carrier && payload.Carrier.value)
      payload.Carrier = payload.Carrier.value;

    // remove carrierLink
    delete payload.CarrierLink;
    delete payload.trackingMore;
    delete payload.isDefaultCarrier;
    delete payload.defaultCarriers;
    delete payload.ManualTracking;

    const sageVendor = vendors.find(({ Name }) => Name === payload.VendorName);
    payload.sageVendorId = sageVendor && sageVendor.selectedSageVendorId;
    delete payload.VendorName;

    const { DeliveryStatus, OverrideDeliveryStatus } = payload;

    updateShipmentGroup(
      {
        ...payload,
        DeliveryStatus: parseInt(DeliveryStatus),
        OverrideDeliveryStatus: parseInt(OverrideDeliveryStatus),
        poNum: c_PurchaseOrderNumber,
        PurchaseOrderId: poId,
        type,
        data,
        misc,
        miscId: ID || shipmentId
      },
      null,
      null,
      result => {
        const { newTrackingNum, newSchdShipDate } = result;
        if (misc) {
          history.push({
            pathname: `/shipments-groups/${result.ID}/${type}/${data}`,
            search
          });
          return;
        }
        if (
          (newTrackingNum && shipment.TrackingNum !== newTrackingNum) ||
          (newTrackingNum &&
            newSchdShipDate &&
            shipment.SchdShipDate !== newSchdShipDate)
        ) {
          history.push({
            pathname: `/shipments-groups/${result.ID}/tracking/${newTrackingNum}`,
            search
          });
        } else if (
          newSchdShipDate && shipment.SchdShipDate !== newSchdShipDate
        ) {
          history.push({
            pathname: `/shipments-groups/${result.ID}/scheduled/${moment
              .utc(newSchdShipDate)
              .format('YYYY-MM-DD')}`,
            search
          });
        } else if (!newTrackingNum && !newSchdShipDate) {
          history.push({
            pathname: `/shipments-groups/${result.ID}/unassigned/na`,
            search
          });
        } else {
          history.push({
            pathname: `/shipments-groups/${result.ID}/${type}/${data}`,
            search
          });
        }
      }
    );
    // const shipmentId = ID || id;
    // if (shipmentId === 'new')
    //   postShipment(payload, null, null, data => {
    //     const pathname = `/shipments/${data.ID}/${poId}`;
    //     history.replace({ pathname, search });
    //   });
    // else
    //   updateShipment({ id: shipmentId, payload }, null, null, data =>
    //     history.push({ pathname: `/shipments/${data.ID}/${poId}`, search }));
  };

  handlePurchaseOrderChange = value => {
    const { ID, shipments: [shipmentGroup] } = value;
    const { groupName, TrackingNum, SchdShipDate } = shipmentGroup;
    const formattedShipDate = moment.utc(SchdShipDate).format('MM-DD-YYYY');
    const data = groupName === 'tracking'
      ? TrackingNum
      : groupName === 'scheduled' ? formattedShipDate : 'na';
    const { history } = this.props;
    history.push(`/shipments-groups/${ID}/${groupName}/${data}`);
  };

  fetchData(
    { getPoShipmentsLineItems, getShipmentsLineItems, match, history, getLogs }
  ) {
    const { poId, type, data } = match.params;
    if (!poId) {
      history.push('/shipments');
    }
    getPoShipmentsLineItems(poId);
    getLogs(poId);
    if (type && data) {
      getShipmentsLineItems({ poId, type, data });
    }
  }

  componentDidMount() {
    this.fetchData(this.props);
    this.props.getAllCarriers();
  }

  render() {
    const {
      inProgress,
      error,
      result,
      location,
      match,
      shipmentLineItems,
      history,
      shipmentGroupLogs,
      showModal,
      webTrack,
      addMiscShipmentsLineItemsGroup,
      webTrackInProgress,
      getCarrierByName,
      clearGetCarrierByName,
      carriers,
      totalCarriers,
      shipmentsSearch
    } = this.props;
    const { poId, data, type } = match.params || {};
    const { openTabs } = this.state;
    const setOpen = (value, name) => {
      this.setState({ openTabs: { ...openTabs, [name]: value } });
    };

    const getShipmentId = () => {
      const {
        shipments = []
      } = result || {};

      const { misc: miscSearch, rank } = queryString.parse(location.search) || {
      };

      const misc = miscSearch === 'true';

      const { ID } = getShipmentGroup({
        shipments,
        data,
        type,
        misc,
        rank
      }) || {};
      return ID;
    };

    const getFormProps = () => {
      const {
        shipments = [],
        carriers = [],
        purchaseOrder,
        shippingAddress = {},
        deliveryStatusCodes = {},
        lineItems = [],
        shipper,
        vendors = [],
        poResults = []
      } = result || {};
      const {
        c_PurchaseOrderNumber,
        VendorNum,
        VendorName,
        c_IsImported
      } = purchaseOrder || {};
      const { copy, misc: miscSearch, rank } = queryString.parse(
        location.search
      ) || {};
      const misc = miscSearch === 'true';

      const shipmentOptions = (shipments || [])
        .map(shipment => {
          const {
            groupName,
            ShipmentDesc,
            TrackingNum,
            SchdShipDate,
            DeliveryStatus,
            ActualShipDate,
            rank
          } = shipment;
          const { label: deliveryStatusValue } = DELIVERY_OPTIONS.find(({
            value,
            label
          }) => {
            if (value === (parseInt(DeliveryStatus) || 1)) {
              return { label };
            }
          }) || { label: '-' };
          const value = groupName === 'tracking'
            ? `tracking#${TrackingNum}`
            : groupName === 'scheduled'
              ? `scheduled#${moment.utc(SchdShipDate).format('YYYY-MM-DD')}`
              : groupName.startsWith('misc@scheduled')
                ? `misc@scheduled#${moment
                  .utc(SchdShipDate)
                  .format('YYYY-MM-DD')}`
                : groupName.startsWith('misc@tracking')
                  ? `misc@tracking#${TrackingNum}`
                  : groupName.startsWith('misc@unassigned')
                    ? `misc@unassigned#na#${rank}`
                    : 'unassigned#na';
          const label = groupName === 'tracking'
            ? [
              c_PurchaseOrderNumber,
              VendorNum,
              !TrackingNum && moment.utc(SchdShipDate).isValid()
                ? moment.utc(SchdShipDate).format('YYYY-MM-DD')
                : TrackingNum,
              deliveryStatusValue,
              ShipmentDesc
            ].join(' | ')
            : groupName === 'scheduled'
              ? [
                c_PurchaseOrderNumber,
                VendorNum,
                moment.utc(SchdShipDate).format('YYYY-MM-DD'),
                deliveryStatusValue,
                ShipmentDesc
              ].join(' | ')
              : groupName.startsWith('misc@scheduled')
                ? [
                  c_PurchaseOrderNumber,
                  VendorNum,
                  SchdShipDate
                    ? moment.utc(SchdShipDate).format('YYYY-MM-DD')
                    : TrackingNum ? TrackingNum : '',
                  deliveryStatusValue,
                  ShipmentDesc,
                  'Misc',
                  rank
                ].join(' | ')
                : groupName.startsWith('misc@tracking')
                  ? [
                    c_PurchaseOrderNumber,
                    VendorNum,
                    !TrackingNum && moment.utc(SchdShipDate).isValid()
                      ? moment.utc(SchdShipDate).format('YYYY-MM-DD')
                      : TrackingNum,
                    deliveryStatusValue,
                    ShipmentDesc,
                    'Misc',
                    rank
                  ].join(' | ')
                  : groupName.startsWith('misc@unassigned')
                    ? `${c_PurchaseOrderNumber} | Misc | ${rank} | ${ShipmentDesc || ''}`
                    : 'Un-assigned Items';
          return {
            value,
            label
          };
        })
        .filter(
          (value, index, self) =>
            index === self.findIndex(t => t.label === value.label)
        );

      const shipment = getShipmentGroup({ shipments, data, type, misc, rank });
      const { DeliveryStatus } = shipment;
      const initialValues = getInitialValues({
        shipments,
        data,
        type,
        lineItems,
        copy,
        CARRIERS,
        purchaseOrder,
        poId,
        misc,
        rank,
        totalCarriers,
        defaultCarriers: this.state.defaultCarriers
      });
      const generalInfo = {
        VendorNum,
        OrderEntryNum: (c_PurchaseOrderNumber || '').split('-')[0],
        ShipVia: shippingAddress.c_ShipViaId,
        ShipViaName: '',
        CarrierException: shipment.CarrierException ? 'Yes' : 'No',
        shipper,
        ShipTo: [
          shippingAddress.Name,
          shippingAddress.Address1,
          shippingAddress.Address2,
          shippingAddress.City,
          shippingAddress.State + ' ' + shippingAddress.Zip,
          shippingAddress.PhoneCell,
          shippingAddress.PhoneContact
        ],
        shippingAddress,
        c_PurchaseOrderNumber
      };

      const vendorOptions = vendors.map(v => {
        return {
          value: v.Name,
          label: v.Name
        };
      });

      const purchaseOrderOptions = poResults.map(({
        c_PurchaseOrderNumber,
        ID,
        VendorName,
        shipments
      }) => {
        return {
          value: c_PurchaseOrderNumber,
          label: `${c_PurchaseOrderNumber} | ${VendorName}`,
          ID,
          shipments
        };
      });

      return {
        shipmentOptions,
        // carrierOptions,
        generalInfo,
        deliveryStatusCodes,
        initialValues: {
          ...initialValues,
          shipmentGroup: type === 'scheduled'
            ? misc
              ? `misc@scheduled#${moment(data).format('YYYY-MM-DD')}`
              : `${type}#${moment(data).format('YYYY-MM-DD')}`
            : misc ? `misc@unassigned#na#${rank}` : `${type}#${data}`
        },
        vendorOptions,
        c_IsImported,
        purchaseOrderOptions,
        poVendorName: VendorName
      };
    };

    const handleShowCarrierPage = value => {
      if ((value.CarrierLink || '').trim().length > 0) {
        window.open(
          value.CarrierLink,
          '_blank',
          'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
        );
      }
    };

    // const handleWebTrack = value => {
    //     this.props.getWebTracking(value, null, null, data => {
    //         handleShowCarrierPage(data);
    //     });
    //     showModal('WebTrackModal');
    // };

    const handleWebTrack = value => {
      this.props.getTrackingMoreTracking(value, null, null, (result) => {
        const formProps = getFormProps();
        const { initialValues } = formProps;
        const { manualTracking } = initialValues;
        if (manualTracking) {
          const { CarrierLink } = result;
          if ((CarrierLink || '').trim().length > 0) {
            window.open(
              CarrierLink,
              '_blank',
              'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
            );
          }
        } else {
          showModal('WebTrackModal');
        }
        this.fetchData(this.props);
      });
    };

    const { result: webTrackResult } = webTrack || {};
    const { trackingLink, CarrierLink } = webTrackResult || {};
    return (
      <Layout route="shipments-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              {shipmentsSearch &&
                <Icon
                  onClick={() => {
                    history.push({ pathname: '/shipments', search: shipmentsSearch })
                  }}
                  name='arrow-left' size='lg' style={{ marginRight: '1rem' }} />
              }

              <h2>
                Shipment Detail
              </h2>
            </div>
          </Container>
        </div>

        <Container>
          {webTrackResult &&
            (trackingLink || '').trim().length === 0 &&
            !CarrierLink &&
            <Alert>
              {
                'Tracking information updated successfully, but we do not have the tracking link for the courier at the moment.'
              }
            </Alert>}
        </Container>
        {(webTrack || {}).error && <Alert variant="danger">{(webTrack || {}).error.message}</Alert>}
        {error && <Alert variant="danger">{error.message}</Alert>}
        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        {!inProgress &&
          <Container>
            <ShipmentsGroupForm
              {...result}
              {...getFormProps()}
              setOpen={setOpen}
              openTabs={openTabs}
              CARRIERS={CARRIERS}
              shipmentLineItems={shipmentLineItems}
              handleShipmentGroupChange={this.handleShipmentGroupChange}
              handlePurchaseOrderChange={this.handlePurchaseOrderChange}
              handleWebTrack={handleWebTrack}
              onSubmit={this.handleSubmit}
              shipmentGroupLogs={shipmentGroupLogs}
              type={type}
              data={data}
              history={history}
              addMiscShipmentsLineItemsGroup={addMiscShipmentsLineItemsGroup}
              webTrackInProgress={webTrackInProgress}
              getCarrierByName={getCarrierByName}
              clearGetCarrierByName={clearGetCarrierByName}
              carriersSearchResults={carriers}
              totalCarriers={totalCarriers}
            />
            <ModalEditLineItem
              name="EditLineItemsModal"
              data={data}
              type={type}
              onUpdateSuccess={({ type, data }) => {
                if (type === 'scheduled')
                  data = moment.utc(data).format('YYYY-MM-DD');
                history.push(
                  `/shipments-groups/${poId}/${type}/${data}?t=${Date.now()}`
                );
              }}
            />
            <ModalRemoveAssignments
              name="RemoveAssignmentsModal"
              shipmentLineItems={shipmentLineItems}
              successHandler={() => {
                history.push(
                  `/shipments-groups/${poId}/unassigned/na?t=${Date.now()}`
                );
              }}
              poId={poId}
            />
            <ModalShipmentLogs
              name="ShipmentLogsModal"
              result={shipmentGroupLogs}
            />
            <ModalWebTrack
              {...webTrack}
              shipmentId={getShipmentId()}
              name="WebTrackModal"
              handleUpdateFromWeb={this.handleSubmit}
              handleShowCarrierPage={handleShowCarrierPage}
            />
            <ModalDeleteMiscShipmentGroup
              name={'DeleteMiscShipmentModal'}
              history={history}
            />
          </Container>}
      </Layout>
    );
  }
}

const mapState = state => {
  const po = state.getPoShipmentsLineItems.toJS();
  const {
    result: shipmentLineItems,
    error: shipmentsLineItemsError
  } = state.getShipmentsLineItems.toJS();

  const {
    result: shipmentGroupLogs,
    error: logsError
  } = state.getShipmentsLineItemsLogs.toJS();

  const {
    error: updateGroupError,
    inProgress: updateInProgress
  } = state.updateShipmentGroup.toJS();

  const {
    error: addMiscShipmentError
  } = state.addMiscShipmentsLineItemsGroup.toJS();

  const webTrack = state.getTrackingMoreTracking.toJS();

  const {
    inProgress: webTrackInProgress
  } = state.getTrackingMoreTracking.toJS();

  const {
    result
  } = state.getCarrierByName.toJS();

  const {
    result: totalCarriers
  } = state.getAllCarriers.toJS();

  return {
    result: po.result,
    error: po.error ||
      shipmentsLineItemsError ||
      logsError ||
      updateGroupError ||
      addMiscShipmentError,
    inProgress: po.inProgress || updateInProgress,
    shipmentLineItems,
    shipmentGroupLogs,
    webTrack,
    webTrackInProgress,
    carriers: result,
    totalCarriers,
    shipmentsSearch: state.shipmentsSearch
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getPoShipmentsLineItems: fetchDux.getPoShipmentsLineItems.createAction,
      getShipmentsLineItems: fetchDux.getShipmentsLineItems.createAction,
      updateShipmentGroup: fetchDux.updateShipmentGroup.createAction,
      getLogs: fetchDux.getShipmentsLineItemsLogs.createAction,
      getWebTracking: fetchDux.getWebTracking.createAction,
      addMiscShipmentsLineItemsGroup: fetchDux.addMiscShipmentsLineItemsGroup.createAction,
      getTrackingMoreTracking: fetchDux.getTrackingMoreTracking.createAction,
      getCarrierByName: fetchDux.getCarrierByName.createAction,
      clearGetCarrierByName: fetchDux.getCarrierByName.clearAction,
      getAllCarriers: fetchDux.getAllCarriers.createAction,
      clearTrackingMoreTracking: fetchDux.getTrackingMoreTracking.clearAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ShipmentsGroupDetails);
