import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Table from '../../../ui/Table';
import Icon from '../../../ui/Icon';
import ColumnPopover from '../../../ui/ColumnPopover';
import { getStatusLabel, getCarrierLabel } from '../shipments-dict';
import moment from 'moment';
import { getShipmentsDetaiURL } from '../shipments-utils';
import WebTrackButtonColumnPopover from '../../../ui/WebTrackButtonColumnPopover';

const getColumns = (
  showActions,
  onRowSelected,
  handleWebTrack,
  updateInProgress,
  totalCarriers,
  showModal,
  CARRIERS
) => {
  const columns = [
    {
      Header: 'Details',
      accessor: 'Details',
      minWidth: 75,
      sortable: false,
      resizable: false,
      id: 'Details',
      Cell: ({ value, original }) => {
        const url = getShipmentsDetaiURL(original);
        return (
          <div className="text-center">
            <Link to={url}>
              <Icon
                style={{ fontSize: '25px', color: '#5EA2C9' }}
                name="list-alt"
              />
            </Link>
          </div>
        );
      }
    },
    {
      Header: 'Order Date',
      accessor: 'c_ImportedDate',
      show: true,
      style: { textAlign: 'center' },
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return <span />;
        return (
          <ColumnPopover
            header="Order Date"
            data={moment(value).format('MM/DD/YYYY')}
          />
        );
      }
    },
    {
      Header: 'PO Number',
      accessor: 'PoNum',
      show: true,
      minWidth: 100,
      Cell: ({ value, original: row }) => {
        if (row.c_IsImported)
          return <ColumnPopover header="PO Number" data={value} />;
        return (
          <ColumnPopover
            header="PO Number"
            data={value}
            url={`/projects/${row.ProjectId}`}
          />
        );
      }
    },
    {
      Header: 'Vendor',
      accessor: 'VendorNum',
      show: true,
      Cell: ({ value }) => {
        return <ColumnPopover header="Vendor" data={value} />;
      }
    },
    {
      Header: 'Shipment Description',
      accessor: 'ShipmentDesc',
      show: true,
      Cell: ({ value }) => {
        return <ColumnPopover header="Shipment Description" data={value} />;
      }
    },

    {
      Header: 'Destination',
      accessor: 'destination',
      sortable: false,
      show: true,
      Cell: ({ original }) => {
        const { City, State, street } = original;
        return (
          <ColumnPopover
            header="Destination"
            data={`${street} ${City}, ${State}`}
          />
        );
      }
    },
    {
      Header: 'Order No.',
      accessor: 'VendorOrderNum',
      show: true,
      Cell: ({ value }) => {
        return <ColumnPopover header="Order No." data={value} />;
      },
      style: { textAlign: 'center' }
    },

    {
      Header: 'Req Ship Date',
      accessor: 'c_ShipDate',
      style: { textAlign: 'center' },
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return <span />;
        return (
          <ColumnPopover
            header="Req Ship Date"
            data={moment.utc(value).format('MM/DD/YYYY')}
          />
        );
      }
    },
    {
      Header: 'Sched Ship Date',
      accessor: 'SchdShipDate',
      style: { textAlign: 'center' },
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return <span />;
        return (
          <ColumnPopover
            header="Sched Ship Date"
            data={moment.utc(value).format('MM/DD/YYYY')}
          />
        );
      }
    },
    {
      Header: 'Actual Ship Date',
      accessor: 'ActualShipDate',
      style: { textAlign: 'center' },
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return <span />;
        return (
          <ColumnPopover
            header="Actual Ship Date"
            data={moment.utc(value).format('MM/DD/YYYY')}
          />
        );
      }
    },
    {
      Header: 'Carrier',
      accessor: 'Carrier',
      Cell: ({ value, original }) => {
        let { Carrier } = original;
        const courier = CARRIERS.some(c => c.value === Carrier);
        Carrier = (Carrier || '').toLowerCase();
        const carrierName = Carrier === 'saia'
          ? 'saia-freight'
          : Carrier === 'dayton'
            ? 'dayton-freight'
            : Carrier === 'rlc' ? 'rl-carriers' :
              Carrier === 'southeastern' ?
                'se-freightlines' :
                Carrier === 'usf' ?
                  'usf-reddaway' :
                  Carrier === 'xpo' ?
                    'xpoweb' :
                    Carrier === 'averitt' ?
                      'averittexpress' :
                      Carrier || '';

        const carrier = (totalCarriers || []).find((c) => {
          const { courier_code, courier_name } = c || {};
          if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('-')) ||
            (courier_name || '').toLowerCase() === (carrierName.split(' ').join('-'))) {
            return c;
          }
          else if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('')) ||
            (courier_name || '').toLowerCase() === (carrierName.split(' ').join(''))) {
            return c;
          }
        })
        return (
          <ColumnPopover
            header="Carrier"
            data={
              carrier
                ? carrier.courier_name
                : getCarrierLabel(value)
            }
            isCustomCourier={!courier && !carrier && (Carrier || '').trim().length > 0}
          />
        );
      }
    },
    {
      Header: 'Tracking Number',
      accessor: 'TrackingNum',
      show: true,
      Cell: ({ value }) => {
        return <ColumnPopover header="Tracking Number" data={value} />;
      }
    },

    {
      Header: 'Web Track',
      accessor: 'webTrack',
      sortable: false,
      resizable: false,
      show: true,
      Cell: ({ original }) => {
        const {
          TrackingNum,
          DeliveryStatus,
          trackingMoreId,
          TrackingMore_Tracking_ID,
          ManualTracking,
        } = original;
        let { Carrier } = original;
        const defaultCarrier = CARRIERS.find(({ value }) => value === Carrier);
        Carrier = (Carrier || '').toLowerCase();
        const carrierName = Carrier === 'saia'
          ? 'saia-freight'
          : Carrier === 'dayton'
            ? 'dayton-freight'
            : Carrier === 'rlc' ? 'rl-carriers' :
              Carrier === 'southeastern' ?
                'se-freightlines' :
                Carrier === 'usf' ?
                  'usf-reddaway' :
                  Carrier === 'xpo' ?
                    'xpoweb' :
                    Carrier === 'averitt' ?
                      'averittexpress' :
                      Carrier || '';

        const courier = (totalCarriers || []).find((c) => {
          const { courier_code, courier_name } = c || {};
          if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('-')) ||
            (courier_name || '').toLowerCase() === (carrierName.split(' ').join('-'))) {
            return c;
          }
          else if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('')) ||
            (courier_name || '').toLowerCase() === (carrierName.split(' ').join(''))) {
            return c;
          }
        })
        const enabled = (Carrier || '').trim().length > 0
          && (TrackingNum || '').trim().length > 0
          && (CARRIERS.some(c => c.value.toLowerCase() === Carrier) || courier);




        const buttonStyle = parseInt(DeliveryStatus) === 4 || parseInt(DeliveryStatus) === 7 ?
          'primary' :
          parseInt(DeliveryStatus) === 0 ? 'primary-grey' :
            (trackingMoreId || TrackingMore_Tracking_ID) ?
              ManualTracking ? 'warning-secondary' : 'warning' :
              !!courier ? 'success' : !!defaultCarrier ? 'success' : 'primary'

        return (
          <div className="text-center">
            <WebTrackButtonColumnPopover
              header="Web Track"
              enabled={enabled}
              buttonStyle={buttonStyle}
              handleWebTrack={handleWebTrack}
              showModal={showModal}
              updateInProgress={updateInProgress}
              original={original}
            />
          </div>
        );
      }
    },

    {
      Header: 'Status',
      accessor: 'DeliveryStatus',
      Cell: ({ value }) => {
        return <ColumnPopover header="Status" data={getStatusLabel(value)} />;
      }
    },

    {
      Header: 'Whse RR #',
      accessor: 'ReceivingReportNum',
      show: true,
      Cell: ({ value }) => {
        return <ColumnPopover header="Whse RR #" data={value} />;
      },
      style: { textAlign: 'center' }
    },
    {
      Header: 'Notes',
      accessor: 'Notes',
      show: true,
      Cell: ({ value }) => {
        return <ColumnPopover header="Notes" data={value} />;
      }
    },

    {
      Header: 'Actual Delivery Date',
      accessor: 'CarrierDeliveryDate',
      show: false,
      style: { textAlign: 'center' },
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return <span />;
        return (
          <ColumnPopover
            header="Actual Delivery Date"
            data={moment.utc(value).format('MM/DD/YYYY')}
          />
        );
      }
    }
  ];
  if (showActions) {
    columns.push({
      Header: 'Actions',
      className: 'text-center',
      width: 80,
      sortable: false,
      show: true,
      Cell: ({ original }) => {
        return (
          <span>
            <Icon
              style={{ fontSize: '25px', color: '#5EA2C9' }}
              name="check-square"
              onClick={() => {
                onRowSelected(original);
              }}
            />
          </span>
        );
      }
    });
  }
  return columns;
};

const ShipmentsResult = (
  {
    result: {
      results,
      count,
      numPages
    },
    paging: {
      page,
      pageSize,
      search
    },
    inProgress,
    error,
    onFetchData,
    activeColumns,
    onColumnChange,
    showActions,
    onRowSelected,
    serverSearch,
    handleSearchChange,
    handleWebTrack,
    updateInProgress,
    totalCarriers,
    showModal,
    CARRIERS,
    shipmentsInProgress,
    hasLoadedData
  }
) => {
  const columns = getColumns(
    showActions,
    onRowSelected,
    handleWebTrack,
    updateInProgress,
    totalCarriers,
    showModal,
    CARRIERS
  );

  const listDraggableColumns = (columns || []).map(({ accessor }) => accessor);
  if (activeColumns.length !== 0) {
    columns.forEach(col => {
      col.show = activeColumns.includes(col.accessor);
    });
  }

  const columnsOrder = JSON.parse(window.localStorage.getItem('shipmentColumnsOrder')) || [];
  const tableRef = React.createRef();

  columnsOrder.forEach((c_accessor, index) => {
    const currentIndex = columns.findIndex(({ accessor }) => c_accessor === accessor);
    if (currentIndex !== index) {
      const element = columns[currentIndex];
      columns.splice(currentIndex, 1);
      columns.splice(index, 0, element);
    }
  })

  const finalPageSize = (shipmentsInProgress || !hasLoadedData) ? Number(pageSize) :
    (results || []).length === 0 ? 5 :
      count < 50 ?
        count > Number(pageSize) ? Number(pageSize) : count < 5 ? 5 : count :
        Number(pageSize)

  const options = [5, 10, 20, 25, 50, 100, finalPageSize];
  const pageSizeOptions = Array.from(new Set(options)).sort((a, b) => (a - b));

  return (
    <Table
      style={{ height: 600 }}
      manual
      serverSearch={serverSearch ? true : false}
      showSearch={serverSearch ? true : false}
      search={serverSearch ? search : false}
      onSearchChange={handleSearchChange}
      data={results}
      columns={columns}
      showColumnsSelector={true}
      onColumnChange={onColumnChange}
      showNumResults={serverSearch ? false : true}
      onFetchData={onFetchData}
      defaultPageSize={Number(pageSize)}
      pageSize={finalPageSize}
      noDataText={shipmentsInProgress ? 'Loading' : 'No orders found'}
      pages={error ? 0 : numPages}
      page={parseInt(page)}
      totalResults={count}
      inProgress={shipmentsInProgress}
      keepTable
      showPageJump={false}
      noResultsText={'No Results'}
      draggableColumns={true}
      listDraggableColumns={listDraggableColumns}
      pageSizeOptions={pageSizeOptions}
      onDropComplete={(reorder) => {
        const activeColumns = (window.localStorage.getItem('shipmentColumns') || '')
          .split(',')
          .filter(v => (v || '').trim().length > 0);
        if (activeColumns.length !== 0) {
          columns.forEach(col => {
            col.show = activeColumns.includes(col.accessor);
          });
        }
        const visibleColumns = columns.filter(col => col.show === true || col.show === undefined);
        const hiddenColumns = columns.filter(col => col.show === false);
        const columnsOrder = [...visibleColumns, ...hiddenColumns].map(({ accessor }) => accessor);
        reorder.forEach(o => columnsOrder.splice(o.a, 0, columnsOrder.splice(o.b, 1)[0]));
        window.localStorage.setItem('shipmentColumnsOrder', JSON.stringify(columnsOrder));

      }}
      tableRef={tableRef}     
       minRows={10}
    />
  );
};

export default ShipmentsResult;
