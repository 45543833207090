import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';
import Icon from '../../../ui/Icon';
import moment from 'moment';
import numeral from 'numeral';
import paymentOptionDict from '../paymentOptionDict';
import getConvenienceFee from '../feeDict';
import { getStatusLabel } from '../../projects/statusDict';

const formatMoney = value => numeral(value).format('0,0.00');
const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y');
};

const EcommerceOrdersSearchResult = (
  {
    error,
    result,
    onFetchData,
    paging,
    handleSearchChange,
    inProgress,
    updateSelected,
    showPending
  }
) => {
  const {
    numPages,
    results: projects
  } = result || {};
  const columns = [
    // {
    //   Header: '',
    //   accessor: 'JobRef',
    //   minWidth: 30,
    //   className: 'text-center',
    //   Cell: ({ value, original }) => (
    //     <input
    //       type="checkbox"
    //       onChange={event => {
    //         updateSelected(value, event.target.checked);
    //       }}
    //       disabled={original.Status === 'Processed'}
    //     />
    //   )
    // },
    {
      Header: 'Order Number',
      accessor: 'JobRef',
      Cell: ({ value, original }) => (
        <Link to={`/ecommerce-orders/${original.projectId}`}>{value}</Link>
      ),
      className: 'text-center'
    },
    {
      Header: 'Order Date',
      accessor: 'c_ImportedDate',
      className: 'text-center',
      Cell: ({ value }) => <span>{formatTime(value)}</span>
    },
    {
      Header: 'Customer',
      className: 'text-center',
      Cell: ({ original }) => (
        <span>{`${original.NameFirst} ${original.NameLast}`}</span>
      )
    },
    {
      Header: 'Payment Method',
      accessor: 'PaymentOption',
      className: 'text-center',
      Cell: ({ value }) => <span>{paymentOptionDict[value]}</span>
    },
    {
      Header: 'Items',
      accessor: 'numItems',
      className: 'text-center'
    },
    {
      Header: 'Sale Amount',
      accessor: 'saleAmount',
      className: 'text-center',
      Cell: ({ value, original }) => {
        const convenienceFee = original.PaymentOption === 1
          ? getConvenienceFee(value)
          : 0;
        return <span>${formatMoney(value + convenienceFee)}</span>;
      }
    },
    {
      Header: 'Ship State',
      className: 'text-center',
      accessor: 'State'
    },
    {
      Header: 'Status',
      className: 'text-center',
      accessor: 'projectStatus',
      Cell: ({ value }) => <span>{getStatusLabel(value)}</span>
    }
  ];
  const {
    sortBy,
    desc,
    page,
    pageSize,
    search
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];
  return (
    <Table
      manual
      keepTable
      showColumnsSelector
      showSearch
      serverSearch
      search={search}
      onSearchChange={handleSearchChange}
      data={projects}
      columns={columns}
      onFetchData={onFetchData}
      noDataText={inProgress ? 'Loading' : 'No orders found'}
      defaultSorted={sorted}
      page={Number(page)}
      pages={error ? 0 : numPages}
      defaultPageSize={Number(pageSize)}
      inProgress={inProgress}
    />
  );
};

export default EcommerceOrdersSearchResult;
