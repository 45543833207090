import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import numeral from 'numeral';

const required = value => value ? undefined : 'Required';

const AcceptQuoteForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result,
    depositInvoice,
    depositPercentage
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">

        <Alert variant="danger">
          <b>This quote has not been accepted by customer</b>
          <div>
            Confirm you want to accept this quote on behalf of the user.
          </div>
        </Alert>

        <Field
          name="note"
          rows={6}
          component={FormField.Textarea}
          label="Add Details"
          validate={[required]}
          insetLabel
        />

        <table className="table-deposit">
          <thead>
            <tr>
              <th colSpan={2}>Deposit Requirements</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Deposit Percentage</th>
              <td>
                {depositPercentage || 0}%
              </td>
            </tr>
            <tr>
              <th>Deposit Invoice</th>
              <td>
                $
                {numeral(depositInvoice || 0).format('0,0.00')}
              </td>
            </tr>
          </tbody>
        </table>

      </div>

      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          Accept Quote
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'accept-quote-form'
})(AcceptQuoteForm);
