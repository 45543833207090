import React from 'react';
import { Field } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import FormField from '../../../forms/FormField';
import AccordionCard from './AccordionCard';

export default (
  {
    setOpen,
    openTabs
  }
) => (
  <AccordionCard
    controlled
    title="Warehouse Information"
    setOpen={setOpen}
    open={openTabs['warehouseForm']}
    name="warehouseForm"
  >
    <Row>
      <Col md={12}>
        <div>If Applicable:</div>
      </Col>
      <Col md={6}>
        <Field
          type="text"
          name="WhseName"
          label="Warehouse Name:"
          component={FormField.Input}
        />
      </Col>
      <Col md={6}>
        <Field
          type="text"
          name="ReceivingReportNum"
          label="Receiving Report #:"
          component={FormField.Input}
        />
        <Field
          type="text"
          name="WhseDeliveryDate"
          label="Date Received:"
          isUTC
          component={FormField.Datepicker}
        />
      </Col>
    </Row>
  </AccordionCard>
);
