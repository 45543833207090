import React from 'react';
import {
  Table,
  Tooltip,
  OverlayTrigger,
  Alert,
  Overlay
} from 'react-bootstrap';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';

const formatNetCharge = (d, cheapest) => {
  const val = d ? `$${Number(d).toFixed(2)}` : '';
  return d === cheapest ? <span style={{ color: '#dc3545' }}>{val}</span> : val;
};

const sumTotalWeight = result => {
  if (!result) return 0;
  const wieghtsArr = result.map(obj => {
    const mainReducer = (accumulator, curr) => {
      return accumulator + curr._totalWeight;
    };
    const {
      quotes
    } = obj || {};
    return quotes.reduce(mainReducer, 0);
  });
  const weightReducer = (accumulator, curr) => accumulator + curr;
  return wieghtsArr.reduce(weightReducer);
};

const displayNetCharge = ({ netCharges, comments }, cheapest) => {
  if (comments && comments.length && netCharges) {
    return (
      <OverlayTrigger
        trigger="hover"
        placement="top"
        delay={{ show: 500, hide: 0 }}
        overlay={<Tooltip>{comments.join(', ')}</Tooltip>}
      >
        <div>
          <Icon name="exclamation-triangle" />
          {` `}
          {formatNetCharge(netCharges, cheapest)}
        </div>
      </OverlayTrigger>
    );
  }

  if (comments && comments.length) {
    return (
      <OverlayTrigger
        trigger="hover"
        placement="top"
        delay={{ show: 500, hide: 0 }}
        overlay={<Tooltip>{comments.join(', ')}</Tooltip>}
      >
        <div>
          <Icon name="exclamation-triangle" />
        </div>
      </OverlayTrigger>
    );
  }

  return formatNetCharge(netCharges, cheapest);
};

const displayFreightProgram = val => {
  if (val === 0) return <span className="cheapest">Free</span>;
  if (val) return formatNetCharge(val);
};

const FreightResult = ({ result, inProgress, error }) => {
  const quotes = (result || []).reduce((t, p) => {
    const q = p.quotes.map(
      (d, i) =>
        i > 0 ? d : Object.assign({}, d, { groupLength: p.quotes.length })
    );
    return [...t, ...q];
  }, []);
  const total = {
    abf: 0,
    xpo: 0,
    rlc: 0,
    fedex: 0,
    ups: 0,
    pittOhio: 0,
    saia: 0
  };
  let cheapest = 0;
  return (
    <div className="freight-upload-quotes">
      {error && <Alert variant="danger">{error.message}</Alert>}
      {inProgress && <Loading message="Quoting, Please Wait" />}
      {result &&
        <Table id="freight-result" bordered>
          <thead>
            <tr>
              <th rowSpan="2">Ship To</th>
              <th rowSpan="2">MFG</th>
              <th colSpan="2">XPO/Con-way</th>
              <th colSpan="2">R&L</th>
              <th colSpan="2">ABF</th>
              <th colSpan="2">Fedex</th>
              <th colSpan="2">UPS</th>
              {/* <th colSpan="2">Pitt Ohio</th> */}
              <th colSpan="2">Saia</th>
              <th rowSpan="2">Total weight</th>
              <th rowSpan="2">Freight Program</th>
            </tr>
            <tr>
              <th>Charges</th>
              <th>Days</th>
              <th>Charges</th>
              <th>Days</th>
              <th>Charges</th>
              <th>Days</th>
              <th>Charges</th>
              <th>Days</th>
              <th>Charges</th>
              <th>Days</th>
              {/* <th>Charges</th>
              <th>Days</th> */}
              <th>Charges</th>
              <th>Days</th>
            </tr>
          </thead>
          <tbody>
            {quotes.map((row, index) => {
              for (const k of Object.keys(total)) {
                total[k] += Number(row[k].netCharges);
              }
              cheapest += row.cheapest;
              return (
                <tr key={index}>
                  {row.groupLength &&
                    <td rowSpan={row.groupLength}>{row.destinationZip}</td>}
                  <td>{row.vendor.Name}</td>
                  <td className="text-right">
                    {displayNetCharge(row.xpo, row.cheapest)}
                  </td>
                  <td className="text-right">{row.xpo.serviceDays}</td>
                  <td className="text-right">
                    {displayNetCharge(row.rlc, row.cheapest)}
                  </td>
                  <td className="text-right">{row.rlc.serviceDays}</td>
                  <td className="text-right">
                    {displayNetCharge(row.abf, row.cheapest)}
                  </td>
                  <td className="text-right">{row.abf.serviceDays}</td>
                  <td className="text-right">
                    {displayNetCharge(row.fedex, row.cheapest)}
                  </td>
                  <td className="text-right">{row.fedex.serviceDays}</td>
                  <td className="text-right">
                    {displayNetCharge(row.ups, row.cheapest)}
                  </td>
                  <td className="text-right">{row.ups.serviceDays}</td>
                  {/* <td className="text-right">
                    {displayNetCharge(row.pittOhio, row.cheapest)}
                  </td> */}
                  {/* <td className="text-right">{row.pittOhio.serviceDays}</td> */}
                  <td className="text-right">
                    {displayNetCharge(row.saia, row.cheapest)}
                  </td>
                  <td className="text-right">{row.saia.serviceDays}</td>
                  <td>
                    {row._totalWeight}
                  </td>
                  <td>
                    {displayFreightProgram(row.vendor.FreightProgramValue)}
                  </td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan="2">Total:</th>
              <th colSpan="2">{formatNetCharge(total.xpo)}</th>
              <th colSpan="2">{formatNetCharge(total.rlc)}</th>
              <th colSpan="2">{formatNetCharge(total.abf)}</th>
              <th colSpan="2">{formatNetCharge(total.fedex)}</th>
              <th colSpan="2">{formatNetCharge(total.ups)}</th>
              {/* <th colSpan="2">{formatNetCharge(total.pittOhio)}</th> */}
              <th colSpan="2">{formatNetCharge(total.saia)}</th>
              <th colSpan="2">{sumTotalWeight(result)}</th>
            </tr>
            <tr>
              <th colSpan="2">Cheapest Option:</th>
              <th colSpan="13">{formatNetCharge(cheapest, cheapest)}</th>
            </tr>
          </tfoot>
        </Table>}
    </div>
  );
};

export default FreightResult;
