import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const PingOverlay = (
  {
    message,
    placement
  }
) => {
  if (!message) return false;

  const overlay = (
    <Popover id="popover">
      <span style={{ color: '#dc3528' }}>
        {message}
      </span>
    </Popover>
  );

  return (
    <span>
      <OverlayTrigger
        trigger="click"
        placement={placement || 'auto'}
        overlay={overlay}
      >
        <Icon
          name="question-circle"
          style={{ color: 'rgba(0, 0, 0, 0.45) !important', cursor: 'pointer' }}
        />
      </OverlayTrigger>
    </span>
  );
};

export default PingOverlay;
