import React, { Component } from 'react';
import Layout from '../../../../ui/Layout';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';

import Loading from '../../../../ui/Loading';
import ProjectInfo from './../../projects-detail/ProjectInfo';

import ProjectsSendPO from './ProjectsSendPO';
import { actionCreators } from '../../../../../state/modals-dux';
import ProjectBreadcrumbs from '../ProjectBreadcrumbs';

class ProjectsSend extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const {
      match,
      getProjectById,
      getLookups
    } = this.props;
    const { id } = match.params || {};
    getProjectById(id);
    getLookups();
  }
  render() {
    const {
      user,
      result,
      inProgress,
      lookups,
      postResendPurchaseOrder,
      closeModal,
      postCancelResendWarning
    } = this.props;

    const {
      project,
      purchaseOrders,
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      deletedPOs,
      pdfLogs
    } = result || {};
    const { ID, c_SageIntegrationStatus: status } = project || {};

    const allPOs = [
      ...(purchaseOrders || []),
      ...(deletedPOs || [])
    ].sort((a, b) => {
      const o1 = a.isDeleted ? a.purchaseOrder.c_OrderIndex : a.c_OrderIndex;
      const o2 = b.isDeleted ? b.purchaseOrder.c_OrderIndex : b.c_OrderIndex;
      return o1 - o2;
    });

    return (
      <Layout route="projects-update-shipping">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs
              project={project}
              current="Send Purchase Orders"
            />
            <ProjectInfo
              status={status}
              result={result}
              projectId={ID}
              backButton
            />
          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

        {!inProgress && (allPOs || []).map((po, i) => {
            const { isDeleted } = po || {};

            if (isDeleted) {
              return (
                <ProjectsSendPO
                  isDeleted
                  user={user}
                  key={i}
                  projectId={po.purchaseOrder.ProjectId}
                  purchaseOrder={{
                    ...po.purchaseOrder,
                    pdfDetails: po.pdfDetails
                  }}
                  lineItems={po.lineItems}
                  lineItemSpecialInstructions={po.lineItemSpecialInstructions}
                  addresses={po.addresses}
                  lookups={lookups}
                  postResendPurchaseOrder={postResendPurchaseOrder}
                  closeModal={closeModal}
                  postCancelResendWarning={postCancelResendWarning}
                  pdfLogs={po.pdfLogs || []}
                />
              );
            }
            return (
              <ProjectsSendPO
                user={user}
                key={i}
                projectId={ID}
                purchaseOrder={po}
                lineItems={lineItems}
                lineItemSpecialInstructions={lineItemSpecialInstructions}
                addresses={addresses}
                lookups={lookups}
                postResendPurchaseOrder={postResendPurchaseOrder}
                closeModal={closeModal}
                postCancelResendWarning={postCancelResendWarning}
                pdfLogs={(pdfLogs || [])
                  .filter(({ PurchaseOrderID }) => PurchaseOrderID === po.ID)}
              />
            );
          })}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();

  const {
    result: user
  } = state.login.toJS();

  const {
    result: lookups
  } = state.getLookups.toJS();

  return {
    user,
    result,
    lookups,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProjectById: fetchDux.getProjectById.createAction,
      postResendPurchaseOrder: fetchDux.postResendPurchaseOrder.createAction,
      getLookups: fetchDux.getLookups.createAction,
      postCancelResendWarning: fetchDux.postCancelResendWarning.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsSend);
