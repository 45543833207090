import React, { Component } from 'react';
import Layout from '../../../ui/Layout';
import { Breadcrumb } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { Link } from 'react-router-dom';
import ProjectStatusBar from '../projects-detail/ProjectStatusBar';
import ProjectInfo from '../projects-detail/ProjectInfo';
import Loading from '../../../ui/Loading';
import EmailLogs from './EmailLogs';
import ActivityLogs from './ActivityLogs';
import statusDict from '../statusDict';

const queryString = require('query-string');

const getTabClass = (idx, activeTab) =>
  idx === activeTab ? 'tab active' : 'tab';

const ProjectLogsBody = (
  { activeTab, setActiveTab, logs, JobRef, toggleDisable, inProgress }
) => {
  return (
    <div className="project-items">
      <div className="project-items-tabs" style={{ marginBottom: '20px' }}>
        <div className={getTabClass(0, activeTab)} onClick={setActiveTab(0)}>
          Project Activity
        </div>
        <div className={getTabClass(1, activeTab)} onClick={setActiveTab(1)}>
          Email Logs
        </div>
      </div>
      {activeTab === 0 && <ActivityLogs logs={logs} JobRef={JobRef} />}
      {activeTab === 1 &&
        <EmailLogs
          logs={logs}
          JobRef={JobRef}
          toggleDisable={toggleDisable}
          inProgress={inProgress}
        />}
    </div>
  );
};

const getIsProject = c_SageIntegrationStatus => {
  return [
    statusDict.VALIDATED,
    statusDict.SHIPPING_CONFIRMED,
    statusDict.APPROVE_INPROGRESS,
    statusDict.APPROVE_COMPLETE,
    statusDict.INSERT_INPROGRESS
  ].some(x => x === c_SageIntegrationStatus);
};

class ProjectsLogs extends Component {
  componentWillMount() {
    const { history } = this.props;
    const tab = parseInt(queryString.parse(this.props.location.search).tab);
    if (!tab) history.push({ search: `?tab=0` });
  }
  componentDidMount() {
    const {
      match,
      getProjectById,
      getProjectLogsById
    } = this.props;
    const { id } = match.params || {};
    getProjectById(id);
    getProjectLogsById(id);
  }
  render() {
    const {
      result,
      inProgress,
      logs,
      putEmailLogDisable,
      disableInProgress,
      history
    } = this.props;
    const activeTab = parseInt(
      queryString.parse(this.props.location.search).tab
    );

    const { project } = result || {};
    const {
      JobRef,
      c_SageIntegrationStatus: status,
      ID,
      ProjectName
    } = project || {};

    const setActiveTab = idx => () => history.push({ search: `?tab=${idx}` });

    const toggleDisable = payload => putEmailLogDisable({ id: ID, payload });
    const isProject = getIsProject(status);

    return (
      <Layout route="projects-detail">
        <Breadcrumb>
          {isProject &&
            <Link to="/projects" className="breadcrumb-item">
              Projects
            </Link>}
          {!isProject &&
            <Link to="/orders" className="breadcrumb-item">
              Orders
            </Link>}
          <Link to={`/projects/${ID}`} className="breadcrumb-item">
            {ProjectName}
          </Link>
          <Breadcrumb.Item active>Logs</Breadcrumb.Item>
        </Breadcrumb>
        {!inProgress &&
          <div>
            {/* <ProjectStatusBar minimal status={status} projectId={ID} /> */}
            <ProjectInfo
              minimal
              status={status}
              result={result}
              projectId={ID}
            />
            <ProjectLogsBody
              setActiveTab={setActiveTab}
              activeTab={activeTab}
              logs={logs}
              JobRef={JobRef}
              toggleDisable={toggleDisable}
              inProgress={disableInProgress}
            />
          </div>}
        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();

  const {
    result: logs,
    inProgress: getProjectLogsByIdInProgress,
    error
  } = state.getProjectLogsById.toJS();

  const {
    inProgress: putEmailLogDisableInProgress
  } = state.putEmailLogDisable.toJS();

  const inProgress = getProjectByIdInProgress || getProjectLogsByIdInProgress;

  return {
    result,
    inProgress,
    logs,
    disableInProgress: putEmailLogDisableInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectById: fetchDux.getProjectById.createAction,
      getProjectLogsById: fetchDux.getProjectLogsById.createAction,
      putEmailLogDisable: fetchDux.putEmailLogDisable.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsLogs);
