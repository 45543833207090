import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import { formValueSelector } from 'redux-form';
import UploadCustomersDocumentForm from './UploadCustomersDocumentForm';
import fetchDux from '../../../../state/fetch-dux';
class ModalCustomersUploadDocuments extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      uploadCompanyDocuments
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-alt"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <UploadCustomersDocumentForm
          onSubmit={uploadCompanyDocuments}
        />
      </Modal>
    );
  }

}

const selector = formValueSelector('customers-upload-document-form');
const mapState = state => {
  const csv = selector(state, 'csv');
  return {
    csv,
    openModal: state.modals
  };
};
const mapDispatch = dispatch => bindActionCreators(
  {
    ...actionCreators
  },
  dispatch);
export default connect(mapState, mapDispatch)(ModalCustomersUploadDocuments);
