import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown } from 'react-bootstrap';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Table from '../../../ui/Table';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCustomersEditLocation
  from '../customers-locations/ModalEditLocation';
import ModalCustomersAddUser from '../customers-users/ModalAddUser';
import ModalCustomersDeleteLocation
  from '../customers-locations/ModalDeleteLocation';
import Notification from '../../../ui/Notification';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '20',
  sortBy: sortBy || 'nameFirst',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

const columns = [
  {
    Header: 'First Name',
    accessor: 'nameFirst',
    Cell: ({ value, original }) => (
      <Link to={`/customers/users/${original.id}`}>{value}</Link>
    )
  },
  {
    Header: 'Last Name',
    accessor: 'nameLast'
  },
  {
    Header: 'Email Address',
    accessor: 'email'
  },
  {
    Header: 'Concepts',
    accessor: 'companies',
    width: 300,
    Cell: ({ value }) => {
      return (
        <div
          style={{
            overflow: 'hidden',
            paddingTop: '0.2rem',
            paddingBottom: '0.3rem'
          }}
        >
          {(value || []).map(({ name }, index) => {
            return (
              <span key={index} className="table-label">
                {name}
              </span>
            );
          })}
        </div>
      );
    }
  },
  {
    Header: 'Quotes',
    accessor: 'quotes',
    width: 300,
    Cell: ({ value }) => {
      return (
        <div
          style={{
            overflow: 'hidden',
            paddingTop: '0.2rem',
            paddingBottom: '0.3rem'
          }}
        >
          {(value || []).map((name, index) => {
            return (
              <span key={index} className="table-label">
                {name}
              </span>
            );
          })}
        </div>
      );
    }
  }
];

class CustomersLocationsUsers extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const {
      match,
      getLocationById,
      searchCustomersCompanies,
      getCompanyById
    } = this.props;
    const { id } = match.params || {};
    getLocationById({ id, query: {} }, null, true, result => {
      const {
        company
      } = result || {};

      const {
        companyID
      } = company || {};

      getCompanyById({ id: companyID, query: { getUsers: 1 } });
    });
    searchCustomersCompanies();
  }
  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      error,
      result,
      showModal,
      match,
      getCompanyResults,
      history,
      getCompanyByIdResult,
      getCompanyByIdInProgress,
      location: { search: searchURL },
      getLocationByIdError,
      getCompanyByIdError,
      searchCustomersCompaniesError,
      clearGetLocationById,
      clearGetCompanyById,
      clearSearchCustomersCompanies
    } = this.props;

    const {
      location,
      company,
      users
    } = result || {};

    const {
      name
    } = location || {};

    const {
      companyID
    } = company || {};

    const { id: locationId } = match.params || {};

    const {
      companyUsers
    } = getCompanyByIdResult || {};

    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    return (
      <Layout route="customers-locations-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/locations" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h3>
                Location
              </h3>
              <h2>
                {name || 'N/A'}
              </h2>
            </div>
            <div>
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalCustomersLocationAddUser')}
                disabled={!getCompanyByIdResult || getCompanyByIdInProgress}
              >
                Manage Users
              </Button>
              {' '}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  <Icon fixedWidth name="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersLocationEdit')}
                  >
                    Edit Location
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersLocationDelete')}
                  >
                    Delete Location
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/locations/${locationId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/project-tracker`}>
                  Project Tracker
                </Link>
              </li>
              <li className="active">
                <Link to={`/customers/locations/${locationId}/users`}>
                  Users
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {!inProgress &&
          <Container>

            <Table
              showSearch
              data={users}
              noDataText="No users found"
              columns={columns}
              search={search}
              onFetchData={this.handleTableChange}
              onSearchChange={this.handleSearchChange}
              defaultSorted={sorted}
              page={error ? 0 : Number(page)}
              defaultPageSize={Number(pageSize)}
              inProgress={inProgress}
            />

          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCustomersEditLocation
          name="modalCustomersLocationEdit"
          location={location}
          getCompanyResults={getCompanyResults}
          companyID={companyID}
        />
        {users &&
          <ModalCustomersAddUser
            name="modalCustomersLocationAddUser"
            companyUsers={companyUsers}
            currentUsers={users}
            entity={location}
          />}
        <ModalCustomersDeleteLocation
          name="modalCustomersLocationDelete"
          location={location}
          history={history}
        />
        {getLocationByIdError &&
          <Notification
            key="location-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetLocationById}
          >
            Failed to load location users. Please refresh the page.
          </Notification>}
        {getCompanyByIdError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyById}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {searchCustomersCompaniesError &&
          <Notification
            key="company-users-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getLocationByIdInProgress,
    error: getLocationByIdError
  } = state.getLocationById.toJS();

  const {
    result: getCompanyByIdResult,
    inProgress: getCompanyByIdInProgress,
    error: getCompanyByIdError
  } = state.getCompanyById.toJS();

  const {
    result: getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  return {
    result,
    getCompanyResults,
    getCompanyByIdResult,
    getCompanyByIdInProgress,
    inProgress: getLocationByIdInProgress,
    getLocationByIdError,
    getCompanyByIdError,
    searchCustomersCompaniesError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getLocationById: fetchDux.getLocationById.createAction,
      clearGetLocationById: fetchDux.getLocationById.clearAction,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersLocationsUsers);
