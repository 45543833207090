import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import CustomersCompanies from './customers-companies/CustomersCompanies.js';
import CustomersCompaniesUsers
  from './customers-companies-detail/CustomersCompaniesUsers.js';
import CustomersCompaniesProducts
  from './customers-companies-detail/CustomersCompaniesProducts.js';
import CustomersCompaniesPackages
  from './customers-companies-detail/CustomersCompaniesPackages.js';
import CustomersCompaniesLocations
  from './customers-companies-detail/CustomersCompaniesLocations.js';
import CustomersCompaniesDocuments
  from './customers-companies-detail/CustomersCompaniesDocuments.js';
import CustomersCompaniesDetails
  from './customers-companies-detail/CustomersCompaniesDetails.js';

import CustomersUsers from './customers-users/CustomersUsers.js';
import CustomersUsersDetail
  from './customers-users-detail/CustomersUsersDetail.js';
import CustomersUsersConcepts
  from './customers-users-detail/CustomersUsersConcepts.js';
import CustomersUsersLocations
  from './customers-users-detail/CustomersUsersLocations.js';

import CustomersLocations from './customers-locations/CustomersLocations.js';
import CustomersLocationsDetail
  from './customers-locations-detail/CustomersLocationsDetail.js';
import CustomersLocationsProjectTracker
  from './customers-locations-detail/CustomersLocationsProjectTracker.js';
import CustomersLocationsUsers
  from './customers-locations-detail/CustomersLocationsUsers.js';
import CustomersLocationsDocuments
  from './customers-locations-detail/CustomersLocationsDocuments.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={CustomersCompanies} />
    <Route
      exact
      path={`${match.url}/concepts`}
      component={CustomersCompanies}
    />
    <Route
      exact
      path={`${match.url}/concepts/:id`}
      component={CustomersCompaniesDetails}
    />
    <Route
      exact
      path={`${match.url}/concepts/:id/details`}
      component={CustomersCompaniesDetails}
    />
    <Route
      exact
      path={`${match.url}/concepts/:id/users`}
      component={CustomersCompaniesUsers}
    />
    <Route
      exact
      path={`${match.url}/concepts/:id/products`}
      component={CustomersCompaniesProducts}
    />
    <Route
      exact
      path={`${match.url}/concepts/:id/packages`}
      component={CustomersCompaniesPackages}
    />
    <Route
      exact
      path={`${match.url}/concepts/:id/locations`}
      component={CustomersCompaniesLocations}
    />
    <Route
      exact
      path={`${match.url}/concepts/:id/documents`}
      component={CustomersCompaniesDocuments}
    />
    <Route exact path={`${match.url}/users`} component={CustomersUsers} />
    <Route
      exact
      path={`${match.url}/users/:id`}
      component={CustomersUsersDetail}
    />
    <Route
      exact
      path={`${match.url}/users/:id/details`}
      component={CustomersUsersDetail}
    />
    <Route
      exact
      path={`${match.url}/users/:id/concepts`}
      component={CustomersUsersConcepts}
    />
    <Route
      exact
      path={`${match.url}/users/:id/locations`}
      component={CustomersUsersLocations}
    />
    <Route
      exact
      path={`${match.url}/locations`}
      component={CustomersLocations}
    />
    <Route
      exact
      path={`${match.url}/locations/:id`}
      component={CustomersLocationsDetail}
    />
    <Route
      exact
      path={`${match.url}/locations/:id/details`}
      component={CustomersLocationsDetail}
    />
    <Route
      exact
      path={`${match.url}/locations/:id/project-tracker`}
      component={CustomersLocationsProjectTracker}
    />
    <Route
      exact
      path={`${match.url}/locations/:id/users`}
      component={CustomersLocationsUsers}
    />
    <Route
      exact
      path={`${match.url}/locations/:id/documents`}
      component={CustomersLocationsDocuments}
    />
    <Redirect to={`${match.url}`} />
  </Switch>
);
