import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const TemplateTrackerForm = props => {
  const {
    handleSubmit,
    handleTemplateChange
  } = props;

  const options = [
    {
      label: 'Select a Template...',
      value: '',
      isDisabled: true
    },
    {
      label: 'Default Location Tracker',
      value: [
        {
          comment: '',
          displayOrder: 0,
          status: 'Pending',
          title: 'Phase One'
        },
        {
          comment: '',
          displayOrder: 1,
          status: 'Pending',
          title: 'Phase Two'
        },
        {
          comment: '',
          displayOrder: 3,
          status: 'Pending',
          title: 'Phase Three'
        }
      ]
    },
    {
      label: '5 Step Location Tracker',
      value: [
        {
          comment: '',
          displayOrder: 0,
          status: 'Pending',
          title: 'Phase One'
        },
        {
          comment: '',
          displayOrder: 1,
          status: 'Pending',
          title: 'Phase Two'
        },
        {
          comment: '',
          displayOrder: 2,
          status: 'Pending',
          title: 'Phase Three'
        },
        {
          comment: '',
          displayOrder: 3,
          status: 'Pending',
          title: 'Phase Four'
        },
        {
          comment: '',
          displayOrder: 4,
          status: 'Pending',
          title: 'Phase Five'
        }
      ]
    },
    {
      label: 'Template1',
      value: [
        {
          displayOrder: 0,
          title: 'Plans Received',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 1,
          title: 'Quote sent out',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 2,
          title: 'Deposit and Required  Documents Received.',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 3,
          title: 'Shop drawing Received and Approved',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 4,
          title: 'Critical Dimensions Received',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 5,
          title: 'Early required items delivered to site',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 6,
          title: 'Equipment in transit',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 7,
          title: 'Site Readiness Confirmed and Final payment received.',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 8,
          title: 'Install Date Schedule and Locked',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 9,
          title: 'Delivery Complete',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 10,
          title: 'All Deliveries, Start up and Punch List Complete',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 11,
          title: 'ASA Information Sent Out',
          status: 'Pending',
          comment: ''
        }
      ]
    },
    {
      label: 'Template2',
      value: [
        {
          displayOrder: 0,
          title: 'Proposal Sent, Under Review',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 1,
          title: 'Contract Sent, Awaiting DocuSign',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 2,
          title: 'Contract Received, Awaiting Deposit',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 3,
          title: 'Deposit Received, Pending Order',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 4,
          title: 'FF&E Ordered/In Production',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 5,
          title: 'Custom Item Approvals Sent',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 6,
          title: 'Custom Item Approvals Received',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 7,
          title: 'Custom Items In Production',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 8,
          title: 'Custom Items Shipped',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 9,
          title: 'FF&E Shipped',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 10,
          title: 'FF&E Received In Market, Ready for Install',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 11,
          title: 'FF&E Installed, Punch List Pending',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 12,
          title: 'FF&E Complete',
          status: 'Pending',
          comment: ''
        }
      ]
    },
    {
      label: 'Template3',
      value: [
        {
          displayOrder: 0,
          title: 'Kick-off call',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 1,
          title: 'Send Concept Services CAD files',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 2,
          title: 'Concept Services create 3D layout',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 3,
          title: 'Concept Services create signage/exterior renderings',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 4,
          title: '3D layout accepted',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 5,
          title: 'Begin FS Sheets',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 6,
          title: 'Contract Signed',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 7,
          title: 'Deposit Received',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 8,
          title: 'Order Smallwares',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 9,
          title: 'Pre-construction meeting',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 10,
          title: 'Received Construction Schedule',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 11,
          title: 'Shop Drawings Received',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 12,
          title: 'Walk-in installation',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 13,
          title: 'Equipment installation',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 14,
          title: 'Millwork installation',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 15,
          title: 'Furniture installation',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 16,
          title: 'Complete contractor punch list',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 17,
          title: 'Receive Smallwares',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 18,
          title: 'Soft Opening',
          status: 'Pending',
          comment: ''
        },
        {
          displayOrder: 19,
          title: 'Official Grand Opening / Ribbon Cutting',
          status: 'Pending',
          comment: ''
        }
      ]
    }
  ];

  return (
    <form onSubmit={handleSubmit} className="single-form-control">

      <Field
        name="searchTemplates"
        type="text"
        component={FormField.SelectReact}
        label="Templates"
        onChange={handleTemplateChange}
        options={options}
        isClearable
      />

    </form>
  );
};

export default reduxForm({
  form: 'tracker-templates-location-form'
})(TemplateTrackerForm);
