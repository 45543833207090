import React, { Component } from 'react';
import { Row, Col, ProgressBar } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import ItemsTable from './ItemsTable';
import statusDict from '../statusDict';
import { actionCreators } from '../../../../state/modals-dux';
import ModalSageImportErrors from './ModalSageImportErrors';

const InsertionStatus = ({ name, completedCount, totalCount }) => {
  return (
    <div className="insertion-status">
      <h4>{name}</h4>
      <div className="countdown">{completedCount} of {totalCount}</div>
      <ProgressBar now={completedCount / totalCount * 100} />
    </div>
  );
};

class ProjectInsertionStatus extends Component {
  constructor(props) {
    super(props);
    this.timeout = null;
    this.pingProjectImportStatus = this.pingProjectImportStatus.bind(this);
    this.state = { itemsSearch: '' };
    this.warned = false;
  }
  componentDidMount() {
    const {
      result,
      getProjectImportStatus,
      clearGetProjectImportStatus
    } = this.props;
    const { project } = result || {};
    const { ID } = project || {};
    clearGetProjectImportStatus();
    getProjectImportStatus(ID);

    this.pingProjectImportStatus();
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.props.clearGetProjectImportStatus();
  }
  componentDidUpdate() {
    const { result } = this.props;
    const { project, importToSageErrors } = result || {};
    const {
      c_SageIntegrationStatus
    } = project || {};

    if (c_SageIntegrationStatus < statusDict.INSERT_COMPLETE) return;

    const { hasErrors } = importToSageErrors || {};

    if (!hasErrors || this.warned) return;

    this.warned = true;
    this.props.showModal('SageImportErrorsModal');
  }
  pingProjectImportStatus() {
    const {
      result,
      getProjectImportStatus,
      ping
    } = this.props;
    if (!ping) return;
    const { project } = result || {};
    const { ID } = project || {};
    this.timeout = setTimeout(
      () => {
        getProjectImportStatus(ID, null, null, data => {
          if (!data) return;
          const { projectStatus } = data || {};
          if ((projectStatus || {}).completedCount === 1)
            this.props.getProjectById(ID);
        });
        this.pingProjectImportStatus();
      },
      500
    );
  }
  render() {
    const {
      projectImportResult,
      result
    } = this.props;

    const { itemsSearch } = this.state;

    const {
      lineItemStatus,
      purchaseOrderStatus
    } = projectImportResult || {};

    const {
      results
    } = lineItemStatus || {};

    const handleSearchChange = (key, value) => {
      this.setState({ itemsSearch: value });
    };

    return (
      <div className="project-insertion-status">
        <ModalSageImportErrors name="SageImportErrorsModal" result={result} />
        <Row style={{ marginBottom: '20px' }}>
          <Col>
            <InsertionStatus
              {...lineItemStatus}
              name="Order Items Insertion Status"
            />
          </Col>
          <Col>
            <InsertionStatus
              {...purchaseOrderStatus}
              name="Purchase Order Creation Status"
            />
          </Col>
          <Col />
        </Row>
        <ItemsTable
          data={results}
          handleSearchChange={handleSearchChange}
          search={itemsSearch}
          show
          showImportStatus
        />
      </div>
    );
  }
}

const mapState = state => {
  const {
    error,
    inProgress,
    result: projectImportResult
  } = state.getProjectImportStatus.toJS();
  return {
    error,
    inProgress,
    projectImportResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProjectById: fetchDux.getProjectById.createAction,
      getProjectImportStatus: fetchDux.getProjectImportStatus.createAction,
      clearGetProjectImportStatus: fetchDux.getProjectImportStatus.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectInsertionStatus);
