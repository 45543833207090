import React from 'react';
import numeral from 'numeral';
import { Row, Col } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { formatMoney } from '../quotes-edit/ProposalSummaryForm';

const convertToRoman = num => {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let str = '';
  for (let i of Object.keys(roman)) {
    const q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};

const QuoteItems = ({ items = [] }) => (
  <div className="items">
    {items.map((item, index) => (
      <div
        className={item.IsOptional ? 'item has-options' : 'item'}
        key={index}
      >
        <strong>
          {/* <Icon name="check-square" /> */}
          {item.isTaxable ? item.LineItemName : `${item.LineItemName} (tax-exempt)`}
        </strong>
        <div style={{
          display: 'flex',
          flex: 0.3,
          justifyContent: 'space-between'
        }}>
          <div style={{ display: 'flex', opacity: 0.8, width:'max-content'}}>
            <span style={{ marginRight: '20px' }}>
              <Icon name={item.IsOptional ? 'check-square' : 'square'} />
              Optional
            </span>
            <span style={{ marginRight: '20px' }}>
              <Icon name={item.Selected ? 'check-square' : 'square'} />
              Selected
            </span>

            <span style={{ marginRight: '20px' }}>
              <Icon name={item.isTaxable ? 'check-square' : 'square'} />
              Taxable
            </span>
          </div>

          <div className="price" style={{width:'100px'}}>
            {item.PriceNonNumeric
              ? item.PriceNonNumeric
              : formatMoney(item.Price)}
          </div>
        </div>


      </div>
    ))}
  </div>
);

const ProposalSummary = ({ data }) => {
  const { quoteSections = [], SubTotal, Deposit, SalesTax, taxableSubTotal, TaxToBeDetermined } = data || {};
  const tax = TaxToBeDetermined ? 0 : taxableSubTotal * (SalesTax / 100);
  const grandTotal = SubTotal + tax;
  const depositInvoice = grandTotal * (Deposit / 100);
  return (
    <div className="quote-proposal-summary">
      <div className="sections">
        {quoteSections.map((section, index) => (
          <div className="section" key={index}>
            <strong>{convertToRoman(index + 1)}. {section.SectionName}</strong>
            <div className="categories">
              {(section.quoteCategories || []).map((category, index) => (
                <div className="category" key={index}>
                  <strong>{category.CategoryName}</strong>
                  <QuoteItems items={category.quoteItems} />
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <Row className="row-totals">
        <Col />
        <Col>
          <table className="table-total">
            <thead>
              <tr>
                <th colSpan={2}>Deposit Requirements</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Deposit Percentage</th>
                <td>{Deposit || 0}%</td>
              </tr>
              <tr>
                <th>Deposit Invoice</th>
                <td>{formatMoney(depositInvoice)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col>
          <table className="table-total">
            <tbody>
              <tr>
                <th>Services</th>
                <td>{formatMoney(data.Services)}</td>
              </tr>
              <tr>
                <th>Material Goods</th>
                <td>{formatMoney(data.MaterialGoods)}</td>
              </tr>
              <tr>
                <th>Subtotal</th>
                <td>{formatMoney(SubTotal)}</td>
              </tr>
              <tr>
                <th>
                  {
                    TaxToBeDetermined ?
                      'Tax to be determined' :
                      'Sales Tax'
                  }

                </th>
                {!TaxToBeDetermined &&
                  <td>
                    ({SalesTax || 0}%) {formatMoney(tax)}
                  </td>
                }

              </tr>
              <tr>
                <th>Grand Total</th>
                <td>{formatMoney(grandTotal)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default ProposalSummary;
