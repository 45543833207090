import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM.DD.YYYY');
};

const FileDetail = ({ input, valid }) => {
  const { value } = input || {};
  return (
    <div>
      <h5>Filename:</h5>
      <div>
        {value.name || ''}
        &nbsp;
        {' '}
        {valid && <Icon style={{ color: '#28a745' }} name="check" />}
      </div>
    </div>
  );
};

const validateFile = value => {
  const { name } = value || {};
  if (!value || !name) return 'Required';
  return undefined;
};

const UploadCustomersDocumentForm = props => {
  const { handleSubmit, submitting, error, valid, closeModal } = props;
  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        {error &&
          <Alert variant="danger">
            <h5>Failed to upload document</h5>
            <div>{error.message || ''}</div>
          </Alert>}

        <Row>
          <Col md={7}>
            {!submitting &&
              <div>
                <Field
                  disabled={submitting}
                  name="csv"
                  component={FormField.InputFileDropzone}
                />
              </div>}
            {submitting &&
              <Loading fullPage message="Uploading, Please Wait..." />}
          </Col>
          <Col md={5}>
            <div className="file-info" style={{ fontSize: '30px' }}>
              <Field
                name="csv"
                component={FileDetail}
                validate={[validateFile]}
                valid={valid}
              />
            </div>
          </Col>
        </Row>

      </div>

      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={!valid || submitting}>
          Save
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'customers-upload-document-form'
})(UploadCustomersDocumentForm);
