import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import EditQuoteStatusForm from './EditQuoteStatusForm';



const ModalEditStatus = (
    {
        openModal,
        name,
        closeModal,
        inProgress,
        data,
        editQuoteStatus
    }
) => {

    const { Status, Id } = data;
    const initialValues = { status: Status }

    return (
        <Modal
            show={openModal === name}
            onHide={() => {
                if (!inProgress) closeModal()
            }}
            size="md"
        >
            <Modal.Header>
                <Modal.Title>
                    Edit Quote Status
                </Modal.Title>
            </Modal.Header>

            <EditQuoteStatusForm
                initialValues={initialValues}
                closeModal={closeModal}
                onSubmit={
                    (values) => {
                        const { status } = values;
                        if (status !== Status) {
                            const payload = { id: Id, status: status.toString().trim().length === 0 ? 0 : status };
                            editQuoteStatus(payload, 'EditQuoteStatusForm', null, () => {
                                closeModal();
                            })
                        }
                    }
                }
            />

        </Modal>
    )
}

const mapState = state => {

    const {
        inProgress
    } = state.editQuoteStatus.toJS();
    return {
        openModal: state.modals,
        inProgress
    };
};
const mapDispatch = dispatch =>
    bindActionCreators(
        {
            ...actionCreators,
            editQuoteStatus: fetchDux.editQuoteStatus.createAction
        }
        , dispatch);

export default connect(mapState, mapDispatch)(ModalEditStatus);
