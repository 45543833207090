import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import CouponsSearch from './coupons-search/CouponsSearch';



export default ({ match }) => {
    return (
        <Switch>
            <Route exact path={`${match.url}`} component={CouponsSearch} />
            <Redirect to={`${match.url}`} />
        </Switch>
    )
}