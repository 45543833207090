import React from 'react';
import { reduxForm, Field } from 'redux-form';
import { Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import paymentOptionDict from '../paymentOptionDict';
import moment from "moment";

const required = value => value ? undefined : 'Required';

const formatTime = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MMM D, Y');
};

const ProcessEcommerceOrdersForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        {
          result.map((data, index) => {
            if (data.selected) {
              return (
                <table key={index} className='table-data'>
                  <thead>
                    <tr>
                      <th style={{ minWidth: '220px' }}>Order Number</th>
                      <td style={{ textAlign: 'left', border: 'none', minWidth: '215px'}}>
                        {data.JobRef}
                      </td>
                    </tr>

                    <tr>
                      <th>Payment Method</th>
                      <td style={{ textAlign: 'left', border: 'none' }}>
                        {paymentOptionDict[data.PaymentOption]}
                      </td>
                    </tr>
                    {data.PaymentOption === 2 &&
                      (
                        <>
                          <tr>
                            <th>Name on Check</th>
                            <td style={{ textAlign: 'left', border: 'none' }}>
                              {data.BusinessNameOnCheck}
                            </td>
                          </tr>

                          <tr>
                            <th>Check Number</th>
                            <td style={{ textAlign: 'left', border: 'none' }}>
                              {data.CheckNumber}
                            </td>
                          </tr>
                        </>
                      )
                    }
                    {
                      data.PaymentOption === 3 &&
                      (
                        <>
                          <tr>
                            <th>Bank Name</th>
                            <td style={{ textAlign: 'left', border: 'none' }}>
                              {data.BankName}
                            </td>
                          </tr>

                          <tr>
                            <th>Check Number</th>
                            <td style={{ textAlign: 'left', border: 'none'}}>
                              {data.NameOnAccount}
                            </td>
                          </tr>
                        </>
                      )
                    }

                    {
                      data.PaymentOption === 4 &&
                      (
                        <>
                          <tr>
                            <th>Po Number</th>
                            <td style={{ textAlign: 'left', border: 'none' }}>
                              {data.PurchaseOrderNumber}
                            </td>
                          </tr>

                          <tr>
                            <th>Po Date</th>
                            <td style={{ textAlign: 'left', border: 'none'}}>
                              {formatTime(data.PurchaseOrderDate)}
                            </td>
                          </tr>

                          <tr>
                            <th>Po Approved By</th>
                            <td style={{ textAlign: 'left', border: 'none' }}>
                              {data.PurchaseOrderApprovedBy}
                            </td>
                          </tr>
                        </>
                      )
                    }

                  </thead>
                </table>
              )
            }
          })
        }
        {/* <table className='table-data'>
          <thead>
            <tr>
              <th>Order Number</th>
              <th>Payment Method</th>
              <th>Payment Value</th>
            </tr>
          </thead>
          <tbody>
            {
              result.map((data, index) => {
                if (data.selected) {
                  return (
                    <tr key={index}>
                      <td style={{ textAlign: 'left', border: 'none' }}>
                        {data.JobRef}
                      </td>
                      <td style={{ textAlign: 'left', border: 'none' }}>
                        {paymentOptionDict[data.PaymentOption]}
                      </td>

                      <td style={{ textAlign: 'left', border: 'none' }}>
                        {data.PaymentOption === 2 && data.CheckNumber}
                        {data.PaymentOption === 3 && data.NameOnAccount}
                        {data.PaymentOption === 4 && data.PurchaseOrderNumber}
                      </td>
                    </tr>
                  )
                }
              })
            }
          </tbody>
        </table> */}
        {/* <Field
          required
          name="checkNumber"
          type="text"
          component={FormField.Input}
          label="Check Number"
          validate={[required]}
          insetLabel
        />

        <Field
          name="details"
          type="text"
          component={FormField.Input}
          label="Add Details"
          insetLabel
        /> */}
      </div>



      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting || !result.some((data) => data.selected === true)}>
          Process
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'process-ecommerce-orders-form'
})(ProcessEcommerceOrdersForm);
