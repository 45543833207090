import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, Form } from 'react-bootstrap';
import FormField from '../../../forms/FormField';
import AccordionCard from './AccordionCard';

export default (
  {
    carrierUpdateDateValue,
    carriers,
    setOpen,
    openTabs,
    getCarrierByName,
    clearGetCarrierByName,
    carriersSearchResults,
    change
  }
) => {
  const carrierOptions =
    carriersSearchResults ?
      (carriersSearchResults || []).map((carrier) => {
        const { courier_name, courier_code } = carrier;
        return {
          label: courier_name,
          value: courier_code
        }
      })
      :
      (carriers || []).sort(({ value: a }, { value: b }) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      });
  return (
    <AccordionCard
      controlled
      title="Carrier Information"
      setOpen={setOpen}
      open={openTabs['carrierForm']}
      name="carrierForm"
    >
      {/* <Row>
        <Col md={6}>
          <Form.Label>Date of Last Carrier Update:</Form.Label>
        </Col>
        <Col md={6}>{carrierUpdateDateValue}</Col>
      </Row> */}
      {/* Carrier */}
      <Row>
        <Col md={6}>
          <Field
            type="text"
            name="Carrier"
            label="Carrier"
            component={FormField.SelectWithSearch}
            options={carrierOptions}
            onSearchChange={
              (value) => {
                if (value.trim().length >= 3) {
                  getCarrierByName({ carrierName: value });
                } else {
                  clearGetCarrierByName();
                }
              }
            }
          />
        </Col>
        <Col md={6}>
          <Field
            type="text"
            name="TrackingNum"
            label="Tracking Number:"
            component={FormField.Input}
            normalize={(value, previousValue) => {
              if (value.indexOf('/') >= 0 || value.indexOf('\\') >= 0)
                return previousValue;
              return value;
            }}
          />
          {/*
            <Button
              variant="secondary"
              size="sm"
              onClick={handleWebTrackClick}
              disabled={!showWebTrack}
            >
              WebTrack
            </Button>
          */}
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            type="text"
            name="SchdDeliveryDate"
            label="Estimated Delivery Date"
            isUTC
            component={FormField.Datepicker}
          />
        </Col>
        <Col md={6}>

          <Field
            type="text"
            name="CarrierFreightCharge"
            label="Freight Charge"
            component={FormField.Input}
          />

          <Row>
            <Col md={6}>
              <Field
                type="text"
                name="NumCartons"
                label="# of Pieces"
                component={FormField.Input}
              />
            </Col>
            <Col md={6}>
              <Field
                type="text"
                name="ShipWeight"
                label="Weight"
                component={FormField.Input}
                onChange={(e) => {
                  change('CarrierShipWeight', e.target.value)
                }}
              />
            </Col>
          </Row>

        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Field
            type="text"
            name="CarrierDeliveryDate"
            label="Actual Delivery Date:"
            isUTC
            component={FormField.Datepicker}
          />
        </Col>
        <Col md={6}>

          <Field
            type="text"
            name="SignedForBy"
            label="Signed By"
            component={FormField.Input}
          />
          <Field
            type="text"
            name="CarrierDamage"
            label="Damage/Shortage"
            component={FormField.Input}
          />

        </Col>
      </Row>
    </AccordionCard>
  );
};
