import React, { Component } from 'react';
import FinalizeOrderForm from './FinalizeOrderForm';
import MiscLineItemsForm from './MiscLineItemsForm';
import { Row, Col, Table, Button, Alert } from 'react-bootstrap';
import numeral from 'numeral';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { isValid, isSubmitting, submit, reset } from 'redux-form';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import { Redirect } from 'react-router-dom';
import Notification from '../../../ui/Notification';

const formatMoney = value => numeral(value).format('0,0.00');

const getInitialValues = isGLOnly => {
  return {
    TaxGroup: 'AVATAX',
    addressType: isGLOnly ? 'other' : 'jobsite'
  };
};

const getProjectAddress = ({ project, addresses }) => {
  const { ProjectAddressId } = project || {};
  return (addresses || [])
    .find(({ AddressId }) => AddressId === ProjectAddressId);
};

const getAdditionalTotalLines = additionalTotals => {
  return (additionalTotals || []).filter(({ Sell }) => Sell > 0);
};

const AdditionalTotals = (
  {
    total,
    additionalTotals,
    handleDeleteAdditionalTotal,
    deletingAdditionalTotalId,
    deleteAdditionalTotalInProgress
  }
) => {
  const lines = getAdditionalTotalLines(additionalTotals || []);

  return (
    <Row>
      <Col sm={12}>
        <Table striped>
          <tbody>
            {(lines || []).map(({
              ID,
              FieldName,
              IsTaxable,
              Sell,
              c_IsCustom
            }) => {
              const showSpinner = deletingAdditionalTotalId === ID &&
                deleteAdditionalTotalInProgress;
              return (
                <tr>
                  <td>{FieldName}</td>
                  <td>{IsTaxable ? 'TAXABLE' : 'NOT TAXABLE'}</td>
                  <td style={{ textAlign: 'right' }}>
                    ${formatMoney(Sell)}
                  </td>
                  <td
                    style={{ textAlign: 'right' }}
                    className={
                      deleteAdditionalTotalInProgress
                        ? 'text-muted'
                        : 'text-danger'
                    }
                  >
                    {!showSpinner &&
                      <Icon
                        name="minus-circle"
                        style={{
                          cursor: deleteAdditionalTotalInProgress
                            ? 'wait'
                            : 'pointer'
                        }}
                        onClick={() =>
                          deleteAdditionalTotalInProgress
                            ? null
                            : handleDeleteAdditionalTotal(ID)}
                      />}
                    {showSpinner &&
                      <div className="text-muted">
                        <Icon name="sync" spin />
                      </div>}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

class FinalizeOrder extends Component {
  constructor(props) {
    super(props);
    this.state = { deletingAdditionalTotalId: null };
    this.pingProjectTaxStatus = this.pingProjectTaxStatus.bind(this);
  }
  componentDidMount() {
    const {
      result,
      getProjectTaxStatus,
      clearGetProjectTaxStatus
    } = this.props;
    const { project } = result || {};
    const { ID } = project || {};
    clearGetProjectTaxStatus();
    getProjectTaxStatus(ID);

    this.pingProjectTaxStatus();
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.props.clearGetProjectTaxStatus();
    this.props.clearPostFinalizeProject();
  }
  componentDidUpdate(prevProps) {
    const { ping } = this.props;
    const { ping: ping2 } = prevProps || {};
    if (!ping || ping === ping2) return;
    this.pingProjectTaxStatus();
  }
  pingProjectTaxStatus() {
    const {
      result,
      getProjectTaxStatus,
      ping
    } = this.props;
    if (!ping) return;
    const { project } = result || {};
    const { ID } = project || {};
    this.timeout = setTimeout(
      () => {
        getProjectTaxStatus(ID, null, null, data => {
          if (!data) return;
          const { c_Sage_TaxStatus } = data || {};
          if (c_Sage_TaxStatus !== null) this.props.getProjectById(ID);
        });
        this.pingProjectTaxStatus();
      },
      500
    );
  }
  render() {
    const {
      ping,
      result,
      postAdditionalTotal,
      submitFinalForm,
      isFormValid,
      isFormSubmitting,
      postFinalizeProject,
      deleteAdditionalTotal,
      deleteAdditionalTotalInProgress,
      resetAdditionalTotalsForm,
      isGLOnly,
      error,
      clearPostFinalizeProject
    } = this.props;

    const { deletingAdditionalTotalId, redirectTo } = this.state;

    const { project, total, additionalTotals } = result || {};

    const { ID: ProjectID } = project || {};

    const initialValues = getInitialValues(isGLOnly);

    const projectAddress = getProjectAddress(result || {});

    const handleMiscLineItemsSubmit = payload =>
      postAdditionalTotal(
        {
          id: ProjectID,
          payload
        },
        'misc-line-items-form',
        null,
        resetAdditionalTotalsForm
      );

    const handleFinalizeSubmit = ({ addressType, TaxGroup, ...address }) => {
      const payload = addressType === 'jobsite'
        ? { ...projectAddress, TaxGroup }
        : { ...address, TaxGroup, isGLOnly };
      postFinalizeProject(
        { id: ProjectID, payload },
        'finalize-order-form',
        null,
        result => {
          if (isGLOnly) {
            const { project: { ID } } = result;
            this.setState({ redirectTo: ID });
          }
        }
      );
    };

    const handleDeleteAdditionalTotal = id => {
      this.setState({ deletingAdditionalTotalId: id });
      deleteAdditionalTotal({ id, projectId: ProjectID }, null, null, () => {
        this.setState({ deletingAdditionalTotalId: null });
      });
    };

    if (redirectTo) return <Redirect to={`/projects/${redirectTo}`} />;

    return (
      <div className="project-items">
        <div className="project-info-header">
          <div className="symbol">CS</div>
          <div className="project">
            <div>Sales Order</div>
            <strong>Project Address</strong>
          </div>
        </div>
        {ping &&
          <div style={{ padding: '20px 0' }}>
            <Loading message="Finalizing Order" />
          </div>}
        {!ping &&
          <div className="project-info-body">
            <FinalizeOrderForm
              initialValues={initialValues}
              projectAddress={projectAddress}
              onSubmit={handleFinalizeSubmit}
              isGLOnly={isGLOnly}
            />
            <MiscLineItemsForm
              onSubmit={handleMiscLineItemsSubmit}
              initialValues={{ IsTaxable: true }}
            />
            <AdditionalTotals
              total={total}
              additionalTotals={additionalTotals}
              handleDeleteAdditionalTotal={handleDeleteAdditionalTotal}
              deletingAdditionalTotalId={deletingAdditionalTotalId}
              deleteAdditionalTotalInProgress={deleteAdditionalTotalInProgress}
            />
            <Row>
              <Col>
                <p style={{ color: '#dc3545' }}>
                  IMPORTANT- when you click "Save & Complete" tax status will be updated
                  to "Avatax" and final numbers will be calculated
                </p>
              </Col>
              <Col sm="auto" style={{ textAlign: 'right' }}>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={submitFinalForm}
                  disabled={isFormSubmitting || !isFormValid}
                >
                  Save & Complete
                </Button>
              </Col>
            </Row>
          </div>}
        {
          error && isGLOnly &&
          <Notification
            key="gl_order_fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostFinalizeProject}
          >
            <h5>Failed to create G/L Only Order</h5>
            <div>{error.message || ''}</div>
          </Notification>
        }
      </div>
    );
  }
}

const mapState = state => {
  const isFormValid = isValid('finalize-order-form')(state);
  const isFormSubmitting = isSubmitting('finalize-order-form')(state);
  const {
    inProgress: deleteAdditionalTotalInProgress
  } = state.deleteAdditionalTotal.toJS();

  const {
    error
  } = state.postFinalizeProject.toJS();

  return {
    isFormValid,
    isFormSubmitting,
    deleteAdditionalTotalInProgress,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postAdditionalTotal: fetchDux.postAdditionalTotal.createAction,
      postFinalizeProject: fetchDux.postFinalizeProject.createAction,
      clearPostFinalizeProject: fetchDux.postFinalizeProject.clearAction,
      deleteAdditionalTotal: fetchDux.deleteAdditionalTotal.createAction,
      getProjectTaxStatus: fetchDux.getProjectTaxStatus.createAction,
      clearGetProjectTaxStatus: fetchDux.getProjectTaxStatus.clearAction,
      getProjectById: fetchDux.getProjectById.createAction,
      submitFinalForm: () => submit('finalize-order-form'),
      resetAdditionalTotalsForm: () => reset('misc-line-items-form')
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(FinalizeOrder);
