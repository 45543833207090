import React, { Component } from 'react';
import Loading from '../../../../ui/Loading';
import { Button } from 'react-bootstrap';
import PingOverlay from '../PingOverlay';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state/modals-dux';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';

const getInProgress = ping => {
  if (!ping) return false;
  const {
    dateinserted,
    responsedate,
    aborted
  } = ping || {};
  return !!dateinserted && !responsedate && !aborted;
};

class DeletePing extends Component {
  constructor(props, context) {
    super(props, context);
    this.ping = this.ping.bind(this);
    this.handleAbort = this.handleAbort.bind(this);
    this.timeout = null;
  }
  componentDidMount() {
    this.props.clearProjectDeleteStatus();
    this.ping();
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.props.clearProjectDeleteStatus();
  }
  componentWillReceiveProps(nextProps) {
    const { ping: p1, deleteProjectInProgress: d1 } = this.props;
    const {
      ping: p2,
      deleteProjectInProgress: d2,
      projectId,
      getProjectById
    } = nextProps;
    const inProgress1 = getInProgress(p1);
    const inProgress2 = getInProgress(p2);
    const { skipPing } = d2 || {};
    if (d1 === true && d2 === false && !skipPing) this.ping();
    if (inProgress1 === true && inProgress2 === false)
      getProjectById(projectId);
  }
  async ping() {
    clearTimeout(this.timeout);
    const {
      projectId,
      setPing,
      getDeleteProjectStatus,
      closeModal,
      history
    } = this.props;
    getDeleteProjectStatus(projectId, null, true, ping => {
      const {
        dateinserted,
        responsedate,
        Sage_Status,
        Sage_Message,
        aborted
      } = ping || {};

      const inProgress = !!dateinserted && !responsedate && !aborted;
      const error = Sage_Status === false && !aborted;
      const payload = { ...ping, inProgress, error };

      if (setPing) setPing(payload);

      if (!inProgress) return;

      this.timeout = setTimeout(this.ping, 2000);
    });
  }
  async handleAbort(pingId) {
    try {
      const {
        setPing,
        postAbortDeleteProjectPing,
        projectId,
        closeModal
      } = this.props;

      closeModal();

      postAbortDeleteProjectPing({ projectId, pingId }, null, null, ping => {
        const {
          dateinserted,
          responsedate,
          Sage_Status,
          aborted
        } = ping || {};

        const inProgress = !!dateinserted && !responsedate && !aborted;
        const error = Sage_Status === false && !aborted;
        const payload = { ...ping, inProgress, error };

        if (setPing) setPing(payload);
      });
    } catch (err) {}
  }
  render() {
    const { ping, style, abortInProgress } = this.props;
    const {
      Sage_Message,
      dateinserted,
      responsedate,
      ID,
      aborted,
      Sage_Status
    } = ping || {};

    const inProgress = !!dateinserted && !responsedate && !aborted;
    const error = Sage_Status === false && !aborted;

    return (
      <div style={style || {}}>
        {inProgress &&
          !error &&
          <div style={{ textAlign: 'right' }}>
            <Loading
              style={{ height: '40px', width: '40px' }}
              containerStyle={{ textAlign: 'right' }}
              message={
                <span>
                  Deleting Project
                  <Button
                    onClick={() => this.handleAbort(ID)}
                    variant="danger"
                    size="sm"
                    style={{ marginLeft: '50px', opacity: '.75' }}
                    disabled={abortInProgress}
                  >
                    Abort
                  </Button>

                </span>
              }
              messageStyle={{
                display: 'inline',
                color: 'rgba(0, 0, 0, 0.45)',
                marginLeft: '10px'
              }}
            />

          </div>}
        {error &&
          <div style={{ textAlign: 'right' }}>
            <span style={{ color: '#DC3528' }}>
              An error occurred deleting the project
              {' '}
              <PingOverlay message={Sage_Message} />
            </span>
            <Button
              variant="danger"
              size="sm"
              style={{ marginLeft: '50px', opacity: '.75' }}
              onClick={() => this.handleAbort(ID)}
              disabled={abortInProgress}
            >
              Dismiss
            </Button>
          </div>}
      </div>
    );
  }
}

const mapState = state => {
  const {
    result: ping
  } = state.getDeleteProjectStatus.toJS();

  const {
    inProgress: abortInProgress
  } = state.postAbortDeleteProjectPing.toJS();

  const {
    inProgress: deleteProjectInProgress
  } = state.deleteProject.toJS();

  return {
    ping,
    abortInProgress,
    deleteProjectInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getDeleteProjectStatus: fetchDux.getDeleteProjectStatus.createAction,
      clearProjectDeleteStatus: fetchDux.getDeleteProjectStatus.clearAction,
      postAbortDeleteProjectPing: fetchDux.postAbortDeleteProjectPing.createAction,
      getProjectById: fetchDux.getProjectById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(DeletePing);
