import 'whatwg-fetch';

import store from '../state/store';

import {
  createFetch,
  createStack,
  accept,
  auth,
  init,
  method,
  body,
  parseJSON,
  params
} from 'http-client';

const apiBase = process.env.REACT_APP_API_BASE;

const getToken = () => {
  const state = store.getState();
  const { result: user } = state.login.toJS();
  return user ? `Bearer ${user.token}` : null;
};

export const authenticatedHref = href => {
  try {
    const state = store.getState();
    const { result: user } = state.login.toJS();
    return `${apiBase}/${href}?token=${user.token || ''}`;
  } catch (err) {
    return `${apiBase}/${href}`;
  }
};

const commonStack = () =>
  createStack(
    accept('application/json'),
    auth(getToken()),
    init('credentials', 'same-origin'),
    parseJSON()
  );

const commonStackFile = () =>
  createStack(auth(getToken()), init('credentials', 'same-origin'));

const handleJsonFetch = async call => {
  const res = await call;
  if (res.ok === false) {
    const error = new Error(res.jsonData.message || 'An error occurred');
    error.status = res.status;
    error.jsonData = res.jsonData;
    throw error;
  }
  return res.jsonData;
};
const handleFileFetch = async call => {
  const res = await call;
  if (res.ok === false) {
    const error = new Error(res.jsonData.message || 'An error occurred');
    error.status = res.status;
    error.jsonData = res.jsonData;
    throw error;
  }
  return res;
};

export const getJson = async ({ path, payload }) => {
  const fetch = createFetch(commonStack(), params(payload));
  return handleJsonFetch(fetch(path));
};
export const getFile = async ({ path, payload }) => {
  const fetch = createFetch(commonStackFile(), params(payload));
  return handleFileFetch(fetch(path));
};
export const postJson = async ({ path, payload }) => {
  const fetch = createFetch(
    commonStack(),
    method('POST'),
    body(JSON.stringify(payload || {}), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const patchJson = async ({ path, payload }) => {
  const fetch = createFetch(
    commonStack(),
    method('PATCH'),
    body(JSON.stringify(payload), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const del = async ({ path }) => {
  const fetch = createFetch(commonStack(), method('DELETE'));
  return handleJsonFetch(fetch(path));
};

export const delJson = async ({ path, payload }) => {
  const fetch = createFetch(
    commonStack(),
    method('DELETE'),
    body(JSON.stringify(payload), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const putJson = async ({ path, payload }) => {
  const fetch = createFetch(
    commonStack(),
    method('PUT'),
    body(JSON.stringify(payload), 'application/json')
  );
  return handleJsonFetch(fetch(path));
};

export const postFile = async ({ path, payload }) => {
  const data = new FormData();
  const keys = Object.keys(payload);
  keys.forEach(key => {
    if (Array.isArray(payload[key])) {
      payload[key].map(p => data.append(key, p));
      return;
    }
    data.append(key, payload[key]);
  });

  const fetch = createFetch(commonStack(), method('POST'), body(data));
  return handleJsonFetch(fetch(path));
};
export const putFile = async ({ path, payload }) => {
  const data = new FormData();
  const keys = Object.keys(payload);
  keys.forEach(key => {
    if (Array.isArray(payload[key])) {
      payload[key].map(p => data.append(key, p));
      return;
    }
    data.append(key, payload[key]);
  });

  const fetch = createFetch(commonStack(), method('PUT'), body(data));
  return handleJsonFetch(fetch(path));
};
