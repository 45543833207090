import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import SendNewProjectForm from './SendNewProjectForm';
import Notification from '../../../ui/Notification';
import { formValueSelector, initialize } from 'redux-form';

class ModalSendNewProject extends Component {
  constructor(props) {
    super(props);
    this.state = { showPreview: false };
  }
  render() {
    const {
      openModal,
      closeModal,
      postError,
      warehouse,
      sendNewProjectEmail,
      sendNewProjectEmailError,
      inProgress,
      formSubject,
      formBody,
      formFooter,
      formProjectName,
      formProjectEstShip,
      formProjectEstDelivery,
      formProjectAddress,
      formProjectQtyHandledIn,
      formProjectWeight,
      user,
      comments
    } = this.props;
    const { showPreview } = this.state;

    const initialValues = {
      subject: 'New Project',
      body: 'We have a new project in your area and we would like to partner with your facility to handle the receiving, storage, and ' +
        'consolidated delivery of our equipment package. If you are able to accept this project, please click the link to confirm your  ' +
        'current rates and facility contact information. You will prompted to accept the project and scope of work. A confirmation email will then follow.',
      footer: 'Please use the link below to review/update the information we have on ' +
        'file for addresses, contact information and rates.'
    };

    // Fix show preview bug on close
    const cancelModal = () => {
      this.setState({ showPreview: false });
      closeModal();
    };

    const handleSubmit = payload => {
      if (showPreview) {
        sendNewProjectEmail(
          { id: warehouse.id, payload: { ...payload, warehouse } },
          'send-new-project-form',
          null,
          () => {
            cancelModal();
          }
        );
      } else {
        this.setState({ showPreview: true });
      }
    };

    const closePreview = () => {
      this.setState({ showPreview: false });
    };

    return (
      <Modal
        show={openModal === 'SendNewProjectModal'}
        onHide={cancelModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send New Project Email</Modal.Title>
        </Modal.Header>
        <SendNewProjectForm
          onSubmit={handleSubmit}
          submitting={inProgress}
          closeModal={cancelModal}
          initialValues={initialValues}
          showPreview={showPreview}
          closePreview={closePreview}
          formValues={{
            formSubject,
            formBody,
            formFooter,
            formProjectName,
            formProjectEstShip,
            formProjectEstDelivery,
            formProjectAddress,
            formProjectQtyHandledIn,
            formProjectWeight,
            comments
          }}
          warehouse={warehouse}
          user={user}
        />
        {sendNewProjectEmailError &&
          <Notification
            key="send-new-project-email-error"
            duration={5}
            closable={true}
            type="danger"
          >
            Error sending new project email, please try again.
          </Notification>}
      </Modal>
    );
  }
}

const selector = formValueSelector('send-new-project-form');

const mapState = state => {
  const formSubject = selector(state, 'subject');
  const formBody = selector(state, 'body');
  const formFooter = selector(state, 'footer');
  const formProjectName = selector(state, 'projectName');
  const formProjectEstShip = selector(state, 'projectEstShip');
  const formProjectEstDelivery = selector(state, 'projectEstDelivery');
  const formProjectAddress = selector(state, 'projectAddress');
  const formProjectQtyHandledIn = selector(state, 'projectQtyHandledIn');
  const formProjectWeight = selector(state, 'projectWeight');
  const comments = selector(state, 'comments');
  const {
    result,
    inProgress,
    error: sendNewProjectEmailError
  } = state.sendNewProjectEmail.toJS();
  const {
    result: user
  } = state.validateLogin.toJS();
  return {
    sendNewProjectEmailError,
    inProgress,
    openModal: state.modals,
    formSubject,
    formBody,
    formFooter,
    formProjectName,
    formProjectEstShip,
    formProjectEstDelivery,
    formProjectAddress,
    formProjectQtyHandledIn,
    formProjectWeight,
    user,
    comments
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      sendNewProjectEmail: fetchDux.sendNewProjectEmail.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalSendNewProject);
