import React, { useEffect, useState } from 'react';
import { reduxForm, Field, FieldArray, change } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Icon from '../../../ui/Icon';
import { getShipmentsDetaiURL } from '../shipments-utils';
import { getFormValues } from 'redux-form';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { cloneDeep, isEqual } from 'lodash';
import Loading from '../../../ui/Loading';


const validateTrackingNumber = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    return dispatch(
      fetchDux.validateTrackingNumber.createAction(
        values,
        null,
        null,
        (result) => resolve(result)
      )
    )
  }).then(result => {
    const shipmentResults = (result || []).map((shipmentResult) => {
      const { TrackingNum, Carrier } = shipmentResult;
      if (TrackingNum && Carrier)
        return { TrackingNum: 'Tracking Number already in use' }
      return {}
    })
    const hasErrors = (shipmentResults || []).some((s) => {
      if (Object.keys(s).length > 0) return true;
    })
    if (hasErrors) {
      const errors = { shipments: shipmentResults }
      throw errors;
    } else {
      const { shipments } = values;
      const trackingNumbers = (shipments || []).map(({ TrackingNum }) => TrackingNum).filter(t => !!t);
      const uniqueTrackingNumners = new Set(trackingNumbers);
      if (uniqueTrackingNumners.size < trackingNumbers.length) {
        const duplicates = trackingNumbers.filter((item, index) => trackingNumbers.indexOf(item) !== index);
        const duplicateIndices = (duplicates || []).map((trackingNumber) => {
          const duplicateIndex = (shipments || []).findLastIndex(({ TrackingNum }) => trackingNumber === TrackingNum);
          return duplicateIndex;
        });
        (duplicateIndices || []).forEach((index) => {
          shipmentResults[index] = { TrackingNum: 'Tracking Numer already in use' }
        })
        throw { shipments: shipmentResults };
      }
    }
  })
}
const DELIVERY_OPTIONS = [
  {
    value: "-1",
    label: 'Unshipped'
  },
  {
    value: 12,
    label: 'Info Received'
  },
  {
    value: 2,
    label: 'In Transit'
  },
  {
    value: 8,
    label: 'Available for Pickup'
  },
  {
    value: 5,
    label: 'Out for Delivery'
  },
  {
    value: 6,
    label: 'Failed Attempt'
  },
  {
    value: 7,
    label: 'Delivered'
  },

  {
    value: 4,
    label: 'Received By Warehouse'
  },
  {
    value: "0",
    label: 'Not Found'
  },
  {
    value: 9,
    label: 'Exception'
  },
  {
    value: 10,
    label: 'Expired'
  }
];


const RenderEditShipmentRow = ({ shipment, item, index, getCarrierByName, clearGetCarrierByName, carriersSearchResults, totalCarriers, changeHandler }) => {
  const url = getShipmentsDetaiURL(shipment);
  const { Carrier, carriers } = shipment;
  const [searchChanged, setSearchChanged] = useState(false);
  const [searchResults, setSearchResults] = useState();
  useEffect(() => {
    if (searchChanged) {
      if (!isEqual(searchResults, carriersSearchResults)) {
        setSearchResults(carriersSearchResults);
      }
    }
  }, [carriersSearchResults]);

  const carrierOptions =
    searchResults ?
      (searchResults || []).map((carrier) => {
        const { courier_name, courier_code } = carrier;
        return {
          label: courier_name,
          value: courier_code
        }
      })
      :
      (carriers || []).sort(({ value: a }, { value: b }) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      });
  let c = carrierOptions.find(({ value }) => value === Carrier);
  if (Carrier && !c) {
    c = (totalCarriers || []).find(({ courier_code }) => courier_code === Carrier);
    if (c) {
      carrierOptions.splice(0, 0, { label: c.courier_name, value: c.courier_code });
    }
  }

  return (
    <tr key={index}>
      <td className="td-details">
        <Link to={url}>
          <Icon
            style={{ fontSize: '25px', color: '#5EA2C9' }}
            name="list-alt"
          />
        </Link>
      </td>
      <td>
        <Field
          name={`${item}.c_ImportedDate`}
          component={FormField.Input}
          disabled
        />
      </td>
      <td>
        <Field
          name={`${item}.PoNum`}
          component={FormField.Input}
          disabled
        />
      </td>

      <td>
        <Field
          name={`${item}.VendorNum`}
          component={FormField.Input}
          disabled
        />
      </td>

      <td>
        <Field
          name={`${item}.ShipmentDesc`}
          component={FormField.Input}
        />
      </td>

      <td>
        <Field
          name={`${item}.Destination`}
          component={FormField.Input}
          disabled
        />
      </td>

      <td>
        <Field
          name={`${item}.VendorOrderNum`}
          component={FormField.Input}
          style={{ textAlign: 'center' }}
        />
      </td>

      <td>
        <Field
          name={`${item}.c_ShipDate`}
          component={FormField.DatepickerTable}
        />
      </td>

      <td>
        <Field
          name={`${item}.SchdShipDate`}
          component={FormField.DatepickerTable}
        />
      </td>

      <td>
        <Field
          name={`${item}.ActualShipDate`}
          component={FormField.DatepickerTable}
        />
      </td>

      <td>
        <Field
          name={`${item}.Carrier`}
          type="text"
          component={FormField.SelectWithSearch}
          options={carrierOptions}
          onSearchChange={
            (value) => {
              if (value.trim().length >= 3) {
                setSearchChanged(true);
                getCarrierByName({ carrierName: value });
              } else {
                clearGetCarrierByName();
              }
            }
          }
        />
      </td>

      <td>
        <Field
          name={`${item}.TrackingNum`}
          component={FormField.Input}
        />
      </td>

      <td>
        <Field
          name={`${item}.DeliveryStatus`}
          component={FormField.SelectReact}
          options={DELIVERY_OPTIONS}
          onChange={(e) => {
            if (!e) e = -1;
            changeHandler('shipment-edit-results-form', `${item}.OverrideDeliveryStatus`, e)
          }}
        />
      </td>

      <td>
        <Field
          name={`${item}.ReceivingReportNum`}
          component={FormField.Input}
        />
      </td>

      <td>
        <Field name={`${item}.Notes`} component={FormField.Input} />
      </td>

      <td>
        <Field
          name={`${item}.CarrierDeliveryDate`}
          component={FormField.DatepickerTable}
        />
      </td>
    </tr>
  );
}

const renderShipmentDetails = ({ fields, shipments, getCarrierByName, clearGetCarrierByName, carriersSearchResults, totalCarriers, changeHandler }) => {
  return (
    <div className="table-edit-shipments" style={{ height: 500}}>
      <table className="table">
        <thead>
          <tr>
            <th className="td-details">
              Details
            </th>
            <th>
              Order Date
            </th>
            <th>
              PO Number
            </th>
            <th>
              Vendor
            </th>
            <th>
              Shipment Description
            </th>
            <th>
              Destination
            </th>
            <th>
              Order No.
            </th>
            <th>
              Req Ship Date.
            </th>
            <th>
              Schd Ship Date.
            </th>
            <th>
              Actual Ship Date.
            </th>
            <th>
              Carrier
            </th>
            <th>
              Tracking Num
            </th>
            <th>
              Status
            </th>
            <th>
              Whse RR#
            </th>
            <th>
              Notes
            </th>
            <th>
              Actual Delivery Date
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map((item, index) => {
            const shipment = shipments[index];
            return RenderEditShipmentRow({ item, index, shipment, getCarrierByName, clearGetCarrierByName, carriersSearchResults, totalCarriers, changeHandler });
          })}
        </tbody>
      </table>
    </div>
  );
};

const ShipmentEditResultsForm = props => {
  const {
    submitting,
    handleSubmit,
    shipments,
    formValues,
    onSave,
    updateInProgress,
    onCancelClick,
    getCarrierByName,
    clearGetCarrierByName,
    carriersSearchResults,
    totalCarriers,
    editMultipleResult,
    pingEditMultipleShipmentsStatus,
    updateFinishHandler,
    updateStatus,
    clearUpdateMultipleShipments,
    changeHandler
  } = props;
  const { insertedId } = editMultipleResult || {};
  const [initiatedPing, setInitiatedPing] = useState(false);
  const { completed } = updateStatus || {};
  useEffect(() => {
    if (!initiatedPing && insertedId) {
      setInitiatedPing(true);
      const initiatePing = () => {
        pingEditMultipleShipmentsStatus(insertedId, null, null, (result) => {
          const { completed, error } = result || {};
          setTimeout(() => {
            if (!completed && !error) {
              initiatePing();
            } else {
              clearUpdateMultipleShipments();
              updateFinishHandler();
            }
          }, 1500)
        });
      }
      initiatePing();
    }
  }, [editMultipleResult])
  return (

    <form onSubmit={handleSubmit}>
      <FieldArray
        name="shipments"
        component={renderShipmentDetails}
        props={{ shipments, getCarrierByName, clearGetCarrierByName, carriersSearchResults, totalCarriers, changeHandler }}
      />
      <div className="form-group-fixed-actions">
        {/* <Button
          variant="success"
          type="button"
          disabled={submitting || updateInProgress}
          onClick={() => {
            onSave(formValues);
          }}>
          Save
        </Button> */}

        {/* {' '} */}

        <Button
          variant="success"
          type="submit"
          disabled={submitting || updateInProgress || (!completed && insertedId)}
        >
          Save and Exit
        </Button>

        {' '}
        <Button
          variant="secondary"
          type="button"
          onClick={onCancelClick}
          disabled={submitting || updateInProgress || (!completed && insertedId)}>
          Cancel
        </Button>

        {' '}
        {
          (submitting || updateInProgress || (!completed && insertedId)) &&
          <Loading />
        }
      </div>
    </form>
  );
};

const formName = 'shipment-edit-results-form'

const mapState = state => {
  const formValues = getFormValues(formName)(state);
  const {
    result: carriersSearchResults
  } = state.getCarrierByName.toJS();

  const {
    result: editMultipleResult
  } = state.updateMultipleShipments.toJS();


  const {
    result: updateStatus
  } = state.pingEditMultipleShipmentsStatus.toJS();
  return {
    formValues,
    carriersSearchResults,
    editMultipleResult,
    updateStatus
  }
}

const form = reduxForm({
  form: 'shipment-edit-results-form',
  enableReinitialize: true,
  // asyncValidate: validateTrackingNumber,
  // asyncBlurFields: ['shipments[].TrackingNum'],
  // asyncChangeFields: ['shipments[].Carrier']
})(ShipmentEditResultsForm);


const mapDispatch = dispatch =>
  bindActionCreators({
    getCarrierByName: fetchDux.getCarrierByName.createAction,
    clearGetCarrierByName: fetchDux.getCarrierByName.clearAction,
    pingEditMultipleShipmentsStatus: fetchDux.pingEditMultipleShipmentsStatus.createAction,
    clearUpdateMultipleShipments: fetchDux.updateMultipleShipments.clearAction,
    changeHandler: change
  }, dispatch)

export default connect(mapState, mapDispatch)(form);
