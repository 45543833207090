import React, { useRef } from 'react';
import { Button, Form } from 'react-bootstrap';
import Table from '../../../ui/Table';
import Icon from '../../../ui/Icon';
import moment from 'moment-timezone';
import generateAndDownloadXlsx from './generateAndDownloadXlsx';
import { Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import Toggle from '../../../ui/Toggle';

const getColumns = ({ toggleDisable, inProgress }) => {
  return [
    {
      Header: 'Purchase Order',
      accessor: 'c_PurchaseOrderNumber',
      width: 150
    },
    {
      Header: 'Vendor',
      accessor: 'Name'
    },
    {
      Header: 'Email From',
      accessor: 'EmailFrom',
      width: 175
    },
    {
      Header: 'Email Recipient',
      accessor: 'EmailRecipient',
      width: 150
    },
    {
      Header: 'Date Sent',
      accessor: 'DateSent',
      width: 160,
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return '';
        return moment(value).tz('America/Chicago').format('MM/DD/YYYY h:mm A zz');
      }
    },
    {
      Header: 'Read',
      accessor: 'IsRead',
      width: 50,
      style: { textAlign: 'center' },
      Cell: ({ value }) => {
        if (!value) return '';
        return <Icon name="check" style={{ color: '#04844B' }} />;
      },
      formatExport: value => value ? 'T' : 'F'
    },
    {
      Header: 'Date Read',
      accessor: 'DateRead',
      width: 160,
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return '';
        return moment(value).format('MM/DD/YYYY h:mm a');
      }
    },
    {
      Header: 'Send Unread Alert',
      accessor: 'IsDisabled',
      width: 150,
      style: { textAlign: 'center' },
      Cell: ({ value, row }) => {
        const { _original } = row || {};
        const { POID: purchaseOrderId } = _original;
        return (
          <Toggle.Checkbox
            name="EnableEmail"
            //front-end value is opposite logic to database, DB shows disable, front end shows enabled
            checked={!value}
            disabled={inProgress}
            onChange={() => toggleDisable({ isDisabled: value, purchaseOrderId })}
          />
        );
      },
      formatExport: value => value ? 'T' : 'F'
    }
  ];
};

const defaultSorted = [
  {
    id: 'c_PurchaseOrderNumber',
    desc: false
  }
];

const getDefaultPageSize = data => {
  const { length } = data || [];
  if (!length || length < 5) return 5;
  return length;
};

const EmailLogs = ({ logs, JobRef, toggleDisable, inProgress }) => {
  const tableRef = useRef(null);
  const { emailLogs } = logs || {};

  const handleDownload = () =>
    generateAndDownloadXlsx({
      ref: tableRef,
      columns,
      sheetName: 'Email Logs',
      downloadName: `${JobRef}-email-logs.xlsx`,
      wscols: [
        { wch: 20 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
        { wch: 30 },
        { wch: 10 },
        { wch: 30 }
      ]
    });

  const columns = getColumns({ toggleDisable, inProgress });

  return (
    <div>
      <div
        style={{ textAlign: 'right', marginBottom: '10px', marginRight: '5px' }}
      >
        <Button size="sm" type="button" onClick={handleDownload}>
          <Icon name="download" />
          &nbsp;
          Download
        </Button>
      </div>
      <Table
        columns={columns}
        data={emailLogs || []}
        showPagination={false}
        defaultPageSize={getDefaultPageSize(emailLogs)}
        defaultSorted={defaultSorted}
        noDataText="No logs exist"
        tableRef={tableRef}
      />
    </div>
  );
};

export default EmailLogs;
