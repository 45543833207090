import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Row, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { formValueSelector } from 'redux-form';

import Notification from '../../../../ui/Notification';
import fetchDux from '../../../../../state/fetch-dux';
import Table from '../../../../ui/Table';
import Icon from '../../../../ui/Icon';
import Layout from '../../../../ui/Layout';
import Box from '../../../../ui/Box';
import Loading from '../../../../ui/Loading';
import { actionCreators } from '../../../../../state/modals-dux';
import { searchActionCreators } from '../../../../../state/search-dux';
import moment from 'moment';
import WarehouseSearchForm
  from '../../../warehouses/warehouses-search/WarehouseSearchForm';
import ModalConfirmDeleteWarehouse
  from '../../../warehouses/warehouses-detail/ModalConfirmDeleteWarehouse';
import ModalSendAllConfirmations
  from '../../../warehouses/warehouses-search//ModalSendAllConfirmations';
import WarehouseStatus
  from '../../../warehouses/warehouses-search/WarehouseStatus';

const getColumns = (
  {
    confirmDeleteWarehouse,
    getCheckedRows,
    isWarehouseSelected,
    selectAll,
    isAllSelected
  }
) => {
  return [
    {
      Header: () => (
        <input
          style={{ marginTop: '2px' }}
          type="checkbox"
          checked={isAllSelected()}
          onClick={() => selectAll()}
          onChange={() => { }} // just to get rid of warning
        />
      ),
      accessor: 'ID',
      width: 40,
      sortable: false,
      className: 'text-center',
      Cell: ({ original }) => (
        <input
          type="checkbox"
          style={{ marginTop: '2px' }}
          checked={isWarehouseSelected(original)}
          onClick={event => getCheckedRows(original, event)}
          onChange={() => { }} // just to get rid of warning
        />
      ),
      hideColumnDropdown: true
    },
    {
      Header: 'Status',
      accessor: 'Label',
      Cell: ({ value, original }) => {
        return (
          <WarehouseStatus
            statusId={original.StatusId}
            statusName={value}
            fullWidth
          />
        );
      }
    },
    {
      Header: 'Name',
      accessor: 'Name',
      Cell: ({ value, original }) => {
        return (
          <Link to={`/administration/warehouses/${original.ID}`}>{value}</Link>
        );
      }
    },
    {
      Header: 'Street',
      accessor: 'Address1'
    },
    {
      Header: 'City',
      accessor: 'City'
    },
    {
      Header: 'State',
      accessor: 'StateProvince'
    },
    {
      Header: 'Zip',
      accessor: 'PostalCode'
    },
    {
      Header: 'Distance (mi.)',
      accessor: 'distance',
      Cell: ({ value }) => {
        return isNaN(value) ? '--' : Math.round(value).toFixed(1);
      },
      className: 'text-center',
      width: 120
    },
    {
      Header: 'Last Used',
      accessor: 'lastUsed',
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return '';
        return moment(value).format('MM/DD/YYYY');
      }
    },
    {
      Header: 'Rates Updated',
      accessor: 'updatedRateDate',
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return '';
        return moment(value).format('MM/DD/YYYY');
      }
    },
    {
      Header: 'Last Rate Request',
      accessor: 'emailSentDate',
      Cell: ({ value }) => {
        if (!value || !moment(value).isValid()) return '';
        return moment(value).format('MM/DD/YYYY');
      }
    },
    {
      Header: 'Count',
      accessor: 'count',
      className: 'text-center'
    },
    {
      Header: '',
      accessor: 'ID',
      style: { textAlign: 'right' },
      width: 100,
      sortable: false,
      Cell: ({ value }) => {
        return (
          <div>
            <Link
              className="btn btn-sm btn-primary"
              to={`/administration/warehouses/${value}/edit`}
            >
              <Icon name="edit" />
            </Link>
            <Button
              size="sm"
              variant="danger"
              style={{ marginLeft: '5px' }}
              onClick={() => confirmDeleteWarehouse(value)}
            >
              <Icon name="trash" />
            </Button>
          </div>
        );
      },
      hideColumnDropdown: true
    }
  ];
};

const defaultSorted = [
  {
    id: 'lastUsed',
    desc: true
  }
];

const getDefaultPageSize = data => {
  const { length } = data || [];
  if (!length || length < 5) return 10;
  return 10;
};

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    address,
    radius,
    name,
    page,
    pageSize,
    desc,
    sortBy
  }
) => ({
  address: address || '',
  radius: parseInt(radius) || null,
  name: name || '',
  page: page || 0,
  pageSize: pageSize || 10,
  desc: getDesc(desc),
  sortBy: sortBy || 'Name'
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class WarehouseSearch extends Component {
  constructor(props) {
    super(props);
    const {
      history,
      location: { pathname, search },
      search: stateSearch
    } = this.props;
    const query = getQueryFromSearch(
      stateSearch ? queryString.stringify(stateSearch) : search
    );
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
    this.state = { activeWarehouseID: null, selectedWarehouses: [] };
  }
  componentDidMount() {
    const {
      location: { search },
      search: stateSearch,
      saveSearch
    } = this.props;
    const qs = getQueryFromSearch(
      stateSearch ? queryString.stringify(stateSearch) : search
    );
    this.props.getWarehouses(qs);
    if (!stateSearch && qs) {
      saveSearch(qs);
    }
  }
  componentWillUnmount() {
    this.props.clearGetWarehouses();
    this.props.clearDelWarehouse();
  }

  handleFetchData = (state) => {
    const {
      location: { search },
      history,
      location: { pathname }
    } = this.props;
    const {
      page,
      pageSize,
      sorted
    } = state || {};
    if (!search) return;
    const [sort] = sorted || [];
    const {
      id: sortBy,
      desc
    } = sort || {};

    const params = {
      ...queryString.parse(search),
      page,
      pageSize,
      sortBy,
      desc
    };
    const query = formatQuery(params);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
    this.setState({ selectedWarehouses: [] });
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search }, getWarehouses } = nextProps;
    if (this.props.location.search !== search) {
      getWarehouses(formatQuery(queryString.parse(search)));
    }
  }

  render() {
    const {
      result,
      inProgress,
      showModal,
      delWarehouse,
      getWarehouses,
      location,
      history,
      getWarehousesError,
      delWarehouseError,
      clearGetWarehouses,
      clearDelWarehouse,
      formSearch,
      formAddress,
      formRadius,
      saveSearch,
      clearSearch,
      sendAllConfirmationEmailResult,
      clearSendAllConfirmationEmail
    } = this.props;

    const {
      search,
      pathname
    } = location || {};

    const { results, numPages, count } = result || {};

    const query = getQueryFromSearch(search);

    const { page } = query;

    const { activeWarehouseID, selectedWarehouses } = this.state;

    const activeWarehouse = (results || [])
      .find(({ ID }) => ID === activeWarehouseID);

    const { ID } = activeWarehouse || {};



    const confirmDeleteWarehouse = ID => {
      this.setState({ activeWarehouseID: ID });
      showModal('DeleteWarehouseModal');
    };

    const handleDeleteWarehouse = () =>
      delWarehouse(ID, null, null, () => {
        const {
          search
        } = location || {};
        const qs = getQueryFromSearch(search);
        this.setState({ selectedWarehouses: [] });
        this.props.getWarehouses(qs);
      });

    const handleSearchWarehouses = values => {
      this.setState({ selectedWarehouses: [] });
      const query = queryString.stringify({ ...values, radius: values.radius === -1 ? null : values.radius });
      history.push({ pathname, search: query });
      saveSearch(values);
    };

    const clearSearchWarehouses = () => {
      const query = getQueryFromSearch({});
      const qs = queryString.stringify(query);
      history.push({ pathname, search: qs });
      this.setState({ selectedWarehouses: [] });
      getWarehouses(query);
      clearSearch();
    };

    const getCheckedRows = (original, event) => {
      event.preventDefault();
      const { ID } = original;
      let selected = selectedWarehouses;
      if (event.target.checked) {
        selected.splice(0, 0, { id: ID });
      } else {
        selected = selected.filter(w => w.id !== ID);
      }
      this.setState({ selectedWarehouses: selected });
    };

    const isWarehouseSelected = original => {
      const { ID } = original;
      const selected = selectedWarehouses.find(w => w.id === ID);
      return selected ? true : false;
    };

    const selectAll = () => {
      const { selectedWarehouses } = this.state;
      if ((results || []).length === (selectedWarehouses || []).length) {
        this.setState({ selectedWarehouses: [] });
        return;
      }
      const selected = (results || []).map((data) => {
        const { ID } = data;
        return { id: ID }
      })
      this.setState({ selectedWarehouses: selected });
    };

    const isAllSelected = () => {
      const { selectedWarehouses } = this.state;
      if (results && (results || []).length === (selectedWarehouses || []).length) return true;
      return false;
    };


    const clearSelectedWarehouses = () => {
      this.setState({ selectedWarehouses: [] });
    };

    const columns = getColumns({
      confirmDeleteWarehouse,
      getCheckedRows,
      isWarehouseSelected,
      selectAll,
      isAllSelected
    });

    return (
      <Layout route="warehouses-search" alt>

        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Warehouses
              </h2>
            </div>
            <div>
              <Button
                size="sm"
                style={{ marginRight: '0.5rem' }}
                onClick={() => showModal('SendAllConfirmationsModal')}
                disabled={(selectedWarehouses || []).length === 0}
              >
                {`Send All Confirmations (${(selectedWarehouses || []).length})`}
              </Button>
              <Link
                to="/administration/warehouses/new"
                className="btn btn-sm btn-primary float-right"
              >
                Add Warehouse
              </Link>
            </div>
          </Container>
        </div>

        <ModalConfirmDeleteWarehouse
          onDeleteWarehouse={handleDeleteWarehouse}
          warehouse={activeWarehouse}
        />

        <ModalSendAllConfirmations
          selectedWarehouses={selectedWarehouses}
          clearSelectedWarehouses={clearSelectedWarehouses}
        />
        <Container>
          <Box className="box-table">
            <WarehouseSearchForm
              submitting={inProgress}
              clearSearch={clearSearchWarehouses}
              onSubmit={handleSearchWarehouses}
              initialValues={{ ...query, radius: query.radius || -1 }}
              formValues={{ formSearch, formAddress, formRadius }}
              enableReinitialize
            />
            <Table
              manual
              columns={columns}
              data={results || []}
              showPagination={true}
              defaultPageSize={10}
              defaultSorted={defaultSorted}
              noDataText={inProgress ? 'Loading' : 'No orders found'}
              showNumResults={true}
              pages={numPages || 0}
              page={parseInt(page)}
              totalResults={count}
              onFetchData={this.handleFetchData}
              showPageJump={false}
              showColumnsSelector={true}
            />
          </Box>
        </Container>
        {getWarehousesError &&
          <Notification
            key="warehouse-search-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetWarehouses}
          >
            Error loading warehouses, verify search criteria.
          </Notification>}
        {delWarehouseError &&
          <Notification
            key="warehouse-del-search-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearDelWarehouse}
          >
            Error deleting warehouse.
          </Notification>}
        {
          sendAllConfirmationEmailResult &&
          <Notification
            key="contact-email-success"
            duration={5}
            closable={true}
            onClose={clearSendAllConfirmationEmail}
          >
            Confirmation Email sent successfully
          </Notification>
        }
      </Layout>
    );
  }
}

const selector = formValueSelector('warehouse-search-form');

const mapState = state => {
  const formSearch = selector(state, 'name');
  const formAddress = selector(state, 'address');
  const formRadius = selector(state, 'radius');
  const {
    result,
    inProgress,
    error: getWarehousesError
  } = state.getAdminWarehouses.toJS();

  const {
    error: delWarehouseError
  } = state.delAdminWarehouse.toJS();

  const {
    result: sendAllConfirmationEmailResult
  } = state.sendAllConfirmationEmails.toJS();

  return {
    result,
    inProgress,
    getWarehousesError,
    delWarehouseError,
    formSearch,
    formAddress,
    formRadius,
    search: state.search,
    sendAllConfirmationEmailResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      ...searchActionCreators,
      getWarehouses: fetchDux.getAdminWarehouses.createAction,
      clearGetWarehouses: fetchDux.getAdminWarehouses.clearAction,
      delWarehouse: fetchDux.delAdminWarehouse.createAction,
      clearDelWarehouse: fetchDux.delAdminWarehouse.clearAction,
      clearSendAllConfirmationEmail: fetchDux.sendAllConfirmationEmails.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(WarehouseSearch);
