import React, { useState } from 'react';
import {
  reduxForm,
  Field,
  formValueSelector,
  getFormValues,
  change,
  destroy
} from 'redux-form';
import { connect } from 'react-redux';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Container } from 'react-bootstrap';
import Loading from '../../../ui/Loading';

import QuoteUser from '../quotes-details/QuoteUser';
import QuoteStatus from '../../../ui/QuoteStatus';
import QuoteItemsForm from './QuoteItemsForm';
import ProposalSummaryForm from './ProposalSummaryForm';
import { bindActionCreators } from 'redux';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const EditQuoteForm = props => {
  const {
    handleSubmit,
    valid,
    customer,
    quoteStatus,
    total,
    result,
    taxToBeDetermined,
    handleChange,
    locations,
    quoteTemplates,
    handleFormFieldChange,
    locationUsers,
    onLocationChange,
    locationLoading,
    quoteUsers,
    depositPercentage,
    salesTaxPercentage,
    invalidQuoteDetails,
    invalidNameLineItems,
    invalidPriceLineItems,
    initialValues,
    quoteSections,
    fullQuote,
    formData
  } = props;

  const [expandAll, setExpandAll] = useState(false);

  const { items } = fullQuote;

  const error = (result || {}).error || props.error;
  const inProgress = (result || {}).inProgress || props.submitting;

  const locationOptions = (locations || []).map(({
    locationID,
    locationName
  }) => ({
    value: locationID,
    label: locationName
  }));

  const { LocationID, users } = initialValues;

  // Limit number of lines in PDF
  let lines = 0;
  const linesLimit = 70;
  if (formData) {
    (formData.quoteSections || []).map(section => {
      lines += 1.5;
      (section.quoteCategories || []).map(category => {
        lines += 1;
        lines += (category.quoteItems || []).length;
      });
    });
  }
  const maxLinesReached = lines >= linesLimit;

  return (
    <form onSubmit={handleSubmit}>

      <Container>
        <Row>
          <Col sm={6}>

            <div className="box-bordered">
              <h4>Quote Details</h4>
              <Row>
                <Col>
                  <Field
                    name="Purchaser"
                    type="text"
                    label="Purchaser"
                    component={FormField.Input}
                    validate={[required]}
                    required
                  />
                </Col>
                <Col>
                  <Field
                    required
                    name="QuoteNumber"
                    type="text"
                    label="Proposal Number"
                    component={FormField.Input}
                    validate={[required]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    required
                    name="ExpirationDate"
                    type="text"
                    label="Proposal Expires"
                    component={FormField.Datepicker}
                    validate={[required]}
                  />
                </Col>
                <Col>
                  <Field
                    required
                    name="PlanDate"
                    type="text"
                    label="Plans Dated"
                    component={FormField.Datepicker}
                    validate={[required]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Field
                    name="LocationID"
                    type="text"
                    label="Location"
                    placeholder="Location"
                    component={FormField.SelectReact}
                    options={locationOptions}
                    onChange={value => {
                      onLocationChange(value);
                      if (value === LocationID) {
                        handleFormFieldChange(
                          'edit-quote-form',
                          'users',
                          users
                        );
                      } else {
                        handleFormFieldChange('edit-quote-form', 'users', []);
                      }
                      handleFormFieldChange(
                        'edit-quote-form',
                        'selectedUser',
                        -1
                      );
                    }}
                    validate={[required]}
                    required
                  />
                </Col>
              </Row>
            </div>

          </Col>

          <Col sm={6}>

            <div className="box-bordered">
              <h4>Quote Status</h4>
              <QuoteStatus status={quoteStatus} quote={fullQuote} />
            </div>

            <QuoteUser
              customer={customer}
              locationUsers={locationUsers}
              quoteStatus={quoteStatus}
              locationLoading={locationLoading}
              editing={true}
              quoteUsers={quoteUsers}
              handleFormFieldChange={handleFormFieldChange}
            />

          </Col>

          <Col sm={12}>
            <div className="box-bordered">
              <h4>Customer Only Display Controls</h4>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Field
                  name="ShowComments"
                  label="Show Comments"
                  size="sm"
                  component={FormField.InputToggle}
                />
                <Field
                  name="ShowAlternate"
                  label="Show Optional/Alternate"
                  size="sm"
                  component={FormField.InputToggle}
                />
                <Field
                  name="ShowSpareNumber"
                  label="Show Spare Number"
                  size="sm"
                  component={FormField.InputToggle}
                />
                <Field
                  name="TaxToBeDetermined"
                  label="Tax to be determined"
                  size="sm"
                  component={FormField.InputToggle}
                />
              </div>

            </div>

          </Col>
          <Col sm={12}>

            <div className="box-bordered">
              <h4>Proposal Summary</h4>
              <ProposalSummaryForm
                total={total}
                taxToBeDetermined={taxToBeDetermined}
                quoteTemplates={quoteTemplates}
                handleFormFieldChange={handleFormFieldChange}
                quoteSections={quoteSections}
                salesTaxPercentage={salesTaxPercentage}
                maxLinesReached={maxLinesReached}
              />
            </div>

            <div className="box-bordered">
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem', height: '30px' }}>
                <h4>Quote Items</h4>
                <Button
                  size='sm'
                  variant="primary"
                  type='button'
                  onClick={() => setExpandAll(!expandAll)}
                >
                  {
                    expandAll && 'Collapse All'
                  }
                  {
                    !expandAll && 'Expand All'
                  }
                </Button>
              </div>

              <QuoteItemsForm
                items={items}
                handleFormFieldChange={handleFormFieldChange}
                expandAll={expandAll}
              />
            </div>

            <div className="box-bordered">
              <h4>Customer Note</h4>
              <Field
                name="CustomerNote"
                type="text"
                rows={6}
                component={FormField.Textarea}
              />
            </div>

          </Col>
        </Row>
      </Container>

      <div className="form-group-fixed-actions">
        {error && <p className="text-danger">{error.message || ''}</p>}
        {inProgress && <Loading message="Saving Quote ..." />}
        {maxLinesReached &&
          <p className="text-danger">
            You have reached the maximum number of line items for the proposal summary. Please delete one to continue
          </p>}
        {!maxLinesReached &&
          !valid &&
          <Col className="errors-parent">
            <div className="errors-container">
              {invalidQuoteDetails &&
                <div className="edit-error">
                  <Icon
                    name="minus-circle"
                    className="text-danger"
                    size="xs"
                    style={{ marginRight: '10px', verticalAlign: 'middle' }}
                  />
                  <span className="text-danger">
                    Please include all required items from the Quote Details section
                  </span>
                </div>}
              {invalidNameLineItems &&
                <div className="edit-error">
                  <Icon
                    name="minus-circle"
                    className="text-danger"
                    size="xs"
                    style={{ marginRight: '10px', verticalAlign: 'middle' }}
                  />
                  <span className="text-danger">
                    Please fill out all line item Names
                  </span>
                </div>}
              {invalidPriceLineItems &&
                <div className="edit-error">
                  <Icon
                    name="minus-circle"
                    className="text-danger"
                    size="xs"
                    style={{ marginRight: '10px', verticalAlign: 'middle' }}
                  />
                  <span className="text-danger">
                    Please fill out all line item Prices
                  </span>
                </div>}
              {!depositPercentage &&
                depositPercentage !== '0' &&
                depositPercentage !== 0 &&
                <div className="edit-error">
                  <Icon
                    name="minus-circle"
                    className="text-danger"
                    size="xs"
                    style={{ marginRight: '10px', verticalAlign: 'middle' }}
                  />
                  <span className="text-danger">
                    Please include a deposit percentage
                  </span>
                </div>}
              {!salesTaxPercentage &&
                salesTaxPercentage !== 0 &&
                salesTaxPercentage !== '0' &&
                <div className="edit-error">
                  <Icon
                    name="minus-circle"
                    className="text-danger"
                    size="xs"
                    style={{ marginRight: '10px', verticalAlign: 'middle' }}
                  />
                  <span className="text-danger">
                    Please include a sales tax percentage
                  </span>
                </div>}
            </div>
          </Col>}

        <Button
          variant="primary"
          type="submit"
          disabled={inProgress || maxLinesReached}
        >
          Save Changes
        </Button>
      </div>

    </form>
  );
};
const formName = 'edit-quote-form';
const selector = formValueSelector(formName);

const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(EditQuoteForm);

const mapState = state => {
  const taxToBeDetermined = selector(state, 'TaxToBeDetermined');
  const customer = selector(state, 'customer');
  const quoteStatus = selector(state, 'Status');
  const deposit = selector(state, 'Deposit');
  const salesTax = selector(state, 'SalesTax');
  const materialGoods = selector(state, 'MaterialGoods');
  const quoteSections = selector(state, 'quoteSections');
  const services = (quoteSections || [])
    .reduce((t, p) => t.concat((p || {}).quoteCategories), [])
    .reduce((t, p) => t.concat((p || {}).quoteItems), [])
    .reduce(
      (t, p) => {
        const { IsOptional, Selected } = p || {};
        if (IsOptional) {
          if (Selected) {
            return t + (isNaN((p || {}).Price) ? 0 : Number((p || {}).Price));
          }
        } else {
          return t + (isNaN((p || {}).Price) ? 0 : Number((p || {}).Price));
        }
        return t;
      },
      0
    );

  const taxableServices = (quoteSections || [])
    .reduce((t, p) => t.concat((p || {}).quoteCategories), [])
    .reduce((t, p) => t.concat((p || {}).quoteItems), [])
    .reduce(
      (t, p) => {
        const { IsOptional, Selected } = p || {};
        if (IsOptional) {
          if (Selected) {
            return t +
              (isNaN((p || {}).Price) || !(p || {}).isTaxable
                ? 0
                : Number((p || {}).Price));
          }
        } else {
          return t +
            (isNaN((p || {}).Price) || !(p || {}).isTaxable
              ? 0
              : Number((p || {}).Price));
        }
        return t;
      },
      0
    );

  const taxableMaterialGoods = selector(state, 'taxableMaterialGoods');
  const subTotal = services + materialGoods;
  const taxableSubTotal = taxableServices + taxableMaterialGoods;
  const total = { services: services, materialGoods, subTotal };
  const tax = taxableSubTotal * (salesTax / 100);
  total.grandTotal = salesTax && !taxToBeDetermined ? subTotal + tax : subTotal;
  total.deposit = deposit ? total.grandTotal * (deposit / 100) : '';

  const purchaser = selector(state, 'Purchaser');
  const quoteNumber = selector(state, 'QuoteNumber');
  const expirationDate = selector(state, 'ExpirationDate');
  const planDate = selector(state, 'PlanDate');
  const locationID = selector(state, 'LocationID');
  const invalidQuoteDetails = !purchaser ||
    !quoteNumber ||
    !expirationDate ||
    !planDate ||
    !locationID;

  const quoteItems = (quoteSections || [])
    .reduce((t, p) => t.concat((p || {}).quoteCategories), [])
    .reduce((t, p) => t.concat((p || {}).quoteItems), []);

  const invalidPriceLineItems = (quoteItems || []).some(item => {
    const { Price } = item || {};
    if (!Price) return true;
  });
  const invalidNameLineItems = (quoteItems || []).some(item => {
    const { LineItemName } = item || {};
    if (!LineItemName) return true;
  });
  const quoteUsers = selector(state, 'users');
  const formData = getFormValues(formName)(state);
  return {
    customer,
    quoteStatus,
    total,
    taxToBeDetermined,
    depositPercentage: deposit,
    salesTaxPercentage: salesTax,
    invalidQuoteDetails,
    invalidPriceLineItems,
    invalidNameLineItems,
    quoteUsers,
    quoteSections,
    formData
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      handleFormFieldChange: change
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(form);
