import React from 'react';
import { Table } from 'react-bootstrap';

const tableRow = (label, data = {}) => {
  return (
    <tr>
      <th>{label}</th>
      <td>{data.netCharges || ''}</td>
      <td>{data.serviceDays || ''}</td>
      <td className="comment">{data.comments || ''}</td>
    </tr>
  );
};

const FreightCalcResults = ({ results }) => {
  const { abf, xpo, fedex, pittOhio, rlc, saia, ups } = results || {};
  return (
    <div>
      <h4>Quotes and Service Days</h4>
      <Table bordered className="table-results">
        <thead>
          <tr>
            <th width="200">Carrier</th>
            <th>Net Charges</th>
            <th>Service Days</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody>
          {tableRow('XPO net charges', xpo)}
          {tableRow('R&L net charges', rlc)}
          {tableRow('ABF net charges', abf)}
          {tableRow('Fedex net charges', fedex)}
          {/* {tableRow('Pitt Ohio net charges', pittOhio)} */}
          {tableRow('Saia net charges', saia)}
          {tableRow('Ups net charges', ups)}
        </tbody>
      </Table>

    </div>
  );
};

export default FreightCalcResults;
