import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';

const ModalDeleteCustomerDocument = (
  {
    openModal,
    name,
    closeModal,
    inProgress,
    history,
    handleDeleteCompanyDoc
  }
) => {

  return (
    <Modal
      show={openModal === name}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          Delete Documents
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: 'center', marginTop: '10px' }}>
          Are you sure you want to delete selected documents?
        </p>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Row>
          <Col style={{ color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>
          <Col sm="auto">
            <Button
              type="button"
              size="sm"
              variant="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="sm"
              variant="danger"
              onClick={handleDeleteCompanyDoc}
              disabled={inProgress}
              style={{ marginLeft: '10px' }}
            >
              Yes, Delete Documents
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
      {false &&
        <Notification
          key="document-delete-fail"
          duration={3}
          closable={true}
          type="danger"
        >
          Failed to delete documents. Please try again.
        </Notification>}
    </Modal>
  );
};

const mapState = state => {
  //  const {
  //    result: deleteDocumentsResult,
  //    inProgress: deleteDocumentsInProgress,
  //    error: deleteDocumentsError
  //  } = state.deleteDocuments.toJS();

  return {
    //    deleteDocumentsResult,
    //    deleteDocumentsInProgress,
    //    deleteDocumentsError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
      //    deleteDocuments: fetchDux.deleteDocuments.createAction,
      //    clearDeleteDocuments: fetchDux.deleteDocuments.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalDeleteCustomerDocument);
