import React, { useEffect, useState } from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { connect } from 'react-redux';
import { Form, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';

import PoForm from './PoForm';
import GeneralForm from './GeneralForm';
import VendorForm from './VendorForm';
import CarrierForm from './CarrierForm';
import WarehouseForm from './WarehouseForm';
import WebTrackButtonColumnPopover from '../../../ui/WebTrackButtonColumnPopover';
import fetchDux from '../../../../state/fetch-dux';
import { authenticatedHref } from '../../../../api/fetch';
import { bindActionCreators } from 'redux';

const FORM_NAME = 'shipment-form';

const validateTrackingNumber = (values, dispatch) => {
  let { Carrier, TrackingNum } = values;
  if (typeof Carrier === 'object')
    Carrier = (Carrier || {}).value;
  if ((Carrier || '').trim().length === 0 || (TrackingNum || '').trim().length === 0)
    return new Promise((resolve, reject) => {
      resolve();
    }).then(() => { });
  return new Promise((resolve, reject) => {
    return dispatch(
      fetchDux.validateTrackingNumber.createAction(
        values,
        null,
        null,
        (result) => resolve(result)
      )
    )
  }).then(result => {
    const { TrackingNum, Carrier } = result || {};
    if (TrackingNum && Carrier)
      throw { TrackingNum: 'Tracking Number already in use' }
  })
}


const DELIVERY_OPTIONS = [
  {
    value: "-1",
    label: 'Unshipped'
  },
  {
    value: 12,
    label: 'Info Received'
  },
  {
    value: 2,
    label: 'In Transit'
  },
  {
    value: 8,
    label: 'Available for Pickup'
  },
  {
    value: 5,
    label: 'Out for Delivery'
  },
  {
    value: 6,
    label: 'Failed Attempt'
  },
  {
    value: 7,
    label: 'Delivered'
  },

  {
    value: 4,
    label: 'Received By Warehouse'
  },
  {
    value: "0",
    label: 'Not Found'
  },
  {
    value: 9,
    label: 'Exception'
  },
  {
    value: 10,
    label: 'Expired'
  }
];

const DeliveryStatusCell = (
  {
    deliveryStatusCodes,
    deliveryStatusValue,
    change
  }
) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    if (open) {
      setOpen(false);
      change('OverrideDeliveryStatus', null);
      return;
    }
    setOpen(true);
    change('OverrideDeliveryStatus', deliveryStatusValue);
  };
  return (
    <Col sm={4}>
      {!open &&
        <span>
          {deliveryStatusCodes[deliveryStatusValue]}
        </span>}
      {open &&
        <div style={{ width: '300px', display: 'inline-block' }}>
          <Field
            name="OverrideDeliveryStatus"
            component={FormField.SelectReact}
            options={DELIVERY_OPTIONS}
          />
        </div>}
      <Icon
        name={open ? 'times-circle' : 'edit'}
        onClick={handleToggle}
        style={{ color: open ? '#5EA2C9' : '#5EA2C9', marginLeft: '20px' }}
      />
    </Col>
  );
};

const VendorCell = (
  {
    vendorOptions,
    VendorName,
    change,
    lineItemsLength,
    poVendorName
  }
) => {
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    if (open) {
      setOpen(false);
      change('VendorName', poVendorName);
      const shipmentDesc = `${poVendorName} ${lineItemsLength} ${lineItemsLength > 1 ? 'Assorted items' : 'Assorted Item'}`;
      change('ShipmentDesc', shipmentDesc);
      return;
    }
    setOpen(true);
  };
  return (
    <div
      style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
    >
      {!open && <span>{VendorName}</span>}
      {open &&
        <div style={{ width: '150px' }}>
          <Field
            name="VendorName"
            component={FormField.SelectReact}
            options={vendorOptions}
            onChange={value => {
              const shipmentDesc = `${value} ${lineItemsLength} ${lineItemsLength > 1 ? 'Assorted items' : 'Assorted Item'}`;
              change('VendorName', value);
              change('ShipmentDesc', shipmentDesc);
            }}
          />
        </div>}
      <Icon
        name={open ? 'times-circle' : 'edit'}
        onClick={handleToggle}
        style={{
          color: open ? '#5EA2C9' : '#5EA2C9',
          marginLeft: '20px',
          marginBottom: open ? '1rem' : '0'
        }}
      />
    </div>
  );
};

const ShipmentsForm = (
  {
    purchaseOrder = {},
    lineItems = [],
    shipmentOptions = [],
    carrierOptions = [],
    generalInfo = {},
    deliveryStatusCodes = {},
    carrierValue,
    trackingNumValue,
    notesValue,
    shipmentNumValue,
    shippingAddress,
    deliveryStatusValue,
    carrierUpdateDateValue,
    showCopy,
    hasLogs,
    handleShipmentLogs,
    handleShipmentChange,
    handleShipmentCopy,
    handleShipmentAdd,
    handleWebTrack,
    handleSubmit,
    confirmDeleteShipment,
    change,
    CARRIERS,
    vendorOptions,
    c_IsImported,
    purchaseOrderOptions,
    handlePurchaseOrderChange,
    VendorName,
    poVendorName,
    setOpen,
    openTabs,
    webTrackInProgress,
    getCarrierByName,
    clearGetCarrierByName,
    carriersSearchResults,
    initialValues,
    pdfLogs,
    trackingMore,
    isDefaultCarrier,
    manualTracking,
    changeHandler
  }
) => {
  const {
    TrackingMore_Tracking_ID,
    Carrier: initialCarrier,
    TrackingNum: initialTrackingNum,
    manualTracking: initialManualTracking
  } = initialValues;

  const { value: initialCarrierValue } = initialCarrier || {};
  const { value: currentCarrierValue } = carrierValue || {};
  const didTrackingInformationChange = (() => {
    if (initialCarrierValue !== currentCarrierValue) return true;
    if (initialTrackingNum !== trackingNumValue) return true;
    if (initialManualTracking !== manualTracking) return true;
  })();
  const popOverLabel = `Selecting this box and clicking “Save” will disable system auto-tracking updates to this shipment. The shipment status and carrier information data fields must be updated manually by the user. You can reconnect to auto-tracking updates by unselecting this box and clicking “Save”.`
  const [rowCount, setRowCount] = useState(5);
  const showWebTrack = carrierValue &&
    carrierValue.value &&
    trackingNumValue &&
    CARRIERS.some(c => (c.value || '').toLowerCase() === (carrierValue.value || '').toLowerCase());
  const handleUpdateNotes = () => change('Notes', notesValue);
  const handleWebTrackClick = () =>
    handleWebTrack({
      Carrier: (carrierValue || {}).value,
      TrackingNum: trackingNumValue,
      deliveryStatusValue
    });

  const { c_ShipDate } = shippingAddress || {};
  const displayDate = d =>
    d && moment(d).isValid() ? moment(d).format('MM/DD/YYYY') : '';


  const buttonStyle = parseInt(deliveryStatusValue) === 4 || parseInt(deliveryStatusValue) === 7 ?
    'primary' :
    parseInt(deliveryStatusValue) === 0 ? 'primary-grey' :
      TrackingMore_Tracking_ID ?
        manualTracking ? 'warning-secondary' : 'warning' :
        trackingMore ? 'success' : isDefaultCarrier ? 'success' : 'primary';

  const getPDFUrl = (
    {
      FilePath,
      FileName
    }
  ) => authenticatedHref(`files/${FilePath}/${FileName}`);
  const pdfDetails = (pdfLogs || [])[0] || {};

  useEffect(() => {
    if (isDefaultCarrier && !trackingMore && !manualTracking) {
      changeHandler(FORM_NAME, 'manualTracking', true)
    }
    else if (!isDefaultCarrier && !trackingMore && !manualTracking && !!carrierValue) {
      changeHandler(FORM_NAME, 'manualTracking', true)
    }
  }, [isDefaultCarrier, trackingMore])
  return (
    <div className="shipment-form">
      <Form onSubmit={handleSubmit}>
        <Field
          hidden
          type="text"
          name="ShipmentNum"
          component={FormField.Input}
        />
        <Row>
          <Col sm={1}>{shipmentNumValue}</Col>
          <Col sm={2}>Shipment</Col>
          <Col sm={9}>
            <Field
              type="text"
              name="ID"
              options={shipmentOptions}
              onChange={handleShipmentChange}
              component={FormField.SelectReact}
            />
          </Col>
        </Row>
        <div className="po-summary">
          <Row>
            <Col className="column">
              <Form.Label style={{ width: '400px' }}>PO Number:</Form.Label>
              <Field
                type="text"
                name="poNumber"
                options={purchaseOrderOptions}
                onChange={value => {
                  const po = purchaseOrderOptions.find(
                    ({ value: v }) => v === value
                  );
                  handlePurchaseOrderChange(po);
                }}
                component={FormField.SelectReact}
              />
            </Col>
            <Col className="column">
              <Form.Label>Order Date:</Form.Label>
              <div>{displayDate(purchaseOrder.c_ProjectCreateDate)}</div>
            </Col>
            <Col className="column">
              <Form.Label>Shipment:</Form.Label>
              <div>{shipmentNumValue}</div>
            </Col>
            <Col className="column">
              <Form.Label>Req. Ship Date:</Form.Label>
              <div>{displayDate(c_ShipDate)}</div>
            </Col>
            <Col className="column">
              <Form.Label>Vendor Name:</Form.Label>
              {/* TODO Display vendor name and NOT vendor number */}
              {c_IsImported
                ? <VendorCell
                  vendorOptions={vendorOptions}
                  VendorName={VendorName}
                  change={change}
                  lineItemsLength={lineItems.length}
                  poVendorName={poVendorName}
                />
                : <div> {purchaseOrder.VendorName}</div>}
            </Col>
            <Col className="column">
              <Form.Label>Sales Rep:</Form.Label>
              <div>{purchaseOrder.c_SalesRepFirstName}</div>
            </Col>
          </Row>
        </div>
        <div>
          <Row>
            <Col sm={3}><Form.Label>Shipment Description:</Form.Label></Col>
            <Col sm={9}>
              <Field
                type="text"
                name="ShipmentDesc"
                component={FormField.Input}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Label>Delivery Status:</Form.Label>
            </Col>
            <DeliveryStatusCell
              deliveryStatusValue={deliveryStatusValue}
              deliveryStatusCodes={deliveryStatusCodes}
              change={change}
            />
            {/* <Col sm={2}>{deliveryStatusValue}</Col> */}
            {/* <Col sm={2}><Form.Label>Delivery Status:</Form.Label></Col> */}
            {/* <Col sm={2}>{deliveryStatusCodes[deliveryStatusValue]}</Col> */}
          </Row>
        </div>
        <div className="my-2">
          <Button type="submit" size="sm">Save</Button>{' '}
          <WebTrackButtonColumnPopover
            header={"Web Track"}
            handleWebTrack={handleWebTrackClick}
            updateInProgress={webTrackInProgress}
            enabled={showWebTrack}
            buttonStyle={buttonStyle}
          />
          {/* <Button
            size="sm"
            onClick={handleWebTrackClick}
            disabled={!showWebTrack || webTrackInProgress}
            variant={buttonStyle}
          >
            Track
          </Button> */}
          {' '}
          <Button size="sm" onClick={handleShipmentLogs} disabled={!hasLogs}>
            View Change Log
          </Button>
          {' '}
          <Button size="sm" onClick={handleShipmentCopy} disabled={!showCopy}>
            Copy Shipment
          </Button>
          {' '}
          <Button size="sm" onClick={handleShipmentAdd}>Add Shipment</Button>
          {' '}
          {
            pdfLogs && pdfLogs.length > 0 &&
            <a href={getPDFUrl(pdfDetails || {})} target="_blank">
              <Button
                variant='primary'
                size='sm'
              >
                View Purchase Order
              </Button>
            </a>

          }
          {' '}
          {showCopy &&
            <Button size="sm" onClick={confirmDeleteShipment} variant="danger">
              <Icon name="trash" />
            </Button>
          }
          {
            <>
              {' '}
              {
                deliveryStatusValue !== 7
                &&
                deliveryStatusValue !== 4
                &&
                TrackingMore_Tracking_ID
                &&
                <div style={{ display: 'inline-block', marginLeft: '1rem' }}>
                  <Field
                    name="manualTracking"
                    label="Track Manually"
                    component={FormField.InlineCheckbox}
                    disabled={(isDefaultCarrier && trackingMore === false) || trackingMore === false}
                    overlayProps={{
                      overlay: true,
                      popOverLabel
                    }}
                  />
                </div>

              }
            </>
          }
        </div>
        {
          didTrackingInformationChange &&
          <div className="my-2">
            {'Changes have been made to a Carrier Information field. Please click Save to finalize your changes.'}
          </div>
        }
        <PoForm lineItems={lineItems} setOpen={setOpen} openTabs={openTabs} />
        <GeneralForm
          data={generalInfo}
          purchaseOrder={purchaseOrder}
          setOpen={setOpen}
          openTabs={openTabs}
        />
        <VendorForm
          handleUpdateNotes={handleUpdateNotes}
          setOpen={setOpen}
          openTabs={openTabs}
        />
        <CarrierForm
          carrierUpdateDateValue={carrierUpdateDateValue}
          carriers={CARRIERS}
          setOpen={setOpen}
          openTabs={openTabs}
          getCarrierByName={getCarrierByName}
          clearGetCarrierByName={clearGetCarrierByName}
          carriersSearchResults={carriersSearchResults}
          change={change}
        />
        <WarehouseForm setOpen={setOpen} openTabs={openTabs} />
        <Row>
          <Col sm={12}>
            <Field
              rows={rowCount}
              name="Notes"
              label="Notes:"
              component={FormField.Textarea}
              normalize={(value, previousValue) => {
                if (value.length < 5000) {
                  return value;
                }
                return previousValue;
              }}
              onChange={({ target }) => {
                const rows = target.value.split('\n');
                if (rows.length > rowCount) {
                  setRowCount(rowCount + 1);
                } else {
                  if (rows.length < 5) {
                    if (rowCount != 5) setRowCount(5);
                  } else if (rows.length < rowCount) {
                    setRowCount(rowCount - 1);
                  }
                }
              }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const formName = FORM_NAME;

const selector = formValueSelector(formName);

const mapState = state => {
  const deliveryStatusValue = selector(state, 'DeliveryStatus');
  const shipmentNumValue = selector(state, 'ShipmentNum');
  const carrierValue = selector(state, 'Carrier');
  const trackingNumValue = selector(state, 'TrackingNum');
  const carrierUpdateDateValue = selector(state, 'CarrierUpdateDate');

  const notes = selector(state, 'Notes');
  const name = selector(state, 'SchdShipDatePerson');
  const schdShipDate = selector(state, 'SchdShipDate');
  const contactDate = selector(state, 'SchdShipDatePersonDate');
  const actualName = selector(state, 'ActualShipDatePerson');
  const actualShipDate = selector(state, 'ActualShipDate');
  const actualContactDate = selector(state, 'ActualShipDatePersonDate');
  const notesVal = [];
  if (notes) notesVal.push(notes);
  notesVal.push('');
  notesVal.push(`** Contact Info ${moment().format('MM/DD/YYYY h:mm:ss a')}`);
  notesVal.push('');
  notesVal.push(`Name: ${name || ''}`);
  notesVal.push(`SchdShipDate: ${schdShipDate || ''}`);
  notesVal.push(`ContactDate: ${contactDate || ''}`);
  notesVal.push(`Name: ${actualName || ''}`);
  notesVal.push(`ActualShipDate: ${actualShipDate || ''}`);
  notesVal.push(`ContactDate: ${actualContactDate || ''}`);
  const VendorName = selector(state, 'VendorName');


  const defaultCarriers = selector(state, 'defaultCarriers');
  const {
    result: totalCarriers
  } = state.getAllCarriers.toJS();


  const carrier = typeof carrierValue === 'object' ? (carrierValue || {}).value : carrierValue

  let c = (defaultCarriers || []).find(({ value }) => value === carrier);
  const isDefaultCarrier = !!c;

  const carrierName = carrier === 'saia'
    ? 'saia-freight'
    : carrier === 'dayton'
      ? 'dayton-freight'
      : carrier === 'rlc' ? 'rl-carriers' :
        carrier === 'southeastern' ?
          'se-freightlines' :
          carrier === 'usf' ?
            'usf-reddaway' :
            carrier === 'xpo' ?
              'xpoweb' :
              carrier === 'averitt' ?
                'averittexpress' :
                carrier || '';

  const courier = (totalCarriers || []).find((c) => {
    const { courier_code, courier_name } = c || {};
    if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('-')) ||
      (courier_name || '').toLowerCase() === (carrierName.split(' ').join('-'))) {
      return c;
    }
    else if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('')) ||
      (courier_name || '').toLowerCase() === (carrierName.split(' ').join(''))) {
      return c;
    }
  })
  const trackingMore = !!courier;
  const manualTracking = selector(state, 'manualTracking');

  return {
    carrierValue,
    trackingNumValue,
    shipmentNumValue,
    deliveryStatusValue:
      isNaN(parseInt(deliveryStatusValue)) ? "-1" :
        parseInt(deliveryStatusValue) === -1 ? "-1" :
          parseInt(deliveryStatusValue) === 0 ? "0" :
            parseInt(deliveryStatusValue),
    carrierUpdateDateValue,
    notesValue: notesVal.join('\n'),
    VendorName,
    trackingMore,
    isDefaultCarrier,
    manualTracking
  };
};

const form = reduxForm({
  form: formName,
  enableReinitialize: true,
  // asyncValidate: validateTrackingNumber,
  // asyncBlurFields: ['TrackingNum'],
  // asyncChangeFields: ['Carrier']
})(ShipmentsForm);

const mapDispatch = (dispatch) => bindActionCreators(
  {

    changeHandler: change
  },
  dispatch
)

export default connect(mapState, mapDispatch)(form);
