import React, { Component } from 'react';
import {
  reduxForm,
  Field,
  FieldArray,
  formValueSelector,
  submit
} from 'redux-form';
import FormField from '../../../forms/FormField';
import {
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Form,
  Table
} from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import ModalConfirmSendPO from './ModalConfirmSendPO';

const required = value => value ? undefined : 'Required';
const requiredFieldArray = value =>
  value && value.length > 0 ? undefined : 'Required';
const requiredToAddress = value =>
  (value || []).some(({ Type }) => Type === 'to')
    ? undefined
    : 'At least one "To" address is required';

const getTypeLabel = Type => {
  switch (Type) {
    case 'to':
      return 'To';
    case 'cc':
      return 'CC';
    case 'bcc':
      return 'BCC';
    default:
      return '';
  }
};

class RenderToInputs extends Component {
  render() {
    const {
      fields,
      toValues,
      subValues,
      change
    } = this.props;

    const {
      EmailRecipient,
      Type
    } = subValues || {};

    const disabled = !EmailRecipient || !Type;

    const handleAddEmail = () => {
      if (disabled) return;
      fields.push(subValues);
      change('sub', {
        EmailRecipient: '',
        Type: 'to',
        Track: true
      });
    };

    const handleTypeChange = value => {
      if (value === 'to') return;
      change('sub.Track', false);
    };

    return (
      <Row>
        <Col sm="12">
          <Table>
            <thead>
              <tr>
                <th>Email Recipient</th>
                <th>Type</th>
                <th>Track?</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Field
                    name="sub.EmailRecipient"
                    type="text"
                    component={FormField.Input}
                  />
                </td>
                <td>
                  <Field
                    name="sub.Type"
                    type="text"
                    component={FormField.SelectReact}
                    placeholder=""
                    onChange={handleTypeChange}
                    options={[
                      { value: 'to', label: 'To' },
                      { value: 'cc', label: 'CC' },
                      { value: 'bcc', label: 'BCC' }
                    ]}
                  />
                </td>
                <td>
                  <Field
                    disabled={Type !== 'to'}
                    name="sub.Track"
                    component={FormField.InlineCheckbox}
                    style={{ display: 'inline' }}
                    inputContainerStyle={{ display: 'inline' }}
                  />
                </td>
                <td style={{ textAlign: 'right' }}>
                  <Button
                    type="button"
                    onClick={handleAddEmail}
                    size="sm"
                    disabled={disabled}
                  >
                    <Icon name="plus" />
                  </Button>
                </td>
              </tr>
              {(toValues || []).map(({ EmailRecipient, Type }, index) => {
                const handleRemove = () => fields.remove(index);
                return (
                  <tr key={`${EmailRecipient}-${index}`}>
                    <td style={{ color: '#5f5f5f' }}>
                      <span style={{ color: '#5EA2C9' }}>
                        <Icon name="envelope" />
                      </span>
                      &nbsp;
                      &nbsp;
                      {EmailRecipient}
                    </td>
                    <td>{getTypeLabel(Type)}</td>
                    <td>
                      {Type === 'to' &&
                        <Field
                          name={`to[${index}].Track`}
                          component={FormField.InlineCheckbox}
                          style={{ display: 'inline' }}
                          inputContainerStyle={{ display: 'inline' }}
                        />}
                    </td>
                    <td
                      style={{
                        color: '#C23934',
                        textAlign: 'right',
                        cursor: 'pointer'
                      }}
                    >
                      <Icon name="minus-circle" onClick={handleRemove} />
                    </td>
                  </tr>
                );
              })}
              {(toValues || []).length === 0 &&
                <tr>
                  <td
                    colSpan="4"
                    style={{ color: '#C23934', textAlign: 'center' }}
                  >
                    <Icon name="exclamation-triangle" />
                    &nbsp;
                    No Email Recipients selected
                  </td>
                </tr>}
              {(toValues || []).length >= 0 &&
                !(toValues || []).some(({ Type }) => Type === 'to') &&
                <tr>
                  <td
                    colSpan="4"
                    style={{ color: '#C23934', textAlign: 'center' }}
                  >
                    <Icon name="exclamation-triangle" />
                    &nbsp;
                    At least one "To" address is required
                  </td>
                </tr>}
            </tbody>
          </Table>
        </Col>
        <Col sm="12">
          <hr />
        </Col>
      </Row>
    );
  }
}

const SendPurchaseOrderForm = props => {
  const {
    handleSubmit,
    submitting,
    submitFailed,
    invalid,
    toValues,
    subValues,
    pdfDetails,
    change,
    showModal,
    onSendPurchaseOrder,
    submitText,
    dispatch,
    form,
    user,
    templateOptions,
    VendorLBMXEnabled,
    sendEmail,
    disableSendEmail,
    lbmxErrorMessage
  } = props;

  const { directory, filename, rootFileShareDirectory } = pdfDetails || {};

  const handleConfirm = handleSubmit(() =>
    showModal(`confirm-send-po-modal-${form}`));
  const handleSendPurchaseOrder = handleSubmit(onSendPurchaseOrder);

  const confirmText = VendorLBMXEnabled
    ? sendEmail
      ? 'Send via LBMX, Email and Save PDF'
      : 'Send via LBMX and Save PDF'
    : 'Send Email and Save PDF';

  const buttonText = VendorLBMXEnabled
    ? sendEmail
      ? 'Send via LBMX, Email and Save PDF'
      : 'Send via LBMX and Save PDF'
    : 'Send Email and Save PDF';

  return (
    <form>
      <ModalConfirmSendPO
        name={`confirm-send-po-modal-${form}`}
        onSubmit={handleSendPurchaseOrder}
        submitting={submitting}
        confirmText={confirmText}
      />
      {VendorLBMXEnabled &&
        <Row>
          <Col>
            <div style={{ display: 'flex' }}>
              <Field
                name="sendEmail"
                component={FormField.InputToggle}
                label="Also send email"
                disabled={disableSendEmail}
              />
              {lbmxErrorMessage &&
                <span
                  className="text-danger"
                  style={{ marginLeft: '1rem', paddingTop: '0.08rem' }}
                >
                  {lbmxErrorMessage}
                </span>}
            </div>
          </Col>
        </Row>}
      {sendEmail &&
        <Row>
          <Col sm="12">
            <FieldArray
              name="to"
              change={change}
              component={RenderToInputs}
              toValues={toValues}
              subValues={subValues}
              validate={[requiredFieldArray, requiredToAddress]}
            />
          </Col>
          <Col sm="12">
            {templateOptions &&
              templateOptions.length > 0 &&
              <div style={{ width: '200px', float: 'right' }}>
                <Field
                  name="templateId"
                  type="text"
                  component={FormField.SelectReact}
                  options={templateOptions}
                  onChange={v => {
                    const { body, subject } = (templateOptions || [])
                      .find(({ value }) => value === v) || {};
                    if (body) change('body', body);
                    if (subject) change('subject', subject);
                  }}
                />
              </div>}
            <Field
              required
              name="subject"
              type="text"
              component={FormField.Input}
              label="Subject"
              validate={[required]}
            />
          </Col>
          <Col sm="12">
            <Field
              required
              name="body"
              rows={10}
              component={FormField.Textarea}
              label="Body"
              validate={[required]}
            />
          </Col>
        </Row>}

      <div>
        <div style={{ color: '#495057', paddingBottom: '10px' }}>
          <span style={{ fontWeight: 'bold' }}>PDF Location:</span>
          &nbsp;&nbsp;
          <Icon name="folder-open" />
          {' '}
          {rootFileShareDirectory}/Purchase Orders/
          {directory}
          /
          {filename}
        </div>
        <Button
          variant="primary"
          type="button"
          disabled={submitting}
          onClick={handleConfirm}
        >
          {submitText || buttonText}
        </Button>
        {submitFailed &&
          invalid &&
          <span style={{ marginLeft: '20px', color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            Form is invalid. Fix errors before proceeding
          </span>}
      </div>
    </form>
  );
};

const formName = 'send-purchase-order-form';

const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(SendPurchaseOrderForm);

const mapState = (state, props) => {
  const selector = formValueSelector(props.form || formName);

  const toValues = selector(state, 'to');
  const subValues = selector(state, 'sub');
  const VendorLBMXEnabled = selector(state, 'VendorLBMXEnabled');
  const sendEmail = selector(state, 'sendEmail');
  const disableSendEmail = selector(state, 'disableSendEmail');
  const lbmxErrorMessage = selector(state, 'lbmxErrorMessage');
  return {
    toValues,
    subValues,
    VendorLBMXEnabled,
    sendEmail: !VendorLBMXEnabled ? true : sendEmail,
    disableSendEmail: disableSendEmail || false,
    lbmxErrorMessage
  };
};

const mapDispatch = (dispatch, ...props) =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(form);
