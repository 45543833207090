import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import moment from 'moment';

const required = value => value ? undefined : 'Required';


const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM.DD.YYYY');
};

const FileDetail = ({ input, valid }) => {
  const { value } = input || {};
  return (
    <div>
      <h5>Filename:</h5>
      <div>
        {value.name || ''}
        &nbsp;
        {' '}
        {valid && <Icon style={{ color: '#28a745' }} name="check" />}
      </div>
      <h5>Last Updated:</h5>
      <div>{getDate(value.lastModifiedDate)}&nbsp;</div>
    </div>
  );
};


const validateFileType = value => {
  const { name } = value || {};
  if (!value || !name) return 'Required';
  const filetype = name.substring(name.lastIndexOf('.') + 1);
  if (filetype.toLowerCase() !== 'csv')
    return 'Invalid file type.';
  return undefined;
};


const ImportCustomersUserForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    valid,
    uploadResult,
    downloadUsersTemplate
  } = props;

  const totalUsers = (uploadResult || []).length;
  const successUsers = (uploadResult || [])
    .filter(({ invalidMessages }) => (invalidMessages || []).length === 0)
    .length

  const failedUsers = (uploadResult || [])
    .filter(({ invalidMessages }) => (invalidMessages || []).length > 0)


  const getExceptionsDisplay = () => {
    return (
      <div className='exceptions'>
        <Row className='header'>
          <strong>{`Exceptions {${failedUsers.length}}`}</strong>
          <label>{`Scroll down to view more`}</label>
        </Row>
        <div className='exceptions-list'>
          {
            failedUsers.map((user, key) => {
              const { invalidMessages, FirstName, LastName, Email } = user;
              return (
                <div key={key}>
                  <b>{`User: ${FirstName || '-'} ${LastName || '-'} (${Email || '-'})`}</b>
                  <ul>
                    {invalidMessages.map((invalidMessage, index) => {
                      const { field, message } = invalidMessage;
                      return (
                        <li key={index}>
                          {field} - {message}
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">
        {
          !uploadResult &&
          <Row>
            <Col md={submitting ? 12 : 7}>
              {
                !submitting &&
                <>
                  <Field
                    name='file'
                    component={FormField.InputFileDropzone}
                  />
                  <p>
                    <small>
                      Download a
                      {' '}
                      <a
                        href="#"
                        onClick={() => downloadUsersTemplate({ fileName: 'users_template.csv' })}
                      >
                        Users CSV template
                      </a>
                      {' '}
                      to see an example of the required format.
                    </small>
                  </p>
                </>

              }
              {submitting &&
                <Loading fullPage message="Uploading, Please Wait..." />}
            </Col>
            {
              !submitting &&
              <Col md={5}>
                <div className="file-info" style={{ fontSize: '30px' }}>
                  <Field
                    name="file"
                    component={FileDetail}
                    validate={[validateFileType]}
                    valid={valid}
                  />
                </div>
              </Col>
            }
          </Row>
        }
        {
          uploadResult &&
          <div>
            <Row className='results'>
              <Col>
                <div className="result gray">
                  <span>{totalUsers}</span>
                  <strong>Uploaded users</strong>
                </div>
              </Col>
              <Col>
                <div className="result green">
                  <span>{successUsers}</span>
                  <strong>Valid users</strong>
                </div>
              </Col>

              <Col>
                <div className="result red">
                  <span>{failedUsers.length}</span>
                  <strong>Exceptions</strong>
                </div>
              </Col>
            </Row>

            <p className="text-center">
              Out of
              {' '}
              <b>{totalUsers}</b>
              {' '}
              {totalUsers === 1 ? `user` : `users`}
              ,
              <b> {successUsers}</b>
              {' '}
              {successUsers === 1 ? `user was` : `users were`}
              {' '}
              successfully uploaded
              <span>
                {' '}
                and
                {' '}
                <b>{failedUsers.length}</b>
                {' '}
                {failedUsers.length === 1 ? 'user' : 'users'}
                {' '}
                had exceptions.
                {' '}
              </span>
              <br />
            </p>
            { (failedUsers || []).length > 0 &&
              getExceptionsDisplay()
            }
            <p className="text-center">  
              <small>
                Download a
                {' '}
                <a
                  href="#"
                  onClick={() => downloadUsersTemplate({ fileName: 'users_template.csv' })}
                >
                  Users CSV template
                </a>
                {' '}
                to see an example of the required format.
              </small>
            </p>
          </div>

        }

      </div>

      <div
        className="modal-footer"
        style={{ justifyContent: uploadResult ? 'center' : 'space-between' }}
      >
        {
          !uploadResult
          &&
          <>
            <Button
              variant="outline-secondary"
              type="button"
              onClick={closeModal}
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={submitting}
            >
              Upload
            </Button>
          </>
        }
        {
          uploadResult &&
          <Button
            variant="primary"
            type="button"
            onClick={closeModal}
          >
            Close
          </Button>
        }

      </div>

    </form>
  );
};

export default reduxForm({
  form: 'import-customers-user-form'
})(ImportCustomersUserForm);
