import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import fetchDux from '../../../state/fetch-dux';
import Table from '../../ui/Table';
import Icon from '../../ui/Icon';
import { actionCreators } from '../../../state/modals-dux';
import ModalConfirmDeleteSalesRep from './ModalConfirmDeleteSalesRep';
import ModalEditSalesRep from './ModalEditSalesRep';
import ModalAddSalesRep from './ModalAddSalesRep';

const getColumns = ({ handleEditSalesRep, confirmDeleteSalesRep }) => {
  return [
    {
      Header: 'First Name',
      accessor: 'FirstName'
    },
    {
      Header: 'Last Name',
      accessor: 'LastName'
    },
    {
      Header: 'Email',
      accessor: 'Email'
    },
    {
      Header: 'Primary Code',
      accessor: 'ParentCode'
    },
    {
      Header: 'Sales Rep Code',
      accessor: 'Code'
    },
    {
      Header: 'File Share Directory',
      accessor: 'RootFileShareDirectory'
    },
    {
      Header: '',
      accessor: 'ID',
      style: { textAlign: 'right' },
      width: 100,
      Cell: ({ value }) => {
        return (
          <div>
            <Button size="sm" onClick={() => handleEditSalesRep(value)}>
              <Icon name="edit" />
            </Button>
            <Button
              size="sm"
              variant="danger"
              style={{ marginLeft: '5px' }}
              onClick={() => confirmDeleteSalesRep(value)}
            >
              <Icon name="trash" />
            </Button>
          </div>
        );
      }
    }
  ];
};

const defaultSorted = [
  {
    id: 'FirstName',
    desc: false
  }
];

const getDefaultPageSize = data => {
  const { length } = data || [];
  if (!length || length < 5) return 5;
  return length;
};

class SalesRepsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { activeSalesRepID: null };
  }
  componentDidMount() {
    this.props.getSalesReps();
  }
  render() {
    const {
      result,
      inProgress,
      showModal,
      delSalesRep,
      putSalesRep,
      postSalesRep
    } = this.props;

    const { activeSalesRepID } = this.state;

    if (inProgress) return false;

    const activeRep = (result || []).find(({ ID }) => ID === activeSalesRepID);

    const { ID } = activeRep || {};

    const handleEditSalesRep = ID => {
      this.setState({ activeSalesRepID: ID });
      showModal('EditSalesRepModal');
    };

    const confirmDeleteSalesRep = ID => {
      this.setState({ activeSalesRepID: ID });
      showModal('DeleteSalesRepModal');
    };

    const handleShowAddSalesRep = () => showModal('AddSalesRepModal');

    const handleDeleteSalesRep = () => delSalesRep(ID);
    const handlePutSalesRep = values =>
      putSalesRep({ id: ID, payload: values });
    const handlePostSalesRep = values => postSalesRep({ payload: values });

    const columns = getColumns({ handleEditSalesRep, confirmDeleteSalesRep });

    return (
      <div>

        <ModalConfirmDeleteSalesRep
          name="DeleteSalesRepModal"
          activeRep={activeRep}
          onDeleteSalesRep={handleDeleteSalesRep}
        />
        <ModalEditSalesRep
          name="EditSalesRepModal"
          activeRep={activeRep}
          onPutSalesRep={handlePutSalesRep}
        />
        <ModalAddSalesRep
          name="AddSalesRepModal"
          onPostSalesRep={handlePostSalesRep}
        />
        <Row>
          <Col>
            <h2>
              Sales Reps
            </h2>
          </Col>
          <Col sm="auto">
            <Button onClick={handleShowAddSalesRep}>Add Sales Rep</Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          data={result || []}
          showPagination={false}
          defaultPageSize={getDefaultPageSize(result)}
          defaultSorted={defaultSorted}
          noDataText="No Sales Reps exist"
        />

      </div>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getSalesReps.toJS();
  return {
    result,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getSalesReps: fetchDux.getSalesReps.createAction,
      delSalesRep: fetchDux.delSalesRep.createAction,
      putSalesRep: fetchDux.putSalesRep.createAction,
      postSalesRep: fetchDux.postSalesRep.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(SalesRepsAdmin);
