import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import CreateCustomersCompanyForm from './CreateCustomersCompanyForm';
import Notification from '../../../ui/Notification';

class ModalCreateCustomersCompany extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmitCreateCompany,
      companyTypes,
      submitting
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Concept</Modal.Title>
        </Modal.Header>
        <CreateCustomersCompanyForm
          onSubmit={handleSubmitCreateCompany}
          companyTypes={companyTypes}
          closeModal={closeModal}
          submitting={submitting}
        />

      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalCreateCustomersCompany);
