import React from 'react';
import { reduxForm, Field, change } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';

const required = value => value ? undefined : 'Required';
const validateEmail = email => {
  if (!email) return undefined;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) ? undefined : 'Invalid Email';
};

const validateFieldsAsync = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      fetchDux.asyncValidateUserFields.createAction(
        values,
        null,
        null,
        response => {
          resolve(response);
        }
      )
    );
  }).then(result => {
    const { email, sageCustomerNumber, message } = result;
    if (email) {
      throw { email: message };
    }
    if (sageCustomerNumber) {
      throw { sageCustomerNumber: message };
    }
  });
};
const CreateCustomersUserForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result,
    isEdit,
    companies,
    companyIds,
    companiesDisplay,
    handleCompanyChange,
    handleRemoveCompany,
    handleHideModal,
    handleRoleChange,
    roleType,
    currentApprovers,
    purchasingApproval,
    paymentTerms,
    handleAddEmailCC,
    handleRemoveEmailCC,
    emailCC,
    change
  } = props;

  const companyOptions = (companies || [])
    .map(({ name, id }) => ({
      value: id,
      label: name
    }))
    .sort((a, b) => {
      var nameA = a.label.toUpperCase(); // ignore upper and lowercase
      var nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    })
    .filter(({ value }) => !(companyIds || []).includes(value));

  const approverOptions = (currentApprovers || [])
    .map(({ nameFirst, nameLast, id }) => ({
      value: id,
      label: `${nameFirst} ${nameLast}`
    }))
    .sort((a, b) => {
      var nameA = a.label.toUpperCase(); // ignore upper and lowercase
      var nameB = b.label.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    })
    .filter(({ value }) => !(companyIds || []).includes(value));

  const noUsers = !(currentApprovers || []).length;

  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        <Row>
          <Col sm={6}>

            <Field
              required
              name="nameFirst"
              type="text"
              component={FormField.Input}
              label="First Name"
              validate={[required]}
              insetLabel
            />

          </Col>
          <Col sm={6}>

            <Field
              required
              name="nameLast"
              type="text"
              component={FormField.Input}
              label="Last Name"
              validate={[required]}
              insetLabel
            />

          </Col>
          <Col sm={6}>

            <Field
              required
              name="email"
              type="email"
              component={FormField.Input}
              label="Email"
              validate={[required, validateEmail]}
              insetLabel
            />

          </Col>
          <Col sm={6}>

            <Field
              required
              name="phone"
              type="text"
              component={FormField.Input}
              label="Phone"
              validate={[required]}
              insetLabel
            />

          </Col>
        </Row>

        <Field
          name="sageCustomerNumber"
          type="text"
          component={FormField.Input}
          label="Sage Customer Number"
          insetLabel
        />

        <Field
          required
          name="assignConcept"
          type="text"
          component={FormField.SelectReact}
          label="Assign Concept"
          placeholder=""
          options={companyOptions}
          isSearchable
          isClearable
          insetLabel
          onChange={e => {
            if (handleCompanyChange) {
              handleCompanyChange(e);
              change('userIDs', '');
            }
          }}
        />

        <Field
          name="userExpiryDate"
          type="date"
          component={FormField.Datepicker}
          label="User Expiry Date"
          placeholder=""
          insetLabel
        />

        {
          !isEdit &&
          <Field
            name='sendWelcomeEmail'
            component={FormField.InputToggle}
            size='sm'
            label='Send Welcome Email'
          />
        }


        <div className="pill-group">
          {(companiesDisplay || []).map(({ name, id }, index) => (
            <span key={index} className="pill">
              {name}
              <Icon
                name="times"
                onClick={() => handleRemoveCompany(id)}
                style={{ cursor: 'pointer' }}
              />
            </span>
          ))}
        </div>

        <h5>User Role</h5>

        <Row>
          <Col sm={6}>

            <Field
              name="role"
              component={FormField.InlineRadio}
              label="Standard"
              onClick={() => handleRoleChange(1)}
              input={{ value: roleType === 1 }}
            />

          </Col>
          <Col sm={6}>

            <Field
              name="role"
              component={FormField.InlineRadio}
              label="Customer Advocate"
              onClick={() => handleRoleChange(2)}
              input={{ value: roleType === 2 }}
            />

          </Col>
        </Row>

        <Field
          size="sm"
          name="isApprover"
          component={FormField.InputToggle}
          label="Is this user an Approver?"
        />

        <Field
          size="sm"
          name="purchasingApproval"
          component={FormField.InputToggle}
          label="Does user have a spending limit and/or require purchase approval?"
        />
        {purchasingApproval &&
          <Row>
            <Col sm={5}>

              <Field
                name="spendingLimit"
                type="text"
                component={FormField.Input}
                label="Spending Limit"
                disabled={!purchasingApproval}
                insetLabel
              />

            </Col>
            <Col sm={7}>

              <Field
                required
                name="requiredApprover"
                type="text"
                component={FormField.SelectReact}
                label="Required Approver"
                placeholder=""
                isSearchable
                isClearable
                isDisabled={noUsers || !purchasingApproval}
                options={approverOptions}
                validate={[required]}
                insetLabel
              />

            </Col>
          </Row>}

        <Field
          size="sm"
          name="paymentTerms"
          component={FormField.InputToggle}
          label="Does user have payment terms?"
        />
        {paymentTerms &&
          <div>
            <Field
              name="ccEmailInvoice"
              type="email"
              component={FormField.Input}
              label="CC Email Invoice"
              validate={[validateEmail]}
              insetLabel
            />

            <Button
              variant="outline-secondary"
              type="button"
              onClick={() => {
                const addedToCC = handleAddEmailCC();
                if (addedToCC) {
                  change('ccEmailInvoice', '');
                }
              }}
            >
              Add Email CC
            </Button>

            <div className="pill-group">
              {(emailCC || []).map((email, index) => (
                <span key={index} className="pill">
                  {email}
                  <Icon
                    name="times"
                    cursor={'pointer'}
                    onClick={() => handleRemoveEmailCC(email)}
                  />
                </span>
              ))}
            </div>
          </div>}

        <h5>Available Payment Options</h5>

        <Row>
          <Col sm={6}>

            <Field
              name="paymentOptionCreditCard"
              component={FormField.InlineCheckbox}
              label="Credit Card"
            />

          </Col>
          <Col sm={6}>

            <Field
              name="paymentOptionPO"
              component={FormField.InlineCheckbox}
              label="PO"
            />

          </Col>
          <Col sm={6}>

            <Field
              name="paymentOptionCheck"
              component={FormField.InlineCheckbox}
              label="Check"
            />

          </Col>
          <Col sm={6}>

            <Field
              name="paymentOptionACH"
              component={FormField.InlineCheckbox}
              label="ACH"
            />

          </Col>
        </Row>

        <h5>Website Access</h5>

        <Row>
          <Col sm={4}>

            <Field
              name="websiteAccessShop"
              component={FormField.InlineCheckbox}
              label="Shop"
            />

          </Col>
          <Col sm={4}>

            <Field
              name="websiteAccessQuotes"
              component={FormField.InlineCheckbox}
              label="Quotes"
            />

          </Col>
          <Col sm={4}>

            <Field
              name="websiteAccessDocuments"
              component={FormField.InlineCheckbox}
              label="Documents"
            />

          </Col>
          <Col sm={4}>

            <Field
              name="websiteAccessOrders"
              component={FormField.InlineCheckbox}
              label="Orders"
            />

          </Col>
          <Col sm={4}>

            <Field
              name="websiteAccessLocations"
              component={FormField.InlineCheckbox}
              label="Locations"
            />

          </Col>
          <Col sm={4}>

            <Field
              name="websiteAccessInvoices"
              component={FormField.InlineCheckbox}
              label="Invoices"
            />

          </Col>
        </Row>
        <Row>
          <Col sm={12} />
        </Row>
      </div>
      <div className="modal-footer">
        <Button
          variant="outline-secondary"
          type="button"
          onClick={handleHideModal}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          {!isEdit && <span>Create User</span>}
          {isEdit && <span>Update User</span>}
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'create-customers-user-form',
  asyncValidate: validateFieldsAsync,
  asyncBlurFields: ['email', 'sageCustomerNumber']
})(CreateCustomersUserForm);
