import React from 'react';
import { connect } from 'react-redux';

const IfAdmin = ({ user, children }) => {
  const { isAdmin } = user || {};
  if (!isAdmin) return false;
  return children;
};

const mapState = state => {
  const { result: user } = state.login.toJS();

  return {
    user
  };
};

export default connect(mapState)(IfAdmin);
