import React, { Component } from 'react';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import Icon from '../../../../ui/Icon';
import { Button } from 'react-bootstrap';

import Step1 from './Step1';
import ManagePOShippingInfoTable from './ManagePOShippingInfoTable';
import ViewShippingSummary from './ViewShippingSummary';
import Loading from '../../../../ui/Loading';
import ModalConfirmChanges from './ModalConfirmChanges';
import { actionCreators } from '../../../../../state/modals-dux';

const getInitialValues = (
  {
    poShippingValues,
    purchaseOrders
  }
) => {
  if (poShippingValues) return poShippingValues;
  const values = (purchaseOrders || []).map(({ ID }) => ({
    ID,
    checked: false
  }));
  return { purchaseOrders: values };
};

const getProjectAddressInitialValues = (
  {
    c_WarehouseID,
    c_ShipViaId,
    c_ShipDate,
    c_SpecialInstructions,
    Name,
    FirstName,
    LastName,
    PhoneContact,
    PhoneFax,
    Address1,
    Address2,
    City,
    State,
    Zip,
    c_Zip_4,
    c_AttnNameOnly
  }
) => ({
  c_WarehouseID: c_ShipViaId ? c_WarehouseID || 'other' : null,
  c_ShipViaId,
  c_ShipDate,
  c_SpecialInstructions,
  Name,
  FirstName,
  LastName,
  PhoneContact,
  PhoneFax,
  Address1,
  Address2,
  City,
  State,
  Zip,
  c_Zip_4,
  c_AttnNameOnly: `${!!c_AttnNameOnly}`
});

class UpdateShippingInfo extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const {
      result,
      lookups,
      projectAddressValues,
      purchaseOrderAddressValues,
      setProjectAddress,
      toggleGroup,
      activeGroup,
      handleSubmitPOShipping,
      poShippingValues,
      handleFinalSubmit,
      showModal,
      hasChanges,
      postUpdateProjectShippingInProgress
    } = this.props;
    const {
      purchaseOrders,
      addresses,
      projectAddress
    } = result || {};

    const projectAddressInitialValues = getProjectAddressInitialValues(
      projectAddressValues || projectAddress || {}
    );

    return (
      <div className="update-shipping-info-accordion">

        <div className={`accordion-group ${activeGroup === 1 ? 'active' : ''}`}>
          <div
            className="accordion-group-header"
            onClick={() => toggleGroup(1)}
          >
            <div><span>1</span> Manage Default Shipping Info</div>
            <Icon name={activeGroup === 1 ? 'angle-down' : 'angle-right'} />
          </div>
          <div className="accordion-group-body">
            {activeGroup === 1 &&
              <Step1
                lookups={lookups}
                active={activeGroup === 1}
                setProjectAddress={setProjectAddress}
                projectAddressInitialValues={projectAddressInitialValues}
              />}
          </div>
        </div>

        <div className={`accordion-group ${activeGroup === 2 ? 'active' : ''}`}>
          <div
            className="accordion-group-header"
            onClick={() => toggleGroup(2)}
          >
            <div><span>2</span> Manage P.O. Shipping Information</div>
            <Icon name={activeGroup === 2 ? 'angle-down' : 'angle-right'} />
          </div>
          <div className="accordion-group-body">
            {activeGroup === 2 &&
              <ManagePOShippingInfoTable
                purchaseOrders={purchaseOrders}
                addresses={addresses}
                lookups={lookups}
                projectAddressValues={
                  projectAddressValues || projectAddressInitialValues
                }
                purchaseOrderAddressValues={purchaseOrderAddressValues}
                onSubmit={handleSubmitPOShipping}
                initialValues={getInitialValues({
                  poShippingValues,
                  purchaseOrders
                })}
              />}
          </div>
        </div>

        <div className={`accordion-group ${activeGroup === 3 ? 'active' : ''}`}>
          <div
            className="accordion-group-header"
            onClick={() => toggleGroup(3)}
          >
            <div><span>3</span> View Shipping Summary</div>
            <Icon name={activeGroup === 3 ? 'angle-down' : 'angle-right'} />
          </div>
          <div className="accordion-group-body">
            {postUpdateProjectShippingInProgress && <Loading />}
            {!postUpdateProjectShippingInProgress &&
              activeGroup === 3 &&
              <ViewShippingSummary
                purchaseOrders={purchaseOrders}
                addresses={addresses}
                projectAddressValues={
                  projectAddressValues || projectAddressInitialValues
                }
                poShippingValues={poShippingValues}
                lookups={lookups}
              />}
          </div>
        </div>

        <div className="text-right" style={{ marginTop: '15px' }}>
          <Button
            disabled={!hasChanges}
            variant="success"
            style={hasChanges ? {} : { cursor: 'not-allowed' }}
            onClick={() =>
              hasChanges ? showModal('ConfirmShippingChanges') : false}
          >
            Finalize & Submit to Sage
          </Button>
        </div>
        <ModalConfirmChanges
          name="ConfirmShippingChanges"
          handleSubmit={handleFinalSubmit}
        />

      </div>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();
  return {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProjectById: fetchDux.getProjectById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(UpdateShippingInfo);
