import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown } from 'react-bootstrap';
import moment from 'moment';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Table from '../../../ui/Table';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCustomersEditUser from '../customers-users/ModalEditUser';
import ModalCustomersDeleteUser from '../customers-users/ModalDeleteUser';

import Notification from '../../../ui/Notification';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  sortBy: sortBy || 'companyName',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

const columns = [
  {
    Header: 'Concept Name',
    accessor: 'companyName',
    Cell: ({ value, original }) => (
      <Link to={`/customers/concepts/${(original || {}).companyID}`}>
        {value}
      </Link>
    )
  },
  {
    Header: 'Locations',
    accessor: 'numLocations',
    Cell: ({ value }) => <div>{value || '0'} Locations</div>
  },
  {
    Header: 'Users',
    accessor: 'numUsers',
    Cell: ({ value }) => <div>{value || '0'} Users</div>
  },
  {
    Header: 'Products',
    accessor: 'numProducts',
    Cell: ({ value }) => <div>{value || '0'} Products</div>
  }
];

class CustomersUsersConcepts extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const {
      match,
      getCustomersUserById,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getCustomersUserById(
      {
        id,
        query: {
          getPaymentOptions: 1,
          getWebsiteOptions: 1,
          getConcepts: 1,
          getPurchaseApprover: 1,
          getEmailCCs: 1
        }
      },
      null,
      true
    );
    searchCustomersCompanies();
  }
  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      result,
      location: { search: searchURL },
      showModal,
      match,
      searchCustomersCompaniesResult,
      getCustomersUsersByIdError,
      searchCustomersCompaniesError,
      clearCustomersUserById,
      clearSearchCustomersCompanies,
      clearDeleteUserById,
      deleteUserByIdError,
      deleteUserById,
      deleteUserByIdInProgress,
      history
    } = this.props;

    const { id: companyId } = match.params || {};

    const {
      user,
      userCompanies
    } = result || {};

    const {
      nameFirst,
      nameLast,
      created,
      id
    } = user || {};

    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const userDays = moment().diff(moment(created), 'days');

    const handleDeleteUser = () => {
      deleteUserById(id, null, null, () => {
        history.push(`/customers/users`);
      });
    };

    return (
      <Layout route="customers-users-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/users" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {nameFirst || ''} {nameLast || ''}
              </h2>
              <h3>
                {!inProgress && <span>User for {userDays} days</span>}
              </h3>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" size="sm">
                <Icon fixedWidth name="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersUserEdit')}
                >
                  Edit User
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersUserDelete')}
                >
                  Delete User
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/users/${companyId}/details`}>
                  Details
                </Link>
              </li>
              <li className="active">
                <Link to={`/customers/users/${companyId}/concepts`}>
                  Concepts
                </Link>
              </li>
              <li>
                <Link to={`/customers/users/${companyId}/locations`}>
                  Locations
                </Link>
              </li>
            </ul>
          </Container>
        </div>
        {!inProgress &&
          <Container>
            <Table
              showSearch
              data={userCompanies || []}
              columns={columns}
              search={search}
              noDataText="No Concepts Found"
              onFetchData={this.handleTableChange}
              onSearchChange={this.handleSearchChange}
              defaultSorted={sorted}
              page={getCustomersUsersByIdError ? 0 : Number(page)}
              defaultPageSize={Number(pageSize)}
              inProgress={inProgress}
            />
          </Container>}
        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}
        {user &&
          <ModalCustomersEditUser
            name="modalCustomersUserEdit"
            result={result}
            companies={searchCustomersCompaniesResult}
          />}
        {user &&
          <ModalCustomersDeleteUser
            name="modalCustomersUserDelete"
            handleSubmit={handleDeleteUser}
            inProgress={deleteUserByIdInProgress}
          />}

        {getCustomersUsersByIdError &&
          <Notification
            key="user-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearCustomersUserById}
          >
            Failed to load user concepts. Please refresh the page.
          </Notification>}

        {deleteUserByIdError &&
          <Notification
            key="user-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearDeleteUserById}
          >
            Failed to delete user. Please try again.
          </Notification>}

        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getCustomersUsersByIdInProgress,
    error: getCustomersUsersByIdError
  } = state.getCustomersUserById.toJS();

  const {
    result: searchCustomersCompaniesResult,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    inProgress: deleteUserByIdInProgress,
    error: deleteUserByIdError
  } = state.deleteUserById.toJS();

  return {
    result,
    searchCustomersCompaniesResult,
    inProgress: getCustomersUsersByIdInProgress,
    getCustomersUsersByIdError,
    deleteUserByIdError,
    deleteUserByIdInProgress,
    searchCustomersCompaniesError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCustomersUserById: fetchDux.getCustomersUserById.createAction,
      clearCustomersUserById: fetchDux.getCustomersUserById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction,
      deleteUserById: fetchDux.deleteUserById.createAction,
      clearDeleteUserById: fetchDux.deleteUserById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersUsersConcepts);
