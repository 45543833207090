import React from 'react';
import numeral from 'numeral';
import { Row, Col, Dropdown, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';

const required = value => value ? undefined : 'Required';
export const formatMoney = d => `$${numeral(d || 0).format('0,0.00')}`;

const convertToRoman = num => {
  const roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  };
  let str = '';
  for (let i of Object.keys(roman)) {
    const q = Math.floor(num / roman[i]);
    num -= q * roman[i];
    str += i.repeat(q);
  }
  return str;
};

const renderQuoteItems = (
  {
    fields,
    category,
    idx: index,
    categories,
    handleFormFieldChange,
    quoteItems,
    maxLinesReached
  }
) => (
  <div className="category">
    <div
      style={{
        float: 'right',
        marginTop: '0.65rem',
        marginRight: '-10px'
      }}
    >
      <div className="col-sm-1" style={{ marginLeft: '-10px' }}>
        <Button
          variant="danger"
          size="sm"
          onClick={() => categories.remove(index)}
        >
          <Icon name="trash" className="danger" />
        </Button>
      </div>
    </div>
    <Button
      variant="outline-primary"
      onClick={() => fields.push()}
      disabled={maxLinesReached}
    >
      <Icon name="plus" /> Line Item
    </Button>
    <strong>
      <Field
        name={`${category}.CategoryName`}
        type="text"
        placeholder="Category name *"
        component={FormField.Input}
        validate={[required]}
        maxLength={70}
      />
    </strong>
    {fields.length > 0 &&
      <div className="items">
        <Row className="item-header">
          <Col sm="3">Line Item Name *</Col>
          <Col sm="2">Price *</Col>
          <Col sm="2">Optional</Col>
          <Col sm="2">Selected</Col>
          <Col sm="2">Taxable</Col>
          <Col sm="1" />
        </Row>
        {fields.map((item, idx) => {
          const quoteItem = (quoteItems || [])[idx];
          const { IsOptional } = quoteItem || {};
          return (
            <Row className="item-body">
              <Col sm="3">
                <Field
                  name={`${item}.LineItemName`}
                  type="text"
                  component={FormField.Input}
                  validate={[required]}
                  maxLength={45}
                />
              </Col>
              <Col sm="2">
                <Field
                  name={`${item}.Price`}
                  type="text"
                  component={FormField.Input}
                  validate={[required]}
                  maxLength={10}
                />
              </Col>
              <Col sm="2">
                <Field
                  name={`${item}.IsOptional`}
                  size="sm"
                  component={FormField.InputToggle}
                  onChange={e => {
                    const checked = e.target.checked;
                    if (!checked) {
                      handleFormFieldChange(
                        'edit-quote-form',
                        `${item}.Selected`,
                        true
                      );
                    }
                  }}
                />
              </Col>
              <Col sm="2">
                <Field
                  name={`${item}.Selected`}
                  size="sm"
                  component={FormField.InputToggle}
                  disabled={!IsOptional}
                />
              </Col>
              <Col sm="2">
                <Field
                  name={`${item}.isTaxable`}
                  size="sm"
                  component={FormField.InputToggle}
                />
              </Col>
              <Col sm="1">
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => fields.remove(idx)}
                >
                  <Icon name="trash" className="danger" />
                </Button>
              </Col>
            </Row>
          );
        })}
      </div>}
  </div>
);

const renderQuoteCategories = (
  {
    fields,
    section,
    index,
    sections,
    handleFormFieldChange,
    quoteSection,
    maxLinesReached
  }
) => (
  <div className="section">
    <div
      style={{
        float: 'right',
        marginTop: '0.65rem',
        marginRight: '-10px'
      }}
    >
      <div className="col-sm-1" style={{ marginLeft: '-10px' }}>
        <Button
          variant="danger"
          size="sm"
          onClick={() => sections.remove(index)}
        >
          <Icon name="trash" className="danger" />
        </Button>
      </div>
    </div>

    <Button
      variant="outline-primary"
      onClick={() => fields.push({})}
      style={{ background: 'white' }}
      disabled={maxLinesReached}
    >
      <Icon name="plus" /> Category
    </Button>

    <strong>
      {convertToRoman(index + 1)}.
      <Field
        required
        name={`${section}.SectionName`}
        type="text"
        placeholder="Section name *"
        component={FormField.Input}
        validate={[required]}
        maxLength={70}
      />
    </strong>
    <div className="categories">
      {fields.map((category, idx) => {
        const { quoteCategories } = quoteSection || {};
        const [quoteCategory] = quoteCategories || [];
        const { quoteItems } = quoteCategory || {};
        return (
          <div key={idx}>
            <FieldArray
              name={`${category}.quoteItems`}
              component={renderQuoteItems}
              props={{
                category,
                idx,
                categories: fields,
                handleFormFieldChange,
                quoteItems,
                maxLinesReached
              }}
            />
          </div>
        );
      })}
    </div>
  </div>
);

const renderQuoteSections = (
  { fields, handleFormFieldChange, quoteSections, maxLinesReached }
) => (
  <div className="sections">
    {fields.map((section, index) => {
      return (
        <div key={index}>
          <FieldArray
            name={`${section}.quoteCategories`}
            component={renderQuoteCategories}
            props={{
              section,
              index,
              sections: fields,
              handleFormFieldChange,
              quoteSection: (quoteSections || [])[index],
              maxLinesReached
            }}
          />
        </div>
      );
    })}
    <div className="form-group-section">
      <Button
        variant="outline-primary"
        size="sm"
        onClick={() => fields.push({})}
        disabled={maxLinesReached}
      >
        <Icon name="plus" /> Section
      </Button>
    </div>
  </div>
);

const ProposalSummaryForm = (
  {
    total,
    taxToBeDetermined,
    quoteTemplates,
    handleFormFieldChange,
    quoteSections,
    salesTaxPercentage,
    maxLinesReached
  }
) => {
  const quoteTemplateOptions = (quoteTemplates || [])
    .map(({ ID, TemplateName }) => ({ label: TemplateName, value: ID }));
  return (
    <div className="quote-proposal-summary">
      <div className="form-group-templates">
        <Field
          name="QuoteTemplateID"
          placeholder="Templates"
          type="text"
          options={quoteTemplateOptions}
          component={FormField.SelectReact}
          onChange={value => {
            const template = (quoteTemplates || [])
              .find(({ ID }) => ID === value);
            const { quoteSections } = template || {};
            handleFormFieldChange(
              'edit-quote-form',
              'quoteSections',
              quoteSections
            );
          }}
        />
      </div>
      <FieldArray
        name="quoteSections"
        component={renderQuoteSections}
        props={{ handleFormFieldChange, quoteSections, maxLinesReached }}
      />
      <Row className="row-totals">
        <Col />
        <Col>
          <table className="table-total">
            <thead>
              <tr>
                <th colSpan={2}>Deposit Requirements</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Deposit Percentage *</th>
                <td>
                  <Field
                    name="Deposit"
                    type="text"
                    placeholder="Deposit Percentage"
                    component={FormField.Input}
                    validate={[required]}
                  />
                </td>
              </tr>
              <tr>
                <th>Deposit Invoice</th>
                <td>{formatMoney(total.deposit)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
        <Col>
          <table className="table-total">
            <tbody>
              <tr>
                <th>Services</th>
                <td>{formatMoney(total.services)}</td>
              </tr>
              <tr>
                <th>Material Goods</th>
                <td>{formatMoney(total.materialGoods)}</td>
              </tr>
              <tr>
                <th>Subtotal</th>
                <td>{formatMoney(total.subTotal)}</td>
              </tr>
              {taxToBeDetermined
                ? <tr
                    style={{
                      marginBottom: salesTaxPercentage < 1 ? '20px' : ''
                    }}
                  >
                    <th>Tax to be determined</th>
                    <td />
                    {/* <td>
                    <Field
                      name="TaxToBeDetermined"
                      type="text"
                      placeholder="Tax Tax"
                      component={FormField.Input}
                      validate={[required]}
                    />
                  </td> */}
                  </tr>
                : <tr
                    style={{
                      marginBottom: salesTaxPercentage < 1 ? '20px' : ''
                    }}
                  >
                    <th
                      style={{
                        paddingBottom: salesTaxPercentage < 1 ? '20px' : ''
                      }}
                    >
                      Sales Tax PERCENTAGE *
                    </th>
                    <td
                      style={{
                        paddingBottom: salesTaxPercentage < 1 ? '20px' : ''
                      }}
                    >
                      <Field
                        name="SalesTax"
                        type="text"
                        placeholder="Sales Tax"
                        component={FormField.Input}
                        validate={[required]}
                      />
                    </td>
                  </tr>}
              {salesTaxPercentage < 1 &&
                !taxToBeDetermined &&
                <div
                  style={{
                    marginTop: '0.5rem',
                    color: 'red',
                    position: 'absolute',
                    bottom: '47px',
                    left: '20px'
                  }}
                >
                  Tax % is less than one, is this intentional?
                </div>}
              <tr>
                <th>Grand Total</th>
                <td>{formatMoney(total.grandTotal)}</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
    </div>
  );
};

export default ProposalSummaryForm;
