import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Container } from 'react-bootstrap';
import queryString from 'query-string';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Loading from '../../../ui/Loading';
import ShipmentsForm from './ShipmentsForm';
import fetchDux from '../../../../state/fetch-dux';
import ModalShipmentLogs from './ModalShipmentLogs';
import { actionCreators } from '../../../../state/modals-dux';
import ModalWebTrack from './ModalWebTrack';
import ModalDeleteShipment from './ModalDeleteShipment';
import Icon from '../../../ui/Icon';
import { cloneDeep } from 'lodash';

const CARRIERS = [
  {
    value: 'aaacooper',
    label: 'AAA Cooper Transportation'
  },
  {
    value: 'A DUIE PYLE',
    label: 'A Duie Pyle' //Not found
  },
  {
    value: 'averittexpress',
    label: 'AVERITT Express'
  },
  {
    value: 'abf',
    label: 'ABF Freight'
  },
  {
    value: 'estes',
    label: 'Estes'
  },
  {
    value: 'fedex',
    label: 'Fedex'
  },
  {
    value: 'old-dominion',
    label: 'Old Dominion Freight Line'
  },
  {
    value: 'rl-carriers',
    label: 'RL Carriers'
  },
  {
    value: 'saia-freight',
    label: 'Saia LTL Freight'
  },
  {
    value: 'se-freightlines',
    label: 'Southeastern Freightlines'
  },
  {
    value: 'ups',
    label: 'UPS'
  },
  {
    value: 'USF',
    label: 'USF Holland' //Not found
  },
  {
    value: 'usps',
    label: 'USPS'
  },
  {
    value: 'xpoweb',
    label: 'XPO Logistics'
  },
  {
    value: 'YRC',
    label: 'YRC' //Not found
  }
];

const getInitialValues = (
  {
    shipments,
    shipmentId,
    lineItems,
    copy,
    CARRIERS,
    purchaseOrder,
    poId,
    totalCarriers,
    defaultCarriers
  }
) => {
  const {
    c_PurchaseOrderNumber,
    VendorName
  } = purchaseOrder || {};

  const shipment = shipments.find(
    s => s.ID === parseInt(shipmentId || copy)
  ) || {};

  const shipmentNum = shipments.map(s => s.ShipmentNum).sort().pop() || 0;

  const { length: itemCount } = (lineItems || [])
    .filter(({ NetPrice }) => NetPrice > 0);

  const additionalFields = !shipmentId
    ? {
      ID: 'new',
      ShipmentNum: shipmentNum,
      PurchaseOrderId: poId,
      ShipmentDesc: `${VendorName || '-'} ${itemCount} ${itemCount > 1 ? 'Assorted items' : 'Assorted Item'}`
    }
    : {};

  let { Carrier } = shipment || {};
  let c = (defaultCarriers || []).find(({ value }) => value === Carrier);
  Carrier = (Carrier || '').toLowerCase();
  const carrierName = Carrier === 'saia'
    ? 'saia-freight'
    : Carrier === 'dayton'
      ? 'dayton-freight'
      : Carrier === 'rlc' ? 'rl-carriers' :
        Carrier === 'southeastern' ?
          'se-freightlines' :
          Carrier === 'usf' ?
            'usf-reddaway' :
            Carrier === 'xpo' ?
              'xpoweb' :
              Carrier === 'averitt' ?
                'averittexpress' :
                Carrier || '';

  const courier = (totalCarriers || []).find((c) => {
    const { courier_code, courier_name } = c || {};
    if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('-')) ||
      (courier_name || '').toLowerCase() === (carrierName.split(' ').join('-'))) {
      return c;
    }
    else if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('')) ||
      (courier_name || '').toLowerCase() === (carrierName.split(' ').join(''))) {
      return c;
    }
  })
  // let trackingMore = !!courier;
  // const isDefaultCarrier = !!c;
  if (!c) {
    c = courier;
    if (c) {
      CARRIERS.splice(0, 0, { label: c.courier_name, value: c.courier_code });
    }
  }

  const carrierValues = Carrier
    ? {
      Carrier: {
        value: c ? c.courier_code ? c.courier_code : carrierName : carrierName,
        label: c ? c.label || c.courier_name : Carrier
      }
    }
    : {};

  const { ManualTracking } = shipment;

  return {
    ...(shipment || {}),
    ...carrierValues,
    ...additionalFields,
    poNumber: c_PurchaseOrderNumber,
    VendorName,
    // trackingMore,
    // isDefaultCarrier,
    // manualTracking: (isDefaultCarrier || trackingMore === false),
    defaultCarriers,
    manualTracking: ManualTracking || false
  };
};

class ShipmentsDetail extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
      openTabs: {
        poForm: true,
        generalForm: true,
        vendorInformation: true,
        carrierForm: true,
        warehouseForm: true
      },
      defaultCarriers: cloneDeep(CARRIERS)
    };
  }

  fetchData({ getPoShipments, getShipmentLogs, match }) {
    const { id, poId } = match.params;
    getPoShipments(poId);
    if (id) getShipmentLogs(id);
  }

  componentDidMount() {
    this.fetchData(this.props);
    this.props.getAllCarriers();
  }

  componentWillUnmount() {
    this.props.clearTrackingMoreTracking()
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search }, match: { params: { poId } } } = this.props;
    if (search !== nextProps.location.search) this.fetchData(nextProps);
    if (poId !== nextProps.match.params.poId) this.fetchData(nextProps);
  }

  handleSubmit(value) {
    const { postShipment, updateShipment, match, history, result } = this.props;
    const { vendors } = result;
    const { id, poId } = match.params;
    const { ID, ...payload } = value;
    const search = queryString.stringify({ t: new Date().getTime() });
    if (payload.Carrier && payload.Carrier.value)
      payload.Carrier = payload.Carrier.value;

    // remove carrierLink
    delete payload.CarrierLink;
    delete payload.trackingMore;
    delete payload.trackingMoreId;
    delete payload.isDefaultCarrier;
    delete payload.defaultCarriers;
    delete payload.ManualTracking;
    // delete payload.TrackingMore_Tracking_ID;

    const sageVendor = vendors.find(({ Name }) => Name === payload.VendorName);
    payload.sageVendorId = sageVendor && sageVendor.selectedSageVendorId;
    delete payload.VendorName;

    const { DeliveryStatus, OverrideDeliveryStatus } = payload;
    const shipmentId = ID || id;
    if (shipmentId === 'new')
      postShipment(
        {
          ...payload,
          DeliveryStatus: parseInt(DeliveryStatus),
          OverrideDeliveryStatus: parseInt(OverrideDeliveryStatus),
        }, null, null, data => {
          const pathname = `/shipments/${data.ID}/${poId}`;
          history.replace({ pathname, search });
        });
    else
      updateShipment(
        {
          id: shipmentId,
          payload: {
            ...payload,
            DeliveryStatus: parseInt(DeliveryStatus),
            OverrideDeliveryStatus: parseInt(OverrideDeliveryStatus),
          }
        }, null, null, data =>
        history.push({ pathname: `/shipments/${data.ID}/${poId}`, search }));
  }

  render() {
    const {
      result,
      error,
      inProgress,
      match,
      history,
      location,
      showModal,
      closeModal,
      shipmentLogs,
      webTrack,
      webTrackInProgress,
      getCarrierByName,
      clearGetCarrierByName,
      carriers,
      totalCarriers,
      shipmentsSearch
    } = this.props;
    const { id: shipmentId, poId } = match.params || {};
    const hasLogs = (shipmentLogs || [])
      .find(f => f.ShipmentId === parseInt(shipmentId));

    const { openTabs } = this.state;
    const setOpen = (value, name) => {
      this.setState({ openTabs: { ...openTabs, [name]: value } });
    };

    const getFormProps = () => {
      const {
        shipments = [],
        carriers = [],
        purchaseOrder,
        shippingAddress = {},
        deliveryStatusCodes = {},
        lineItems = [],
        shipper,
        vendors = [],
        poResults = []
      } = result || {};
      const {
        c_PurchaseOrderNumber,
        VendorNum,
        VendorName,
        c_IsImported
      } = purchaseOrder || {};
      const { copy } = queryString.parse(location.search) || {};

      const shipment = shipments.find(
        s => s.ID === parseInt(shipmentId || copy)
      ) || {};

      // const carrierOptions = carriers.map(s => ({ value: s, label: s }));
      const shipmentOptions = shipments.map(s => ({
        value: s.ID,
        label: [
          c_PurchaseOrderNumber,
          s.ShipmentNum,
          VendorNum,
          s.ShipmentDesc
        ].join(' | ')
      }));

      const initialValues = getInitialValues({
        shipments,
        shipmentId,
        lineItems,
        copy,
        CARRIERS,
        purchaseOrder,
        poId,
        totalCarriers,
        defaultCarriers: this.state.defaultCarriers
      });

      // new
      if (!shipmentId) {
        const shipmentNum = (shipments || []).length + 1;
        shipmentOptions.push({
          value: 'new',
          label: [
            c_PurchaseOrderNumber,
            shipmentNum,
            VendorNum,
            initialValues.ShipmentDesc
          ].join(' | ')
        });
      }

      const generalInfo = {
        VendorNum,
        OrderEntryNum: (c_PurchaseOrderNumber || '').split('-')[0],
        ShipVia: shippingAddress.c_ShipViaId,
        ShipViaName: '',
        CarrierException: shipment.CarrierException ? 'Yes' : 'No',
        shipper,
        ShipTo: [
          shippingAddress.Name,
          shippingAddress.Address1,
          shippingAddress.Address2,
          shippingAddress.City,
          shippingAddress.State + ' ' + shippingAddress.Zip,
          shippingAddress.PhoneCell,
          shippingAddress.PhoneContact
        ],
        shippingAddress,
        c_PurchaseOrderNumber
      };

      const vendorOptions = vendors.map(v => {
        return {
          value: v.Name,
          label: v.Name
        };
      });

      const purchaseOrderOptions = poResults.map(({
        c_PurchaseOrderNumber,
        ID,
        ShipmentID,
        VendorName
      }) => {
        return {
          value: c_PurchaseOrderNumber,
          label: `${c_PurchaseOrderNumber} | ${VendorName}`,
          ID,
          ShipmentID
        };
      });

      return {
        shipmentOptions,
        // carrierOptions,
        generalInfo,
        deliveryStatusCodes,
        initialValues,
        vendorOptions,
        c_IsImported,
        purchaseOrderOptions,
        poVendorName: VendorName
      };
    };

    const handleShipmentChange = id => {
      const search = queryString.stringify({ t: new Date().getTime() });
      history.push({ pathname: `/shipments/${id}/${poId}`, search });
    };

    const handleShipmentCopy = () => {
      const search = queryString.stringify({
        t: new Date().getTime(),
        copy: shipmentId
      });
      history.push({ pathname: `/shipments/new/${poId}`, search });
    };

    const handleShipmentAdd = () => {
      const search = queryString.stringify({ t: new Date().getTime() });
      history.push({ pathname: `/shipments/new/${poId}`, search });
    };

    const handleShowCarrierPage = value => {
      if ((value.CarrierLink || '').trim().length > 0) {
        window.open(
          value.CarrierLink,
          '_blank',
          'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
        );
      }
    };

    // const handleWebTrack = value => {
    //   this.props.getWebTracking(value, null, null, data => {
    //     handleShowCarrierPage(data);
    //   });
    //   showModal('WebTrackModal');
    // };

    // const handleWebTrack = value => {
    //   this.props.getTrackingMoreTracking(value, null, null, (result) => {
    //     const formProps = getFormProps();
    //     const { initialValues } = formProps;
    //     const { manualTracking } = initialValues;
    //     if (manualTracking) {
    //       const { CarrierLink } = result;
    //       if ((CarrierLink || '').trim().length > 0) {
    //         window.open(
    //           CarrierLink,
    //           '_blank',
    //           'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
    //         );
    //       }
    //     } else {
    //       showModal('WebTrackModal');
    //     }
    //     this.fetchData(this.props);
    //   });
    // };

    const handleWebTrack = value => {
      this.props.getTrackingMoreTracking({ ...value, ID: shipmentId }, null, null, result => {
        const formProps = getFormProps();
        const { initialValues } = formProps;
        const { manualTracking } = initialValues;
        const { shipmentId: id } = result;
        if (manualTracking) {
          const { CarrierLink } = result;
          if ((CarrierLink || '').trim().length > 0) {
            window.open(
              CarrierLink,
              '_blank',
              'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
            );
          }
        } else {
          showModal('WebTrackModal');
        }
        const { location: { search } } = this.props;
        const newSearch = queryString.stringify({
          ...queryString.parse(search),
          t: Date.now()
        });
        history.push({
          pathname: `/shipments/${(id || shipmentId)}/${poId}`,
          search: newSearch
        });
      });
    };

    const handleDeleteShipment = option => {
      const { shipments = [] } = result || {};
      const nextShipment = []; //shipments.filter(f => f.ID !== Number(shipmentId));
      this.props.deleteShipment(
        { id: shipmentId, option, poId },
        null,
        null,
        data => {
          const search = queryString.stringify({ t: new Date().getTime() });
          const pathname = nextShipment.length > 0
            ? `/shipments/${nextShipment[0].ID}/${poId}`
            : '/shipments';
          closeModal();
          history.push({ pathname, search });
        }
      );
    };

    const { result: webTrackResult } = webTrack || {};
    const { trackingLink, CarrierLink } = webTrackResult || {};

    return (
      <Layout route="shipments-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
              {shipmentsSearch &&
                <Icon
                  onClick={() => {
                    history.push({ pathname: '/shipments', search: shipmentsSearch })
                  }}
                  name='arrow-left' size='lg' style={{ marginRight: '1rem' }} />
              }
              <h2>
                Shipment Detail
              </h2>
            </div>
          </Container>
        </div>
        <Container>
          {webTrackResult &&
            (trackingLink || '').trim().length === 0 &&
            !CarrierLink &&
            <Alert>
              {
                'Tracking information updated successfully, but we do not have the tracking link for the courier at the moment.'
              }
            </Alert>}
        </Container>

        {(webTrack || {}).error && <Alert variant="danger">{(webTrack || {}).error.message}</Alert>}
        {error && <Alert variant="danger">{error.message}</Alert>}
        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}
        <ModalShipmentLogs name="ShipmentLogsModal" result={shipmentLogs} />
        <ModalWebTrack
          {...webTrack}
          name="WebTrackModal"
          handleShowCarrierPage={handleShowCarrierPage}
        />
        <ModalDeleteShipment
          name="DeleteShipmentModal"
          onDeleteShipment={handleDeleteShipment}
        />
        {!inProgress &&
          <Container>
            <Box className="box-table">
              <ShipmentsForm
                {...result}
                {...getFormProps()}
                setOpen={setOpen}
                openTabs={openTabs}
                showCopy={match.params.id}
                hasLogs={hasLogs}
                handleShipmentChange={handleShipmentChange}
                handleShipmentCopy={handleShipmentCopy}
                handleShipmentAdd={handleShipmentAdd}
                handleWebTrack={handleWebTrack}
                handleShipmentLogs={() => showModal('ShipmentLogsModal')}
                confirmDeleteShipment={() => showModal('DeleteShipmentModal')}
                onSubmit={this.handleSubmit}
                CARRIERS={CARRIERS}
                handlePurchaseOrderChange={({ ID, ShipmentID }) => {
                  const url = ShipmentID
                    ? `/shipments/${ShipmentID}/${ID}`
                    : `/shipments/new/${ID}`;
                  history.push(url);
                }}
                webTrackInProgress={webTrackInProgress}
                getCarrierByName={getCarrierByName}
                clearGetCarrierByName={clearGetCarrierByName}
                carriersSearchResults={carriers}
                totalCarriers={totalCarriers}
              />
            </Box>
          </Container>}

      </Layout>
    );
  }
}

const mapState = state => {
  const po = state.getPoShipments.toJS();
  const post = state.postShipment.toJS();
  const update = state.updateShipment.toJS();
  const logs = state.getShipmentLogs.toJS();
  const webTrack = state.getTrackingMoreTracking.toJS();
  const {
    result: totalCarriers
  } = state.getAllCarriers.toJS();
  const {
    inProgress: webTrackInProgress
  } = state.getTrackingMoreTracking.toJS();
  const {
    result
  } = state.getCarrierByName.toJS();
  return {
    webTrack,
    shipmentLogs: logs.result || [],
    result: po.result,
    error: po.error || post.error || update.error,
    inProgress: po.inProgress || post.inProgress || update.inProgress,
    webTrackInProgress,
    carriers: result,
    totalCarriers,
    shipmentsSearch: state.shipmentsSearch
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getShipmentLogs: fetchDux.getShipmentLogs.createAction,
      getPoShipments: fetchDux.getPoShipments.createAction,
      updateShipment: fetchDux.updateShipment.createAction,
      postShipment: fetchDux.postShipment.createAction,
      deleteShipment: fetchDux.deleteShipment.createAction,
      getWebTracking: fetchDux.getWebTracking.createAction,
      getTrackingMoreTracking: fetchDux.getTrackingMoreTracking.createAction,
      getCarrierByName: fetchDux.getCarrierByName.createAction,
      clearGetCarrierByName: fetchDux.getCarrierByName.clearAction,
      getAllCarriers: fetchDux.getAllCarriers.createAction,
      clearTrackingMoreTracking: fetchDux.getTrackingMoreTracking.clearAction,
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ShipmentsDetail);
