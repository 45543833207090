import React, { Component } from 'react';
import Layout from '../../../../ui/Layout';
import Notification from '../../../../ui/Notification';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';

import Loading from '../../../../ui/Loading';
import ProjectInfo from './../../projects-detail/ProjectInfo';

import ProjectLineItemInstructionsPO from './ProjectLineItemInstructionsPO';
import { actionCreators } from '../../../../../state/modals-dux';
import ProjectBreadcrumbs from '../ProjectBreadcrumbs';

class ProjectsSend extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const {
      match,
      getProjectById,
      getLookups
    } = this.props;
    const { id } = match.params || {};
    getProjectById(id);
    getLookups();
  }
  render() {
    const {
      user,
      result,
      inProgress,
      lookups,
      closeModal,
      postUpdateLineItemSpecialInstructions,
      clearPostUpdateLineItemSpecialInstructions,
      postResult,
      postError
    } = this.props;

    const {
      project,
      purchaseOrders,
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      pdfLogs
    } = result || {};
    const { ID, c_SageIntegrationStatus: status } = project || {};

    const showSuccessNotification = !!postResult;
    const showErrorNotification = !!postError;

    return (
      <Layout route="projects-update-shipping">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs
              project={project}
              current="Update Line Item Instructions"
            />
            <ProjectInfo
              status={status}
              result={result}
              projectId={ID}
              backButton
            />
          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

        {!inProgress && (purchaseOrders || []).map((po, i) => {
            return (
              <ProjectLineItemInstructionsPO
                user={user}
                key={i}
                projectId={ID}
                purchaseOrder={po}
                lineItems={lineItems}
                lineItemSpecialInstructions={lineItemSpecialInstructions}
                addresses={addresses}
                lookups={lookups}
                closeModal={closeModal}
                postUpdateLineItemSpecialInstructions={
                  postUpdateLineItemSpecialInstructions
                }
                pdfLogs={(pdfLogs || [])
                  .filter(({ PurchaseOrderID }) => PurchaseOrderID === po.ID)}
              />
            );
          })}

        {showSuccessNotification &&
          <Notification
            key="line-item-update-success"
            duration={5}
            closable={true}
            type="success"
            onClose={clearPostUpdateLineItemSpecialInstructions}
          >
            Success! Line Item Special Instructions updated.
          </Notification>}
        {showErrorNotification &&
          <Notification
            key="line-item-update-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPostUpdateLineItemSpecialInstructions}
          >
            An error occurred updating Line Item Special Instructions.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();

  const {
    result: user
  } = state.login.toJS();

  const {
    result: lookups
  } = state.getLookups.toJS();

  const {
    result: postResult,
    error: postError
  } = state.postUpdateLineItemSpecialInstructions.toJS();

  return {
    user,
    result,
    lookups,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError,
    postResult,
    postError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProjectById: fetchDux.getProjectById.createAction,
      postResendPurchaseOrder: fetchDux.postResendPurchaseOrder.createAction,
      getLookups: fetchDux.getLookups.createAction,
      postCancelResendWarning: fetchDux.postCancelResendWarning.createAction,
      postUpdateLineItemSpecialInstructions: fetchDux.postUpdateLineItemSpecialInstructions.createAction,
      clearPostUpdateLineItemSpecialInstructions: fetchDux.postUpdateLineItemSpecialInstructions.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsSend);
