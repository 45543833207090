import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';

const ModalProjectImportWarnDate = (
  {
    openModal,
    name,
    closeModal,
    onSubmit
  }
) => {
  return (
    <Modal show={openModal === name} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" /> File warning
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '14px' }}>
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <p>
            This project was exported more than 24 hrs ago.
          </p>
          <p style={{ fontWeight: 'bold' }}>
            Are you sure you wish to continue?
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button type="button" size="sm" variant="danger" onClick={onSubmit}>
          Yes, import project
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalProjectImportWarnDate);
