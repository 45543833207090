import React, { Component, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import PoSummary from './PoSummary';
import moment from 'moment';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';
import ShipmentBreakdown from './ShipmentBreakdown';
import PoForm from '../../shipments/shipments-detail/PoForm';
import GeneralForm from '../../shipments/shipments-detail/GeneralForm';
import CarrierForm from '../../shipments/shipments-detail/CarrierForm';
import WarehouseForm from '../../shipments/shipments-detail/WarehouseForm';
import VendorForm from '../../shipments/shipments-detail/VendorForm';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import WebTrackButtonColumnPopover from '../../../ui/WebTrackButtonColumnPopover';
import { authenticatedHref } from '../../../../api/fetch';



const validateTrackingNumber = (values, dispatch) => {
    let { Carrier, TrackingNum } = values;
    if (typeof Carrier === 'object')
        Carrier = (Carrier || {}).value;
    if ((Carrier || '').trim().length === 0 || (TrackingNum || '').trim().length === 0)
        return new Promise((resolve, reject) => {
            resolve();
        }).then(() => { });
    return new Promise((resolve, reject) => {
        return dispatch(
            fetchDux.validateTrackingNumber.createAction(
                values,
                null,
                null,
                (result) => resolve(result)
            )
        )
    }).then(result => {
        const { TrackingNum, Carrier } = result || {};
        if (TrackingNum && Carrier)
            throw { TrackingNum: 'Tracking Number already in use' }
    })
}
const DELIVERY_OPTIONS = [
    {
        value: "-1",
        label: 'Unshipped'
    },
    {
        value: 12,
        label: 'Info Received'
    },
    {
        value: 2,
        label: 'In Transit'
    },
    {
        value: 8,
        label: 'Available for Pickup'
    },
    {
        value: 5,
        label: 'Out for Delivery'
    },
    {
        value: 6,
        label: 'Failed Attempt'
    },
    {
        value: 7,
        label: 'Delivered'
    },

    {
        value: 4,
        label: 'Received By Warehouse'
    },
    {
        value: "0",
        label: 'Not Found'
    },
    {
        value: 9,
        label: 'Exception'
    },
    {
        value: 10,
        label: 'Expired'
    }
];

const DeliveryStatusCell = (
    {
        deliveryStatusCodes,
        deliveryStatusValue,
        change
    }
) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        if (open) {
            setOpen(false);
            change('OverrideDeliveryStatus', null);
            return;
        }
        setOpen(true);
        change('OverrideDeliveryStatus', deliveryStatusValue);
    };
    return (
        <Col sm={4}>
            {!open &&
                <span>
                    {deliveryStatusCodes[deliveryStatusValue]}
                </span>}
            {open &&
                <div style={{ width: '300px', display: 'inline-block' }}>
                    <Field
                        name="OverrideDeliveryStatus"
                        component={FormField.SelectReact}
                        options={DELIVERY_OPTIONS}
                    />
                </div>}
            <Icon
                name={open ? 'times-circle' : 'edit'}
                onClick={handleToggle}
                style={{ color: open ? '#5EA2C9' : '#5EA2C9', marginLeft: '20px' }}
            />
        </Col>
    );
};


class ShipmentsGroupForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rowCount: 5
        }
    }

    render() {
        const {
            purchaseOrder = {},
            poResults = [],
            shippingAddress = {},
            vendors = [],
            purchaseOrderOptions,
            vendorOptions,
            VendorName,
            lineItems = [],
            change,
            deliveryStatusCodes = {},
            deliveryStatusValue,
            shipmentOptions,
            shipments,
            setOpen,
            openTabs,
            generalInfo,
            CARRIERS,
            shipmentLineItems,
            handleShipmentGroupChange,
            handlePurchaseOrderChange,
            shipmentGroup,
            showModal,
            closeModal,
            notesValue,
            handleSubmit,
            shipmentGroupLogs,
            carrierValue,
            trackingNumValue,
            handleWebTrack,
            type,
            data,
            addMiscShipmentsLineItemsGroup,
            history,
            initialValues,
            webTrackInProgress,
            getCarrierByName,
            clearGetCarrierByName,
            carriersSearchResults,
            pdfLogs,
            trackingMore,
            isDefaultCarrier,
            manualTracking
        } = this.props;
        const {
            groupName,
            ID: shipmentID,
            trackingMoreId,
            Carrier: initialCarrier,
            TrackingNum: initialTrackingNum,
            manualTracking: initialManualTracking
        } = initialValues;

        const { value: initialCarrierValue } = initialCarrier || {};
        const { value: currentCarrierValue } = carrierValue || {};
        const didTrackingInformationChange = (() => {
            if (initialCarrierValue !== currentCarrierValue) return true;
            if (initialTrackingNum !== trackingNumValue) return true;
            if (initialManualTracking !== manualTracking) return true;
        })();


        const { rowCount } = this.state;
        const { ID, c_PurchaseOrderNumber } = purchaseOrder;
        const showWebTrack = carrierValue &&
            carrierValue.value &&
            trackingNumValue &&
            CARRIERS.some(c => (c.value || '').toLowerCase() === (carrierValue.value || '').toLowerCase());

        const handleUpdateNotes = () => change('Notes', notesValue);
        const popOverLabel = `Selecting this box and clicking “Save” will disable system auto-tracking updates to this shipment. The shipment status and carrier information data fields must be updated manually by the user. You can reconnect to auto-tracking updates by unselecting this box and clicking “Save”.`

        const handleWebTrackClick = () => {
            handleWebTrack({
                ID: shipmentID,
                Carrier: (carrierValue || {}).value,
                TrackingNum: trackingNumValue,
                deliveryStatusValue,
                groupName,
                poNumber: c_PurchaseOrderNumber
            });
        }

        const buttonStyle = parseInt(deliveryStatusValue) === 4 || parseInt(deliveryStatusValue) === 7 ?
            'primary' :
            parseInt(deliveryStatusValue) === 0 ? 'primary-grey' :
                trackingMoreId ?
                    manualTracking ? 'warning-secondary' : 'warning' :
                    trackingMore ? 'success' : isDefaultCarrier ? 'success' : 'primary';

        const getPDFUrl = (
            {
                FilePath,
                FileName
            }
        ) => authenticatedHref(`files/${FilePath}/${FileName}`);
        const pdfDetails = (pdfLogs || [])[0] || {};
        return (
            <div className="shipment-form">
                <Form onSubmit={handleSubmit}>
                    <PoSummary
                        shippingAddress={shippingAddress}
                        purchaseOrderOptions={purchaseOrderOptions}
                        vendorOptions={vendorOptions}
                        VendorName={VendorName}
                        lineItems={lineItems}
                        change={change}
                        purchaseOrder={purchaseOrder}
                        shipments={shipments}
                        handlePurchaseOrderChange={handlePurchaseOrderChange}
                    />
                    <div>
                        <Row>
                            <Col sm={3}>
                                <Form.Label>
                                    {`Shipment Groups (${shipmentOptions.length})`}
                                </Form.Label>
                            </Col>
                            <Col sm={9}>
                                <Field
                                    type="text"
                                    name="shipmentGroup"
                                    options={shipmentOptions}
                                    onChange={handleShipmentGroupChange}
                                    component={FormField.SelectReact}
                                />
                            </Col>
                        </Row>
                    </div>
                    <hr style={{ marginBottom: '2rem' }} />
                    <div>
                        <Row>
                            <Col sm={3}><Form.Label>Shipment Description:</Form.Label></Col>
                            <Col sm={9}>
                                <Field
                                    type="text"
                                    name="ShipmentDesc"
                                    component={FormField.Input}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={3}>
                                <Form.Label>Delivery Status:</Form.Label>
                            </Col>
                            <DeliveryStatusCell
                                deliveryStatusValue={deliveryStatusValue}
                                deliveryStatusCodes={deliveryStatusCodes}
                                change={change}
                            />
                        </Row>
                    </div>

                    <div className="my-2">
                        <Button type="submit" size="sm">Save</Button>{' '}
                        {

                            <WebTrackButtonColumnPopover
                                header={"Web Track"}
                                handleWebTrack={handleWebTrackClick}
                                updateInProgress={webTrackInProgress}
                                enabled={showWebTrack}
                                buttonStyle={buttonStyle}
                            />
                            // <Button
                            //     size="sm"
                            //     onClick={handleWebTrackClick}
                            //     disabled={!showWebTrack || webTrackInProgress}
                            //     variant={buttonStyle}
                            // >
                            //     Track
                            // </Button>
                        }

                        {' '}
                        <Button size="sm"
                            onClick={() => showModal('ShipmentLogsModal')}
                            disabled={(shipmentGroupLogs || []).length === 0}
                        >
                            View Change Log
                        </Button>
                        {' '}
                        {
                            !(shipmentGroup || '').startsWith('unassigned') &&
                            !(groupName || '').startsWith('misc') &&
                            <Button
                                variant="danger"
                                size='sm'
                                onClick={() => showModal({ name: 'RemoveAssignmentsModal', payload: [] })}
                            >
                                Remove Assignments
                            </Button>
                        }
                        {' '}
                        {
                            // !(groupName || '').startsWith('misc') &&
                            <Button
                                variant='primary'
                                size='sm'
                                onClick={() => {
                                    const payload = {
                                        type,
                                        data,
                                        purchaseOrderNumber: purchaseOrder.c_PurchaseOrderNumber
                                    }
                                    addMiscShipmentsLineItemsGroup(payload, null, null, (result) => {
                                        const { count } = result;
                                        history.push(`/shipments-groups/${ID}/unassigned/na?misc=true&rank=${count}`);
                                    });
                                }}
                            >
                                Add Misc Shipment Group
                            </Button>
                        }
                        {' '}
                        {
                            (groupName || '').startsWith('misc') &&
                            <>
                                <Button
                                    variant='danger'
                                    size='sm'
                                    onClick={() => showModal(
                                        {
                                            name: 'DeleteMiscShipmentModal',
                                            shipments,
                                            groupName,
                                            shipmentID,
                                            purchaseOrder
                                        })}
                                >
                                    Delete Misc ShipmentGroup
                                </Button>
                                {' '}
                            </>

                        }
                        {
                            pdfLogs && pdfLogs.length > 0 &&
                            <a href={getPDFUrl(pdfDetails || {})} target="_blank">
                                <Button
                                    variant='primary'
                                    size='sm'
                                >
                                    View Purchase Order
                                </Button>
                            </a>

                        }
                        {
                            <>
                                {' '}
                                {
                                    deliveryStatusValue !== 7
                                    &&
                                    deliveryStatusValue !== 4
                                    &&
                                    trackingMoreId &&
                                    <div style={{ display: 'inline-block', marginLeft: '1rem' }}>
                                        <Field
                                            name="manualTracking"
                                            label="Track Manually"
                                            component={FormField.InlineCheckbox}
                                            disabled={(isDefaultCarrier && trackingMore === false) || trackingMore === false}
                                            overlayProps={{
                                                overlay: true,
                                                popOverLabel
                                            }}
                                        />
                                    </div>

                                }
                            </>
                        }
                    </div>
                    {
                        didTrackingInformationChange &&
                        <div className="my-2">
                            {'Changes have been made to a Carrier Information field. Please click Save to finalize your changes.'}
                        </div>
                    }

                    <PoForm lineItems={lineItems} setOpen={setOpen} openTabs={openTabs} />
                    {
                        !(groupName || '').startsWith('misc') &&
                        <ShipmentBreakdown
                            setOpen={setOpen}
                            openTabs={openTabs}
                            shipmentLineItems={shipmentLineItems}
                            showModal={showModal}
                            poId={ID}
                            type={type}
                        />
                    }

                    <GeneralForm
                        data={generalInfo}
                        purchaseOrder={purchaseOrder}
                        setOpen={setOpen}
                        openTabs={openTabs}
                        closeModal={closeModal}
                    />
                    <VendorForm
                        handleUpdateNotes={handleUpdateNotes}
                        setOpen={setOpen}
                        openTabs={openTabs}
                    />

                    <CarrierForm
                        // carrierUpdateDateValue={carrierUpdateDateValue}
                        carriers={CARRIERS}
                        setOpen={setOpen}
                        openTabs={openTabs}
                        getCarrierByName={getCarrierByName}
                        clearGetCarrierByName={clearGetCarrierByName}
                        carriersSearchResults={carriersSearchResults}
                        change={change}
                    />
                    <WarehouseForm setOpen={setOpen} openTabs={openTabs} />
                    <Row>
                        <Col sm={12}>
                            <Field
                                rows={rowCount}
                                name="Notes"
                                label="Notes:"
                                component={FormField.Textarea}
                                normalize={(value, previousValue) => {
                                    if (value.length < 5000) {
                                        return value;
                                    }
                                    return previousValue;
                                }}
                                onChange={({ target }) => {
                                    const rows = target.value.split('\n');
                                    if (rows.length > rowCount) {
                                        this.setState({ rowCount: rowCount + 1 });
                                    } else {
                                        if (rows.length < 5) {
                                            if (rowCount != 5) this.setState({ rowCount: 5 })
                                        } else if (rows.length < rowCount) {
                                            this.setState({ rowCount: rowCount - 1 });
                                        }
                                    }
                                }}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
        )

    }
}


const FORM_NAME = 'shipment-group-form';
const selector = formValueSelector(FORM_NAME);

const mapState = state => {
    const deliveryStatusValue = selector(state, 'DeliveryStatus');
    const shipmentNumValue = selector(state, 'ShipmentNum');
    const carrierValue = selector(state, 'Carrier');
    const trackingNumValue = selector(state, 'TrackingNum');
    const carrierUpdateDateValue = selector(state, 'CarrierUpdateDate');

    const notes = selector(state, 'Notes');
    const name = selector(state, 'SchdShipDatePerson');
    const schdShipDate = selector(state, 'SchdShipDate');
    const contactDate = selector(state, 'SchdShipDatePersonDate');
    const actualName = selector(state, 'ActualShipDatePerson');
    const actualShipDate = selector(state, 'ActualShipDate');
    const actualContactDate = selector(state, 'ActualShipDatePersonDate');
    const notesVal = [];
    if (notes) notesVal.push(notes);
    notesVal.push('');
    notesVal.push(`** Contact Info ${moment().format('MM/DD/YYYY h:mm:ss a')}`);
    notesVal.push('');
    notesVal.push(`Name: ${name || ''}`);
    notesVal.push(`SchdShipDate: ${schdShipDate || ''}`);
    notesVal.push(`ContactDate: ${contactDate || ''}`);
    notesVal.push(`Name: ${actualName || ''}`);
    notesVal.push(`ActualShipDate: ${actualShipDate || ''}`);
    notesVal.push(`ContactDate: ${actualContactDate || ''}`);
    const VendorName = selector(state, 'VendorName');
    const shipmentGroup = selector(state, 'shipmentGroup');
    const defaultCarriers = selector(state, 'defaultCarriers');
    const {
        result: totalCarriers
    } = state.getAllCarriers.toJS();


    const carrier = typeof carrierValue === 'object' ? (carrierValue || {}).value : carrierValue

    let c = (defaultCarriers || []).find(({ value }) => value === carrier);
    const isDefaultCarrier = !!c;

    const carrierName = carrier === 'saia'
        ? 'saia-freight'
        : carrier === 'dayton'
            ? 'dayton-freight'
            : carrier === 'rlc' ? 'rl-carriers' :
                carrier === 'southeastern' ?
                    'se-freightlines' :
                    carrier === 'usf' ?
                        'usf-reddaway' :
                        carrier === 'xpo' ?
                            'xpoweb' :
                            carrier === 'averitt' ?
                                'averittexpress' :
                                carrier || '';

    const courier = (totalCarriers || []).find((c) => {
        const { courier_code, courier_name } = c || {};
        if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('-')) ||
            (courier_name || '').toLowerCase() === (carrierName.split(' ').join('-'))) {
            return c;
        }
        else if ((courier_code || '').toLowerCase() === (carrierName.split(' ').join('')) ||
            (courier_name || '').toLowerCase() === (carrierName.split(' ').join(''))) {
            return c;
        }
    })
    const trackingMore = !!courier;
    const manualTracking = selector(state, 'manualTracking');
    return {
        carrierValue,
        trackingNumValue,
        shipmentNumValue,
        deliveryStatusValue:
            isNaN(parseInt(deliveryStatusValue)) ? "-1" :
                parseInt(deliveryStatusValue) === -1 ? "-1" :
                    parseInt(deliveryStatusValue) === 0 ? "0" :
                        parseInt(deliveryStatusValue),
        carrierUpdateDateValue,
        notesValue: notesVal.join('\n'),
        VendorName,
        shipmentGroup,
        isDefaultCarrier,
        trackingMore,
        manualTracking
    };
};


const form = reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    // asyncValidate: validateTrackingNumber,
    // asyncBlurFields: ['TrackingNum'],
    // asyncChangeFields: ['Carrier']
})(ShipmentsGroupForm);

const mapDispatch = (dispatch) => bindActionCreators(
    {
        ...actionCreators,
        changeHandler: change
    },
    dispatch
)

export default connect(mapState, mapDispatch)(form);



