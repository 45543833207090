import React from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/modals-dux';
import Icon from '../../ui/Icon';

const ModalConfirmDeleteSalesRep = (
  {
    openModal,
    name,
    closeModal,
    onDeleteSalesRep,
    inProgress,
    error,
    activeRep
  }
) => {
  const show = openModal === name;
  const { FirstName, LastName } = activeRep || {};
  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          &nbsp;
          Delete Sales Rep
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: 'center' }}>
          Are you sure you want to delete Sales Rep
          {' '}
          <b>{FirstName} {LastName}</b>
          ?
        </p>
        {error &&
          <div
            style={{
              color: '#C23934',
              textAlign: 'center',
              padding: '20px 60px 20px 0'
            }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            {error.message || 'An error occurred'}
          </div>}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'normal' }}>
        <Col style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          This action cannot be undone!
        </Col>
        <Col sm="auto">
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={closeModal}
            disabled={inProgress}
          >
            Cancel
          </Button>
          <Button
            type="button"
            size="sm"
            variant="danger"
            onClick={onDeleteSalesRep}
            disabled={inProgress}
            style={{ marginLeft: '10px' }}
          >
            Yes, Delete Sales Rep
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => {
  const {
    inProgress,
    error
  } = state.delSalesRep.toJS();

  return {
    inProgress,
    error,
    openModal: state.modals
  };
};

const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalConfirmDeleteSalesRep);
