import React from 'react';
import { Modal, Button, Col, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/modals-dux';
import Icon from '../../ui/Icon';
import VendorMappingForm from './VendorMappingForm';
import { submit, isValid, isSubmitting } from 'redux-form';
import fetchDux from '../../../state/fetch-dux';

const MappingsTable = (
  { activeMappings, deleteVendorsMappings, getVendors }
) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>AQ Manufacturer</th>
          <th>AQ Manufacturer ID</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {(activeMappings || []).map(({
          ID,
          ManufacturerName,
          AQVendorId
        }, idx) => (
          <tr key={idx}>
            <td>
              {ManufacturerName}
            </td>
            <td>
              {AQVendorId}
            </td>
            <td style={{ textAlign: 'right', color: 'red' }}>
              <Icon
                name="minus-circle"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  deleteVendorsMappings(ID, null, null, getVendors)}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

const ModalEditVendorMappings = (
  {
    openModal,
    name,
    closeModal,
    inProgress,
    error,
    activeVendor,
    activeMappings,
    manufacturers,
    deleteVendorsMappings,
    handlePostVendorsMapping,
    getVendors
  }
) => {
  const show = openModal === name;
  const {
    Name,
    VendorNo
  } = activeVendor || {};

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-1100"
    >
      <Modal.Header>
        <Modal.Title>
          {Name} ({VendorNo})
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VendorMappingForm
          manufacturers={manufacturers}
          onSubmit={handlePostVendorsMapping}
        />
        <MappingsTable
          activeMappings={activeMappings}
          deleteVendorsMappings={deleteVendorsMappings}
          getVendors={getVendors}
        />
        {error &&
          <div
            style={{
              color: '#C23934',
              textAlign: 'center',
              padding: '20px 60px 20px 0'
            }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            {error.message || 'An error occurred'}
          </div>}
      </Modal.Body>
      <Modal.Footer>
        <Col sm="auto">
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={closeModal}
            disabled={inProgress}
          >
            Done
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => {
  const {
    inProgress,
    error
  } = state.putVendor.toJS();

  return {
    inProgress,
    error,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      deleteVendorsMappings: fetchDux.deleteVendorsMappings.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalEditVendorMappings);
