import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import SendContactForm from './SendContactForm';
import Notification from '../../../ui/Notification';
import { formValueSelector } from 'redux-form';

class ModalSendContact extends Component {
  constructor(props) {
    super(props);
    this.state = { showPreview: false };
  }
  render() {
    const {
      openModal,
      closeModal,
      postError,
      WarehouseId,
      sendContactEmail,
      sendContactEmailError,
      inProgress,
      formSubject,
      formBody,
      formFooter,
      user
    } = this.props;
    const { showPreview } = this.state;

    const initialValues = {
      subject: 'Warehousing partner requested',
      body: 'Concept Services is a kitchen equipment supplier and installation company for chain restaurants.' +
        ' We use moving and storage companies nationwide to receive, consolidate, and deliver our equipment packages to our projects.' +
        ' I am reaching out to you to confirm whether you would be interested in being included in our warehouse database as a potential' +
        ' partner for future projects in your area.' +
        '\r\n\n' +
        'Unless specified differently by the Concept Services project manager you will be working with,' +
        ' our standard scope of work includes receiving shipments from our manufacturing partners, storing the equipment,' +
        ' and performing a consolidated delivery to the project location. Deliveries are typically driver-only, requiring a' +
        ' standard ground delivery via liftgate or ramp. An installation crew will be on site to handle the unloading and transportation' +
        ' of the equipment into the building. ' +
        '\r\n\n' +
        'The link directly below is our standard scope of work for your review:'
      ,
      footer: 'Please use the link below to review/update the information we have on ' +
        'file for addresses, contact information and rates.'
    };

    // Fix show preview bug on close
    const cancelModal = () => {
      this.setState({ showPreview: false });
      closeModal();
    };

    const handleSubmit = payload => {
      if (showPreview) {
        sendContactEmail(
          { id: WarehouseId, payload: { ...payload } },
          'send-contact-form',
          null,
          () => {
            cancelModal();
          }
        );
      } else {
        this.setState({ showPreview: true });
      }
    };

    const closePreview = () => {
      this.setState({ showPreview: false });
    };

    return (
      <Modal
        show={openModal === 'SendContactModal'}
        onHide={cancelModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Contact Email</Modal.Title>
        </Modal.Header>
        <SendContactForm
          onSubmit={handleSubmit}
          submitting={inProgress}
          closeModal={cancelModal}
          initialValues={initialValues}
          showPreview={showPreview}
          closePreview={closePreview}
          formValues={{ formSubject, formBody, formFooter }}
          user={user}
        />
        {sendContactEmailError &&
          <Notification
            key="send-contact-email-error"
            duration={5}
            closable={true}
            type="danger"
          >
            Error sending contact email, please try again.
          </Notification>}
      </Modal>
    );
  }
}

const selector = formValueSelector('send-contact-form');

const mapState = state => {
  const formSubject = selector(state, 'subject');
  const formBody = selector(state, 'body');
  const formFooter = selector(state, 'footer');
  const {
    result,
    inProgress,
    error: sendContactEmailError
  } = state.sendContactEmail.toJS();
  const {
    result: user
  } = state.validateLogin.toJS();
  return {
    sendContactEmailError,
    inProgress,
    openModal: state.modals,
    formSubject,
    formBody,
    formFooter,
    user
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      sendContactEmail: fetchDux.sendContactEmail.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalSendContact);
