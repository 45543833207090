import React from 'react';
import { Table, Button } from 'react-bootstrap';
import numeral from 'numeral';
import Icon from '../../../../ui/Icon';
import FormField from '../../../../forms/FormField';
import { reduxForm, Field, Form } from 'redux-form';

const formatMoney = value => numeral(value).format('0,0.00');

const sumTotalPrice = lines =>
  (lines || []).reduce((prev, { TotalPrice }) => prev + TotalPrice, 0);

const getLineItemGroups = lineItems =>
  lineItems.reduce(
    (prev, next) => {
      const { LineItemId } = next || {};
      const existing = prev.some(p => p.LineItemId === LineItemId);
      if (existing) {
        return prev.map(row => {
          if (row.LineItemId !== LineItemId) return row;
          const lines = [...row.lines, next];
          return Object.assign({}, row, { lines });
        });
      }

      return [...prev, { LineItemId, lines: [next] }];
    },
    []
  );

const EditLineItemInstructions = (
  {
    lineItems,
    purchaseOrder,
    handleSubmit,
    submitting
  }
) => {
  const lineItemGroups = getLineItemGroups(lineItems);
  const {
    SumSellPrice
  } = purchaseOrder || {};

  return (
    <Form onSubmit={handleSubmit}>
      <div className="purchase-order">
        <Table responsive className="table-items">
          <tbody>
            <tr>
              <th colSpan={2} style={{ textAlign: 'center' }}>
                Order Items
              </th>
            </tr>
            <tr>
              <td colSpan={2}>
                <Table>
                  <thead>
                    <tr>
                      <th>ItemNo</th>
                      <th>Qty</th>
                      <th>Model</th>
                      <th>Description</th>
                      <th className="text-right">Cost (ea)</th>
                      <th className="text-right">Cost (Ext.)</th>
                    </tr>
                  </thead>
                  {lineItemGroups.map(({ LineItemId, lines }, lidx) => (
                    <tbody key={lidx}>
                      {(lines || []).map(({
                        ItemNumber,
                        Quantity,
                        Model,
                        Spec,
                        NetPrice,
                        TotalPrice
                      }, index) => (
                        <tr key={index}>
                          <td>{index === 0 ? ItemNumber : ''}</td>
                          <td>
                            {Quantity}
                          </td>
                          <td>
                            {Model}
                          </td>
                          <td>
                            {Spec}
                          </td>
                          <td className="text-right">
                            ${formatMoney(NetPrice)}
                          </td>
                          <td className="text-right">
                            ${formatMoney(TotalPrice)}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          className="text-right"
                          colSpan="5"
                          style={{ fontWeight: 'bold' }}
                        >
                          Item Total -
                        </td>
                        <td
                          className="text-right"
                          colSpan="1"
                          style={{ fontWeight: 'bold' }}
                        >
                          ${formatMoney(sumTotalPrice(lines))}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="6" className="special-instructions">
                          <h5 style={{ paddingBottom: '10px' }}>
                            <Icon prefix="far" name="hand-point-right" />
                            {' '}
                            SPECIAL INSTRUCTIONS (For Items Above):
                          </h5>
                          <Field
                            name={LineItemId}
                            rows={3}
                            component={FormField.Textarea}
                            maxLength="1000"
                          />
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td>
                Purchase Order Total
              </td>
              <td className="text-right">
                ${formatMoney(SumSellPrice)}
              </td>
            </tr>
          </tfoot>
        </Table>
        <div style={{ textAlign: 'right', marginTop: '15px' }}>
          <Button type="submit" variant="success" disabled={submitting}>
            Save
          </Button>
        </div>
      </div>
    </Form>
  );
};

const form = reduxForm({
  form: 'edit-line-item-instructions-form'
})(EditLineItemInstructions);

export default form;
