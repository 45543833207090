import React from 'react';
import { reduxForm, Field, submit } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Alert } from 'react-bootstrap';
import moment from 'moment';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM.DD.YYYY');
};

const FileDetail = ({ input, valid }) => {
  const { value } = input || {};
  return (
    <div>
      <h5>Filename:</h5>
      <div>
        {value.name || ''}
        &nbsp;
        {' '}
        {valid && <Icon style={{ color: '#28a745' }} name="check" />}
      </div>
      <h5>Last Updated:</h5>
      <div>{getDate(value.lastModifiedDate)}&nbsp;</div>
    </div>
  );
};

const validateFileType = value => {
  const { name } = value || {};
  if (!value || !name) return 'Required';
  const filetype = name.substring(name.lastIndexOf('.') + 1);
  if (filetype.toLowerCase() !== 'json')
    return 'Invalid file type. Only JSON files allowed.';
  return undefined;
};

const getLoadingMessage = step => {
  switch (step) {
    case 1:
      return 'Step 1 of 5 - Loading Vendor Assets';
    case 2:
      return 'Step 2 of 5 - Loading Product Assets';
    case 3:
      return 'Step 3 of 5 - Loading File Assets';
    case 4:
      return 'Step 4 of 5 - Saving Products';
    case 5:
      return 'Step 5 of 5 - Saving Files';
    default:
      return '';
  }
};

const ReplaceProductCatalogForm = props => {
  const {
    handleSubmit,
    error,
    valid,
    closeModal,
    getProductImportStatusResult,
    postProductsJsonInProgress,
    postProductsJsonResult,
    handleCancelImport
  } = props;

  const {
    totalAssets,
    completeAssets,
    statusID,
    step
  } = getProductImportStatusResult || {};

  const {
    deletedproducts,
  } = postProductsJsonResult || {};
  const submitting = statusID === 1;

  const loadingMessage = getLoadingMessage(step);

  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        {error &&
          <Alert variant="danger">
            <h5>Failed to upload product catalog</h5>
            <div>{error.message || ''}</div>
          </Alert>}
        {!submitting &&
          <Row>
            <Col md={7}>
              <Field
                disabled={submitting || postProductsJsonInProgress}
                name="jsonFile"
                component={FormField.InputFileDropzone}
              />
            </Col>
            <Col md={5}>
              <div className="file-info" style={{ fontSize: '30px' }}>
                <Field
                  name="jsonFile"
                  component={FileDetail}
                  validate={[validateFileType]}
                  valid={valid}
                />
              </div>
            </Col>
          </Row>}

        {submitting &&
          <Row>
            <Col md={12}>
              <Loading
                fullPage
                message={
                  submitting
                    ? `${loadingMessage}`
                    : 'Parsing JSON, Please Wait...'
                }
                messageStyle={{ whiteSpace: 'pre-wrap' }}
              />
            </Col>
          </Row>}

      </div>

      {submitting &&
        <div className="modal-footer">
          <Button variant="danger" type="button" onClick={handleCancelImport}>
            Cancel Import
          </Button>
        </div>}
      {!submitting &&
        <div className="modal-footer">
          {/* <Field
            name="paymentTerms"
            component={FormField.InlineCheckbox}
            validate={[required]}
            label="Agree to replace catalog and remove current packages."
          /> */}
          <div>
            <Button
              variant="outline-secondary"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </Button>
            {' '}
            <Button
              variant="primary"
              type="submit"
              disabled={!valid || submitting || postProductsJsonInProgress}
            >
              Continue
            </Button>
          </div>
        </div>}

    </form>
  );
};

export default reduxForm({
  form: 'replace-product-catalog-form'
})(ReplaceProductCatalogForm);
