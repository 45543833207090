import { actionNamespace } from './defaults';

const SAVE_SHIPMENTS_SEARCH = `${actionNamespace}/SAVE_SHIPMENTS_SEARCH`;

const defaultState = null;

export default (state = defaultState, { type, payload }) => {
    switch (type) {
        case SAVE_SHIPMENTS_SEARCH:
            return payload;
        default:
            return state;
    }
};

export const actionCreators = {
    saveShipmentsSearch: payload => ({ type: SAVE_SHIPMENTS_SEARCH, payload }),
};
