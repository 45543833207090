import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector, SubmissionError } from 'redux-form';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import CustomersUserForm from './CreateCustomersUserForm';

import Notification from '../../../ui/Notification';

const getPaymentOptions = paymentSettings => {
  let result = {};

  (paymentSettings || []).map(({ paymentTypeId }) => {
    switch (paymentTypeId) {
      case 1:
        result.paymentOptionCreditCard = true;
        break;
      case 2:
        result.paymentOptionPO = true;
        break;
      case 3:
        result.paymentOptionCheck = true;
        break;
      case 4:
        result.paymentOptionACH = true;
        break;
    }
  });

  return result;
};
const getWebsiteOptions = websiteSettings => {
  let result = {};

  (websiteSettings || []).map(({ websiteAccessTypeID }) => {
    switch (websiteAccessTypeID) {
      case 1:
        result.websiteAccessShop = true;
        break;
      case 2:
        result.websiteAccessQuotes = true;
        break;
      case 3:
        result.websiteAccessDocuments = true;
        break;
      case 4:
        result.websiteAccessOrders = true;
        break;
      case 5:
        result.websiteAccessLocations = true;
        break;
      case 6:
        result.websiteAccessInvoices = true;
        break;
    }
  });

  return result;
};

const getApproverSetting = ({ approverID, spendingLimit }) => {
  return { requiredApprover: approverID, spendingLimit };
};

const getInitialValues = (
  {
    user,
    purchaseApprover,
    paymentOptions,
    websiteOptions
  }
) => {
  const approver = getApproverSetting(purchaseApprover || {});
  const paymentSettings = getPaymentOptions(paymentOptions || []) || {};
  const websiteSettings = getWebsiteOptions(websiteOptions || []) || {};

  return { ...user, ...approver, ...paymentSettings, ...websiteSettings };
};

const getApprovers = (selectedCompanies, allUsers) => {
  return (allUsers || [])
    .filter(
      ({ companies, isApprover }) =>
        isApprover &&
        (companies || [])
          .some(({ id }) => (selectedCompanies || []).includes(id))
    );
};

const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const getCurrentCompanies = userCompanies => {
  return (userCompanies || []).map(({ companyID }) => {
    return companyID;
  });
};
const getCurrentEmailCCs = emailCCs => {
  return (emailCCs || []).map(({ email }) => {
    return email;
  });
};
class ModalEditCustomersUser extends Component {
  constructor(props) {
    super(props);
    this.state = { companyIds: [], roleType: null, emailCC: [] };
  }
  componentDidMount() {
    const {
      result,
      searchCustomersUsers
    } = this.props;

    const {
      userCompanies,
      emailCCs,
      user
    } = result || {};

    const {
      roleTypeId
    } = user || {};

    this.setState({
      companyIds: getCurrentCompanies(userCompanies),
      emailCC: getCurrentEmailCCs(emailCCs),
      roleType: roleTypeId
    });

    searchCustomersUsers();
  }
  render() {
    const {
      openModal,
      closeModal,
      name,
      companies,
      result,
      purchasingApproval,
      paymentTerms,
      ccEmailInvoice,
      searchCustomersUsersResult,
      putUser,
      getCustomersUserById,
      clearPutUser,
      putUserInProgress,
      putUserError,
      clearGetCustomersUserById,
      getCustomersUserByIdError,
      clearSearchCustomersUsers,
      searchCustomersUsersError
    } = this.props;

    const {
      userCompanies,
      emailCCs,
      user
    } = result || {};

    const {
      id
    } = user || {};

    const {
      companyIds,
      roleType,
      emailCC
    } = this.state;

    const companiesDisplay = (companies || [])
      .sort((a, b) => {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .filter(({ id }) => (companyIds || []).includes(id));

    const handlePutUser = values => {
      values.companies = companyIds;
      values.roleTypeID = roleType;
      values.emailCC = emailCC;
      if (!(companyIds || []).length) {
        throw new SubmissionError({
          assignConcept: 'minimum 1 company required',
          _error: 'user did not select a company'
        });
      }
      putUser(
        { payload: values, id },
        'create-customers-user-form',
        null,
        () => {
          getCustomersUserById(
            {
              id,
              query: {
                getPaymentOptions: 1,
                getWebsiteOptions: 1,
                getConcepts: 1,
                getPurchaseApprover: 1,
                getEmailCCs: 1,
                getLocations: 1
              }
            },
            null,
            true
          );
        }
      );
    };

    const handleCompanyChange = newSelectedCompany => {
      if (!newSelectedCompany) return;
      this.setState({
        companyIds: (companyIds || []).concat(newSelectedCompany)
      });
    };

    const handleRemoveCompany = removeCompanyId => {
      if (!removeCompanyId) return;
      const {
        companyIds
      } = this.state;
      const newCompanyArray = (companyIds || [])
        .filter(companyId => removeCompanyId !== companyId);
      this.setState({ companyIds: newCompanyArray });
    };

    const handleAddEmailCC = () => {
      if (!ccEmailInvoice) return false;
      if (!validEmail(ccEmailInvoice)) return false;
      this.setState({ emailCC: (emailCC || []).concat([ccEmailInvoice]) });
      return true;
    };

    const handleRemoveEmailCC = removeEmailCC => {
      if (!removeEmailCC) return;
      const {
        emailCC
      } = this.state;
      const newEmailCCArray = (emailCC || [])
        .filter(emailName => removeEmailCC !== emailName);
      this.setState({ emailCC: newEmailCCArray });
    };

    const handleHideModal = () => {
      closeModal();
      this.setState({
        companyIds: getCurrentCompanies(userCompanies),
        emailCC: getCurrentEmailCCs(emailCCs)
      });
    };

    const handleRoleChange = type => {
      this.setState({ roleType: type });
    };

    const currentApprovers = getApprovers(
      companyIds,
      searchCustomersUsersResult
    );
    const initialValues = getInitialValues(result || {});

    return (
      <Modal
        show={openModal === name}
        onHide={handleHideModal}
        className="modal-side"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <CustomersUserForm
          onSubmit={handlePutUser}
          initialValues={initialValues}
          handleCompanyChange={handleCompanyChange}
          handleRemoveCompany={handleRemoveCompany}
          companyIds={companyIds}
          companies={companies}
          companiesDisplay={companiesDisplay}
          handleAddEmailCC={handleAddEmailCC}
          handleRemoveEmailCC={handleRemoveEmailCC}
          emailCC={emailCC}
          handleRoleChange={handleRoleChange}
          roleType={roleType}
          handleHideModal={handleHideModal}
          currentApprovers={currentApprovers}
          purchasingApproval={purchasingApproval}
          paymentTerms={paymentTerms}
          isEdit
          submitting={putUserInProgress}
        />
        {putUserError &&
          <Notification
            key="user-edit-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPutUser}
          >
            Failed to update user details. Please refresh the page.
          </Notification>}
        {getCustomersUserByIdError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCustomersUserById}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {getCustomersUserByIdError &&
          <Notification
            key="form-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCustomersUserById}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {searchCustomersUsersError &&
          <Notification
            key="users-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersUsers}
          >
            Failed to load user options. Please refresh the page.
          </Notification>}
      </Modal>
    );
  }
}

const selector = formValueSelector('create-customers-user-form');

const mapState = state => {
  const purchasingApproval = selector(state, 'purchasingApproval');
  const paymentTerms = selector(state, 'paymentTerms');
  const ccEmailInvoice = selector(state, 'ccEmailInvoice');

  const {
    result: searchCustomersUsersResult,
    inProgress: searchCustomersUsersInProgress,
    error: searchCustomersUsersError
  } = state.searchCustomersUsers.toJS();

  const {
    result: getCustomersUserByIdResult,
    inProgress: getCustomersUserByIdProgress,
    error: getCustomersUserByIdError
  } = state.getCustomersUserById.toJS();

  const {
    inProgress: putUserInProgress,
    error: putUserError
  } = state.putUser.toJS();

  return {
    searchCustomersUsersResult,
    searchCustomersUsersInProgress,
    searchCustomersUsersError,
    purchasingApproval,
    paymentTerms,
    ccEmailInvoice,
    putUserInProgress,
    putUserError,
    getCustomersUserByIdProgress,
    getCustomersUserByIdError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      putUser: fetchDux.putUser.createAction,
      clearPutUser: fetchDux.putUser.clearAction,
      getCustomersUserById: fetchDux.getCustomersUserById.createAction,
      clearGetCustomersUserById: fetchDux.getCustomersUserById.clearAction,
      searchCustomersUsers: fetchDux.searchCustomersUsers.createAction,
      clearSearchCustomersUsers: fetchDux.searchCustomersUsers.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalEditCustomersUser);
