import React, { useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';
import numeral from 'numeral';


const data = {
  name: 'Quote name',
  user: { firstName: 'Joe', lastName: 'Doe', id: 1 },
  items: 42,
  sale: 234567
};

const required = value => value ? undefined : 'Required';

const ShareQuoteForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result,
    initialValues,
    handleChange
  } = props;

  const {
    firstName,
    lastName,
    customerId,
    quoteName,
    itemsCount,
    grandTotal,
    users
  } = initialValues;

  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    const ids = users.map(({ Id }) => Id);
    setSelectedUsers(ids);
  }, [])

  useEffect(() => {
    handleChange('share-quote-form', 'selectedUsers', selectedUsers);
  }, [selectedUsers]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">

        <h3>{quoteName}</h3>

        <div>
          <h5 style={{ color: 'rgba(0, 0, 0, 0.35)', fontSize: '0.8rem', marginBottom: '0.3rem' }}>
            Sharing quote with
          </h5>
          <div style={{ color: 'rgba(0, 0, 0, 0.35)', marginBottom: '0.75rem', fontSize: '0.7rem' }}>Click 'x' to exclude a user</div>

          <div className='pill-group'>
            {(users || []).map((user, index) => {
              const { Id, firstName, lastName } = user;
              return selectedUsers.includes(Id) ?
                (
                  <span key={index} className="pill">
                    {firstName} {lastName}
                    <Icon
                      name="times"
                      onClick={() => {
                        const selected = selectedUsers.filter(userId => userId != Id);
                        setSelectedUsers(selected);
                      }}
                      style={{ cursor: 'pointer' }}
                    />
                  </span>
                )
                :
                (
                  <span key={index} className="pill" style={{ textDecoration: 'line-through' }}>
                    {firstName} {lastName}
                    <Icon
                      name="check"
                      onClick={() => {
                        const selected = [...selectedUsers, Id];
                        setSelectedUsers(selected);
                      }}
                      style={{ cursor: 'pointer', color: 'green' }}
                    />
                  </span>
                )
            })
            }
          </div>
          {/* <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              paddingRight: '3rem'
            }}
          >
            {(users || []).map((user, index) => {
              const { Id, firstName, lastName } = user;
              return (
                <div
                  style={{ marginBottom: '1rem', marginRight: '1rem' }}
                  key={index}
                >
                  <Icon name="user-circle" style={{ marginRight: '5px' }} />
                  {' '}
                  <Link to={`/customers/users/${Id}`}>
                    {firstName} {lastName}
                  </Link>
                </div>
              );
            })}
          </div> */}
        </div>

        <table className="table-data" style={{ marginTop: 0 }}>
          <tbody>
            {/* <tr>
              {
                users.map((user, index) => {
                  const { Id, firstName, lastName } = user;
                  return (
                    <td key={index}>
                      <Icon name="user-circle" style={{ marginRight: '5px' }} />
                      {' '}
                      <Link to={`/customers/users/${Id}`}>
                        {firstName} {lastName}
                      </Link>
                    </td>
                  )
                })
              }

            </tr> */}
            <tr style={{ borderTop: '1px solid rgba(0,0,0,0.15)' }}>
              <th>Items</th>
              <td>{itemsCount}</td>
            </tr>
            <tr>
              <th>Sale</th>
              <td>${numeral(grandTotal).format('0,0.00')}</td>
            </tr>
          </tbody>
        </table>

        <Field
          name="note"
          rows={6}
          component={FormField.Textarea}
          label="Customer Note"
          insetLabel
        />

      </div>

      <div className="modal-footer">
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          {
            selectedUsers.length === 0 &&
            <div className='text-danger' style={{ marginBottom: '0.5rem' }}>
              All of the users are excluded
            </div>
          }
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button variant="outline-secondary" type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit" disabled={submitting}>
              Share Now
            </Button>
          </div>

        </div>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'share-quote-form'
})(ShareQuoteForm);
