import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import AddCustomersUserForm from './AddCustomersUserForm';
import Notification from '../../../ui/Notification';

const getInitialUsersState = currentUsers => {
  return (currentUsers || []).map(({ id }) => id);
};

class ModalAddCustomersUser extends Component {
  constructor(props) {
    super(props);
    this.state = { userIds: [] };
  }
  componentDidMount() {
    const {
      currentUsers
    } = this.props;

    this.setState({ userIds: getInitialUsersState(currentUsers) });
  }
  componentWillUnmount() {
    const {
      clearGetCompanyById
    } = this.props;
    this.setState({ userIds: [] });
    clearGetCompanyById();
  }
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmitAddUser,
      companyUsers,
      currentUsers,
      postLocationUsers,
      entity,
      getLocationById,
      isCompany,
      postCompanyUsers,
      getCompanyById,
      postError,
      clearPostLocationUsers,
      clearPostCompanyUsers
    } = this.props;

    const {
      userIds
    } = this.state;

    const {
      id
    } = entity || {};

    const handleUserChange = newSelectedUser => {
      if (!newSelectedUser) return;
      this.setState({ userIds: (userIds || []).concat(newSelectedUser) });
    };

    const handleRemoveUser = removeUserId => {
      if (!removeUserId) return;
      const {
        userIds
      } = this.state;
      const newUserArray = (userIds || [])
        .filter(userId => removeUserId !== userId);
      this.setState({ userIds: newUserArray });
    };

    const handleHideModal = () => {
      closeModal();
      this.setState({ userIds: getInitialUsersState(currentUsers) });
    };

    const handlePostEntityUsers = () => {
      if (isCompany) {
        postCompanyUsers({ id, payload: { userIds } }, null, null, () => {
          getCompanyById({ id, query: { getUsers: 1 } }, null, true);
        });
        return;
      }
      postLocationUsers({ id, payload: { userIds } }, null, null, () => {
        getLocationById({ id, query: {} }, null, true);
      });
    };

    const usersDisplay = (companyUsers || [])
      .sort((a, b) => {
        var nameA = a.nameFirst.toUpperCase(); // ignore upper and lowercase
        var nameB = b.nameFirst.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .filter(({ id }) => (userIds || []).includes(id));

    const userOptions = (companyUsers || [])
      .map(({ nameFirst, nameLast, id }) => ({
        value: id,
        label: `${nameFirst} ${nameLast}`
      }))
      .sort((a, b) => {
        var nameA = a.label.toUpperCase(); // ignore upper and lowercase
        var nameB = b.label.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names must be equal
      })
      .filter(({ value }) => !(userIds || []).includes(value));

    const noUsersLeft = !(userOptions || []).length &&
      !!(companyUsers || []).length;
    const noCompanyUsers = !(companyUsers || []).length;

    return (
      <Modal
        show={openModal === name}
        onHide={handleHideModal}
        className="modal-side"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Users</Modal.Title>
        </Modal.Header>
        <AddCustomersUserForm
          enableReinitialize
          usersDisplay={usersDisplay}
          onSubmit={handlePostEntityUsers}
          handleUserChange={handleUserChange}
          handleRemoveUser={handleRemoveUser}
          handleHideModal={handleHideModal}
          userOptions={userOptions}
          noUsersLeft={noUsersLeft}
          noCompanyUsers={noCompanyUsers}
          isCompany
        />
        {postError &&
          <Notification
            key="company-delete-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={isCompany ? clearPostCompanyUsers : clearPostLocationUsers}
          >
            {
              `Failed to update ${isCompany ? 'company' : 'location'} users. Please try again.`
            }
          </Notification>}
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    error: postCompanyUsersError
  } = state.postCompanyUsers.toJS();
  const {
    error: postLocationUsersError
  } = state.postLocationUsers.toJS();

  return {
    postError: postCompanyUsersError || postLocationUsersError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getLocationById: fetchDux.getLocationById.createAction,
      postLocationUsers: fetchDux.postLocationUsers.createAction,
      clearPostLocationUsers: fetchDux.postLocationUsers.clearAction,
      postCompanyUsers: fetchDux.postCompanyUsers.createAction,
      clearPostCompanyUsers: fetchDux.postCompanyUsers.clearAction,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalAddCustomersUser);
