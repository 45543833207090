import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import ReactStars from 'react-rating-stars-component';
import numeral from 'numeral';

const AddWarehouseCommentForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    ratingChanged,
    rating
  } = props;

  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        <label className="form-label required">Rating</label>
        <div className="form-group-rating">
          <ReactStars
            count={5}
            onChange={ratingChanged}
            size={24}
            isHalf={true}
            emptyIcon={<i className="far fa-star" />}
            halfIcon={<i className="fa fa-star-half-alt" />}
            fullIcon={<i className="fa fa-star" />}
            activeColor="#ffd700"
          />
          <div>
            {numeral(rating).format('0.0')}
          </div>
        </div>

        <br />

        <Field
          name="comment"
          component={FormField.Textarea}
          label="Comment (optional)"
          rows={6}
        />

      </div>

      <div className="modal-footer">
        <Button
          variant="outline-secondary"
          type="button"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          Save Rating
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'add-warehouse-comment-form'
})(AddWarehouseCommentForm);
