import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';

const ModalConfirmDeleteProject = (
  {
    openModal,
    name,
    closeModal,
    onCancelProject,
    inProgress,
    error,
    getOrderLockedResult
  }
) => {
  const { locked, partialLocked } = getOrderLockedResult || {};
  const warn = locked || partialLocked;
  const [showConfirmed, setShowConfirmed] = useState(false);
  const show = openModal === name;
  const handleCloseModal = () => {
    setShowConfirmed(false);
    closeModal();
  };
  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          &nbsp;
          Delete Project
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!showConfirmed &&
          <p style={{ textAlign: 'center' }}>
            Are you sure you want to delete project?
          </p>}
        {showConfirmed &&
          <div
            style={{
              color: '#C23934',
              textAlign: 'center'
            }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            Order has received cost. Are you sure?
          </div>}
        {/* <p style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          &nbsp;
          <b>This action cannot be undone!</b>
        </p> */}
        {error &&
          <div
            style={{
              color: '#C23934',
              textAlign: 'center',
              padding: '20px 60px 20px 0'
            }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            {error.message || 'An error occurred'}
          </div>}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Row>
          <Col style={{ color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>
          <Col sm="auto">
            <Button
              type="button"
              size="sm"
              variant="secondary"
              onClick={handleCloseModal}
              disabled={inProgress}
            >
              Cancel
            </Button>
            {!showConfirmed &&
              <Button
                type="button"
                size="sm"
                variant="danger"
                onClick={warn ? () => setShowConfirmed(true) : onCancelProject}
                disabled={inProgress}
                style={{ marginLeft: '10px' }}
              >
                Yes, Delete Project
              </Button>}
            {showConfirmed &&
              <Button
                type="button"
                size="sm"
                variant="danger"
                onClick={onCancelProject}
                disabled={inProgress}
                style={{ marginLeft: '10px' }}
              >
                Yes, Delete Project
              </Button>}
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => {
  const {
    inProgress: deleteProjectInProgress,
    error: deleteProjectError
  } = state.deleteProject.toJS();
  const {
    result: getOrderLockedResult
  } = state.getOrderLocked.toJS();
  return {
    inProgress: deleteProjectInProgress,
    error: deleteProjectError,
    openModal: state.modals,
    getOrderLockedResult
  };
};

const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalConfirmDeleteProject);
