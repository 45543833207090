import React, { Component, useState } from 'react';
import { Button, Row, Col, Dropdown } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import moment from 'moment';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ProjectError from './ProjectError';

import statusDict, { getStatusLabel } from '../statusDict';

import ModalConfirmOrderInsert from './ModalConfirmOrderInsert';
import ModalInvoiceHistory from './ModalInvoiceHistory';
import { actionCreators } from '../../../../state/modals-dux';

import DiffRender from '../projects-update/DiffRender';
import OrderLocked from '../projects-update/OrderLocked';
import ImportPing from '../projects-update/import/ImportPing';
import DeletePing from '../projects-update/delete-project/DeletePing';
import { downloadAllPurchaseOrders } from '../../../../api/api';
import download from 'downloadjs';
import Loading from '../../../ui/Loading';

const HIDE_MENU = process.env.REACT_APP_HIDE_MENU === 'true';

const formatMoney = value => numeral(value).format('0,0.00');

const getCustomer = ({ customer, addresses }) => {
  const { CustomerAddressId } = customer || {};
  return (addresses || [])
    .find(({ AddressId }) => AddressId === CustomerAddressId) || {};
};

const getAddressLine = value => {
  if (!value) return '';
  return <span>{value}<br /></span>;
};

class CustomToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <Button onClick={this.handleClick}>
        {this.props.children}
      </Button>
    );
  }
}

const DisableLink = (
  {
    disabled,
    style,
    ...props
  }
) => {
  if (!disabled) return <Link {...props} style={style || {}} />;
  return (
    <Link
      {...props}
      onClick={e => e.preventDefault()}
      style={{ ...(style || {}), opacity: '.5', cursor: 'not-allowed' }}
    />
  );
};

const getIsProject = c_SageIntegrationStatus => {
  return [
    statusDict.VALIDATED,
    statusDict.SHIPPING_CONFIRMED,
    statusDict.APPROVE_INPROGRESS,
    statusDict.APPROVE_COMPLETE,
    statusDict.INSERT_INPROGRESS
  ].some(x => x === c_SageIntegrationStatus);
};

const Address = (
  {
    Name,
    FirstName,
    LastName,
    Address1,
    Address2,
    City,
    State,
    Zip,
    c_Zip_4
  } = {}
) => (
  <address>
    {getAddressLine(Name)}
    {getAddressLine(`${FirstName || ''} ${LastName || ''}`)}
    {getAddressLine(`${Address1 || ''} ${Address2 || ''}`)}
    {City ? `${City},` : ''}
    {' '}
    {State || ''}
    {' '}
    {Zip || ''}
    {c_Zip_4 ? `-${c_Zip_4}` : ''}
  </address>
);

const ProjectInfo = (
  {
    status,
    result,
    handleConfirmShippingInfo,
    handleConfirmReviewItems,
    handleUpdateProject,
    handleShowEditShippingModal,
    handleShowValidateShippingInfoModal,
    handleValidateShippingInfo,
    handleConfirmInsertOrders,
    handleConfirmSendPOs,
    handleViewProjectErrors,
    showModal,
    minimal,
    user,
    confirmDeleteProject,
    handleSendOrderInvoiceModal,
    projectId,
    backButton,
    isReimport,
    actions,
    reimportError,
    reimportErrorMessage,
    nextSteps,
    hasReimportChanges
  }
) => {
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const {
    project,
    vendors,
    lineItems,
    customer,
    addresses,
    projectAddress,
    errors,
    canEditProjectAddress,
    importToSageErrors,
    calculatedTotals,
    importLog,
    hasUnsentPOs
  } = result || {};
  const {
    ProjectName,
    MerchandiseNet,
    MerchandiseSell,
    c_ImportedDate: ImportedDate,
    c_ProjectImportedDate,
    JobRef,
    c_ProjectAddressId
  } = project || {};
  const {
    totalGl,
    sumTotal,
    tax
  } = calculatedTotals || {};
  const [orderStatus, setOrderStatus] = useState(null);
  const [pingStatus, setPingStatus] = useState(null);
  const [delPingStatus, setDelPingStatus] = useState(null);
  const { length: numVendors } = vendors || {};
  const { length: numItems } = lineItems || {};

  const { editable: e1 } = orderStatus || {};
  const { inProgress: e2 } = pingStatus || {};
  const { inProgress: e3 } = delPingStatus || {};
  const { allLocked } = orderStatus || {};
  const editable = e1 && !e2 && !e3 && status !== statusDict.ARCHIVED;

  const { hasErrors } = importToSageErrors || {};
  const {
    Name: CustomerName
  } = getCustomer({ customer, addresses });

  const { ExportId } = customer || {};

  const {
    City,
    State,
    c_ShipDate,
    CarrierName,
    c_ShipViaId
  } = projectAddress || {};

  const { isAdmin } = user || {};
  const canDelete = status !== statusDict.ARCHIVED &&
    status !== statusDict.INVALID &&
    (e1 || isAdmin);

  const { Name: importedBy } = importLog || {};
  const isProject = getIsProject(status);

  return (
    <div className="project-info">
      <ModalConfirmOrderInsert
        name="ConfirmOrderInsertModal"
        onSubmit={handleConfirmInsertOrders}
      />
      {status === statusDict.ARCHIVED &&
        <div className={`project-info-label red`}>
          <Icon name="trash" style={{ fontSize: '20px' }} />
          <div style={{ fontWeight: 'bold' }}>Project has been deleted</div>
        </div>}
      {isReimport &&
        status === statusDict.REIMPORT &&
        <div
          className={`project-info-label ${!!reimportError ? 'red' : 'green'}`}
        >
          <Button
            variant="secondary"
            size="sm"
            onClick={handleUpdateProject}
            disabled={!!reimportError || !hasReimportChanges}
          >
            Sync to Sage
          </Button>
          <Icon name="check-circle" />
          <div>{reimportErrorMessage || 'Project has been updated'}</div>
          <div>
            Next Steps: {nextSteps || 'Review Changes and Sync to Sage.'}
          </div>
        </div>}
      {!minimal &&
        status === statusDict.VALIDATED &&
        <div className="project-info-label green">
          {!c_ProjectAddressId &&
            <Button
              variant="secondary"
              size="sm"
              onClick={handleShowEditShippingModal}
              disabled={hasErrors}
            >
              Add Shipping Info
            </Button>}
          {c_ProjectAddressId &&
            <Button
              variant="secondary"
              size="sm"
              onClick={handleValidateShippingInfo}
              disabled={hasErrors}
            >
              Confirm Shipping Info
            </Button>}
          <Icon name="check-circle" />
          <div>"{ProjectName}" project has been uploaded and validated.</div>
          <div>Next Steps: Review and confirm shipping information.</div>
        </div>}
      {!minimal &&
        status === statusDict.SHIPPING_CONFIRMED &&
        <div className="project-info-label green">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleConfirmReviewItems}
            disabled={hasErrors}
          >
            Review Items
          </Button>
          <Icon name="check-circle" />
          <div>Shipping Information has been confirmed.</div>
          <div>Next Steps: Review vendor and items.</div>
        </div>}

      {!minimal &&
        status === statusDict.APPROVE_COMPLETE &&
        <div className="project-info-label green">
          <Button
            variant="secondary"
            size="sm"
            onClick={() => showModal('ConfirmOrderInsertModal')}
            disabled={hasErrors}
          >
            Insert Orders and Create POs
          </Button>
          <Icon name="check-circle" />
          <div>All vendor items have been reviewed and approved.</div>
          <div>Next Steps: Insert Order and Create POs.</div>
        </div>}
      {!minimal &&
        status === statusDict.INSERT_COMPLETE &&
        <div className="project-info-label green">
          <Button
            variant="secondary"
            size="sm"
            onClick={handleConfirmSendPOs}
            disabled={hasErrors}
          >
            Send POs
          </Button>
          <Icon name="check-circle" />
          <div>
            Congratulations. Your Order has been inserted and POs have been created
          </div>
          <div>Next Steps: Send POs to vendors</div>
        </div>}
      {!minimal &&
        status === statusDict.SEND_COMPLETE &&
        <div className="project-info-label green">
          <Icon name="check-circle" />
          <div>
            Congratulations. YOU'RE ALMOST DONE!
          </div>
          <div>
            Next Steps: Finalize Sales Order Address, GL Totals, and Set Tax Status.
          </div>
        </div>}
      {/*!minimal &&
        status === statusDict.COMPLETE &&
        <div className="project-info-label green">
          <Icon name="check-circle" />
          <div>
            Congratulations. YOU'RE DONE!
          </div>
          <div>Next Steps: Take a break.</div>
        </div>*/}
      {!minimal &&
        status === statusDict.INVALID &&
        <div className="project-info-label red">
          <Link to="/projects/new">
            <Button variant="secondary" size="sm">
              Reimport
            </Button>
          </Link>
          <Button
            variant="secondary"
            size="sm"
            style={{ marginRight: '10px' }}
            onClick={handleViewProjectErrors}
          >
            View Error Summary
          </Button>
          <Icon name="exclamation-triangle" />
          <div>Oops! Your project is missing some important information.</div>
          <div>Next Steps: Correct in AutoQuotes and reimport.</div>
        </div>}
      {/* By Kanthi On 11th Oct 2023
          I did not understand why the minimal flag was used.
          It's being used a lot in this component. 
          Which is one of the reason why the import errors are not 
          being displayed. More info on CSIMAP-220.
          So to fix this issue, below is the logic.
          I'll be creating a follow up ticket to understand 
          why the minimal flag is used.
         */}
      {minimal && isReimport &&
        status === statusDict.INVALID &&
        <div className="project-info-label red">
          <Link to={`/projects/${projectId}/import`}>
            <Button variant="secondary" size="sm">
              Reimport
            </Button>
          </Link>
          <Button
            variant="secondary"
            size="sm"
            style={{ marginRight: '10px' }}
            onClick={handleViewProjectErrors}
          >
            View Error Summary
          </Button>
          <Icon name="exclamation-triangle" />
          <div>Oops! Your project is missing some important information.</div>
          <div>Next Steps: Correct in AutoQuotes and reimport.</div>
        </div>}

      <div className="project-info-header">
        {!isReimport &&
          (status === statusDict.COMPLETE || status === statusDict.ARCHIVED) &&
          !backButton &&
          <Dropdown
            style={{ fontSize: '14px', marginLeft: '5px' }}
            drop="down"
            alignRight
          >
            <Dropdown.Toggle as={CustomToggle} id="dropdown-pr-menu">
              <Icon name="caret-down" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Link
                className="dropdown-item"
                to={`/projects/${projectId}/logs`}
              >
                View Logs
              </Link>
              <Dropdown.Divider />
              <DisableLink
                className="dropdown-item"
                to={`/projects/${projectId}/import`}
                disabled={!editable || HIDE_MENU}
              >
                Update Equipment
              </DisableLink>
              <DisableLink
                className="dropdown-item"
                to={`/projects/${projectId}/line-item-instructions`}
                disabled={!editable || HIDE_MENU}
              >
                Update Line Item Instructions
              </DisableLink>
              <DisableLink
                className="dropdown-item"
                to={`/projects/${projectId}/misc-charges`}
                disabled={!editable || HIDE_MENU}
              >
                Update Misc Charges
              </DisableLink>
              <DisableLink
                className="dropdown-item"
                to={`/projects/${projectId}/shipping`}
                disabled={!editable || HIDE_MENU}
              >
                Update Shipping Instructions
              </DisableLink>
              <DisableLink
                className="dropdown-item"
                to={`/projects/${projectId}/tax-status`}
                disabled={!editable || HIDE_MENU}
              >
                Update Tax Info
              </DisableLink>
              <DisableLink
                className="dropdown-item"
                to={`/projects/${projectId}/vendors`}
                disabled={!editable || HIDE_MENU}
              >
                Update Vendors
              </DisableLink>
              <DisableLink
                className="dropdown-item"
                to={`/projects/${projectId}/send`}
                disabled={status === statusDict.ARCHIVED}
              >
                Send Purchase Orders
              </DisableLink>
              {status === statusDict.COMPLETE &&
                !!allLocked &&
                <Dropdown.Item onClick={handleSendOrderInvoiceModal}>
                  Send Invoice Request
                </Dropdown.Item>}
              {/* <Dropdown.Item onClick={() => showModal('invoiceHistoryModal')}>
                Invoice History
              </Dropdown.Item> */}
              <Dropdown.Divider />
              {canDelete &&
                <Dropdown.Item onClick={confirmDeleteProject}>
                  Delete Project
                </Dropdown.Item>}
            </Dropdown.Menu>
          </Dropdown>}
        {(status === statusDict.COMPLETE || isReimport) &&
          backButton &&
          <Link to={`/projects/${projectId}`} className="btn btn-back">
            <Icon name="angle-left" /> Back
          </Link>}
        {!isProject && <div className="symbol">SO</div>}
        {isProject && <div className="symbol">AQ</div>}
        <div className="project">
          {!isProject && <div>Order</div>}
          {isProject && <div>Project</div>}
          <DiffRender
            field="ProjectName"
            value={ProjectName}
            placement="right"
            actions={actions}
            renderDiff={value => value}
            diffStyle={{ textAlign: 'right' }}
            render={value => <strong>{value}</strong>}
          />
        </div>
        {status === statusDict.COMPLETE &&
          <OrderLocked
            projectId={projectId}
            setLocked={status => setOrderStatus(status)}
          />}
        {status === statusDict.COMPLETE &&
          <ImportPing
            projectId={projectId}
            style={{ float: 'right', marginRight: '20px' }}
            setPing={values => setPingStatus(values)}
          />}
        <DeletePing
          projectId={projectId}
          style={{ float: 'right', marginRight: '20px' }}
          setPing={values => setDelPingStatus(values)}
        />
      </div>
      {status !== statusDict.SHIPPING_VALIDATED &&
        <div className="project-info-body">
          <Row>
            <Col>
              <h5>Customer</h5>
              <div className="value customer">
                <ProjectError errors={errors} field="Customer" />
                {CustomerName} ({ExportId})
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: '10px' }}>
            <Col sm={2}>
              <h5>Equipment Cost</h5>
              <div className="value large">
                ${formatMoney(MerchandiseNet)}
              </div>
            </Col>
            <Col sm={6}>
              <Row>
                <Col sm="auto">
                  <h5>Vendors</h5>
                  <div className="value large">
                    {numVendors}
                  </div>
                </Col>
                <Col sm="auto">
                  <h5>Items</h5>
                  <div className="value large">
                    {numItems}
                  </div>
                </Col>
                <Col sm="auto">
                  <h5>JobRef</h5>
                  <div className="value large">
                    <ProjectError errors={errors} field="JobRef" />
                    {JobRef}
                  </div>
                </Col>
                <Col md="auto">
                  <h5>Ship To Location</h5>
                  <div className="value large">
                    {/* <ProjectError errors={errors} field="SalesRep" /> */}
                    {City}, {State}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col>
              <h5>Date Imported</h5>
              <div className="value large">
                {moment(c_ProjectImportedDate).format('MM/DD/YYYY')}
              </div>
            </Col>
            <Col>
              {/* <h5>Status</h5> */}
              <h5>Ship Date</h5>
              <div className="value large">
                {/* {getStatusLabel(status)} */}
                {moment(c_ShipDate).format('MM/DD/YYYY')}
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={2}>
              <h5>Equipment Sell</h5>
              <div className="value large">
                ${formatMoney(MerchandiseSell)}
              </div>
            </Col>
            <Col sm={2}>
              <h5>GL Sell</h5>
              <div className="value large">
                ${formatMoney(totalGl)}
              </div>
            </Col>
            <Col sm={2}>
              <h5>Total Tax</h5>
              <div className="value large">
                ${formatMoney(tax)}
              </div>
            </Col>
            <Col sm={2}>
              <h5>Total Sell</h5>
              <div className="value large">
                ${formatMoney(sumTotal)}
              </div>
            </Col>
            <Col>
              <h5>Last Equipment Update</h5>
              <div className="value large">
                {moment(ImportedDate).format('MM/DD/YYYY')}
              </div>
            </Col>
          </Row>

          {(isReimport || !minimal) &&
            status < statusDict.APPROVE_INPROGRESS &&
            <div>
              <h4 style={{ color: '#D1B043' }}>
                Shipping Information - All items ship per below instructions unless otherwise noted with individual item.
              </h4>
              <Row>
                <Col>
                  <h3>Ship To Location</h3>
                  <div className="value address">
                    <Address {...projectAddress} />
                  </div>
                </Col>
                <Col className="text-right">
                  <h3>Ship Via</h3>
                  <div className="value bold">
                    {CarrierName || 'Use Cheapest Option Available'}
                  </div>
                </Col>
                <Col className="text-right">
                  <h3>Ship Date</h3>
                  <div className="value bold">
                    {c_ShipDate && moment(c_ShipDate).isValid()
                      ? moment(c_ShipDate).format('MM/DD/YYYY')
                      : ''}
                  </div>
                </Col>
                <Col className="text-right">
                  <Icon
                    name="truck"
                    style={
                      status >= statusDict.APPROVE_COMPLETE
                        ? { color: '#04844B' }
                        : {}
                    }
                  />
                  {!minimal &&
                    canEditProjectAddress &&
                    c_ProjectAddressId &&
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleShowEditShippingModal}
                    >
                      Edit Default Shipping Info
                    </Button>}
                </Col>
              </Row>

              {!isReimport &&
                <div className="note" style={{ color: '#D1B043' }}>
                  NOTE - You may make changes as needed prior to creating purchase orders.
                </div>}
            </div>}

          {!minimal &&
            status >= statusDict.APPROVE_INPROGRESS &&
            <div>
              <Row>
                <Col className="text-right">
                  {/* <Icon
                  name="truck"
                  style={
                    status >= statusDict.APPROVE_COMPLETE
                      ? { color: '#04844B' }
                      : {}
                  }
                /> */}
                  {canEditProjectAddress &&
                    c_ProjectAddressId &&
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleShowEditShippingModal}
                    >
                      Edit Default Shipping Info
                    </Button>}
                </Col>
              </Row>
            </div>}
          <Row>
            {status === statusDict.COMPLETE &&
              hasUnsentPOs &&
              <Col style={{ color: '#C23934', marginTop: '30px' }}>
                <Icon name="exclamation-triangle" />
                {' '}
                Project has unsent Purchase Orders
              </Col>}
            {status === statusDict.COMPLETE &&
              <Col
                className="text-right"
                style={{
                  marginTop: '30px',
                  color: 'rgba(0, 0, 0, 0.45)',
                  fontWeight: 'bold'
                }}
              >
                <div style={{ display: 'inline-block' }}>
                  <span style={{ marginRight: '0.35rem' }}>
                    Imported By: {importedBy}
                  </span>
                  <Button
                    size="sm"
                    type="button"
                    variant="primary"
                    onClick={() => {
                      setDownloadInProgress(true);
                      downloadAllPurchaseOrders(projectId, JobRef)
                        .then(function (response) {
                          return response.blob();
                        })
                        .then(function (blob) {
                          setDownloadInProgress(false);
                          download(blob, `${JobRef}_purchaseorders`);
                        });
                    }}
                    disabled={downloadInProgress}
                  >
                    {`Download All PO's`}
                  </Button>
                </div>
              </Col>}
            {status !== statusDict.COMPLETE &&
              <Col
                className="text-right"
                style={{
                  marginTop: '30px',
                  color: 'rgba(0, 0, 0, 0.45)',
                  fontWeight: 'bold'
                }}
              >
                Imported By: {importedBy}
              </Col>}
          </Row>
        </div>}
      {/* <ModalInvoiceHistory name="invoiceHistoryModal" /> */}

    </div>
  );
};

const mapState = state => ({});

const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ProjectInfo);
