import React from 'react';

const ScrollBox = ({ height, children }) => (
  <div className="scroll-box">
    <div className="scroll" style={{ maxHeight: height }}>
      <div>
        {children}
      </div>
    </div>
  </div>
);

export default ScrollBox;
