import React from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import SendOrderInvoiceForm from './SendOrderInvoiceForm';

const ModalSendOrderInvoice = (
  {
    openModal,
    closeModal,
    name,
    projectId,
    jobRef,
    user,
    handleSendOrderInvoice
  }
) => {
  return (
    <Modal show={openModal === name} onHide={closeModal} className="modal-side">
      <Modal.Header closeButton>
        <Modal.Title>Send Invoice Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <SendOrderInvoiceForm
          form="SendOrderInvoiceForm"
          onSubmit={handleSendOrderInvoice}
          initialValues={{
            to: [
              {
                EmailRecipient: 'invoicerequest@conceptserv.com',
                Type: 'to'
              }
            ],
            subject: `Please Invoice Order #${jobRef}`,
            body: `From: ${user.email}\n\nPlease invoice order #${jobRef}, all costs have been received and it is ready for invoicing.\n\nIf there is any issue with this request, please let me know ASAP.`
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

const mapState = state => {
  return {
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalSendOrderInvoice);
