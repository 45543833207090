import duxFactory from './fetch-dux-factory';
import * as api from '../api/api';

export default {
  login: duxFactory({
    apiFn: api.postLogin,
    actionPrefix: 'POST_LOGIN'
  }),
  validateLogin: duxFactory({
    apiFn: api.validateLogin,
    actionPrefix: 'POST_VALIDATE_LOGIN',
    requestListeners: ['POST_LOGIN'],
    successListeners: ['POST_LOGIN']
  }),
  postProject: duxFactory({
    apiFn: api.postProject,
    actionPrefix: 'POST_PROJECT'
  }),
  getProjectById: duxFactory({
    apiFn: api.getProjectById,
    actionPrefix: 'GET_PROJECT_BY_ID',
    successListeners: [
      'POST_UPDATE_STATUS',
      'POST_APPROVE_PURCHASE_ORDER',
      'POST_PROJECT_LINE_ITEMS',
      'POST_PROJECT_ADDRESS',
      'POST_PURCHASE_ORDER_ADDRESS',
      'POST_SEND_PURCHASE_ORDER',
      'POST_PO_VENDOR',
      'DEL_DELETE_ORPHANED_ITEMS',
      'POST_ADDITIONAL_TOTAL',
      'POST_FINALIZE_PROJECT',
      'DEL_ADDITIONAL_TOTAL',
      'POST_UPDATE_VENDOR',
      'POST_UPDATE_ADDITIONAL_TOTALS',
      'POST_CANCEL_RESEND_WARNING',
      'POST_RESEND_PURCHASE_ORDER',
      'POST_UPDATE_LINEITEM_SPECIAL_INSTRUCTIONS'
    ]
  }),
  searchProjects: duxFactory({
    apiFn: api.searchProjects,
    actionPrefix: 'GET_SEARCH_PROJECTS'
  }),
  postUpdateStatus: duxFactory({
    apiFn: api.postUpdateStatus,
    actionPrefix: 'POST_UPDATE_STATUS',
    successEmit: ['CLOSE_MODAL']
  }),
  postApprovePurchaseOrder: duxFactory({
    apiFn: api.postApprovePurchaseOrder,
    actionPrefix: 'POST_APPROVE_PURCHASE_ORDER'
  }),
  postProjectLineItem: duxFactory({
    apiFn: api.postProjectLineItem,
    actionPrefix: 'POST_PROJECT_LINE_ITEMS'
  }),
  postProjectAddress: duxFactory({
    apiFn: api.postProjectAddress,
    actionPrefix: 'POST_PROJECT_ADDRESS',
    successEmit: ['CLOSE_MODAL']
  }),
  sendOrderInvoice: duxFactory({
    apiFn: api.sendOrderInvoice,
    actionPrefix: 'SEND_ORDER_INVOICE'
  }),
  getLookups: duxFactory({
    apiFn: api.getLookups,
    actionPrefix: 'GET_LOOKUPS',
    successListeners: ['DEL_VENDOR_JOIN']
  }),
  postPurchaseOrderAddress: duxFactory({
    apiFn: api.postPurchaseOrderAddress,
    actionPrefix: 'POST_PURCHASE_ORDER_ADDRESS'
  }),
  postSendPurchaseOrder: duxFactory({
    apiFn: api.postSendPurchaseOrder,
    actionPrefix: 'POST_SEND_PURCHASE_ORDER',
    successEmit: ['CLOSE_MODAL']
  }),
  getProjectImportStatus: duxFactory({
    persist: true,
    apiFn: api.getProjectImportStatus,
    actionPrefix: 'GET_PROJECT_IMPORT_STATUS'
  }),
  postPOVendor: duxFactory({
    apiFn: api.postPOVendor,
    actionPrefix: 'POST_PO_VENDOR',
    successEmit: ['CLOSE_MODAL']
  }),
  searchVendors: duxFactory({
    apiFn: api.searchVendors,
    actionPrefix: 'GET_SEARCH_VENDORS'
  }),
  postVendorJoin: duxFactory({
    apiFn: api.postVendorJoin,
    actionPrefix: 'POST_VENDOR_JOIN'
  }),
  getPurchaseOrderPublic: duxFactory({
    apiFn: api.getPurchaseOrderPublic,
    actionPrefix: 'GET_PURCHASE_ORDER_PUBLIC'
  }),
  deleteOrphanedItems: duxFactory({
    apiFn: api.deleteOrphanedItems,
    actionPrefix: 'DEL_DELETE_ORPHANED_ITEMS',
    successEmit: ['CLOSE_MODAL']
  }),
  postAdditionalTotal: duxFactory({
    apiFn: api.postAdditionalTotal,
    actionPrefix: 'POST_ADDITIONAL_TOTAL'
  }),
  postFinalizeProject: duxFactory({
    apiFn: api.postFinalizeProject,
    actionPrefix: 'POST_FINALIZE_PROJECT'
  }),
  deleteAdditionalTotal: duxFactory({
    apiFn: api.deleteAdditionalTotal,
    actionPrefix: 'DEL_ADDITIONAL_TOTAL'
  }),
  getProjectTaxStatus: duxFactory({
    apiFn: api.getProjectTaxStatus,
    actionPrefix: 'GET_PROJECT_TAX_STATUS'
  }),
  deleteVendorJoin: duxFactory({
    apiFn: api.deleteVendorJoin,
    actionPrefix: 'DEL_VENDOR_JOIN'
  }),
  getProjectLogsById: duxFactory({
    apiFn: api.getProjectLogsById,
    actionPrefix: 'GET_PROJECT_LOGS_BY_ID',
    successListeners: ['PUT_EMAIL_LOG_DISABLE']
  }),
  putEmailLogDisable: duxFactory({
    apiFn: api.putEmailLogDisable,
    actionPrefix: 'PUT_EMAIL_LOG_DISABLE'
  }),
  getSalesReps: duxFactory({
    apiFn: api.getSalesReps,
    actionPrefix: 'GET_SALES_REPS',
    successListeners: ['DEL_SALES_REPS', 'PUT_SALES_REPS', 'POST_SALES_REPS']
  }),
  delSalesRep: duxFactory({
    apiFn: api.delSalesRep,
    actionPrefix: 'DEL_SALES_REPS',
    successEmit: ['CLOSE_MODAL']
  }),
  putSalesRep: duxFactory({
    apiFn: api.putSalesRep,
    actionPrefix: 'PUT_SALES_REPS',
    successEmit: ['CLOSE_MODAL']
  }),
  postSalesRep: duxFactory({
    apiFn: api.postSalesRep,
    actionPrefix: 'POST_SALES_REPS',
    successEmit: ['CLOSE_MODAL']
  }),
  getVendors: duxFactory({
    apiFn: api.getVendors,
    actionPrefix: 'GET_VENDORS',
    successListeners: ['DEL_VENDORS', 'PUT_VENDORS', 'POST_VENDORS']
  }),
  delVendor: duxFactory({
    apiFn: api.delVendor,
    actionPrefix: 'DEL_VENDORS',
    successEmit: ['CLOSE_MODAL']
  }),
  putVendor: duxFactory({
    apiFn: api.putVendor,
    actionPrefix: 'PUT_VENDORS',
    successEmit: ['CLOSE_MODAL']
  }),
  postVendor: duxFactory({
    apiFn: api.postVendor,
    actionPrefix: 'POST_VENDORS',
    successEmit: ['CLOSE_MODAL']
  }),
  getVendorsMappings: duxFactory({
    apiFn: api.getVendorsMappings,
    actionPrefix: 'GET_VENDORS_MAPPINGS',
    successListeners: ['DEL_VENDORS_MAPPINGS', 'POST_VENDORS_MAPPING']
  }),
  deleteVendorsMappings: duxFactory({
    apiFn: api.deleteVendorsMappings,
    actionPrefix: 'DEL_VENDORS_MAPPINGS'
  }),
  getManufacturers: duxFactory({
    apiFn: api.getManufacturers,
    actionPrefix: 'GET_MANUFACTURERS'
  }),
  postVendorsMapping: duxFactory({
    apiFn: api.postVendorsMapping,
    actionPrefix: 'POST_VENDORS_MAPPING'
  }),
  postReimportProject: duxFactory({
    apiFn: api.postReimportProject,
    actionPrefix: 'POST_REIMPORT_PROJECT'
  }),
  getReimportDiff: duxFactory({
    apiFn: api.getReimportDiff,
    actionPrefix: 'GET_REIMPORT_DIFF'
  }),
  postUpdateProjectShipping: duxFactory({
    apiFn: api.postUpdateProjectShipping,
    actionPrefix: 'POST_UPDATE_PROJECT_SHIPPING'
  }),
  getRelatedProjects: duxFactory({
    apiFn: api.getRelatedProjects,
    actionPrefix: 'GET_RELATED_PROJECTS'
  }),
  postUpdateVendor: duxFactory({
    apiFn: api.postUpdateVendor,
    actionPrefix: 'POST_UPDATE_VENDOR'
  }),
  // getPingVendorStatus: duxFactory({
  //   apiFn: api.getPingVendorStatus,
  //   actionPrefix: 'GET_PING_VENDOR_STATUS'
  // }),
  postUpdateAdditionalTotals: duxFactory({
    apiFn: api.postUpdateAdditionalTotals,
    actionPrefix: 'POST_UPDATE_ADDITIONAL_TOTALS'
  }),
  getMiscChargeStatus: duxFactory({
    apiFn: api.getMiscChargeStatus,
    actionPrefix: 'GET_MISC_CHARGE_STATUS'
  }),
  getOrderLocked: duxFactory({
    apiFn: api.getOrderLocked,
    actionPrefix: 'GET_ORDER_LOCKED'
  }),
  postCompleteReimportProject: duxFactory({
    apiFn: api.postCompleteReimportProject,
    actionPrefix: 'POST_COMPLETE_REIMPORT_PROJECT'
  }),
  getReimportStatus: duxFactory({
    apiFn: api.getReimportStatus,
    actionPrefix: 'GET_IMPORT_STATUS'
  }),
  postAbortReimportPing: duxFactory({
    apiFn: api.postAbortReimportPing,
    actionPrefix: 'POST_ABORT_REIMPORT_PING'
  }),
  postResendPurchaseOrder: duxFactory({
    apiFn: api.postResendPurchaseOrder,
    actionPrefix: 'POST_RESEND_PURCHASE_ORDER'
  }),
  getGeocode: duxFactory({
    apiFn: api.getGeocode,
    actionPrefix: 'GET_GEOCODE'
  }),
  postGeoLookup: duxFactory({
    apiFn: api.postGeoLookup,
    actionPrefix: 'POST_GEO_LOOKUP'
  }),
  postUpdateTaxStatus: duxFactory({
    apiFn: api.postUpdateTaxStatus,
    actionPrefix: 'POST_UPDATE_TAX_STATUS'
  }),
  getTaxUpdateStatus: duxFactory({
    apiFn: api.getTaxUpdateStatus,
    actionPrefix: 'GET_TAX_UPDATE_STATUS'
  }),
  postAbortTaxUpdatePing: duxFactory({
    apiFn: api.postAbortTaxUpdatePing,
    actionPrefix: 'POST_ABORT_TAX_UPDATE_PING'
  }),
  cancelProject: duxFactory({
    apiFn: api.cancelProject,
    actionPrefix: 'CANCEL_PROJECT',
    successEmit: ['CLOSE_MODAL']
  }),
  deleteProject: duxFactory({
    apiFn: api.deleteProject,
    actionPrefix: 'DEL_PROJECT',
    successEmit: ['CLOSE_MODAL']
  }),
  getDeleteProjectStatus: duxFactory({
    apiFn: api.getDeleteProjectStatus,
    actionPrefix: 'GET_DEL_PROJECT_STATUS'
  }),
  postAbortDeleteProjectPing: duxFactory({
    apiFn: api.postAbortDeleteProjectPing,
    actionPrefix: 'POST_ABORT_DEL_PROJECT_PING'
  }),
  postCancelResendWarning: duxFactory({
    apiFn: api.postCancelResendWarning,
    actionPrefix: 'POST_CANCEL_RESEND_WARNING'
  }),
  postUpdateLineItemSpecialInstructions: duxFactory({
    apiFn: api.postUpdateLineItemSpecialInstructions,
    actionPrefix: 'POST_UPDATE_LINEITEM_SPECIAL_INSTRUCTIONS'
  }),
  // WAREHOUSES
  getWarehouses: duxFactory({
    apiFn: api.getWarehouses,
    actionPrefix: 'GET_WAREHOUSES'
  }),
  getWarehouseById: duxFactory({
    apiFn: api.getWarehouseById,
    actionPrefix: 'GET_WAREHOUSE_BY_ID',
    successListeners: ['POST_WAREHOUSE_COMMENT']
  }),
  postWarehouse: duxFactory({
    apiFn: api.postWarehouse,
    actionPrefix: 'POST_WAREHOUSE'
  }),
  putWarehouse: duxFactory({
    apiFn: api.putWarehouse,
    actionPrefix: 'PUT_WAREHOUSE'
  }),
  delWarehouse: duxFactory({
    apiFn: api.delWarehouse,
    actionPrefix: 'DEL_WAREHOUSE',
    successEmit: ['CLOSE_MODAL']
  }),
  postWarehouseComment: duxFactory({
    apiFn: api.postWarehouseComment,
    actionPrefix: 'POST_WAREHOUSE_COMMENT'
  }),

  //Companies
  getConceptsLookups: duxFactory({
    apiFn: api.getConceptsLookups,
    actionPrefix: 'GET_CONCEPT_LOOKUPS'
  }),
  searchCustomersCompanies: duxFactory({
    apiFn: api.searchCustomersCompanies,
    actionPrefix: 'GET_SEARCH_CUSTOMERS_COMPANIES'
  }),
  postCompany: duxFactory({
    apiFn: api.postCompany,
    actionPrefix: 'POST_COMPANY',
    successEmit: ['CLOSE_MODAL']
  }),
  uploadConceptDocument: duxFactory({
    apiFn: api.uploadConceptDocument,
    actionPrefix: 'POST_UPLOADCONCEPTDOCUMENT'
  }),
  postPackage: duxFactory({
    apiFn: api.postPackage,
    actionPrefix: 'POST_PACKAGE',
    successEmit: ['CLOSE_MODAL']
  }),
  putPackage: duxFactory({
    apiFn: api.putPackage,
    actionPrefix: 'PUT_PACKAGE',
    successEmit: ['CLOSE_MODAL']
  }),
  getPackageById: duxFactory({
    apiFn: api.getPackageById,
    actionPrefix: 'GET_PACKAGE_BY_ID'
  }),
  putCompanyById: duxFactory({
    apiFn: api.putCompanyById,
    actionPrefix: 'PUT_COMPANY',
    successEmit: ['CLOSE_MODAL']
  }),
  deleteLocationDocuments: duxFactory({
    apiFn: api.deleteLocationDocuments,
    actionPrefix: 'DELETE_LOCATION_DOCUMENTD',
    successEmit: ['CLOSE_MODAL']
  }),
  deleteCompanyById: duxFactory({
    apiFn: api.deleteCompanyById,
    actionPrefix: 'DELETE_COMPANY_BY_ID',
    successEmit: ['CLOSE_MODAL']
  }),
  deleteCompanyDocuments: duxFactory({
    apiFn: api.deleteCompanyDocuments,
    actionPrefix: 'DELETE_COMPANY_DOCUMENTD',
    successEmit: ['CLOSE_MODAL']
  }),
  deletePackageById: duxFactory({
    apiFn: api.deletePackageById,
    actionPrefix: 'DELETE_PACKAGE_BY_ID',
    successEmit: ['CLOSE_MODAL']
  }),
  getCompanyById: duxFactory({
    apiFn: api.getCompanyById,
    actionPrefix: 'GET_COMPANIES_BY_ID'
  }),
  postProductsJson: duxFactory({
    apiFn: api.postProductsJson,
    actionPrefix: 'POST_PRODUCTS_JSON'
  }),
  getProductImportStatus: duxFactory({
    persist: true,
    apiFn: api.getProductImportStatus,
    actionPrefix: 'GET_PRODUCT_IMPORT_STATUS'
  }),
  uploadLocationDocument: duxFactory({
    apiFn: api.uploadLocationDocument,
    actionPrefix: 'POST_UPLOADLOCATIONDOCUMENT'
  }),
  postSaveProducts: duxFactory({
    apiFn: api.postSaveProducts,
    actionPrefix: 'POST_SAVE_PRODUCTS',
    successEmit: ['CLOSE_MODAL']
  }),
  postCompanyUsers: duxFactory({
    apiFn: api.postCompanyUsers,
    actionPrefix: 'POST_COMPANNY_USERS',
    successEmit: ['CLOSE_MODAL']
  }),

  //Users
  searchCustomersUsers: duxFactory({
    apiFn: api.searchCustomersUsers,
    actionPrefix: 'GET_SEARCH_CUSTOMERS_USERS'
  }),
  postUser: duxFactory({
    apiFn: api.postUser,
    actionPrefix: 'POST_USER',
    successEmit: ['CLOSE_MODAL']
  }),
  putUser: duxFactory({
    apiFn: api.putUser,
    actionPrefix: 'PUT_USER',
    successEmit: ['CLOSE_MODAL']
  }),
  getCustomersUserById: duxFactory({
    apiFn: api.getCustomersUserById,
    actionPrefix: 'GET_CUSTOMERS_USER_BY_ID'
  }),
  deleteUserById: duxFactory({
    apiFn: api.deleteUserById,
    actionPrefix: 'DELETE_USER_BY_ID'
  }),
  resetPasswordById: duxFactory({
    apiFn: api.resetPasswordById,
    actionPrefix: 'RESET_PASSWORD_ID'
  }),
  createPasswordById: duxFactory({
    apiFn: api.createPasswordById,
    actionPrefix: 'CREATE_PASSWORD_ID'
  }),

  //Locations
  postLocation: duxFactory({
    apiFn: api.postLocation,
    actionPrefix: 'POST_LOCATION',
    successEmit: ['CLOSE_MODAL']
  }),
  putLocationById: duxFactory({
    apiFn: api.putLocationById,
    actionPrefix: 'PUT_LOCATION',
    successEmit: ['CLOSE_MODAL']
  }),
  putProgressDisplayById: duxFactory({
    apiFn: api.putProgressDisplayById,
    actionPrefix: 'PUT__PROGRESS_DISPLAY'
  }),
  postStatuses: duxFactory({
    apiFn: api.postStatuses,
    actionPrefix: 'POST_STATUSES'
  }),
  postLocationUsers: duxFactory({
    apiFn: api.postLocationUsers,
    actionPrefix: 'POST_LOCATION_USERS',
    successEmit: ['CLOSE_MODAL']
  }),
  deleteLocationById: duxFactory({
    apiFn: api.deleteLocationById,
    actionPrefix: 'DELETE_LOCATION',
    successEmit: ['CLOSE_MODAL']
  }),
  searchCustomersLocations: duxFactory({
    apiFn: api.searchCustomersLocations,
    actionPrefix: 'GET_SEARCH_CUSTOMERS_LOCATIONS'
  }),
  getLocationById: duxFactory({
    apiFn: api.getLocationById,
    actionPrefix: 'GET_CUSTOMERS_LOCATION_BY_ID'
  }),
  searchShipments: duxFactory({
    apiFn: api.searchShipments,
    actionPrefix: 'SEARCH_SHIPMENTS'
  }),
  getPoShipments: duxFactory({
    apiFn: api.getPoShipments,
    actionPrefix: 'GET_PO_SHIPMENT'
  }),
  postShipment: duxFactory({
    apiFn: api.postShipment,
    actionPrefix: 'POST_SHIPMENT'
  }),
  updateShipment: duxFactory({
    apiFn: api.putShipment,
    actionPrefix: 'PUT_SHIPMENT'
  }),
  deleteShipment: duxFactory({
    apiFn: api.deleteShipment,
    actionPrefix: 'DELETE_SHIPMENT'
  }),
  deleteQuote: duxFactory({
    apiFn: api.deleteQuote,
    actionPrefix: 'DELETE_QUOTE'
  }),
  postQuote: duxFactory({
    apiFn: api.postQuote,
    actionPrefix: 'POST_QUOTE'
  }),
  putQuote: duxFactory({
    apiFn: api.putQuote,
    actionPrefix: 'PUT_QUOTE'
  }),
  searchQuotes: duxFactory({
    apiFn: api.searchQuotes,
    actionPrefix: 'GET_SEARCH_QUOTES'
  }),
  getQuoteById: duxFactory({
    apiFn: api.getQuoteById,
    actionPrefix: 'GET_QUOTE_BY_ID',
    successListeners: ['SHARE_QUOTE', 'EDIT_QUOTE_STATUS']
  }),
  postFreightQuote: duxFactory({
    apiFn: api.postFreightQuote,
    actionPrefix: 'POST_FREIGHT_QUOTE'
  }),
  getShipmentLogs: duxFactory({
    apiFn: api.getShipmentLogs,
    actionPrefix: 'GET_SHIPMENT_LOGS'
  }),
  getWebTracking: duxFactory({
    apiFn: api.getWebTracking,
    actionPrefix: 'GET_WEB_TRACKING'
  }),
  uploadFreight: duxFactory({
    apiFn: api.uploadFreight,
    actionPrefix: 'UPLOAD_FREIGHT'
  }),
  postFreightBatch: duxFactory({
    apiFn: api.postFreightBatch,
    actionPrefix: 'POST_FREIGHT_BATCH_QUOTE'
  }),
  cancelProductImport: duxFactory({
    apiFn: api.cancelProductImport,
    actionPrefix: 'POST_CANCEL_PRODUCT_IMPORT'
  }),

  shareQuote: duxFactory({
    apiFn: api.shareQuote,
    actionPrefix: 'SHARE_QUOTE'
  }),

  revokeQuote: duxFactory({
    apiFn: api.revokeQuote,
    actionPrefix: 'REVOKE_QUOTE'
  }),

  acceptQuote: duxFactory({
    apiFn: api.acceptQuote,
    actionPrefix: 'ACCEPT_QUOTE'
  }),

  getReportsLookups: duxFactory({
    apiFn: api.getReportsLookups,
    actionPrefix: 'GET_REPORTS_LOOKUPS'
  }),

  getReports: duxFactory({
    apiFn: api.getReports,
    actionPrefix: 'GET_REPORTS'
  }),

  getAllOrders: duxFactory({
    apiFn: api.getAllOrders,
    actionPrefix: 'GET_ALL_ORDERS'
  }),

  processOrders: duxFactory({
    apiFn: api.processOrders,
    actionPrefix: 'PROCESS_ORDERS'
  }),

  asyncValidateUserFields: duxFactory({
    apiFn: api.asyncValidateUserFields,
    actionPrefix: 'ASYNC_VALIDATE_USER_FIELDS'
  }),

  asyncValidateCompanyFields: duxFactory({
    apiFn: api.asyncValidateCompanyFields,
    actionPrefix: 'ASYNC_VALIDATE_COMPANY_FIELDS'
  }),

  getOrderById: duxFactory({
    apiFn: api.getOrderById,
    actionPrefix: 'GET_ORDER_BY_ID'
  }),

  updateOrderLocation: duxFactory({
    apiFn: api.updateOrderLocation,
    actionPrefix: 'UPDATE_ORDER_LOCATION'
  }),

  createGLOnlyProject: duxFactory({
    apiFn: api.createGLOnlyProject,
    actionPrefix: 'CREATE_GL_ONLY_PROJECT',
    successListeners: [
      'POST_ADDITIONAL_TOTAL',
      'POST_FINALIZE_PROJECT',
      'DEL_ADDITIONAL_TOTAL'
    ]
  }),

  getQuoteTemplates: duxFactory({
    apiFn: api.getQuoteTemplates,
    actionPrefix: 'GET_QUOTE_TEMPLATES'
  }),

  getPoShipmentsLineItems: duxFactory({
    apiFn: api.getPoShipmentsLineItems,
    actionPrefix: 'GET_PO_SHIPMENTS_LINEITEMS'
  }),

  getShipmentsLineItems: duxFactory({
    apiFn: api.getShipmentsLineItems,
    actionPrefix: 'GET_SHIPMENTS_LINEITEMS'
  }),

  updateShipmentsLineItems: duxFactory({
    apiFn: api.updateShipmentsLineItems,
    actionPrefix: 'UPDATE_SHIPMENTS_LINEITEMS'
  }),

  updateShipmentGroup: duxFactory({
    apiFn: api.updateShipmentGroup,
    actionPrefix: 'UPDATE_SHIPMENT_GROUP'
  }),

  removeShipmentAssignments: duxFactory({
    apiFn: api.removeShipmentAssignments,
    actionPrefix: 'REMOVE_SHIPMENT_ASSIGNMENTS'
  }),

  getShipmentsLineItemsLogs: duxFactory({
    apiFn: api.getShipmentsLineItemsLogs,
    actionPrefix: 'GET_SHIPMENTS_LINEITEMS_LOGS'
  }),
  uploadUsers: duxFactory({
    apiFn: api.uploadUsers,
    actionPrefix: 'UPLOAD_USERS'
  }),

  downloadUsersTemplate: duxFactory({
    apiFn: api.downloadUsersTemplate,
    actionPrefix: 'DOWNLOAD_USERS_TEMPLATE'
  }),

  uploadLocations: duxFactory({
    apiFn: api.uploadLocations,
    actionPrefix: 'UPLOAD_LOCATIONS'
  }),

  downloadLocationsTemplate: duxFactory({
    apiFn: api.downloadLocationsTemplate,
    actionPrefix: 'DOWNLOAD_LOCATIONS_TEMPLATE'
  }),

  getAdminWarehouses: duxFactory({
    apiFn: api.getAdminWarehouses,
    actionPrefix: 'GET_ADMIN_WAREHOUSES'
  }),

  postAdminWarehouse: duxFactory({
    apiFn: api.postAdmintWarehouse,
    actionPrefix: 'POST_ADMIN_AREHOUSE'
  }),

  putAdminWarehouse: duxFactory({
    apiFn: api.putAdminWarehouse,
    actionPrefix: 'PUT_ADMIN_WAREHOUSE'
  }),

  delAdminWarehouse: duxFactory({
    apiFn: api.delAdminWarehouse,
    actionPrefix: 'DEL_ADMIN_WAREHOUSE',
    successEmit: ['CLOSE_MODAL']
  }),

  sendContactEmail: duxFactory({
    apiFn: api.sendContactEmail,
    actionPrefix: 'SEND_CONTACT_EMAIL'
  }),

  downloadQuoteById: duxFactory({
    apiFn: api.downloadQuoteById,
    actionPrefix: 'DOWNLOAD_QUOTE_BY_ID'
  }),

  searchCoupons: duxFactory({
    apiFn: api.searchCoupons,
    actionPrefix: 'SEARCH_COUPONS',
    successListeners: [
      'CREATE_COUPON',
      'EDIT_COUPON',
      'DELETE_COUPON'
    ]
  }),

  createCoupon: duxFactory({
    apiFn: api.createCoupon,
    actionPrefix: 'CREATE_COUPON'
  }),

  editCoupon: duxFactory({
    apiFn: api.editCoupon,
    actionPrefix: 'EDIT_COUPON'
  }),

  deleteCoupon: duxFactory({
    apiFn: api.deleteCoupon,
    actionPrefix: 'DELETE_COUPON'
  }),

  sendConfirmationEmail: duxFactory({
    apiFn: api.sendConfirmationEmail,
    actionPrefix: 'SEND_CONFIRMATION_EMAIL'
  }),
  sendAllConfirmationEmails: duxFactory({
    apiFn: api.sendAllConfirmationEmails,
    actionPrefix: 'SEND_ALL_CONFIRMATION_EMAIL'
  }),
  sendNewProjectEmail: duxFactory({
    apiFn: api.sendNewProjectEmail,
    actionPrefix: 'SEND_NEW_PROJECT_EMAIL'
  }),
  getHotlistMissingSchdShipDateShipments: duxFactory({
    apiFn: api.getHotlistMissingSchdShipDateShipments,
    actionPrefix: 'GET_HOTLIST_MISSING_SCHD_SHIPDATE_SHIPMENTS'
  }),

  getHotlistAlteredShipments: duxFactory({
    apiFn: api.getHotlistAlteredShipments,
    actionPrefix: 'GET_HOTLIST_ALTERED_SHIPMENTS'
  }),

  acknowledgeShipmentChange: duxFactory({
    apiFn: api.acknowledgeShipmentChange,
    actionPrefix: 'ACKNOWLEDGE_SHIPMENT_CHANGE'
  }),

  editQuoteStatus: duxFactory({
    apiFn: api.editQuoteStatus,
    actionPrefix: 'EDIT_QUOTE_STATUS'
  }),

  addMiscShipmentsLineItemsGroup: duxFactory({
    apiFn: api.addMiscShipmentsLineItemsGroup,
    actionPrefix: 'ADD_SHIPMENTS_LINEITEMS_GROUP'
  }),

  deleteMiscShipmentsLineItemsGroup: duxFactory({
    apiFn: api.deleteMiscShipmentsLineItemsGroup,
    actionPrefix: 'DELETE_MISC_SHIPMENT_LINEITEMS_GROUP'
  }),

  updateMultipleShipments: duxFactory({
    apiFn: api.updateMultipleShipments,
    actionPrefix: 'UPDATE_MULTIPLE_SHIPMENTS'
  }),

  getTrackingMoreTracking: duxFactory({
    apiFn: api.getTrackingMoreTracking,
    actionPrefix: 'GET_TRACKINGMORE_TRACKING'
  }),

  getCarrierByName: duxFactory({
    apiFn: api.getCarrierByName,
    actionPrefix: 'GET_CARRIER_BY_NAME'
  }),

  getAllCarriers: duxFactory({
    apiFn: api.getAllCarriers,
    actionPrefix: 'GET_ALL_CARRIERS'
  }),

  pingEditMultipleShipmentsStatus: duxFactory({
    apiFn: api.pingEditMultipleShipmentsStatus,
    actionPrefix: 'PING_EDIT_MULTIPLE_SHIPMENTS_STATUS'
  }),

  validateTrackingNumber: duxFactory({
    apiFn: api.validateTrackingNumber,
    actionPrefix: 'VALIDATE_TRACKING_NUMBER'
  })

};
