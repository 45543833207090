import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { Alert, Container } from 'react-bootstrap';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import ShipmentsResult from './ShipmentsResult';
import ShipmentSearchForm from './ShipmentsSearchForm';
import ShipmentEditResultsForm from './ShipmentEditResultsForm';
import { actionCreators } from '../../../../state/modals-dux';
import { actionCreators as shipmentSearchActions } from '../../../../state/shipments-dux';
import ModalWebTrack from '../shipments-detail/ModalWebTrack';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';

const CARRIERS = [
  {
    value: 'aaacooper',
    label: 'AAA Cooper Transportation'
  },
  {
    value: 'A DUIE PYLE',
    label: 'A Duie Pyle' //Not found
  },
  {
    value: 'averittexpress',
    label: 'AVERITT Express'
  },
  {
    value: 'abf',
    label: 'ABF Freight'
  },
  {
    value: 'estes',
    label: 'Estes'
  },
  {
    value: 'fedex',
    label: 'Fedex'
  },
  {
    value: 'old-dominion',
    label: 'Old Dominion Freight Line'
  },
  {
    value: 'rl-carriers',
    label: 'RL Carriers'
  },
  {
    value: 'saia-freight',
    label: 'Saia LTL Freight'
  },
  {
    value: 'se-freightlines',
    label: 'Southeastern Freightlines'
  },
  {
    value: 'ups',
    label: 'UPS'
  },
  {
    value: 'USF',
    label: 'USF Holland' //Not found
  },
  {
    value: 'usps',
    label: 'USPS'
  },
  {
    value: 'xpoweb',
    label: 'XPO Logistics'
  },
  {
    value: 'YRC',
    label: 'YRC' //Not found
  }
];

const formatValue = (value, label, isArray) => {
  const intValues = ['deliveryStatus'];
  if (isArray && intValues.includes(label)) {
    return Array.isArray(value) ?
      (value || []).map(v => parseInt(v)) :
      [parseInt(value)]
  } else if (intValues.includes(label)) {
    return parseInt(value);
  }
  return value;
};

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    ...rest
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '50',
  sortBy: sortBy || 'poNum',
  desc: getDesc(desc),
  ...rest
});

class ShipmentsSearch extends Component {
  constructor(props) {
    super(props);
    const activeColumns = (window.localStorage.getItem('shipmentColumns') || '')
      .split(',')
      .filter(v => (v || '').trim().length > 0);
    this.state = { editMode: false, activeColumns, hasLoadedData: false };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFetchData = this.handleFetchData.bind(this);
  }

  componentDidMount() {
    this.props.getAllCarriers();
  }

  parseQuery(search) {
    const params = queryString.parse(search);
    const multiSelect = ['salesRep', 'sortBy', 'deliveryStatus'];
    let obj = {}
    Object.keys(params).forEach(
      (key) => {
        const value = formatValue(params[key], key, multiSelect.includes(key));
        if (multiSelect.includes(key)) {
          obj = { ...obj, [key]: Array.isArray(value) ? value : [value] };
        } else {
          obj = { ...obj, [key]: value };
        }
      });
    return obj;
  }

  callSearch(search) {
    const { submitSearch, clearSearch } = this.props;

    if (!search) {
      clearSearch();
      return;
    }

    this.setState({ hasLoadedData: false });
    this.props.saveShipmentsSearch(queryString.stringify(this.parseQuery(search)));
    submitSearch(this.parseQuery(search), null, null, () => {
      this.setState({ hasLoadedData: true })
    });
  }

  componentWillMount() {
    const { getLookups, location: { search } } = this.props;
    getLookups();
    this.callSearch(search);
  }

  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    if (this.props.location.search !== search) {
      this.callSearch(search);
    }
  }

  componentWillUnmount() {
    this.props.clearTrackingMoreTracking();
  }

  handleSubmit(values) {
    const { carrierName, ...rest } = values;
    const { history, location: { pathname } } = this.props;
    const params = { ...rest, t: new Date().getTime(), page: '0', carrierName: typeof carrierName === 'object' ? (carrierName || {}).value : carrierName };
    const search = queryString.stringify(formatQuery(params));
    history.push({ pathname, search });
  }

  handleFetchData(state) {
    const {
      location: { search },
      history,
      location: { pathname }
    } = this.props;
    const {
      page,
      pageSize,
      sorted
    } = state || {};
    if (!search) return;
    const [sort] = sorted || [];
    const {
      id: sortBy,
      desc
    } = sort || {};

    const params = {
      ...queryString.parse(search),
      page,
      pageSize,
      sortBy,
      desc
    };
    const query = formatQuery(params);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  }

  getInitialValues() {
    const { location: { search }, totalCarriers } = this.props;
    const last = this.parseQuery(search);
    const {
      carrierName,
      deliveryStatus
    } = last;
    let c = CARRIERS.find(({ value }) => value === carrierName);
    if (!c) {
      c = (totalCarriers || []).find(({ courier_code }) => courier_code === carrierName);
    }
    const carrierValues = carrierName
      ? {
        carrierName: {
          value: carrierName,
          label: c ? c.label || c.courier_name : carrierName
        }
      }
      : {};
    return {
      searchType: 'all',
      sortBy: ['poNum'],
      ...(last || {}),
      ...carrierValues,
      deliveryStatus: (deliveryStatus || []).map((v) => v < 0 ? v.toString() : v)
    };
  }

  onResultColumnChange(activeColumns) {
    // this.props.setActiveColumns(activeColumns);
    window.localStorage.setItem('shipmentColumns', activeColumns);
  }

  handleShowCarrierPage = value => {
    window.open(
      value.CarrierLink,
      '_blank',
      'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
    );
  };

  /*handleWebTrack = shipment => {
    const {
      getWebTracking
    } = this.props;
    const { Carrier, TrackingNum } = shipment;
    getWebTracking({ Carrier, TrackingNum }, null, null, data => {
      const {
        updateShipment,
        updateShipmentGroup,
        location: { search }
      } = this.props;

      const {
        Carrier,
        CarrierDeliveryDate,
        CarrierDeliveryStatus,
        SignedForBy,
        CarrierShipWeight
      } = data;
      const { ID, PoID, ShipmentNum } = shipment;
      const payload = {
        Carrier,
        CarrierDeliveryDate,
        CarrierDeliveryStatus,
        SignedForBy,
        CarrierShipWeight,
        ShipWeight: CarrierShipWeight,
        PurchaseOrderId: PoID,
        ShipmentNum
      };
      const newSearch = queryString.stringify({
        ...queryString.parse(search),
        t: new Date().getTime()
      });
      if (ID !== 'new' && parseInt(ID) > 0) {
        updateShipment({ id: ID, payload }, null, null, () => {
          this.callSearch(newSearch);
        });
      } else {
        const { groupName, SchdShipDate, TrackingNum, PoNum } = shipment;
        const formattedShipDate = moment.utc(SchdShipDate).format('MM-DD-YYYY');
        const misc = groupName.startsWith('misc');
        const type = groupName;
        const data = groupName === 'tracking'
          ? TrackingNum
          : groupName === 'scheduled' ? formattedShipDate : 'na';

        delete payload.ShipmentNum;
        updateShipmentGroup(
          {
            ...payload,
            poNum: PoNum,
            PurchaseOrderId: PoID,
            type,
            data,
            misc,
            miscId: ID
          },
          null,
          null,
          () => {
            this.callSearch(newSearch);
          }
        );
      }
    });
  };*/

  handleWebTrack = (shipment, callBack) => {
    const {
      getTrackingMoreTracking,
      location: { search }
    } = this.props;
    const {
      Carrier,
      TrackingNum,
      ID,
      groupName,
      DeliveryStatus,
      PoNum,
      ManualTracking
    } = shipment;
    const newSearch = queryString.stringify({
      ...queryString.parse(search),
      t: new Date().getTime()
    });
    getTrackingMoreTracking(
      {
        Carrier,
        TrackingNum,
        ID,
        groupName,
        deliveryStatusValue: parseInt(DeliveryStatus) || 0,
        poNumber: PoNum
      }, null, null, (result) => {
        this.callSearch(newSearch);
        if (ManualTracking) {
          const { CarrierLink } = result;
          if ((CarrierLink || '').trim().length > 0) {
            window.open(
              CarrierLink,
              '_blank',
              'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
            );
          }
        } else {
          callBack();
        }

      });
  };

  handleShowCarrierPage = value => {
    if ((value.CarrierLink || '').trim().length > 0) {
      window.open(
        value.CarrierLink,
        '_blank',
        'width=600,height=600,top=100,left=200, resizable=yes,scrollbars=yes,titlebar=yes,location=yes,status=yes'
      );
    }
  };

  render() {
    const {
      result,
      inProgress,
      error,
      salesReps,
      location: { search },
      webTrack,
      updateInProgress,
      updateMultipleShipments,
      totalCarriers,
      showModal,
      clearPingEditMultipleShipmentsStatus,
      updateMultipleShipmentsStatus,
      shipmentsInProgress
    } = this.props;

    const { editMode } = this.state;
    const salesRepsOpts = (salesReps || [])
      .map(s => ({ value: `${s.FirstName}:${s.LastName}:${s.Code}`, label: `${s.FirstName} ${s.LastName}` }));
    const paging = formatQuery(queryString.parse(search));
    const toggleEditMode = () => this.setState({ editMode: !editMode }, () => {
      if (this.state.editMode) {
        clearPingEditMultipleShipmentsStatus();
      }
    });
    const { results } = result || {};

    const getInitialValues = () => {
      return {
        shipments: (results || []).map(shipment => {
          const {
            c_ImportedDate,
            street,
            City,
            State,
            c_ShipDate,
            SchdShipDate,
            ActualShipDate,
            Carrier,
            TrackingNum,
            CarrierDeliveryDate,
            DeliveryStatus,
            ...rest
          } = shipment;

          let c = CARRIERS.find(({ value }) => value === Carrier);
          if (!c) {
            c = (totalCarriers || []).find(({ courier_code }) => courier_code === Carrier);
          }

          const carrierValues = Carrier
            ? {
              Carrier: {
                value: Carrier,
                label: c ? c.label || c.courier_name : Carrier
              }
            }
            : {};
          return {
            c_ImportedDate: moment(c_ImportedDate).isValid()
              ? moment(c_ImportedDate).utc().format('MM/DD/YYYY')
              : c_ImportedDate,
            c_ShipDate: moment(c_ShipDate).isValid()
              ? moment(c_ShipDate).utc().format('MM/DD/YYYY')
              : c_ShipDate,
            Destination: `${street} ${City} ${State}`,
            SchdShipDate: moment(SchdShipDate).isValid()
              ? moment(SchdShipDate).utc().format('MM/DD/YYYY')
              : SchdShipDate,
            ActualShipDate: moment(ActualShipDate).isValid()
              ? moment(ActualShipDate).utc().format('MM/DD/YYYY')
              : ActualShipDate,
            CarrierDeliveryDate: moment(CarrierDeliveryDate).isValid()
              ? moment(CarrierDeliveryDate).utc().format('MM/DD/YYYY')
              : CarrierDeliveryDate,
            TrackingNum,
            originalTrackinngNumber: TrackingNum,
            originalScheduledDate: SchdShipDate,
            originalCarrier: carrierValues.Carrier ? carrierValues.Carrier.value : '',
            DeliveryStatus:
              isNaN(parseInt(DeliveryStatus)) ? "-1" :
                parseInt(DeliveryStatus) === -1 ? "-1" :
                  parseInt(DeliveryStatus) === 0 ? "0" :
                    parseInt(DeliveryStatus),
            ...carrierValues,
            ...rest
          };
        })
      };
    };
    const { errors } = updateMultipleShipmentsStatus || {};
    return (
      <Layout route="shipments-search" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Shipments
              </h2>
            </div>
          </Container>
        </div>
        <Container>
          <Box>
            <ShipmentSearchForm
              salesReps={salesRepsOpts}
              initialValues={this.getInitialValues()}
              onSubmit={this.handleSubmit}
              toggleEditMode={toggleEditMode}
              editMode={editMode}
              hasResults={(results || []).length > 0 && totalCarriers}
            />
          </Box>
          {error && <Alert variant="danger">{error.message}</Alert>}
          {
            errors &&
            !this.state.editMode &&
            <>
              {(errors || []).map((e, index) => {
                return (
                  <div key={index}>
                    <Alert variant="danger">{(e || {}).message}</Alert>
                  </div>
                )
              })}
            </>
          }
        </Container>
        <Container fluid>
          <Box className="box-table">
            {!editMode &&
              <ShipmentsResult
                result={result || {}}
                paging={paging}
                activeColumns={this.state.activeColumns || []}
                onColumnChange={this.onResultColumnChange.bind(this)}
                inProgress={inProgress}
                error={error}
                onFetchData={this.handleFetchData}
                handleWebTrack={this.handleWebTrack}
                updateInProgress={updateInProgress}
                totalCarriers={totalCarriers}
                showModal={showModal}
                CARRIERS={CARRIERS}
                shipmentsInProgress={shipmentsInProgress}
                hasLoadedData={this.state.hasLoadedData}
              />}
            {editMode &&
              <ShipmentEditResultsForm
                initialValues={getInitialValues()}
                onSubmit={values => {
                  const {
                    shipments
                  } = values;
                  const payloadShipments = (shipments || []).map((shipment) => {
                    const { Carrier, DeliveryStatus, OverrideDeliveryStatus, ...rest } = shipment;
                    return {
                      Carrier: Carrier && Carrier.value ? Carrier.value : '',
                      DeliveryStatus: parseInt(DeliveryStatus),
                      OverrideDeliveryStatus: parseInt(OverrideDeliveryStatus),
                      ...rest
                    }
                  })
                  values = { shipments: payloadShipments }
                  updateMultipleShipments(
                    values,
                    'shipment-edit-results-form',
                    null,
                    () => {

                    }
                  );
                }}
                onSave={values => {
                  const {
                    shipments
                  } = values;
                  const payloadShipments = (shipments || []).map((shipment) => {
                    const { Carrier, ...rest } = shipment;
                    return {
                      Carrier: Carrier && Carrier.value ? Carrier.value : '',
                      ...rest
                    }
                  })
                  values = { shipments: payloadShipments }
                  updateMultipleShipments(values);
                }}
                onCancelClick={() => {
                  toggleEditMode();
                  const newSearch = queryString.stringify({
                    ...queryString.parse(search),
                    t: new Date().getTime()
                  });
                  this.callSearch(newSearch);
                }}
                CARRIERS={CARRIERS}
                toggleEditMode={toggleEditMode}
                shipments={(results || []).map((shipment) => ({ ...shipment, carriers: cloneDeep(CARRIERS) }))}
                updateInProgress={updateInProgress}
                totalCarriers={totalCarriers}
                updateFinishHandler={
                  () => {
                    this.setState({ editMode: false });
                    const newSearch = queryString.stringify({
                      ...queryString.parse(search),
                      t: new Date().getTime()
                    });
                    this.callSearch(newSearch);
                  }
                }
              />}
          </Box>
          <ModalWebTrack
            {...webTrack}
            name="WebTrackModal"
            handleShowCarrierPage={this.handleShowCarrierPage}
          />
        </Container>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: lookups,
    inProgress: lookupsInProgress,
    error: lookupsError
  } = state.getLookups.toJS();

  const {
    result: totalCarriers
  } = state.getAllCarriers.toJS();
  const webTrack = state.getTrackingMoreTracking.toJS();
  const shipments = state.searchShipments.toJS();
  const updateShipment = state.updateShipment.toJS();
  const updateShipmentGroup = state.updateShipmentGroup.toJS();
  const updateMultipleShipments = state.updateMultipleShipments.toJS();
  const {
    result: updateMultipleShipmentsStatus
  } = state.pingEditMultipleShipmentsStatus.toJS();
  const {
    salesReps
  } = lookups || {};
  return {
    salesReps,
    inProgress: lookupsInProgress || shipments.inProgress,
    result: shipments.result,
    error: lookupsError || shipments.error || webTrack.error,
    webTrack,
    updateInProgress: updateShipment.inProgress ||
      updateShipmentGroup.inProgress ||
      webTrack.inProgress ||
      updateMultipleShipments.inProgress,
    totalCarriers,
    updateMultipleShipmentsStatus,
    shipmentsInProgress: shipments.inProgress
  };

};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      ...shipmentSearchActions,
      getLookups: fetchDux.getLookups.createAction,
      submitSearch: fetchDux.searchShipments.createAction,
      clearSearch: fetchDux.searchShipments.clearAction,
      getWebTracking: fetchDux.getWebTracking.createAction,
      updateShipment: fetchDux.updateShipment.createAction,
      updateShipmentGroup: fetchDux.updateShipmentGroup.createAction,
      updateMultipleShipments: fetchDux.updateMultipleShipments.createAction,
      getTrackingMoreTracking: fetchDux.getTrackingMoreTracking.createAction,
      getAllCarriers: fetchDux.getAllCarriers.createAction,
      clearTrackingMoreTracking: fetchDux.getTrackingMoreTracking.clearAction,
      clearPingEditMultipleShipmentsStatus: fetchDux.pingEditMultipleShipmentsStatus.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ShipmentsSearch);
