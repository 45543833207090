import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown } from 'react-bootstrap';
import moment from 'moment';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { actionCreators } from '../../../../state/modals-dux';
import Table from '../../../ui/Table';
import Loading from '../../../ui/Loading';

import ModalCreateUser from '../customers-users/ModalCreateUser';
import ModalCustomersAddUser from '../customers-users/ModalAddUser';
import ModalCustomersImportUser from '../customers-users/ModalImportUser';

import ModalCustomersEditCompany from '../customers-companies/ModalEditCompany';
import ModalCustomersDeleteCompany
  from '../customers-companies/ModalDeleteCompany';
import Notification from '../../../ui/Notification';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '20',
  sortBy: sortBy || 'nameFirst',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

const columns = [
  {
    Header: 'First Name',
    accessor: 'nameFirst',
    width: 225,
    Cell: ({ value, original }) => (
      <Link to={`/customers/users/${original.id}`}>{value}</Link>
    )
  },
  {
    Header: 'Last Name',
    accessor: 'nameLast',
    width: 225
  },
  {
    Header: 'Email Address',
    accessor: 'email',
    width: 225
  },
  {
    Header: 'Concepts',
    accessor: 'companies',
    Cell: ({ value, original }) => {
      return (value || []).map((company, index) => {
        return (
          <span key={index} className="table-label">
            {company}
          </span>
        );
      });
    }
  }
];

class CustomersCompaniesUsers extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const {
      match,
      getCompanyById,
      getCompanyLookups,
      searchCustomersUsers,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getCompanyById({ id, query: { getUsers: 1 } }, null, true);
    getCompanyLookups({ companyTypes: 1 });
    searchCustomersUsers();
    searchCustomersCompanies();
  }
  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      getCompanyByIdError,
      result,
      showModal,
      match,
      getConceptsLookupsResult,
      putCompanyById,
      getCompanyById,
      deleteCompanyById,
      history,
      location: { search: searchURL },
      searchCustomersUsersResult,
      searchCustomersCompaniesResult,
      deleteCompanyByIdError,
      clearDeleteCompanyById,
      putCompanyByIdError,
      clearPutCompanyById,
      getConceptsLookupsError,
      clearGetCompanyLookups,
      clearGetCompanyById
    } = this.props;

    const { companyUsers, company } = result || {};

    const {
      name,
      companyTypeID,
      notes,
      created,
      id,
      jobRefNum,
      supportEmail,
      freightMarkup
    } = company || {};

    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const companyYears = moment().diff(moment(created), 'years');

    const initialValues = {
      name,
      companyTypeID,
      notes,
      jobRefNum,
      supportEmail,
      freightMarkup,
      id
    };

    const {
      companyTypes
    } = getConceptsLookupsResult || {};

    const handleSubmitUpdateCompany = values => {
      putCompanyById(
        { id, payload: values },
        'create-customers-company-form',
        null,
        () => {
          getCompanyById({ id, query: { getUsers: 1 } }, null, true);
        }
      );
    };

    const handleDeleteCompany = () => {
      deleteCompanyById(id, null, null, () => {
        history.push(`/customers/concepts`);
      });
    };

    const { id: companyId } = match.params || {};
    return (
      <Layout route="customers-companies-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/concepts" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {name || 'Loading...'}
              </h2>
              <h3>
                {!inProgress &&
                  <span>
                    Concept for
                    {' '}
                    {companyYears === undefined ? '-' : companyYears}
                    {' '}
                    years
                  </span>}
                &nbsp;
              </h3>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" size="sm">
                <Icon fixedWidth name="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersCompanyEdit')}
                >
                  Edit Concept
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersCompanyDelete')}
                >
                  Delete Concept
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/concepts/${companyId}/details`}>
                  Details
                </Link>
              </li>
              <li className="active">
                <Link to={`/customers/concepts/${companyId}/users`}>Users</Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/products`}>
                  Products
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/packages`}>
                  Packages
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/locations`}>
                  Locations
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>
        {!inProgress &&
          <Container>
            <div>
              {(companyUsers || []).length < 1 &&
                <div className="box-empty-customers">
                  <Icon name="user-circle" className="icon" />
                  <p>This concept has no users yet</p>
                  {/* <Button
                    variant="primary"
                    size="sm"
                    onClick={() => showModal('modalCustomersCompanyImportUser')}
                    disabled
                  >
                    Import CSV
                  </Button> */}
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => showModal('modalCustomersCompanyAddUser')}
                  >
                    Add From Users
                  </Button>
                  <Button
                    variant="primary"
                    size="sm"
                    onClick={() => showModal('modalCreateCustomersUser')}
                  >
                    Create User
                  </Button>
                </div>}
              {(companyUsers || []).length > 0 &&
                <div>
                  <div className="customers-actions">
                    {/* <Button
                      variant="primary"
                      size="sm"
                      onClick={() =>
                        showModal('modalCustomersCompanyImportUser')}
                      disabled
                    >
                      Import CSV
                    </Button> */}
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => showModal('modalCustomersCompanyAddUser')}
                    >
                      Manage Users
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => showModal('modalCreateCustomersUser')}
                    >
                      Create User
                    </Button>
                  </div>
                  <Table
                    showSearch
                    data={companyUsers}
                    noDataText="No users found"
                    columns={columns}
                    search={search}
                    onFetchData={this.handleTableChange}
                    onSearchChange={this.handleSearchChange}
                    defaultSorted={sorted}
                    page={getCompanyByIdError ? 0 : Number(page)}
                    defaultPageSize={Number(pageSize)}
                    inProgress={inProgress}
                  />
                </div>}
            </div>
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCreateUser
          name="modalCreateCustomersUser"
          companies={searchCustomersCompaniesResult}
          isCompany
          companyId={parseInt(companyId)}
        />
        {companyUsers &&
          <ModalCustomersAddUser
            name="modalCustomersCompanyAddUser"
            currentUsers={companyUsers}
            companyUsers={searchCustomersUsersResult}
            entity={company}
            isCompany
          />}
        <ModalCustomersImportUser name="modalCustomersCompanyImportUser" />
        <ModalCustomersEditCompany
          name="modalCustomersCompanyEdit"
          initialValues={initialValues}
          companyTypes={companyTypes}
          handleSubmitUpdateCompany={handleSubmitUpdateCompany}
          putCompanyByIdError={putCompanyByIdError}
          clearPutCompanyById={clearPutCompanyById}
        />
        <ModalCustomersDeleteCompany
          name="modalCustomersCompanyDelete"
          handleDeleteCompany={handleDeleteCompany}
          deleteCompanyByIdError={deleteCompanyByIdError}
          clearDeleteCompanyById={clearDeleteCompanyById}
        />
        {getCompanyByIdError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyById}
          >
            Users failed to load. Please refresh the page.
          </Notification>}
        {getConceptsLookupsError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyLookups}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getCompanyByIdInProgress,
    error: getCompanyByIdError
  } = state.getCompanyById.toJS();

  const {
    result: getConceptsLookupsResult,
    inProgress: getConceptsLookupsInProgress,
    error: getConceptsLookupsError
  } = state.getConceptsLookups.toJS();

  const {
    result: searchCustomersUsersResult,
    inProgress: searchCustomersUsersInProgress,
    error: searchCustomersUsersError
  } = state.searchCustomersUsers.toJS();

  const {
    result: searchCustomersCompaniesResult,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    error: deleteCompanyByIdError
  } = state.deleteCompanyById.toJS();

  const {
    error: putCompanyByIdError
  } = state.putCompanyById.toJS();

  return {
    result,
    getConceptsLookupsResult,
    searchCustomersUsersResult,
    searchCustomersCompaniesResult,
    inProgress: getCompanyByIdInProgress,
    getCompanyByIdError,
    deleteCompanyByIdError,
    putCompanyByIdError,
    getConceptsLookupsError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction,
      getCompanyLookups: fetchDux.getConceptsLookups.createAction,
      clearGetCompanyLookups: fetchDux.getConceptsLookups.clearAction,
      putCompanyById: fetchDux.putCompanyById.createAction,
      clearPutCompanyById: fetchDux.putCompanyById.clearAction,
      deleteCompanyById: fetchDux.deleteCompanyById.createAction,
      clearDeleteCompanyById: fetchDux.deleteCompanyById.clearAction,
      searchCustomersUsers: fetchDux.searchCustomersUsers.createAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersCompaniesUsers);
