import React, { Component } from 'react';
import Layout from '../../../ui/Layout';
import Loading from '../../../ui/Loading';
import FinalizeOrder from '../projects-detail/FinalizeOrder';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import statusDict from '../statusDict';

class ProjectDetailGL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mountComplete: false
    };
  }

  componentDidMount = () => {
    if (this.state.mountComplete) return;
    return; //remove for prod
    this.setState({ mountComplete: true }, () => {
      const { createGLOnlyProject } = this.props;
      createGLOnlyProject();
    });
  };
  render() {
    const {
      createGLOnlyProjectInProgress,
      result,
      error
    } = this.props;
    const { project } = result || {};
    const {
      c_SageIntegrationStatus: status
    } = project || {};
    return (
      <Layout route="projects-detail-gl">
        {createGLOnlyProjectInProgress
          ? <Loading
              fullPage
              color="#04844B"
              message="Loading, Please Wait..."
            />
          : status === statusDict.TAX_INPROGRESS
              ? <FinalizeOrder result={result} isGLOnly={true} ping />
              : <FinalizeOrder result={result} isGLOnly={true} />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: createGLOnlyProjectInProgress,
    error
  } = state.createGLOnlyProject.toJS();

  return {
    result,
    createGLOnlyProjectInProgress,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectById: fetchDux.getProjectById.createAction,
      createGLOnlyProject: fetchDux.createGLOnlyProject.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectDetailGL);
