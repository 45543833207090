import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Form, Table } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const validZip = (value, v, props) => {
  if (value !== 'jobsite') return undefined;
  const { projectAddress } = props || {};
  const { Zip: zipValue } = projectAddress || {};
  return !zipValue ? 'Invalid Jobsite Address Zip Code' : undefined;
};

const SelectedAddress = (
  {
    Name,
    // Phone,
    Fax,
    Address1,
    Address2,
    City,
    State,
    Zip
  }
) => (
  <div className="address-card">
    <div className="location">
      <Icon name="id-card" />
      {Name}
    </div>
    {/* <label>Phone</label> */}
    {/* <p>{Phone}</p> */}
    <label>Address</label>
    <p>
      {Address1}
      {Address2 ? ` ${Address2}` : ''}
      ,
      {' '}
      {City}
      ,
      {' '}
      {State}
      ,
      {' '}
      {Zip}
    </p>
  </div>
);

const AddressFields = ({ isGLOnly }) => (
  <Row>
    <Col sm={6}>
      <Field
        required
        name="Name"
        type="text"
        component={FormField.Input}
        label="Company Name"
        validate={[required]}
      />
      {/* <Field
        required
        name="PhoneContact"
        type="text"
        component={FormField.Input}
        label="Phone"
        validate={[required]}
      /> */}
      <Field
        required
        name="Address1"
        type="text"
        component={FormField.Input}
        label="Address 1"
        validate={[required]}
      />
      <Field
        name="Address2"
        type="text"
        component={FormField.Input}
        label="Address 2"
      />
      {
        isGLOnly &&
        <>
          <Field
            required
            name="jobRef"
            type="text"
            component={FormField.Input}
            label="Job Ref"
            validate={[required]}
          />
          <Field
            required
            name="customerNumber"
            type="text"
            component={FormField.Input}
            label="Customer Number"
            validate={[required]}
          />

          <Field
            required
            name="ProjectName"
            type="text"
            component={FormField.Input}
            label="Project Name"
            validate={[required]}
          />
        </>
      }

    </Col>
    <Col sm={6}>
      <Field
        required
        name="City"
        type="text"
        component={FormField.Input}
        label="City"
        validate={[required]}
      />
      <Field
        required
        name="State"
        type="text"
        component={FormField.Input}
        label="State"
        validate={[required]}
      />
      <Field
        required
        name="Zip"
        type="text"
        component={FormField.Input}
        label="Zip"
        validate={[required]}
      />
      <Field
        name="c_Zip_4"
        type="text"
        component={FormField.Input}
        label="Zip 4"
      />
      {/* {
        isGLOnly &&
        <Field
          required
          name="CustomerId"
          type="text"
          component={FormField.Input}
          label="Customer ID"
          validate={[required]}
        />
      } */}
    </Col>
  </Row>
);

const FinalizeOrderForm = props => {
  const {
    handleSubmit,
    submitting,
    addressTypeValue,
    change,
    projectAddress,
    jobsiteLabel,
    isGLOnly
  } = props;

  const clearAddress = () => {
    change('Name', '');
    change('PhoneContact', '');
    change('Address1', '');
    change('Address2', '');
    change('City', '');
    change('State', '');
    change('Zip', '');
    change('c_Zip_4', '');
    change('c_WarehouseID', '');
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Col sm={12} style={{ padding: '20px 0' }}>
        <Field
          name="TaxGroup"
          component={FormField.RadioGroup}
          label={
            <h3 style={{ display: 'inline', marginRight: '30px' }}>
              Confirm Tax Location
            </h3>
          }
          options={[
            { value: 'AVATAX', label: 'Avatax (US)' },
            { value: 'NONUS', label: 'Non-US' }
          ]}
          disabled={submitting}
          validate={[required]}
          inputContainerStyle={{ display: 'inline', marginLeft: '20px' }}
        />
      </Col>
      <Col sm={12} style={{ padding: '20px 0' }}>
        <h3>
          Confirm or enter the actual address of the project (so taxes will be accurate)
        </h3>
      </Col>
      <Row>
        {!isGLOnly &&
          <Col sm={4}>
            <Field
              name="addressType"
              component={FormField.RadioGroup}
              options={[
                {
                  value: 'jobsite',
                  label: jobsiteLabel || 'Use Jobsite Address'
                },
                { value: 'other', label: 'Enter Different Address' }
              ]}
              validate={[required, validZip]}
              disabled={submitting}
            />
          </Col>
        }

        {addressTypeValue === 'other' &&
          <Col sm={8}>
            <AddressFields isGLOnly={isGLOnly} />
          </Col>}
        {addressTypeValue === 'jobsite' &&
          <Col sm={4}>
            <SelectedAddress {...projectAddress || {}} />
          </Col>}
      </Row>
    </Form>
  );
};

const formName = 'finalize-order-form';

const form = reduxForm({
  form: formName
})(FinalizeOrderForm);

const selector = formValueSelector(formName);

const mapState = state => {
  const addressTypeValue = selector(state, 'addressType');

  return {
    addressTypeValue
  };
};

export default connect(mapState)(form);
