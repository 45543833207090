import React, { Component } from 'react';
import Layout from '../../../ui/Layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import Loading from '../../../ui/Loading';
import ProjectInfo from './../projects-detail/ProjectInfo';
import ProjectImportForm from './../projects-new/ProjectImportForm';
import ModalProjectImportWarnDate
  from './../projects-new/ModalProjectImportWarnDate';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';
import { Table, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ProjectBreadcrumbs from './ProjectBreadcrumbs';

const getShouldWarnDate = ({ lastModifiedDate }) => {
  if (!lastModifiedDate || !moment(lastModifiedDate).isValid()) return false;
  return moment().subtract(1, 'day').isAfter(moment(lastModifiedDate));
};

const formatDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY hh:mm a');
};

const RelatedOptions = (
  {
    projectId,
    relatedProjects
  }
) => {
  if (!relatedProjects || !relatedProjects.length) return false;
  return (
    <Table>
      {/* <thead>
        <tr>
          <th>Date Imported</th>
          <th></th>
        </tr>
      </thead> */}
      <tbody>
        {(relatedProjects || []).map(({ ID, c_ImportedDate }) => (
          <tr>
            <td>
              {formatDate(c_ImportedDate)}
            </td>
            <td>
              <Link to={`/projects/${projectId}/import/${ID}`}>
                Continue
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

class ProjectsUpdateImport extends Component {
  componentDidMount() {
    this.props.clearPostReimportProject();
    const {
      match,
      getProjectById,
      getRelatedProjects
    } = this.props;
    const { id } = match.params || {};
    getProjectById(id);
    getRelatedProjects(id);
  }
  render() {
    const {
      history,
      postReimportProject,
      postProjectError,
      showModal,
      closeModal,
      aqjson,
      result,
      inProgress,
      relatedProjects
    } = this.props;
    const { project } = result || {};
    const {
      c_SageIntegrationStatus: status,
      ID: relatedProjectId
    } = project || {};

    const handleSubmit = values => {
      const shouldWarnDate = getShouldWarnDate(aqjson);
      if (shouldWarnDate) {
        showModal('ProjectImportWarnDateModal');
        return;
      }
      postReimportProject(
        { payload: values, projectId: relatedProjectId },
        'project-import-form',
        null,
        result => {
          const { project } = result || {};
          const { ID } = project || {};
          if (!ID) return;
          history.push(`/projects/${relatedProjectId}/import/${ID}`);
        }
      );
    };

    const handleConfirmWarnDate = () => {
      closeModal();
      postReimportProject(
        { payload: { aqjson }, projectId: relatedProjectId },
        'project-import-form',
        null,
        result => {
          const { project } = result || {};
          const { ID } = project || {};
          if (!ID) return;
          history.push(`/projects/${relatedProjectId}/import/${ID}`);
        }
      );
    };

    return (
      <Layout route="projects-update-import">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs project={project} current="Update Equipment" />
            <ProjectInfo
              status={status}
              result={result}
              projectId={relatedProjectId}
              backButton
            />
            <ModalProjectImportWarnDate
              name="ProjectImportWarnDateModal"
              onSubmit={handleConfirmWarnDate}
            />
            <Row>
              <Col sm="8">
                <h1>Update project JSON</h1>
                {/* <h2>
                  Make sure you pick the most recent JSON file exported from AQ
                </h2> */}
                <ProjectImportForm
                  onSubmit={handleSubmit}
                  postProjectError={postProjectError}
                />
              </Col>
              <Col sm="4">
                <h1>In Progress</h1>
                <RelatedOptions
                  projectId={relatedProjectId}
                  relatedProjects={relatedProjects}
                />
              </Col>
            </Row>

          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

      </Layout>
    );
  }
}

const selector = formValueSelector('project-import-form');

const mapState = state => {
  const aqjson = selector(state, 'aqjson');
  // const lastModifiedDate = getLastModified(file || {});
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();
  const {
    result: relatedProjects
  } = state.getRelatedProjects.toJS();
  const {
    error: postProjectError
  } = state.postReimportProject.toJS();
  return {
    aqjson,
    postProjectError,
    result,
    relatedProjects,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postReimportProject: fetchDux.postReimportProject.createAction,
      clearPostReimportProject: fetchDux.postReimportProject.clearAction,
      getProjectById: fetchDux.getProjectById.createAction,
      getRelatedProjects: fetchDux.getRelatedProjects.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsUpdateImport);
