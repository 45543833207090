import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import Icon from '../../../ui/Icon';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Loading from '../../../ui/Loading';

import FreightCalcForm from './FreightCalcForm';
import FreightCalcResults from './FreightCalcResults';

class FreightCalc extends Component {
  render() {
    const { postFreightQuote, result, inProgress } = this.props;

    const handleSubmit = payload => {
      const { items } = payload || {};
      const formattedItems = (items || []).map(i => ({ ...i, quantity: 1 }));
      postFreightQuote({ ...payload, items: formattedItems });
    };

    return (
      <Layout route="freight-quote">
        <h2>
          Freight Rates
          <div>Get Quotes and Service Days</div>
        </h2>
        <div className="info">
          Please be advised if any of the following are applicable to your quote you
          {' '}
          <b>MUST</b>
          {' '}
          go directly to the website for your rate quote or email the sales rep directly.
        </div>
        <p className="info">
          Residential pick-up or delivery, holiday pick-up or delivery, Exhibition pick-up or delivery.
        </p>

        <Box>
          <div className="box-body">

            <FreightCalcForm
              onSubmit={handleSubmit}
              initialValues={{ items: [{}] }}
            />

          </div>
        </Box>

        {inProgress && <Loading />}
        {!inProgress &&
          <Box>
            <div className="box-body">

              <FreightCalcResults results={result} />

            </div>
          </Box>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.postFreightQuote.toJS();
  return {
    result,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postFreightQuote: fetchDux.postFreightQuote.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(FreightCalc);
