import React from "react";
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fetchDux from "../../../../state/fetch-dux";
import { actionCreators } from "../../../../state/modals-dux";
import Icon from "../../../ui/Icon";

const ModalRemoveAssignments = (
    {
        name,
        openModal,
        closeModal,
        shipmentLineItems,
        inProgress,
        removeShipmentAssignments,
        successHandler,
        poId,
        error
    }
) => {

    const { name: openModalName } = openModal || {};
    const [item = {}] = shipmentLineItems || [];
    const { PurchaseOrderNumber } = item;
    const shipmentLineItemIds = (shipmentLineItems || []).map(({ shipmentLineItemId }) => shipmentLineItemId);

    const closeHandler = () => {
        if (!inProgress) closeModal()
    }

    return (
        <Modal
            show={openModalName === name}
            size="md"
            dialogClassName="modal-600 modal-edit-line-items"
            onHide={closeHandler}
        >
            <Modal.Header>
                <Modal.Title style={{ color: '#C23934' }}>
                    <Icon name="exclamation-triangle" />
                    &nbsp;
                    &nbsp;
                    {`Remove Assignments`}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {error && <Alert variant="danger">{error.message}</Alert>}
                <label>
                    {
                        `Are you sure you want to remove assignments to the shipment lineitems (${shipmentLineItemIds.length}).
                    Doing so will move all the shipment lineitems to the Un-assigned Shipment group.`
                    }
                </label>
            </Modal.Body>


            <Modal.Footer>
                <Row className='editLineItemsFooter' style={{ justifyContent: 'flex-end' }}>
                    <Col sm={3}>
                        <Button
                            size='sm'
                            variant='outline-secondary'
                            onClick={
                                () => closeModal()
                            }
                            disabled={inProgress}
                        >
                            Cancel
                        </Button>
                    </Col>

                    <Col sm={3}>
                        <Button
                            size='sm'
                            variant='danger'
                            style={{ float: 'right' }}
                            disabled={inProgress}
                            onClick={
                                () => {
                                    const payload =
                                    {
                                        poNum: PurchaseOrderNumber,
                                        shipmentLineItems: shipmentLineItemIds,
                                        poId
                                    }
                                    removeShipmentAssignments(payload, null, null, () => {
                                        closeHandler();
                                        successHandler();
                                    });
                                }
                            }
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}


const mapState = state => {
    const {
        inProgress,
        result,
        error
    } = state.removeShipmentAssignments.toJS();
    return {
        openModal: state.modals,
        inProgress,
        result,
        error
    }
}

const mapDispatch = dispatch => bindActionCreators(
    {
        ...actionCreators,
        removeShipmentAssignments: fetchDux.removeShipmentAssignments.createAction
    },
    dispatch
)

export default connect(mapState, mapDispatch)(ModalRemoveAssignments);

