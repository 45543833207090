import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

import ImportCustomersLocationsForm from './ImportCustomersLocationsForm';
import fetchDux from '../../../../state/fetch-dux';

class ModalImportCustomersLocation extends Component {


  handleLocationsUploadSumbit = (values) => {
    const { uploadLocations } = this.props;
    const { file } = values;
    const payload = {
      uploadFile: file
    }
    uploadLocations(payload, 'locations-import-form');
  }

  closeHandler = () => {
    const {
      inProgress,
      clearUploadLocationsResult,
      closeModal,
      searchCustomersCompanies,
      searchCustomersLocations,
      uploadResult
    } = this.props;
    if (!inProgress) {
      if (uploadResult) {
        clearUploadLocationsResult();
        searchCustomersCompanies();
        searchCustomersLocations();
      }
      closeModal()
    }
  }

  render() {
    const {
      openModal,
      name,
      error,
      uploadResult,
      downloadLocationsTemplate
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={this.closeHandler}
        className="modal-alt"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload CSV</Modal.Title>
        </Modal.Header>
        <ImportCustomersLocationsForm
          onSubmit={this.handleLocationsUploadSumbit}
          closeModal={this.closeHandler}
          uploadResult={uploadResult}
          downloadLocationsTemplate={downloadLocationsTemplate}
          error={error}
        />
      </Modal>
    );
  }
}


const mapState = state => {
  const {
    inProgress,
    error,
    result: uploadResult
  } = state.uploadLocations.toJS()
  return {
    openModal: state.modals,
    inProgress,
    error,
    uploadResult
  }
}
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      uploadLocations: fetchDux.uploadLocations.createAction,
      clearUploadLocationsResult: fetchDux.uploadLocations.clearAction,
      downloadLocationsTemplate: fetchDux.downloadLocationsTemplate.createAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      searchCustomersLocations: fetchDux.searchCustomersLocations.createAction,
    }
    , dispatch);

export default connect(mapState, mapDispatch)(ModalImportCustomersLocation);
