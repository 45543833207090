import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Row, Col, Form } from 'react-bootstrap';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

const freightProgramOptions = [
  { value: 'P', label: 'If # of pieces exceeds' },
  { value: 'T', label: 'If PO total $ exceeds' },
  { value: 'F', label: 'Maximum cost' },
  { value: 'A', label: 'Always free', isMaster: true }
];

export const freightPrograms = freightProgramOptions.reduce(
  (t, p) => Object.assign({}, t, { [p.value]: p.label }),
  {}
);

const required = value => value ? undefined : 'Required';
const requiredFPValue = (code, value, formValues) => {
  const { FreightProgram = [] } = formValues;
  return FreightProgram.includes(code) && !value ? 'Required' : undefined;
};
const requiredFreightProgramPieces = (value, formValues) =>
  requiredFPValue('P', value, formValues);
const requiredFreightProgramPO = (value, formValues) =>
  requiredFPValue('T', value, formValues);
const requiredFreightProgramFlatRate = (value, formValues) =>
  requiredFPValue('F', value, formValues);

const VendorForm = props => {
  const {
    handleSubmit,
    submitting,
    change,
    IsColoradoType,
    FreightProgramValue = []
  } = props;

  const isColoradoDisabled = !IsColoradoType ||
    ['1', '2'].some(x => x === IsColoradoType);

  const handleColoradoTypeChange = (e, value) => {
    if (value === '1') return change('IsColorado', false);
    if (value === '2') return change('IsColorado', true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={6}>
          <Field
            name="Name"
            component={FormField.Input}
            label="Sage Vendor Name"
            disabled={submitting}
            validate={[required]}
          />
        </Col>
        <Col sm={3} style={{ marginTop: '30px' }}>
          <Field
            name="IsColoradoType"
            component={FormField.RadioGroup}
            options={[
              { value: '1', label: 'Never Colorado' },
              { value: '2', label: 'Always Colorado' },
              { value: '3', label: 'Colorado Optional' }
            ]}
            validate={[required]}
            disabled={submitting}
            onChange={handleColoradoTypeChange}
          />

        </Col>
        <Col sm={3} style={{ marginTop: '30px' }}>
          <Field
            name="IsColorado"
            component={FormField.InputToggle}
            label="Default to Colorado"
            disabled={submitting || isColoradoDisabled}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            name="VendorNo"
            component={FormField.Input}
            label="Sage Vendor No"
            disabled={submitting}
            validate={[required]}
          />
        </Col>
        <Col sm={6}>
          <Field
            name="PreferredEmail"
            component={FormField.Input}
            label="Email"
            disabled={submitting}
            validate={[required]}
          />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <Field
            name="DisplayName"
            component={FormField.Input}
            label="Display Name"
            disabled={submitting}
          />
        </Col>
        <Col sm={6}>
          <Field
            name="PreferredEmailCC"
            component={FormField.Input}
            label="Email CC ( Use semicolon to add multiple email addresses. )"
            disabled={submitting}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            name="RepGroup"
            component={FormField.Input}
            label="Rep Group"
            disabled={submitting}
          />
        </Col>
        <Col sm={6}>
          <Field
            name="Discount"
            component={FormField.Input}
            label="Discount"
            disabled={submitting}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <Field
            name="AdditionalNotes"
            component={FormField.Textarea}
            label="Additional Notes"
            disabled={submitting}
          />
        </Col>
        <Col sm={3} style={{ marginTop: '30px' }}>
          <Field
            name="CPG"
            component={FormField.InputToggle}
            label="CPG"
            disabled={submitting}
          />
        </Col>

        <Col sm={3} style={{ marginTop: '30px' }}>
          <Field
            name="LBMX"
            component={FormField.InputToggle}
            label="LBMX"
            disabled={submitting}
          />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <hr />
          <h4>Freight Program</h4>
        </Col>
        <Col sm={6}>
          <Field
            name="FreightProgram"
            component={FormField.CheckboxGroup}
            options={freightProgramOptions}
            // label="Freight Program"
            disabled={submitting}
          />
        </Col>
        <Col sm={6}>
          <Field
            name="FreightProgramPieces"
            component={FormField.Input}
            label="Number of Pieces"
            disabled={!FreightProgramValue.includes('P') || submitting}
            validate={[requiredFreightProgramPieces]}
          />
          <Field
            name="FreightProgramPO"
            component={FormField.Input}
            label="Total PO"
            disabled={!FreightProgramValue.includes('T') || submitting}
            validate={[requiredFreightProgramPO]}
          />
          <Field
            name="FreightProgramFlatRate"
            component={FormField.Input}
            label="Flat Rate"
            disabled={!FreightProgramValue.includes('F') || submitting}
            validate={[requiredFreightProgramFlatRate]}
          />
        </Col>
      </Row>
    </Form>
  );
};

const formName = 'vendor-form';

const selector = formValueSelector(formName);

const mapState = state => {
  const IsColoradoType = selector(state, 'IsColoradoType');
  const FreightProgramValue = selector(state, 'FreightProgram');
  return {
    IsColoradoType,
    FreightProgramValue
  };
};

const form = reduxForm({
  form: formName
})(VendorForm);

export default connect(mapState)(form);
