import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Layout from '../../ui/Layout';
import SalesRepsAdmin from './SalesRepsAdmin';
import VendorsAdmin from './VendorsAdmin';
import AdminWarehouses from './warehouses/AdminWarehouses';

const getTabClass = (path, pathname) =>
  (pathname || '').indexOf(path || '') !== -1 ? 'tab active' : 'tab';

const TabsNavigation = ({ pathname, children, navigate }) => {
  return (
    <div className="project-items">
      <div className="project-items-tabs" style={{ marginBottom: '20px' }}>
        <div
          className={getTabClass('sales-reps', pathname)}
          onClick={navigate('/administration/sales-reps')}
        >
          Sales Reps
        </div>
        <div
          className={getTabClass('vendors', pathname)}
          onClick={navigate('/administration/vendors')}
        >
          Sage Vendors
        </div>
      </div>
      {children}
    </div>
  );
};

export default ({ match, location, history }) => {
  const { pathname } = location || {};
  const navigate = r => () => history.push(r);
  return (
    <Switch>
      <Route path={`${match.url}/warehouses`} component={AdminWarehouses} />
      <Layout route="projects-detail">
        <TabsNavigation pathname={pathname} navigate={navigate}>
          <Switch>
            <Route
              exact
              path={`${match.url}/sales-reps`}
              component={SalesRepsAdmin}
            />
            <Route exact path={`${match.url}/vendors`} component={VendorsAdmin} />
            <Redirect to={`${match.url}/sales-reps`} />
          </Switch>
        </TabsNavigation>
      </Layout>
    </Switch>
  );
};
