import React, { Component, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../../ui/Icon';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state/modals-dux';
import fetchDux from '../../../../../state/fetch-dux';

import ViewShippingSummaryMap from './ViewShippingSummaryMap';

const getDate = (d, format) => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format(format);
};

const getAddress = (
  {
    addresses,
    value,
    c_PurchaseOrderAddressId,
    projectAddressValues
  }
) => {
  const address = (addresses || [])
    .find(({ ID }) => ID === c_PurchaseOrderAddressId);
  const {
    override,
    confirmed
  } = value || {};
  if (override) return override;
  if (confirmed) return projectAddressValues;
  return address;
};

const getWarehouse = (
  {
    c_WarehouseID,
    warehouses
  }
) => {
  const warehouse = (warehouses || []).find(({ ID }) => ID === c_WarehouseID);
  if (!warehouse) return {};
  return {
    State: warehouse.StateProvince,
    Address1: warehouse.Address1,
    City: warehouse.City
  };
};

const getShipper = (
  {
    carriers,
    c_ShipViaId
  }
) => {
  const shipper = (carriers || []).find(({ Code }) => Code === c_ShipViaId);
  if (!shipper) return {};
  return {
    CarrierName: shipper.Name
  };
};

const getShippingAddress = (
  {
    addresses,
    c_PurchaseOrderAddressId,
    POID,
    purchaseOrderAddressValues,
    projectAddressValues,
    lookups
  }
) => {
  const value = (purchaseOrderAddressValues || [])
    .find(({ ID }) => ID === POID) || {};

  const address = getAddress({
    addresses,
    value,
    c_PurchaseOrderAddressId,
    purchaseOrderAddressValues,
    projectAddressValues
  });

  const { c_ShipViaId } = address || {};

  const {
    c_WarehouseID
  } = address || {};

  const {
    carriers,
    warehouses
  } = lookups || {};

  const shipper = getShipper({
    c_ShipViaId,
    carriers
  });
  const warehouse = getWarehouse({
    c_WarehouseID,
    warehouses
  });

  return { ...address, ...shipper, ...warehouse };
};

const getGroups = (
  {
    purchaseOrders,
    addresses,
    projectAddressValues,
    lookups,
    purchaseOrderAddressValues
  }
) => {
  const pos = (purchaseOrders || []).map(po => {
    const {
      c_PurchaseOrderAddressId,
      ID: POID
    } = po || {};
    const shippingAddress = getShippingAddress({
      addresses,
      c_PurchaseOrderAddressId,
      POID,
      projectAddressValues,
      purchaseOrderAddressValues,
      lookups
    });
    const {
      Address1,
      State,
      Zip,
      City
    } = shippingAddress || {};

    const fullAddress = `${Address1 || ''}, ${City || ''}, ${State || ''} ${Zip || ''}`;

    return { ...po, shippingAddress, fullAddress };
  });

  const addressList = pos.map(({ fullAddress }) => fullAddress);

  const location = pos.reduce(
    (prev, next) => {
      const { fullAddress, shippingAddress } = next || {};
      const {
        Address1,
        Address2,
        State,
        Zip,
        City
      } = shippingAddress || {};
      const existing = prev.find(({ key }) => key === fullAddress);
      if (!existing)
        return [
          ...prev,
          {
            key: fullAddress,
            header: (
              <div>
                <p>{Address1} {Address2 || ''}</p>
                <strong>{City}, {State} {Zip}</strong>
              </div>
            ),
            items: [next]
          }
        ];
      return prev.map(p => {
        if (p.key !== fullAddress) return p;
        return { ...p, items: [...p.items, next] };
      });
    },
    []
  );

  const shipDate = pos.reduce(
    (prev, next) => {
      const { shippingAddress } = next || {};
      const { c_ShipDate } = shippingAddress || {};

      const shipDate = getDate(c_ShipDate, 'MM/DD/YYYY');

      const existing = prev.find(({ key }) => key === shipDate);
      if (!existing)
        return [
          ...prev,
          {
            key: shipDate,
            header: (
              <div>
                <p>{getDate(c_ShipDate, 'dddd')}</p>
                <strong>{getDate(c_ShipDate, 'MM/DD/YYYY')}</strong>
              </div>
            ),
            items: [next]
          }
        ];
      return prev.map(p => {
        if (p.key !== shipDate) return p;
        return { ...p, items: [...p.items, next] };
      });
    },
    []
  );

  const carrier = pos.reduce(
    (prev, next) => {
      const { shippingAddress } = next || {};
      const { c_ShipViaId, CarrierName } = shippingAddress || {};

      const existing = prev.find(({ key }) => key === c_ShipViaId);
      if (!existing)
        return [
          ...prev,
          {
            key: c_ShipViaId,
            header: (
              <div>
                <p>{CarrierName}</p>
              </div>
            ),
            items: [next]
          }
        ];
      return prev.map(p => {
        if (p.key !== c_ShipViaId) return p;
        return { ...p, items: [...p.items, next] };
      });
    },
    []
  );

  return {
    addressList,
    groups: {
      location,
      shipDate,
      carrier
    }
  };
};

const RenderGroup = ({ g, view, onGroupClick }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={`group ${isOpen ? 'active' : ''}`}>
      <div className="group-header">
        <div className="total">{(g.items || []).length}</div>
        <Icon
          className="toggle"
          name={isOpen ? 'angle-down' : 'angle-right'}
          onClick={() => setOpen(!isOpen)}
          style={{ right: '0', padding: '15px' }}
        />
        <div className="icon" onClick={() => onGroupClick(g)}>
          {view === 'location' && <Icon name="map-marker-alt" />}
          {view === 'shipDate' && <Icon name="calendar-alt" />}
          {view === 'carrier' && <Icon name="truck" />}
        </div>
        <div onClick={() => onGroupClick(g)}>
          {g.header}
        </div>
      </div>
      {(g.items || []).map((po, ii) => (
        <div
          className="group-item"
          key={ii}
          onClick={() => onGroupClick({ items: [po] })}
        >
          <strong>{po.c_PurchaseOrderNumber}</strong>
          <p>{po.VendorName}</p>
        </div>
      ))}
    </div>
  );
};

class ViewShippingSummary extends Component {
  constructor(props, context) {
    super(props, context);
    this.setView = this.setView.bind(this);
    this.toggleGroup = this.toggleGroup.bind(this);
    this.state = {
      view: 'location',
      group: null
    };
    this.shippingSummary = null;
  }
  componentDidMount() {
    const {
      purchaseOrders,
      addresses,
      projectAddressValues,
      poShippingValues,
      lookups,
      postGeoLookup
    } = this.props;

    const { purchaseOrders: purchaseOrderAddressValues } = poShippingValues || {
    };

    const { addressList } = getGroups({
      purchaseOrders,
      addresses,
      projectAddressValues,
      lookups,
      purchaseOrderAddressValues
    });
    postGeoLookup(addressList);
  }
  setView(value) {
    this.setState({ view: value, group: null });
  }
  toggleGroup(value) {
    this.setState({ group: value === this.state.group ? null : value });
  }
  render() {
    const {
      purchaseOrders,
      addresses,
      projectAddressValues,
      poShippingValues,
      lookups,
      geocodes,
      getGeocodesInProgress
    } = this.props;

    const { purchaseOrders: purchaseOrderAddressValues } = poShippingValues || {
    };
    const {
      view
    } = this.state;

    const { groups } = getGroups({
      purchaseOrders,
      addresses,
      projectAddressValues,
      lookups,
      purchaseOrderAddressValues
    });

    const { refs } = this.shippingSummary || {};
    const { map } = refs || {};

    const handleGroupClick = group => {
      const { items } = group || {};
      if (!items || !items.length) return;
      const addresses = items.map(({ fullAddress }) => fullAddress);
      const filtered = geocodes.filter(({ address }) =>
        addresses.some(a => a === address));
      if (!filtered || !filtered.length) return;
      if (!map) return;
      map.setBounds(filtered);
    };

    return (
      <div className="view-shipping-summary">
        <Row>
          <Col sm={4}>
            <div className="menu">
              <strong>View By</strong>
              <Row>
                <Col>
                  <Button
                    variant={view === 'location' ? 'primary' : 'secondary'}
                    onClick={() => this.setView('location')}
                  >
                    Location
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={view === 'shipDate' ? 'primary' : 'secondary'}
                    onClick={() => this.setView('shipDate')}
                  >
                    Ship Date
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={view === 'carrier' ? 'primary' : 'secondary'}
                    onClick={() => this.setView('carrier')}
                  >
                    Carrier
                  </Button>
                </Col>
              </Row>
            </div>

            <div className="groups">
              {(groups[view] || [])
                .map((g, i) => (
                  <RenderGroup
                    key={g.key}
                    view={view}
                    g={g}
                    onGroupClick={handleGroupClick}
                  />
                ))}
            </div>

          </Col>
          <Col sm={8}>
            {map &&
              <div style={{ textAlign: 'right', marginBottom: '15px' }}>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => map.updateMap()}
                >
                  Recenter Map
                </Button>
              </div>}
            <ViewShippingSummaryMap
              geocodes={geocodes}
              getGeocodesInProgress={getGeocodesInProgress}
              ref={ref => this.shippingSummary = ref}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapState = state => {
  const {
    result: geocodes,
    inProgress: getGeocodesInProgress
  } = state.postGeoLookup.toJS();
  return {
    geocodes,
    getGeocodesInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postGeoLookup: fetchDux.postGeoLookup.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ViewShippingSummary);
