import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import ProcessEcommerceOrderForm from './ProcessEcommerceOrderForm';

class ModalProcessEcommerceOrder extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmit,
      initialValues,
      companyTypes,
      handleSubmitUpdateCompany
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Proccess Order</Modal.Title>
        </Modal.Header>
        <ProcessEcommerceOrderForm closeModal={closeModal} />
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalProcessEcommerceOrder);
