import React, { useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Alert, Container } from 'react-bootstrap';
import moment from 'moment';
import numeral from 'numeral';

import Loading from '../../../ui/Loading';

const formatMoney = value => numeral(value).format('0,0.00');

const required = value => !value ? 'Required' : undefined;

const ImportProductsResults = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    productsResult,
    vendorsResult,
    noChangeProducts,
    deletingResult,
    addingResult,
    updatingResult,
    existedProducts,
    handleCancelImport,
    valid
  } = props;
  const [activeTab, setActiveTab] = useState(0);

  const getStyle=(currentTab, activeTab)=>{
    if(currentTab === activeTab) return {color:'#5ea2c9'}
    return {}
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">

        <h6>
          UPLOAD SUMMARY
        </h6>

        <div className="box-page-tabs" style={{ margin: '20px 30px 10px 0' }}>
          <ul>
            <li onClick={() => setActiveTab(0)} style={getStyle(0, activeTab)}>
              <a href>Existing ({(existedProducts || []).length})</a>
            </li>
            <li
              onClick={() => setActiveTab(1)}
              style={getStyle(1, activeTab)}
            >
              <a href>New ({(addingResult || []).length})</a>
            </li>
            <li
              onClick={() => setActiveTab(2)}
              style={getStyle(2, activeTab)}
            >
              <a href>Updating ({(updatingResult || []).length})</a>
            </li>
            <li
              onClick={() => setActiveTab(3)}
              style={getStyle(3, activeTab)}
            >
              <a href>Deleting ({(deletingResult || []).length})</a>
            </li>
            <li
              onClick={() => setActiveTab(4)}
              style={!valid ? { color: 'red' } : {}}
            >
              <a href>Vendors ({(vendorsResult || []).length})</a>
            </li>
          </ul>
        </div>

        <Row
          style={{
            height: '300px',
            overflow: 'scroll',
            overflowX: 'hidden',
            display: activeTab === 0 ? 'flex' : 'none'
          }}
        >
          <Col md={12} style={{ paddingRight: '45px' }}>
            {(existedProducts || []).map(({
              category,
              spec,
              model,
              fullPrice,
              imageHref
            }) => {
              return (
                <Row style={{ alignContent: 'center' }}>
                  <Col md={1} style={{ alignSelf: 'center' }}>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={imageHref}
                    />
                  </Col>
                  <Col
                    md={3}
                    style={{
                      alignSelf: 'center',
                      paddingLeft: '30px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      paddingBottom: '4px'
                    }}
                  >
                    <span className="table-label">
                      {category}
                    </span>
                  </Col>
                  <Col
                    md={6}
                    style={{
                      alignSelf: 'center',
                      //    height: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <div style={{ fontWeight: 'bold' }}>
                      {model}
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 'smaller',
                          margin: '0px',
                          overflow: 'hidden'
                        }}
                      >
                        {spec}
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{ alignSelf: 'center', textAlign: 'right' }}
                  >
                    ${formatMoney(fullPrice)}
                  </Col>
                  <Col sm={12}>
                    <hr
                      style={{
                        borderTopColor: 'rgba(0, 0, 0, 0.25)',
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>


        <Row
          style={{
            height: '300px',
            overflow: 'scroll',
            overflowX: 'hidden',
            display: activeTab === 1 ? 'flex' : 'none'
          }}
        >
          <Col md={12} style={{ paddingRight: '45px' }}>
            {(addingResult || []).map(({
              Category,
              Spec,
              Model,
              fullPrice,
              SellPrice,
              imageHref
            }) => {
              return (
                <Row style={{ alignContent: 'center' }}>
                  <Col md={1} style={{ alignSelf: 'center' }}>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={imageHref}
                    />
                  </Col>
                  <Col
                    md={3}
                    style={{
                      alignSelf: 'center',
                      paddingLeft: '30px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      paddingBottom: '4px'
                    }}
                  >
                    <span className="table-label">
                      {Category}
                    </span>
                  </Col>
                  <Col
                    md={6}
                    style={{
                      alignSelf: 'center',
                      //    height: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <div style={{ fontWeight: 'bold' }}>
                      {Model}
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 'smaller',
                          margin: '0px',
                          overflow: 'hidden'
                        }}
                      >
                        {Spec}
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{ alignSelf: 'center', textAlign: 'right' }}
                  >
                    ${formatMoney(SellPrice)}
                  </Col>
                  <Col sm={12}>
                    <hr
                      style={{
                        borderTopColor: 'rgba(0, 0, 0, 0.25)',
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row
          style={{
            height: '300px',
            overflow: 'scroll',
            overflowX: 'hidden',
            display: activeTab === 2 ? 'flex' : 'none'
          }}
        >
          <Col md={12} style={{ paddingRight: '45px' }}>
            {(updatingResult || []).map(({
              Category,
              Spec,
              Model,
              SellPrice,
              imageHref
            }) => {
              return (
                <Row style={{ alignContent: 'center' }}>
                  <Col md={1} style={{ alignSelf: 'center' }}>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={imageHref}
                    />
                  </Col>
                  <Col
                    md={3}
                    style={{
                      alignSelf: 'center',
                      paddingLeft: '30px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      paddingBottom: '4px'
                    }}
                  >
                    <span className="table-label">
                      {Category}
                    </span>
                  </Col>
                  <Col
                    md={6}
                    style={{
                      alignSelf: 'center',
                      //    height: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <div style={{ fontWeight: 'bold' }}>
                      {Model}
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 'smaller',
                          margin: '0px',
                          overflow: 'hidden'
                        }}
                      >
                        {Spec}
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{ alignSelf: 'center', textAlign: 'right' }}
                  >
                    ${formatMoney(SellPrice)}
                  </Col>
                  <Col sm={12}>
                    <hr
                      style={{
                        borderTopColor: 'rgba(0, 0, 0, 0.25)',
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row
          style={{
            height: '300px',
            overflow: 'scroll',
            overflowX: 'hidden',
            display: activeTab === 3 ? 'flex' : 'none'
          }}
        >
          <Col md={12} style={{ paddingRight: '45px' }}>
            {(deletingResult || []).map(({
              Category,
              Spec,
              Model,
              SellPrice,
              imageHref
            }) => {
              return (
                <Row style={{ alignContent: 'center' }}>
                  <Col md={1} style={{ alignSelf: 'center' }}>
                    <img
                      style={{ width: '50px', height: '50px' }}
                      src={imageHref}
                    />
                  </Col>
                  <Col
                    md={3}
                    style={{
                      alignSelf: 'center',
                      paddingLeft: '30px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      paddingBottom: '4px'
                    }}
                  >
                    <span className="table-label">
                      {Category}
                    </span>
                  </Col>
                  <Col
                    md={6}
                    style={{
                      alignSelf: 'center',
                      //    height: '100%',
                      overflow: 'hidden'
                    }}
                  >
                    <div style={{ fontWeight: 'bold' }}>
                      {Model}
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: 'smaller',
                          margin: '0px',
                          overflow: 'hidden'
                        }}
                      >
                        {Spec}
                      </p>
                    </div>
                  </Col>
                  <Col
                    md={2}
                    style={{ alignSelf: 'center', textAlign: 'right' }}
                  >
                    ${formatMoney(SellPrice)}
                  </Col>
                  <Col sm={12}>
                    <hr
                      style={{
                        borderTopColor: 'rgba(0, 0, 0, 0.25)',
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row
          style={{
            height: '300px',
            overflow: 'scroll',
            overflowX: 'hidden',
            display: activeTab === 4 ? 'flex' : 'none'
          }}
        >
          <Col md={12} style={{ paddingRight: '45px' }}>
            {(vendorsResult || []).map(({
              ID,
              Name,
              VendorNo,
              vendorOptions,
              c_SelectedPurchaseOrderSageVendorID,
              IsColoradoType
            }) => {
              const options = vendorOptions.map(({
                ID: value,
                Name: label
              }) => ({
                value,
                label
              }));
              return (
                <Row style={{ alignContent: 'center' }}>
                  <Col md={8} style={{ alignSelf: 'center' }}>
                    {Name}
                  </Col>
                  <Col md={4} style={{ alignSelf: 'center' }}>
                    {c_SelectedPurchaseOrderSageVendorID &&
                      <span>{VendorNo}</span>}
                    {!c_SelectedPurchaseOrderSageVendorID &&
                      <div className="no-margin">
                        <Field
                          name={`vendors.${ID}`}
                          component={FormField.SelectReact}
                          validate={[required]}
                          options={options}
                        />
                      </div>}
                    {/* {IsColoradoType === 3 && (
                      <div>HELLO WORLD</div>
                    )} */}
                  </Col>
                  <Col sm={12}>
                    <hr
                      style={{
                        borderTopColor: 'rgba(0, 0, 0, 0.25)',
                        marginTop: '10px',
                        marginBottom: '10px'
                      }}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Row>

      </div>

      <div className="modal-footer justify-content-between" >
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <div>
          <Button
            variant="danger"
            type="button"
            onClick={handleCancelImport}
            disabled={submitting}
            style={{ marginRight: '10px' }}
          >
            Cancel Import
          </Button>
          <Button variant="primary" type="submit" disabled={submitting}>
            Save & Update
          </Button>
        </div>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'review-product-catalog-form'
})(ImportProductsResults);
