import React from 'react';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import EditProjectShippingForm
  from './../../projects-detail/EditProjectShippingForm';
import moment from 'moment';

const formatShipDate = value => {
  if (!value || !moment(value).isValid()) return null;
  return moment(value).format('YYYY-MM-DD');
};

const SubmitButtonHtml = ({ dispatch }) => (
  <Button
    variant="success"
    onClick={() => dispatch(submit('shipping-order-address'))}
  >
    Save & Continue
  </Button>
);

const SubmitButton = connect()(SubmitButtonHtml);

const Step1 = (
  {
    lookups,
    active,
    setProjectAddress,
    projectAddressInitialValues
  }
) => {
  if (!active) return false;

  const handleSubmit = values => {
    const { c_AttnNameOnly, c_ShipDate, ...rest } = values || {};
    setProjectAddress({
      c_AttnNameOnly: c_AttnNameOnly === 'true',
      c_ShipDate: formatShipDate(c_ShipDate),
      ...rest
    });
  };

  return (
    <div>
      <EditProjectShippingForm
        enableReinitialize
        initialValues={projectAddressInitialValues}
        lookups={lookups}
        form="shipping-order-address"
        onSubmit={handleSubmit}
        hideButtons
      />
      <div style={{ textAlign: 'right' }}>
        <SubmitButton />
      </div>
    </div>
  );
};

export default Step1;
