import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';

const required = value => value ? undefined : 'Required';
const radiusRequired = value => value && value !== -1 ? undefined : 'Required';

const WarehouseSearchForm = props => {
  const {
    handleSubmit,
    submitting,
    clearSearch,
    formValues
  } = props;
  const { formSearch, formAddress, formRadius } = formValues || {};
  return (
    <form onSubmit={handleSubmit} style={{ paddingBottom: '10px' }}>
      <Row>

        <Col md={3}>
          <Field
            name="name"
            type="text"
            placeholder={'Search by name...'}
            component={FormField.Input}
            validate={!formAddress && !(formRadius && formRadius !== -1) ? [required] : []}
          />
        </Col>

        <Col md={3}>
          <Field
            name="address"
            type="text"
            placeholder={'Enter zip or city...'}
            component={FormField.Input}
            validate={!formSearch ? [required] : []}
          />
        </Col>

        <Col md={3}>
          <Field
            name="radius"
            type="text"
            component={FormField.SelectReact}
            placeholder={'Select a radius...'}
            options={[
              { value: 5, label: 'within 5 miles' },
              { value: 10, label: 'within 10 miles' },
              { value: 25, label: 'within 25 miles' },
              { value: 50, label: 'within 50 miles' },
              { value: 75, label: 'within 75 miles' },
              { value: 100, label: 'within 100 miles' }
            ]}
            validate={!formSearch ? [radiusRequired] : []}
          />
        </Col>

        <Col md={3}>
          <Button
            variant="primary"
            type="submit"
            disabled={submitting}
            style={{ marginRight: '5px' }}
          >
            Search
          </Button>
          <Button
            variant="danger"
            onClick={clearSearch}
            disabled={submitting}
            style={{ marginL: '5px' }}
          >
            Clear
          </Button>
        </Col>

      </Row>
    </form>
  );
};

export default reduxForm({
  form: 'warehouse-search-form'
})(WarehouseSearchForm);
