import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button } from 'react-bootstrap';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Loading from '../../../ui/Loading';
import CustomersCompaniesSearchResults from './CustomersCompaniesSearchResults';
import { actionCreators } from '../../../../state/modals-dux';
import Notification from '../../../ui/Notification';

import ModalCreateCompany from './ModalCreateCompany';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '25',
  sortBy: sortBy || 'companyName',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class CustomersCompaniesSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchCustomersCompanies = this.handleSearchCustomersCompanies.bind(
      this
    );
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    // const { location: { search } } = this.props;
    // const query = getQueryFromSearch(search);
    // const qs = queryString.stringify(query);
    this.props.searchCustomersCompanies();
    this.props.getCompanyLookups({ companyTypes: 1 });
  }
  // componentWillReceiveProps(nextProps) {
  //   const { location: { search } } = nextProps;
  //   const { location: { search: search2 } } = this.props;

  //   if (search === search2) return;

  //   const query = getQueryFromSearch(search);
  //   const qs = queryString.stringify(query);
  //   this.props.searchCustomersCompanies(qs);
  // }
  handleSearchCustomersCompanies(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchCustomersCompanies({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchCustomersCompanies(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      error,
      result,
      location: { search },
      showModal,
      postCompany,
      getConceptsLookupsResult,
      clearSearchCustomersCompanies,
      searchCustomersCompaniesError,
      postCompanyInProgress,
      postCompanyError,
      clearPostCompany,
      getConceptsLookupsError,
      clearGetCompanyLookups,
      getConceptsLookupsInProgress
    } = this.props;

    const paging = getQueryFromSearch(search);

    const handleSubmitCreateCompany = values => {
      postCompany(values, 'create-customers-company-form', null, () => {
        this.props.searchCustomersCompanies();
      });
    };

    const {
      companyTypes
    } = getConceptsLookupsResult || {};

    const {
      message
    } = postCompanyError || {};

    const { search: companySearch = '' } = paging;

    const filteredResults = (result || []).filter((company) => {
      const { companyType, name, numProducts, numLocations, numUsers } = company;
      if(
        (name || '').indexOf(companySearch) >= 0 || 
        (companyType || '').indexOf(companySearch) >= 0 ||
        (numProducts.toString() || '').indexOf(companySearch) >= 0 ||
        (numLocations.toString() || '').indexOf(companySearch) >= 0 ||
        (numUsers.toString() || '').indexOf(companySearch) >= 0 
      ){
        return company;
      }
    })

    return (
      <Layout route="customers-companies-search" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Concepts
              </h2>
              <h3>
                {!inProgress && <span>{(result || []).length} Concepts</span>}
              </h3>
            </div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => showModal('modalCreateCustomersCompany')}
            >
              Create Concept
            </Button>
          </Container>
        </div>
        {!inProgress &&
          <Container>
            <Box className="box-table">
              <CustomersCompaniesSearchResults
                paging={paging}
                result={filteredResults}
                error={error}
                onFetchData={this.handleTableChange}
                handleSearchChange={this.handleSearchChange}
                inProgress={inProgress}
              />
            </Box>
          </Container>}
        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}
        <ModalCreateCompany
          name="modalCreateCustomersCompany"
          handleSubmitCreateCompany={handleSubmitCreateCompany}
          companyTypes={companyTypes}
          submitting={postCompanyInProgress}
        />
        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Concepts failed to load. Please refresh the page.
          </Notification>}
        {getConceptsLookupsError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyLookups}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {postCompanyError &&
          <Notification
            key="company-post-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostCompany}
          >
            {message ? message : 'Create Company Failed. Please try again.'}
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    inProgress: postCompanyInProgress,
    error: postCompanyError
  } = state.postCompany.toJS();

  const {
    result: getConceptsLookupsResult,
    inProgress: getConceptsLookupsInProgress,
    error: getConceptsLookupsError
  } = state.getConceptsLookups.toJS();

  return {
    result,
    getConceptsLookupsResult,
    inProgress: searchCustomersCompaniesInProgress,
    searchCustomersCompaniesError,
    getConceptsLookupsError,
    postCompanyInProgress,
    postCompanyError,
    getConceptsLookupsInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction,
      postCompany: fetchDux.postCompany.createAction,
      clearPostCompany: fetchDux.postCompany.clearAction,
      getCompanyLookups: fetchDux.getConceptsLookups.createAction,
      clearGetCompanyLookups: fetchDux.getConceptsLookups.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersCompaniesSearch);
