import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { Container, Button } from 'react-bootstrap';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import EcommerceOrdersSearchResult from './EcommerceOrdersSearchResult';
import ModalProcessEcommerceOrders from './ModalProcessEcommerceOrders';
import Loading from '../../../ui/Loading';
import { isEqual, cloneDeep } from 'lodash';
import Toggle from '../../../ui/Toggle';
import statusDict from '../statusDict';
import { getAllOrders } from '../../../../api/api';

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search,
    status
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  sortBy: sortBy || 'c_ImportedDate',
  desc: getDesc(desc),
  search: search || '',
  status: status || 'Pending'
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class EcommerceOrdersSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.state = {
      showPending: true,
      prevSearch: search,
      pending: []
    };
    this.handleSearchOrders = this.handleSearchOrders.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    this.props.getAllOrders(qs);
  }

  handleSearchOrders(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);
    history.push({ pathname, search: qs });
  }

  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted,
      status
    } = state || {};
    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchOrders({
      page,
      pageSize,
      sortBy,
      desc,
      status: query.status,
      search: query.search
    });
  }

  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchOrders(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }

  updateSelected = (jobRef, selected) => {
    const { pending } = this.state;
    pending.map(data => {
      if (data.JobRef === jobRef) {
        data.selected = selected;
      }
    });
  };

  ecommOrders = orderType => {
    if (orderType === 'Pending') {
      this.setState({ showPending: true });
    } else {
      this.setState({ showPending: false });
    }
  };

  static getDerivedStateFromProps(nextProps, currentState) {
    const { location: { search } } = nextProps;
    if (search !== currentState.prevSearch) {
      const qs = getQueryFromSearch(search);
      const query = queryString.stringify(qs);
      nextProps.getAllOrders(query);
      return {
        prevSearch: search
      };
    } else {
      //clear previously selected orders
      const { modals, inProgress, result } = nextProps;
      const { results } = result || {};
      if (!modals && !inProgress && results && results.length > 0) {
        const pending = cloneDeep(results || []);
        pending.map(data => data.selected = false);
        return {
          pending
        };
      }
      return null;
    }
  }

  render() {
    const {
      inProgress,
      error,
      result,
      location: { search },
      showModal,
      processOrders,
      closeModal
    } = this.props;
    const { pending, showPending } = this.state;
    const paging = getQueryFromSearch(search);
    const query = queryString.stringify(paging);
    const defaultLink = getQueryFromSearch({});
    const qs = queryString.stringify(defaultLink);
    return (
      <Layout route="ecommerce-orders-search" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Ecommerce Orders
              </h2>
            </div>
            {/* <Button
              variant="primary"
              size="sm"
              onClick={() => showModal('modalProcessEcommerceOrders')}
              disabled={inProgress || ''}
            >
              Process Selected Orders
            </Button> */}
          </Container>
        </div>
        <Container>
          <Box className="box-table">
            <div className="box-page-tabs">
              <Container>
                <ul>
                  <li className={showPending ? 'active' : ''}>
                    <Link
                      to={
                        `ecommerce-orders?${qs.replace('Processed', 'Pending')}`
                      }
                      onClick={() => this.ecommOrders('Pending')}
                    >
                      Pending Orders
                    </Link>
                  </li>

                  <li className={!showPending ? 'active' : ''}>
                    <Link
                      to={
                        `ecommerce-orders?${qs.replace('Pending', 'Processed')}`
                      }
                      onClick={() => this.ecommOrders('Processed')}
                    >
                      Processed Orders
                    </Link>
                  </li>
                </ul>
              </Container>

            </div>
            <EcommerceOrdersSearchResult
              paging={paging}
              error={error}
              onFetchData={this.handleTableChange}
              handleSearchChange={this.handleSearchChange}
              inProgress={inProgress}
              result={result}
              updateSelected={this.updateSelected}
              showPending={showPending}
            />
          </Box>
        </Container>
        <ModalProcessEcommerceOrders
          name="modalProcessEcommerceOrders"
          result={pending}
          handleSubmit={() => {
            const orders = pending
              .filter(data => data.selected)
              .map(data => data.projectId);
            processOrders({ orders }, null, null, result => {
              closeModal('modalProcessEcommerceOrders');
              this.props.getAllOrders(query);
            });
          }}
        />
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress,
    error
  } = state.getAllOrders.toJS();
  return {
    result,
    inProgress,
    error,
    modals: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getAllOrders: fetchDux.getAllOrders.createAction,
      processOrders: fetchDux.processOrders.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(EcommerceOrdersSearch);
