import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import numeral from 'numeral';
import { actionCreators } from '../../../../state/modals-dux';

import ModalSendUserWelcome from './SendUserWelcomeModal';
import ModalResetUserPassword from './ResetUserPasswordModal';
import ModalCustomersEditUser from '../customers-users/ModalEditUser';
import ModalCustomersDeleteUser from '../customers-users/ModalDeleteUser';
import Notification from '../../../ui/Notification';

const formatMoney = value => numeral(value).format('0,0.00');

const getBoolValue = value => {
  if (value === false)
    return <Icon fixedWidth name="times" className="text-danger" />;
  if (value === true)
    return <Icon fixedWidth name="check" className="text-success" />;

  return 'N/A';
};

const hasOptionType = (type, options, optionType) => {
  return (options || [])
    .some(option => parseInt((option || {})[optionType]) === type);
};

class CustomersUsersDetail extends Component {
  componentDidMount() {
    const {
      match,
      getCustomersUserById,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getCustomersUserById(
      {
        id,
        query: {
          getPaymentOptions: 1,
          getWebsiteOptions: 1,
          getConcepts: 1,
          getLocations: 1,
          getPurchaseApprover: 1,
          getEmailCCs: 1
        }
      },
      null,
      true
    );
    searchCustomersCompanies();
  }
  render() {
    const {
      inProgress,
      result,
      showModal,
      match,
      searchCustomersCompaniesResult,
      deleteUserByIdInProgress,
      deleteUserById,
      history,
      getCustomersUsersByIdError,
      clearCustomersUserById,
      clearDeleteUserById,
      deleteUserByIdError,
      clearSearchCustomersCompanies,
      searchCustomersCompaniesError,
      createPasswordById,
      welcomeEmailInProgress,
      welcomeEmailSuccess,
      closeModal,
      clearCreatePasswordById
    } = this.props;

    const {
      user,
      purchaseApprover,
      emailCCs,
      paymentOptions,
      websiteOptions,
      userCompanies,
      userLocations
    } = result || {};

    const {
      id,
      nameFirst,
      nameLast,
      email,
      phone,
      sageCustomerNumber,
      created,
      roleType,
      isApprover,
      purchasingApproval,
      paymentTerms
    } = user || {};

    const {
      spendingLimit,
      nameFirst: approverNameFirst,
      nameLast: approverNameLast
    } = purchaseApprover || {};

    const { id: userId } = match.params || {};

    const userDays = moment().diff(moment(created), 'days');
    const formattedDate = moment(created).format('MMMM Do YYYY');

    const handleDeleteUser = () => {
      deleteUserById(id, null, null, () => {
        history.push(`/customers/users`);
      });
    };

    const handleSendWelcome = () => {
      //Api call for sending reset password email link
      createPasswordById(id, null, null, () => {
        closeModal();
      });
    };

    return (
      <Layout route="customers-users-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/users" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {nameFirst || ''} {nameLast || ''}
              </h2>
              <h3>
                {!inProgress && <span>User for {userDays} days</span>}
              </h3>
            </div>
            <div>
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('SendUserWelcomeModal')}
              >
                Send Welcome Email
              </Button>
              {' '}
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('ResetUserPasswordModal')}
              >
                Send Reset Password Email
              </Button>
              {' '}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  <Icon fixedWidth name="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersUserEdit')}
                  >
                    Edit User
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersUserDelete')}
                  >
                    Delete User
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li className="active">
                <Link to={`/customers/users/${userId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/users/${userId}/concepts`}>
                  Concepts
                </Link>
              </li>
              <li>
                <Link to={`/customers/users/${userId}/locations`}>
                  Locations
                </Link>
              </li>
            </ul>
          </Container>
        </div>
        {!inProgress &&
          <Container>

            <Row>
              <Col sm={6}>

                <div className="box-bordered">

                  <h4>User Overview</h4>
                  <h5>User since {formattedDate}</h5>

                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <Icon fixedWidth name="user-circle" /> Name
                        </th>
                        <td>{nameFirst || ''} {nameLast || ''}</td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="envelope" /> Email{' '}
                        </th>
                        <td>
                          <a href={`mailto:${email || ''}`}>
                            {' '}{email || 'N/A'}
                          </a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="phone-alt" /> Phone{' '}
                        </th>
                        <td>
                          <a href={`tel:${phone}`}>{phone}</a>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="link" /> Sage Number{' '}
                        </th>
                        <td>{sageCustomerNumber || 'N/A'}</td>
                      </tr>
                    </tbody>
                  </table>

                </div>

                <div className="box-bordered">

                  <h4>User Settings</h4>

                  <br />

                  <h5>Payment Options</h5>
                  <Row>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(1, paymentOptions, 'paymentTypeId')
                      )}
                      {' '}
                      Credit Card
                    </Col>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(2, paymentOptions, 'paymentTypeId')
                      )}
                      {' '}
                      PO
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(3, paymentOptions, 'paymentTypeId')
                      )}
                      {' '}
                      Check
                    </Col>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(4, paymentOptions, 'paymentTypeId')
                      )}
                      {' '}
                      ACH
                    </Col>
                  </Row>

                  <hr />

                  <h5>Website Access</h5>
                  <Row>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(1, websiteOptions, 'websiteAccessTypeID')
                      )}
                      {' '}
                      Shop
                    </Col>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(2, websiteOptions, 'websiteAccessTypeID')
                      )}
                      {' '}
                      Quotes
                    </Col>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(3, websiteOptions, 'websiteAccessTypeID')
                      )}
                      {' '}
                      Documents
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(4, websiteOptions, 'websiteAccessTypeID')
                      )}
                      {' '}
                      Orders
                    </Col>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(5, websiteOptions, 'websiteAccessTypeID')
                      )}
                      {' '}
                      Locations
                    </Col>
                    <Col sm={4}>
                      {getBoolValue(
                        hasOptionType(6, websiteOptions, 'websiteAccessTypeID')
                      )}
                      {' '}
                      Invoices
                    </Col>
                  </Row>

                </div>

              </Col>
              <Col sm={6}>

                <div className="box-bordered">

                  <h4>User Details</h4>

                  <table>
                    <tbody>
                      <tr>
                        <th>
                          User Role
                        </th>
                        <td>
                          {roleType || 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Is Approver
                        </th>
                        <td>
                          {getBoolValue(isApprover)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Requires Approval
                        </th>
                        <td>
                          {getBoolValue(purchasingApproval)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Spending Limit
                        </th>
                        <td>
                          {spendingLimit === 0
                            ? `$${formatMoney(0)}`
                            : !spendingLimit
                              ? 'N/A'
                              : `$${formatMoney(spendingLimit)}`}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Required Approver
                        </th>
                        <td>
                          {approverNameFirst && approverNameLast
                            ? `${approverNameFirst || ''} ${approverNameLast}`
                            : 'N/A'}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Has Payment Terms
                        </th>
                        <td>
                          {getBoolValue(paymentTerms)}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          CC Email Invoices
                        </th>
                        <td>
                          {(emailCCs || [])
                            .map(({ email }) => (
                              <span className="table-label">{email}</span>
                            ))}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>

                <div className="box-bordered">

                  <h4>Concepts & Locations</h4>

                  <table>
                    <tbody>
                      <tr>
                        <th>
                          Concepts
                        </th>
                        <td>
                          <Link to={`/customers/users/${userId}/concepts`}>
                            {(userCompanies || []).length} Concepts
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Locations
                        </th>
                        <td>
                          <Link to={`/customers/users/${userId}/locations`}>
                            {(userLocations || []).length} Locations
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>

              </Col>
            </Row>

          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalResetUserPassword
          name="ResetUserPasswordModal"
          user={user || {}}
          match={match}
        />
        <ModalSendUserWelcome
          name="SendUserWelcomeModal"
          user={user || {}}
          match={match}
          inProgress={welcomeEmailInProgress}
          handleSendWelcome={handleSendWelcome}
        />

        {user &&
          <ModalCustomersEditUser
            name="modalCustomersUserEdit"
            result={result}
            companies={searchCustomersCompaniesResult}
          />}
        {user &&
          <ModalCustomersDeleteUser
            name="modalCustomersUserDelete"
            handleSubmit={handleDeleteUser}
            inProgress={deleteUserByIdInProgress}
          />}

        {getCustomersUsersByIdError &&
          <Notification
            key="user-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearCustomersUserById}
          >
            Failed to load user details. Please refresh the page.
          </Notification>}

        {deleteUserByIdError &&
          <Notification
            key="user-delete-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearDeleteUserById}
          >
            Failed to delete user. Please try again.
          </Notification>}

        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}

        {welcomeEmailSuccess &&
          <Notification
            key="welcome-email-sent"
            duration={3}
            closable={true}
            type="success"
            onClose={clearCreatePasswordById}
          >
            Welcome email has been sent
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getCustomersUsersByIdInProgress,
    error: getCustomersUsersByIdError
  } = state.getCustomersUserById.toJS();

  const {
    result: searchCustomersCompaniesResult,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    inProgress: deleteUserByIdInProgress,
    error: deleteUserByIdError
  } = state.deleteUserById.toJS();

  const {
    inProgress: welcomeEmailInProgress,
    result: welcomeEmailSuccess
  } = state.createPasswordById.toJS();
  return {
    result,
    searchCustomersCompaniesResult,
    deleteUserByIdInProgress,
    inProgress: getCustomersUsersByIdInProgress,
    getCustomersUsersByIdError,
    deleteUserByIdError,
    searchCustomersCompaniesError,
    welcomeEmailInProgress,
    welcomeEmailSuccess
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCustomersUserById: fetchDux.getCustomersUserById.createAction,
      clearCustomersUserById: fetchDux.getCustomersUserById.clearAction,
      deleteUserById: fetchDux.deleteUserById.createAction,
      clearDeleteUserById: fetchDux.deleteUserById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction,
      createPasswordById: fetchDux.createPasswordById.createAction,
      clearCreatePasswordById: fetchDux.createPasswordById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersUsersDetail);
