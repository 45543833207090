import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { actionCreators } from '../../state/modals-dux';

import NavItemLink from './NavItemLink';
import NavbarBrandLink from './NavbarBrandLink';
import Icon from './Icon';

import LogoutModal from '../routes/login/LogoutModal';

const TopNav = ({ user, showModal, minimal }) => (
  <Navbar bg="white" expand="lg" fixed="top">
    <LogoutModal />
    <NavbarBrandLink to={minimal ? '#' : '/'}>
      Concept Services 2.0
    </NavbarBrandLink>
    {!minimal && <Navbar.Toggle aria-controls="navbar-nav" />}
    {!minimal &&
      <Navbar.Collapse id="navbar-nav">
        <Nav className="mr-auto">
          <NavItemLink to="/"><Icon name="home" /></NavItemLink>
        </Nav>
        {user &&
          <Nav>
            <Navbar.Text className="user">
              {user.firstName && user.lastName
                ? `${user.firstName} ${user.lastName.charAt(0)}.`
                : user.email}
              <Button
                variant="danger"
                size="sm"
                onClick={() => showModal('LogoutModal')}
              >
                Logout
              </Button>
            </Navbar.Text>
          </Nav>}
      </Navbar.Collapse>}
  </Navbar>
);

const mapState = state => {
  const user = state.login.toJS().result;
  return { user };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);
export default connect(mapState, mapDispatch)(TopNav);
