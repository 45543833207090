import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Row, Col, Button, Table } from 'react-bootstrap';

import PurchaseOrderNotifications from './PurchaseOrderNotifications';
import { authenticatedHref } from '../../../../api/fetch';
import Icon from '../../../ui/Icon';
import PurchaseOrderAddress from './PurchaseOrderAddress';

const formatMoney = value => numeral(value).format('0,0.00');

const sumTotalPrice = lines =>
  (lines || []).reduce((prev, { TotalPrice }) => prev + TotalPrice, 0);

const getLineSpecialInstructions = (initialValues, LineItemId) =>
  ((initialValues || {}).ItemSpecial || {})[LineItemId] || null;

const getPDFUrl = (
  {
    directory,
    filename
  },
  fileUrl
) => authenticatedHref(fileUrl || `files/${directory}/${filename}`);

const getDateOrdered = d => {
  if (!d || !moment(d).isValid()) return 'PENDING';
  return moment(d).format('MM/DD/YYYY');
};

class PurchaseOrderBodyPublic extends Component {
  render() {
    const {
      purchaseOrder,
      pdfDetails,
      fileUrl,
      shippingAddress,
      billingAddress,
      lineItemGroups,
      initialValues,
      isDeleted,
      isRevised
    } = this.props;

    const {
      VendorName,
      SumSellPrice,
      c_PurchaseOrderNumber,
      c_POSent_Date
    } = purchaseOrder || {};

    const {
      c_ShipDate,
      c_SpecialInstructions,
      CarrierName
    } = shippingAddress || {};

    return (
      <div className="purchase-order-public">
        <Row>
          <Col style={{ textAlign: 'right', margin: '10px 0' }}>
            <a href={getPDFUrl(pdfDetails || {}, fileUrl)} target="_blank">
              <Button variant="danger">
                <Icon name="download" />
                &nbsp;
                CLICK HERE TO DOWNLOAD PDF
              </Button>
            </a>
          </Col>
        </Row>
        <div className="purchase-order">
          <Row className="public-head">
            <Col>
              <img src="/logo.jpg" alt="Concept Services" />
              <h1 style={{ marginBottom: '10px' }}>Purchase Order</h1>
            </Col>
            <Col>
              <div className="public-header">
                <strong style={{ fontWeight: 'bold' }}>P.O. No.</strong>
                {c_PurchaseOrderNumber}
              </div>
              {!isDeleted &&
                <div className="public-header">
                  <strong style={{ fontWeight: 'bold' }}>Ship Date</strong>
                  {c_ShipDate && moment(c_ShipDate).isValid()
                    ? moment(c_ShipDate).format('MM/DD/YYYY')
                    : <span className="text-muted">PENDING</span>}
                </div>}
              {isDeleted &&
                <div className="public-header">
                  <strong style={{ fontWeight: 'bold' }}>Ship Date</strong>
                  <span className="text-muted">DELETED</span>
                </div>}
            </Col>
          </Row>
          <Row>
            <Col sm="auto">
              <strong className="header">P.O. No.</strong>
              {c_PurchaseOrderNumber}
              {isRevised &&
                <div>
                  <span className="action delete">
                    Revised PO - Do Not Duplicate
                  </span>
                </div>}
            </Col>
            <Col sm="auto">
              <strong className="header">Vendor</strong>
              {VendorName}
            </Col>

          </Row>
          <Table responsive className="table-items">
            <thead>
              <tr>
                <th width="50%">Bill To</th>
                <th width="50%">Ship To</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <PurchaseOrderAddress {...billingAddress} />
                </td>
                <td>
                  {!isDeleted &&
                    <PurchaseOrderAddress
                      {...shippingAddress}
                      c_PurchaseOrderNumber={c_PurchaseOrderNumber}
                      shipping
                    />}
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: 'none' }}>
                  <div className="shipping-info">
                    {isDeleted &&
                      <div>
                        <strong>Ship Date:</strong>
                        {' '}
                        DELETED
                      </div>}
                    {!isDeleted &&
                      <div>
                        <strong>Ship Date:</strong>
                        {' '}
                        {c_ShipDate && moment(c_ShipDate).isValid()
                          ? moment(c_ShipDate).format('MM/DD/YYYY')
                          : ''}
                      </div>}
                  </div>
                </td>
                <td style={{ borderTop: 'none' }}>
                  {!isDeleted &&
                    <div className="shipping-info">
                      <div>
                        <strong>Ship Via:</strong> {CarrierName}<br/>
                        <strong>Vendor:</strong> Please mark BOL with PO number
                      </div>
                    </div>}
                </td>
              </tr>
              {c_SpecialInstructions &&
                <tr>
                  <td colSpan="2" className="special-instructions">
                    <h5>
                      <Icon prefix="far" name="hand-point-right" />
                      {' '}
                      SPECIAL INSTRUCTIONS:
                    </h5>
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {c_SpecialInstructions}
                    </span>
                  </td>
                </tr>}
              <tr>
                <th colSpan={2} style={{ textAlign: 'center' }}>
                  Order Items
                </th>
              </tr>
              <tr>
                <td colSpan={2}>

                  <Table>
                    <thead>
                      <tr>
                        <th>Qty</th>
                        <th>Model</th>
                        <th>Description</th>
                        <th className="text-right">Cost (ea)</th>
                        <th className="text-right">Cost (Ext.)</th>
                      </tr>
                    </thead>

                    {!isDeleted &&
                      lineItemGroups.map(({ LineItemId, lines }, lidx) => (
                        <tbody key={lidx}>
                          {(lines || []).map(({
                            ItemNumber,
                            Quantity,
                            Model,
                            Spec,
                            NetPrice,
                            TotalPrice
                          }, index) => (
                            <tr key={index}>
                              <td>
                                {Quantity}
                              </td>
                              <td>
                                {Model}
                              </td>
                              <td>
                                {Spec}
                              </td>
                              <td className="text-right">
                                ${formatMoney(NetPrice)}
                              </td>
                              <td className="text-right">
                                ${formatMoney(TotalPrice)}
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td
                              className="text-right"
                              colSpan="4"
                              style={{ fontWeight: 'bold' }}
                            >
                              Item Total -
                            </td>
                            <td
                              className="text-right"
                              colSpan="1"
                              style={{ fontWeight: 'bold' }}
                            >
                              ${formatMoney(sumTotalPrice(lines))}
                            </td>
                          </tr>
                          {getLineSpecialInstructions(
                            initialValues,
                            LineItemId
                          ) &&
                            <tr>
                              <td colSpan="5" className="special-instructions">
                                <h5>
                                  <Icon prefix="far" name="hand-point-right" />
                                  {' '}
                                  SPECIAL INSTRUCTIONS (For Items Above):
                                </h5>
                                <span style={{ whiteSpace: 'pre-wrap' }}>
                                  {getLineSpecialInstructions(
                                    initialValues,
                                    LineItemId
                                  )}
                                </span>
                              </td>
                            </tr>}
                        </tbody>
                      ))}
                    {isDeleted &&
                      <tbody>
                        <tr>
                          <td
                            colSpan="5"
                            style={{
                              textAlign: 'center',
                              backgroundColor: '#ffebe6',
                              color: '#C23934',
                              fontWeight: 'bold',
                              fontSize: '1rem'
                            }}
                          >
                            Delete all items from original purchase order
                          </td>
                        </tr>
                      </tbody>}
                  </Table>

                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  Purchase Order Total
                </td>
                <td className="text-right">
                  ${formatMoney(SumSellPrice)}
                </td>
              </tr>
            </tfoot>
          </Table>
          <Row>
            <Col>
              <div
                style={{
                  marginTop: '20px',
                  color: '#54698D',
                  padding: '0 120px',
                  fontSize: '.8em'
                }}
              >
                This order by Concept Services, placed on
                {' '}
                {getDateOrdered(c_POSent_Date)}
                ,
                contains strictly confidential information to be solely used for the purchase of the items detailed above.
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PurchaseOrderBodyPublic;
