import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { Container, Button, Dropdown } from 'react-bootstrap';

import Table from '../../../ui/Table';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCustomersEditCompany from '../customers-companies/ModalEditCompany';
import ModalCustomersDeleteCompany
  from '../customers-companies/ModalDeleteCompany';
import ModalCustomersUploadDocuments
  from '../customers-companies-detail/ModalCustomersUploadDocuments';
import ModalDeleteCustomerDocument
  from '../customers-companies-detail/ModalDeleteCustomerDocument';
import Notification from '../../../ui/Notification';
import { downloadConceptDocument } from '../../../../api/api';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '20',
  sortBy: sortBy || 'Filename',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

const getColumns = ({ getDownloadFileDetails, getCheckedCells }) => {
  return [
    {
      Header: '',
      accessor: 'ID',
      width: 50,
      Cell: ({ original }) => (
        <input
          type="checkbox"
          defaultChecked={original.checked}
          onClick={event => getCheckedCells(original, event)}
        />
      )
    },
    {
      Header: 'Name',
      // accessor: 'Filename',
      className: 'text-center',
      Cell: ({ original }) => (
        <a
          href={original.metadata.name}
          target="_blank"
          onClick={e => {
            getDownloadFileDetails(original, e);
          }}
        >
          {original.metadata.name}
        </a>
      )
    },
    {
      Header: 'Created',
      accessor: 'uploadDate',
      className: "text-center",
      Cell: ({ value }) => <span>{moment(value).format('MMMM Do YYYY')}</span>
    },
    {
      Header: 'Size',
      accessor: 'length',
      className: 'text-center',
      Cell: ({ value }) => <span>{humanFileSize(value)}</span>

    },
    {
      Header: 'Kind',
      className: 'text-center',
      Cell: ({ original }) => <span>{original.metadata.kind}</span>
    }
  ];
};

const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

class CustomersCompaniesDocuments extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = {
      selectedDocuments: []
    }
  }
  componentDidMount() {
    const {
      match,
      getCompanyById,
      getCompanyLookups,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getCompanyById(
      { id, query: { getUsers: 1, getLocations: 1, getConceptDocuments: 1 } },
      null,
      true
    );
    getCompanyLookups({ companyTypes: 1 });
    searchCustomersCompanies();
  }

  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }

  uploadCompanyDocuments = (values) => {
    const { csv } = values;
    const {
      uploadConceptDocument,
      match: { params: { id } },
      getCompanyById,
      getCompanyLookups,
      searchCustomersCompanies,
      closeModal
    } = this.props;
    uploadConceptDocument({ csv, companyId: id }, 'customers-upload-document-form', null, () => {
      closeModal('modalUploadCustomerDocument');
      getCompanyById(
        { id, query: { getUsers: 1, getLocations: 1, getConceptDocuments: 1 } },
        null,
        true
      );
      getCompanyLookups({ companyTypes: 1 });
      searchCustomersCompanies();
    })
  }
  render() {
    const {
      inProgress,
      result,
      showModal,
      match,
      getConceptsLookupsResult,
      putCompanyById,
      getCompanyById,
      deleteCompanyById,
      history,
      getCompanyResults,
      location: { search: searchURL },
      clearGetCompanyById,
      clearGetCompanyLookups,
      getCompanyByIdError,
      getConceptsLookupsError,
      searchCustomersCompaniesError,
      clearSearchCustomersCompanies,
      clearDeleteCompanyById,
      deleteCompanyByIdError,
      clearPutCompanyById,
      putCompanyByIdError,
      deleteCompanyDocuments,
      clearDeleteCompanyDocuments
    } = this.props;

    const { documents, company } = result || {};

    const {
      name,
      companyTypeID,
      notes,
      created,
      id,
      jobRefNum,
      supportEmail,
      freightMarkup
    } = company || {};

    const companyYears = moment().diff(moment(created), 'years');

    const initialValues = {
      name,
      companyTypeID,
      notes,
      jobRefNum,
      supportEmail,
      freightMarkup,
      id
    };

    const {
      companyTypes
    } = getConceptsLookupsResult || {};

    const handleSubmitUpdateCompany = values => {
      putCompanyById(
        { id, payload: values },
        'create-customers-company-form',
        null,
        () => {
          getCompanyById(
            { id, query: { getUsers: 1, getLocations: 1 } },
            null,
            true
          );
        }
      );
    };

    const handleDeleteCompany = () => {
      deleteCompanyById(id, null, null, () => {
        history.push(`/customers/concepts`);
      });
    };

    const handleDeleteCompanyDoc = () => {
      let selectedDocuments = this.state.selectedDocuments;
      if (selectedDocuments.length === 0) return;

      deleteCompanyDocuments({ selectedDocuments }, null, null, () => {
        this.setState({
          selectedDocuments: []
        })
        getCompanyById(
          {
            id,
            query: { getUsers: 1, getLocations: 1, getConceptDocuments: 1 }
          },
          null,
          true
        );
      });
    };
    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];
    const getDownloadFileDetails = (original, e) => {
      e.preventDefault();
      downloadConceptDocument({ id: original._id, payload: original });
    };
    const getCheckedCells = (original, event) => {
      event.preventDefault();
      const { _id } = original;
      if (event.target.checked) {
        let selectedDocuments = this.state.selectedDocuments;
        selectedDocuments.splice(0, 0, { id: _id });
        this.setState({ selectedDocuments: selectedDocuments });
      } else {
        let selectedDocuments = this.state.selectedDocuments;
        selectedDocuments = selectedDocuments.filter((doc) => doc.id !== _id);
        this.setState({ selectedDocuments: selectedDocuments });
      }
    };

    const columns = getColumns({ getDownloadFileDetails, getCheckedCells });
    const { id: companyId } = match.params || {};


    const renderDocuments = (documents || []).map((document) => {
      const selectedDocuments = this.state.selectedDocuments;
      const selectedDoc = selectedDocuments.find((doc) => doc.id === document._id);
      return selectedDoc ? { ...document, checked: true } : document;
    })

    return (
      <Layout route="customers-companies-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/concepts" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {name || 'Loading...'}
              </h2>
              <h3>
                {!inProgress &&
                  <span>
                    Concept for
                    {' '}
                    {companyYears === undefined ? '-' : companyYears}
                    {' '}
                    years
                  </span>}
                &nbsp;
              </h3>
            </div>

            <div>
              <Button
                variant="danger"
                size="sm"
                onClick={() => showModal('modalDeleteCustomerDocument')}
              >
                Delete
              </Button>
              {' '}
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalUploadCustomerDocument')}
              >
                Upload
              </Button>
              {' '}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  <Icon fixedWidth name="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyEdit')}
                  >
                    Edit Concept
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyDelete')}
                  >
                    Delete Concept
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/concepts/${companyId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/users`}>Users</Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/products`}>
                  Products
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/packages`}>
                  Packages
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/locations`}>
                  Locations
                </Link>
              </li>
              <li className="active">
                <Link to={`/customers/concepts/${companyId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        {!inProgress &&
          <Container>
            <Table
              showSearch
              data={renderDocuments}
              noDataText="No documents found"
              columns={columns}
              search={search}
              onFetchData={this.handleTableChange}
              onSearchChange={this.handleSearchChange}
              defaultSorted={sorted}
              page={getCompanyByIdError ? 0 : Number(page)}
              defaultPageSize={Number(pageSize)}
              inProgress={inProgress}
            />
          </Container>}

        <ModalCustomersEditCompany
          name="modalCustomersCompanyEdit"
          initialValues={initialValues}
          companyTypes={companyTypes}
          handleSubmitUpdateCompany={handleSubmitUpdateCompany}
          putCompanyByIdError={putCompanyByIdError}
          clearPutCompanyById={clearPutCompanyById}
        />
        <ModalCustomersDeleteCompany
          name="modalCustomersCompanyDelete"
          handleDeleteCompany={handleDeleteCompany}
          deleteCompanyByIdError={deleteCompanyByIdError}
          clearDeleteCompanyById={clearDeleteCompanyById}
        />
        <ModalCustomersUploadDocuments
          name="modalUploadCustomerDocument"
          companyId={companyId}
          history={history}
          uploadCompanyDocuments={this.uploadCompanyDocuments}
        />
        <ModalDeleteCustomerDocument
          name="modalDeleteCustomerDocument"
          handleDeleteCompanyDoc={handleDeleteCompanyDoc}
        />
        {getCompanyByIdError &&
          <Notification
            key="users-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyById}
          >
            Documents failed to load. Please refresh the page.
          </Notification>}
        {getConceptsLookupsError &&
          <Notification
            key="options-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyLookups}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getCompanyByIdInProgress,
    error: getCompanyByIdError
  } = state.getCompanyById.toJS();

  const {
    result: getConceptsLookupsResult,
    inProgress: getConceptsLookupsInProgress,
    error: getConceptsLookupsError
  } = state.getConceptsLookups.toJS();

  const {
    result: getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    result: deleteCompanyByIdResult,
    inProgress: deleteCompanyByIdInProgress,
    error: deleteCompanyByIdError
  } = state.deleteCompanyById.toJS();

  const {
    result: putCompanyByIdResult,
    inProgress: putCompanyByIdInProgress,
    error: putCompanyByIdError
  } = state.putCompanyById.toJS();

  const {
    result: deleteCompanyDocumentsResult,
    inProgress: deleteCompanyDocumentsInProgress,
    error: deleteCompanyDocumentsError
  } = state.deleteCompanyDocuments.toJS();

  return {
    result,
    getConceptsLookupsResult,
    inProgress: getCompanyByIdInProgress,
    getCompanyByIdError,
    getCompanyResults,
    getConceptsLookupsError,
    searchCustomersCompaniesError,
    deleteCompanyByIdError,
    putCompanyByIdError,
    deleteCompanyDocumentsError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction,
      getCompanyLookups: fetchDux.getConceptsLookups.createAction,
      clearGetCompanyLookups: fetchDux.getConceptsLookups.clearAction,
      putCompanyById: fetchDux.putCompanyById.createAction,
      clearPutCompanyById: fetchDux.putCompanyById.clearAction,
      deleteCompanyById: fetchDux.deleteCompanyById.createAction,
      clearDeleteCompanyById: fetchDux.deleteCompanyById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction,
      deleteCompanyDocuments: fetchDux.deleteCompanyDocuments.createAction,
      clearDeleteCompanyDocuments: fetchDux.deleteCompanyDocuments.clearAction,
      uploadConceptDocument: fetchDux.uploadConceptDocument.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersCompaniesDocuments);
