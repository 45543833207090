import React, { Component } from 'react';
import { Button, Row, Col, Dropdown } from 'react-bootstrap';
import statusDict from '../statusDict';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';

const getStatusColor = (status, target, color1, color2) => {
  if (status < target) return '';
  if (status === target) return color1;
  if (status > target) return color2;
};

class CustomToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <Button onClick={this.handleClick}>
        {this.props.children}
      </Button>
    );
  }
}

const ProjectMenu = ({ user, status, projectId, confirmDeleteProject }) => {
  const { isAdmin } = user || {};
  const canDelete = status < statusDict.INSERT_INPROGRESS || isAdmin;
  return (
    <Dropdown
      style={{ fontSize: '14px', marginLeft: '5px' }}
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={CustomToggle}
        variant="primary"
        id="dropdown-pr-menu"
      >
        <Icon name="bars" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Link className="dropdown-item" to={`/projects/${projectId}/logs`}>
          View Logs
        </Link>
        {canDelete &&
          <Dropdown.Item onClick={confirmDeleteProject}>
            Delete Project
          </Dropdown.Item>}
      </Dropdown.Menu>
    </Dropdown>
  );
};

class ProjectStatusBar extends Component {
  shouldComponentUpdate(nextProps) {
    const { status: a1, projectId: b1 } = nextProps || {};
    const { status: a2, projectId: b2 } = this.props;
    return a1 !== a2 || b1 !== b2;
  }
  render() {
    const {
      user,
      status,
      projectId,
      minimal,
      confirmDeleteProject
    } = this.props;

    return (
      <Row className="project-status-bar no-gutters">
        <Col md="auto">
          <ul>
            <li
              className={getStatusColor(
                status === 13 ? 1 : status,
                statusDict.INVALID,
                'red',
                'green'
              )}
            >
              Project Validated
            </li>
            <li
              className={getStatusColor(
                status === 13 ? 1 : status,
                statusDict.APPROVE_INPROGRESS,
                'blue',
                'green'
              )}
            >
              Items Approved
            </li>
            <li
              className={getStatusColor(
                status === 13 ? 1 : status,
                statusDict.INSERT_INPROGRESS,
                'blue',
                'green'
              )}
            >
              Order Inserted & Purchase Orders Created
            </li>
            <li
              className={getStatusColor(
                status === 13 ? 1 : status,
                statusDict.SEND_INPROGRESS,
                'blue',
                'green'
              )}
            >
              Purchase Orders Sent
            </li>
            <li
              className={getStatusColor(
                status === 13 ? 1 : status,
                statusDict.SEND_COMPLETE,
                'blue',
                'green'
              )}
            >
              Finalize Sales Order
            </li>
          </ul>
        </Col>
        {!minimal &&
          <Col style={{ textAlign: 'right' }}>
            <ProjectMenu
              user={user}
              status={status}
              projectId={projectId}
              confirmDeleteProject={confirmDeleteProject}
            />
          </Col>}
      </Row>
    );
  }
}

export default ProjectStatusBar;
