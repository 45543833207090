import React from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Button, Row, Col, Table } from 'react-bootstrap';
import AccordionCard from '../../shipments/shipments-detail/AccordionCard';
import FormField from '../../../forms/FormField';
import { commodityClassOptions } from '../freight-calc/FreightCalcForm';
import Icon from '../../../ui/Icon';
import ReactTableToExcel from 'react-html-table-to-excel';

const getNameParts = name => {
  const [p1, p2] = (name || '').split('.') || [];
  const [a, x1] = (p1 || '').split('[');
  const [b, x2] = (p2 || '').split('[');

  return {
    vendorIndex: parseInt(x1),
    itemsIndex: parseInt(x2)
  };
};

const getExcludedValue = (
  vendors,
  {
    vendorIndex,
    itemsIndex
  }
) => {
  try {
    const vendor = vendors[vendorIndex] || {};
    const { items } = vendor || {};
    const item = items[itemsIndex] || {};
    return item.exclude || false;
  } catch (err) {
    return false;
  }
};

const optionalRequired = (value, formValues, form, name) => {
  const { vendors } = formValues || {};

  const {
    vendorIndex,
    itemsIndex
  } = getNameParts(name);

  const isExcluded = getExcludedValue(vendors, {
    vendorIndex,
    itemsIndex
  });

  if (isExcluded) return undefined;

  return value ? undefined : 'Required';
};

const renderItems = ({ fields }) => (
  <tbody>
    {fields.map((item, index) => (
      <tr key={index}>
        <td>{fields.get(index).Model}</td>
        <td>
          <Field
            name={`${item}.quantity`}
            component={FormField.Input}
            validate={[optionalRequired]}
            hardTouched
          />
        </td>
        <td>
          <Field
            name={`${item}.weight`}
            component={FormField.Input}
            validate={[optionalRequired]}
            hardTouched
          />
        </td>
        <td>
          <Field
            name={`${item}.commodityClass`}
            component={FormField.SelectHTML}
            validate={[optionalRequired]}
            hardTouched
          >
            {(commodityClassOptions || []).map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Field>
        </td>
        <td>
          <Field
            name={`${item}.originZip`}
            component={FormField.Input}
            validate={[optionalRequired]}
            hardTouched
          />
        </td>
        <td>
          <Field
            name={`${item}.destinationZip`}
            component={FormField.Input}
            validate={[optionalRequired]}
            hardTouched
          />
        </td>
        <td className="nowrap">
          <Row>
            <Col sm={5}>
              <Field
                label="Liftgate"
                name={`${item}.deliveryLiftgate`}
                component={FormField.InlineCheckbox}
              />
            </Col>
            <Col sm={7}>
              <Field
                label="Inside Placement"
                name={`${item}.deliveryInside`}
                component={FormField.InlineCheckbox}
              />
            </Col>
          </Row>
        </td>
        <td className="text-center">
          <Field
            name={`${item}.exclude`}
            component={FormField.InlineCheckbox}
          />
        </td>
      </tr>
    ))}
  </tbody>
);

const renderVendors = ({ fields, change }) => (
  <div>
    {fields.map((vendor, idx) => {
      const { label, items, excludeAll } = fields.get(idx);
      const hasErr = items.filter(
        d =>
          !d.exclude &&
          (!d.weight ||
            !Number(d.commodityClass) ||
            !d.originZip ||
            !d.destinationZip)
      );
      // event, newValue, prevValue, name
      const toggleChange = (event, value) => {
        for (const i in items) {
          change(`${vendor}.items[${i}].exclude`, value);
        }
      };
      const checked = items.length === items.filter(d => !!d.exclude).length;
      if (checked !== excludeAll) change(`${vendor}.excludeAll`, checked);
      return (
        <AccordionCard
          key={idx}
          title={`${label} (${items.length})`}
          isOpen={false}
          hasError={!!hasErr.length}
        >
          <Table bordered>
            <thead>
              <tr>
                <th>Items</th>
                <th width="100">Quantity</th>
                <th width="100">Weight</th>
                <th className="nowrap">Commodity Class</th>
                <th className="nowrap">Origin Zip</th>
                <th className="nowrap">Destination Zip</th>
                <th className="nowrap">Accessorial Options<br />Delivery</th>
                <th>
                  Exclude
                  <Field
                    name={`${vendor}.excludeAll`}
                    component={FormField.InlineCheckbox}
                    onChange={toggleChange}
                  />
                </th>
              </tr>
            </thead>
            <FieldArray name={`${vendor}.items`} component={renderItems} />
          </Table>
        </AccordionCard>
      );
    })}
  </div>
);

const UploadResultForm = props => {
  const {
    showDownload,
    handleSubmit,
    submitting,
    change,
    valid
  } = props;
  return (
    <div className="freight-upload-result">
      {!valid &&
        <div className="pb-3 info" style={{ fontWeight: 'bold' }}>
          <Icon name="exclamation-triangle" />
          {` `}
          Items in red need additional information
        </div>}
      <form onSubmit={handleSubmit}>
        <FieldArray
          name="vendors"
          component={renderVendors}
          props={{ change }}
        />
        <div className="py-3">
          <Button
            variant="primary"
            type="submit"
            disabled={!valid || submitting}
          >
            Get Rate Quote and Transit Time
          </Button>
          {showDownload &&
            <ReactTableToExcel
              className="float-right btn btn-primary btn-sm"
              table="freight-result"
              filename={`freight-quote-${new Date().getTime()}`}
              sheet="Sheet"
              buttonText="Export to Excel"
            />}
        </div>
      </form>
    </div>
  );
};

export default reduxForm({
  form: 'freight-upload-result-form',
  validate: (values, ...props) => {
    const errors = {};
    const { vendors } = values || {};
    const hasErr = (vendors || []).some(({
      items
    }) => {
      // if (excludeAll) return false;
      return items.some(
        d =>
          !d.exclude &&
          (!d.weight ||
            !Number(d.commodityClass) ||
            !d.originZip ||
            !d.destinationZip)
      );
    });
    if (hasErr) {
      errors._error = 'Invalid';
    }

    return errors;
  }
})(UploadResultForm);
