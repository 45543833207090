import React, { Component } from 'react';
import ReactTable from 'react-table';
import {
  Form,
  DropdownButton,
  Dropdown,
  Row,
  Col,
  Button
} from 'react-bootstrap';
import Icon from './Icon';
import 'react-table/react-table.css';
import withFixedColumns from 'react-table-hoc-fixed-columns';
import 'react-table-hoc-fixed-columns/lib/styles.css'; // important: this line must be placed after react-table css import
import { Parser } from 'json2csv';
import download from 'downloadjs';
import moment from 'moment';
import { parse } from 'query-string';
import "react-table/react-table.css";
import withDraggableColumns from './../../libs/index';
import './../../libs/styles.scss';
const downloadCsv = ({ data, columns, filename, exportWithHeaderData }) => {
  const fields = columns.filter(c => !!c.accessor).map(c => ({
    label: c.Header,
    value: c.accessor
  }));
  const parser = new Parser({ fields });
  const csv = exportWithHeaderData
    ? `${exportWithHeaderData}${parser.parse(data)}`
    : parse.parse(data);
  download(csv, filename, 'text/csv; charset=utf-8');
  return csv;
};

const getFileName = () => {
  const now = moment();
  return `report-${now.format('YYYY-MM-DD hh:mm')}.csv`;
};

const ReactTableSticky = withFixedColumns(ReactTable);
const ReactTableDraggable = withDraggableColumns(ReactTable);

const filterData = ({ columns, search, data }) => {
  const s = (search || '').toLowerCase().replace(/\s/g, '');
  const keys = columns.map(({ accessor }) => accessor);
  return (data || []).filter(d => {
    const str = keys
      .reduce((prev, key) => `${prev}${d[key] || ''}`, '')
      .toLowerCase()
      .replace(/\s/g, '');
    return str.indexOf(s) !== -1;
  });
};

const CopyButton = () => {
  const onClick = () => navigator.clipboard.writeText(window.location.href);

  return (
    <Button
      size="sm"
      onClick={onClick}
      style={{ display: 'inline', marginRight: '5px' }}
      variant="outline-secondary"
    >
      Copy Link
    </Button>
  );
};

const ColumnButton = (
  {
    formattedColumns,
    toggleColumn
  }
) => (
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="vendor-columns"
    style={{ display: 'inline' }}
    title="Columns"
    alignRight
    className="checkbox-dropdown"
  >
    {formattedColumns
      .filter(({ show, hideColumnDropdown }) => show && !hideColumnDropdown)
      .map(({
        Header,
        id,
        accessor
      }, index) => (
        <div
          key={index}
          className="dropdown-item"
          onClick={() =>
            toggleColumn({
              show: false,
              columns: [id || accessor]
            })}
        >
          <Icon name="toggle-on" />
          {Header}
        </div>
      ))}
    {formattedColumns.filter(
      ({ show, hideColumnDropdown }) => !show && !hideColumnDropdown
    ).length > 0 && <Dropdown.Divider />}
    {formattedColumns
      .filter(({ show, hideColumnDropdown }) => !show && !hideColumnDropdown)
      .map(({
        Header,
        id,
        accessor
      }, index) => (
        <div
          key={index}
          className="dropdown-item"
          onClick={() =>
            toggleColumn({
              show: true,
              columns: [id || accessor]
            })}
        >
          <Icon name="toggle-off" />
          {Header}
        </div>
      ))}
  </DropdownButton>
);

const ExportButton = (
  {
    onExport,
    onExportAll
  }
) => (
  <DropdownButton
    variant="outline-secondary"
    size="sm"
    id="export-columns"
    title="Export"
    alignRight
    className="checkbox-dropdown"
    style={{ display: 'inline', marginRight: '5px' }}
  >
    <div className="dropdown-item" onClick={() => onExport ? onExport() : null}>
      Export Screen View
    </div>
    <div
      className="dropdown-item"
      onClick={() => onExportAll ? onExportAll() : null}
    >
      Export All Data
    </div>
  </DropdownButton>
);

class Table extends Component {
  constructor(props) {
    super(props);
    const { columns } = props;
    const activeColumns = (columns || [])
      .filter(({ show }) => show !== false)
      .map(({ id, accessor }) => id || accessor);
    this.state = { activeColumns };
    this.toggleColumn = this.toggleColumn.bind(this);
    if (this.props.onColumnChange) this.props.onColumnChange(activeColumns);
  }
  toggleColumn(
    {
      show,
      columns
    }
  ) {
    const { activeColumns } = this.state;
    if (show === true) {
      const next = columns.filter(c => !activeColumns.some(a => a === c));
      if (this.props.onColumnChange) this.props.onColumnChange([...activeColumns, ...next]);
      return this.setState({ activeColumns: [...activeColumns, ...next] });
    }

    const next = activeColumns.filter(a => !columns.some(c => c === a));
    if (this.props.onColumnChange) this.props.onColumnChange(next);
    this.setState({ activeColumns: next });
  }
  render() {
    const {
      data,
      fullData,
      columns,
      onSearchChange,
      showSearch,
      search,
      serverSearch,
      showColumnsSelector,
      sticky,
      inProgress,
      tableRef,
      showNumResults,
      showExport,
      showCopy,
      showNote,
      exportWithHeaderData,
      keepTable,
      totalResults,
      filterDataFromSearch,
      noResultsText,
      draggableColumns,
      listDraggableColumns,
      dragCompleteHandler,
      onDropComplete,
      getStatusLabel,
      getColumnsOrder,
      ...props
    } = this.props;
    const { activeColumns } = this.state;
    // const dragTableRef = React.createRef();

    const getSortedData = () => {
      const { current } = tableRef || {};
      if (!current) return;
      const { wrappedInstance } = current;
      const tableState = draggableColumns ? wrappedInstance.getResolvedState() : current.getResolvedState();
      const { sortedData } = tableState || {};
      return (sortedData || []).map(d => {
        return (columns || []).reduce((prev, next) => {
          const { accessor, formatExport } = next || {};
          if (accessor === 'DeliveryStatus' && getStatusLabel) {
            const value = formatExport
              ? formatExport(getStatusLabel(d[accessor]) || null)
              : getStatusLabel(d[accessor]) || null;
            return { ...prev, [accessor]: value };
          } else {
            const value = formatExport
              ? formatExport(d[accessor] || null)
              : d[accessor] || null;
            return { ...prev, [accessor]: value };
          }

        }, {});
      });
    };

    const filteredData = filterDataFromSearch ? filterData({
      columns,
      search: serverSearch ? '' : search,
      data
    }) : data;

    const formattedColumns = columns.map(column => {
      const show = activeColumns.some(
        a => a === (column.id || column.accessor)
      );
      return Object.assign({}, column, { show });
    });

    const onExport = () => {
      const filteredColumns = columns.filter(column =>
        activeColumns.some(a => a === (column.id || column.accessor)));
      const sortedData = getSortedData();
      const data = sortedData ? sortedData : fullData;
      if (draggableColumns && getColumnsOrder) {
        const columnsOrder = getColumnsOrder();
        columnsOrder.forEach((c_accessor, index) => {
          const currentIndex = filteredColumns.findIndex(({ accessor }) => c_accessor === accessor);
          if (currentIndex !== index && currentIndex !== -1) {
            const element = filteredColumns[currentIndex];
            filteredColumns.splice(currentIndex, 1);
            filteredColumns.splice(index, 0, element);
          }
        })
      }
      downloadCsv({
        columns: filteredColumns,
        data,
        filename: getFileName(),
        exportWithHeaderData
      });
    };

    const onExportAll = () => {
      const sortedData = getSortedData();
      const data = sortedData ? sortedData : fullData;
      if (draggableColumns && getColumnsOrder) {
        const columnsOrder = getColumnsOrder();
        columnsOrder.forEach((c_accessor, index) => {
          const currentIndex = columns.findIndex(({ accessor }) => c_accessor === accessor);
          if (currentIndex !== index) {
            const element = columns[currentIndex];
            columns.splice(currentIndex, 1);
            columns.splice(index, 0, element);
          }
        })
      }
      downloadCsv({
        columns,
        data,
        filename: getFileName(),
        exportWithHeaderData
      });
    };

    if (draggableColumns && !!!listDraggableColumns)
      throw new Error('Provide the list of draggable columns');

    return (
      <div>
        {(showSearch || showColumnsSelector) &&
          <Row className="table-actions">
            {showSearch &&
              <Col>
                <Form.Control
                  type="text"
                  size="sm"
                  placeholder="Search your results..."
                  className="table-search"
                  onChange={e => onSearchChange(e.target.value)}
                  value={search}
                />
              </Col>}
            {showNumResults &&
              totalResults === 0 &&
              noResultsText && !inProgress &&
              <Col>
                {noResultsText}
              </Col>
            }
            {showNumResults && totalResults !== 0 &&
              <Col>
                Results: {totalResults ? totalResults : (data || []).length}
              </Col>
            }
            {/* {showNote &&
              <Col>
                {showNote}
              </Col>} */}
            {(showColumnsSelector || showExport || showCopy || showNote) &&
              <Col className="text-right">
                {showNote && showNote}
                {showCopy && <CopyButton />}
                {showExport &&
                  <ExportButton
                    onExport={onExport}
                    onExportAll={onExportAll}
                  />}
                {showColumnsSelector &&
                  <ColumnButton
                    formattedColumns={formattedColumns}
                    toggleColumn={this.toggleColumn}
                  />}

              </Col>}
          </Row>}
        {
          draggableColumns &&
          (keepTable || !inProgress) &&
          <React.Fragment>
            <ReactTableDraggable
              draggableColumns={{
                enableColumnWideDrag: true,
                mode: 'reorder',
                draggable: listDraggableColumns,
                useDragImage: true,
                onDropSuccess: () => {
                  const current = tableRef.current;
                  onDropComplete(current.reorder, current.currentColumnOrder);
                }
              }}
              data={filteredData || []}
              columns={formattedColumns || []}
              ref={tableRef || null}
              {...props}
            />
          </React.Fragment>

        }
        {!sticky && !draggableColumns &&
          (keepTable || !inProgress) &&
          <ReactTable
            data={filteredData || []}
            columns={formattedColumns || []}
            ref={tableRef || null}
            {...props}
          />}
        {sticky && !draggableColumns &&
          (keepTable || !inProgress) &&
          <ReactTableSticky
            data={filteredData || []}
            columns={formattedColumns || []}
            ref={tableRef || null}
            {...props}
          />}
      </div>
    );
  }
}

export default Table;
