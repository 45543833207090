import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Container, Button } from 'react-bootstrap';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
// import OrderStatus from '../../../ui/OrderStatus';
import numeral from 'numeral';

import ModalProcessEcommerceOrder from './ModalProcessEcommerceOrder';
import FormLocationSearch from './FormLocationSearch';
import Loading from '../../../ui/Loading';
import paymentOptionDict from '../paymentOptionDict';
import { getLocationsByOrder } from './../../../../api/api';
import ProjectStatusBar from '../../projects/projects-detail/ProjectStatusBar';

class EcommerceOrdersDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { toggleLocationEdit: false };
  }

  componentDidMount() {
    const { match: { params }, getOrderById } = this.props;
    const { id } = params;
    getOrderById(id);
  }

  loadLocations = value => {
    if ((value || '').length < 2) {
      return new Promise(resolve => {
        resolve([]);
      });
    }
    const { match: { params } } = this.props;
    const { id } = params;
    return getLocationsByOrder({ search: value, projectId: id });
  };

  render() {
    const { toggleLocationEdit } = this.state;
    const {
      result,
      inProgress,
      updateOrderLocation,
      getOrderById,
      match: { params: { id } },
      processOrders,
      history
    } = this.props;
    const {
      jobRef,
      orderDate,
      FirstName,
      LastName,
      Address1,
      Address2,
      City,
      Zip,
      Email,
      CardNumber,
      NameOnCard,
      ExpiryMonth,
      ExpiryYear,
      BusinessNameOnCheck,
      CheckNumber,
      BankName,
      NameOnAccount,
      PurchaseOrderNumber,
      PurchaseOrderDate,
      PurchaseOrderApprovedBy,
      ShipViaId,
      shoppingCartItems,
      tax,
      freight,
      subTotal,
      Status,
      projectId,
      State,
      location,
      c_SageIntegrationStatus,
      project,
      payments,
      emailLogs,
      couponResult
    } = result || {};

    const { Code, Amount = 0 } = couponResult || {};

    const {
      concept,
      Name,
      Address1: locationAddress1,
      Address2: locationAddress2,
      City: locationCity,
      PostalCode,
      StateProvince
    } = location || {};

    const toggleLocationForm = () =>
      this.setState({ toggleLocationEdit: !toggleLocationEdit });

    const total = (subTotal || 0) + (freight || 0) + (tax || 0) - Amount;

    const {
      c_ConvenienceFee: convenienceFee,
      c_ConvenienceRate: convenienceRate,
      FreightMarkupPercentage,
      FreightMarkup,
      FreightNet,
      FreightSell
    } = project || {};

    const [payment] = payments || [];

    const { PaymentOption, OrderStatus } = payment || {};

    return (
      <Layout route="ecommerce-orders-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/ecommerce-orders" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                Order Number {id}
              </h2>
              <h3>
                {!inProgress &&
                  <span>{(shoppingCartItems || []).length} items</span>}
              </h3>
            </div>
            <div>
              <Button
                variant="primary"
                size="sm"
                style={{ marginRight: '10px' }}
                onClick={() => history.push(`/projects/${id}`)}
                disabled={inProgress}
              >
                View Detail
              </Button>
              {Status === 'Pending' &&
                <Button
                  variant="primary"
                  size="sm"
                  onClick={() => {
                    processOrders({ orders: [projectId] }, null, null, () => {
                      getOrderById(id);
                    });
                  }}
                  disabled={inProgress}
                >
                  Process Order
                </Button>}
            </div>

          </Container>
        </div>
        {inProgress
          ? <Loading />
          : <Container>
            <Row>
              <Col md={12}>
                <ProjectStatusBar
                  minimal
                  status={c_SageIntegrationStatus}
                  projectId={projectId}
                />
              </Col>
              <Col md={6}>
                <div className="box-bordered">
                  <h4>Order Details</h4>
                  <table>
                    <tbody>
                      <tr>
                        <th>
                          Order Status
                        </th>
                        {/* <td><OrderStatus status={Status} /></td> */}
                        <td>{OrderStatus}</td>
                      </tr>
                      <tr>
                        <th>
                          Confirmation Sent To
                        </th>
                        <td>
                          {(emailLogs || [])
                            .map(({ EmailRecipient }) => (
                              <div>{EmailRecipient}</div>
                            ))}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Order Number
                        </th>
                        <td>
                          {projectId}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Shipping To
                        </th>
                        <td>
                          {FirstName} {LastName}<br />
                          {Address1}, {Address2}<br />
                          {City}, {State}, {Zip}<br />
                          {/* {location.name} - Store #{location.id} */}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          Payment Method
                        </th>
                        <td>
                          {paymentOptionDict[PaymentOption]}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="box-bordered">

                  {location &&
                    <Button
                      className="float-right"
                      size="sm"
                      variant="outline-primary"
                      onClick={() => toggleLocationForm()}
                    >
                      Edit
                    </Button>}

                  <h4>Associated Location</h4>

                  {!toggleLocationEdit &&
                    <div>

                      {location &&
                        <table>
                          <tbody>
                            <tr>
                              <th>
                                <Icon name="flag" /> Location Name
                              </th>
                              <td>{Name}</td>
                            </tr>
                            <tr>
                              <th>
                                <Icon name="map-marker" /> Address
                              </th>
                              <td>
                                {locationAddress1}, {locationAddress2}<br />
                                {locationCity}, {StateProvince}, {PostalCode}
                              </td>
                            </tr>
                            <tr>
                              <th>
                                <Icon name="building" /> Concept
                              </th>
                              <td>{concept}</td>
                            </tr>
                          </tbody>
                        </table>}

                      {!location &&
                        <Button
                          variant="outline-primary"
                          onClick={() => toggleLocationForm()}
                        >
                          <Icon name="plus" /> Add location to this order
                        </Button>}
                    </div>}
                  {toggleLocationEdit &&
                    <FormLocationSearch
                      handleClose={toggleLocationForm}
                      loadLocations={this.loadLocations}
                      onSubmit={values => {
                        const { location: locationId } = values;
                        updateOrderLocation(
                          { projectId, payload: { locationId } },
                          null,
                          null,
                          () => {
                            toggleLocationForm();
                            getOrderById(projectId);
                          }
                        );
                      }}
                    />}

                </div>

              </Col>
              <Col md={6}>

                <div className="box-bordered">

                  <h4>Order Summary</h4>

                  <div className="shopping-cart">
                    {(shoppingCartItems || []).map((p, index) => {
                      return (
                        <div key={index} className="product">
                          <div
                            className="image"
                            style={{ backgroundImage: `url(${p.imageHref})` }}
                          >
                            <span className="qty">{p.qty}</span>
                          </div>
                          <div className="detail">
                            <strong>{p.model}</strong>
                            <p>{p.category && p.category.categoryName}</p>
                          </div>
                          <div>
                            ${numeral(p.fullPrice).format('0,0.00')}
                            <span />
                          </div>
                        </div>
                      );
                    })}

                    <table className="table-subtotal">
                      <tbody>
                        <tr>
                          <th>Subtotal</th>
                          <td>
                            ${numeral(subTotal || 0).format('0,0.00')}
                          </td>
                        </tr>
                        {
                          couponResult &&
                          <tr>
                            <th>{`Discount ( ${Code} )`}</th>
                            <td>{`-$${Amount}`}</td>
                          </tr>
                        }
                        <tr>
                          <th>
                            Freight (
                            {numeral(FreightMarkupPercentage || 0).format(
                              '0.00%'
                            )}
                            )
                          </th>
                          <td>
                            ${numeral(FreightSell || 0).format('0,0.00')}
                          </td>
                        </tr>
                        <tr>
                          <th>Tax</th>
                          <td>${numeral(tax || 0).format('0,0.00')}</td>
                        </tr>
                        {PaymentOption === 1 &&
                          <tr>
                            <th>
                              Convenience Fee (
                              {numeral(convenienceRate || 0).format('0.00%')}
                              )
                            </th>
                            <td>
                              ${numeral(convenienceFee || 0).format('0,0.00')}
                            </td>
                          </tr>}

                      </tbody>
                      <tfoot>
                        <tr>
                          <th>Total</th>
                          <td>
                            $
                            {numeral((total < 0 ? 0 : total) + (convenienceFee < 0 ? 0 : convenienceFee)).format('0,0.00')}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>

              </Col>
            </Row>
          </Container>}

        <ModalProcessEcommerceOrder name="modalProcessEcommerceOrder" />
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getOrderById.toJS();

  return {
    result,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getOrderById: fetchDux.getOrderById.createAction,
      updateOrderLocation: fetchDux.updateOrderLocation.createAction,
      processOrders: fetchDux.processOrders.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(EcommerceOrdersDetail);
