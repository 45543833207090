import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { isValid, isSubmitting, submit } from 'redux-form';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import PurchaseOrderNotifications from './PurchaseOrderNotifications';
import { authenticatedHref } from '../../../../api/fetch';
import Icon from '../../../ui/Icon';
import { Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import ConnectedField from '../../../forms/ConnectedField';
import EditProjectShippingForm
  from '../projects-detail/EditProjectShippingForm';

const formatMoney = value => numeral(value).format('0,0.00');

const sumTotalPrice = lines =>
  (lines || []).reduce((prev, { TotalPrice }) => prev + TotalPrice, 0);

const getPDFUrl = (
  {
    directory,
    filename
  },
  fileUrl
) => authenticatedHref(fileUrl || `files/${directory}/${filename}`);

class PurchaseOrderBodyEdit extends Component {
  render() {
    const {
      LastVendorName,
      purchaseOrder,
      pdfDetails,
      fileUrl,
      showEdit,
      toggleEditing,
      inProgress,
      shippingAddress,
      billingAddress,
      lineItemGroups,
      initialValues,
      submitting,
      submitForm,
      valid,
      onPostPurchaseOrderAddress,
      lookups,
      result,
      hidePDF
    } = this.props;

    const {
      ID,
      VendorName,
      VendorNum,
      VendorEmail,
      SumSellPrice,
      c_PurchaseOrderAddressId,
      BillToAddressId,
      c_Approved,
      c_SelectedPurchaseOrderSageVendorID,
      VendorId,
      c_PurchaseOrderNumber,
      c_POSent,
      c_POSent_Date,
      c_IsColorado,
      VendorIsColorado,
      VendorIsColoradoType
    } = purchaseOrder || {};

    const {
      c_ShipDate,
      c_SpecialInstructions,
      CarrierName
    } = shippingAddress || {};

    return (
      <div className="purchase-order">
        <div>
          <PurchaseOrderNotifications VendorName={LastVendorName} />
          {c_POSent &&
            !hidePDF &&
            <Row>
              <Col style={{ textAlign: 'right', marginBottom: '10px' }}>
                <a href={getPDFUrl(pdfDetails || {}, fileUrl)} target="_blank">
                  <Button variant="danger">
                    <Icon name="download" />
                    &nbsp;
                    CLICK HERE TO DOWNLOAD PDF
                  </Button>
                </a>
              </Col>
            </Row>}
          <Row>
            <Col sm="auto">
              <strong className="header">P.O. No.</strong>
              {c_PurchaseOrderNumber}
            </Col>
            <Col sm="auto">
              <strong className="header">Date Ordered</strong>
              {c_POSent_Date && moment(c_POSent_Date).isValid()
                ? moment(c_POSent_Date).format('MM/DD/YYYY')
                : <span className="text-muted">PENDING</span>}
            </Col>
            <Col sm="auto">
              <strong className="header">Vendor No.</strong>
              {VendorNum}
            </Col>
            <Col sm="auto">
              <strong className="header">Vendor</strong>
              {VendorName}
            </Col>
            <Col className="text-right">
              <div>
                <Button
                  type="button"
                  variant="success"
                  size="sm"
                  onClick={submitForm}
                  disabled={!valid || submitting}
                >
                  Save
                </Button>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => toggleEditing(false)}
                  disabled={inProgress || submitting}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm="auto" style={{ fontWeight: 'bold', marginTop: '20px' }}>
              Purchase Order Total
            </Col>
            <Col sm="auto" style={{ fontWeight: 'bold', marginTop: '20px' }}>
              ${formatMoney(SumSellPrice)}
            </Col>
          </Row>
          <EditProjectShippingForm
            hideButtons
            onSubmit={onPostPurchaseOrderAddress}
            initialValues={initialValues}
            lookups={lookups}
            VendorIsColorado={VendorIsColorado}
            VendorIsColoradoType={VendorIsColoradoType}
            result={result}
          />
          <Table responsive className="table-items">
            <tbody>
              <tr>
                <th colSpan={2} style={{ textAlign: 'center' }}>
                  Order Items
                </th>
              </tr>
              <tr>
                <td colSpan={2}>
                  <Table>
                    <thead>
                      <tr>
                        <th>ItemNo</th>
                        <th>Qty</th>
                        <th>Model</th>
                        <th>Description</th>
                        <th className="text-right">Cost (ea)</th>
                        <th className="text-right">Cost (Ext.)</th>
                      </tr>
                    </thead>
                    {lineItemGroups.map(({ LineItemId, lines }, lidx) => (
                      <tbody key={lidx}>
                        {(lines || []).map(({
                          ItemNumber,
                          Quantity,
                          Model,
                          Spec,
                          NetPrice,
                          TotalPrice
                        }, index) => (
                          <tr key={index}>
                            <td>{index === 0 ? ItemNumber : ''}</td>
                            <td>
                              {Quantity}
                            </td>
                            <td>
                              {Model}
                            </td>
                            <td>
                              {Spec}
                            </td>
                            <td className="text-right">
                              ${formatMoney(NetPrice)}
                            </td>
                            <td className="text-right">
                              ${formatMoney(TotalPrice)}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td
                            className="text-right"
                            colSpan="5"
                            style={{ fontWeight: 'bold' }}
                          >
                            Item Total -
                          </td>
                          <td
                            className="text-right"
                            colSpan="1"
                            style={{ fontWeight: 'bold' }}
                          >
                            ${formatMoney(sumTotalPrice(lines))}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="6" className="special-instructions">
                            <ConnectedField form="edit-shipping-form">
                              <h5 style={{ paddingBottom: '10px' }}>
                                <Icon prefix="far" name="hand-point-right" />
                                {' '}
                                SPECIAL INSTRUCTIONS (For Items Above):
                              </h5>
                              <Field
                                name={`ItemSpecial.${LineItemId}`}
                                rows={3}
                                component={FormField.Textarea}
                                maxLength="1000"
                              />
                            </ConnectedField>
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </Table>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  Purchase Order Total
                </td>
                <td className="text-right">
                  ${formatMoney(SumSellPrice)}
                </td>
              </tr>
            </tfoot>
          </Table>
          <Row>
            <Col className="text-right actions" style={{ marginTop: '20px' }}>
              <Button
                variant="success"
                size="sm"
                onClick={submitForm}
                disabled={!valid || submitting}
              >
                Save
              </Button>
              <Button
                variant="outline-secondary"
                size="sm"
                onClick={() => toggleEditing(false)}
                disabled={inProgress || submitting}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapState = state => {
  const valid = isValid('edit-shipping-form')(state);
  const submitting = isSubmitting('edit-shipping-form')(state);

  const { result } = state.getProjectById.toJS();

  return {
    result,
    valid,
    submitting
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      submitForm: () => submit('edit-shipping-form')
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(PurchaseOrderBodyEdit);
