import React, { Component } from 'react';
import {
  getPingShippingStatus,
  postAbortShippingPing
} from '../../../../../api/api';
import Loading from '../../../../ui/Loading';
import { Button } from 'react-bootstrap';
import PingOverlay from '../PingOverlay';
import IfAdmin from '../IfAdmin';

class ShippingPing extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      ping: null
    };
    this.ping = this.ping.bind(this);
    this.handleAbort = this.handleAbort.bind(this);
    this.timeout = null;
  }
  componentDidMount() {
    this.ping();
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  async ping() {
    clearTimeout(this.timeout);
    const { purchaseOrderId, setPing } = this.props;
    try {
      const ping = await getPingShippingStatus(purchaseOrderId);
      const {
        dateinserted,
        responsedate,
        Sage_Status,
        Sage_Message,
        aborted
      } = ping || {};

      const inProgress = !!dateinserted && !responsedate && !aborted;
      const error = Sage_Status === false && !aborted;
      const payload = { ...ping, inProgress, error };

      this.setState({ ping: payload });
      if (setPing) setPing(payload);

      if (!inProgress) return;

      this.timeout = setTimeout(this.ping, 2000);
    } catch (err) {
      // console.dir(err);
    }
  }
  async handleAbort(ID) {
    try {
      const { setPing } = this.props;
      const ping = await postAbortShippingPing(ID);
      const {
        dateinserted,
        responsedate,
        Sage_Status,
        aborted
      } = ping || {};

      const inProgress = !!dateinserted && !responsedate && !aborted;
      const error = Sage_Status === false && !aborted;
      const payload = { ...ping, inProgress, error };

      this.setState({ ping: payload });
      if (setPing) setPing(payload);
    } catch (err) {}
  }
  render() {
    const { ping } = this.state;
    const {
      Sage_Message,
      error,
      inProgress,
      ID
    } = ping || {};

    return (
      <div>
        {inProgress &&
          <div style={{ textAlign: 'right' }}>
            <Loading
              color="#04844B"
              style={{ height: '40px', width: '40px' }}
              containerStyle={{ textAlign: 'right' }}
              message={
                <span>
                  Syncing Shipping Address
                  <IfAdmin>
                    <Button
                      onClick={() => this.handleAbort(ID)}
                      variant="danger"
                      size="sm"
                      style={{ marginLeft: '50px', opacity: '.75' }}
                    >
                      Abort
                    </Button>
                  </IfAdmin>
                </span>
              }
              messageStyle={{
                display: 'inline',
                color: 'rgba(0, 0, 0, 0.45)',
                marginLeft: '10px'
              }}
            />

          </div>}
        {error &&
          <div style={{ textAlign: 'right' }}>
            <span style={{ color: '#DC3528' }}>
              An error occurred updating Sage Shipping Address
              {' '}
              <PingOverlay message={Sage_Message} />
            </span>
            <Button
              variant="danger"
              size="sm"
              style={{ marginLeft: '50px', opacity: '.75' }}
              onClick={() => this.handleAbort(ID)}
            >
              Dismiss
            </Button>
          </div>}
      </div>
    );
  }
}

export default ShippingPing;
