import xlsx from 'xlsx';
import download from 'downloadjs';

const generateAndDownloadXlsx = (
  {
    ref,
    columns,
    sheetName,
    downloadName,
    wscols
  }
) => {
  const { current } = ref || {};
  if (!current) return;
  const tableState = current.getResolvedState();
  const { sortedData } = tableState || {};

  const data = (sortedData || []).map(d => {
    return (columns || []).reduce((prev, next) => {
      const { accessor, Header, formatExport } = next || {};
      const value = formatExport
        ? formatExport(d[accessor] || null)
        : d[accessor] || null;
      return { ...prev, [Header]: value };
    }, {});
  });

  const workbook = xlsx.utils.book_new();
  const worksheet = xlsx.utils.json_to_sheet(data);

  if (wscols) worksheet['!cols'] = wscols;

  xlsx.utils.book_append_sheet(workbook, worksheet, sheetName || 'Sheet1');

  const content = xlsx.write(workbook, { type: 'binary', bookType: 'xlsx' });
  download(
    content,
    downloadName || 'download.xlsx',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  );
};

export default generateAndDownloadXlsx;
