import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import {
  Button,
  Row,
  Col,
  Container,
  ProgressBar,
  Dropdown
} from 'react-bootstrap';

import Notification from '../../../ui/Notification';
import Icon from '../../../ui/Icon';
import Layout from '../../../ui/Layout';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import ModalConfirmDeleteWarehouse from './ModalConfirmDeleteWarehouse';
import ModalAddWarehouseComment from './ModalAddWarehouseComment';
import ModalSendContact from './ModalSendContact';
import ModalSendConfirmation from './ModalSendConfirmation';
import ModalSendNewProject from './ModalSendNewProject';
import WarehouseStatus from '../warehouses-search/WarehouseStatus';

class WarehouseDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { showAllComments: false };
  }
  componentDidMount() {
    const {
      match,
      getWarehouseById
    } = this.props;
    const { id } = match.params || {};
    getWarehouseById(id);
  }
  componentWillUnmount() {
    const {
      clearGetWarehouseById,
      clearDelWarehouse
    } = this.props;
    clearGetWarehouseById();
    clearDelWarehouse();
  }
  render() {
    const {
      match,
      result,
      inProgress,
      showModal,
      delWarehouse,
      history,
      delWarehouseError,
      getWarehouseByIdError,
      clearGetWarehouseById,
      clearDelWarehouse,
      user,
      contactEmailResult,
      confirmationEmailResult,
      clearSendConfirmationEmail,
      clearSendContactEmail
    } = this.props;
    const { id } = match.params || {};
    const { showAllComments } = this.state;

    const { isAdmin, username, isWarehouseAdmin } = user || {};
    const {
      statusId,
      Label,
      name,
      address1,
      address2,
      city,
      postalCode,
      stateProvince,
      tollFree,
      phone,
      fax,
      emergencyPhone,
      minHandling,
      handlingIn,
      handlingOut,
      storage,
      delivery999,
      delivery1999,
      delivery3999,
      delivery7999,
      deliveryPlus,
      driverHelper,
      manTruck,
      twoMenTruck,
      minDelivery,
      warehouseHelper,
      debrisRemoval,
      protection,
      accessFee,
      notes,
      receivingHours,
      fuelSurcharge,
      ratings,
      userContacts,
      lastUsed,
      updatedRateDate,
      emailSentDate
    } = result || {};

    const ratingsTotal = (ratings || [])
      .reduce((prev, next) => prev + next.Rating, 0);
    const ratingAvg = ratingsTotal / (ratings || []).length;

    const deleteWarehouse = () => {
      showModal('DeleteWarehouseModal');
    };

    const handleDeleteWarehouse = () =>
      delWarehouse(id, null, null, () => {
        history.push(`/administration/warehouses`);
      });

    const toggleAllComments = () => {
      this.setState({ showAllComments: true });
    };

    return (
      <Layout route="warehouses-detail" alt>

        <div className="layout-app-header">
          <Container>
            <div>
              <Link
                to={(isAdmin || isWarehouseAdmin) ? '/administration/warehouses' : '/warehouses'}
                className="back"
              >
                <Icon name="arrow-left" />
              </Link>
              <h3>
                Warehouses Detail
              </h3>
              <h2>
                {name || 'N/A'}
              </h2>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" size="sm">
                <Icon fixedWidth name="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {userContacts &&
                  userContacts.length <= 0 &&
                  statusId !== 3 &&
                  <span
                    className="dropdown-item"
                    style={{ color: 'red' }}
                  >
                    Contacts Required
                  </span>}
                {userContacts &&
                  userContacts.length > 0 &&
                  statusId !== 3 &&
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => showModal('SendNewProjectModal')}
                  >
                    Request New Project
                  </a>}
                {userContacts &&
                  userContacts.length > 0 &&
                  statusId !== 3 &&
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => showModal('SendConfirmationModal')}
                  >
                    Request Confirmation
                  </a>}
                {userContacts &&
                  userContacts.length > 0 &&
                  statusId !== 3 &&
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => showModal('SendContactModal')}
                  >
                    Request New Warehouse
                  </a>}
                {userContacts &&
                  statusId !== 3 &&
                  (isAdmin || isWarehouseAdmin) &&
                  <Dropdown.Divider />}
                {(isAdmin || isWarehouseAdmin) &&
                  <Link
                    to={`/administration/warehouses/${id}/edit`}
                    className="dropdown-item"
                  >
                    Edit
                  </Link>}
                {(isAdmin || isWarehouseAdmin) &&
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => deleteWarehouse()}
                  >
                    Delete
                  </a>}
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </div>

        {!inProgress &&
          <Container>
            <Row>
              <Col md={6}>
                <div className="box-bordered">

                  <h4>Contact</h4>
                  <br />

                  <div className="data-group">
                    <div className="muted-Title">Name</div>
                    <div>{name || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Address</div>
                    <div>{address1 || '-'} {address2 || '-'}</div>
                    <div>
                      {city || '-'}, {stateProvince} {postalCode || '-'}
                    </div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">TollFree</div>
                    <div>{tollFree || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Phone</div>
                    <div>{phone || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Fax</div>
                    <div>{fax || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Emergency Phone</div>
                    <div>{emergencyPhone || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Last Used</div>
                    <div>
                      {lastUsed &&
                        <span>{moment(lastUsed).format('MM/DD/YYYY')}</span>}
                      {!lastUsed && <span>-</span>}
                    </div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Last Rate Update</div>
                    <div>
                      {updatedRateDate &&
                        <span>
                          {moment(updatedRateDate).format('MM/DD/YYYY')}
                        </span>}
                      {!updatedRateDate && <span>-</span>}
                    </div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Last Email Sent</div>
                    <div>
                      {emailSentDate &&
                        <span>
                          {moment(emailSentDate).format('MM/DD/YYYY')}
                        </span>}
                      {!emailSentDate && <span>-</span>}
                    </div>
                  </div>

                </div>
              </Col>
              <Col md={6}>
                <div className="box-bordered">

                  <h4>Additional Info</h4>
                  <br />

                  <div className="data-group">
                    <div className="muted-Title">Warehouse Status</div>
                    <div>
                      <WarehouseStatus statusId={statusId} statusName={Label} />
                    </div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Debris Removal</div>
                    <div>{debrisRemoval || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Full Value Protection</div>
                    <div>{protection || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Access Fee</div>
                    <div>{accessFee || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Notes</div>
                    <div>{notes || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Receiving Hours</div>
                    <div>{receivingHours || '-'}</div>
                  </div>

                  <div className="data-group">
                    <div className="muted-Title">Fuel Surcharge</div>
                    <div>{fuelSurcharge || '-'}</div>
                  </div>

                </div>
              </Col>
              <Col md={12}>
                <div className="box-bordered">

                  <h4>Handling/Delivery</h4>
                  <br />

                  <Row>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">Min Handling Charge</div>
                        <p>{minHandling || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">Min Delivery Charge</div>
                        <p>{minDelivery || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">Handling - In (cwt)</div>
                        <p>{handlingIn || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">Handling - Out (cwt)</div>
                        <p>{handlingOut || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">Storage (cwt)</div>
                        <p>{storage || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          Delivery 0 - 999 (cwt)
                        </div>
                        <p>{delivery999 || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          Delivery 1000 - 1999 (cwt)
                        </div>
                        <p>{delivery1999 || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          Delivery 2000 - 3999 (cwt)
                        </div>
                        <p>{delivery3999 || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          Delivery 4000 - 7999 (cwt)
                        </div>
                        <p>{delivery7999 || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          Delivery 8000 and up (cwt)
                        </div>
                        <p>{deliveryPlus || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          Driver helper (per hour)
                        </div>
                        <p>{driverHelper || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          1 man and a truck (per hour)
                        </div>
                        <p>{manTruck || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          2 men and a truck (per hour)
                        </div>
                        <p>{twoMenTruck || '-'}</p>
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="data-group">
                        <div className="muted-Title">
                          Warehouse helper (per hour)
                        </div>
                        <p>{warehouseHelper || '-'}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={12} style={{ marginBottom: '2rem' }}>
                <div className="box-bordered">
                  <h3>Warehouse Contacts</h3>
                  <br />
                  {(userContacts || []).map((user, index) => {
                    return (
                      <Row key={index}>
                        <Col md={3}>
                          <div className="data-group">
                            <div className="muted-Title">First Name</div>
                            <div>{user.UserFirstName || '-'}</div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="data-group">
                            <div className="muted-Title">Last Name</div>
                            <div>{user.UserLastName || '-'}</div>
                          </div>
                        </Col>
                        <Col md={5}>
                          <div className="data-group">
                            <div className="muted-Title">Email</div>
                            <div>{user.Email || '-'}</div>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="data-group">
                            <div className="muted-Title">Phone</div>
                            <div>{user.Phone || '-'}</div>
                          </div>
                        </Col>
                        {index + 1 !== (userContacts || []).length &&
                          <Col md={12}>
                            <hr />
                          </Col>}
                      </Row>
                    );
                  })}
                  {(userContacts || []).length < 1 &&
                    <div className="text-center text-muted">
                      <br />
                      <p>No warehouse contacts were found</p>
                    </div>}
                </div>

              </Col>
              <Col md={12}>
                <hr />
                <div>
                  <Row style={{ marginBottom: '2rem', marginTop: '4rem' }}>
                    <Col>
                      <h3>Ratings</h3>
                      {(ratings || []).length > 0 &&
                        <div className="rating-average">
                          <ProgressBar now={ratingAvg * 20} />
                          {' '}
                          {/*Need to time by 20 because it is out of 10*/}
                          <div className="summary">
                            {numeral(ratingAvg).format('0.0')} of 5.0
                          </div>
                        </div>}
                    </Col>
                    <Col className="text-right">
                      <Button
                        size="sm"
                        variant="primary"
                        onClick={() => showModal('addWarehouseCommentModal')}
                      >
                        Add Rating
                      </Button>
                    </Col>
                  </Row>

                  {(ratings || []).length < 1 &&
                    <div className="text-center text-muted">
                      <br />
                      <p>No ratings to display yet</p>
                    </div>}

                  {(ratings || []).length > 0 &&
                    <div className="comments">
                      <div>
                        {!showAllComments &&
                          (ratings || [])
                            .reverse()
                            .slice(0, 5)
                            .map((rating, index) => {
                              return (
                                <div className="comment" key={index}>
                                  <Row>
                                    <Col>
                                      <div className="name">
                                        {
                                          `${rating.UserFirstName} ${rating.UserLastName[0]}.`
                                        }
                                      </div>
                                      <div className="date">
                                        Reviewed on
                                        {' '}
                                        <span className="text-muted">
                                          {moment(rating.Created).format(
                                            'MM/DD/YYYY'
                                          )}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div className="rating">
                                        <ProgressBar now={rating.Rating * 20} />
                                        {' '}
                                        {/*Need to time by 20 because it is out of 10*/}
                                        <div className="summary">
                                          {numeral(rating.Rating).format('0.0')}
                                          {' '}
                                          of 5.0
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                  {rating.Comment &&
                                    <div className="text">
                                      {rating.Comment}
                                    </div>}
                                </div>
                              );
                            })}
                      </div>
                      <div>
                        {showAllComments &&
                          (ratings || []).reverse().map((rating, index) => {
                            return (
                              <div className="comment" key={index}>
                                <Row>
                                  <Col>
                                    <div className="name">
                                      {
                                        `${rating.UserFirstName} ${rating.UserLastName[0]}.`
                                      }
                                    </div>
                                    <div className="date">
                                      Reviewed on
                                      {' '}
                                      <span className="text-muted">
                                        {moment(rating.Created).format(
                                          'MM/DD/YYYY'
                                        )}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div className="rating">
                                      <ProgressBar now={rating.Rating * 10} />
                                      <div className="summary">
                                        {numeral(rating.Rating).format('0.0')}
                                        {' '}
                                        of 5.0
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                {rating.Comment &&
                                  <div className="text">
                                    {rating.Comment}
                                  </div>}
                              </div>
                            );
                          })}
                      </div>
                      {(ratings || []).length > 5 &&
                        !showAllComments &&
                        <div
                          className="show-more"
                          onClick={() => toggleAllComments()}
                        >
                          Load all ratings ({(ratings || []).length - 5} more)
                        </div>}
                    </div>}

                </div>
              </Col>
            </Row>
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalConfirmDeleteWarehouse
          onDeleteWarehouse={handleDeleteWarehouse}
          warehouse={{ Name: name }}
        />

        <ModalAddWarehouseComment WarehouseId={id} />

        <ModalSendConfirmation WarehouseId={id} />

        <ModalSendContact WarehouseId={id} />

        <ModalSendNewProject warehouse={result} />

        {delWarehouseError &&
          <Notification
            key="warehouse-del-detail-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearDelWarehouse}
          >
            Error deleting warehouse.
          </Notification>}
        {getWarehouseByIdError &&
          <Notification
            key="warehouse-get-detail-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetWarehouseById}
          >
            Error loading warehouse data.
          </Notification>}

        {
          contactEmailResult &&
          <Notification
            key="contact-email-success"
            duration={5}
            closable={true}
            onClose={clearSendContactEmail}
          >
            Contact Email sent successfully
          </Notification>
        }
        {
          confirmationEmailResult &&
          <Notification
            key="contact-email-success"
            duration={5}
            closable={true}
            onClose={clearSendConfirmationEmail}
          >
            Confirmation Email sent successfully
          </Notification>
        }

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress,
    error: getWarehouseByIdError
  } = state.getWarehouseById.toJS();
  const {
    error: delWarehouseError
  } = state.delWarehouse.toJS();
  const {
    result: user
  } = state.validateLogin.toJS();

  const {
    result: contactEmailResult
  } = state.sendContactEmail.toJS();

  const {
    result: confirmationEmailResult
  } = state.sendConfirmationEmail.toJS();
  return {
    result,
    inProgress,
    delWarehouseError,
    getWarehouseByIdError,
    user,
    contactEmailResult,
    confirmationEmailResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getWarehouseById: fetchDux.getWarehouseById.createAction,
      clearGetWarehouseById: fetchDux.getWarehouseById.clearAction,
      delWarehouse: fetchDux.delAdminWarehouse.createAction,
      clearDelWarehouse: fetchDux.delAdminWarehouse.clearAction,
      clearSendConfirmationEmail: fetchDux.sendConfirmationEmail.clearAction,
      clearSendContactEmail: fetchDux.sendContactEmail.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(WarehouseDetail);
