import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import ItemsTable from './ItemsTable';

class ModalOrphanedItems extends Component {
  render() {
    const {
      openModal,
      name,
      result,
      onDeleteOrphanedItems,
      onCancelProject,
      inProgress,
      error
    } = this.props;
    const { orphanedItems } = result || {};
    const show = openModal === name;
    const closeModal = () => {};

    return (
      <Modal
        show={show}
        onHide={closeModal}
        size="lg"
        dialogClassName="modal-1100 modal-orphaned-items"
      >
        <Modal.Header>
          <Modal.Title style={{ color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            &nbsp;
            Orphaned Items
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            The following items are not associated with a Purchase Order. By choosing to continue, you are acknowledging that
            the items below will be excluded from the import.
          </p>
          {show &&
            <ItemsTable
              hideSearch
              show
              data={orphanedItems}
              search={''}
              handleSearchChange={() => {}}
              tableProps={{
                showPagination: false,
                defaultPageSize: (orphanedItems || []).length || 1
              }}
            />}
          {error &&
            <div
              style={{
                color: '#C23934',
                textAlign: 'center',
                padding: '20px 60px 20px 0'
              }}
            >
              <Icon name="exclamation-triangle" />
              &nbsp;
              {error.message || 'An error occurred'}
            </div>}
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm md="auto" style={{ color: '#C23934' }}>
              <Icon name="exclamation-triangle" />
              &nbsp;
              Would you like to remove the items above and continue with the import?
            </Col>
            <Col sm md="auto">
              <Button
                type="button"
                size="sm"
                variant="secondary"
                onClick={onCancelProject}
                disabled={inProgress}
              >
                No, Cancel Project Import
              </Button>
              <Button
                type="button"
                size="sm"
                variant="danger"
                onClick={onDeleteOrphanedItems}
                disabled={inProgress}
              >
                Yes, Delete Items and Import Project
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    inProgress: deleteOrphanedItemsInProgress,
    error: deleteOrphanedItemsError
  } = state.deleteOrphanedItems.toJS();

  const {
    inProgress: deleteProjectInProgress,
    error: deleteProjectError
  } = state.cancelProject.toJS();

  return {
    inProgress: deleteOrphanedItemsInProgress || deleteProjectInProgress,
    error: deleteOrphanedItemsError || deleteProjectError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalOrphanedItems);
