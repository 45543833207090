import React, { useState } from 'react';
import FormField from '../../../forms/FormField';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { Field } from 'redux-form';
import moment from 'moment';
import Icon from '../../../ui/Icon';

const displayDate = d =>
    d && moment(d).isValid() ? moment(d).format('MM/DD/YYYY') : '';

const VendorCell = (
    {
        vendorOptions,
        VendorName,
        change,
        lineItemsLength,
        poVendorName
    }
) => {
    const [open, setOpen] = useState(false);
    const handleToggle = () => {
        if (open) {
            setOpen(false);
            change('VendorName', poVendorName);
            const shipmentDesc = `${poVendorName} ${lineItemsLength} ${lineItemsLength > 1 ? 'Assorted items' : 'Assorted Item'}`;
            change('ShipmentDesc', shipmentDesc);
            return;
        }
        setOpen(true);
    };
    return (
        <div
            style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}
        >
            {!open && <span>{VendorName}</span>}
            {open &&
                <div style={{ width: '150px' }}>
                    <Field
                        name="VendorName"
                        component={FormField.SelectReact}
                        options={vendorOptions}
                        onChange={value => {
                            const shipmentDesc = `${value} ${lineItemsLength} ${lineItemsLength > 1 ? 'Assorted items' : 'Assorted Item'}`;
                            change('VendorName', value);
                            change('ShipmentDesc', shipmentDesc);
                        }}
                    />
                </div>}
            <Icon
                name={open ? 'times-circle' : 'edit'}
                onClick={handleToggle}
                style={{
                    color: open ? '#5EA2C9' : '#5EA2C9',
                    marginLeft: '20px',
                    marginBottom: open ? '1rem' : '0'
                }}
            />
        </div>
    );
};

const PoSummary = (props) => {
    const {
        handlePurchaseOrderChange,
        purchaseOrder = {},
        shippingAddress,
        VendorName,
        purchaseOrderOptions,
        vendorOptions,
        lineItems,
        change,
        shipments
    } = props;
    const { c_ShipDate } = shippingAddress;
    const { c_IsImported, VendorName: poVendorName } = purchaseOrder;
    return (
        <div className='po-summary'>
            <Row>
                <Col className="column">
                    <Form.Label style={{ width: '400px' }}>PO Number:</Form.Label>
                    <Field
                        type="text"
                        name="poNumber"
                        options={purchaseOrderOptions}
                        onChange={value => {
                            const po = purchaseOrderOptions.find(
                                ({ value: v }) => v === value
                            );
                            handlePurchaseOrderChange(po);
                        }}
                        component={FormField.SelectReact}
                    />
                </Col>
                <Col className="column">
                    <Form.Label>Order Date:</Form.Label>
                    <div>{displayDate(purchaseOrder.c_ProjectCreateDate)}</div>
                </Col>
                <Col className="column text-center">
                    <Form.Label>Shipments:</Form.Label>
                    <div className='text-center'> {(shipments || []).length}</div>
                </Col>
                <Col className="column">
                    <Form.Label>Req. Ship Date:</Form.Label>
                    <div>{displayDate(c_ShipDate)}</div>
                </Col>
                <Col className="column">
                    <Form.Label>Vendor Name:</Form.Label>
                    {/* TODO Display vendor name and NOT vendor number */}
                    {c_IsImported
                        ? <VendorCell
                            vendorOptions={vendorOptions}
                            VendorName={VendorName}
                            change={change}
                            lineItemsLength={lineItems.length}
                            poVendorName={poVendorName}
                        />
                        : <div> {purchaseOrder.VendorName}</div>}
                </Col>
                <Col className="column">
                    <Form.Label>Sales Rep:</Form.Label>
                    <div>{purchaseOrder.c_SalesRepFirstName}</div>
                </Col>
            </Row>
        </div>
    )
}

export default PoSummary;