import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import localForage from 'localforage';
import { actionCreators } from '../../../state/modals-dux';

const { location } = window || {};

const logout = async () => {
  await localForage.removeItem('identity');
  location.href = '/';
};

const LogoutModal = ({ openModal, closeModal }) => (
  <Modal show={openModal === 'LogoutModal'} onHide={closeModal}>
    <Modal.Header closeButton>
      <Modal.Title>Logout</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <h4>Are you sure you wish to logout?</h4>
      <p>Unsaved changes will be lost.</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={logout}>
        Logout
      </Button>
      <Button variant="outline-secondary" onClick={closeModal}>
        Cancel
      </Button>
    </Modal.Footer>
  </Modal>
);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(LogoutModal);
