import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

export default (
  {
    controlled,
    open: o = false,
    setOpen: s,
    name,
    children,
    title,
    isOpen = true,
    hasError = false
  }
) => {
  const [open, setOpen] = controlled ? [o, s] : useState(isOpen);
  const headerStyle = hasError
    ? { cursor: 'pointer', color: 'red' }
    : { cursor: 'pointer' };
  return (
    <Card style={{ marginBottom: '0.5rem' }}>
      <Card.Header
        style={headerStyle}
        onClick={() => controlled ? setOpen(!open, name) : setOpen(!open)}
      >
        <Icon
          name={open ? 'angle-up' : 'angle-down'}
          style={{ marginRight: '0.5rem' }}
        />
        {' '}
        {title}
      </Card.Header>
      {open &&
        <Card.Body>
          {children}
        </Card.Body>}
    </Card>
  );
};