import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import AcceptQuoteForm from './AcceptQuoteForm';

class ModalAcceptQuote extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmit,
      depositPercentage,
      depositInvoice
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Accept Quote</Modal.Title>
        </Modal.Header>
        <AcceptQuoteForm
          depositPercentage={depositPercentage}
          depositInvoice={depositInvoice}
          onSubmit={handleSubmit}
          closeModal={closeModal}
        />
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalAcceptQuote);
