import React, { Component } from 'react';
import { getVendorStatus } from '../../../../../api/api';
import Loading from '../../../../ui/Loading';
import Icon from '../../../../ui/Icon';

class VendorCheck extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      result: null,
      loaded: false
    };
    this.ping = this.ping.bind(this);
  }
  componentDidMount() {
    this.ping();
  }
  async ping() {
    const { purchaseOrderId, setPing } = this.props;
    try {
      const result = await getVendorStatus(purchaseOrderId);
      this.setState({ result, loaded: true });
    } catch (err) {
      // console.dir(err);
    }
  }
  render() {
    const { result, loaded } = this.state;
    const { hideOk } = this.props;
    const {
      existsInSage,
      IsActive
    } = result || {};

    // return (
    //   <div><Icon name="check" /></div>
    // )

    if (!loaded)
      return (
        <Loading
          style={{ height: '30px', width: '30px' }}
          containerStyle={{ textAlign: 'left', display: 'inline' }}
          innercontainerStyle={{ display: 'inline' }}
        />
      );
    if (!existsInSage)
      return (
        <span style={{ color: '#DC3528' }}>
          <Icon name="warning" style={{ color: '#e3fcef' }} />
          Vendor Number no longer exists in Sage
        </span>
      );
    if (!IsActive)
      return (
        <span style={{ color: '#DC3528' }}>
          <Icon name="exclamation-triangle" />
          Vendor Mapping is no longer active
        </span>
      );

    if (IsActive && existsInSage && !hideOk)
      return <Icon name="check" style={{ color: '#2EB86F' }} />;

    return false;
  }
}

export default VendorCheck;
