import React, { Component } from 'react';
import Layout from '../../../../ui/Layout';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import { getFormValues } from 'redux-form';

import Loading from '../../../../ui/Loading';
import ProjectInfo from './../../projects-detail/ProjectInfo';
import UpdateShippingInfo from './UpdateShippingInfo';
import ProjectBreadcrumbs from '../ProjectBreadcrumbs';

const getAddress = (
  {
    override,
    confirmed,
    projectAddressValues,
    defaultaddress
  }
) => {
  if (override) return override;
  if (confirmed) return projectAddressValues;
  return defaultaddress;
};

const getPayload = (
  {
    addresses,
    purchaseOrders,
    poShippingValues,
    projectAddressValues,
    projectAddress
  }
) => {
  const { purchaseOrders: povalues } = poShippingValues || {};
  const pos = (povalues || [])
    .filter(({ confirmed, override }) => confirmed || !!override)
    .map(({ ID, confirmed, override }) => {
      const po = (purchaseOrders || []).find(p => p.ID === ID) || {};
      const { c_PurchaseOrderAddressId } = po;
      const defaultaddress = (addresses || [])
        .find(p => p.ID === c_PurchaseOrderAddressId);
      const address = getAddress({
        override,
        confirmed,
        projectAddressValues: projectAddressValues || projectAddress,
        defaultaddress
      });
      return {
        ID,
        payload: address
      };
    });

  return {
    projectAddress: projectAddressValues,
    purchaseOrders: pos
  };
};

const getHasChanges = (
  {
    addresses,
    purchaseOrders: pos,
    poShippingValues,
    projectAddressValues
  }
) => {
  const payload = getPayload({
    addresses,
    pos,
    poShippingValues,
    projectAddressValues
  });
  const {
    projectAddress,
    purchaseOrders
  } = payload;

  const hasPOChanges = purchaseOrders.length > 0;
  const hasProjectChanges = !!projectAddress;

  return hasPOChanges || hasProjectChanges;
};

class ProjectsUpdateShipping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeGroup: null
    };
  }
  componentDidMount() {
    const {
      match,
      getProjectById,
      getLookups
    } = this.props;
    const { id } = match.params || {};
    getProjectById(id);
    getLookups();
  }
  render() {
    const {
      result,
      inProgress,
      lookups,
      history,
      purchaseOrderAddressValues,
      postUpdateProjectShipping,
      postUpdateProjectShippingInProgress
    } = this.props;
    const { activeGroup, projectAddressValues, poShippingValues } = this.state;
    const { project, addresses, purchaseOrders, projectAddress } = result || {};
    const { c_SageIntegrationStatus: status, ID } = project || {};

    const setProjectAddress = values => {
      this.setState({
        projectAddressValues: values,
        activeGroup: 2
      });
    };

    const toggleGroup = value => {
      this.setState({ activeGroup: value === activeGroup ? null : value });
    };

    const handleSubmitPOShipping = values => {
      this.setState({
        poShippingValues: values,
        activeGroup: 3
      });
    };

    const handleFinalSubmit = () => {
      const payload = getPayload({
        addresses,
        purchaseOrders,
        poShippingValues,
        projectAddressValues,
        projectAddress
      });
      postUpdateProjectShipping({ id: ID, payload }, null, null, () =>
        history.push(`/projects/${ID}`));
    };

    const hasChanges = getHasChanges({
      addresses,
      poShippingValues,
      projectAddressValues
    });

    return (
      <Layout route="projects-update-shipping">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs
              project={project}
              current="Update Shipping Instructions"
            />
            <ProjectInfo
              status={status}
              result={result}
              projectId={ID}
              backButton
            />
            <UpdateShippingInfo
              status={status}
              result={result}
              projectId={ID}
              lookups={lookups}
              projectAddressValues={projectAddressValues}
              purchaseOrderAddressValues={purchaseOrderAddressValues}
              setProjectAddress={setProjectAddress}
              activeGroup={activeGroup}
              toggleGroup={toggleGroup}
              handleSubmitPOShipping={handleSubmitPOShipping}
              poShippingValues={poShippingValues}
              handleFinalSubmit={handleFinalSubmit}
              hasChanges={hasChanges}
              postUpdateProjectShippingInProgress={
                postUpdateProjectShippingInProgress
              }
            />
          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();
  const {
    inProgress: postUpdateProjectShippingInProgress
  } = state.postUpdateProjectShipping.toJS();

  const {
    result: lookups
  } = state.getLookups.toJS();

  const projectAddressValues = getFormValues('shipping-order-address')(state);
  const purchaseOrderAddressValues = getFormValues('shipping-po-checked')(
    state
  ) || {};
  return {
    result,
    lookups,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError,
    projectAddressValues,
    postUpdateProjectShippingInProgress,
    purchaseOrderAddressValues: purchaseOrderAddressValues.purchaseOrders || []
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectById: fetchDux.getProjectById.createAction,
      getLookups: fetchDux.getLookups.createAction,
      postUpdateProjectShipping: fetchDux.postUpdateProjectShipping.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsUpdateShipping);
