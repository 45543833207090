import React from 'react';
import { Row, Col } from 'react-bootstrap';
import numeral from 'numeral';
import classNames from 'classnames';

import Icon from '../../../ui/Icon';

const formatMoney = value => numeral(value).format('0,0.00');

const PurchaseOrderCard = (
  {
    VendorName,
    numItems,
    SumSellPrice,
    ID,
    setActive,
    activePO,
    style,
    c_SelectedPurchaseOrderSageVendorID,
    c_PurchaseOrderNumber,
    Manufacturer,
    isApproved
  }
) => {
  return (
    <div
      className={classNames(
        'purchase-order-card',
        activePO === ID ? 'active' : '',
        isApproved ? 'success' : ''
      )}
      onClick={() => setActive(ID)}
      style={style || {}}
    >
      {isApproved && <Icon name="check" className="corner" />}
      <h4>Purchase Order: <span>{c_PurchaseOrderNumber}</span></h4>
      {c_SelectedPurchaseOrderSageVendorID && <h5>{VendorName}</h5>}
      {!c_SelectedPurchaseOrderSageVendorID &&
        <h5 style={{ color: '#C23934' }}>AQ MFR: {Manufacturer}</h5>}
      <Row>
        <Col>
          <strong>Amount</strong>
          ${formatMoney(SumSellPrice)}
        </Col>
        <Col>
          <strong>Items</strong>
          {numItems}
        </Col>
        <Col>
          {c_SelectedPurchaseOrderSageVendorID
            ? <span className="vendor-id-link">
                <Icon name="link" flip="vertical" />
              </span>
            : <span className="vendor-id-link">
                <Icon name="unlink" flip="vertical" />
              </span>}
        </Col>
      </Row>
    </div>
  );
};

export default PurchaseOrderCard;
