import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../../ui/Icon';
import numeral from 'numeral';
import moment from 'moment';
import PDFLink from '../PDFLink';

import PurchaseOrderDetail
  from '../../purchase-order-detail/PurchaseOrderDetail';
import EditLineItemInstructions from './EditLineItemInstructions';

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY');
};

const deleteLabelStyle = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  display: 'inline',
  marginLeft: '20px',
  color: '#C23934'
};

class ProjectLineItemInstructionsPO extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.state = {
      showDetails: false,
      showEdit: false
    };
  }
  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }
  render() {
    const {
      purchaseOrder,
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      isDeleted,
      projectId,
      postUpdateLineItemSpecialInstructions
    } = this.props;
    const {
      ID: POID,
      pdfDetails,
      Manufacturer
    } = purchaseOrder || {};

    const {
      showDetails,
      showEdit
    } = this.state;

    const toggleEdit = () => this.setState({ showEdit: !showEdit });

    const poItems = (lineItems || [])
      .filter(
        ({ PurchaseOrderId }) =>
          PurchaseOrderId === (purchaseOrder || {}).PurchaseOrderId
      );
    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (purchaseOrder || {}).ID
      );

    const initialValues = (poItemsInstructions || []).reduce((prev, next) => {
      const { LineItemId, SpecialInstructions } = next || {};
      return {
        ...prev,
        [LineItemId]: SpecialInstructions
      };
    }, {});

    const formName = `edit-line-item-instructions-form-${POID}`;
    const handleSubmit = values => {
      const lineItemPayload = Object.keys(values || {}).map(LineItemId => ({
        LineItemId,
        SpecialInstructions: values[LineItemId]
      }));

      const payload = {
        lineItems: lineItemPayload,
        purchaseOrderId: POID
      };
      postUpdateLineItemSpecialInstructions(
        { id: projectId, payload },
        formName,
        null,
        () => {
          this.setState({ showEdit: false });
        }
      );
    };

    return (
      <div className="purchase-order-summary">
        <div
          className="purchase-order-summary-header"
          style={isDeleted ? { backgroundColor: '#ffebe6' } : {}}
        >
          <button className="btn toggle-details" onClick={this.toggleDetails}>
            <Icon name={showDetails ? 'caret-up' : 'caret-down'} />
          </button>
          <div className="symbol">PO</div>
          <div className="project">
            <div>Purchase Order</div>
            <strong>{purchaseOrder.c_PurchaseOrderNumber}</strong>
          </div>
          <div className="project" style={{ marginLeft: '20px' }}>
            <div>Manufacturer</div>
            <strong>{Manufacturer}</strong>
          </div>
          {isDeleted && <div style={deleteLabelStyle}>Deleted</div>}
        </div>
        <div className="purchase-order-summary-body">
          <Row>
            <Col sm="3">
              <h5>P.O. No.</h5>
              <div className="value large">
                {purchaseOrder.c_PurchaseOrderNumber}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor No.</h5>
              <div className="value small">
                {purchaseOrder.VendorNum}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor</h5>
              <div className="value small">
                {purchaseOrder.VendorName}
              </div>
            </Col>
            <Col>
              <div style={{ textAlign: 'right' }}>
                {!showEdit &&
                  <Button onClick={toggleEdit}>
                    Edit Line Item Instructions
                  </Button>}
                {showEdit && <Button onClick={toggleEdit}>Cancel</Button>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <PDFLink pdfDetails={pdfDetails} />
            </Col>
          </Row>
        </div>
        <div
          className={
            `purchase-order-summary-items collapse ${showEdit ? 'show' : ''}`
          }
        >
          <EditLineItemInstructions
            purchaseOrder={purchaseOrder}
            lineItems={poItems}
            lineItemSpecialInstructions={poItemsInstructions}
            addresses={addresses}
            form={formName}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          />
        </div>
        <div
          className={
            `purchase-order-summary-items collapse ${showDetails && !showEdit ? 'show' : ''}`
          }
        >
          <PurchaseOrderDetail
            purchaseOrder={purchaseOrder}
            lineItems={poItems}
            lineItemSpecialInstructions={poItemsInstructions}
            addresses={addresses}
            hidePDF={true}
          />
        </div>
      </div>
    );
  }
}

export default ProjectLineItemInstructionsPO;
