import React, { Component } from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';

class ModalProjectErrorSummary extends Component {
  render() {
    const {
      openModal,
      name,
      closeModal,
      result
    } = this.props;

    const { errors } = result || {};
    return (
      <Modal show={openModal === name} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title style={{ color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            &nbsp;
            Project Error Summary
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table>
            <thead>
              <tr>
                <th>Field</th>
                <th>ID</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {(errors || []).map(({ field, id, message }) => (
                <tr>
                  <td>{field}</td>
                  <td>{id}</td>
                  <td>{message}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={closeModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalProjectErrorSummary);
