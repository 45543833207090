import React, { Component } from 'react';
import { reduxForm, Field, FieldArray, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Table } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';

const required = value => value ? undefined : 'Required';
const requiredFieldArray = value =>
  value && value.length > 0 ? undefined : 'Required';
const requiredToAddress = value =>
  (value || []).some(({ Type }) => Type === 'to')
    ? undefined
    : 'At least one "To" address is required';

const getTypeLabel = Type => {
  switch (Type) {
    case 'to':
      return 'To';
    case 'cc':
      return 'CC';
    case 'bcc':
      return 'BCC';
    default:
      return '';
  }
};

class RenderToInputs extends Component {
  render() {
    const {
      fields,
      toValues,
      subValues,
      change
    } = this.props;

    const {
      EmailRecipient,
      Type
    } = subValues || {};

    const disabled = !EmailRecipient || !Type;

    const handleAddEmail = () => {
      if (disabled) return;
      fields.push(subValues);
      change('sub', {
        EmailRecipient: '',
        Type: 'to'
      });
    };

    return (
      <Row>
        <Col sm="12">
          <Table>
            <thead>
              <tr>
                <th>Email Recipient</th>
                <th>Type</th>
                <th />
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ paddingLeft: '0px' }}>
                  <Field
                    name="sub.EmailRecipient"
                    type="text"
                    component={FormField.Input}
                  />
                </td>
                <td>
                  <Field
                    name="sub.Type"
                    type="text"
                    component={FormField.SelectReact}
                    placeholder=""
                    options={[
                      { value: 'to', label: 'To' },
                      { value: 'cc', label: 'CC' },
                      { value: 'bcc', label: 'BCC' }
                    ]}
                  />
                </td>
                <td style={{ textAlign: 'right', paddingRight: '0px' }}>
                  <Button
                    type="button"
                    onClick={handleAddEmail}
                    size="sm"
                    disabled={disabled}
                  >
                    <Icon name="plus" />
                  </Button>
                </td>
              </tr>
              {(toValues || []).map(({ EmailRecipient, Type }, index) => {
                const handleRemove = () => fields.remove(index);
                return (
                  <tr key={`${EmailRecipient}-${index}`}>
                    <td style={{ color: '#5f5f5f', paddingLeft: '0px' }}>
                      <span style={{ color: '#5EA2C9' }}>
                        <Icon name="envelope" />
                      </span>
                      &nbsp;
                      &nbsp;
                      {EmailRecipient}
                    </td>
                    <td>{getTypeLabel(Type)}</td>
                    <td
                      style={{
                        color: '#C23934',
                        textAlign: 'right',
                        cursor: 'pointer',
                        paddingRight: '0px'
                      }}
                    >
                      <Icon name="minus-circle" onClick={handleRemove} />
                    </td>
                  </tr>
                );
              })}
              {(toValues || []).length === 0 &&
                <tr>
                  <td
                    colSpan="4"
                    style={{ color: '#C23934', textAlign: 'center' }}
                  >
                    <Icon name="exclamation-triangle" />
                    &nbsp;
                    No Email Recipients selected
                  </td>
                </tr>}
              {(toValues || []).length >= 0 &&
                !(toValues || []).some(({ Type }) => Type === 'to') &&
                <tr>
                  <td
                    colSpan="4"
                    style={{ color: '#C23934', textAlign: 'center' }}
                  >
                    <Icon name="exclamation-triangle" />
                    &nbsp;
                    At least one "To" address is required
                  </td>
                </tr>}
            </tbody>
          </Table>
        </Col>
        <Col sm="12">
          <hr />
        </Col>
      </Row>
    );
  }
}

const SendOrderInvoiceForm = props => {
  const {
    submitting,
    submitFailed,
    invalid,
    toValues,
    subValues,
    change,
    handleSubmit
  } = props;

  return (
    <form>
      <Row>
        <Col sm="12">
          <FieldArray
            name="to"
            change={change}
            component={RenderToInputs}
            toValues={toValues}
            subValues={subValues}
            validate={[requiredFieldArray, requiredToAddress]}
          />
        </Col>
        <Col sm="12">
          <Field
            required
            name="subject"
            type="text"
            component={FormField.Input}
            label="Subject"
            validate={[required]}
          />
        </Col>
        <Col sm="12">
          <Field
            required
            name="body"
            rows={10}
            component={FormField.Textarea}
            label="Body"
            validate={[required]}
          />
        </Col>
      </Row>
      <div>
        <Button
          variant="primary"
          type="button"
          disabled={submitting}
          onClick={handleSubmit}
        >
          Send Email
        </Button>
        {submitFailed &&
          invalid &&
          <span style={{ marginLeft: '20px', color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            Form is invalid. Fix errors before proceeding
          </span>}
      </div>
    </form>
  );
};

const formName = 'send-order-invoice-form';

const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(SendOrderInvoiceForm);

const mapState = (state, props) => {
  const selector = formValueSelector(props.form || formName);

  const toValues = selector(state, 'to');
  const subValues = selector(state, 'sub');

  return {
    toValues,
    subValues
  };
};

const mapDispatch = (dispatch, ...props) =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(form);
