import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';

const SHOW_STRIPPED = process.env.REACT_APP_SHOW_STRIPPED === 'true';

const ECOMMERCE_URL = process.env.REACT_APP_PORTAL_ECOMMERCE;

const ActionCard = props => {
  const {
    name,
    description,
    icon,
    url,
    absoluteUrl,
    submenu,
    disabled,
    descriptionStyle,
    overideSubMenuClick
  } = props || {};
  const [isSubmenuOpen, toggleSubmenu] = useState(false);

  return (
    <article className={disabled ? 'homepage-card disabled' : 'homepage-card'} >
      <div className="body">
        {overideSubMenuClick &&
          <a
            onClick={() => toggleSubmenu(!isSubmenuOpen)}
          >
            <Icon name={icon} className="icon" />
            <h4>{name}</h4>
            <p style={descriptionStyle || {}}>{description}</p>
          </a>}
        {absoluteUrl && !overideSubMenuClick &&
          <a href={absoluteUrl} target="_blank">
            <Icon name={icon} className="icon" />
            <h4>{name}</h4>
            <p style={descriptionStyle || {}}>{description}</p>
          </a>}
        {!absoluteUrl && !overideSubMenuClick &&
          <Link to={`/${url}`}>
            <Icon name={icon} className="icon" />
            <h4>{name}</h4>
            <p style={descriptionStyle || {}}>{description}</p>
          </Link>}
        {submenu &&
          <div
            className="submenu-toggle"
            onClick={() => toggleSubmenu(!isSubmenuOpen)}
          >
            <Icon fixedWidth name={isSubmenuOpen ? 'times' : 'ellipsis-h'} />
          </div>}

      </div>
      {submenu &&
        isSubmenuOpen &&
        <div className="submenu">
          <ul>
            {(submenu || []).map((item, index) => (
              <li key={index}>
                <Link to={`/${item.url}`}>
                  <Icon name="chevron-right" />
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>}
    </article>
  );
};

export const Homepage = ({ user }) => {
  const { isAdmin, username, isWarehouseAdmin } = user || {};

  const subMenuProjectsAndOrders = [
    { name: 'Import New Project', url: 'projects/new' },
    {
      name: 'Browse Imports In Progress',
      url: 'projects'
    },
    { name: 'Browse Active Orders', url: 'orders' },
    { name: 'Browse Archived Orders', url: 'archived-orders' }
  ];

  if (SHOW_STRIPPED) {
    subMenuProjectsAndOrders.splice(1, 0, { name: 'Create G/L only Order', url: 'projects/gl' });
  }

  //will remove after soft launch of quotes
  const usersList = ['cparnell', 'michaelkeck', 'ccatalano'];
  const isQuotePreReleaseGroup = usersList.includes(username);
  return (
    <Layout route="homepage">
      <Row>
        <Col md={12}>
          <h1>Hi {user.firstName} {user.lastName},</h1>
          <h2>Pick your path and get started.</h2>
          <h3>You can always change your mind later.</h3>
        </Col>
      </Row>
      <Row className="row-eq-height">
        <Col md={4}>

          <ActionCard
            name="All Projects &amp; Orders"
            descriptionStyle={{
              marginTop: '8px',
              textDecoration: 'underline'
            }}
            description={<Link to="/projects/new">CLICK HERE TO IMPORT</Link>}
            icon="bars"
            url="orders"
            submenu={subMenuProjectsAndOrders}
          />
          {(SHOW_STRIPPED || isQuotePreReleaseGroup) &&
            <ActionCard
              name="Customers and Locations"
              // description="Manage Concepts, Locations, and Users"
              icon="user-circle"
              url="customers"
              submenu={[
                {
                  name: 'Create or Browse Locations',
                  url: 'customers/locations'
                },
                { name: 'Create or Browse Users', url: 'customers/users' },
                { name: 'Create or edit Concepts', url: 'customers/concepts' }
              ]}
            />}

          <ActionCard
            name="Vendor Details"
            // description="View only vendor details"
            icon="search"
            url="vendors"
          />
          {isAdmin &&
            <ActionCard
              name="Admin"
              // description="Manage Sales Reps, Access and Vendors"
              icon="wrench"
              url="administration"
              submenu={[
                {
                  name: 'Browse Sales Representatives',
                  url: 'administration/sales-reps'
                },
                { name: 'Browse Sage Vendors', url: 'administration/vendors' },
                { name: 'Manage Warehouses', url: 'administration/warehouses' }
              ]}
            />}
          {!isAdmin &&
            isWarehouseAdmin &&
            <ActionCard
              name="Admin"
              icon="wrench"
              url="administration/warehouses"
            />}
        </Col>

        <Col md={4}>
          {SHOW_STRIPPED &&
            <ActionCard
              name="Spot Orders &amp; E-commerce"
              // description="View orders from e-commerce website"
              icon="shopping-cart"
              url="ecommerce-orders"
              submenu={[
                {
                  name: 'Manage Coupons',
                  url: 'coupons'
                }
              ]}
            />}

          <ActionCard
            name="Shipping and Tracking"
            // description="Search and create shipments"
            icon="boxes"
            url="shipments"
            submenu={[
              {
                name: 'Shipping and Tracking',
                url: 'shipments'
              },
              { name: 'Reports', url: 'reports' }
            ]}
          />
          {(SHOW_STRIPPED || isQuotePreReleaseGroup) &&
            <ActionCard
              name="Customer Dashboard"
              icon="shopping-cart"
              absoluteUrl={ECOMMERCE_URL}
            />}
          <ActionCard
            name="Warehouse Database"
            // description="Manage warehouses"
            icon="search"
            url="warehouses"
          />
        </Col>
        <Col md={4}>
          {(SHOW_STRIPPED || isQuotePreReleaseGroup) &&
            <ActionCard
              name="Quotes"
              description="Create &amp; Manage"
              icon="file-invoice"
              url="quotes"
            />}
          <ActionCard
            name="Freight Calculators"
            description="Individual and Project"
            icon="truck"
            url="freight"
            submenu={[
              { name: 'Calculate for Single Shipment', url: 'freight' },
              {
                name: 'Calculate for project via .json import',
                url: 'freight/upload'
              }
            ]}
          />

        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => {
  const user = state.login.toJS().result;
  return { user };
};

export default connect(mapState)(Homepage);
