import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector, change } from 'redux-form';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCustomersEditLocation
  from '../customers-locations/ModalEditLocation';
import ModalCustomersDeleteLocation
  from '../customers-locations/ModalDeleteLocation';

import TrackerTemplateForm from './TrackerTemplateForm';
import DisplayProgressForm from './DisplayProgressForm';
import ConfigureTrackerForm from './ConfigureTrackerForm';
import Notification from '../../../ui/Notification';

class CustomersLocationsStatus extends Component {
  constructor(props) {
    super(props);
    this.state = { showConfigureForm: false, editStatuses: [] };
  }
  componentDidMount() {
    const {
      match,
      getLocationById,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getLocationById({ id, query: { getStatuses: 1 } }, null, true, data => {
      const {
        statuses
      } = data || {};
      //set up the editable statuses
      this.setState({ editStatuses: [].concat(statuses) });
    });
    searchCustomersCompanies();
  }
  render() {
    const {
      inProgress,
      getLocationByIdError,
      result,
      showModal,
      match,
      getCompanyResults,
      history,
      getLocationById,
      putProgressDisplayById,
      putProgressDisplayByIdInProgress,
      postStatuses,
      postStatusesInProgress,
      selectedTemplate,
      clearGetLocationById,
      searchCustomersCompaniesError,
      clearSearchCustomersCompanies,
      putProgressDisplayByIdError,
      clearPutProgressDisplayById,
      postStatusesError,
      clearPostStatuses
    } = this.props;

    const {
      editStatuses
    } = this.state;

    const {
      location,
      company,
      statuses
    } = result || {};

    const {
      name,
      id,
      progressDisplayUpdated,
      progressDisplay
    } = location || {};

    const {
      companyID
    } = company || {};

    const { showConfigureForm } = this.state;
    const { id: locationId } = match.params || {};
    const showConfigureTrackerForm = () => {
      this.setState({ showConfigureForm: true });
    };
    const handleConfigureTrackerFormCancel = () => {
      change('tracker-templates-location-form', 'searchTemplates', null);
      this.setState({ showConfigureForm: false });
    };

    const handlePutProgressDisplayById = value => {
      putProgressDisplayById(
        {
          id,
          payload: { progressDisplay: value }
        },
        'display-progress-location-form',
        null,
        () => {
          getLocationById(
            { id, query: { getStatuses: 1 } },
            null,
            true,
            null,
            true
          );
        }
      );
    };

    const handleSubmitStatuses = values => {
      const {
        statuses
      } = values || {};

      (statuses || []).map((step, index) => {
        step.displayOrder = index;
      });
      postStatuses({ id, payload: values }, null, true, () => {
        getLocationById({ id, query: { getStatuses: 1 } }, null, true, () => {
          change('tracker-templates-location-form', 'searchTemplates', null);
          this.setState({ showConfigureForm: false });
        });
      });
    };

    const handleTemplateChange = values => {
      if (!values) {
        this.setState({ showConfigureForm: false });
        return;
      }
      this.setState({ editStatuses: [].concat(values) });
      this.setState({ showConfigureForm: true });
    };

    const formattedProgressUpdate = moment(progressDisplayUpdated).format(
      'MMMM Do YYYY h:mm:ss A'
    );

    const showNoSteps = (statuses || []).length < 1;

    return (
      <Layout route="customers-locations-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/locations" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h3>
                Location
              </h3>
              <h2>
                {name || 'N/A'}
              </h2>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" size="sm">
                <Icon fixedWidth name="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersLocationEdit')}
                >
                  Edit Location
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersLocationDelete')}
                >
                  Delete Location
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/locations/${locationId}/details`}>
                  Details
                </Link>
              </li>
              <li className="active">
                <Link to={`/customers/locations/${locationId}/project-tracker`}>
                  Project Tracker
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/users`}>
                  Users
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {!inProgress &&
          <Container>

            <Row>
              <Col sm={6} md={5}>

                <div className="box-bordered">

                  <h4>Project Tracker</h4>
                  <h5>
                    Last Updated:
                    {' '}
                    {progressDisplayUpdated ? formattedProgressUpdate : 'N/A'}
                  </h5>

                  <DisplayProgressForm
                    enableReinitialize
                    handlePutProgressDisplayById={handlePutProgressDisplayById}
                    putProgressDisplayByIdInProgress={
                      putProgressDisplayByIdInProgress || inProgress
                    }
                    progressDisplay={progressDisplay}
                  />

                </div>

              </Col>
              <Col sm={6} md={7}>

                <div className="box-bordered">

                  <h4>Choose from Tracker Templates</h4>
                  {/* <h5>Last Updated on January 16, 2021</h5> */}
                  {selectedTemplate &&
                    <h5>
                      Use the form below to review and save the selected template.
                    </h5>}
                  {!selectedTemplate && <br />}

                  <TrackerTemplateForm
                    handleTemplateChange={handleTemplateChange}
                  />

                </div>

              </Col>
              <Col sm={12}>

                <div className="box-bordered">

                  {!showConfigureForm &&
                    <Button
                      size="sm"
                      variant="primary"
                      className="float-right"
                      type="button"
                      onClick={() => {
                        this.setState({ editStatuses: [].concat(statuses) });
                        showConfigureTrackerForm();
                      }}
                    >
                      Edit
                    </Button>}

                  <h4>
                    Configure Status Tracker
                    {' '}
                    {postStatusesInProgress ? '(Saving...)' : ''}
                  </h4>

                  {!showConfigureForm &&
                    !postStatusesInProgress &&
                    <div className="configure-status-tracker-preview">
                      {(statuses || [])
                        .sort(
                          (a, b) =>
                            parseInt(a.displayOrder) - parseInt(b.displayOrder)
                        )
                        .map(({
                          displayOrder,
                          status,
                          title,
                          comment,
                          dateEst,
                          dateComplete
                        }, index) => (
                          <div key={index} className="step">
                            <Row key={index}>
                              <Col md={12}>
                                <Row>
                                  <Col className="step-number">
                                    <span>{displayOrder + 1}</span>
                                  </Col>
                                  <Col md={2}>
                                    <span className="value">
                                      <b>
                                        Status:
                                      </b>
                                      <br />
                                      <span className="value-value">
                                        {status || ''}
                                      </span>
                                    </span>
                                  </Col>
                                  <Col md={3}>
                                    <span className="value">
                                      <b>
                                        Title:
                                      </b>
                                      <br />
                                      <span className="value-value">
                                        {title || ''}
                                      </span>
                                    </span>
                                  </Col>
                                  <Col md={3}>
                                    <span className="value">
                                      <b>
                                        Due Date:
                                      </b>
                                      <br />
                                      <span className="value-value">
                                        {dateEst
                                          ? moment(dateEst).format('MMM D, Y')
                                          : ' -'}
                                      </span>
                                    </span>
                                  </Col>
                                  <Col md={3}>
                                    <span className="value">
                                      <b>
                                        Complete Date:
                                      </b>
                                      <br />
                                      <span className="value-value">
                                        {dateComplete
                                          ? moment(dateComplete).format(
                                            'MMM D, Y'
                                          )
                                          : ' -'}
                                      </span>
                                    </span>
                                  </Col>
                                </Row>
                                <br />
                                <Row>
                                  <Col md={1} />
                                  <Col md={11}>
                                    <div className="value">
                                      <b>
                                        Comment:
                                      </b>
                                      <br />
                                      <p className="value-value">
                                        {comment}
                                      </p>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        ))}
                      {showNoSteps &&
                        !postStatusesInProgress &&
                        <div style={{ marginTop: '25px', textAlign: 'center' }}>
                          <span
                            style={{
                              textAlign: 'center',
                              marginTop: '20px',
                              backgroundColor: '#f5f8fa',
                              padding: '10px 40px',
                              borderStyle: 'solid',
                              borderWidth: '1px',
                              borderRadius: '5px',
                              borderColor: 'lightgray'
                            }}
                          >
                            {' '}
                            Tracking steps have not been assigned for this location...
                          </span>
                        </div>}
                      {postStatusesInProgress &&
                        <div style={{ marginTop: '25px', textAlign: 'center' }}>
                          <span
                            style={{
                              textAlign: 'center',
                              marginTop: '20px',
                              backgroundColor: '#f5f8fa',
                              padding: '10px 40px',
                              borderStyle: 'solid',
                              borderWidth: '1px',
                              borderRadius: '5px',
                              borderColor: 'lightgray'
                            }}
                          >
                            {' '}
                            Saving...
                          </span>
                        </div>}
                    </div>}

                  {showConfigureForm &&
                    <ConfigureTrackerForm
                      initialValues={{ statuses: editStatuses }}
                      handleCancel={handleConfigureTrackerFormCancel}
                      onSubmit={handleSubmitStatuses}
                      submitting={inProgress || postStatusesInProgress}
                    />}

                </div>

              </Col>
            </Row>

          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCustomersEditLocation
          name="modalCustomersLocationEdit"
          location={location}
          getCompanyResults={getCompanyResults}
          companyID={companyID}
          isStatusPage
        />
        <ModalCustomersDeleteLocation
          name="modalCustomersLocationDelete"
          location={location}
          history={history}
        />
        {getLocationByIdError &&
          <Notification
            key="location-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetLocationById}
          >
            Failed to load location status. Please refresh the page.
          </Notification>}
        {searchCustomersCompaniesError &&
          <Notification
            key="tracker-update-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {putProgressDisplayByIdError &&
          <Notification
            key="display-update-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPutProgressDisplayById}
          >
            Failed to update display settings. Please try again.
          </Notification>}
        {postStatusesError &&
          <Notification
            key="status-update-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostStatuses}
          >
            Failed to update status settings. Please try again.
          </Notification>}
      </Layout>
    );
  }
}

const selector = formValueSelector('tracker-templates-location-form');

const mapState = state => {
  const selectedTemplate = selector(state, 'searchTemplates');

  const {
    result,
    inProgress: getLocationByIdInProgress,
    error: getLocationByIdError
  } = state.getLocationById.toJS();

  const {
    result: getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    result: putProgressDisplayByIdResults,
    inProgress: putProgressDisplayByIdInProgress,
    error: putProgressDisplayByIdError
  } = state.putProgressDisplayById.toJS();

  const {
    inProgress: postStatusesInProgress,
    error: postStatusesError
  } = state.postStatuses.toJS();

  return {
    result,
    getCompanyResults,
    inProgress: getLocationByIdInProgress,
    getLocationByIdError,
    putProgressDisplayByIdInProgress,
    postStatusesInProgress,
    selectedTemplate,
    searchCustomersCompaniesError,
    putProgressDisplayByIdError,
    postStatusesError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getLocationById: fetchDux.getLocationById.createAction,
      clearGetLocationById: fetchDux.getLocationById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction,
      putProgressDisplayById: fetchDux.putProgressDisplayById.createAction,
      clearPutProgressDisplayById: fetchDux.putProgressDisplayById.clearAction,
      postStatuses: fetchDux.postStatuses.createAction,
      clearPostStatuses: fetchDux.postStatuses.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersLocationsStatus);
