import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import CreateCustomersLocationsForm from './CreateCustomersLocationsForm';
import Notification from '../../../ui/Notification';

class ModalEditCustomersLocation extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      location,
      getCompanyResults,
      companyID,
      getLocationById,
      putLocationById,
      isStatusPage,
      putLocationByIdError,
      clearPutLocationById
    } = this.props;

    const {
      id
    } = location || {};

    const initialValues = {
      ...location,
      companyID
    };

    const handlePutLocationById = values => {
      putLocationById(
        { id, payload: values },
        'create-customers-location-form',
        null,
        () => {
          getLocationById(
            { id, query: isStatusPage ? { getStatuses: 1 } : {} },
            null,
            true
          );
        }
      );
    };

    const {
      message
    } = putLocationByIdError || {};

    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Location</Modal.Title>
        </Modal.Header>
        <CreateCustomersLocationsForm
          isEdit
          getCompanyResults={getCompanyResults}
          initialValues={initialValues}
          onSubmit={handlePutLocationById}
        />
        {putLocationByIdError &&
          <Notification
            key="location-update-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPutLocationById}
          >
            {message ? message : 'Failed to update location. Please try again.'}
          </Notification>}
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    result: putLocationByIdResult,
    inProgress: putLocationByIdInProgress,
    error: putLocationByIdError
  } = state.putLocationById.toJS();

  return {
    putLocationByIdResult,
    putLocationByIdInProgress,
    putLocationByIdError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      putLocationById: fetchDux.putLocationById.createAction,
      clearPutLocationById: fetchDux.putLocationById.clearAction,
      getLocationById: fetchDux.getLocationById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalEditCustomersLocation);
