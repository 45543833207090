import React, { Component } from 'react';
import {
  Row,
  Col,
  Badge,
  OverlayTrigger,
  Popover,
  Table
} from 'react-bootstrap';
import numeral from 'numeral';
import moment from 'moment';
import Icon from '../../../ui/Icon';
import PurchaseOrderDetailDiff from './PurchaseOrderDetailDiff';
import PurchaseOrderDetail from '../purchase-order-detail/PurchaseOrderDetail';
import DiffRender from './DiffRender';
import POLocked from './POLocked';
import POHeader from '../purchase-order-detail/POHeader';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import fetchDux from '../../../../state/fetch-dux';

const formatMoney = value => numeral(value).format('0,0.00');

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY');
};

const lStyle = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  display: 'inline',
  marginLeft: '20px'
};

const getLabelStyle = ({ type }) => {
  switch (type) {
    case 'none':
      return {};
    case 'modify':
      return { color: '#ab8106', ...lStyle };
    case 'add':
      return { color: '#04844B', ...lStyle };
    case 'delete':
      return { color: '#C23934', ...lStyle };
    default:
      return {};
  }
};

const getPOStyle = ({ type }) => {
  switch (type) {
    case 'none':
      return {};
    case 'modify':
      return { backgroundColor: '#ffffc7' };
    case 'add':
      return { backgroundColor: '#e3fcef' };
    // return { backgroundColor: '#04844B' };
    case 'delete':
      return { backgroundColor: '#ffebe6' };
    // return { backgroundColor: '#C23934' };
    default:
      return {};
  }
};

const styleRed = {
  backgroundColor: '#ffebe6',
  color: '#172B4D'
};
const styleGreen = {
  backgroundColor: '#e3fcef',
  color: '#172B4D'
};

const moneyFields = ['SumSellPrice', 'NetPrice', 'TotalPrice'];

const ActionPill = (
  {
    action,
    placement
  }
) => {
  const { numActions, actions, lineItemActions } = action || {};
  if (!numActions) return false;
  const allActions = [...(actions || []), ...(lineItemActions || [])];
  const overlay = (
    <Popover id="popover-diif-table" className="popover-diff">
      <Table>
        <thead>
          <tr>
            <th>Action</th>
            <th style={{ minWidth: '100px' }}>Line Item</th>
            <th>Field</th>
            <th>Old Value</th>
            <th>New Value</th>
          </tr>
        </thead>
        <tbody>
          {(allActions || []).map(({
            type,
            PickingSequence,
            field,
            value,
            originalValue
          }) => {
            const isMoney = moneyFields.some(m => m === field);

            return (
              <tr>
                <td><div className={`action ${type}`}>{type}</div></td>
                <td>{PickingSequence}</td>
                <td>{field}</td>
                <td style={type !== 'add' ? styleRed : {}}>
                  {isMoney && <span>${formatMoney(originalValue)}</span>}
                  {!isMoney && `${originalValue || ''}`}
                </td>
                <td style={type !== 'delete' ? styleGreen : {}}>
                  {isMoney && <span>${formatMoney(value)}</span>}
                  {!isMoney && `${value || ''}`}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger="click"
      placement={placement || 'left'}
      overlay={overlay}
    >
      <Badge
        pill
        variant="warning"
        style={{
          float: 'right',
          color: 'white',
          margin: '10px',
          cursor: 'pointer'
        }}
      >
        {numActions}
      </Badge>
    </OverlayTrigger>
  );
};

const getVendorOptions = ({ vendors, manufacturer }) => {
  const {
    CatalogVendorId
  } = manufacturer || {};

  return (vendors || [])
    .filter(({ AQVendorId }) => AQVendorId === CatalogVendorId);
};

class DiffOrderDetailPO extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.state = {
      showDetails: false
    };
  }
  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }
  render() {
    const {
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      action,
      projectVendors,
      lookups,
      handleApprovePurchaseOrder,
      inProgress,
      submit
    } = this.props;

    const { purchaseOrder, actions, numActions, type } = action || {};
    const {
      c_PurchaseOrderAddressId,
      ID: POID,
      c_Approved,
      c_SelectedPurchaseOrderSageVendorID,
      VendorName,
      VendorNum,
      VendorId,
      c_IsColorado,
      Manufacturer
    } = purchaseOrder || {};

    const {
      showDetails
    } = this.state;

    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (purchaseOrder || {}).ID
      );
    const shippingAddress = (addresses || [])
      .find(({ ID }) => ID === c_PurchaseOrderAddressId) || {};
    const {
      c_ShipDate
    } = shippingAddress || {};

    const { vendors } = lookups || {};

    const manufacturer = (projectVendors || [])
      .find(({ VendorId: manID }) => manID === VendorId) || {};

    const vendorOptions = getVendorOptions({ manufacturer, vendors });

    return (
      <div className="purchase-order-summary">
        <div
          className="purchase-order-summary-header"
          style={getPOStyle(action)}
        >
          <button className="btn toggle-details" onClick={this.toggleDetails}>
            <Icon name={showDetails ? 'caret-up' : 'caret-down'} />
          </button>
          <div className="symbol">PO</div>
          <div className="project">
            <div>Purchase Order</div>
            <strong>{purchaseOrder.c_PurchaseOrderNumber}</strong>
          </div>
          <div className="project" style={{ marginLeft: '20px' }}>
            <div>Manufacturer</div>
            <strong>{Manufacturer}</strong>
          </div>
          {type &&
            type !== 'none' &&
            <div style={getLabelStyle(action)}>{type}</div>}
          <POLocked purchaseOrderId={POID} />
          <ActionPill action={action} />
        </div>
        <div className="purchase-order-summary-body">
          <Row>
            <Col sm="3">
              <h5>P.O. No.</h5>
              <div className="value large">
                {purchaseOrder.c_PurchaseOrderNumber}
              </div>
            </Col>
            <Col sm="2">
              <h5>Total Amount</h5>
              <DiffRender
                field="SumSellPrice"
                value={purchaseOrder.SumSellPrice}
                actions={actions}
                renderDiff={value => `$${formatMoney(value)}`}
                diffStyle={{ textAlign: 'right' }}
                render={value => (
                  <div className="value large">
                    ${formatMoney(value)}
                  </div>
                )}
              />

            </Col>
            <Col sm="auto">
              <h5>Ship Date</h5>
              <div className="value large">
                {getDate(c_ShipDate)}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor No.</h5>
              <div className="value small">
                {purchaseOrder.VendorNum}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor</h5>
              <div className="value small">
                {purchaseOrder.VendorName}
              </div>
            </Col>
            <Col sm="auto" className="text-right ml-auto">
              <h5>Net</h5>
              <DiffRender
                field="SumSellPrice"
                value={purchaseOrder.SumSellPrice}
                actions={actions}
                renderDiff={value => `$${formatMoney(value)}`}
                diffStyle={{ textAlign: 'right' }}
                render={value => (
                  <div className="value small">
                    ${formatMoney(value)}
                  </div>
                )}
              />
            </Col>
          </Row>
        </div>
        {!c_Approved &&
          <POHeader
            inProgress={inProgress}
            c_Approved={c_Approved}
            c_SelectedPurchaseOrderSageVendorID={
              c_SelectedPurchaseOrderSageVendorID
            }
            VendorName={VendorName}
            VendorNum={VendorNum}
            handleApproveOrder={handleApprovePurchaseOrder(
              POID,
              `vendor-form-${POID}`
            )}
            submitVendorSelectForm={() => submit(`vendor-form-${POID}`)}
            vendorOptions={vendorOptions}
            c_IsColorado={c_IsColorado}
            showVendor
            formProps={{ form: `vendor-form-${POID}` }}
          />}
        <div
          className={
            `purchase-order-summary-items collapse ${showDetails ? 'show' : ''}`
          }
        >
          <PurchaseOrderDetail
            purchaseOrder={purchaseOrder}
            actions={actions}
            lineItems={lineItems}
            lineItemSpecialInstructions={poItemsInstructions}
            addresses={addresses}
            hidePDF={true}
            showEditDenied
          />
        </div>
      </div>
    );
  }
}

class DiffOrderDetail extends Component {
  render() {
    const {
      result,
      reimportDiff,
      lookups,
      postApprovePurchaseOrder,
      inProgress,
      reload,
      submit
    } = this.props;
    const {
      vendors: projectVendors,
      lineItemSpecialInstructions
    } = result || {};

    const { purchaseOrderActions } = reimportDiff || {};

    const handleApprovePurchaseOrder = (id, formName) =>
      payload =>
        postApprovePurchaseOrder({ id, payload }, formName, null, () => {
          reload();
        });

    return (
      <div>
        {(purchaseOrderActions || [])
          .map((action, i) => (
            <DiffOrderDetailPO
              key={i}
              action={action}
              lineItems={action.lineItems || []}
              lineItemSpecialInstructions={lineItemSpecialInstructions}
              addresses={action.addresses || []}
              projectVendors={projectVendors}
              lookups={lookups}
              handleApprovePurchaseOrder={handleApprovePurchaseOrder}
              inProgress={inProgress}
              submit={submit}
            />
          ))}
      </div>
    );
  }
}

const mapState = state => {
  const {
    error,
    inProgress
  } = state.postApprovePurchaseOrder.toJS();
  return {
    error,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postPurchaseOrderAddress: fetchDux.postPurchaseOrderAddress.createAction,
      postApprovePurchaseOrder: fetchDux.postApprovePurchaseOrder.createAction,
      postPOVendor: fetchDux.postPOVendor.createAction,
      submit: submit
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(DiffOrderDetail);
