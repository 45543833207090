import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const FormLocationSearch = props => {
  const {
    handleSubmit,
    submitting,
    handleClose,
    loadLocations
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <br />

      <Field
        required
        name="location"
        type="text"
        component={FormField.SelectReactAsync}
        placeholder="Search Location"
        validate={[required]}
        loadOptions={loadLocations}
      />

      <div className="text-right">
        <Button variant="outline-secondary" type="button" onClick={handleClose}>
          Cancel
        </Button>
        {' '}
        <Button variant="primary" type="submit" disabled={submitting}>
          Save
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'ecommerce-order-location-form'
})(FormLocationSearch);
