import React from 'react';
import { Link } from 'react-router-dom';
import { Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import Icon from '../../../ui/Icon';
import statusDict from '../statusDict';

const QuoteUser = ({
  customer = {},
  locationUsers,
  quoteStatus,
  locationLoading,
  editing = false,
  quoteUsers,
  handleFormFieldChange
}) => {
  const options = (locationUsers || [])
    .filter(({ id }) => !(quoteUsers || []).includes(id))
    .map(({ nameFirst, nameLast, id }) => ({ label: `${nameFirst} ${nameLast}`, value: id }))
  return (
    <div className="box-bordered">
      {
        editing && quoteStatus !== statusDict.DRAFT ?
          <h4 style={{ marginBottom: '0.4rem' }}>Customer(s)</h4>
          :
          <h4>Customer(s)</h4>
      }

      {editing &&
        quoteStatus !== statusDict.DRAFT &&
        <div className="quote-error-message">
          <div className="icon-div">
            <Icon name="exclamation" size="xs" />
          </div>
          <p className="text-danger">
            This quote is no longer in DRAFT status, removing a user now will revoke their access.
          </p>
        </div>}
      {editing &&
        <Field
          name='selectedUser'
          component={FormField.SelectReact}
          options={options}
          disabled={locationLoading}
          onChange={
            (value) => {
              if (value && !quoteUsers.includes(value)) {
                const users = [...quoteUsers, value];
                handleFormFieldChange('edit-quote-form', 'users', users);
              }
            }
          }
        />
      }
      {
        editing &&
        <div className='pill-group'>
          {
            (quoteUsers || []).map((userId, index) => {
              const user = (locationUsers || []).find(({ id }) => id == userId);

              const { nameFirst, nameLast, id } = user || {};
              return user ? (
                <span key={index} className="pill">
                  {nameFirst} {nameLast}
                  <Icon
                    name="times"
                    onClick={() => {
                      if (quoteUsers.includes(id)) {
                        handleFormFieldChange(
                          'edit-quote-form',
                          'users',
                          quoteUsers.filter(userId => userId !== id)
                        );
                      }
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                </span>
              ) : null
            })
          }
        </div>

      }
      {
        !editing &&
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {
            (quoteUsers || []).map((user, index) => {
              const { Id, firstName, lastName } = user || {};
              return (
                <div
                  style={{ marginBottom: '0.5rem', marginRight: '1rem' }}
                  key={index}
                >
                  <Icon name="user-circle" style={{ marginRight: '5px' }} />
                  {' '}
                  <Link to={`/customers/users/${Id}`}>
                    {firstName} {lastName}
                  </Link>
                </div>
              )
            })
          }
        </div>
      }

    </div>
  );
};

export default QuoteUser;
