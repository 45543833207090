import { fromJS } from 'immutable';
import { put, call, takeLatest } from 'redux-saga/effects';
import { startSubmit, stopSubmit } from 'redux-form';

import { actionNamespace } from './defaults';

const defaultState = fromJS({
  inProgress: false,
  result: null,
  error: null,
  timestamp: new Date().getTime()
});

export default (
  {
    apiFn,
    actionPrefix,
    persist,
    successListeners,
    errorListeners,
    requestListeners,
    successEmit
  }
) => {
  // actions
  const REQUEST = `${actionNamespace}/${actionPrefix}_REQUEST`;
  const SUCCESS = `${actionNamespace}/${actionPrefix}_SUCCESS`;
  const ERROR = `${actionNamespace}/${actionPrefix}_ERROR`;
  const CLEAR = `${actionNamespace}/${actionPrefix}_CLEAR`;
  const MODIFY = `${actionNamespace}/${actionPrefix}_MODIFY`;

  const LISTENERS = (successListeners || [])
    .map(prefix => `${actionNamespace}/${prefix}_SUCCESS`);
  const ERROR_LISTENERS = (errorListeners || [])
    .map(prefix => `${actionNamespace}/${prefix}_ERROR`);
  const REQUEST_LISTENERS = (requestListeners || [])
    .map(prefix => `${actionNamespace}/${prefix}_REQUEST`);

  const EMITTERS = (successEmit || [])
    .map(prefix => `${actionNamespace}/${prefix}`);

  const reducer = (state = defaultState, action) => {
    switch (action.type) {
      case REQUEST: {
        if (persist || action.persist) {
          return state.merge({
            inProgress: action.skipInProgress ? false : true,
            error: null,
            timestamp: new Date().getTime()
          });
        }
        return state.merge({
          inProgress: action.skipInProgress ? false : true,
          result: null,
          error: null,
          timestamp: new Date().getTime()
        });
      }
      case SUCCESS: {
        const { payload: result } = action;
        return state.merge({
          inProgress: false,
          result,
          timestamp: new Date().getTime()
        });
      }
      case ERROR: {
        const { payload: { status, message, stack, jsonData } } = action;
        return state.merge({
          inProgress: false,
          error: { status, message, stack, jsonData },
          timestamp: new Date().getTime()
        });
      }
      case CLEAR:
        return defaultState;
      case MODIFY: {
        const { payload: result } = action;
        return state.merge({
          result,
          timestamp: new Date().getTime()
        });
      }
      default: {
        if (REQUEST_LISTENERS.indexOf(action.type) !== -1) {
          return state.merge({
            inProgress: true,
            result: null,
            error: null,
            timestamp: new Date().getTime()
          });
        }
        if (LISTENERS.indexOf(action.type) !== -1) {
          const { payload: result } = action;
          return state.merge({
            inProgress: false,
            result,
            timestamp: new Date().getTime()
          });
        }
        if (ERROR_LISTENERS.indexOf(action.type) !== -1) {
          const { payload: { status, message, stack, jsonData } } = action;
          return state.merge({
            inProgress: false,
            error: { status, message, stack, jsonData },
            timestamp: new Date().getTime()
          });
        }
        return state;
      }
    }
  };

  const createAction = (values, formId, persist, next, skipInProgress) => ({
    type: REQUEST,
    payload: { values, formId },
    persist,
    next,
    skipInProgress
  });

  const clearAction = () => ({ type: CLEAR });
  const modifyAction = payload => ({ type: MODIFY, payload });

  function* handleRequest(action) {
    const { values, formId } = action.payload;
    const { next } = action || {};

    if (formId) {
      yield put(startSubmit(formId));
    }

    try {
      const result = yield call(apiFn, values);
      yield put({ type: SUCCESS, payload: result });
      for (var i = 0; i < EMITTERS.length; i++) {
        yield put({ type: EMITTERS[i], payload: result });
      }
      if (next) next(result);
    } catch (error) {
      yield put({ type: ERROR, payload: error });
    } finally {
      if (formId) {
        yield put(stopSubmit(formId));
      }
    }
  }

  function* watchForRequest() {
    yield takeLatest(REQUEST, handleRequest);
  }

  return {
    reducer,
    createAction,
    watchForRequest,
    clearAction,
    modifyAction
  };
};
