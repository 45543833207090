import React, { Component } from 'react';
import numeral from 'numeral';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import Table from '../../../ui/Table';
import Icon from '../../../ui/Icon';

import DiffRender from '../projects-update/DiffRender';

const formatMoney = value => numeral(value).format('0,0.00');

const columnsBase = [
  {
    Header: 'ItemNo',
    accessor: 'ItemNumber',
    style: { textAlign: 'center' },
    width: 100,
    Cell: ({ original: row }) => (
      <DiffRender
        field="ItemNumber"
        value={row.ItemNumber}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'Qty',
    accessor: 'Quantity',
    style: { textAlign: 'center' },
    width: 75,
    Cell: ({ original: row }) => (
      <DiffRender
        field="Quantity"
        value={row.Quantity}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'Manufacturer',
    accessor: 'Manufacturer',
    style: { textAlign: 'center' },
    Cell: ({ original: row }) => (
      <DiffRender
        field="Manufacturer"
        value={row.Manufacturer}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'Model No.',
    accessor: 'Model',
    style: { textAlign: 'center' },
    Cell: ({ original: row }) => (
      <DiffRender field="Model" value={row.Model} actions={row.__actions} />
    )
  },
  {
    Header: 'Unit',
    show: false,
    accessor: 'unit', // TODO
    style: { textAlign: 'center' },
    Cell: ({ original: row }) => (
      <DiffRender field="unit" value={row.unit} actions={row.__actions} />
    )
  },
  {
    Header: 'Category',
    show: true,
    accessor: 'Category',
    style: { textAlign: 'center' },
    Cell: ({ original: row }) => (
      <DiffRender
        field="Category"
        value={row.Category}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'Spec',
    accessor: 'Spec',
    style: { textAlign: 'center' },
    Cell: ({ original: row }) => (
      <DiffRender field="Spec" value={row.Spec} actions={row.__actions} />
    )
  },
  {
    Header: 'Markup',
    accessor: 'markup', // TODO
    show: false,
    style: { textAlign: 'center' },
    Cell: ({ original: row }) => (
      <DiffRender field="markup" value={row.markup} actions={row.__actions} />
    )
  },
  {
    Header: 'Sell',
    accessor: 'SellPrice',
    className: 'text-right',
    width: 125,
    Cell: ({ original: row }) => (
      <DiffRender
        field="SellPrice"
        value={row.SellPrice}
        render={value => <span>${formatMoney(value)}</span>}
        renderDiff={value => <span>${formatMoney(value)}</span>}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'List',
    accessor: 'ListPrice',
    className: 'text-right',
    show: false,
    width: 125,
    Cell: ({ original: row }) => (
      <DiffRender
        field="ListPrice"
        value={row.ListPrice}
        render={value => <span>${formatMoney(value)}</span>}
        renderDiff={value => <span>${formatMoney(value)}</span>}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'Net',
    accessor: 'NetPrice',
    className: 'text-right',
    width: 125,
    Cell: ({ original: row }) => (
      <DiffRender
        field="NetPrice"
        value={row.NetPrice}
        render={value => <span>${formatMoney(value)}</span>}
        renderDiff={value => <span>${formatMoney(value)}</span>}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'Custom Column',
    accessor: 'CustomColumn1',
    show: false,
    Cell: ({ original: row }) => (
      <DiffRender
        field="CustomColumn1"
        value={row.CustomColumn1}
        actions={row.__actions}
      />
    )
  },
  {
    Header: 'Taxable',
    accessor: 'Taxable',
    show: false,
    Cell: ({ original: row }) => (
      <DiffRender
        field="Taxable"
        value={row.Taxable}
        actions={row.__actions}
        render={value => `${value}`}
        renderDiff={value => `${value}`}
      />
    )
  }
];

const importStatusColumn = {
  Header: '',
  accessor: 'c_Sage_Status',
  hideColumnDropdown: true,
  fixed: 'right',
  width: 50,
  Cell: ({ value, original: row }) => {
    const {
      c_Sage_Message,
      LineItemId
    } = row || {};
    if (value === null)
      return (
        <div className="text-center text-muted">
          <Icon name="sync" spin />
        </div>
      );
    if (value === true)
      return (
        <div className="text-center text-success">
          <Icon name="check-circle" />
        </div>
      );
    if (value === false)
      return (
        <div className="text-center text-danger">
          <OverlayTrigger
            placement="auto"
            className="text-center text-danger"
            overlay={
              <Popover id={`popover-${LineItemId}`}>
                <span className="text-danger">{c_Sage_Message}</span>
              </Popover>
            }
          >
            <span className="fa fa-exclamation-triangle" />
          </OverlayTrigger>
        </div>
      );
    return <span />;
  }
};

class ItemsTable extends Component {
  shouldComponentUpdate(nextProps) {
    const { show } = nextProps || {};
    const { show: show2 } = this.props;
    const different = show !== show2;
    return !!show || different;
  }
  render() {
    const {
      show,
      data,
      handleSearchChange,
      search,
      showImportStatus,
      hideSearch,
      tableProps
    } = this.props;

    const columns = showImportStatus
      ? [...columnsBase, importStatusColumn]
      : columnsBase;

    const defaultSorted = [
      {
        id: 'Manufacturer',
        desc: false
      },
      {
        id: 'c_PO_OrderIndex',
        desc: false
      },
      {
        id: 'c_DisplayOrder',
        desc: false
      }
    ];

    return (
      <div
        className="project-items-grid"
        style={show ? {} : { display: 'none' }}
      >
        <Table
          showSearch={!hideSearch}
          showColumnsSelector
          search={search}
          onSearchChange={value => handleSearchChange('itemsSearch', value)}
          data={data || []}
          columns={columns}
          defaultSorted={defaultSorted}
          noDataText="No items found"
          sticky
          {...tableProps || {}}
        />
      </div>
    );
  }
}

export default ItemsTable;
