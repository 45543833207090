import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown } from 'react-bootstrap';
import numeral from 'numeral';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Icon from '../../../ui/Icon';
import Table from '../../../ui/Table';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';

import ModalCustomersCompanyReplaceProducts
  from './ModalCustomersCompanyReplaceProducts';
import ModalCustomersEditCompany from '../customers-companies/ModalEditCompany';
import ModalCustomersDeleteCompany
  from '../customers-companies/ModalDeleteCompany';
import ModalCustomersCompanyCreatePackage
  from './ModalCustomersCompanyCreatePackage';
import Notification from '../../../ui/Notification';

const formatMoney = value => numeral(value).format('0,0.00');

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '20',
  sortBy: sortBy || 'category',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

const ReplaceStatus = ({ importProgress }) => {
  const { statusID } = importProgress || {};
  if (statusID === 2)
    return <Icon name="check" style={{ marginRight: '5px' }} />;
  if (statusID === 1)
    return <Icon name="spinner" spin style={{ marginRight: '5px' }} />;
  return false;
};

class CustomersCompaniesProducts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
      nameForm: '',
      featured: '',
      description: '',
      hidePrice: false
    };
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.timeout = null;
    this.pingStatus = this.pingStatus.bind(this);
  }
  componentDidMount() {
    const {
      match,
      getCompanyById,
      getCompanyLookups,
      getProductImportStatus
    } = this.props;
    const { id } = match.params || {};
    getCompanyById({ id, query: { getProducts: 1, getUsers: 1 } }, null, true);
    getCompanyLookups({ companyTypes: 1 });
    getProductImportStatus(id, null, null, this.pingStatus);
  }
  componentWillUnmount() {
    const {
      clearPostPackage,
      clearGetCompanyById
    } = this.props;
    clearPostPackage();
    clearGetCompanyById();
    clearTimeout(this.timeout);

  }
  pingStatus(data) {
    const {
      match,
      getProductImportStatus
    } = this.props;
    const { id } = match.params || {};
    const { statusID } = data || {};
    if (statusID !== 1) return;
    this.timeout = setTimeout(
      () => {
        getProductImportStatus(id, null, null, this.pingStatus);
      },
      3000
    );
  }
  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      result,
      showModal,
      match,
      getConceptsLookupsResult,
      putCompanyById,
      getCompanyById,
      deleteCompanyById,
      history,
      location: { search: searchURL },
      clearPutCompanyById,
      putCompanyByIdError,
      clearDeleteCompanyById,
      deleteCompanyByIdError,
      deleteCompanyByIdInProgress,
      putCompanyByIdInProgress,
      getCustomersCompaniesByIdError,
      getConceptsLookupsError,
      clearGetCompanyById,
      clearGetCompanyLookups,
      selectedProductsForm,
      postPackage,
      postPackageResult,
      postPackageInProgress,
      postPackageError,
      clearPostPackage,
      nameForm,
      description,
      featured,
      hidePrice,
      importProgress
    } = this.props;

    const {
      selectedProducts
    } = this.state;

    const handleAddRemoveProduct = (selectedProductId, add, model) => {
      if (add) {
        this.setState({
          selectedProducts: selectedProducts.concat({
            productId: selectedProductId,
            qty: 1,
            model
          })
        });
        return;
      }
      this.setState({
        selectedProducts: selectedProducts.filter(
          ({ productId }) => productId !== selectedProductId
        )
      });
    };

    const handleChangeQty = (productId, qty) => {
      const results = (selectedProducts || []).map(({ productId }, index) => {
        return { productId, index };
      });
      const { index } = (results || [])
        .find(({ productId: itemProductId }) => itemProductId === productId);
      (selectedProducts[index] || {}).qty = qty;
      this.setState({ selectedProducts: selectedProducts });
    };

    const handleSelectProduct = (selectedProductId, model) => {
      this.setState({ nameForm, featured, description, hidePrice });
      if (
        (selectedProducts || [])
          .find(({ productId }) => productId === selectedProductId)
      ) {
        handleAddRemoveProduct(selectedProductId, false);
        return;
      }
      handleAddRemoveProduct(selectedProductId, true, model);
    };

    const { id: companyId } = match.params || {};

    const columns = [
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value, original }, index) => {
          const {
            model
          } = original || {};
          return (
            <input
              type="checkbox"
              onClick={() => handleSelectProduct(value, model)}
              checked={(selectedProducts || [])
                .find(({ productId }) => productId === value)}
            />
          );
        },
        width: 25
      },
      {
        Header: '',
        accessor: 'imageHref',
        width: 62,
        style: { alignContent: 'center' },
        Cell: ({ value }) => (
          <img
            style={{ width: '50px', height: '50px' }}
            src={value ? value : '/product-placeholder.png'}
          />
        )
      },
      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ value }) => <span className="table-label">{value}</span>,
        style: { textAlign: 'center' }
      },
      {
        Header: 'Product',
        accessor: 'model',
        width: 500,
        Cell: ({ original }) => {
          const {
            model,
            spec
          } = original || {};
          return (
            <div style={{ fontWeight: 'bold' }}>
              {model}
              <p
                style={{
                  fontWeight: 'normal',
                  fontSize: 'smaller',
                  margin: '0px',
                  overflow: 'hidden'
                }}
              >
                {spec}
              </p>
            </div>
          );
        }
      },
      {
        Header: 'Price',
        accessor: 'fullPrice',
        Cell: ({ value }) => <span>${formatMoney(value)}</span>,
        width: 150,
        style: { textAlign: 'right' }
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value, original }) => {
          const url = `${process.env.REACT_APP_PORTAL_ECOMMERCE}/shop/products?productId=${original.id}&shop=${companyId}`;
          return <span><a href={url} target="_blank"> View Product </a></span>;
        },
        style: { textAlign: 'center' }
      }
    ];

    const { products, company } = result || {};

    const hasProducts = (products || []).length > 0;

    const {
      name,
      companyTypeID,
      notes,
      created,
      id,
      jobRefNum,
      supportEmail,
      freightMarkup
    } = company || {};

    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const companyYears = moment().diff(moment(created), 'years');

    const initialValues = {
      name,
      companyTypeID,
      notes,
      jobRefNum,
      supportEmail,
      freightMarkup,
      id
    };

    const {
      companyTypes
    } = getConceptsLookupsResult || {};

    const handleSubmitUpdateCompany = values => {
      putCompanyById(
        { id, payload: values },
        'create-customers-company-form',
        null,
        () => {
          getCompanyById({ id, query: { getUsers: 1 } }, null, true);
        }
      );
    };

    const handleDeleteCompany = () => {
      deleteCompanyById(id, null, null, () => {
        history.push(`/customers/concepts`);
      });
    };

    const handleSubmitPackage = values => {
      values.selectedProducts = selectedProducts;
      postPackage(
        {
          payload: values,
          id
        },
        'create-package-form',
        null,
        () => {
          this.setState({ selectedProducts: [] });
        }
      );
    };

    return (
      <Layout route="customers-companies-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/concepts" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {name || 'Loading...'}
              </h2>
              <h3>
                {!inProgress &&
                  <span>
                    Concept for
                    {' '}
                    {companyYears === undefined ? '-' : companyYears}
                    {' '}
                    years
                  </span>}
                &nbsp;
              </h3>
            </div>
            <div>
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalCustomersCompanyCreatePackage')}
                disabled={!(selectedProducts || []).length}
              >
                Create package
              </Button>
              {' '}
              <Button
                variant="primary"
                size="sm"
                onClick={() =>
                  showModal('modalCustomersCompanyReplaceProducts')}
              >
                <ReplaceStatus importProgress={importProgress} />
                {hasProducts
                  ? 'Update Product Catalog'
                  : 'Create Product Catalog'}
              </Button>
              {' '}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  <Icon fixedWidth name="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyEdit')}
                  >
                    Edit Concept
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyDelete')}
                  >
                    Delete Concept
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/concepts/${companyId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/users`}>Users</Link>
              </li>
              <li className="active">
                <Link to={`/customers/concepts/${companyId}/products`}>
                  Products
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/packages`}>
                  Packages
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/locations`}>
                  Locations
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {!inProgress &&
          <Container>
            <Table
              showSearch
              data={products}
              noDataText="No products found"
              columns={columns}
              search={search}
              onFetchData={this.handleTableChange}
              onSearchChange={this.handleSearchChange}
              defaultSorted={sorted}
              page={getCustomersCompaniesByIdError ? 0 : Number(page)}
              defaultPageSize={Number(pageSize)}
              inProgress={inProgress}
            />
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCustomersCompanyReplaceProducts
          name="modalCustomersCompanyReplaceProducts"
          companyId={companyId}
        />
        <ModalCustomersEditCompany
          name="modalCustomersCompanyEdit"
          initialValues={initialValues}
          companyTypes={companyTypes}
          handleSubmitUpdateCompany={handleSubmitUpdateCompany}
          putCompanyByIdError={putCompanyByIdError}
          clearPutCompanyById={clearPutCompanyById}
          submitting={putCompanyByIdInProgress}
        />
        <ModalCustomersDeleteCompany
          name="modalCustomersCompanyDelete"
          handleDeleteCompany={handleDeleteCompany}
          deleteCompanyByIdError={deleteCompanyByIdError}
          clearDeleteCompanyById={clearDeleteCompanyById}
          submitting={deleteCompanyByIdInProgress}
        />
        <ModalCustomersCompanyCreatePackage
          name="modalCustomersCompanyCreatePackage"
          selectedProducts={selectedProducts}
          products={products}
          handleAddRemoveProduct={handleAddRemoveProduct}
          handleSubmitForm={handleSubmitPackage}
          nameForm={nameForm}
          description={description}
          featured={featured}
          hidePrice={hidePrice}
          handleCloseModal={closeModal => closeModal()}
          submitting={postPackageInProgress}
          handleChangeQty={handleChangeQty}
        />

        {getCustomersCompaniesByIdError &&
          <Notification
            key="products-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyById}
          >
            Products failed to load. Please refresh the page.
          </Notification>}
        {getConceptsLookupsError &&
          <Notification
            key="form-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyLookups}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {postPackageError &&
          <Notification
            key="form-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostPackage}
          >
            Failed to create Package. Please try again.
          </Notification>}
        {postPackageError &&
          <Notification
            key="form-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostPackage}
          >
            Failed to create Package. Please try again.
          </Notification>}
        {postPackageResult &&
          <Notification
            key="create-package-success"
            duration={5}
            closable={true}
            type="success"
            onClose={clearPostPackage}
          >
            Success! Package created.
          </Notification>}

      </Layout>
    );
  }
}

const selector = formValueSelector('create-package-form');

const mapState = state => {
  const selectedProductsForm = selector(state, 'selectedProducts');
  const nameForm = selector(state, 'name');
  const description = selector(state, 'description');
  const featured = selector(state, 'featured');
  const hidePrice = selector(state, 'hidePrice');

  const {
    result,
    inProgress: getCustomersCompaniesByIdInProgress,
    error: getCustomersCompaniesByIdError
  } = state.getCompanyById.toJS();

  const {
    result: getConceptsLookupsResult,
    inProgress: getConceptsLookupsInProgress,
    error: getConceptsLookupsError
  } = state.getConceptsLookups.toJS();

  const {
    result: deleteCompanyByIdResult,
    inProgress: deleteCompanyByIdInProgress,
    error: deleteCompanyByIdError
  } = state.deleteCompanyById.toJS();

  const {
    result: putCompanyByIdResult,
    inProgress: putCompanyByIdInProgress,
    error: putCompanyByIdError
  } = state.putCompanyById.toJS();

  const {
    result: postPackageResult,
    inProgress: postPackageInProgress,
    error: postPackageError
  } = state.postPackage.toJS();

  const {
    result: importProgress
  } = state.getProductImportStatus.toJS();

  return {
    result,
    getConceptsLookupsResult,
    deleteCompanyByIdError,
    putCompanyByIdError,
    deleteCompanyByIdInProgress,
    putCompanyByIdInProgress,
    inProgress: getCustomersCompaniesByIdInProgress,
    getCustomersCompaniesByIdError,
    getConceptsLookupsError,
    selectedProductsForm,
    postPackageResult,
    postPackageInProgress,
    postPackageError,
    nameForm,
    description,
    featured,
    hidePrice,
    importProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction,
      getCompanyLookups: fetchDux.getConceptsLookups.createAction,
      clearGetCompanyLookups: fetchDux.getConceptsLookups.clearAction,
      putCompanyById: fetchDux.putCompanyById.createAction,
      clearPutCompanyById: fetchDux.putCompanyById.clearAction,
      deleteCompanyById: fetchDux.deleteCompanyById.createAction,
      clearDeleteCompanyById: fetchDux.deleteCompanyById.clearAction,
      postPackage: fetchDux.postPackage.createAction,
      clearPostPackage: fetchDux.postPackage.clearAction,
      getProductImportStatus: fetchDux.getProductImportStatus.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersCompaniesProducts);
