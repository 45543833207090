import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const AddCustomersUserForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result,
    handleRemoveUser,
    usersDisplay,
    handleUserChange,
    userOptions,
    noUsersLeft,
    noCompanyUsers,
    handleHideModal,
    isCompany,
    handleChange,
    change
  } = props;

  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        <Field
          // required
          name="searchUsers"
          type="text"
          component={FormField.SelectReact}
          label={
            noCompanyUsers
              ? isCompany
                  ? 'Failed to load users'
                  : 'No users assigned to parent concept'
              : noUsersLeft ? 'All company users added' : 'Search Users'
          }
          placeholder=""
          // validate={[required]}
          options={userOptions}
          onChange={e => {
            handleUserChange(e);
            change('userIDs', ''); //TODO figure out why it does not reset form value
          }}
          isSearchable
          isDisabled={noUsersLeft || noCompanyUsers}
          insetLabel
        />

        <div className="pill-group">
          {(usersDisplay || []).map(({ nameFirst, nameLast, id }, index) => (
            <span key={index} className="pill">
              {nameFirst} {nameLast}
              <Icon
                name="times"
                onClick={() => handleRemoveUser(id)}
                style={{ cursor: 'pointer' }}
              />
            </span>
          ))}
        </div>

      </div>

      <div className="modal-footer">
        <Button
          variant="outline-secondary"
          type="button"
          onClick={handleHideModal}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          Save Users
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'add-customers-user-form'
})(AddCustomersUserForm);
