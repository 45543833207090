import {
  getJson,
  postJson,
  postFile,
  del,
  putJson,
  putFile,
  getFile,
  patchJson
} from './fetch';
import download from 'downloadjs';
import localForage from 'localforage';

const apiBase = process.env.REACT_APP_API_BASE;

export const postLogin = payload =>
  postJson({
    path: `${apiBase}/identity/login`,
    payload
  }).then(result => {
    localForage.setItem('identity', result);
    return result;
  });

export const validateLogin = () =>
  getJson({
    path: `${apiBase}/identity/validate`
  });

export const postProject = payload =>
  postFile({
    path: `${apiBase}/projects`,
    payload
  });

export const getProjectById = id =>
  getJson({
    path: `${apiBase}/projects/${id}`
  });

export const searchProjects = qs =>
  getJson({
    path: `${apiBase}/projects/?${qs}`
  });

export const postUpdateStatus = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/status/${id}`,
    payload
  });

export const postApprovePurchaseOrder = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/purchase-orders/approve/${id}`,
    payload
  });

export const postProjectLineItem = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/projects/${id}/line-items`,
    payload
  });

export const postProjectAddress = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/address/${id}`,
    payload
  });

export const sendOrderInvoice = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/send-order-invoice/${id}`,
    payload
  });

export const getLookups = () =>
  getJson({
    path: `${apiBase}/lookups`
  });

export const getConceptsLookups = query => {
  const include = Object.keys(query).join(',');
  return getJson({
    path: `${apiBase}/lookups/companies?include=${include}`
  });
};

export const postPurchaseOrderAddress = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/purchase-orders/address/${id}`,
    payload
  });

export const postSendPurchaseOrder = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/purchase-orders/send/${id}`,
    payload
  });

export const getProjectImportStatus = id =>
  getJson({
    path: `${apiBase}/projects/import-status/${id}`
  });

export const postPOVendor = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/purchase-orders/vendor/${id}`,
    payload
  });

export const searchVendors = search =>
  getJson({
    path: `${apiBase}/vendors/search/${search}`
  });

export const postVendorJoin = payload =>
  postJson({
    path: `${apiBase}/vendors/join`,
    payload
  });

export const getPurchaseOrderPublic = id =>
  getJson({
    path: `${apiBase}/public/purchase-order/${id}`
  });

export const deleteOrphanedItems = id =>
  del({
    path: `${apiBase}/projects/items/${id}`
  });

export const postAdditionalTotal = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/${id}/additional-totals`,
    payload
  });

export const postFinalizeProject = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/${id}/finalize`,
    payload
  });

export const deleteAdditionalTotal = ({ projectId, id }) =>
  del({
    path: `${apiBase}/projects/${projectId}/additional-totals/${id}`
  });

export const getProjectTaxStatus = id =>
  getJson({
    path: `${apiBase}/projects/${id}/finalize-status`
  });

export const deleteVendorJoin = id =>
  del({
    path: `${apiBase}/vendors/join/${id}`
  });

export const getProjectLogsById = id =>
  getJson({
    path: `${apiBase}/projects/${id}/logs`
  });

export const putEmailLogDisable = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/projects/${id}/disable`,
    payload
  });

export const getSalesReps = () =>
  getJson({
    path: `${apiBase}/administration/sales-reps`
  });

export const delSalesRep = id =>
  del({
    path: `${apiBase}/administration/sales-reps/${id}`
  });

export const putSalesRep = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/administration/sales-reps/${id}`,
    payload
  });

export const postSalesRep = ({ payload }) =>
  postJson({
    path: `${apiBase}/administration/sales-reps`,
    payload
  });

export const getVendors = () =>
  getJson({
    path: `${apiBase}/vendors-view/vendors`
  });

export const delVendor = id =>
  del({
    path: `${apiBase}/administration/vendors/${id}`
  });

export const putVendor = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/administration/vendors/${id}`,
    payload
  });

export const postVendor = ({ payload }) =>
  postJson({
    path: `${apiBase}/administration/vendors`,
    payload
  });

export const getVendorsMappings = () =>
  getJson({
    path: `${apiBase}/vendors-view/vendors-mappings`
  });

export const deleteVendorsMappings = id =>
  del({
    path: `${apiBase}/administration/vendors-mappings/${id}`
  });

export const getManufacturers = () =>
  getJson({
    path: `${apiBase}/vendors-view/manufacturers`
  });

export const postVendorsMapping = ({ payload }) =>
  postJson({
    path: `${apiBase}/administration/vendors-mappings`,
    payload
  });

export const postReimportProject = ({ payload, projectId }) =>
  postFile({
    path: `${apiBase}/projects/${projectId}/reimport`,
    payload
  });

export const getReimportDiff = ({ id, relatedProjectId }) =>
  getJson({
    path: `${apiBase}/projects/${relatedProjectId}/reimport/${id}`
  });

export const postUpdateProjectShipping = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/${id}/shipping`,
    payload
  });

export const getRelatedProjects = id =>
  getJson({
    path: `${apiBase}/projects/${id}/related`
  });

export const postUpdateVendor = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/${id}/purchase-order-vendor`,
    payload
  });

export const getPingVendorStatus = id =>
  getJson({
    path: `${apiBase}/projects/${id}/ping/vendor`
  });

export const postAbortVendorPing = id =>
  postJson({
    path: `${apiBase}/projects/${id}/ping/vendor/abort`,
    payload: {}
  });

export const getPingShippingStatus = id =>
  getJson({
    path: `${apiBase}/projects/${id}/ping/shipping`
  });

export const postAbortShippingPing = id =>
  postJson({
    path: `${apiBase}/projects/${id}/ping/shipping/abort`,
    payload: {}
  });

export const postUpdateAdditionalTotals = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/projects/${id}/additional-totals-update`,
    payload
  });

export const getMiscChargeStatus = id =>
  getJson({
    path: `${apiBase}/projects/${id}/ping/misc-charges`
  });

export const postAbortMiscChargesPing = id =>
  postJson({
    path: `${apiBase}/projects/${id}/ping/misc-charges/abort`,
    payload: {}
  });

export const getPOLocked = id =>
  getJson({
    path: `${apiBase}/purchase-orders/locked/${id}`
  });

export const getOrderLocked = id =>
  getJson({
    path: `${apiBase}/orders/${id}/locked`
  });

export const postCompleteReimportProject = id =>
  postJson({
    path: `${apiBase}/orders/${id}/reimport`,
    payload: {}
  });

export const getVendorStatus = id =>
  getJson({
    path: `${apiBase}/purchase-orders/vendor-status/${id}`
  });

export const getReimportStatus = id =>
  getJson({
    path: `${apiBase}/orders/${id}/import-status`
  });

export const postAbortReimportPing = ({ projectId, pingId }) =>
  postJson({
    path: `${apiBase}/orders/${projectId}/import-status/ping/${pingId}/abort`,
    payload: {}
  });

export const postResendPurchaseOrder = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/purchase-orders/resend/${id}`,
    payload
  });

export const getGeocode = id =>
  getJson({
    path: `${apiBase}/geocode/${id}`
  });

export const postGeoLookup = payload =>
  postJson({
    path: `${apiBase}/geocode`,
    payload
  });

export const postUpdateTaxStatus = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/orders/${id}/tax-status`,
    payload
  });

export const getTaxUpdateStatus = id =>
  getJson({
    path: `${apiBase}/orders/${id}/tax-status/ping`
  });

export const postAbortTaxUpdatePing = ({ projectId, pingId }) =>
  postJson({
    path: `${apiBase}/orders/${projectId}/tax-status/ping/${pingId}/abort`,
    payload: {}
  });

export const cancelProject = id =>
  del({
    path: `${apiBase}/projects/${id}`
  });

export const deleteProject = id =>
  del({
    path: `${apiBase}/orders/${id}/delete`
  });

export const getDeleteProjectStatus = id =>
  getJson({
    path: `${apiBase}/orders/${id}/delete/ping`
  });

export const postAbortDeleteProjectPing = ({ projectId, pingId }) =>
  postJson({
    path: `${apiBase}/orders/${projectId}/delete/ping/${pingId}/abort`,
    payload: {}
  });

export const postCancelResendWarning = ({ id }) =>
  postJson({
    path: `${apiBase}/purchase-orders/resend/${id}/cancel`,
    payload: {}
  });

export const postUpdateLineItemSpecialInstructions = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/orders/${id}/line-item-special-instructions`,
    payload
  });

// WAREHOUSES

export const getWarehouses = search =>
  getJson({
    path: `${apiBase}/warehouses`,
    payload: { ...search, radius: search.radius === -1 ? null : search.radius }
  });

export const getWarehouseById = id =>
  getJson({
    path: `${apiBase}/warehouses/${id}`
  });

export const postWarehouse = ({ payload }) =>
  postJson({
    path: `${apiBase}/warehouses/`,
    payload
  });

export const putWarehouse = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/warehouses/${id}`,
    payload
  });

export const delWarehouse = id =>
  del({
    path: `${apiBase}/administration/warehouses/${id}`
  });

export const postWarehouseComment = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/warehouses/ratings/${id}`,
    payload
  });

// Companies
export const searchCustomersCompanies = qs =>
  getJson({
    path: `${apiBase}/companies/`
  });

export const postCompany = payload =>
  postFile({
    path: `${apiBase}/companies/`,
    payload
  });

export const uploadConceptDocument = payload =>
  postFile({
    path: `${apiBase}/companies/uploadconceptdocument`,
    payload
  });

export const postPackage = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/companies/${id}/package`,
    payload
  });

export const putPackage = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/companies/${id}/package`,
    payload
  });

export const getPackageById = id => {
  return getJson({
    path: `${apiBase}/companies/${id}/package`
  });
};

export const putCompanyById = ({ payload, id }) =>
  putFile({
    path: `${apiBase}/companies/${id}`,
    payload
  });

export const deleteLocationDocuments = payload =>
  postJson({
    path: `${apiBase}/companies/deletelocationdocuments`,
    payload
  });

export const deletePackageById = id =>
  del({
    path: `${apiBase}/companies/${id}/package`
  });

export const deleteCompanyById = id =>
  del({
    path: `${apiBase}/companies/${id}`
  });

export const deleteCompanyDocuments = payload =>
  postJson({
    path: `${apiBase}/companies/deletecompanydocuments`,
    payload
  });

export const getCompanyById = ({ id, query }) => {
  const include = Object.keys(query).join(',');
  return getJson({
    path: `${apiBase}/companies/${id}?include=${include}`
  });
};

export const downloadLocationDocument = ({ id, payload }) => {
  getFile({
    path: `${apiBase}/locations/${id}/downloadLocationDocument`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, id);
    });
};

export const downloadConceptDocument = ({ id, payload }) => {
  getFile({
    path: `${apiBase}/companies/${id}/downloadConceptDocument`,
    payload
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, id);
    });
};

export const postCompanyUsers = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/companies/${id}/users`,
    payload
  });

export const postProductsJson = ({ payload, id }) =>
  postFile({
    path: `${apiBase}/companies/${id}/products/parse`,
    payload
  });

export const getProductImportStatus = id => {
  return getJson({
    path: `${apiBase}/companies/${id}/products/import-progress`
  });
};

export const postSaveProducts = ({ id, payload, addedProducts }) =>
  postJson({
    path: `${apiBase}/companies/${id}/products/save`,
    payload
  });

//Users
export const postUser = payload =>
  postJson({
    path: `${apiBase}/users/`,
    payload
  });

export const putUser = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/users/${id}`,
    payload
  });

export const searchCustomersUsers = qs =>
  getJson({
    path: `${apiBase}/users/`
  });

export const getCustomersUserById = ({ id, query }) => {
  const include = Object.keys(query).join(',');
  return getJson({
    path: `${apiBase}/users/${id}?include=${include}`
  });
};

export const deleteUserById = id =>
  del({
    path: `${apiBase}/users/${id}`
  });

export const resetPasswordById = id =>
  postJson({
    path: `${apiBase}/users/${id}/reset-password`
  });

export const createPasswordById = id =>
  postJson({
    path: `${apiBase}/users/${id}/create-password`
  });

//Locations
export const postLocation = payload =>
  postJson({
    path: `${apiBase}/locations/`,
    payload
  });

export const putLocationById = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/locations/${id}`,
    payload
  });

export const postStatuses = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/locations/${id}/status`,
    payload
  });

export const postLocationUsers = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/locations/${id}/users`,
    payload
  });

export const putProgressDisplayById = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/locations/${id}/progress-tracker`,
    payload
  });

export const deleteLocationById = id =>
  del({
    path: `${apiBase}/locations/${id}`
  });

export const searchCustomersLocations = qs =>
  getJson({
    path: `${apiBase}/locations/`
  });

export const getLocationById = ({ id, query }) => {
  const include = Object.keys(query).join(',');
  return getJson({
    path: `${apiBase}/locations/${id}?include=${include}`
  });
};

export const searchShipments = (payload = {}) =>
  postJson({
    path: `${apiBase}/shipments/search`,
    payload
  });

export const getPoShipments = poId =>
  getJson({
    path: `${apiBase}/shipments/po/${poId}`
  });

export const postShipment = payload =>
  postJson({
    path: `${apiBase}/shipments`,
    payload
  });

export const putShipment = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/shipments/${id}`,
    payload
  });

export const deleteShipment = ({ id, option, poId }) =>
  del({
    path: `${apiBase}/shipments/${id}/${poId}/${option}`
  });

export const deleteQuote = id =>
  del({
    path: `${apiBase}/quotes/${id}`
  });

export const postQuote = payload =>
  postFile({
    path: `${apiBase}/quotes`,
    payload
  });

export const putQuote = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/quotes/${id}`,
    payload
  });

export const searchQuotes = qs =>
  getJson({
    path: `${apiBase}/quotes/?${qs}`
  });

export const getQuoteById = id =>
  getJson({
    path: `${apiBase}/quotes/${id}`
  });

export const shareQuote = ({ id, payload }) =>
  putJson({
    path: `${apiBase}/quotes/share/${id}`,
    payload
  });

export const revokeQuote = ({ id }) => {
  getJson({
    path: `${apiBase}/quotes/revoke/${id}`
  });
};

export const acceptQuote = ({ id, payload }) =>
  postJson({
    path: `${apiBase}/quotes/accept/${id}`,
    payload
  });

export const postFreightQuote = payload =>
  postJson({
    path: `${apiBase}/freight/quote`,
    payload
  });

export const getShipmentLogs = id =>
  getJson({
    path: `${apiBase}/shipments/${id}/logs`
  });

export const getWebTracking = payload =>
  getJson({
    path: `${apiBase}/shipments/carrier-tracking`,
    payload
  });

export const uploadFreight = payload =>
  postFile({
    path: `${apiBase}/freight/upload`,
    payload
  });

export const postFreightBatch = payload =>
  postJson({
    path: `${apiBase}/freight/quote/batch`,
    payload
  });

export const uploadLocationDocument = payload =>
  postFile({
    path: `${apiBase}/companies/uploadlocationdocument`,
    payload
  });

export const cancelProductImport = id =>
  postJson({
    path: `${apiBase}/companies/${id}/products/cancel`,
    payload: {}
  });

export const getReportsLookups = () =>
  getJson({
    path: `${apiBase}/reports/lookups`
  });

export const getReports = payload =>
  getJson({
    path: `${apiBase}/reports`,
    payload
  });

export const getAllOrders = qs =>
  getJson({
    path: `${apiBase}/orders?${qs}`
  });

export const processOrders = payload =>
  postJson({
    path: `${apiBase}/orders/process`,
    payload: payload
  });

export const asyncValidateUserFields = payload =>
  postJson({
    path: `${apiBase}/users/asyncvalidate`,
    payload
  });

export const asyncValidateCompanyFields = payload =>
  postJson({
    path: `${apiBase}/companies/asyncvalidate`,
    payload
  });

export const getOrderById = projectId =>
  getJson({
    path: `${apiBase}/orders/${projectId}`
  });

export const getLocationsByOrder = ({ projectId, search }) =>
  getJson({
    path: `${apiBase}/orders/locations/${projectId}/${search}`
  });

export const updateOrderLocation = ({ projectId, payload }) =>
  putJson({
    path: `${apiBase}/orders/${projectId}`,
    payload
  });

export const createGLOnlyProject = () =>
  postJson({
    path: `${apiBase}/projects/glonly`
  });

export const getQuoteTemplates = () =>
  getJson({
    path: `${apiBase}/quotes/templates`
  });

export const getPoShipmentsLineItems = poId =>
  getJson({
    path: `${apiBase}/shipments/po-shipments-lineitems/${poId}`
  });

export const getShipmentsLineItems = ({ poId, type, data }) =>
  getJson({
    path: `${apiBase}/shipments/shipments-lineitems/${poId}`,
    payload: { type, data }
  });

export const updateShipmentsLineItems = ({ poNum, ...rest }) =>
  putJson({
    path: `${apiBase}/shipments/shipments-lineitems/${poNum}`,
    payload: { ...rest }
  });

export const updateShipmentGroup = ({ poNum, ...rest }) =>
  putJson({
    path: `${apiBase}/shipments/shipments-group/${poNum}`,
    payload: rest
  });

export const removeShipmentAssignments = ({ poNum, ...rest }) =>
  postJson({
    path: `${apiBase}/shipments/remove-assignments/${poNum}`,
    payload: { ...rest }
  });

export const getShipmentsLineItemsLogs = poId =>
  getJson({
    path: `${apiBase}/shipments/logs/shipments-lineitems/${poId}`
  });
export const uploadUsers = payload =>
  postFile({
    path: `${apiBase}/users/upload`,
    payload
  });

export const downloadUsersTemplate = ({ fileName }) =>
  getFile({
    path: `${apiBase}/users/download/template`
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, fileName);
    });

export const uploadLocations = payload =>
  postFile({
    path: `${apiBase}/locations/upload`,
    payload
  });

export const downloadLocationsTemplate = ({ fileName }) =>
  getFile({
    path: `${apiBase}/locations/download/template`
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, fileName);
    });

export const getAdminWarehouses = search =>
  getJson({
    path: `${apiBase}/administration/warehouses`,
    payload: { ...search, radius: search.radius === -1 ? null : search.radius }
  });

export const postAdmintWarehouse = ({ payload }) =>
  postJson({
    path: `${apiBase}/administration/warehouses/`,
    payload
  });

export const putAdminWarehouse = ({ payload, id }) =>
  putJson({
    path: `${apiBase}/administration/warehouses/${id}`,
    payload
  });

export const delAdminWarehouse = id =>
  del({
    path: `${apiBase}/administration/warehouses/${id}`
  });

export const sendContactEmail = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/warehouses/contact-email/${id}`,
    payload
  });
export const sendConfirmationEmail = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/warehouses/confirmation-email/${id}`,
    payload
  });
export const sendAllConfirmationEmails = payload =>
  postJson({
    path: `${apiBase}/warehouses/confirmation-email`,
    payload
  });
export const sendNewProjectEmail = ({ payload, id }) =>
  postJson({
    path: `${apiBase}/warehouses/new-project-email/${id}`,
    payload
  });

export const searchCoupons = () =>
  getJson({
    path: `${apiBase}/coupons`
  });

export const createCoupon = payload =>
  postJson({
    path: `${apiBase}/coupons`,
    payload
  });

export const editCoupon = ({ ID, Code, Amount, Expires }) =>
  putJson({
    path: `${apiBase}/coupons/${ID}`,
    payload: { Code, Amount, Expires }
  });

export const deleteCoupon = id =>
  del({
    path: `${apiBase}/coupons/${id}`
  });

export const getHotlistMissingSchdShipDateShipments = payload => {
  return postJson({
    path: `${apiBase}/hotlist/missing-schdshipdate-shipments`,
    payload
  });
};
export const downloadQuoteById = ({ id }) =>
  getFile({
    path: `${apiBase}/quotes/download/${id}`
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, `quote${id}.pdf`);
    });

export const getHotlistAlteredShipments = payload =>
  postJson({
    path: `${apiBase}/hotlist/altered-shipments`,
    payload
  });

export const acknowledgeShipmentChange = payload =>
  postJson({
    path: `${apiBase}/hotlist/acknowledge`,
    payload
  });

export const editQuoteStatus = ({ id, status }) => {
  return putJson({
    path: `${apiBase}/quotes/status/${id}`,
    payload: { status }
  });
};

export const addMiscShipmentsLineItemsGroup = payload =>
  postJson({
    path: `${apiBase}/shipments/misc-shipments-lineitems-group`,
    payload
  });

export const deleteMiscShipmentsLineItemsGroup = payload =>
  postJson({
    path: `${apiBase}/shipments/delete-misc-shipments-lineitems-group`,
    payload
  });

export const downloadAllPurchaseOrders = (id, JobRef) => {
  return getFile({
    path: `${apiBase}/projects/download/pos/${id}`
  })
    .then(function (response) {
      return response.blob();
    })
    .then(function (blob) {
      download(blob, `${JobRef}_purchaseorders`);
    });
};

export const updateMultipleShipments = payload => {
  return patchJson({
    path: `${apiBase}/shipments/edit-multiple`,
    payload
  });
};


export const getTrackingMoreTracking = payload =>
  getJson({
    path: `${apiBase}/shipments/trackingmore-tracking`,
    payload
  })


export const getCarrierByName = payload =>
  getJson({
    path: `${apiBase}/shipments/carriers/search`,
    payload
  })

export const getAllCarriers = () =>
  getJson({
    path: `${apiBase}/shipments/carriers/all`
  })

export const pingEditMultipleShipmentsStatus = id =>
  getJson({
    path: `${apiBase}/shipments/edit-multiple/${id}`
  })

export const validateTrackingNumber = (payload) =>
  postJson({
    path: `${apiBase}/shipments/validate-tracking-number`,
    payload
  })
