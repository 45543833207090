import React, { useRef } from 'react';
import Table from '../../../ui/Table';
import moment from 'moment-timezone';
import generateAndDownloadXlsx from './generateAndDownloadXlsx';
import { Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const columns = [
  {
    Header: 'Date Logged',
    accessor: 'DateLogged',
    Cell: ({ value }) => {
      if (!value || !moment(value).isValid()) return '';
      return moment(value).tz('America/Chicago').format('MM/DD/YYYY h:mm A zz');
    }
  },
  {
    Header: 'Purchase Order',
    accessor: 'c_PurchaseOrderNumber'
  },
  {
    Header: 'User',
    accessor: 'Name'
  },
  {
    Header: 'Message',
    accessor: 'Message',
    width: 400
  }
];

const defaultSorted = [
  {
    id: 'DateLogged',
    desc: false
  }
];

const getDefaultPageSize = data => {
  const { length } = data || [];
  if (!length || length < 5) return 5;
  return length;
};

const ActivityLogs = ({ logs, JobRef }) => {
  const tableRef = useRef(null);

  const { projectLogs } = logs || {};

  const handleDownload = () =>
    generateAndDownloadXlsx({
      ref: tableRef,
      columns,
      sheetName: 'Project Logs',
      downloadName: `${JobRef}-project-logs.xlsx`,
      wscols: [{ wch: 30 }, { wch: 20 }, { wch: 20 }, { wch: 50 }]
    });

  return (
    <div>
      <div
        style={{ textAlign: 'right', marginBottom: '10px', marginRight: '5px' }}
      >
        <Button size="sm" type="button" onClick={handleDownload}>
          <Icon name="download" />
          &nbsp;
          Download
        </Button>
      </div>
      <Table
        columns={columns}
        data={projectLogs || []}
        showPagination={false}
        defaultPageSize={getDefaultPageSize(projectLogs)}
        defaultSorted={defaultSorted}
        noDataText="No logs exist"
        tableRef={tableRef}
      />
    </div>
  );
};

export default ActivityLogs;
