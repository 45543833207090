import React from 'react';
import classNames from 'classnames';

const Box = ({ children, header, badge, actions, className }) => (
  <div className={classNames('box', className)}>
    {header &&
      <div className="box-header">
        <h3>{header} {badge && <span className="badge">{badge}</span>}</h3>
        {actions &&
          <div className="box-actions">
            {actions}
          </div>}
      </div>}
    <div className="box-body">
      {children}
    </div>
  </div>
);

export default Box;
