import React, { Component } from 'react';
import Layout from '../../../ui/Layout';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import { Row, Col, Button, Table } from 'react-bootstrap';
import { isValid, isSubmitting, submit, reset } from 'redux-form';
import numeral from 'numeral';
import Notification from '../../../ui/Notification';

import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import ProjectInfo from './../projects-detail/ProjectInfo';
import MiscLineItemsForm from './../projects-detail/MiscLineItemsForm';
import MiscChargesPing from './misc-charges/MiscChargesPing';
import ProjectBreadcrumbs from './ProjectBreadcrumbs';

const formatMoney = value => numeral(value).format('0,0.00');

const getAdditionalTotalLines = additionalTotals => {
  return (additionalTotals || []).filter(({ Sell }) => Sell > 0);
};

const AdditionalTotals = (
  {
    disabled,
    total,
    additionalTotals,
    handleDeleteAdditionalTotal
  }
) => {
  const withIdx = (additionalTotals || []).map((v, idx) => ({ ...v, idx }));
  const lines = getAdditionalTotalLines(withIdx || []);

  return (
    <Row>
      <Col sm={12}>
        <Table striped>
          <tbody>
            {(lines || []).map(({
              ID,
              FieldName,
              IsTaxable,
              Sell,
              c_IsCustom,
              idx
            }) => {
              return (
                <tr>
                  <td>{FieldName}</td>
                  <td>{IsTaxable ? 'TAXABLE' : 'NOT TAXABLE'}</td>
                  <td style={{ textAlign: 'right' }}>
                    ${formatMoney(Sell)}
                  </td>
                  <td style={{ textAlign: 'right' }} className="text-muted">
                    <Icon
                      name="minus-circle"
                      style={{
                        cursor: 'pointer'
                      }}
                      onClick={() =>
                        disabled ? null : handleDeleteAdditionalTotal(idx)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

class ProjectsMiscCharges extends Component {
  constructor(props) {
    super(props);
    this.state = { additionalTotals: null, ping: null };
  }
  componentDidMount() {
    const {
      match,
      getProjectById
    } = this.props;
    this.state = { additionalTotals: [] };
    const { id } = match.params || {};
    getProjectById(id, null, null, data => {
      const { additionalTotals } = data || {};
      this.setState({ additionalTotals });
    });
  }

  componentWillUnmount() {
    this.props.clearUpdateAdditionalTotals();
  }

  render() {
    const {
      result,
      inProgress,
      postUpdateAdditionalTotals,
      resetAdditionalTotalsForm,
      postUpdateAdditionalTotalsInprogress,
      postUpdateAdditionalTotalsResult,
      clearUpdateAdditionalTotals
    } = this.props;
    const {
      project,
      total
    } = result || {};
    const { c_SageIntegrationStatus: status, ID } = project || {};

    const { additionalTotals, ping } = this.state;

    const {
      inProgress: pingInProgress,
      error: pingError,
      responsedate,
      Sage_Status,
      aborted
    } = ping || {};

    const disabled = pingError || pingInProgress;
    const success = (Sage_Status || !!responsedate && !aborted) && !!postUpdateAdditionalTotalsResult;

    const handleMiscLineItemsSubmit = payload => {
      const value = {
        ...payload,
        c_IsCustom: true
      };
      this.setState({ additionalTotals: [...additionalTotals, value] });
      resetAdditionalTotalsForm();
    };

    const handleDeleteAdditionalTotal = idx => {
      const payload = (additionalTotals || []).filter((v, i) => i !== idx);
      this.setState({ additionalTotals: payload });
    };

    const submitMiscCharges = () => {
      postUpdateAdditionalTotals({ id: ID, payload: additionalTotals });
    };
    return (
      <Layout route="projects-misc-charges">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs
              project={project}
              current="Update Misc Charges"
            />
            {success &&
              <Notification
                  key="misc-charges-update-success"
                  duration={5}
                  closable={true}
                  type="success"
                  onClose={clearUpdateAdditionalTotals}
                >
                  Success! Misc. Line Items updated.
              </Notification>
            }
            <ProjectInfo
              status={status}
              result={result}
              projectId={ID}
              backButton
            />
            <div className="projects-update-misc-charges-body">

              <MiscLineItemsForm
                onSubmit={handleMiscLineItemsSubmit}
                disabled={disabled}
                initialValues={{ IsTaxable: true }}
              />

              <AdditionalTotals
                disabled={disabled}
                total={total}
                additionalTotals={additionalTotals}
                handleDeleteAdditionalTotal={handleDeleteAdditionalTotal}
              />

              <Row>
                <Col sm="auto" style={{ textAlign: 'right' }}>
                  {!disabled &&
                    <Button
                      type="submit"
                      variant="success"
                      onClick={submitMiscCharges}
                      disabled={postUpdateAdditionalTotalsInprogress}
                    >
                      Save & Submit To Sage
                    </Button>}
                  {!postUpdateAdditionalTotalsInprogress &&
                    <MiscChargesPing
                      projectId={ID}
                      setPing={values => this.setState({ ping: values })}
                    />}
                </Col>
              </Row>

            </div>
          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();

  const {
    result:postUpdateAdditionalTotalsResult,
    inProgress: postUpdateAdditionalTotalsInprogress
  } = state.postUpdateAdditionalTotals.toJS();

  return {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError,
    postUpdateAdditionalTotalsInprogress,
    postUpdateAdditionalTotalsResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectById: fetchDux.getProjectById.createAction,
      postUpdateAdditionalTotals: fetchDux.postUpdateAdditionalTotals.createAction,
      clearUpdateAdditionalTotals: fetchDux.postUpdateAdditionalTotals.clearAction,
      resetAdditionalTotalsForm: () => reset('misc-line-items-form')
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsMiscCharges);
