import React from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../state/fetch-dux';
import authorized from '../../../authorized';
import { Row, Col, Alert } from 'react-bootstrap';
import { withLastLocation } from 'react-router-last-location';

import Layout from '../../ui/Layout';
import LoginForm from './LoginForm';

export const Login = (
  {
    user,
    error,
    location,
    postLogin,
    lastLocation
  }
) => {
  const isAuthorized = authorized({
    user
  });

  const handlePostLogin = values => postLogin(values, 'login');

  if (isAuthorized) return <Redirect to={lastLocation || { pathname: '/' }} />;
  return (
    <Layout route="login" hideNav>
      <Row className="justify-content-sm-center align-items-center">
        <Col sm md={4}>
          {error && <Alert variant="danger">{error.message}</Alert>}
          <LoginForm onSubmit={values => handlePostLogin(values)} />
        </Col>
      </Row>
    </Layout>
  );
};

const mapState = state => {
  const {
    result: user,
    error
  } = state.login.toJS();
  return {
    user,
    error
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postLogin: fetchDux.login.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(withLastLocation(Login));
