import moment from 'moment';
export const getShipmentsDetaiURL = (shipment) => {
    const {
        groupName,
        PoID,
        ID,
        TrackingNum,
        SchdShipDate,
        rank
    } = shipment;
    const formattedShipDate = moment.utc(SchdShipDate).format('MM-DD-YYYY');
    const misc = (groupName || '').startsWith('misc');
    const data = groupName === 'tracking'
        ? TrackingNum
        : groupName === 'scheduled' ? formattedShipDate : 'na';
    const urlGroupName = (groupName || '').startsWith('misc')
        ? 'unassigned'
        : groupName;
    const url = ID
        ? ID === -1 || (groupName || '').startsWith('misc')
            ? `/shipments-groups/${PoID}/${urlGroupName}/${data}?misc=${misc}&rank=${rank}`
            : `/shipments/${ID}/${PoID}`
        : `/shipments/new/${PoID}`;
    return url;
}