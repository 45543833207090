import React, { Component } from 'react';
import Layout from '../../../../ui/Layout';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';
import { Row, Col, Button } from 'react-bootstrap';
import { submit } from 'redux-form';
import { Link } from 'react-router-dom';
import Notification from '../../../../ui/Notification';

import Loading from '../../../../ui/Loading';
import ProjectInfo from '../../projects-detail/ProjectInfo';
import TaxPing from './TaxPing';
import ProjectBreadcrumbs from '../ProjectBreadcrumbs';
import FinalizeOrderForm from '../../projects-detail/FinalizeOrderForm';

const getInitialValues = (
  {
    project
  }
) => {
  const { c_TaxGroup } = project || {};
  return {
    TaxGroup: c_TaxGroup,
    addressType: 'jobsite'
  };
};

const getProjectAddress = ({ project, addresses }) => {
  const { c_FinalAddressID } = project || {};
  return (addresses || []).find(({ ID }) => ID === c_FinalAddressID);
};

class ProjectsMiscCharges extends Component {
  constructor(props) {
    super(props);
    this.state = { additionalTotals: null, ping: null };
  }
  componentDidMount() {
    const {
      match,
      getProjectById
    } = this.props;
    this.state = { additionalTotals: [] };
    const { id } = match.params || {};
    getProjectById(id, null, null, data => {
      const { additionalTotals } = data || {};
      this.setState({ additionalTotals });
    });
  }
  componentWillUnmount() {
    this.props.clearPostUpdateTaxStatus();
  }
  render() {
    const {
      result,
      inProgress,
      submitForm,
      postUpdateTaxStatus,
      postUpdateTaxStatusInProgress,
      postUpdateTaxStatusResult,
      clearPostUpdateTaxStatus
    } = this.props;
    const {
      project
    } = result || {};
    const { c_SageIntegrationStatus: status, ID } = project || {};

    const { ping } = this.state;

    const {
      inProgress: pingInProgress,
      error: pingError,
      responsedate,
      Sage_Status,
      aborted
    } = ping || {};

    const disabled = pingError || pingInProgress;
    const success = (Sage_Status || !!responsedate && !aborted) && !!postUpdateTaxStatusResult;

    const initialValues = getInitialValues(result || {});
    const projectAddress = getProjectAddress(result || {});

    const handleFinalizeSubmit = ({ addressType, TaxGroup, ...address }) => {
      const payload = addressType === 'jobsite'
        ? { ...projectAddress, TaxGroup }
        : { ...address, TaxGroup };

      postUpdateTaxStatus({ id: ID, payload }, 'finalize-order-form');
    };

    return (
      <Layout route="projects-misc-charges">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs project={project} current="Update Tax Status" />
            {success &&
              <Notification
                  key="tax-update-success"
                  duration={5}
                  closable={true}
                  type="success"
                  onClose={clearPostUpdateTaxStatus}
                >
                  Success! Tax Status updated.
              </Notification>
            }
            <ProjectInfo
              status={status}
              result={result}
              projectId={ID}
              backButton
            />
            <div className="projects-update-misc-charges-body">
              <Row>
                <Col sm="12" style={{ marginTop: '20px' }}>
                  <h3>
                    <span style={{ width: '500px', display: 'inline-block' }}>
                      Update Misc Charge Tax Details
                    </span>
                    <Link to={`/projects/${ID}/misc-charges`}>
                      <Button size="sm" variant="primary">
                        Click Here
                      </Button>
                    </Link>
                  </h3>
                  <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>
                    <span style={{ width: '500px', display: 'inline-block' }}>
                      Update Line Item Tax Details
                    </span>
                    <Link to={`/projects/${ID}/import`}>
                      <Button size="sm" variant="primary">
                        Click Here
                      </Button>
                    </Link>
                  </h3>
                  <h3>
                    <span style={{ width: '500px', display: 'inline-block' }}>
                      Update Taxable Address
                    </span>
                    <span
                      style={{
                        fontSize: '1.5rem',
                        color: 'rgba(0, 0, 0, 0.45)',
                        fontWeight: 'bold'
                      }}
                    >
                      BELOW
                    </span>
                  </h3>

                </Col>
              </Row>
              <div className="project-info-body">
                <FinalizeOrderForm
                  initialValues={initialValues}
                  projectAddress={projectAddress}
                  onSubmit={handleFinalizeSubmit}
                  jobsiteLabel="Current Address"
                />
              </div>
              <Row>
                <Col sm="auto" style={{ textAlign: 'right' }}>
                  {!disabled &&
                    <Button
                      type="submit"
                      variant="success"
                      onClick={submitForm}
                      disabled={postUpdateTaxStatusInProgress}
                    >
                      Save & Submit To Sage
                    </Button>}
                  {!postUpdateTaxStatusInProgress &&
                    <TaxPing
                      projectId={ID}
                      setPing={values => this.setState({ ping: values })}
                    />}
                </Col>
              </Row>

            </div>
          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();

  const {
    result:postUpdateTaxStatusResult,
    inProgress: postUpdateTaxStatusInProgress
  } = state.postUpdateTaxStatus.toJS();

  return {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError,
    postUpdateTaxStatusInProgress,
    postUpdateTaxStatusResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectById: fetchDux.getProjectById.createAction,
      postUpdateTaxStatus: fetchDux.postUpdateTaxStatus.createAction,
      clearPostUpdateTaxStatus: fetchDux.postUpdateTaxStatus.clearAction,
      submitForm: () => submit('finalize-order-form')
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsMiscCharges);
