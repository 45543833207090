import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../../state/modals-dux';

class ModalConfirmChanges extends Component {
  render() {
    const {
      openModal,
      name,
      closeModal,
      handleSubmit
    } = this.props;

    const onSubmit = (...props) => {
      handleSubmit(...props);
      closeModal();
    };

    return (
      <Modal show={openModal === name} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Confirm changes
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ fontSize: '14px' }}>
          <div style={{ textAlign: 'center', padding: '20px 0' }}>
            Are you sure you want to save all changes?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={onSubmit} variant="success">
            Yes, Save All Changes
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalConfirmChanges);
