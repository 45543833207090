import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import PurchaseOrderDetail from '../purchase-order-detail/PurchaseOrderDetail';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import PurchaseOrderCardFilter from './PurchaseOrderCardFilter';

const sortPOs = (sort, desc) =>
  (a, b) => {
    const x = (a[sort] || '').toUpperCase();
    const y = (b[sort] || '').toUpperCase();
    if (desc) return y < x ? -1 : y > x ? 1 : 0;
    return x < y ? -1 : x > y ? 1 : 0;
  };

class PurchaseOrdersGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePO: null,
      editing: false,
      poFilter: 2,
      poSort: 'VendorName',
      poDesc: false
    };
    this.getNextPO = this.getNextPO.bind(this);
  }
  componentDidMount() {
    this.getNextPO(this.props);
  }
  componentDidUpdate() {
    this.getNextPO(this.props);
  }
  getNextPO(props) {
    const { result } = props || {};
    const { purchaseOrders } = result || {};
    const { activePO, poSort, poDesc } = this.state;
    const filteredPOs = (purchaseOrders || []).sort(sortPOs(poSort, poDesc));

    if (!filteredPOs || !filteredPOs.length) return;

    if (!activePO) {
      const next = (filteredPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }

    const isActive = filteredPOs.some(({ ID }) => ID === activePO);

    if (isActive) return;

    const lastIndex = filteredPOs.findIndex(({ ID }) => ID === activePO);

    if (lastIndex + 1 === filteredPOs.length) {
      const next = (filteredPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }

    const sliced = filteredPOs.slice(lastIndex + 1);

    if (!sliced || !sliced.length) {
      const next = (filteredPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }
    const next = (sliced[0] || {}).ID;
    return this.setState({ activePO: next });
  }
  render() {
    const {
      result,
      lookups,
      showEdit,
      postPurchaseOrderAddress
    } = this.props;
    const { activePO, poFilter, poSort, poDesc, editing } = this.state;
    const {
      purchaseOrders,
      lineItems,
      addresses,
      vendors: projectVendors,
      lineItemSpecialInstructions
    } = result || {};
    const setActive = ID => this.setState({ activePO: ID, editing: false });
    const po = (purchaseOrders || []).find(({ ID }) => ID === activePO);
    const poItems = (lineItems || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (po || {}).PurchaseOrderId
      );
    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(({ PurchaseOrderId }) => PurchaseOrderId === (po || {}).ID);

    const setPOSort = (sort, desc) =>
      this.setState({ poSort: sort, poDesc: desc });
    const setPOFilter = values => this.setState(values);
    const toggleEditing = value => this.setState({ editing: value });

    const handlePostPurchaseOrderAddress = values =>
      postPurchaseOrderAddress(values, 'edit-shipping-form', null, () => {
        this.setState({ editing: false });
      });

    return (
      <Row>
        <Col md="4">
          <PurchaseOrderCardFilter
            setPOFilter={setPOFilter}
            poFilter={poFilter}
            setPOSort={setPOSort}
            poSort={poSort}
            poDesc={poDesc}
            purchaseOrders={purchaseOrders}
            setActive={setActive}
            activePO={activePO}
            isApproved={() => true}
            options={[{ value: 2, label: 'All' }]}
            editing={editing}
          />
        </Col>
        <Col md="8">
          {po &&
            <PurchaseOrderDetail
              showEdit={showEdit}
              purchaseOrder={po}
              lineItems={poItems}
              lineItemSpecialInstructions={poItemsInstructions}
              addresses={addresses}
              lookups={lookups}
              projectVendors={projectVendors}
              pdfDetails={(po || {}).pdfDetails}
              toggleEditing={toggleEditing}
              editing={editing}
              handlePostPurchaseOrderAddress={handlePostPurchaseOrderAddress}
            />}
        </Col>
      </Row>
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postPurchaseOrderAddress: fetchDux.postPurchaseOrderAddress.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(PurchaseOrdersGrid);
