import React, { Component } from 'react';
import Layout from '../../../ui/Layout';
import { connect } from 'react-redux';
import fetchDux from '../../../../state/fetch-dux';
import { bindActionCreators } from 'redux';

import Loading from '../../../ui/Loading';
import ProjectInfo from './../projects-detail/ProjectInfo';
import ProjectsUpdateLayout from './ProjectsUpdateLayout';
import VendorPO from './vendors/VendorPO';
import ProjectBreadcrumbs from './ProjectBreadcrumbs';

class ProjectsUpdateVendors extends Component {
  componentDidMount() {
    const {
      match,
      getProjectById,
      getLookups
    } = this.props;
    const { id } = match.params || {};
    getProjectById(id);
    getLookups();
  }
  render() {
    const {
      result,
      lookups,
      inProgress,
      postUpdateVendor,
      postUpdateVendorInProgress
    } = this.props;
    const {
      project,
      purchaseOrders,
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      vendors: projectVendors
    } = result || {};

    const { c_SageIntegrationStatus: status, ID } = project || {};

    return (
      <Layout route="projects-update-vendors">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs project={project} current="Update Vendors" />

            <ProjectInfo
              status={status}
              result={result}
              projectId={ID}
              backButton
            />
            <ProjectsUpdateLayout
              result={result}
              Page1={
                <div>
                  {(purchaseOrders || [])
                    .map((po, i) => (
                      <VendorPO
                        key={po.ID}
                        projectId={ID}
                        purchaseOrder={po}
                        lineItems={lineItems}
                        lineItemSpecialInstructions={
                          lineItemSpecialInstructions
                        }
                        addresses={addresses}
                        projectVendors={projectVendors}
                        lookups={lookups}
                        postUpdateVendor={postUpdateVendor}
                        postUpdateVendorInProgress={postUpdateVendorInProgress}
                      />
                    ))}
                </div>
              }
            />

          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();
  const {
    result: lookups
  } = state.getLookups.toJS();
  const {
    inProgress: postUpdateVendorInProgress
  } = state.postUpdateVendor.toJS();
  return {
    result,
    lookups,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError,
    postUpdateVendorInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getProjectById: fetchDux.getProjectById.createAction,
      getLookups: fetchDux.getLookups.createAction,
      postUpdateVendor: fetchDux.postUpdateVendor.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsUpdateVendors);
