import React, { Component, createElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const renderComponent = (
  { render, component, validateLoginResult, adminOnly, allowWarehouseAdmin }
) =>
  props => {
    const {
      result,
      error,
      inProgress
    } = validateLoginResult || {};
    const { isAdmin, username, isWarehouseAdmin } = result || {};
    if (error) return <Redirect to="/login" />;
    if (!inProgress &&
      adminOnly &&
      !isAdmin &&
      (!allowWarehouseAdmin || (allowWarehouseAdmin && !isWarehouseAdmin))
    ) return <Redirect to="/" />;
    if (result) return render ? render(props) : createElement(component, props);
    return <div />;
  };

class SecureRoute extends Component {
  render() {
    const {
      component,
      render,
      validateLoginResult,
      permissions,
      adminOnly,
      allowWarehouseAdmin,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={renderComponent({
          render,
          component,
          validateLoginResult,
          adminOnly,
          allowWarehouseAdmin
        })}
      />
    );
  }
}

const mapState = state => ({ validateLoginResult: state.validateLogin.toJS() });

export default connect(mapState)(SecureRoute);
