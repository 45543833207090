import { actionNamespace } from './defaults';

const SET_TYPING = `${actionNamespace}/SET_TYPING`;

const defaultState = false;

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_TYPING:
      return payload;
    default:
      return state;
  }
};

export const actionCreators = {
  setTyping: payload => ({ type: SET_TYPING, payload })
};
