import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';

const required = value => value ? undefined : 'Required';

const validateFileType = value => {
  if (!value) return undefined;
  const { type } = value || {};
  if (type !== 'image/png' && type !== 'image/jpeg')
    return 'Invalid file type. Only png and jpeg files allowed.';
  return undefined;
};

const validateEmail = email => {
  if (!email) return undefined;
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase()) ? undefined : 'Invalid Email';
};

const validateNumber = value => {
  if (!value) return undefined;
  return Number.isNaN(parseFloat(value))
    ? 'Please enter a number.'
    : value > 0 ? undefined : 'Number must be greater than 0.';
};

const validateFieldsAsync = (values, dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(
      fetchDux.asyncValidateCompanyFields.createAction(
        values,
        null,
        null,
        result => {
          resolve(result);
        }
      )
    );
  }).then(result => {
    const { contains, message } = result;
    if (contains) {
      throw { jobRefNum: message };
    }
  });
};

const ModalCreateCustomersCompany = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    result,
    isEdit,
    companyTypes
  } = props;

  const options = (companyTypes || []).map(({ companyType, id }) => ({
    value: id,
    label: companyType
  }));

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">

        <Field
          required
          name="name"
          type="text"
          component={FormField.Input}
          label="Concept Name"
          validate={[required]}
          insetLabel
        />

        <Field
          required
          name="companyTypeID"
          type="text"
          component={FormField.SelectReact}
          options={options}
          label="Concept Type"
          placeholder=""
          validate={[required]}
          insetLabel
        />

        <Field
          name="notes"
          rows={6}
          component={FormField.Textarea}
          label="Notes"
          insetLabel
        />

        <Field
          name="logo"
          type="file"
          component={FormField.InputFileUpload}
          label="Upload Concept Logo"
          accept="image/*"
          validate={[validateFileType]}
        />

        <Field
          required
          name="supportEmail"
          type="text"
          component={FormField.Input}
          label="Support Email"
          help="Send e-commerce support to email address(es)"
          insetLabel
          validate={[required, validateEmail]}
        />

        <Field
          required
          name="freightMarkup"
          type="text"
          component={FormField.Input}
          label="Freight Markup"
          help="Freight Markup %"
          validate={[required]}
          insetLabel
          validate={[required, validateNumber]}
        />

        <Field
          required
          name="jobRefNum"
          type="text"
          component={FormField.Input}
          label="Job REF Number"
          validate={[required]}
          insetLabel
        />

      </div>

      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          {!isEdit && <span>Create Concept</span>}
          {isEdit && <span>Save Concept</span>}
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'create-customers-company-form'
  // CSIECO-459 disabled JobRefs validation
  //  asyncValidate: validateFieldsAsync,
  //  asyncBlurFields: ['jobRefNum']
})(ModalCreateCustomersCompany);
