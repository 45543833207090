import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, Form } from 'react-bootstrap';
import AccordionCard from './AccordionCard';
import FormField from '../../../forms/FormField';

export default (
  {
    data,
    purchaseOrder,
    setOpen,
    openTabs
  }
) => {
  const { shipper, shippingAddress, c_PurchaseOrderNumber } = data || {};
  const { Name } = shipper || {};
  const {
    FirstName,
    LastName,
    c_Attn,
    Address1,
    Address2,
    City,
    State,
    c_Zip_4,
    Zip,
    c_AttnNameOnly,
    PhoneContact

  } = shippingAddress || {};

  const getAddressLine = value => {
    if (!value) return '';
    return <span>{value}<br /></span>;
  };
  return (
    <AccordionCard
      controlled
      title="General Information"
      className="general-form"
      setOpen={setOpen}
      open={openTabs['generalForm']}
      name="generalForm"
    >
      <Row>
        <Col md={6}>
          <Row>
            <Col md={4}><Form.Label>Vendor:</Form.Label></Col>
            <Col md={8}>{(purchaseOrder || {}).VendorName}</Col>
          </Row>
          <Row>
            <Col md={4}><Form.Label>Order Entry Number:</Form.Label></Col>
            <Col md={8}>{data.OrderEntryNum}</Col>
          </Row>
          <Row>
            <Col md={4}><Form.Label>Requested Ship Via:</Form.Label></Col>
            <Col md={8}>{Name || data.ShipVia}</Col>
          </Row>
          <Row>
            <Col md={4}><Form.Label>Carrier Exception ?:</Form.Label></Col>
            <Col md={8}>{data.CarrierException}</Col>
          </Row>
        </Col>
        <Col md={6}>
          <Form.Group>
            <Form.Label>Ship To:</Form.Label>
            <h5 style={{ fontSize: '11pt', fontWeight: 'bold', margin: 0 }}>Concept Services Inc</h5>
            <h5 style={{ fontSize: '11pt', fontWeight: 'bold', margin: 0 }}>{' C/O: '}{(shippingAddress || {}).Name || ''}</h5>
            {
              c_Attn &&
              <h5 style={{ fontSize: '11pt', fontWeight: 'bold', margin: 0 }}>
                Attn: {c_Attn}
              </h5>
            }
            {getAddressLine((FirstName || LastName ? `${FirstName || ''} ${LastName || ''}` : ''))}
            {getAddressLine(Address1 || '')}
            {getAddressLine(Address2 || '')}
            <span>{City || ''}, {State || ''} {Zip || ''} {c_Zip_4 ? c_Zip_4 : ''}</span>
            {!c_AttnNameOnly ?
              <h5 style={{ fontSize: '11pt', fontWeight: 'bold', margin: 0 }}>
                Mark For PO #: {c_PurchaseOrderNumber}
              </h5>
              : null}
            <div>
              <strong>Phone:</strong> {PhoneContact || ''}
            </div>
          </Form.Group>
        </Col>
      </Row>
    </AccordionCard>
  );
};
