import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import Box from '../../../ui/Box';
import Layout from '../../../ui/Layout';
import Loading from '../../../ui/Loading';
import Table from '../../../ui/Table';
import moment from 'moment';
import ModalCreateCoupon from './ModalCreateCoupon';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';
import ModalEditCoupon from './ModalEditCoupon';

const getColumns = ({
    editClickHandler,
    deleteClickHandler,
    cancelClickHandler,
    confirmDeleteHandler,
    deleteInProgress
}) => {
    return [
        {
            Header: 'Code',
            accessor: 'Code',
            className: 'text-center'
        },
        {
            Header: 'Amount',
            accessor: 'Amount',
            className: 'text-center',
            Cell: ({ value }) => <span>{`$${value}`}</span>
        },
        {
            Header: 'Created',
            accessor: 'Created',
            className: 'text-center',
            Cell: ({ value }) => <span>{moment(value).isValid() ? moment(value).format('MM/DD/YYYYY') : '-'}</span>
        },
        {
            Header: 'Expires',
            accessor: 'Expires',
            className: 'text-center',
            Cell: ({ value }) => <span>{moment(value).isValid() ? moment(value).format('MM/DD/YYYYY') : '-'}</span>
        },
        {
            Header: 'Redeemed',
            accessor: 'Redeemed',
            className: 'text-center',
            Cell: ({ value }) => <span>{moment(value).isValid() ? moment(value).format('MM/DD/YYYYY') : '-'}</span>
        },
        {
            Header: 'Applied Order',
            accessor: 'AppliedOrder',
            className: 'text-center'
        },
        {
            Header: 'IsDeleted',
            accessor: 'IsDeleted',
            className: 'text-center',
            Cell: ({ value }) => <span>{value ? 'Yes' : 'No'}</span>
        },
        {
            Header: 'Actions',
            width: 400,
            Cell: ({ original }) => {
                const { deleting } = original;
                if (deleting) {
                    return (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            Are you sure you and delete the coupon?
                            <div
                                style={{
                                    cursor: 'pointer',
                                    marginRight: '1rem',
                                    color: 'red',
                                    marginLeft: '1rem',
                                    opacity: deleteInProgress ? 0.5 : 1
                                }}
                                onClick={
                                    () => confirmDeleteHandler(original)
                                }
                            >
                                <Icon name='check' />
                            </div>

                            <div
                                style={{
                                    cursor: 'pointer',
                                    color: 'orange',
                                    opacity: deleteInProgress ? 0.5 : 1
                                }}
                                onClick={() => cancelClickHandler({ fromDelete: true })}
                            >
                                <Icon name='times' />
                            </div>
                        </div >
                    )
                }
                return (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <div
                            style={{ cursor: 'pointer', marginRight: '2rem' }}
                            onClick={
                                () => editClickHandler(original)
                            }
                        >
                            <Icon name='edit' />
                        </div>
                        <div style={{ cursor: 'pointer' }}
                            className='text-danger'
                            onClick={() => deleteClickHandler(original)}>
                            <Icon name='trash' />
                        </div>
                    </div>
                )
            }
        }
    ];
};

class CouponsSearch extends Component {

    constructor(props) {
        super(props);
        this.state = { filter: 'active', editing: [], deleting: [] };
    }

    componentDidMount() {
        const { searchCoupons } = this.props;
        searchCoupons();
    }


    editClickHandler = (original) => {
        const modalPayload = {
            modalName: 'EditCouponModal',
            payload: original
        }
        const { showModal } = this.props;
        showModal(modalPayload);
    }

    deleteClickHandler = ({ ID }) => {
        this.setState({ deleting: [ID] });
    }

    cancelClickHandler = ({ fromEdit, fromDelete }) => {
        if (fromDelete) {
            this.setState({ deleting: [] })
        }
        else if (fromEdit) {
            this.setState({ editing: [] });
        }
    }

    confirmDeleteHandler = ({ ID }) => {
        const { deleteCoupon } = this.props;
        deleteCoupon(ID, null, null, () => {
            this.setState({ deleting: [] });
        });
    }


    getFilteredData = () => {
        const { filter, editing, deleting } = this.state;
        const { couponsResult } = this.props;

        const filteredCoupons = filter === 'all' ?
            couponsResult :
            filter === 'active' ? (couponsResult || []).filter(({ IsDeleted }) => !IsDeleted)
                :
                (couponsResult || []).filter(({ IsDeleted }) => IsDeleted);


        return filteredCoupons.map((coupon) => {
            const { ID } = coupon;
            return deleting.includes(ID) ? { ...coupon, deleting: true } :
                editing.includes(ID) ? { ...coupon, editing: true } : coupon
        });

    }

    render() {

        const {
            inProgress,
            showModal,
            getCouponError,
            createCouponError,
            editCouponError,
            deleteCouponError,
            deleteInProgress
        } = this.props;
        const { filter } = this.state;
        const columns = getColumns({
            editClickHandler: this.editClickHandler,
            deleteClickHandler: this.deleteClickHandler,
            cancelClickHandler: this.cancelClickHandler,
            confirmDeleteHandler: this.confirmDeleteHandler,
            deleteInProgress
        });
        const data = this.getFilteredData();
        return (
            <Layout route="coupons-search" alt>
                <div className="layout-app-header">
                    <Container>
                        <div>
                            <h2>
                                Coupons
                            </h2>
                        </div>
                        <div>
                            <Button
                                variant='primary'
                                size='sm'
                                onClick={
                                    () => showModal('CreateCouponModal')
                                }
                            >
                                Create Coupon
                            </Button>
                        </div>
                    </Container>
                </div>

                {inProgress &&
                    <Loading
                        fullPage
                        color="#04844B"
                        message="Loading, Please Wait..."
                    />}
                {
                    !inProgress &&
                    <Container>
                        <div style={{ marginBottom: '1rem' }}>
                            <ButtonGroup size='sm'>
                                <Button
                                    variant={filter === 'all' ? 'primary' : 'secondary'}
                                    onClick={
                                        () => this.setState({ filter: 'all' })
                                    }
                                    className='filter-button extra'
                                >
                                    All
                                </Button>
                                <Button
                                    variant={filter === 'active' ? 'primary' : 'secondary'}
                                    onClick={
                                        () => this.setState({ filter: 'active' })
                                    }
                                    className='filter-button'
                                >
                                    Active
                                </Button>
                                <Button
                                    variant={filter === 'deleted' ? 'primary' : 'secondary'}
                                    onClick={
                                        () => this.setState({ filter: 'deleted' })
                                    }
                                    className='filter-button'
                                >
                                    Deleted
                                </Button>
                            </ButtonGroup>
                        </div>
                        <Box className="box-table">
                            <Table
                                columns={columns}
                                data={data || []}
                                showPagination={true}
                                noDataText="No coupons found"
                            />
                        </Box>
                    </Container>
                }

                <ModalCreateCoupon
                    name='CreateCouponModal'
                />
                <ModalEditCoupon
                    name='EditCouponModal'
                />
                {
                    getCouponError &&
                    <Notification
                        key="get-coupon-error"
                        duration={5}
                        closable={true}
                        type="danger"
                    >
                      Error getting list of coupons. Please try again.
                    </Notification>
                }

                {
                    createCouponError &&
                    <Notification
                        key="create-coupon-error"
                        duration={5}
                        closable={true}
                        type="danger"
                    >
                        {createCouponError.message || 'Error creating coupon.'} Please try again.
                    </Notification>
                }

                {
                    editCouponError &&
                    <Notification
                        key="edit-coupon-error"
                        duration={5}
                        closable={true}
                        type="danger"
                    >
                        Error editing a coupon. Please try again.
                    </Notification>
                }
                {
                    deleteCouponError &&
                    <Notification
                        key="delete-coupon-error"
                        duration={5}
                        closable={true}
                        type="danger"
                    >
                        Error deleting a coupon. Please try again.
                    </Notification>
                }
            </Layout>
        )
    }
}


const mapState = state => {
    const {
        inProgress,
        result: couponsResult,
        error: getCouponError
    } = state.searchCoupons.toJS();

    const {
        error: createCouponError
    } = state.createCoupon.toJS();

    const {
        error: editCouponError
    } = state.editCoupon.toJS();

    const {
        error: deleteCouponError,
        inProgress: deleteInProgress
    } = state.deleteCoupon.toJS();

    return {
        inProgress,
        couponsResult,
        createCouponError,
        getCouponError,
        editCouponError,
        deleteCouponError,
        deleteInProgress
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        ...actionCreators,
        searchCoupons: fetchDux.searchCoupons.createAction,
        deleteCoupon: fetchDux.deleteCoupon.createAction
    }, dispatch)

export default connect(mapState, mapDispatch)(CouponsSearch);