import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCustomersEditLocation
  from '../customers-locations/ModalEditLocation';
import ModalCustomersDeleteLocation
  from '../customers-locations/ModalDeleteLocation';
import Notification from '../../../ui/Notification';

class CustomersLocationsDetail extends Component {
  componentDidMount() {
    const {
      match,
      getLocationById,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getLocationById({ id, query: {} }, null, true);
    searchCustomersCompanies();
  }
  render() {
    const {
      inProgress,
      getLocationByIdError,
      clearGetLocationById,
      result,
      showModal,
      match,
      getCompanyResults,
      history,
      clearSearchCustomersCompanies,
      searchCustomersCompaniesError
    } = this.props;

    const {
      location,
      company,
      users,
      created
    } = result || {};

    const {
      name,
      address1,
      address2,
      city,
      postalCode,
      stateProvince
    } = location || {};

    const {
      companyName,
      companyID
    } = company || {};

    const { id: locationId } = match.params || {};

    const fullAddressTop = `${address1 || ''} ${address2 || ''}`;
    const fullAddressBottom = `${city || ''}, ${stateProvince || ''} ${postalCode || ''}`;

    const formattedDate = moment(created).format('MMMM Do YYYY');

    return (
      <Layout route="customers-locations-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/locations" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h3>
                Location
              </h3>
              <h2>
                {name || 'N/A'}
              </h2>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" size="sm">
                <Icon fixedWidth name="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersLocationEdit')}
                >
                  Edit Location
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => showModal('modalCustomersLocationDelete')}
                >
                  Delete Location
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li className="active">
                <Link to={`/customers/locations/${locationId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/project-tracker`}>
                  Project Tracker
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/users`}>
                  Users
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {!inProgress &&
          <Container>
            <Row>
              <Col sm={6} md={5}>

                <div className="box-bordered">

                  <h4>Location Overview</h4>
                  <h5>Location since {formattedDate || 'N/A'}</h5>

                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <Icon fixedWidth name="flag" /> {name || 'N/A'}
                        </th>
                        <td />
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="map-marker" /> {fullAddressTop}
                          <div style={{ marginLeft: '31px' }}>
                            {fullAddressBottom}
                          </div>
                        </th>
                        <td>
                          <Link to="#" />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="building" /> {companyName}
                        </th>
                        <td>
                          <Link to="#" />
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="user-circle" />
                          {' '}
                          {(users || []).length}
                          {' '}
                          Users
                        </th>
                        <td>
                          <Link to="#" />
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>

              </Col>
            </Row>
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCustomersEditLocation
          name="modalCustomersLocationEdit"
          location={location}
          getCompanyResults={getCompanyResults}
          companyID={companyID}
        />
        <ModalCustomersDeleteLocation
          name="modalCustomersLocationDelete"
          location={location}
          history={history}
        />
        {getLocationByIdError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetLocationById}
          >
            Failed to load location details. Please refresh the page.
          </Notification>}
        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getLocationByIdInProgress,
    error: getLocationByIdError
  } = state.getLocationById.toJS();

  const {
    result: getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  return {
    result,
    getCompanyResults,
    inProgress: getLocationByIdInProgress,
    getLocationByIdError,
    searchCustomersCompaniesError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getLocationById: fetchDux.getLocationById.createAction,
      clearGetLocationById: fetchDux.getLocationById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersLocationsDetail);
