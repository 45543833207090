import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button } from 'react-bootstrap';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Loading from '../../../ui/Loading';
import CustomersUsersSearchResults from './CustomersUsersSearchResults';
import { actionCreators } from '../../../../state/modals-dux';
import ModalImportUser from './ModalImportUser';

import ModalCreateUser from './ModalCreateUser';
import Notification from '../../../ui/Notification';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '25',
  sortBy: sortBy || 'firstName',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class CustomersUsersSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchCustomersUsers = this.handleSearchCustomersUsers.bind(
      this
    );
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    // const { location: { search } } = this.props;
    // const query = getQueryFromSearch(search);
    // const qs = queryString.stringify(query);
    this.props.searchCustomersUsers();
    this.props.searchCustomersCompanies();
  }
  // componentWillReceiveProps(nextProps) {
  //   const { location: { search } } = nextProps;
  //   const { location: { search: search2 } } = this.props;

  //   if (search === search2) return;

  //   const query = getQueryFromSearch(search);
  //   const qs = queryString.stringify(query);
  //   this.props.searchCustomersUsers(qs);
  // }
  handleSearchCustomersUsers(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchCustomersUsers({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchCustomersUsers(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      error,
      result,
      location: { search },
      showModal,
      searchCustomersCompaniesResult,
      uploadError,
      clearUploadUsers
    } = this.props;

    const paging = getQueryFromSearch(search);
    const { search: searchUsers = '' } = paging;

    const filteredUsers = (result || []).filter((user) => {
      const { nameFirst, nameLast, email } = user;
      if (
        (nameFirst || '').indexOf(searchUsers) >= 0 ||
        (nameLast || '').indexOf(searchUsers) >= 0 ||
        (email || '').indexOf(searchUsers) >= 0
      ) {
        return user;
      }
    })

    return (
      <Layout route="customers-users-search" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Users
              </h2>
              <h3>
                {!inProgress && <span>{(result || []).length} Users</span>}
              </h3>
            </div>
            <div>
              <Button
                size="sm"
                variant="outline-primary"
                onClick={() => showModal('modalImportUsers')}
                style={{
                  marginRight: '0.5rem'
                }}
              >
                Upload Users
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalCreateCustomersUser')}
              >
                Create User
              </Button>
            </div>

          </Container>
        </div>
        {!inProgress &&
          <Container>
            <Box className="box-table">
              <CustomersUsersSearchResults
                paging={paging}
                result={filteredUsers}
                error={error}
                onFetchData={this.handleTableChange}
                handleSearchChange={this.handleSearchChange}
                inProgress={inProgress}
              />
            </Box>
          </Container>}
        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}
        <ModalCreateUser
          name="modalCreateCustomersUser"
          companies={searchCustomersCompaniesResult}
        />

        <ModalImportUser
          name="modalImportUsers"
          searchCustomers={this.props.searchCustomersUsers}
          searcCustomersCompanies={this.props.searchCustomersCompanies}
        />

        {uploadError &&
          <Notification
            key="upload-users-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearUploadUsers}
          >
            Error uploading users, Please try again.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: searchCustomersUsersInProgress,
    error: searchCustomersUsersError
  } = state.searchCustomersUsers.toJS();

  const {
    result: searchCustomersCompaniesResult,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    error: uploadError
  } = state.uploadUsers.toJS();

  return {
    result,
    searchCustomersCompaniesResult,
    inProgress: searchCustomersUsersInProgress,
    error: searchCustomersUsersError,
    uploadError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      searchCustomersUsers: fetchDux.searchCustomersUsers.createAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearUploadUsers: fetchDux.uploadUsers.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersUsersSearch);
