import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../../ui/Icon';
import { bindActionCreators } from 'redux';

import PurchaseOrderDetail
  from '../../purchase-order-detail/PurchaseOrderDetail';
import VendorEditForm from './VendorEditForm';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';
import VendorPing from './VendorPing';
import VendorCheck from './VendorCheck';
import POLocked from '../POLocked';

const getVendorOptions = ({ vendors, manufacturer }) => {
  const {
    CatalogVendorId
  } = manufacturer || {};

  return (vendors || [])
    .filter(({ AQVendorId }) => AQVendorId === CatalogVendorId);
};

class VendorPO extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.state = {
      showDetails: false,
      showEdit: false,
      ping: null,
      locked: true
    };
  }
  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }
  render() {
    const {
      purchaseOrder,
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      projectVendors,
      lookups,
      postUpdateVendor,
      projectId,
      postUpdateVendorInProgress
    } = this.props;
    const {
      ID: POID,
      c_PurchaseOrderAddressId,
      VendorId,
      c_SelectedPurchaseOrderSageVendorID,
      c_IsColorado,
      Manufacturer
    } = purchaseOrder || {};

    const {
      showDetails,
      showEdit,
      ping,
      locked
    } = this.state;

    const { inProgress, error } = ping || {};

    const toggleEdit = () => this.setState({ showEdit: !showEdit });

    const poItems = (lineItems || [])
      .filter(
        ({ PurchaseOrderId }) =>
          PurchaseOrderId === (purchaseOrder || {}).PurchaseOrderId
      );
    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (purchaseOrder || {}).ID
      );
    const shippingAddress = (addresses || [])
      .find(({ ID }) => ID === c_PurchaseOrderAddressId) || {};
    const {
      c_ShipDate
    } = shippingAddress || {};

    const { vendors } = lookups || {};

    const manufacturer = (projectVendors || [])
      .find(({ VendorId: manID }) => manID === VendorId) || {};

    const vendorOptions = getVendorOptions({ manufacturer, vendors });

    const options = (vendorOptions || []).map(({
      ID,
      Name,
      DisplayName,
      VendorNo
    }) => ({
      label: `${DisplayName || Name} (${VendorNo})`,
      value: ID
    }));

    const formName = `vendor-edit-form-${POID}`;

    const handleSubmit = values => {
      const payload = {
        c_SelectedPurchaseOrderSageVendorID: values.c_SelectedPurchaseOrderSageVendorID,
        c_IsColorado: values.c_IsColorado,
        ID: POID
      };
      postUpdateVendor({ id: projectId, payload }, formName, null, () =>
        this.setState({ showEdit: false }));
    };

    const handlePing = values => this.setState({ ping: values });

    return (
      <div className="purchase-order-summary">
        <div className="purchase-order-summary-header">
          <button className="btn toggle-details" onClick={this.toggleDetails}>
            <Icon name={showDetails ? 'caret-up' : 'caret-down'} />
          </button>
          <div className="symbol">PO</div>
          <div className="project">
            <div>Purchase Order</div>
            <strong>{purchaseOrder.c_PurchaseOrderNumber}</strong>
          </div>
          <div className="project" style={{ marginLeft: '20px' }}>
            <div>Manufacturer</div>
            <strong>{Manufacturer}</strong>
          </div>
          <POLocked
            purchaseOrderId={POID}
            setLocked={({ locked, partialLocked }) =>
              this.setState({ locked: locked || partialLocked })}
          />
        </div>
        <div className="purchase-order-summary-body">
          <Row>
            <Col sm="3">
              <h5>P.O. No.</h5>
              <div className="value large">
                {purchaseOrder.c_PurchaseOrderNumber}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor No.</h5>
              <div className="value small">
                {purchaseOrder.VendorNum}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor</h5>
              <div className="value small" style={{ display: 'inline' }}>
                {purchaseOrder.VendorName}
              </div>
              {' '}
            </Col>
            <Col>
              <div style={{ marginTop: '15px' }}>
                {!postUpdateVendorInProgress &&
                  <VendorCheck purchaseOrderId={POID} />}
              </div>
              {!showEdit &&
                <VendorPing purchaseOrderId={POID} setPing={handlePing} />}
              {!inProgress &&
                !error &&
                !showEdit &&
                <div style={{ textAlign: 'right' }}>
                  <Button onClick={toggleEdit} disabled={locked}>Edit</Button>
                </div>}
              {!inProgress &&
                !error &&
                showEdit &&
                <VendorEditForm
                  vendors={vendors}
                  vendorOptions={options}
                  toggleEdit={toggleEdit}
                  form={formName}
                  initialValues={{
                    c_IsColorado,
                    c_SelectedPurchaseOrderSageVendorID
                  }}
                  onSubmit={handleSubmit}
                  inProgress={postUpdateVendorInProgress}
                />}
            </Col>
          </Row>
        </div>
        <div
          className={
            `purchase-order-summary-items collapse ${showDetails ? 'show' : ''}`
          }
        >
          <PurchaseOrderDetail
            purchaseOrder={purchaseOrder}
            lineItems={poItems}
            lineItemSpecialInstructions={poItemsInstructions}
            addresses={addresses}
            hidePDF={true}
          />
        </div>
      </div>
    );
  }
}

export default VendorPO;
