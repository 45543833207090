import React, { Component } from 'react';
import Layout from '../../../ui/Layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';

import ProjectImportForm from './ProjectImportForm';
import ModalProjectImportWarnDate from './ModalProjectImportWarnDate';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';

const getShouldWarnDate = ({ lastModifiedDate }) => {
  if (!lastModifiedDate || !moment(lastModifiedDate).isValid()) return false;
  return moment().subtract(1, 'day').isAfter(moment(lastModifiedDate));
};

class ProjectsNew extends Component {
  componentDidMount() {
    this.props.clearPostProject();
  }
  render() {
    const {
      history,
      postProject,
      postProjectError,
      showModal,
      closeModal,
      aqjson
    } = this.props;
    const handleSubmit = values => {
      const shouldWarnDate = getShouldWarnDate(aqjson);
      if (shouldWarnDate) {
        showModal('ProjectImportWarnDateModal');
        return;
      }
      postProject(values, 'project-import-form', null, result => {
        const { project } = result || {};
        const { ID } = project || {};
        if (!ID) return;
        history.push(`/projects/${ID}`);
      });
    };

    const handleConfirmWarnDate = () => {
      closeModal();
      postProject({ aqjson }, 'project-import-form', null, result => {
        const { project } = result || {};
        const { ID } = project || {};
        if (!ID) return;
        history.push(`/projects/${ID}`);
      });
    };

    return (
      <Layout route="projects-new">
        <ModalProjectImportWarnDate
          name="ProjectImportWarnDateModal"
          onSubmit={handleConfirmWarnDate}
        />
        <h1>Choose the file you wish to insert and order</h1>
        <h2>Make sure you pick the most recent JSON file exported from AQ</h2>
        <ProjectImportForm
          onSubmit={handleSubmit}
          postProjectError={postProjectError}
        />
      </Layout>
    );
  }
}

const selector = formValueSelector('project-import-form');

const mapState = state => {
  const aqjson = selector(state, 'aqjson');
  // const lastModifiedDate = getLastModified(file || {});
  const {
    error: postProjectError
  } = state.postProject.toJS();
  return {
    aqjson,
    postProjectError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postProject: fetchDux.postProject.createAction,
      clearPostProject: fetchDux.postProject.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsNew);
