import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fetchDux from "../../../../state/fetch-dux";
import { actionCreators } from "../../../../state/modals-dux";
import CouponForm from "./CouponForm";

const ModalEditCoupon = ({ name, openModal, inProgress, closeModal, editCoupon }) => {
    const closeHandler = () => {
        if (!inProgress) closeModal();
    }
    const { modalName, payload } = openModal || {};
    return (
        <Modal
            show={name === modalName}
            onHide={closeHandler}
        >
            <Modal.Header>
                <Modal.Title>
                    Edit Coupon
                </Modal.Title>
            </Modal.Header>
            <CouponForm
                initialValues={{ ...payload }}
                closeHandler={closeHandler}
                edit={true}
                onSubmit={
                    (values) => {
                        const { ID, Code, Expires, Amount } = values;
                        editCoupon({ ID, Code, Expires,Amount }, 'CuponForm', null, () => {
                            closeHandler();
                        })
                    }
                }
            />
        </Modal>
    )
}

const mapState = state => {

    return {
        openModal: state.modals
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        ...actionCreators,
        editCoupon: fetchDux.editCoupon.createAction
    }, dispatch)

export default connect(mapState, mapDispatch)(ModalEditCoupon);