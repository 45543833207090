import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import FormField from '../../../forms/FormField';

const required = value => value ? undefined : 'Required';
const UpdateEmailRecipientsForm = ({ handleSubmit }) => {
    return (
        <form onSubmit={handleSubmit}>
            <Modal.Body>

                <Row>
                    <Col>
                        <div>
                            This action will add CC email recipients for all existing Purchase Orders
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Field
                            name='ccEmailRecipients'
                            label='CC Email Recipients'
                            type='text'
                            component={FormField.Input}
                            validate={[required]}
                            required
                        />
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    type="submit"
                    size="sm"
                    variant="success"
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </form>
    )
}

export default reduxForm({ form: 'update-email-recipients' })(UpdateEmailRecipientsForm)



