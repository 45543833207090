import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ProjectsSearch from './projects-search/ProjectsSearch.js';
import ProjectsNew from './projects-new/ProjectsNew.js';
import ProjectsDetail from './projects-detail/ProjectsDetail.js';
import ProjectsLogs from './projects-logs/ProjectsLogs.js';
import ProjectsMiscCharges from './projects-update/ProjectsMiscCharges.js';
import ProjectsUpdateVendors from './projects-update/ProjectsUpdateVendors.js';
import ProjectsUpdateShipping
  from './projects-update/shipping/ProjectsUpdateShipping.js';
import ProjectsUpdateImport from './projects-update/ProjectsUpdateImport.js';
import ProjectsUpdateImportDiff
  from './projects-update/ProjectsUpdateImportDiff.js';
import ProjectsSend from './projects-update/send/ProjectsSend.js';
import ProjectsTaxStatus from './projects-update/tax/ProjectsTaxStatus.js';
import ProjectLineItemInstructions
  from './projects-update/line-item-instructions/ProjectLineItemInstructions.js';
import ProjectDetailGL from './projects-gl-only/ProjectDetailGL.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={ProjectsSearch} />
    <Route exact path={`${match.url}/new`} component={ProjectsNew} />
    <Route
      exact
      path={`${match.url}/gl`}
      component={ProjectDetailGL}
    />
    <Route exact path={`${match.url}/:id`} component={ProjectsDetail} />
    <Route exact path={`${match.url}/:id/logs`} component={ProjectsLogs} />
    <Route
      exact
      path={`${match.url}/:id/misc-charges`}
      component={ProjectsMiscCharges}
    />
    <Route
      exact
      path={`${match.url}/:id/line-item-instructions`}
      component={ProjectLineItemInstructions}
    />
    <Route
      exact
      path={`${match.url}/:id/vendors`}
      component={ProjectsUpdateVendors}
    />
    <Route
      exact
      path={`${match.url}/:id/shipping`}
      component={ProjectsUpdateShipping}
    />
    <Route
      exact
      path={`${match.url}/:id/import`}
      component={ProjectsUpdateImport}
    />
    <Route
      exact
      path={`${match.url}/:relatedProjectId/import/:id`}
      component={ProjectsUpdateImportDiff}
    />
    <Route exact path={`${match.url}/:id/send`} component={ProjectsSend} />
    <Route
      exact
      path={`${match.url}/:id/tax-status`}
      component={ProjectsTaxStatus}
    />
    <Redirect to={`${match.url}`} />
  </Switch>
);
