import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import CreatePackageForm from './CreatePackageForm';

class ModalCustomersCompanyCreatePackage extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      selectedProducts,
      products,
      handleAddRemoveProduct,
      handleSubmitForm,
      nameForm,
      description,
      featured,
      hidePrice,
      isEdit,
      handleCloseModal,
      submitting,
      handleChangeQty
    } = this.props;

    return (
      <Modal
        show={openModal === name}
        onHide={() => handleCloseModal(closeModal)}
        className="modal-side"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? 'Edit Package' : 'Create Package'}
          </Modal.Title>
        </Modal.Header>
        <CreatePackageForm
          initialValues={{
            selectedProducts,
            name: nameForm,
            description,
            featured,
            hidePrice
          }}
          products={products}
          handleAddRemoveProduct={handleAddRemoveProduct}
          handleChangeQty={handleChangeQty}
          onSubmit={handleSubmitForm}
          selectedProducts={selectedProducts}
          submitting={submitting}
          closeModal={() => handleCloseModal(closeModal)}
          isEdit={isEdit}
        />
      </Modal>
    );
  }
}

const mapState = state => {
  return {
    openModal: state.modals
  };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(
  ModalCustomersCompanyCreatePackage
);
