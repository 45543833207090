import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import ValidateShippingInfoForm from './ValidateShippingInfoForm';
import moment from 'moment';

const formatShipDate = value => {
  if (!value || !moment(value).isValid()) return null;
  return moment(value).format('YYYY-MM-DD');
};

const getInitialValues = (
  {
    c_WarehouseID,
    c_ShipViaId,
    c_ShipDate,
    c_SpecialInstructions,
    Name,
    FirstName,
    LastName,
    PhoneContact,
    PhoneFax,
    Address1,
    Address2,
    City,
    State,
    Zip,
    c_Zip_4,
    c_AttnNameOnly
  }
) => ({
  c_WarehouseID: c_ShipViaId && c_ShipViaId !== 'Use Cheapest Option Available'
    ? c_WarehouseID || 'other'
    : null,
  c_ShipViaId,
  c_ShipDate,
  c_SpecialInstructions,
  Name,
  FirstName,
  LastName,
  PhoneContact,
  PhoneFax,
  Address1,
  Address2,
  City,
  State,
  Zip,
  c_Zip_4,
  c_AttnNameOnly: `${!!c_AttnNameOnly}`
});

class ModalValidateShippingInfoModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      result,
      handleSubmitProjectAddress,
      lookups,
      initialValues: shipViaDefault
    } = this.props;

    const { projectAddress, project } = result || {};
    const { c_ShipViaId } = shipViaDefault || {};
    let updatedProjectAddress = { ...projectAddress, c_ShipViaId };
    const initialValues = getInitialValues(updatedProjectAddress || {});
    const onSubmitProjectAddress = payload => {
      const { c_AttnNameOnly, c_ShipDate, ...rest } = payload || {};
      handleSubmitProjectAddress({
        c_AttnNameOnly: c_AttnNameOnly === 'true',
        c_ShipDate: formatShipDate(c_ShipDate),
        ...rest
      });
    };
    return (
      <Modal show={openModal === name} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Validate Shipping Info</Modal.Title>
        </Modal.Header>
        <ValidateShippingInfoForm
          onSubmit={onSubmitProjectAddress}
          closeModal={closeModal}
          initialValues={initialValues}
          result={result}
        />
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalValidateShippingInfoModal);
