import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Popover } from 'react-bootstrap';

const ColumnPopover = ({ header, data, url, isCustomCourier }) => (
  <div>
    <OverlayTrigger
      trigger="hover"
      placement="left"
      overlay={
        <Popover title={header || ''}>
          {data}
        </Popover>
      }
    >
      <div>
        {!url && <div style={{ fontStyle: isCustomCourier ? 'italic' : "normal" }}>{data}</div>}
        {url && <Link to={url} style={{ fontStyle: isCustomCourier ? 'italic' : "normal" }}>{data}</Link>}
      </div>
    </OverlayTrigger>
  </div>
);

export default ColumnPopover;
