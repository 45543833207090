import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import ImportCustomersUserForm from './ImportCustomersUserForm';
import fetchDux from '../../../../state/fetch-dux';

class ModalImportCustomersUser extends Component {
  handleUsersUploadSumbit = values => {
    const { uploadUsers } = this.props;
    const { file } = values;
    const payload = {
      uploadFile: file
    };
    uploadUsers(payload, 'import-customers-user-form');
  };

  closeHandler = () => {
    const {
      inProgress,
      clearUploadUsersResult,
      closeModal,
      uploadResult,
      searchCustomers,
      searcCustomersCompanies
    } = this.props;
    if (!inProgress) {
      if (uploadResult) {
        clearUploadUsersResult();
        searchCustomers();
        searcCustomersCompanies();
      }
      closeModal();
    }
  };

  render() {
    const {
      openModal,
      name,
      error,
      uploadResult,
      downloadUsersTemplate
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        className="modal-alt"
        size="lg"
        onHide={this.closeHandler}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Users</Modal.Title>
        </Modal.Header>
        <ImportCustomersUserForm
          closeModal={this.closeHandler}
          onSubmit={this.handleUsersUploadSumbit}
          uploadResult={uploadResult}
          downloadUsersTemplate={downloadUsersTemplate}
        />
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    inProgress,
    error,
    result: uploadResult
  } = state.uploadUsers.toJS();
  return {
    openModal: state.modals,
    inProgress,
    error,
    uploadResult
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      uploadUsers: fetchDux.uploadUsers.createAction,
      clearUploadUsersResult: fetchDux.uploadUsers.clearAction,
      downloadUsersTemplate: fetchDux.downloadUsersTemplate.createAction
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(ModalImportCustomersUser);
