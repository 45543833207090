import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import { formValueSelector } from 'redux-form';
import fetchDux from '../../../../state/fetch-dux';

import UploadLocationDocumentForm from './UploadLocationDocumentForm';

class ModalLocationUploadDocuments extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      uploadLocationDocuments,
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-alt"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <UploadLocationDocumentForm
          onSubmit={uploadLocationDocuments}
          closeModal={() => { closeModal(name) }}
        />
      </Modal>
    );
  }
}

const selector = formValueSelector('locations-upload-document-form');
const mapState = state => {
  const csv = selector(state, 'csv');
  return {
    csv,
    openModal: state.modals
  };
};

const mapDispatch = dispatch => bindActionCreators(
  {
    ...actionCreators
  },
  dispatch);

export default connect(mapState, mapDispatch)(ModalLocationUploadDocuments);
