import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { Container, Button } from 'react-bootstrap';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import { actionCreators } from '../../../../state/modals-dux';

import QuotesSearchResults from './QuotesSearchResults';
import ModalCreateQuote from './ModalCreateQuote';
import Loading from '../../../ui/Loading';

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '25',
  sortBy,
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class QuotesSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchQuotes = this.handleSearchQuotes.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    this.props.searchQuotes(qs);
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    const { location: { search: search2 } } = this.props;

    if (search === search2) return;

    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    this.props.searchQuotes(qs);
  }
  handleSearchQuotes(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchQuotes({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchQuotes(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      error,
      result,
      location: { search },
      history,
      showModal
    } = this.props;

    const paging = getQueryFromSearch(search);

    return (
      <Layout route="quotes-search" alt>

        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Quotes
              </h2>
              <h3>
                {!inProgress && <span>{(result || {}).count || 0} Quotes</span>}
              </h3>
            </div>
            <Button
              variant="primary"
              size="sm"
              onClick={() => showModal('modalCreateQuote')}
            >
              Create Quote
            </Button>
          </Container>
        </div>

        <Container>
          <Box className="box-table">
            <QuotesSearchResults
              paging={paging}
              result={result}
              error={error}
              onFetchData={this.handleTableChange}
              handleSearchChange={this.handleSearchChange}
              inProgress={inProgress}
            />
          </Box>
        </Container>

        <ModalCreateQuote name="modalCreateQuote" history={history} />

      </Layout>
    );
  }
}

const mapState = state => state.searchQuotes.toJS();

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      searchQuotes: fetchDux.searchQuotes.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QuotesSearch);
