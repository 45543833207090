import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import statusDict from '../statusDict';
import ShippingInfoResult from './ValidateShippingInfoResults';
import { getShippingData } from './getShippingInfoResult';
import ModalConfirmDeleteProject from './ModalConfirmDeleteProject';
import { actionCreators } from '../../../../state/modals-dux';
import Loading from '../../../ui/Loading';
import Notification from '../../../ui/Notification';

class ValidateShippingInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = { showUpload: true, ping: null };
    this.ping = this.ping.bind(this);
  }
  componentDidMount() {
    this.props.clearPostProjectLineItem()
    this.ping();
  }
  async ping() {
    const { result, setPing } = this.props;
    const ping = await getShippingData(result);
    this.setState({ ping });
    if (setPing) setPing(ping);
  }

  render() {
    const {
      postProjectLineItem,
      projectByIdResults,
      postUpdateStatus,
      deleteProject,
      inProgress,
      error,
      history,
      clearPostProjectLineItem,
      postProjectLineItemInProgress,
      postUpdateStatusInProgress
    } = this.props;

    const { project } = projectByIdResults || {};
    const {
      c_SageIntegrationStatus: status,
      ID
    } = project || {};

    const { ping: resultValues } = this.state;

    const handleConfirmShippingInfo = () => {
      const payload = { status: statusDict.APPROVE_INPROGRESS };
      postUpdateStatus({ id: ID, payload });
    };

    const handleSubmit = ({ vendors }) => {
      const values = vendors
        .reduce((t, p) => [...t, ...p.items], [])
        .filter(d => !d.exclude);

      postProjectLineItem({ payload: values, id: ID }, null, null, () => {
        handleConfirmShippingInfo();
      });
    };

    const handleDeleteProject = () =>
      deleteProject(ID, null, null, data => {
        const { skipPing } = data || {};
        if (skipPing) history.push('/projects');
      });

    const confirmDeleteProject = () => {
      this.props.showModal('ConfirmDeleteProjectModal');
    };

    const getInitialValues = () => {
      const vendors = resultValues.map(d =>
        Object.assign({}, d, {
          excludeAll: false
        }));
      return { vendors };
    };
    return (
      <Layout style={{paddingTop:'5px'}} route="freight-upload">
        { resultValues &&
          <ShippingInfoResult
            onSubmit={handleSubmit}
            initialValues={getInitialValues()}
            confirmDeleteProject={confirmDeleteProject}
            postProjectLineItemInProgress={postProjectLineItemInProgress}
            postUpdateStatusInProgress={postUpdateStatusInProgress}
          />}
        <ModalConfirmDeleteProject
          name="ConfirmDeleteProjectModal"
          projectId={ID}
          onCancelProject={handleDeleteProject}
          history={history}
          status={status}
        />
        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}
        { error && 
          <Notification
            key="freight-program"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostProjectLineItem}
          >
            {error.message}
          </Notification>
        }
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: projectByIdResults,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();

  const {
    inProgress: postUpdateStatusInProgress,
    error: postUpdateStatusError
  } = state.postUpdateStatus.toJS();

  const {
    result: postProjectLineItemResult,
    inProgress: postProjectLineItemInProgress,
    error: postProjectLineItemResultError
  } = state.postProjectLineItem.toJS();
  return {
    openModal: state.modals,
    projectByIdResults,
    postProjectLineItemResult,
    postProjectLineItemInProgress,
    postUpdateStatusInProgress,
    inProgress: getProjectByIdInProgress ||
      postUpdateStatusInProgress ||
      postProjectLineItemInProgress,
    error: getProjectByIdError ||
      postUpdateStatusError ||
      postProjectLineItemResultError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProjectById: fetchDux.getProjectById.createAction,
      postProjectLineItem: fetchDux.postProjectLineItem.createAction,
      postUpdateStatus: fetchDux.postUpdateStatus.createAction,
      clearPostProjectLineItem: fetchDux.postProjectLineItem.clearAction,
      deleteProject: fetchDux.deleteProject.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ValidateShippingInfoForm);
