import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const VendorForm = props => {
  const {
    handleSubmit,
    submitting,
    manufacturers
  } = props;

  const options = (manufacturers || []).map(({ CatalogVendorId, Name }) => ({
    value: CatalogVendorId,
    label: Name
  }));

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={9}>
          <Field
            name="AQVendorId"
            component={FormField.SelectReact}
            label="AQ Manufacturer"
            disabled={submitting}
            validate={[required]}
            options={options}
          />
        </Col>
        <Col sm="auto" style={{ marginTop: '30px' }}>
          <Button type="submit">
            <Icon name="plus" />
          </Button>
        </Col>

      </Row>
    </Form>
  );
};

const formName = 'vendor-mapping-form';

const form = reduxForm({
  form: formName
})(VendorForm);

export default form;
