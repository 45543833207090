import React, { Component } from 'react';
import { getPOLocked } from '../../../../api/api';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';

const iconStyle = {
  float: 'right',
  marginRight: '10px',
  display: 'inline-block',
  fontSize: '24px'
};

class POLocked extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locked: true,
      loaded: false,
      partialLocked: false
    };
    this.getLocked = this.getLocked.bind(this);
    this.timeout = null;
  }
  componentDidMount() {
    const { purchaseOrderId } = this.props;
    if (purchaseOrderId) this.getLocked(purchaseOrderId);
  }
  componentWillReceiveProps(nextProps) {
    const { purchaseOrderId: p1, result } = nextProps;
    const { purchaseOrderId: p2 } = this.props;
    const { locked } = result || {};
    if (locked) {
      this.setState({ locked: true, loaded: true });
      return;
    }
    if (p1 === p2) return;
    if (!p1) return;
    clearTimeout(this.timeout);
    this.getLocked(p1);
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  async getLocked(id) {
    try {
      const { setLocked } = this.props;
      const status = await getPOLocked(id);
      const { locked, partialLocked } = status || {};
      this.setState({ locked, partialLocked, loaded: true });
      if (setLocked) setLocked({ ...status, loaded: true });
      if (locked) return;
      clearTimeout(this.timout);
      // this.timeout = setTimeout(() => this.getLocked(id), 10000);
    } catch (err) {}
  }
  render() {
    const {
      loaded,
      locked,
      partialLocked
    } = this.state;

    const { hideUnlocked, loadingStyle } = this.props;

    const colorStyle = partialLocked ? { color: '#ab8106' } : {};

    if (!loaded)
      return (
        <Loading
          color="#04844B"
          containerStyle={{
            float: 'right',
            marginRight: '10px',
            display: 'inline-block'
          }}
          style={{ width: '40px', height: '40px', ...(loadingStyle || {}) }}
        />
      );
    if (!locked && !hideUnlocked)
      return (
        <div
          style={{
            ...iconStyle,
            ...colorStyle,
            opacity: '.5'
          }}
        >
          <Icon name="lock-open" />
        </div>
      );

    if (locked)
      return (
        <div
          style={{
            ...iconStyle,
            color: '#dc3545',
            opacity: '.75'
          }}
        >
          <Icon name="lock" />
        </div>
      );

    return false;
  }
}

export default POLocked;
