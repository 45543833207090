import React from 'react';
import { Modal, Button, Row, Col, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import moment from 'moment';

const ModalDeleteMiscShipmentGroup = (
    {
        name,
        openModal,
        closeModal,
        inProgress,
        error,
        deleteMiscShipmentsLineItemsGroup,
        history
    }
) => {

    const { name: openModalName, shipments, groupName: miscGroupName, purchaseOrder, shipmentID } = openModal || {};
    const { c_PurchaseOrderNumber } = purchaseOrder || {};
    const closeHandler = () => {
        if (!inProgress) closeModal()
    }

    return (
        <Modal
            show={openModalName === name}
            size="md"
            dialogClassName="modal-600 modal-edit-line-items"
            onHide={closeHandler}
        >

            <Modal.Header>
                <Modal.Title style={{ color: '#C23934' }}>
                    <Icon name="exclamation-triangle" />
                    &nbsp;
                    &nbsp;
                    {`Delete Misc ShipmentGroup`}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {error && <Alert variant="danger">{error.message}</Alert>}
                <label>
                    {
                        `Are you sure you want to delete the misc shipment group.`
                    }
                </label>
            </Modal.Body>

            <Modal.Footer>
                <Row className='editLineItemsFooter' style={{ justifyContent: 'flex-end' }}>
                    <Col sm={3}>
                        <Button
                            size='sm'
                            variant='outline-secondary'
                            onClick={
                                () => closeModal()
                            }
                            disabled={inProgress}
                        >
                            Cancel
                        </Button>
                    </Col>

                    <Col sm={3}>
                        <Button
                            size='sm'
                            variant='danger'
                            style={{ float: 'right' }}
                            disabled={inProgress}
                            onClick={
                                () => {
                                    const payload =
                                    {
                                        shipmentID,
                                        purchaseOrderNumber: c_PurchaseOrderNumber
                                    }
                                    deleteMiscShipmentsLineItemsGroup(payload, null, null, () => {
                                        closeHandler();
                                        const filteredShipments = shipments.filter(({ groupName }) => groupName !== miscGroupName);
                                        const shipment = filteredShipments[0];
                                        const { groupName, PoID, TrackingNum, SchdShipDate } = shipment;
                                        const misc = groupName.startsWith('misc');
                                        const formattedShipDate = moment.utc(SchdShipDate).format('MM-DD-YYYY');
                                        const data = (groupName === 'tracking' || groupName.startsWith('misc@tracking')) ? TrackingNum
                                            : (groupName === 'scheduled' || groupName.startsWith('misc@scheduled')) ? formattedShipDate : 'na';
                                        const urlGroupName = groupName.startsWith('misc') ? groupName.split('@')[1] : groupName;
                                        const url = `/shipments-groups/${PoID}/${urlGroupName}/${data}?misc=${misc}`;
                                        history.push(url);

                                    });
                                }
                            }
                        >
                            Confirm
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>

        </Modal>
    )
}



const mapState = state => {
    const {
        inProgress,
        result,
        error
    } = state.deleteMiscShipmentsLineItemsGroup.toJS();
    return {
        openModal: state.modals,
        inProgress,
        result,
        error
    }
}

const mapDispatch = dispatch => bindActionCreators(
    {
        ...actionCreators,
        deleteMiscShipmentsLineItemsGroup: fetchDux.deleteMiscShipmentsLineItemsGroup.createAction
    },
    dispatch
)

export default connect(mapState, mapDispatch)(ModalDeleteMiscShipmentGroup);
