import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import moment from 'moment';

const required = value => value ? undefined : 'Required';

const SendNewProjectForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    initialValues,
    showPreview,
    closePreview,
    formValues,
    warehouse,
    user
  } = props;
  const {
    formSubject,
    formBody,
    formFooter,
    formProjectName,
    formProjectEstShip,
    formProjectEstDelivery,
    formProjectAddress,
    formProjectQtyHandledIn,
    formProjectWeight,
    comments
  } = formValues || {};
  const { email, firstName, lastName } = user || {};
  const {
    name,
    address1,
    address2,
    city,
    postalCode,
    stateProvince,
    tollFree,
    phone,
    fax,
    emergencyPhone,
    minHandling,
    handlingIn,
    handlingOut,
    storage,
    delivery999,
    delivery1999,
    delivery3999,
    delivery7999,
    deliveryPlus,
    driverHelper,
    manTruck,
    twoMenTruck,
    minDelivery,
    warehouseHelper,
    debrisRemoval,
    protection,
    accessFee,
    notes,
    receivingHours,
    ratings,
    userContacts,
    lastUsed,
    updatedRate
  } = warehouse || {};

  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        {!showPreview &&
          <div>

            <Field
              name="subject"
              type="text"
              component={FormField.Input}
              label="Subject"
              validate={[required]}
              required
            />

            <Field
              name="body"
              component={FormField.Textarea}
              label="Body"
              required
              validate={[required]}
              rows={4}
            />

            <Field name="footer" component={FormField.Textarea} rows={4} />

            <div
              className="modal-email-preview-body"
              style={{ marginBottom: '1rem' }}
            >
              <div><b>Project Information</b></div>
              <br />

              <Row>
                <Col md={6} lg={4}>

                  <Field
                    name="projectName"
                    type="text"
                    component={FormField.Input}
                    label="Project Name"
                    validate={[required]}
                    required
                  />

                </Col>
                <Col md={6} lg={4}>

                  <Field
                    name="projectEstShip"
                    type="text"
                    component={FormField.Datepicker}
                    label="Est. Mfg Ship Date"
                    validate={[required]}
                    required
                  />

                </Col>
                <Col md={6} lg={4}>

                  <Field
                    name="projectEstDelivery"
                    type="text"
                    component={FormField.Datepicker}
                    label="Est. Delivery Date"
                    validate={[required]}
                    required
                  />

                </Col>
                <Col md={12} lg={12}>

                  <Field
                    name="projectAddress"
                    type="text"
                    component={FormField.Input}
                    label="Full Address Text"
                    validate={[required]}
                    required
                  />

                </Col>
                <Col md={6} lg={4}>

                  <Field
                    name="projectQtyHandledIn"
                    type="text"
                    component={FormField.Input}
                    label="Est. Qty Handled In"
                    validate={[required]}
                    required
                  />

                </Col>
                <Col md={6} lg={4}>

                  <Field
                    name="projectWeight"
                    type="text"
                    component={FormField.Input}
                    label="Est. Total Weight"
                    validate={[required]}
                    required
                  />

                </Col>

                <Col md={6} lg={4}>

                  <Field
                    name="jobCode"
                    type="text"
                    component={FormField.Input}
                    label="Job Code"
                    validate={[required]}
                    required
                  />

                </Col>
              </Row>
            </div>

            <Field
              name="comments"
              component={FormField.Textarea}
              rows={4}
              placeholder="Clarifications and exceptions go here..."
            />

            <div className="text-muted">
              <Icon name="exclamation-triangle" />
              {' '}
              All unique project contraints should be clearly explained in the post table text above.
            </div>

          </div>}

        {showPreview &&
          <div>
            <div className="modal-email-preview">
              <h5>Email Subject</h5>
              {formSubject}
            </div>
            <div className="modal-email-preview">
              <h5>Email Body</h5>
              <div className="modal-email-preview-body">
                <p style={{ whiteSpace: 'pre-line' }}>{formBody}</p>
                <p style={{ whiteSpace: 'pre-line' }}>{formFooter}</p>
                <p>
                  <a
                    href="https://shop.conceptserv.com/warehouse-info/%TOKEN%"
                  >
                    https://shop.conceptserv.com/warehouse-info/%TOKEN%
                  </a>
                </p>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="4">Project Information</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Name:</th>
                      <td>{formProjectName || '-'}</td>
                      <th>Est. Ship:</th>
                      <td>
                        {formProjectEstShip &&
                          <span>
                            {moment(formProjectEstShip).format('MM/DD/YYYY')}
                          </span>}
                        {!formProjectEstShip && <span>-</span>}
                      </td>
                    </tr>
                    <tr>
                      <th>Address:</th>
                      <td>{formProjectAddress || '-'}</td>
                      <th>Est. Delivery:</th>
                      <td>
                        {formProjectEstDelivery &&
                          <span>
                            {moment(formProjectEstDelivery).format(
                              'MM/DD/YYYY'
                            )}
                          </span>}
                        {!formProjectEstDelivery && <span>-</span>}
                      </td>
                    </tr>
                    <tr>
                      <th>Weight (lbs):</th>
                      <td>{formProjectWeight || '-'}</td>
                      <th>Qty Handled In:</th>
                      <td>{formProjectQtyHandledIn || '-'}</td>
                    </tr>
                  </tbody>
                </table>
                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="4">Warehouse Contact</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Name:</th>
                      <td>{name || '-'}</td>
                      <th>Address</th>
                      <td>
                        <div>{address1 || '-'} {address2 || '-'}</div>
                        <div>
                          {city || '-'}, {stateProvince} {postalCode || '-'}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>TollFree:</th>
                      <td>{tollFree || '-'}</td>
                      <th>Phone:</th>
                      <td>{phone || '-'}</td>
                    </tr>
                    <tr>
                      <th>Fax:</th>
                      <td>{fax || '-'}</td>
                      <th>Emergency Phone</th>
                      <td>{emergencyPhone || '-'}</td>
                    </tr>
                  </tbody>
                </table>

                <table className="table">
                  <thead>
                    <tr>
                      <th colSpan="4">Warehouse Rates</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Debris Removal:</th>
                      <td>{debrisRemoval || '-'}</td>
                      <th>Full Value Protection:</th>
                      <td>{protection || '-'}</td>
                    </tr>
                    <tr>
                      <th>Access Fee:</th>
                      <td>{accessFee || '-'}</td>
                      <th>Receiving Hours:</th>
                      <td>{receivingHours || '-'}</td>
                    </tr>
                    <tr>
                      <th>Delivery 0-999 (cwt):</th>
                      <td>{delivery999 || '-'}</td>
                      <th>Delivery 1000-1999 (cwt):</th>
                      <td>{delivery1999 || '-'}</td>
                    </tr>
                    <tr>
                      <th>Delivery 2000-3999 (cwt):</th>
                      <td>{delivery3999 || '-'}</td>
                      <th>Delivery 4000 - 7999 (cwt):</th>
                      <td>{delivery7999 || '-'}</td>
                    </tr>
                    <tr>
                      <th>Delivery 8000 and up (cwt):</th>
                      <td>{deliveryPlus || '-'}</td>
                      <th>Storage:</th>
                      <td>{storage || '-'}</td>
                    </tr>
                    <tr>
                      <th>Min Handling Charge:</th>
                      <td>{minHandling || '-'}</td>
                      <th>Min Delivery Charge:</th>
                      <td>{minDelivery || '-'}</td>
                    </tr>
                    <tr>
                      <th>Handling - In (cwt):</th>
                      <td>{handlingIn || '-'}</td>
                      <th>Handling - Out (cwt):</th>
                      <td>{handlingOut || '-'}</td>
                    </tr>
                    <tr>
                      <th>Driver helper (per hour):</th>
                      <td>{driverHelper || '-'}</td>
                      <th>1 man and a truck (per hour):</th>
                      <td>{manTruck || '-'}</td>
                    </tr>
                    <tr>
                      <th>2 men and a truck (per hour):</th>
                      <td>{twoMenTruck || '-'}</td>
                      <th>Warehouse helper (per hour):</th>
                      <td>{warehouseHelper || '-'}</td>
                    </tr>
                  </tbody>
                </table>
                {comments && comments.trim().length > 0 &&
                  <b>Additional project clarifications and exceptions:</b>
                }
                <p style={{ whiteSpace: 'pre-line' }}>{comments}</p>
                Thank you,
                <br /><br />
                {firstName} {lastName}<br />
                {email}
              </div>
            </div>
          </div>}

      </div>

      <div className="modal-footer">
        <Button
          variant="outline-secondary"
          type="button"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
        {!showPreview &&
          <Button variant="primary" type="submit" disabled={submitting}>
            Preview
          </Button>}
        {showPreview &&
          <Button
            variant="outline-secondary"
            type="button"
            onClick={closePreview}
          >
            Edit
          </Button>}
        {showPreview &&
          <Button variant="primary" type="submit" disabled={submitting}>
            Send
          </Button>}
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'send-new-project-form'
})(SendNewProjectForm);
