import React, { Component } from 'react';
import { Modal, Button, Alert } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';

class ModalRevokeAccess extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleRevokeClick,
      PDFDownloaded
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Revoke Access</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {
            PDFDownloaded
            &&
            <Alert variant="danger">
              <b>This quote has already been downloaded</b>
              <div>
                Confirm you want to revoke access from the user. The user has already downloaded this quote and they will still have access to that PDF version.
              </div>
            </Alert>
          }


          <p>
            The quote will be placed back in a draft status.
            <br />
            You can choose to share it again if you would like.
          </p>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            type="button"
            onClick={closeModal}
          >
            Close
          </Button>
          <Button variant="primary" type="button" onClick={handleRevokeClick}>
            Revoke Access
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalRevokeAccess);
