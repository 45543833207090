import React from 'react';
import Icon from '../../../ui/Icon';

const baseStyle = { color: '#C23934' };

const ProjectError = ({ errors, field, multiple, icon, style }) => {
  const err = (errors || []).filter(e => e.field === field);
  if (!err || !err.length) return false;
  if (!multiple) {
    return (
      <div style={Object.assign({}, baseStyle, style || {})}>
        {icon && <span><Icon name="exclamation-triangle" />&nbsp;</span>}
        {err[0].message}
      </div>
    );
  }
  return (
    <div>
      {(err || []).map(({ message }) => (
        <div style={Object.assign({}, baseStyle, style || {})}>
          {icon && <span><Icon name="exclamation-triangle" />&nbsp;</span>}
          {message}
        </div>
      ))}
    </div>
  );
};

export default ProjectError;
