const getZip = zip => !zip ? '' : zip.substr(0, 5);
const groupBy = (arr, key) =>
  (arr || []).reduce((t, p) => Object.assign({}, t, { [p[key]]: p }), {});

const getVendors = async vendors => {
  return groupBy(vendors, 'VendorId');
};

const isItemCodeValid = ({ ItemTypeCode, ItemTypeDescription }) => {
  if (ItemTypeCode === 3 && ItemTypeDescription === 'Note') return true;
  if (ItemTypeCode === 2 || ItemTypeCode === 3) return false;
  return true;
};

const filterLineItems = (
  {
    StatusDescription,
    SpecialCode,
    ItemTypeCode,
    ItemTypeDescription,
    VendorId
  }
) =>
  StatusDescription === 'Active' &&
  !SpecialCode &&
  !!VendorId &&
  isItemCodeValid({ ItemTypeCode, ItemTypeDescription });

export const getShippingData = async result => {
  const {
    vendors,
    lineItems,
    addresses,
    projectAddress,
    purchaseOrders
  } = result || {};
  const vendorsInfo = await getVendors(vendors);
  const addressesInfo = groupBy(addresses, 'AddressId');
  const destinationZip = getZip((projectAddress || {}).Zip);
  const projectAddressId = (projectAddress || {}).ID;

  const lineItemsInfo = (lineItems || []).reduce((
    prev,
    lineItem,
    lineItemIdx
  ) => {
    const {
      Category,
      ItemNumber,
      LineItemId,
      SubLineItems
    } = lineItem || {};
    const next = (SubLineItems || [])
      .filter(filterLineItems)
      .map((item, sublineItemIdx) =>
        Object.assign({}, item, {
          Category,
          ItemNumber,
          LineItemId,
          sublineItemIdx,
          lineItemIdx
        }));
    return [...prev, ...next];
  }, []);
  // group by vendor
  const group = {};
  for (const line of lineItems) {
    const vendor = vendorsInfo[line.VendorId] || {};

    // if (!line.Weight) continue;

    const vendorName = vendor.Name || line.VendorId;
    vendor.c_PurchaseOrderAddressId = ((purchaseOrders || [])
      .find(
        ({ PurchaseOrderId }) => PurchaseOrderId === line.PurchaseOrderId
      ) || {}).c_PurchaseOrderAddressId;
    const row = {
      id: line.ID,
      quantity: line.Quantity,
      lineItemId: line.LineItemId,
      model: line.Model,
      netPrice: line.NetPrice,
      vendor,
      weight: line.Weight ? Number((line.Weight || '').toFixed(2)) : '',
      commodityClass: line.FreightClass,
      originZip: getZip((addressesInfo[vendor.VendorAddressId] || {}).Zip),
      destinationZip,
      projectAddressId,
      PurchaseOrderId: line.PurchaseOrderId,
      pickupLiftgate: false,
      pickupInside: false,
      deliveryLiftgate: false,
      deliveryInside: false
    };

    if (group[vendorName]) group[vendorName].push(row);
    else group[vendorName] = [row];
  }
  return Object.keys(group)
    .sort((a, b) =>
      (a || '').toUpperCase().localeCompare((b || '').toUpperCase()))
    .map(k => {
      const items = group[k];
      const lineItems = Object.keys(groupBy(items, 'lineItemId')).length;
      const netPrices = items.reduce((t, p) => t + p.netPrice, 0);
      const { vendor } = items[0] || {};

      if (vendor.FreightProgram) {
        const fpCode = vendor.FreightProgram.split(',');
        // alway free
        if (fpCode.includes('A'))
          vendor.FreightProgramValue = 0;
        else {
          // flat rate
          if (fpCode.includes('F'))
            vendor.FreightProgramValue = vendor.FreightProgramFlatRate;
          // total PO exceed
          if (fpCode.includes('T') && netPrices >= vendor.FreightProgramPO)
            vendor.FreightProgramValue = 0;
          // total pieces exceed
          if (fpCode.includes('P') && lineItems >= vendor.FreightProgramPieces)
            vendor.FreightProgramValue = 0;
        }
      }

      return {
        label: k,
        lineItems,
        netPrices,
        items: items.map(d => Object.assign({}, d, { vendor }))
      };
    });
};
