import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';
import { Link } from 'react-router-dom';

const required = value => value ? undefined : 'Required';

const WarehouseNewForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>

      {!submitting &&
        <div>
          <Row>
            <Col md={6}>

              <Field
                name="name"
                type="text"
                label="Warehouse Name"
                component={FormField.Input}
                validate={[required]}
              />

              <Field
                name="address1"
                type="text"
                label="Address 1"
                component={FormField.Input}
                validate={[required]}
              />

              <Field
                name="address2"
                type="text"
                label="Address 2"
                component={FormField.Input}
              />

              <Row>
                <Col md={8}>

                  <Field
                    name="city"
                    type="text"
                    label="City"
                    component={FormField.Input}
                    validate={[required]}
                  />

                </Col>
                <Col md={4}>

                  <Field
                    name="postalCode"
                    type="text"
                    label="ZIP"
                    component={FormField.Input}
                    validate={[required]}
                  />

                </Col>
              </Row>

              <Field
                name="stateProvince"
                type="text"
                label="State"
                component={FormField.Input}
                validate={[required]}
              />

            </Col>
            <Col md={6} />
          </Row>
        </div>}
      {submitting &&
        <Loading fullPage message="Creating Warehouse, Please Wait..." />}

      <Link className="btn btn-secondary" to="/warehouses">
        Cancel
      </Link>
      {' '}
      <Button variant="primary" type="submit" disabled={submitting}>
        Add Warehouse
      </Button>

    </form>
  );
};

export default reduxForm({
  form: 'warehouse-new-form'
})(WarehouseNewForm);
