import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../state/fetch-dux';
import { actionCreators } from '../../../state/modals-dux';

const AcknowledgeModal = (
    {
        name,
        openModal,
        inProgress,
        closeModal,
        acknowledgeShipmentChange,
        onAckSuccess
    }
) => {
    const { name: modalName, selectedRow } = openModal || {};

    const closeHandler = () => {
        if (!inProgress) closeModal();
    }

    return (
        <Modal
            show={name === modalName}
            onHide={closeHandler}
        >
            <Modal.Header>
                <Modal.Title>Confirmation</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                Are you sure you want to Acknowledge Shipment Change?
            </Modal.Body>

            <Modal.Footer>
                <Button
                    variant='secondary'
                    size='sm'
                    disabled={inProgress}
                    onClick={closeHandler}
                >
                    Cancel
                </Button>
                <Button
                    variant='primary'
                    size='sm'
                    disabled={inProgress}
                    onClick={
                        () => acknowledgeShipmentChange({ selectedRow }, null, null, () => {
                            closeHandler();
                            onAckSuccess();
                        })
                    }

                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal >
    )
}

const mapState = state => {
    return {
        openModal: state.modals
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        ...actionCreators,
        acknowledgeShipmentChange: fetchDux.acknowledgeShipmentChange.createAction
    }, dispatch)

export default connect(mapState, mapDispatch)(AcknowledgeModal);