import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../../ui/Icon';
import numeral from 'numeral';
import moment from 'moment';
import PDFLink from '../PDFLink';

import PurchaseOrderDetail
  from '../../purchase-order-detail/PurchaseOrderDetail';
import SendPurchaseOrderForm
  from '../../purchase-order-detail/SendPurchaseOrderForm';

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY');
};

const getInitialEmailRecipients = (
  {
    email,
    VendorEmail,
    VendorEmailCC
  }
) => {
  return [
    {
      EmailRecipient: VendorEmail,
      Type: 'to',
      Track: true
    },
    {
      EmailRecipient: VendorEmailCC,
      Type: 'cc',
      Track: false
    },
    {
      EmailRecipient: email,
      Type: 'cc',
      Track: false
    }
  ].filter(({ EmailRecipient }) => !!EmailRecipient);
};

const getInitialValuesSendPo = (
  {
    VendorEmail,
    VendorEmailCC,
    c_PurchaseOrderNumber,
    user,
    VendorName,
    c_ShipDate,
    pdfLogs,
    isDeleted,
    templateOptions,
    VendorLBMXEnabled,
    disableSendEmail,
    sendEmail,
    lbmxErrorMessage
  }
) => {
  const { email } = user || {};

  const to = getInitialEmailRecipients({ VendorEmail, VendorEmailCC, email });

  const templateId = isDeleted ? 3 : (pdfLogs || []).length > 1 ? 2 : 1;

  const { body, subject } = (templateOptions || [])
    .find(({ value }) => value === templateId) || {};

  return {
    to,
    templateId,
    sub: {
      EmailRecipient: '',
      Type: 'to',
      Track: true
    },
    subject: subject || '',
    body: body || '',
    VendorLBMXEnabled,
    sendEmail: !VendorLBMXEnabled ? true : sendEmail || false,
    disableSendEmail,
    lbmxErrorMessage
  };
};

const deleteLabelStyle = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  display: 'inline',
  marginLeft: '20px',
  color: '#C23934'
};

const getTemplateOptions = (
  {
    c_PurchaseOrderNumber,
    user,
    c_ShipDate,
    VendorName
  }
) => {
  const { email } = user || {};

  return [
    {
      value: 1,
      label: 'New PO Email',
      subject: `${VendorName} P.O. #${c_PurchaseOrderNumber} to SHIP ON ${getDate(c_ShipDate)} OR AS NOTED ON LINE ITEMS`,
      body: `Please process this order to ship on ${getDate(c_ShipDate)}, not before or after.  If you have any issues meeting this date, please notify ${email}

Please mark ALL shipments for P.O. # ${c_PurchaseOrderNumber}

Send acknowledgements and/or tracking information ASAP directly to ----> tracking@conceptserv.com and cc ${email}
`
    },
    {
      value: 2,
      label: 'Revised PO Email',
      subject: `${VendorName} REVISED P.O. #${c_PurchaseOrderNumber} to SHIP ON ${getDate(c_ShipDate)} OR AS NOTED ON LINE ITEMS`,
      body: `Please process this REVISED order, do not duplicate. If you have any issues with this revision, please notify ${email}

Please mark ALL shipments for P.O. # ${c_PurchaseOrderNumber}
      
Send acknowledgements and/or tracking information ASAP directly to ----> tracking@conceptserv.com and cc ${email}
`
    },
    {
      value: 3,
      label: 'Deleted PO Email',
      subject: `${VendorName} DELETED P.O. #${c_PurchaseOrderNumber} DO NOT SHIP`,
      body: `Please see attached order cancellation and do not ship.  If you have any issues with this cancellation, please notify ${email}`
    }
  ];
};

class ProjectsSendPO extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.state = {
      showDetails: false,
      showEdit: false
    };
  }
  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }
  render() {
    const {
      user,
      purchaseOrder,
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      postResendPurchaseOrder,
      closeModal,
      isDeleted,
      pdfLogs,
      postCancelResendWarning
    } = this.props;
    const {
      ID: POID,
      c_PurchaseOrderAddressId,
      VendorEmail,
      VendorEmailCC,
      c_PurchaseOrderNumber,
      VendorName,
      pdfDetails,
      Manufacturer,
      c_POSent,
      VendorLBMXEnabled
    } = purchaseOrder || {};

    const {
      showDetails,
      showEdit,
      disableSendEmail,
      sendEmail,
      lbmxErrorMessage
    } = this.state;

    const toggleEdit = () => {
      if (VendorLBMXEnabled) {
        this.setState({
          showEdit: !showEdit,
          lbmxErrorMessage: null,
          sendEmail: false,
          disableSendEmail: false
        });
      } else {
        this.setState({
          showEdit: !showEdit
        });
      }
    };

    const poItems = (lineItems || [])
      .filter(
        ({ PurchaseOrderId }) =>
          PurchaseOrderId === (purchaseOrder || {}).PurchaseOrderId
      );
    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (purchaseOrder || {}).ID
      );
    const shippingAddress = (addresses || [])
      .find(({ ID }) => ID === c_PurchaseOrderAddressId) || {};
    const {
      c_ShipDate
    } = shippingAddress || {};

    const formName = `send-po-form-${POID}`;

    const onSendPurchaseOrder = data => {
      closeModal();
      const { sub, ...payload } = data || {};
      postResendPurchaseOrder({ id: POID, payload }, formName, null, result => {
        if (VendorLBMXEnabled) {
          const { lbmxSuccess, sendEmail } = result || {};
          if (!lbmxSuccess) {
            if (!sendEmail) {
              this.setState({
                disableSendEmail: true,
                lbmxErrorMessage: '(LBMX transmission failed. Try again with email.)',
                sendEmail: true
              });
            } else {
              this.setState({
                lbmxErrorMessage: '(LBMX failed, but the email was successfully sent.  Please follow-up.)',
                showEdit: false
              });
            }
          }
        } else {
          this.setState({ showEdit: false });
        }
      });
    };

    const templateOptions = getTemplateOptions({
      c_PurchaseOrderNumber,
      user,
      c_ShipDate,
      VendorName
    });

    return (
      <div className="purchase-order-summary">
        <div
          className="purchase-order-summary-header"
          style={isDeleted ? { backgroundColor: '#ffebe6' } : {}}
        >
          <button className="btn toggle-details" onClick={this.toggleDetails}>
            <Icon name={showDetails ? 'caret-up' : 'caret-down'} />
          </button>
          <div className="symbol">PO</div>
          <div className="project">
            <div>Purchase Order</div>
            <strong>{purchaseOrder.c_PurchaseOrderNumber}</strong>
          </div>
          <div className="project" style={{ marginLeft: '20px' }}>
            <div>Manufacturer</div>
            <strong>{Manufacturer}</strong>
          </div>
          {isDeleted && <div style={deleteLabelStyle}>Deleted</div>}
        </div>
        <div className="purchase-order-summary-body">
          <Row>
            <Col sm="3">
              <h5>P.O. No.</h5>
              <div className="value large">
                {purchaseOrder.c_PurchaseOrderNumber}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor No.</h5>
              <div className="value small">
                {purchaseOrder.VendorNum}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor</h5>
              <div className="value small">
                {(purchaseOrder.VendorName || '').substring(0, 40)}
              </div>
            </Col>
            {lbmxErrorMessage &&
              !showEdit &&
              <Col sm="auto">
                <span className="text-danger">{lbmxErrorMessage}</span>
              </Col>}
            <Col>
              <div style={{ textAlign: 'right' }}>
                {!c_POSent &&
                  <span style={{ color: '#dc3545', marginRight: '10px' }}>
                    <Icon
                      name="exclamation-triangle"
                      style={{ marginRight: '5px' }}
                    />
                    Changes have not been emailed
                    <Button
                      style={{ marginLeft: '10px' }}
                      onClick={() => postCancelResendWarning({ id: POID })}
                      // size="sm"
                      variant="danger"
                    >
                      Resolved
                    </Button>
                  </span>}
                {!showEdit && <Button onClick={toggleEdit}>Resend</Button>}
                {showEdit && <Button onClick={toggleEdit}>Cancel</Button>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <PDFLink pdfDetails={pdfDetails} />
            </Col>
          </Row>
        </div>
        <div
          className={
            `purchase-order-summary-items collapse ${showEdit ? 'show' : ''}`
          }
        >
          <SendPurchaseOrderForm
            form={formName}
            // confirmText="Send Email"
            // submitText="Send Email"
            onSendPurchaseOrder={onSendPurchaseOrder}
            pdfDetails={pdfDetails}
            templateOptions={templateOptions}
            initialValues={getInitialValuesSendPo({
              VendorEmail,
              VendorEmailCC,
              c_PurchaseOrderNumber,
              user,
              VendorName,
              c_ShipDate,
              pdfLogs,
              isDeleted,
              templateOptions,
              VendorLBMXEnabled,
              sendEmail,
              disableSendEmail,
              lbmxErrorMessage
            })}
          />
        </div>
        <div
          className={
            `purchase-order-summary-items collapse ${showDetails ? 'show' : ''}`
          }
        >
          <PurchaseOrderDetail
            purchaseOrder={purchaseOrder}
            lineItems={poItems}
            lineItemSpecialInstructions={poItemsInstructions}
            addresses={addresses}
            hidePDF={true}
          />
        </div>
      </div>
    );
  }
}

export default ProjectsSendPO;
