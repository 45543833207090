import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import Notification from '../../../ui/Notification';


class ResetUserPasswordModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      user,
      name,
      result,
      inProgress,
      error,
      clearResetPasswordById
    } = this.props;

    const {
      id,
      nameFirst,
      nameLast
    } = user || {}
    
    const handleResetPassword = () => {
      //Api call for sending reset password email link
      this.props.resetPasswordById(id, null, null, () => {
        closeModal();
      });
    }
    return (
      <Modal show={openModal === name} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Reset User Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Are you sure you wish to reset password for user <b>{nameFirst} {nameLast}</b>?
      </h5>
          <div className="text-muted">
            User will receive an email with link to password reset page.
      </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={closeModal}
            disabled={inProgress}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={handleResetPassword}
            disabled={inProgress}
          >
            Reset Password
          </Button>
        </Modal.Footer>

        {error &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearResetPasswordById}
          >
           Failed to send email. Please try again.
          </Notification>
        }
        {result &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="success"
            onClose={clearResetPasswordById}
          >
           Success! Reset email sent.
          </Notification>
        }

      </Modal>
    );
  }
}
const mapState = state => {
  const { result, inProgress, error } = state.resetPasswordById.toJS();
  return {
    result,
    inProgress,
    error,
    openModal: state.modals
  }
};
const mapDispatch = dispatch => bindActionCreators({
  ...actionCreators,
  resetPasswordById: fetchDux.resetPasswordById.createAction,
  clearResetPasswordById: fetchDux.resetPasswordById.clearAction
}, dispatch)
export default connect(mapState, mapDispatch)(ResetUserPasswordModal);
