import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import ProjectSearchResults from './ProjectSearchResults';
import statusDict from '../statusDict';

const getDesc = desc => {
  if (desc === false || desc === 'false') return 'false';
  return 'true';
};

const status = [
  statusDict.VALIDATED,
  statusDict.SHIPPING_CONFIRMED,
  statusDict.APPROVE_INPROGRESS,
  statusDict.APPROVE_COMPLETE,
  statusDict.INSERT_INPROGRESS,
  statusDict.SHIPPING_VALIDATED,
].join(',');

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '10',
  sortBy: sortBy || 'c_ImportedDate',
  desc: getDesc(desc),
  search: search || '',
  status
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class ProjectsSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchProjects = this.handleSearchProjects.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    this.props.searchProjects(qs);
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search } } = nextProps;
    const { location: { search: search2 } } = this.props;

    if (search === search2) return;

    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);
    this.props.searchProjects(qs);
  }
  handleSearchProjects(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchProjects({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchProjects(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      error,
      result,
      location: { search }
    } = this.props;

    const paging = getQueryFromSearch(search);

    return (
      <Layout route="projects-search">
        <h2>
          Project Search
          <Link
            to="/projects/new"
            className="btn btn-sm btn-primary float-right"
          >
            Import New Project
          </Link>
        </h2>
        <Box className="box-table">
          <ProjectSearchResults
            paging={paging}
            result={result}
            error={error}
            onFetchData={this.handleTableChange}
            handleSearchChange={this.handleSearchChange}
            inProgress={inProgress}
          />
        </Box>
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: searchProjectsInProgress,
    error: searchProjectsError
  } = state.searchProjects.toJS();

  return {
    result,
    inProgress: searchProjectsInProgress,
    error: searchProjectsError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      searchProjects: fetchDux.searchProjects.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsSearch);
