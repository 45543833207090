import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const required = value => {
  return !value ? 'Required' : undefined;
};

const VendorSelectForm = (
  {
    handleSubmit,
    vendorOptions
  }
) => {
  const options = (vendorOptions || []).map(({
    ID,
    Name,
    DisplayName,
    VendorNo
  }) => ({
    label: `${DisplayName || Name} (${VendorNo})`,
    value: ID
  }));
  return (
    <form onSubmit={handleSubmit}>
      <div style={{ marginTop: '15px', color: 'black', fontWeight: 'normal' }}>
        <Field
          name="c_SelectedPurchaseOrderSageVendorID"
          component={FormField.SelectReact}
          validate={[required]}
          options={options}
        />
      </div>
    </form>
  );
};

const formName = 'vendor-select-form';

export default reduxForm({
  form: formName,
  enableReinitialize: true
})(VendorSelectForm);
