import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';
import { Container, Button, Dropdown, Row, Col } from 'react-bootstrap';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCustomersEditCompany from '../customers-companies/ModalEditCompany';
import ModalCustomersDeleteCompany
  from '../customers-companies/ModalDeleteCompany';
import Notification from '../../../ui/Notification';
import ModalCreateLocation from '../customers-locations/ModalCreateLocation';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '20',
  sortBy: sortBy || 'name',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

const columns = [
  {
    Header: 'Location Name',
    accessor: 'name',
    Cell: ({ value, original }) => (
      <Link to={`/customers/locations/${original.locationID}`}>{value}</Link>
    )
  },
  {
    Header: 'Address',
    accessor: 'address',
    Cell: ({ value, original }) => {
      const {
        address1,
        address2,
        postalCode,
        city,
        stateProvince
      } = original || {};

      return (
        <div>
          {`${address1} ${address2} ${city}, ${stateProvince} ${postalCode}`}
        </div>
      );
    }
  },
  {
    Header: 'Users',
    accessor: 'numUsers',
    Cell: ({ value }) => <div>{`${value || '0'} Users`}</div>
  }
];

class CustomersCompaniesLocations extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const {
      match,
      getCompanyById,
      getCompanyLookups,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getCompanyById({ id, query: { getUsers: 1, getLocations: 1 } }, null, true);
    getCompanyLookups({ companyTypes: 1 });
    searchCustomersCompanies();
  }
  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      result,
      showModal,
      match,
      getConceptsLookupsResult,
      putCompanyById,
      getCompanyById,
      deleteCompanyById,
      history,
      getCompanyResults,
      location: { search: searchURL },
      clearGetCompanyById,
      clearGetCompanyLookups,
      getCompanyByIdError,
      getConceptsLookupsError,
      searchCustomersCompaniesError,
      clearSearchCustomersCompanies,
      clearDeleteCompanyById,
      deleteCompanyByIdError,
      clearPutCompanyById,
      putCompanyByIdError,
      putCompanyByIdInProgress
    } = this.props;

    const { locations, company } = result || {};

    const {
      name,
      companyTypeID,
      companyType,
      notes,
      created,
      id,
      logoHref,
      jobRefNum,
      supportEmail,
      freightMarkup
    } = company || {};

    const companyYears = moment().diff(moment(created), 'years');

    const initialValues = {
      name,
      companyTypeID,
      notes,
      jobRefNum,
      supportEmail,
      freightMarkup,
      id
    };

    const {
      companyTypes
    } = getConceptsLookupsResult || {};

    const handleSubmitUpdateCompany = values => {
      putCompanyById(
        { id, payload: values },
        'create-customers-company-form',
        null,
        () => {
          getCompanyById(
            { id, query: { getUsers: 1, getLocations: 1 } },
            null,
            true
          );
        }
      );
    };

    const handleDeleteCompany = () => {
      deleteCompanyById(id, null, null, () => {
        history.push(`/customers/concepts`);
      });
    };

    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const { id: companyId } = match.params || {};
    return (
      <Layout route="customers-companies-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/concepts" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {name || 'Loading...'}
              </h2>
              <h3>
                {!inProgress &&
                  <span>
                    Concept for
                    {' '}
                    {companyYears === undefined ? '-' : companyYears}
                    {' '}
                    years
                  </span>}
                &nbsp;
              </h3>
            </div>
            <div>
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalCreateCustomersLocation')}
              >
                Create Location
              </Button>
              {' '}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  <Icon fixedWidth name="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyEdit')}
                  >
                    Edit Concept
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyDelete')}
                  >
                    Delete Concept
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li className="active">
                <Link to={`/customers/concepts/${companyId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/users`}>Users</Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/products`}>
                  Products
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/packages`}>
                  Packages
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/locations`}>
                  Locations
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {!inProgress &&
          <Container>
            <Row>
              <Col sm={4}>
                <div className="box-logo">
                  <h4>Concept Logo</h4>
                  <div className="box-body">
                    <img
                      src={logoHref + `?=${Date.now()}`} //added date param to stop browser caching on update
                      alt={name}
                    />
                  </div>
                </div>
              </Col>
              <Col sm={6}>

                <div className="box-bordered">

                  <h4>Concept Overview</h4>

                  <table>
                    <tbody>
                      <tr>
                        <th>
                          <Icon fixedWidth name="store-alt" /> Concept Name
                        </th>
                        <td>{name || ''}</td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="list" /> Concept Type{' '}
                        </th>
                        <td>
                          {companyType || ''}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="envelope" /> Support Email{' '}
                        </th>
                        <td>
                          {supportEmail || ''}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="shipping-fast" />
                          {' '}
                          Freight Markup
                          {' '}
                        </th>
                        <td>
                          {freightMarkup || '-'} %
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="link" /> Job REF Number{' '}
                        </th>
                        <td>
                          {jobRefNum || ''}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          <Icon fixedWidth name="comment" /> Notes{' '}
                        </th>
                        <td>
                          {notes || ''}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>

              </Col>
            </Row>
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCustomersEditCompany
          name="modalCustomersCompanyEdit"
          initialValues={initialValues}
          companyTypes={companyTypes}
          handleSubmitUpdateCompany={handleSubmitUpdateCompany}
          putCompanyByIdError={putCompanyByIdError}
          clearPutCompanyById={clearPutCompanyById}
        />
        <ModalCustomersDeleteCompany
          name="modalCustomersCompanyDelete"
          handleDeleteCompany={handleDeleteCompany}
          deleteCompanyByIdError={deleteCompanyByIdError}
          clearDeleteCompanyById={clearDeleteCompanyById}
        />

        <ModalCreateLocation
          name="modalCreateCustomersLocation"
          getCompanyResults={getCompanyResults}
          isFromConcepts
          companyId={companyId}
        />
        {getCompanyByIdError &&
          <Notification
            key="users-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyById}
          >
            Locations failed to load. Please refresh the page.
          </Notification>}
        {getConceptsLookupsError &&
          <Notification
            key="options-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyLookups}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getCompanyByIdInProgress,
    error: getCompanyByIdError
  } = state.getCompanyById.toJS();

  const {
    result: getConceptsLookupsResult,
    inProgress: getConceptsLookupsInProgress,
    error: getConceptsLookupsError
  } = state.getConceptsLookups.toJS();

  const {
    result: getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    result: deleteCompanyByIdResult,
    inProgress: deleteCompanyByIdInProgress,
    error: deleteCompanyByIdError
  } = state.deleteCompanyById.toJS();

  const {
    result: putCompanyByIdResult,
    inProgress: putCompanyByIdInProgress,
    error: putCompanyByIdError
  } = state.putCompanyById.toJS();

  return {
    result,
    getConceptsLookupsResult,
    inProgress: getCompanyByIdInProgress,
    getCompanyByIdError,
    getCompanyResults,
    getConceptsLookupsError,
    searchCustomersCompaniesError,
    deleteCompanyByIdError,
    putCompanyByIdError,
    putCompanyByIdInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction,
      getCompanyLookups: fetchDux.getConceptsLookups.createAction,
      clearGetCompanyLookups: fetchDux.getConceptsLookups.clearAction,
      putCompanyById: fetchDux.putCompanyById.createAction,
      clearPutCompanyById: fetchDux.putCompanyById.clearAction,
      deleteCompanyById: fetchDux.deleteCompanyById.createAction,
      clearDeleteCompanyById: fetchDux.deleteCompanyById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersCompaniesLocations);
