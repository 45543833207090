import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';

const columns = [
  {
    Header: 'Location Name',
    accessor: 'locationName',
    width:250,
    Cell: ({ value, original }) => (
      <Link to={`/customers/locations/${original.locationID}`}>{value}</Link>
    )
  },
  {
    Header: 'Address',
    accessor: 'address',
    width:350,
    Cell: ({ value, original }) => {
      const {
        address1,
        address2,
        postalCode,
        city,
        stateProvince
      } = original || {};

      return (<div>{`${address1 || ''} ${address2 || ''} ${city || ''}, ${stateProvince || ''} ${postalCode || ''}`}</div>)
    }
  },
  {
    Header: 'Users',
    accessor: 'numUsers',
    Cell: ({ value }) => {

      return (<div>{value || '0'} Users</div>)
    }
  },
  {
    Header: 'Concept',
    accessor: 'companyName',
    Cell: ({ value }) => {
      return (<span className="table-label">{value || '-'} </span>)
    }
  }
];

const CustomersLocationsSearchResults = (
  {
    error,
    result,
    onFetchData,
    paging,
    handleSearchChange,
    inProgress
  }
) => {
  const {
    numPages,
    results: projects
  } = result || {};

  const {
    sortBy,
    desc,
    page,
    pageSize,
    search
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];
  return (
    <Table
      showColumnsSelector
      showSearch
      data={result || []}
      columns={columns}
      search={search}
      noDataText="No Locations Found"
      onFetchData={onFetchData}
      onSearchChange={handleSearchChange}
      defaultSorted={sorted}
      page={error ? 0 : Number(page)}
      defaultPageSize={Number(pageSize)}
      inProgress={inProgress}
    />
  );
};

export default CustomersLocationsSearchResults;
