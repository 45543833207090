import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import numeral from 'numeral';
import moment from 'moment';
import Icon from '../../../ui/Icon';
import PurchaseOrderDetail from '../purchase-order-detail/PurchaseOrderDetail';
import ProjectsUpdateLayout from '../projects-update/ProjectsUpdateLayout';
import ShippingPing from '../projects-update/shipping/ShippingPing';
import VendorPing from '../projects-update/vendors/VendorPing';
import PDFLink from '../projects-update/PDFLink';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import POLocked from '../projects-update/POLocked';
import VendorCheck from '../projects-update/vendors/VendorCheck';
import statusDict from '../statusDict';

const formatMoney = value => numeral(value).format('0,0.00');

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY');
};

const deleteLabelStyle = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  display: 'inline',
  marginLeft: '20px',
  color: '#C23934'
};
class CompleteOrderDetailPO extends Component {
  constructor(props, context) {
    super(props, context);
    this.toggleDetails = this.toggleDetails.bind(this);
    this.state = {
      showDetails: false
    };
  }
  toggleDetails() {
    this.setState({ showDetails: !this.state.showDetails });
  }
  render() {
    const {
      purchaseOrder,
      lineItems,
      lineItemSpecialInstructions,
      addresses,
      pdfLogs,
      isDeleted,
      isProjectDeleted
    } = this.props;
    const {
      ID,
      c_PurchaseOrderAddressId,
      pdfDetails,
      Manufacturer
    } = purchaseOrder || {};
    const {
      showDetails
    } = this.state;
    const poItems = (lineItems || [])
      .filter(
        ({ PurchaseOrderId }) =>
          PurchaseOrderId === (purchaseOrder || {}).PurchaseOrderId
      );
    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (purchaseOrder || {}).ID
      );
    const shippingAddress = (addresses || [])
      .find(({ ID }) => ID === c_PurchaseOrderAddressId) || {};
    const {
      c_ShipDate
    } = shippingAddress || {};
    return (
      <div className="purchase-order-summary">
        <div
          className="purchase-order-summary-header"
          style={
            isDeleted || isProjectDeleted ? { backgroundColor: '#ffebe6' } : {}
          }
        >
          <button className="btn toggle-details" onClick={this.toggleDetails}>
            <Icon name={showDetails ? 'caret-up' : 'caret-down'} />
          </button>
          <div className="symbol">PO</div>
          <div className="project">
            <div>Purchase Order</div>
            <strong>{purchaseOrder.c_PurchaseOrderNumber}</strong>
          </div>
          <div className="project" style={{ marginLeft: '20px' }}>
            <div>Manufacturer</div>
            <strong>{Manufacturer}</strong>
          </div>
          {(isDeleted || isProjectDeleted) &&
            <div style={deleteLabelStyle}>Deleted</div>}

          <POLocked purchaseOrderId={ID} isDeleted={isDeleted} />
        </div>
        <div className="purchase-order-summary-body">
          <Row>
            <Col sm="3">
              <h5>P.O. No.</h5>
              <div className="value large">
                {purchaseOrder.c_PurchaseOrderNumber}
              </div>
            </Col>
            <Col sm="2">
              <h5>Total Amount</h5>
              <div className="value large">
                ${formatMoney(purchaseOrder.SumSellPrice)}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Ship Date</h5>
              {isDeleted &&
                <div className="value large">
                  DELETED
                </div>}
              {!isDeleted &&
                <div className="value large">
                  {getDate(c_ShipDate)}
                </div>}
            </Col>
            <Col sm="auto">
              <h5>Vendor No.</h5>
              <div className="value large">
                {purchaseOrder.VendorNum}
              </div>
            </Col>
            <Col sm="auto">
              <h5>Vendor</h5>
              <div className="value large">
                {(purchaseOrder.VendorName || '').substring(0, 20)}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <PDFLink pdfDetails={pdfDetails} pdfLogs={pdfLogs} />
            </Col>
            <Col>
              <VendorCheck purchaseOrderId={ID} hideOk />
            </Col>
          </Row>
          <div>
            <ShippingPing purchaseOrderId={ID} />
            <br />
            <VendorPing purchaseOrderId={ID} />
          </div>
        </div>
        <div
          className={
            `purchase-order-summary-items collapse ${showDetails ? 'show' : ''}`
          }
        >
          <PurchaseOrderDetail
            purchaseOrder={purchaseOrder}
            lineItems={poItems}
            lineItemSpecialInstructions={poItemsInstructions}
            addresses={addresses}
            hidePDF={true}
            isDeleted={isDeleted}
          />
        </div>
      </div>
    );
  }
}

class CompleteOrderDetail extends Component {
  render() {
    const {
      result
    } = this.props;
    const {
      purchaseOrders,
      lineItems,
      addresses,
      lineItemSpecialInstructions,
      pdfLogs,
      deletedPOs,
      project
    } = result || {};

    const { c_SageIntegrationStatus } = project || {};

    const isProjectDeleted = c_SageIntegrationStatus === statusDict.ARCHIVED;

    const allPOs = [
      ...(purchaseOrders || []),
      ...(deletedPOs || [])
    ].sort((a, b) => {
      const o1 = a.isDeleted ? a.purchaseOrder.c_OrderIndex : a.c_OrderIndex;
      const o2 = b.isDeleted ? b.purchaseOrder.c_OrderIndex : b.c_OrderIndex;
      return o1 - o2;
    });
    return (
      <ProjectsUpdateLayout
        result={result}
        Page1={
          <div>
            {(allPOs || []).map((po, i) => {
              const { isDeleted } = po || {};
              if (isDeleted)
                return (
                  <CompleteOrderDetailPO
                    key={i}
                    isDeleted
                    purchaseOrder={po.purchaseOrder}
                    lineItems={po.lineItems}
                    lineItemSpecialInstructions={po.lineItemSpecialInstructions}
                    addresses={po.addresses}
                    isProjectDeleted={isProjectDeleted}
                    pdfLogs={po.pdfLogs || []}
                  />
                );

              return (
                <CompleteOrderDetailPO
                  key={i}
                  purchaseOrder={po}
                  lineItems={lineItems}
                  lineItemSpecialInstructions={lineItemSpecialInstructions}
                  addresses={addresses}
                  isProjectDeleted={isProjectDeleted}
                  pdfLogs={(pdfLogs || [])
                    .filter(({ PurchaseOrderID }) => PurchaseOrderID === po.ID)}
                />
              );
            })}
          </div>
        }
      />
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postPurchaseOrderAddress: fetchDux.postPurchaseOrderAddress.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CompleteOrderDetail);
