import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../state/fetch-dux';

import SecureRoute from './ui/SecureRoute';
import Homepage from './routes/homepage/Homepage';
import Projects from './routes/projects/Projects';
import OrdersSearch from './routes/orders/OrdersSearch';
import ArchivedOrdersSearch
  from './routes/archived-orders/ArchivedOrdersSearch';
import Administration from './routes/administration/Administration';
import SageViewer from './routes/sageViewer/SageViewer';
import Login from './routes/login/Login';
import Warehouses from './routes/warehouses/Warehouses';
import VendorPo from './routes/vendor-po/VendorPo';
import Freight from './routes/freight/Freight';
import Customers from './routes/customers/Customers';
import Shipments from './routes/shipments/Shipments';
import Quotes from './routes/quotes/Quotes';
import EcommerceOrders from './routes/ecommerce-orders/EcommerceOrders';
import Reports from './routes/reports/Reports';
import Coupons from './routes/coupons/Coupons';
import ShipmentsGroup from './routes/shipments-groups/ShipmentsGroup';
import Hotlist from './routes/hotlist/Hotlist';

class App extends Component {
  componentDidMount() {
    this.props.validateLogin();
  }
  render() {
    return (
      <Router>
        <LastLocationProvider>
          <Switch>
            <SecureRoute path="/" component={Homepage} exact />
            <SecureRoute path="/projects" component={Projects} />
            <SecureRoute path="/orders" component={OrdersSearch} />
            <SecureRoute
              path="/archived-orders"
              component={ArchivedOrdersSearch}
            />
            <SecureRoute
              path="/administration"
              component={Administration}
              adminOnly
              allowWarehouseAdmin
            />
            <SecureRoute path="/vendors" component={SageViewer} />
            <SecureRoute path="/freight" component={Freight} />
            <SecureRoute path="/customers" component={Customers} />
            <SecureRoute path="/shipments" component={Shipments} />
            <SecureRoute path='/shipments-groups' component={ShipmentsGroup} />
            <SecureRoute path="/quotes" component={Quotes} />
            <SecureRoute path="/reports" component={Reports} />
            <SecureRoute path="/ecommerce-orders" component={EcommerceOrders} />
            <Route path="/vendor-po/:id" component={VendorPo} />
            <Route path="/login" component={Login} />
            <SecureRoute path="/warehouses" component={Warehouses} />
            <SecureRoute path="/coupons" component={Coupons} />
            <Redirect to="/" />
          </Switch>
        </LastLocationProvider>
      </Router>
    );
  }
}

const mapState = state => ({});

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      validateLogin: fetchDux.validateLogin.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(App);
