import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Loading from '../../../ui/Loading';

const required = value => value ? undefined : 'Required';

const SendAllConfirmationsForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    initialValues,
    showPreview,
    closePreview,
    formValues,
    user
  } = props;
  const { formSubject, formBody, formFooter } = formValues || {};
  const { email, firstName, lastName } = user || {};

  return (
    <form onSubmit={handleSubmit}>

      <div className="modal-body">

        {!showPreview &&
          !submitting &&
          <div>

            <Field
              name="subject"
              type="text"
              component={FormField.Input}
              label="Subject"
              validate={[required]}
              required
            />

            <Field
              name="body"
              component={FormField.Textarea}
              label="Body"
              required
              validate={[required]}
              rows={8}
            />

            <Field name="footer" component={FormField.Textarea} rows={4} />

          </div>}

        {showPreview &&
          !submitting &&
          <div>
            <div className="modal-email-preview">
              <h5>Email Subject</h5>
              {formSubject}
            </div>
            <div className="modal-email-preview">
              <h5>Email Body</h5>
              <div className="modal-email-preview-body">
                <p style={{ whiteSpace: 'pre-line' }}>{formBody}</p>
                <p>
                  <a href="https://shop.conceptserv.com/warehouse-agreement">
                    https://shop.conceptserv.com/warehouse-agreement
                  </a>
                </p>
                <p style={{ whiteSpace: 'pre-line' }}>{formFooter}</p>
                <p>
                  <a
                    href="https://shop.conceptserv.com/warehouse-info/%TOKEN%"
                  >
                    https://shop.conceptserv.com/warehouse-info/%TOKEN%
                  </a>
                </p>
                Thank you,
                <br /><br />
                {firstName} {lastName}<br />
                {email}
              </div>
            </div>
          </div>}

        {submitting && <Loading message="Sending emails, please wait…" />}

      </div>

      <div className="modal-footer">
        <Button
          variant="outline-secondary"
          type="button"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
        {!showPreview &&
          <Button variant="primary" type="submit" disabled={submitting}>
            Preview
          </Button>}
        {showPreview &&
          <Button
            variant="outline-secondary"
            type="button"
            onClick={closePreview}
          >
            Edit
          </Button>}
        {showPreview &&
          <Button variant="primary" type="submit" disabled={submitting}>
            Send
          </Button>}
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'send-all-confirmations-form'
})(SendAllConfirmationsForm);
