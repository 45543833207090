import React, { useState } from 'react';
import ItemsTable from '../projects-detail/ItemsTable';
import VendorsTable from '../projects-detail/VendorsTable';
import ViewShippingSummary from './shipping/ViewShippingSummary';
import MiscChargesTable from './MiscChargesTable';

const getTabClass = (idx, activeTab) =>
  idx === activeTab ? 'tab active' : 'tab';

const getAdditionalTotalLines = additionalTotals => {
  return (additionalTotals || []).filter(({ Sell }) => Sell > 0);
};

const ProjectsUpdateLayout = (
  {
    Page1,
    result
  }
) => {
  const [activeTab, setActiveTab] = useState(0);
  const [search, setSearch] = useState('');
  const [vendorSearch, setVendorSearch] = useState('');
  const [miscSearch, setMiscSearch] = useState('');
  const handleSetActiveTab = v => () => setActiveTab(v);

  const { lineItems, vendors, additionalTotals } = result || {};

  const additionalTotalsLines = getAdditionalTotalLines(additionalTotals);

  const handleSearchChange = (name, value) => setSearch(value);
  const handleVendorSearchChange = (name, value) => setVendorSearch(value);
  const handleMiscSearchChange = (name, value) => setMiscSearch(value);

  return (
    <div className="project-items update">
      <div className="project-items-tabs">
        <div
          className={getTabClass(0, activeTab)}
          onClick={handleSetActiveTab(0)}
        >
          Purchase Orders
        </div>
        <div
          className={getTabClass(1, activeTab)}
          onClick={handleSetActiveTab(1)}
        >
          Items
        </div>
        <div
          className={getTabClass(2, activeTab)}
          onClick={handleSetActiveTab(2)}
        >
          Vendors
        </div>
        <div
          className={getTabClass(3, activeTab)}
          onClick={handleSetActiveTab(3)}
        >
          Misc Charges
        </div>
        <div
          className={getTabClass(4, activeTab)}
          onClick={handleSetActiveTab(4)}
        >
          Map
        </div>
      </div>
      {activeTab === 0 && Page1}
      <ItemsTable
        show={activeTab === 1}
        data={lineItems || []}
        search={search}
        handleSearchChange={handleSearchChange}
        tableProps={{
          getTdProps: (props, v) => {
            const { original: row } = v || {};
            const { __type } = row || {};
            const isAdd = __type === 'add';
            const isDel = __type === 'delete';
            if (!isAdd && !isDel) return {};
            const backgroundColor = isAdd ? '#e3fcef' : '#ffebe6';
            return { style: { backgroundColor } };
          }
        }}
      />
      <VendorsTable
        show={activeTab === 2}
        data={vendors}
        search={vendorSearch}
        handleSearchChange={handleVendorSearchChange}
      />
      <MiscChargesTable
        show={activeTab === 3}
        data={additionalTotalsLines}
        search={miscSearch}
        handleSearchChange={handleMiscSearchChange}
      />
      {activeTab === 4 && <ViewShippingSummary {...result} hideSave />}
    </div>
  );
};

export default ProjectsUpdateLayout;
