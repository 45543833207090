import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import FreightCalc from './freight-calc/FreightCalc.js';
import FreightUpload from './freight-upload/FreightUpload.js';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={FreightCalc} />
    <Route exact path={`${match.url}/upload`} component={FreightUpload} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
