import { connect } from "react-redux";
import React from 'react';
import { Modal } from "react-bootstrap";
import EditLineItesmForm from './EditLineItemsForm';
import { bindActionCreators } from "redux";
import { actionCreators } from "../../../../state/modals-dux";
import fetchDux from "../../../../state/fetch-dux";
import moment from "moment";

const ModalEditLineItem = (
    {
        name,
        closeModal,
        openModal,
        updateShipmentsLineItems,
        type,
        data,
        onUpdateSuccess,
        inProgress,
        error
    }
) => {
    const { name: openModalName, payload, poId, onModalWindowClosed } = openModal || {};

    const [item = {}] = payload || [];
    const { PurchaseOrderNumber } = item;
    const shipmentLineItems = (payload || []).map(({ shipmentLineItemId }) => shipmentLineItemId);

    const closeHandler = () => {
        if (!inProgress) {
            closeModal();
            onModalWindowClosed();
        }
    }
    const { TrackingNum, SchdShipDate } = item;
    const initialValues = { TrackingNum, SchdShipDate, error };

    return (
        <Modal
            show={openModalName === name}
            onHide={closeHandler}
            size="md"
            dialogClassName="modal-600 modal-edit-line-items"
        >
            <Modal.Header>
                <Modal.Title>
                    {`Edit Shipment Line Items (${(payload || []).length})`}
                </Modal.Title>
            </Modal.Header>

            <EditLineItesmForm
                closeModal={closeHandler}
                onSubmit={(values) => {
                    const { TrackingNum, SchdShipDate } = values;
                    const lineItemType =
                        TrackingNum && TrackingNum !== 'null' && TrackingNum !== initialValues.TrackingNum
                            ? 'tracking' :
                            SchdShipDate && SchdShipDate !== initialValues.SchdShipDate && !TrackingNum
                                ? 'scheduled' :
                                type
                    const lineItemData =
                        TrackingNum && TrackingNum !== 'null' && TrackingNum !== initialValues.TrackingNum
                            ? TrackingNum :
                            SchdShipDate && SchdShipDate !== initialValues.SchdShipDate && !TrackingNum
                                ? SchdShipDate
                                : data;

                    const payload = {
                        poNum: PurchaseOrderNumber,
                        TrackingNum,
                        SchdShipDate,
                        shipmentLineItems,
                        poId
                    }
                    updateShipmentsLineItems(payload, 'EditLineItemsForm', false, () => {
                        closeHandler();
                        onUpdateSuccess({ type: lineItemType, data: lineItemData });
                    })
                }}
                initialValues={initialValues}
            />

        </Modal>
    )
}


const mapState = state => {
    const { inProgress, error } = state.updateShipmentsLineItems.toJS();
    return {
        openModal: state.modals,
        inProgress,
        error
    }
}

const mapDispatch = dispatch => bindActionCreators(
    {
        ...actionCreators,
        updateShipmentsLineItems: fetchDux.updateShipmentsLineItems.createAction
    },
    dispatch
)

export default connect(mapState, mapDispatch)(ModalEditLineItem);