import React, { Component } from 'react';
import Loading from '../../../../ui/Loading';
import { Button } from 'react-bootstrap';
import PingOverlay from '../PingOverlay';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import fetchDux from '../../../../../state/fetch-dux';

const getInProgress = ping => {
  if (!ping) return false;
  const {
    dateinserted,
    responsedate,
    aborted
  } = ping || {};
  return !!dateinserted && !responsedate && !aborted;
};

class TaxPing extends Component {
  constructor(props, context) {
    super(props, context);
    this.ping = this.ping.bind(this);
    this.handleAbort = this.handleAbort.bind(this);
    this.timeout = null;
  }
  componentDidMount() {
    this.props.clearGetTaxUpdateStatus();
    this.ping();
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.props.clearGetTaxUpdateStatus();
  }
  componentWillReceiveProps(nextProps) {
    const { ping: p1 } = this.props;
    const { ping: p2, projectId, getProjectById } = nextProps;
    const inProgress1 = getInProgress(p1);
    const inProgress2 = getInProgress(p2);
    if (inProgress1 === true && inProgress2 === false)
      getProjectById(projectId);
  }
  async ping() {
    clearTimeout(this.timeout);
    const { projectId, setPing, getTaxUpdateStatus } = this.props;
    getTaxUpdateStatus(projectId, null, true, ping => {
      const {
        dateinserted,
        responsedate,
        Sage_Status,
        Sage_Message,
        aborted
      } = ping || {};

      const inProgress = !!dateinserted && !responsedate && !aborted;
      const error = Sage_Status === false && !aborted;
      const payload = { ...ping, inProgress, error };

      if (setPing) setPing(payload);

      if (!inProgress) return;

      this.timeout = setTimeout(this.ping, 2000);
    });
  }
  async handleAbort(pingId) {
    try {
      const { setPing, postAbortTaxUpdatePing, projectId } = this.props;
      postAbortTaxUpdatePing({ projectId, pingId }, null, null, ping => {
        const {
          dateinserted,
          responsedate,
          Sage_Status,
          aborted
        } = ping || {};

        const inProgress = !!dateinserted && !responsedate && !aborted;
        const error = Sage_Status === false && !aborted;
        const payload = { ...ping, inProgress, error };

        if (setPing) setPing(payload);
      });
    } catch (err) {}
  }
  render() {
    const { ping, style } = this.props;
    const {
      Sage_Message,
      dateinserted,
      responsedate,
      ID,
      aborted,
      Sage_Status
    } = ping || {};

    const inProgress = !!dateinserted && !responsedate && !aborted;
    const error = Sage_Status === false && !aborted;

    return (
      <div style={style || {}}>
        {inProgress &&
          <div style={{ textAlign: 'right' }}>
            <Loading
              color="#04844B"
              style={{ height: '40px', width: '40px' }}
              containerStyle={{ textAlign: 'right' }}
              message={
                <span>
                  Updating Tax Status
                  <Button
                    onClick={() => this.handleAbort(ID)}
                    variant="danger"
                    size="sm"
                    style={{ marginLeft: '50px', opacity: '.75' }}
                  >
                    Abort
                  </Button>

                </span>
              }
              messageStyle={{
                display: 'inline',
                color: 'rgba(0, 0, 0, 0.45)',
                marginLeft: '10px'
              }}
            />

          </div>}
        {error &&
          <div style={{ textAlign: 'right' }}>
            <span style={{ color: '#DC3528' }}>
              An error occurred updating Tax Address
              {' '}
              <PingOverlay message={Sage_Message} />
            </span>
            <Button
              variant="danger"
              size="sm"
              style={{ marginLeft: '50px', opacity: '.75' }}
              onClick={() => this.handleAbort(ID)}
            >
              Dismiss
            </Button>
          </div>}
      </div>
    );
  }
}

const mapState = state => {
  const {
    result: ping
  } = state.getTaxUpdateStatus.toJS();
  return {
    ping
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getTaxUpdateStatus: fetchDux.getTaxUpdateStatus.createAction,
      clearGetTaxUpdateStatus: fetchDux.getTaxUpdateStatus.clearAction,
      postAbortTaxUpdatePing: fetchDux.postAbortTaxUpdatePing.createAction,
      getProjectById: fetchDux.getProjectById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(TaxPing);
