import React from 'react';

const getAddressLine = value => {
  if (!(value || '').trim()) return '';
  return <span>{value}<br /></span>;
};

const PurchaseOrderAddress = (
  {
    Name,
    FirstName,
    LastName,
    Address1,
    Address2,
    City,
    State,
    Zip,
    c_Zip_4,
    PhoneContact,
    PhoneFax,
    shipping,
    c_PurchaseOrderNumber,
    c_Attn,
    c_AttnNameOnly
  }
) => (
  <div>
    <address style={{ marginBottom: '0' }}>
      {shipping && <h5>Concept Services Inc</h5>}
      <h5>{shipping && 'C/O: '}{Name}</h5>
      {shipping && c_Attn && <h5>Attn: {c_Attn}</h5>}

      {getAddressLine(
        FirstName || LastName ? `${FirstName || ''} ${LastName || ''}` : ''
      )}
      {getAddressLine(`${Address1 || ''}`)}
      {getAddressLine(`${Address2 || ''}`)}
      {City}, {State} {Zip}{c_Zip_4 ? `-${c_Zip_4}` : ''}
    </address>
    {c_PurchaseOrderNumber &&
      <address style={{ marginBottom: '0' }}>
        {!c_AttnNameOnly && <h5>Mark For PO #: {c_PurchaseOrderNumber}</h5>}
        {/* {c_AttnNameOnly && <h5>ATTN {c_Attn}</h5>} */}
      </address>}
    <address style={{ marginBottom: '0' }}>
      <div>
        <strong>Phone:</strong> {PhoneContact}
      </div>
      {/* <div>
        <strong>Fax:</strong> {PhoneFax}
      </div> */}
    </address>
  </div>
);

export default PurchaseOrderAddress;
