const statusDict = {
  DRAFT: 0,
  SENT: 1,
  VIEWED: 2,
  ACCEPTED: 3,
  DECLINED: 4,
  EXPIRED: 5,
  REVISION_REQUESTED: 6
};

export const getStatusLabel = (status, hasExpired) => {
  if (hasExpired) {
    return 'Expired';
  }
  switch (status) {
    case statusDict.DRAFT:
      return 'Draft';
    // label renamed CSIECO-432
    case statusDict.SENT:
      return 'Ready for Approval';
    case statusDict.VIEWED:
      return 'Viewed';
    case statusDict.ACCEPTED:
      return 'Accepted';
    case statusDict.DECLINED:
      return 'Declined';
    case statusDict.EXPIRED:
      return 'Expired';
    case statusDict.REVISION_REQUESTED:
      return 'Revision Requested';
    default:
      return '';
  }
};

export default statusDict;
