import React from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Notification from '../../../ui/Notification';
import fetchDux from '../../../../state/fetch-dux';

const PurchaseOrderNotifications = (
  {
    VendorName,
    approvePOResult,
    addressPOResult,
    sendPOResult,
    clearPostApprovePurchaseOrder,
    clearPostPurchaseOrderAddress,
    clearPostSendPurchaseOrder
  }
) => {
  if (approvePOResult && approvePOResult.result)
    return (
      <Notification
        key="po-approve-success"
        duration={3}
        closable={true}
        type="success"
        onClose={clearPostApprovePurchaseOrder}
      >
        {VendorName} PO approved!
      </Notification>
    );
  if (approvePOResult && approvePOResult.error)
    return (
      <Notification
        id="po-approve-error"
        duration={3}
        closable={true}
        type="danger"
        onClose={clearPostApprovePurchaseOrder}
      >
        An error occurred approving {VendorName} PO
        <br />
        {(approvePOResult.error || {}).message || ''}
      </Notification>
    );

  if (addressPOResult && addressPOResult.result)
    return (
      <Notification
        key="po-address-success"
        duration={3}
        closable={true}
        type="success"
        onClose={clearPostPurchaseOrderAddress}
      >
        {VendorName} PO updated!
      </Notification>
    );
  if (addressPOResult && addressPOResult.error)
    return (
      <Notification
        id="po-address-error"
        duration={3}
        closable={true}
        type="danger"
        onClose={clearPostPurchaseOrderAddress}
      >
        An error occurred updating {VendorName} PO
      </Notification>
    );
  if (sendPOResult && sendPOResult.result) {
    const { result } = sendPOResult;
    const { VendorLBMXEnabled, lbmxSuccess, sendEmail } = result;
    if (VendorLBMXEnabled && !lbmxSuccess && !sendEmail) return null;
    const message = VendorLBMXEnabled && !lbmxSuccess && sendEmail
      ? `${VendorName} PO sent successfully! LBMX failed, but the email was successfully sent.  Please follow-up.`
      : `${VendorName} PO sent successfully!`;

    return (
      <Notification
        key="po-send-success"
        duration={30}
        closable={true}
        type="success"
        onClose={clearPostSendPurchaseOrder}
      >
        {message}
      </Notification>
    );
  }

  if (sendPOResult && sendPOResult.error)
    return (
      <Notification
        key="po-send-error"
        duration={3}
        closable={true}
        type="danger"
        onClose={clearPostSendPurchaseOrder}
      >
        An error occurred while sending {VendorName} PO
      </Notification>
    );

  return false;
};

const mapState = state => {
  const approvePOResult = state.postApprovePurchaseOrder.toJS();
  const addressPOResult = state.postPurchaseOrderAddress.toJS();
  const sendPOResult = state.postSendPurchaseOrder.toJS();
  return {
    approvePOResult,
    addressPOResult,
    sendPOResult
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      clearPostApprovePurchaseOrder: fetchDux.postApprovePurchaseOrder.clearAction,
      clearPostPurchaseOrderAddress: fetchDux.postPurchaseOrderAddress.clearAction,
      clearPostSendPurchaseOrder: fetchDux.postSendPurchaseOrder.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(PurchaseOrderNotifications);
