import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Notification from '../../../ui/Notification';
import fetchDux from '../../../../state/fetch-dux';
import Icon from '../../../ui/Icon';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';
import WarehouseNewForm from './WarehouseNewForm';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class WarehouseNew extends Component {
  componentWillUnmount() {
    const {
      clearPostWarehouse
    } = this.props;
    clearPostWarehouse();
  }
  render() {
    const {
      error,
      inProgress,
      postWarehouse,
      history,
      clearPostWarehouse
    } = this.props;

    const handlePostWarehouse = values =>
      postWarehouse(
        { payload: values },
        'warehouse-new-form',
        null,
        newWarehouseId => {
          history.push(`/warehouses/${newWarehouseId}`);
        }
      );

    return (
      <Layout route="warehouses-new" alt>

        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Add New Warehouse
              </h2>
            </div>
          </Container>
        </div>

        <Container>
          <Box>
            <WarehouseNewForm
              onSubmit={handlePostWarehouse}
              submitting={inProgress}
            />
          </Box>
        </Container>

        {error &&
          <Notification
            key="warehouse-post-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPostWarehouse}
          >
            Error creating new warehouse.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    error,
    inProgress
  } = state.postWarehouse.toJS();
  return {
    error,
    inProgress,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postWarehouse: fetchDux.postWarehouse.createAction,
      clearPostWarehouse: fetchDux.postWarehouse.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(WarehouseNew);
