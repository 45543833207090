import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react';
import { Form, FormControl, Dropdown, Popover, OverlayTrigger } from 'react-bootstrap';
import ReactSelect from 'react-select';
import AsyncSelect from 'react-select/async';
import ReactSelectCreatable from 'react-select/creatable';
import DatePicker from 'react-datepicker';
import { useDropzone } from 'react-dropzone';
import FormError from './FormError';
import Toggle from '../ui/Toggle';
import Icon from '../ui/Icon';
import ScrollBox from '../ui/ScrollBox';

import classNames from 'classnames';
import moment from 'moment';

const getValidationState = (meta, hardTouched) => {
  if (meta.touched || hardTouched) {
    if (meta.valid === false) {
      return 'has-error';
    }
    if (meta.warning) {
      return 'has-warning';
    }
    if (meta.valid === true) {
      // return 'success';
    }
  }

  return null;
};

const getLabelClass = ({ required }) => required ? 'required' : '';

const Input = (
  {
    input,
    label,
    type,
    meta,
    help,
    required,
    insetLabel,
    hardTouched,
    ...props
  }
) => (
  <Form.Group
    controlId={input.name}
    className={classNames(getValidationState(meta, hardTouched), {
      'form-group-inset-label': insetLabel,
      'has-value': input.value
    })}
  >
    {label &&
      <Form.Label className={getLabelClass({ required })}>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {(meta.touched || hardTouched) &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const SelectHTML = (
  {
    input,
    label,
    type,
    meta,
    help,
    hardTouched,
    required,
    ...props
  }
) => (
  <Form.Group
    controlId={input.name}
    className={getValidationState(meta, hardTouched)}
  >
    {label &&
      <Form.Label className={getLabelClass({ required })}>{label}</Form.Label>}
    <div className="form-container">
      <Form.Control {...input} as="select" {...props} />
      {help && <Form.Text>{help}</Form.Text>}
      {(meta.touched || hardTouched) &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const SelectReactAsync = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => {
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <AsyncSelect
          {...props}
          value={(props.options || []).find(o => o.value === input.value)}
          onChange={value => input.onChange((value || {}).value)}
          onBlur={() => input.onBlur(input.value)}
          classNamePrefix="react-select"
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const SelectReact = (
  {
    input,
    label,
    altLabel,
    type,
    meta,
    help,
    onClear,
    required,
    afterChange,
    insetLabel,
    disabled,
    style = {},
    isClearable,
    ...props
  }
) => {
  const ref = React.createRef();
  useEffect(
    () => {
      if (input.value === -1) ref.current.select.clearValue();
    },
    [input.value]
  );
  return (
    <Form.Group
      controlId={input.name}
      className={classNames(getValidationState(meta), {
        'form-group-inset-label': insetLabel,
        'has-value': input.value
      })}
      style={
        disabled ? { opacity: '.5', pointerEvents: 'none', ...style } : style
      }
    >
      {label &&
        <Form.Label>
          <span className={getLabelClass({ required })}>{label}</span>
          {altLabel && altLabel}
        </Form.Label>}
      <div className="form-container">
        <ReactSelect
          {...props}
          value={(props.options || []).find(o => o.value === input.value)}
          onChange={(value, opt) => {
            input.onChange((value || {}).value || null);
            const { action } = opt || {};
            if (action === 'clear' && onClear) onClear();
            if (afterChange) afterChange((value || {}).value);
          }}
          onBlur={() => input.onBlur(input.value)}
          classNamePrefix="react-select"
          ref={ref}
          isClearable={isClearable}
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const MultiSelect = (
  {
    input,
    label,
    type,
    meta,
    required,
    help,
    className,
    onClear,
    afterChange,
    ...props
  }
) => (
  <Form.Group
    controlId={input.name}
    validationstate={getValidationState(meta)}
    className={className}
  >
    {label &&
      <Form.Label
        className={required ? 'control-label required' : 'control-label'}
      >
        {label}
      </Form.Label>}
    <div className="form-container">
      <ReactSelect
        isMulti
        {...props}
        value={(input.value || [])
          .map(v => (props.options || []).find(o => o.value === v))}
        onChange={(value, opt) => {
          const vals = (value || []).map(v => v.value);
          input.onChange(vals);
          if (opt.action === 'clear' && onClear) onClear();
          if (afterChange) afterChange(vals);
        }}
        onBlur={() => input.onBlur ? input.onBlur(input.value) : () => { }}
      />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Creatable = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      <ReactSelectCreatable
        {...props}
        value={input.value}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
      />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const InputGroup = (
  {
    input,
    label,
    type,
    meta,
    help,
    children,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    {label && <Form.Label>{label}</Form.Label>}
    <div className="form-container">
      {children}
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const formatFiles = value => {
  if (!value) return [];
  const keys = Object.keys(value);
  return (keys || []).map(key => value[key]);
};

const InputFileUpload = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    ...props
  }
) => {
  const handleFileChange = e => {
    e.preventDefault();
    const { files: inputFiles } = e.target;
    if (!inputFiles) return;
    const f = formatFiles(inputFiles);
    if (!multiple) {
      input.onChange(f[0]);
      return;
    }
    input.onChange([...input.value, ...f]);
  };
  const { value } = input || {};
  return (
    <Form.Group controlId={input.name} className={getValidationState(meta)}>
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <div className="file-upload">
          <div className="btn-file-upload btn btn-secondary">
            Choose Files
            <Form.Control
              value=""
              type="file"
              name={input.name}
              onChange={handleFileChange}
              multiple={multiple || false}
              {...props || {}}
            />
          </div>
          {value && <div>{value.name}</div>}
        </div>
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const InputFileDropzone = (
  {
    input,
    label,
    type,
    meta,
    help,
    multiple,
    disabled,
    ...props
  }
) => {
  const onDrop = useCallback(
    files => {
      if (!multiple) {
        input.onChange(files[0]);
        return;
      }
      input.onChange(files);
    },
    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const { valid } = meta || {};

  return (
    <Form.Group
      controlId={input.name}
      className={getValidationState(
        Object.assign({}, meta, { touched: meta.touched || meta.dirty })
      )}
    >
      <div className="file-import" {...getRootProps()}>
        {label && <Form.Label>{label}</Form.Label>}
        <div className="form-container">
          <div className="file-upload">
            <Form.Control
              value=""
              type="file"
              name={input.name}
              {...getInputProps({
                disabled: disabled || false,
                multiple: multiple || false
              })}
            />
            {isDragActive
              ? <p>Drop the files here ...</p>
              : <p>
                Drag 'n' drop some files here, or click to select files
                {valid &&
                  <span style={{ fontSize: '30px', color: '#28a745' }}>
                    &nbsp;<Icon name="check-circle" />
                  </span>}
              </p>}
          </div>
        </div>
      </div>
      {help && <Form.Text>{help}</Form.Text>}
      {(meta.touched || meta.dirty) &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </Form.Group>
  );
};

const InputToggle = (
  {
    input,
    label,
    meta,
    required,
    help,
    disabled,
    ...props
  }
) => (
  <Form.Group controlId={input.name} className={getValidationState(meta)}>
    <div className="form-container" style={disabled ? { opacity: '.5' } : {}}>
      <Toggle.Checkbox
        label={label}
        {...input}
        {...props}
        disabled={disabled}
      />
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const Textarea = (
  {
    input,
    label,
    altLabel,
    type,
    meta,
    required,
    help,
    insetLabel,
    ...props
  }
) => (
  <Form.Group
    controlId={input.name}
    className={classNames(getValidationState(meta), {
      'form-group-inset-label': insetLabel,
      'has-value': input.value
    })}
  >
    {label &&
      <Form.Label className={getLabelClass({ required })}>
        {label}{altLabel && altLabel}
      </Form.Label>}
    <div className="form-container">
      <Form.Control {...input} type={type} {...props} as="textarea" />
      {help && <Form.Text>{help}</Form.Text>}
      {meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const DatepickerTable = ({
  input,
  label,
  meta,
  help,
  required,
  disableWeekends,
  insetLabel,
  isUTC,
  ...props
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const datepickerRef = useRef(null);

  const handleChange = (value) => {
    input.onChange(value || '')
  };

  const handleDocumentClick = (event) => {
    if (
      datepickerRef.current &&
      !datepickerRef.current.contains(event.target)
    ) {
      // Click occurred outside the date picker, close it
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []); // Add and remove event listener on component mount and unmount

  return (
    <div ref={datepickerRef}>
      <Form.Group
        controlId={input.name}
        className={classNames('form-group-datepicker', getValidationState(meta), {
          'form-group-inset-label': insetLabel,
          'has-value': input.value
        })}
      >
        {label && (
          <Form.Label className={getLabelClass({ required })}>
            {label}
          </Form.Label>
        )}
        <div
          className={
            disableWeekends
              ? 'form-container disable-weekends'
              : 'form-container'
          }
        >
          <DatePicker
            className="form-control"
            onChange={handleChange}
            open={showCalendar}
            dateFormat="MM/dd/yyyy"
            selected={
              input.value && moment(input.value).isValid()
                ? isUTC
                  ? moment(input.value)
                    .add(
                      moment(input.value).toDate().getTimezoneOffset() / 60,
                      'hours'
                    )
                    .toDate()
                  : moment(input.value).toDate()
                : null
            }
          />
          <div onClick={() => setShowCalendar(!showCalendar)}>
            <Icon
              name="calendar"
              style={{ pointerEvents: 'fill', cursor: 'default' }}
            />
          </div>
          {help && <Form.Text>{help}</Form.Text>}
          {meta.touched &&
            (meta.error || meta.warning) && (
              <FormError message={meta.error || meta.warning} />
            )}
        </div>
      </Form.Group>
    </div>
  );
};

const Datepicker = (
  {
    input,
    label,
    meta,
    help,
    required,
    disableWeekends,
    insetLabel,
    isUTC,
    ...props
  }
) => {
  return (
    <Form.Group
      controlId={input.name}
      className={classNames('form-group-datepicker', getValidationState(meta), {
        'form-group-inset-label': insetLabel,
        'has-value': input.value
      })}
    >
      {label &&
        <Form.Label className={getLabelClass({ required })}>
          {label}
        </Form.Label>}
      <div
        className={
          disableWeekends ? 'form-container disable-weekends' : 'form-container'
        }
      >
        <DatePicker
          className="form-control"
          onChange={value => input.onChange(value || '')}
          onFocus={input.onFocus}
          onBlur={e => input.onBlur()}
          dateFormat="MM/dd/yyyy"
          selected={
            input.value && moment(input.value).isValid()
              ? isUTC
                ? moment(input.value)
                  .add(
                    moment(input.value).toDate().getTimezoneOffset() / 60,
                    'hours'
                  )
                  .toDate()
                : moment(input.value).toDate()
              : null
          }
        />
        <Icon
          name="calendar"
          style={{ pointerEvents: 'none', cursor: 'default' }}
        />
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

const getClassInlineCheckbox = (required, disabled) => {
  if (required && disabled) return 'required text-muted';
  if (required) return 'required';
  if (disabled) return 'text-muted';
  return '';
};

const InlineCheckbox = (
  {
    input,
    label,
    altLabel,
    meta,
    required,
    help,
    disabled,
    hideErrorMessage,
    style = {},
    checkBoxProps = {},
    inputContainerStyle,
    overlayProps,
    ...props
  }
) => {
  const { overlay = false, popOverLabel = '', title = '' } = overlayProps || {};
  return (
    <Form.Group
      controlId={input.name}
      className={classNames(getValidationState(meta))}
      style={disabled ? { opacity: '.75', ...style } : style}
    >
      {overlay &&
        <div
          className="form-container inline-checkbox"
          style={inputContainerStyle || {}}
        >
          <OverlayTrigger
            trigger="hover"
            placement="right"
            overlay={
              <Popover title={title || ''}>
                {popOverLabel}
              </Popover>
            }
          >
            <div>
              <label className="checkbox">
                <Form.Control
                  {...input}
                  type="checkbox"
                  {...props}
                  disabled={disabled}
                  checked={input.value === true}
                />
                <div className="checkbox-slider" {...checkBoxProps}>
                  <Icon name="check" />
                </div>
              </label>
              {label &&
                <strong className={getClassInlineCheckbox(required, disabled)}>
                  {label}
                </strong>}
              {altLabel && altLabel}
              {help && <Form.Text>{help}</Form.Text>}
              {!hideErrorMessage &&
                meta.touched &&
                (meta.error || meta.warning) &&
                <FormError message={meta.error || meta.warning} />}
            </div>
          </OverlayTrigger>
        </div>
      }
      {
        !overlay &&
        <div
          className="form-container inline-checkbox"
          style={inputContainerStyle || {}}
        >
          <label className="checkbox">
            <Form.Control
              {...input}
              type="checkbox"
              {...props}
              disabled={disabled}
              checked={input.value === true}
            />
            <div className="checkbox-slider" {...checkBoxProps}>
              <Icon name="check" />
            </div>
          </label>
          {label &&
            <strong className={getClassInlineCheckbox(required, disabled)}>
              {label}
            </strong>}
          {altLabel && altLabel}
          {help && <Form.Text>{help}</Form.Text>}
          {!hideErrorMessage &&
            meta.touched &&
            (meta.error || meta.warning) &&
            <FormError message={meta.error || meta.warning} />}
        </div>
      }

    </Form.Group>
  )
}

const getClassInlineRadio = (required, disabled) => {
  if (required && disabled) return 'required text-muted';
  if (required) return 'required';
  if (disabled) return 'text-muted';
  return '';
};

const InlineRadio = (
  {
    input,
    label,
    altLabel,
    meta,
    required,
    help,
    disabled,
    hideErrorMessage,
    style = {},
    checkBoxProps = {},
    inputContainerStyle,
    ...props
  }
) => (
  <Form.Group
    controlId={input.name}
    className={classNames(getValidationState(meta))}
    style={disabled ? { opacity: '.75', ...style } : style}
  >
    <div
      className="form-container inline-checkbox"
      style={inputContainerStyle || {}}
    >
      <label className="checkbox">
        <Form.Control
          {...input}
          type="radio"
          {...props}
          disabled={disabled}
          checked={input.value === true}
        />
        <div className="checkbox-slider" {...checkBoxProps}>
          <Icon name="check" />
        </div>
      </label>
      {label &&
        <strong className={getClassInlineRadio(required, disabled)}>
          {label}
        </strong>}
      {altLabel && altLabel}
      {help && <Form.Text>{help}</Form.Text>}
      {!hideErrorMessage &&
        meta.touched &&
        (meta.error || meta.warning) &&
        <FormError message={meta.error || meta.warning} />}
    </div>
  </Form.Group>
);

const RadioGroup = (
  {
    input,
    label,
    options,
    disabled,
    inputContainerStyle,
    getInputContainerStyle,
    meta
  }
) => {
  const { touched, error, warning } = meta || {};
  const showError = error || warning;
  return (
    <div>
      {label && label}
      {showError &&
        <span style={{ color: 'rgb(220, 53, 69)' }}>{error || warning}</span>}
      {(options || []).map(({ value, label }, index) => (
        <div
          key={index}
          style={
            getInputContainerStyle
              ? getInputContainerStyle({ value, label }, index)
              : inputContainerStyle || {}
          }
        >
          <input
            type="radio"
            id={value}
            {...input}
            value={value}
            checked={value === input.value}
            disabled={disabled}
            style={{ cursor: 'pointer' }}
          />
          <label
            htmlFor={value}
            style={{ paddingLeft: '5px', cursor: 'pointer' }}
          >
            {label}
          </label>

        </div>
      ))}
    </div>
  );
};

const CheckboxGroup = (
  {
    input,
    label,
    options,
    disabled,
    required,
    inputContainerStyle,
    getInputContainerStyle,
    meta
  }
) => {
  const { touched, error, warning } = meta || {};
  const showError = touched && (error || warning);
  const { value: masterValue } = (options || []).find(d => d.isMaster) || {};
  options = options.map(d =>
    Object.assign({}, d, {
      disabled: !d.isMaster && input.value.includes(masterValue)
    }));
  return (
    <div>
      {label &&
        <Form.Label className={getLabelClass({ required })}>
          {label}
        </Form.Label>}
      {showError &&
        <span style={{ color: 'rgb(220, 53, 69)' }}>{error || warning}</span>}
      {(options || []).map((opt, index) => {
        const handleChange = e => {
          let values = [...input.value];
          if (e.target.checked) {
            if (opt.isMaster) values = [opt.value];
            else values.push(opt.value);
          } else {
            values.splice(values.indexOf(opt.value), 1);
          }
          return input.onChange(values);
        };
        const checked = (input.value || []).includes(opt.value);
        return (
          <div
            key={index}
            style={
              getInputContainerStyle
                ? getInputContainerStyle(opt, index)
                : inputContainerStyle || {}
            }
          >
            <input
              type="checkbox"
              id={opt.value}
              name={`${input.name}[${index}]`}
              value={opt.value}
              checked={checked}
              disabled={opt.disabled || disabled}
              onChange={handleChange}
              style={{ cursor: 'pointer' }}
            />
            <label
              htmlFor={opt.value}
              style={{ paddingLeft: '5px', cursor: 'pointer' }}
            >
              {opt.label}
            </label>

          </div>
        );
      })}
    </div>
  );
};

const SelectWithSearchToggle = React.forwardRef(({
  children,
  onClick
}, ref) => (
  <div
    href=""
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </div>
));

const SelectWithSearchMenu = React.forwardRef(({
  children,
  style,
  className,
  'aria-labelledby': labeledBy,
  onSearchChange
}, ref) => {
  const [value, setValue] = useState('');
  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
      <div className="dropdown-item-search">
        <FormControl
          placeholder="Search"
          onChange={e => {
            setValue(e.target.value);
            onSearchChange(e.target.value);
          }}
          value={value}
        />
        {value &&
          value.length >= 3 &&
          <svg
            height="20"
            width="20"
            viewBox="0 0 20 20"
            aria-hidden="true"
            focusable="false"
            onClick={() => {
              setValue('');
              onSearchChange('');
            }}
          >
            <path
              d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
            />
          </svg>}
      </div>
      {children}
    </div>
  );
});

const SelectWithSearch = (
  {
    input,
    label,
    type,
    meta,
    help,
    ...props
  }
) => {
  let displayLabel = input.value.label || props.placeholder || ''
  if (displayLabel.startsWith('Create')) {
    displayLabel = displayLabel.split('Create')[1].toString().trim().replaceAll("'", '');
  }
  return (
    <Form.Group
      controlId={input.name}
      className={classNames(
        'form-group-search-select',
        getValidationState(meta)
      )}
    >
      {label && <Form.Label>{label}</Form.Label>}
      <div className="form-container">
        <Form.Control type="hidden" value={input.name} />
        <Dropdown>
          <Dropdown.Toggle
            as={SelectWithSearchToggle}
            id="dropdown-custom-components"
          >
            <div className="form-group-search-select-toggle">
              {displayLabel}
              <div className="actions">
                {input.value.label &&
                  <div className="separator">
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      onClick={value => input.onChange('')}
                    >
                      <path
                        d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"
                      />
                    </svg>
                  </div>}
                <svg
                  height="20"
                  width="20"
                  viewBox="0 0 20 20"
                  aria-hidden="true"
                  focusable="false"
                >
                  <path
                    d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                  />
                </svg>
              </div>
            </div>
          </Dropdown.Toggle>
          <Dropdown.Menu
            as={SelectWithSearchMenu}
            onSearchChange={value => props.onSearchChange(value)}
          >
            <ScrollBox>
              {(props.options || []).map((option, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={value => input.onChange(option || '')}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </ScrollBox>
          </Dropdown.Menu>
        </Dropdown>
        {help && <Form.Text>{help}</Form.Text>}
        {meta.touched &&
          (meta.error || meta.warning) &&
          <FormError message={meta.error || meta.warning} />}
      </div>
    </Form.Group>
  );
};

export default {
  Input,
  InputGroup,
  InputFileUpload,
  InputFileDropzone,
  InputToggle,
  Textarea,
  SelectHTML,
  SelectReact,
  SelectReactAsync,
  MultiSelect,
  Creatable,
  Datepicker,
  DatepickerTable,
  InlineCheckbox,
  InlineRadio,
  RadioGroup,
  CheckboxGroup,
  SelectWithSearch
};
