import React, { useState } from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const getProjectAddress = ({ project, addresses } = {}) => {
  const { ProjectAddressId } = project || {};
  return (addresses || [])
    .find(({ AddressId }) => AddressId === ProjectAddressId) || {};
};

const SelectedAddress = (
  {
    Name,
    Phone,
    Fax,
    Address1,
    Address2,
    City,
    StateProvince,
    PostalCode
  }
) => (
  <div className="address-card">
    <div className="location">
      <Icon name="id-card" />
      {Name}
    </div>
    <label>Phone</label>
    <p>{Phone}</p>
    <label>Address</label>
    <p>
      {Address1}
      {Address2 ? ` ${Address2}` : ''}
      ,
      {' '}
      {City}
      ,
      {' '}
      {StateProvince}
      ,
      {' '}
      {PostalCode}
    </p>
  </div>
);

const AddressFields = () => (
  <div>
    <Field
      required
      name="Name"
      type="text"
      component={FormField.Input}
      label="Company Name"
      validate={[required]}
    />
    <Field
      required
      name="PhoneContact"
      type="text"
      component={FormField.Input}
      label="Phone"
      validate={[required]}
    />
    <Field
      required
      name="Address1"
      type="text"
      component={FormField.Input}
      label="Address 1"
      validate={[required]}
    />
    <Field
      name="Address2"
      type="text"
      component={FormField.Input}
      label="Address 2"
    />
    <Field
      required
      name="City"
      type="text"
      component={FormField.Input}
      label="City"
      validate={[required]}
    />
    <Field
      required
      name="State"
      type="text"
      component={FormField.Input}
      label="State"
      validate={[required]}
    />
    <Field
      required
      name="Zip"
      type="text"
      component={FormField.Input}
      label="Zip"
      validate={[required]}
    />
    <Field
      name="c_Zip_4"
      type="text"
      component={FormField.Input}
      label="Zip 4"
    />

  </div>
);

const AltLabel = ({ children }) => (
  <div
    style={{
      color: 'rgb(209, 176, 67)',
      textTransform: 'uppercase',
      fontSize: '12px'
    }}
  >
    {children}
  </div>
);

const EditProjectShippingForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal,
    warehouseIdValue,
    lookups,
    hideButtons,
    change,
    VendorIsColorado,
    VendorIsColoradoType,
    result
  } = props;

  const { carriers, warehouses } = lookups || {};
  const [state, setState] = useState({
    hideShipViaText: false
  });
  const shipToOptions = [
    {
      value: 'other',
      label: 'Manually Enter'
    },
    ...(warehouses || []).map(({ ID, Name, City, StateProvince }) => ({
      value: ID,
      label: `${Name} - ${City || ''}, ${StateProvince || ''}`
    }))
  ];

  let shipperOptions = (carriers || []).map(({ Code, Name }) => ({
    value: Code,
    label: Name
  }));
  //need to get project and integration status Id to know if we should
  // push cheapest option below
  const {
    project
  } = result || {};
  const {
    c_SageIntegrationStatus
  } = project || {}
  // Add default option to Ship Via only if it is initial shipping confirmation
  if(c_SageIntegrationStatus < 3){
    shipperOptions.push({
      value: 'Use Cheapest Option Available',
      label: 'Use Cheapest Option Available'
    });
  }
  const selectedAddress = (warehouses || [])
    .find(({ ID }) => ID === warehouseIdValue);

  const clearAddress = () => {
    change('Name', '');
    change('PhoneContact', '');
    change('Address1', '');
    change('Address2', '');
    change('City', '');
    change('State', '');
    change('Zip', '');
    change('c_Zip_4', '');
    change('c_WarehouseID', '');
  };

  const handleJobsiteClick = () => {
    const address = getProjectAddress(result);

    const {
      Name: companyName,
      PhoneCell,
      PhoneCompany,
      Address1,
      Address2,
      City,
      State,
      Zip,
      c_Zip_4
    } = address || {};

    const phone = PhoneCompany || PhoneCell;
    change('Name', companyName);
    change('PhoneContact', phone);
    change('Address1', Address1);
    change('Address2', Address2);
    change('City', City);
    change('State', State);
    change('Zip', Zip);
    change('c_Zip_4', c_Zip_4);
    change('c_WarehouseID', 'other');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Row className={hideButtons ? '' : 'modal-body'}>
        <Col sm={12} style={{ paddingBottom: '30px', paddingTop: '30px' }}>
          <Row>
            <Col sm={6}>
              <Field
                name="c_AttnNameOnly"
                component={FormField.RadioGroup}
                options={[
                  { value: 'false', label: 'Mark for PO No' },
                  { value: 'true', label: 'Attn + Name ONLY' }
                ]}
                // inputContainerStyle={{ display: 'inline', marginLeft: '40px' }}
                getInputContainerStyle={(option, index) =>
                  index === 0
                    ? { display: 'inline' }
                    : { display: 'inline', marginLeft: '40px' }}
                validate={[required]}
                disabled={submitting}
              />
            </Col>
            <Col sm={6}>
              {VendorIsColoradoType === 3 &&
                <Field
                  name="c_IsColorado"
                  component={FormField.InputToggle}
                  label="Order through Colorado office"
                  disabled={VendorIsColoradoType !== 3}
                />}
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <Button
            style={{ float: 'right', border: 'none' }}
            variant="outline-secondary"
            size="sm"
            onClick={handleJobsiteClick}
          >
            <Icon name="copy" />&nbsp;Use Job Site Address
          </Button>

          <Field
            required
            name="c_WarehouseID"
            type="text"
            component={FormField.SelectReact}
            label="Ship To"
            altLabel={
              hideButtons
                ? null
                : <AltLabel>This will be the default ship to</AltLabel>
            }
            validate={[required]}
            options={shipToOptions}
            onClear={clearAddress}
            onChange={value => {
              if (value != 'other') clearAddress();
            }}
            isClearable
            isSearchable
          />
          <Field
            name="c_Attn"
            type="text"
            component={FormField.Input}
            label="Attn"
            // validate={[required]}
          />
          {warehouseIdValue !== 'other' &&
            selectedAddress &&
            <SelectedAddress {...selectedAddress} />}
          {warehouseIdValue === 'other' && <AddressFields />}
        </Col>
        <Col sm={6}>
          <Field
            required
            name="c_ShipViaId"
            type="text"
            component={FormField.SelectReact}
            label="Ship Via"
            altLabel={
              hideButtons
                ? null
                : <AltLabel>This will be the default carrier</AltLabel>
            }
            validate={[required]}
            options={shipperOptions}
            onChange={value => {
              value === 'Use Cheapest Option Available'
                ? setState({ hideShipViaText: false })
                : setState({ hideShipViaText: true });
            }}
          />
          <Field
            required
            name="c_ShipDate"
            type="text"
            component={FormField.Datepicker}
            label="Ship Date"
            validate={[required]}
          />
          <Field
            name="c_SpecialInstructions"
            rows={6}
            component={FormField.Textarea}
            label="Special Instructions"
            altLabel={
              hideButtons ? null : <AltLabel>To appear on every PO</AltLabel>
            }
            maxLength="1000"
          />
        </Col>
      </Row>
      {state.hideShipViaText &&
        <div
          className="note"
          style={{
            color: '#ff6666',
            fontSize: '1.0em',
            float: 'left',
            marginLeft: '15px',
            justifyContent: 'space-between'
          }}
        >
          <Row>
            <Col style={{padding:'20px'}}>
              You have selected an option other than "Use Cheapest Option Available" freight rate. Please confirm this is intentional.
            </Col>
          </Row>
          <Row>
            <Col style={{padding:'20px'}}>
              <Field
                name="notcheapest"
                type="checkbox"
                component={FormField.InlineCheckbox}
                validate={[required]}
                label="Yes"
              />
            </Col>
          </Row>
        </div>}
      {!hideButtons &&
        <div
          className="modal-footer"
          style={{ justifyContent: 'space-between' }}
        >
          <div
            className="note"
            style={{ color: '#D1B043', fontSize: '0.8em', float: 'left' }}
          >
            NOTE - You may make changes as needed prior to creating purchase orders.
          </div>
          <div>
            <Button
              variant="primary"
              type="submit"
              disabled={submitting}
              style={{ marginRight: '10px' }}
            >
              Save
            </Button>
            <Button
              variant="outline-secondary"
              type="button"
              onClick={closeModal}
            >
              Cancel
            </Button>

          </div>
        </div>}
    </form>
  );
};

const form = reduxForm({
  form: 'edit-shipping-form'
})(EditProjectShippingForm);

const mapState = (state, props) => {
  const formName = props.form || 'edit-shipping-form';
  const selector = formValueSelector(formName);

  const warehouseIdValue = selector(state, 'c_WarehouseID');
  return {
    warehouseIdValue
  };
};

export default connect(mapState)(form);
