import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Notification from '../../../ui/Notification';
import fetchDux from '../../../../state/fetch-dux';
import Icon from '../../../ui/Icon';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';
import WarehouseEditForm from './WarehouseEditForm';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class WarehouseEdit extends Component {
  componentDidMount() {
    const {
      match,
      getWarehouseById
    } = this.props;
    const { id } = match.params || {};
    getWarehouseById(id);
  }
  componentWillUnmount() {
    const {
      clearGetWarehouseById,
      clearPutWarehouse
    } = this.props;
    clearGetWarehouseById();
    clearPutWarehouse();
  }
  render() {
    const {
      putWarehouseInProgress,
      getWarehouseByIdInProgress,
      putWarehouse,
      result,
      history,
      putWarehouseError,
      clearPutWarehouse,
      getWarehouseByIdError,
      clearGetWarehouseById
    } = this.props;

    const {
      id
    } = result || {};

    const handlePutWarehouse = values =>
      putWarehouse(
        { payload: values, id },
        'warehouse-edit-form',
        null,
        () => {
          history.push(`/administration/warehouses/${id}`);
        }
      );

    return (
      <Layout route="warehouses-edit" alt>

        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Update Warehouse
              </h2>
            </div>
          </Container>
        </div>

        <Container>
          <Box>
            <WarehouseEditForm
              onSubmit={handlePutWarehouse}
              putWarehouseInProgress={putWarehouseInProgress}
              getWarehouseByIdInProgress={getWarehouseByIdInProgress}
              initialValues={result}
            />
          </Box>
        </Container>

        {putWarehouseError &&
          <Notification
            key="warehouse-put-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPutWarehouse}
          >
            Error updating warehouse information.
          </Notification>}
        {getWarehouseByIdError &&
          <Notification
            key="warehouse-get-edit-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearGetWarehouseById}
          >
            Error loading warehouse details.
          </Notification>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    error: putWarehouseError,
    inProgress: putWarehouseInProgress
  } = state.putWarehouse.toJS();
  const {
    result,
    inProgress: getWarehouseByIdInProgress,
    error: getWarehouseByIdError
  } = state.getWarehouseById.toJS();
  return {
    putWarehouseInProgress,
    getWarehouseByIdInProgress,
    result,
    putWarehouseError,
    getWarehouseByIdError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getWarehouseById: fetchDux.getWarehouseById.createAction,
      clearGetWarehouseById: fetchDux.getWarehouseById.clearAction,
      putWarehouse: fetchDux.putWarehouse.createAction,
      clearPutWarehouse: fetchDux.putWarehouse.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(WarehouseEdit);
