import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import ProjectStatusBar from './ProjectStatusBar';
import ProjectInfo from './ProjectInfo';
import ItemsGrid from './ItemsGrid';
import ApprovePurchaseOrders from './ApprovePurchaseOrders';
import ProjectInsertionStatus from './ProjectInsertionStatus';
import SendPurchaseOrders from './SendPurchaseOrders';
import PurchaseOrdersGrid from './PurchaseOrdersGrid';
import FinalizeOrder from './FinalizeOrder';
import CompleteOrderDetail from './CompleteOrderDetail';

import { actionCreators } from '../../../../state/modals-dux';
import Layout from '../../../ui/Layout';
import Loading from '../../../ui/Loading';
import fetchDux from '../../../../state/fetch-dux';
import statusDict from '../statusDict';
import ModalEditProjectShipping from './ModalEditProjectShipping';
import ModalValidateShippingInfoModal from './ModalValidateShippingInfoModal';
import ModalProjectErrorSummary from './ModalProjectErrorSummary';
import ModalOrphanedItems from './ModalOrphanedItems';
import ModalConfirmDeleteProject from './ModalConfirmDeleteProject';
import ValidateShippingInfoForm from './ValidateShippingInfoForm';
import ModalSendOrderInvoice from './ModalSendOrderInvoice';

const ProjectBody = ({ status, result, lookups, history }) => {
  switch (status) {
    case statusDict.INVALID:
    case statusDict.SHIPPING_VALIDATED:
      const { errors } = result || {};
      return errors.length
        ? ''
        : <ValidateShippingInfoForm result={result} history={history} />;
    case statusDict.VALIDATED:
    case statusDict.SHIPPING_CONFIRMED:
      return <ItemsGrid result={result} lookups={lookups} />;
    case statusDict.APPROVE_COMPLETE:
      return (
        <ItemsGrid
          result={result}
          lookups={lookups}
          POTable={PurchaseOrdersGrid}
          tableProps={{ showEdit: true }}
        />
      );
    case statusDict.COMPLETE:
    case statusDict.ARCHIVED:
      return <CompleteOrderDetail result={result} lookups={lookups} />;
    case statusDict.APPROVE_INPROGRESS:
      return (
        <ItemsGrid
          result={result}
          lookups={lookups}
          POTable={ApprovePurchaseOrders}
        />
      );
    case statusDict.INSERT_INPROGRESS:
      return <ProjectInsertionStatus result={result} ping />;
    case statusDict.INSERT_COMPLETE:
      return <ProjectInsertionStatus result={result} />;
    case statusDict.SEND_INPROGRESS:
      return (
        <ItemsGrid
          result={result}
          lookups={lookups}
          POTable={SendPurchaseOrders}
        />
      );
    case statusDict.SEND_COMPLETE:
      return <FinalizeOrder result={result} />;
    case statusDict.TAX_INPROGRESS:
      return <FinalizeOrder result={result} ping />;
    default:
      return <div />;
  }
};

const getProjectStatus = ({ result }) => {
  const { project } = result || {};
  const { c_SageIntegrationStatus } = project || {};
  return c_SageIntegrationStatus;
};

const getOrphanedItemsLength = ({ result }) => {
  const { orphanedItems } = result || {};
  const { length } = orphanedItems || {};
  return length || 0;
};

const getIsProject = c_SageIntegrationStatus => {
  return [
    statusDict.VALIDATED,
    statusDict.SHIPPING_VALIDATED,
    statusDict.SHIPPING_CONFIRMED,
    statusDict.APPROVE_INPROGRESS,
    statusDict.APPROVE_COMPLETE,
    statusDict.INSERT_INPROGRESS
  ].some(x => x === c_SageIntegrationStatus);
};

class ProjectsDetail extends Component {
  componentDidMount() {
    const {
      match,
      getProjectById,
      getLookups
    } = this.props;
    const { id } = match.params || {};
    getProjectById(id);
    getLookups();
  }
  componentDidUpdate(prevProps) {
    const status = getProjectStatus(this.props);
    const prevStatus = getProjectStatus(prevProps);
    const oLen = getOrphanedItemsLength(this.props);
    const oPrevLen = getOrphanedItemsLength(prevProps);
    if (oLen !== oPrevLen && oLen !== 0 && status < statusDict.INSERT_COMPLETE)
      this.props.showModal('OrphanedItemsModal');
    if (status === prevStatus) return;
    window.scrollTo(0, 0);
  }
  shouldComponentUpdate(nextProps) {
    const { openModal } = nextProps || {};
    const { openModal: openModal2 } = this.props;
    const different = openModal !== openModal2;
    return !openModal || different;
  }
  render() {
    const {
      user,
      lookups,
      result,
      postUpdateStatus,
      inProgress,
      showModal,
      closeModal,
      postProjectAddress,
      deleteOrphanedItems,
      deleteProject,
      cancelProject,
      history,
      initialValues
    } = this.props;
    const { project, projectAddress } = result || {};
    const {
      c_SageIntegrationStatus: status,
      ID,
      ProjectName,
      JobRef
    } = project || {};

    const handleValidateShippingInfo = () => {
      const {
        c_ShipViaId
      } = projectAddress || {};
      //if option is not "use cheapest option" then no need for validate 
      const statusToUse = c_ShipViaId === 'Use Cheapest Option Available' ? statusDict.SHIPPING_VALIDATED : statusDict.APPROVE_INPROGRESS
      const payload = { status: statusToUse };
      postUpdateStatus({ id: ID, payload });
    };
    const handleConfirmShippingInfo = () => {
      const payload = { status: statusDict.APPROVE_INPROGRESS };
      postUpdateStatus({ id: ID, payload });
    };
    const handleConfirmReviewItems = () => {
      const payload = { status: statusDict.APPROVE_INPROGRESS };
      postUpdateStatus({ id: ID, payload });
    };
    const handleConfirmInsertOrders = () => {
      const payload = { status: statusDict.INSERT_INPROGRESS };
      postUpdateStatus({ id: ID, payload });
    };
    const handleConfirmSendPOs = () => {
      const payload = { status: statusDict.SEND_INPROGRESS };
      postUpdateStatus({ id: ID, payload });
    };

    const handleShowEditShippingModal = () =>
      showModal('EditProjectShippingModal');

    const handleShowValidateShippingInfoModal = () =>
      showModal('ValidateShippingInfoModal');

    const handleViewProjectErrors = () => showModal('ProjectErrorSummaryModal');

    const handleSubmitProjectAddress = payload =>
      postProjectAddress({ id: ID, payload });

    const handleDeleteProject = () =>
      deleteProject(ID, null, null, data => {
        const { skipPing } = data || {};
        if (skipPing) history.push('/projects');
      });
    const handleCancelProject = () =>
      cancelProject(ID, null, null, () => history.push('/projects'));

    const confirmDeleteProject = () => showModal('ConfirmDeleteProjectModal');

    const handleSendOrderInvoiceModal = () =>
      showModal('SendOrderInvoiceModal');

    const handleSendOrderInvoice = data => {
      this.props.sendOrderInvoice({ id: ID, payload: data });
      closeModal();
    };
    const isProject = getIsProject(status);

    return (
      <Layout route="projects-detail">
        <ModalEditProjectShipping
          name="EditProjectShippingModal"
          initialValues={initialValues}
          result={result}
          handleSubmitProjectAddress={handleSubmitProjectAddress}
          lookups={lookups}
        />
        <ModalProjectErrorSummary
          name="ProjectErrorSummaryModal"
          result={result}
        />
        <ModalOrphanedItems
          name="OrphanedItemsModal"
          result={result}
          onDeleteOrphanedItems={() => deleteOrphanedItems(ID)}
          projectId={ID}
          onCancelProject={handleCancelProject}
          history={history}
          status={status}
        />
        <ModalConfirmDeleteProject
          name="ConfirmDeleteProjectModal"
          projectId={ID}
          onCancelProject={handleDeleteProject}
          history={history}
          status={status}
        />
        <ModalValidateShippingInfoModal
          name="ValidateShippingInfoModal"
          initialValues={initialValues}
          result={result}
          handleSubmitProjectAddress={handleSubmitProjectAddress}
        />
        <ModalSendOrderInvoice
          name="SendOrderInvoiceModal"
          projectId={ID}
          jobRef={JobRef}
          user={user}
          handleSendOrderInvoice={handleSendOrderInvoice}
        />
        <Breadcrumb>
          {isProject &&
            <Link to="/projects" className="breadcrumb-item">
              Projects
            </Link>}
          {!isProject &&
            <Link to="/orders" className="breadcrumb-item">
              Orders
            </Link>}
          <Breadcrumb.Item active>{ProjectName}</Breadcrumb.Item>
        </Breadcrumb>
        {!inProgress &&
          <div>
            {![statusDict.COMPLETE, statusDict.ARCHIVED].some(
              s => s === status
            ) &&
              <ProjectStatusBar
                user={user}
                status={status}
                projectId={ID}
                confirmDeleteProject={confirmDeleteProject}
              />}
            <ProjectInfo
              status={status}
              result={result}
              user={user}
              projectId={ID}
              lookups={lookups}
              confirmDeleteProject={confirmDeleteProject}
              handleSendOrderInvoiceModal={handleSendOrderInvoiceModal}
              handleViewProjectErrors={handleViewProjectErrors}
              handleConfirmShippingInfo={handleConfirmShippingInfo}
              handleConfirmReviewItems={handleConfirmReviewItems}
              handleShowEditShippingModal={handleShowEditShippingModal}
              handleValidateShippingInfo={handleValidateShippingInfo}
              handleShowValidateShippingInfoModal={
                handleShowValidateShippingInfoModal
              }
              handleConfirmInsertOrders={handleConfirmInsertOrders}
              handleConfirmSendPOs={handleConfirmSendPOs}
            />
            <ProjectBody
              status={status}
              result={result}
              lookups={lookups}
              history={history}
            />
          </div>}
        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();

  const {
    inProgress: postUpdateStatusInProgress,
    error: postUpdateStatusError
  } = state.postUpdateStatus.toJS();

  const {
    result: lookups,
    inProgress: getLookupsInProgress,
    error: getLookupsError
  } = state.getLookups.toJS();

  const { result: user } = state.login.toJS();

  return {
    user,
    openModal: state.modals,
    result,
    lookups,
    inProgress: getProjectByIdInProgress ||
      postUpdateStatusInProgress ||
      getLookupsInProgress,
    error: getProjectByIdError || postUpdateStatusError || getLookupsError,
    initialValues: {
      c_ShipViaId: 'Use Cheapest Option Available',
      hideShipViaText: false
    }
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getProjectById: fetchDux.getProjectById.createAction,
      postUpdateStatus: fetchDux.postUpdateStatus.createAction,
      postProjectAddress: fetchDux.postProjectAddress.createAction,
      sendOrderInvoice: fetchDux.sendOrderInvoice.createAction,
      getLookups: fetchDux.getLookups.createAction,
      deleteOrphanedItems: fetchDux.deleteOrphanedItems.createAction,
      deleteProject: fetchDux.deleteProject.createAction,
      cancelProject: fetchDux.cancelProject.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsDetail);
