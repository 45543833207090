import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import fetchDux from '../../../state/fetch-dux';
import Table from '../../ui/Table';
import Icon from '../../ui/Icon';
import Loading from '../../ui/Loading';
import { actionCreators } from '../../../state/modals-dux';

import ModalViewVendor from './ModalViewVendor';

const getColumns = handleViewVendor => {
  return [
    {
      Header: 'Vendor',
      accessor: 'Name',
      width: 175
    },
    {
      Header: 'Discount',
      accessor: 'Discount',
      width: 100,
      style: { textAlign: 'center' }
    },
    {
      Header: 'Rep Group',
      accessor: 'RepGroup',
      width: 175
    },
    {
      Header: 'Notes',
      accessor: 'AdditionalNotes',
      style: { whiteSpace: 'pre-wrap' }
    },
    {
      Header: '',
      accessor: 'ID',
      style: { textAlign: 'center' },
      width: 50,
      Cell: ({ value, original }) => {
        const { NumMappings } = original || {};
        return (
          <div>
            <Button
              size="sm"
              onClick={() => handleViewVendor(value)}
              style={{ marginLeft: '5px' }}
            >
              <Icon name="eye" />
            </Button>
          </div>
        );
      }
    }
  ];
};

const defaultSorted = [
  {
    id: 'Name',
    desc: false
  }
];

const getDefaultPageSize = data => {
  const { length } = data || [];
  if (!length || length < 5) return 5;
  if (length > 10) return 10;
  return length;
};

class SageVenderViewer extends Component {
  constructor(props) {
    super(props);
    this.state = { activeVendorID: null, search: '' };
  }
  componentDidMount() {
    this.props.getVendors();
    this.props.getVendorsMappings();
    this.props.getManufacturers();
  }
  render() {
    const {
      result,
      inProgress,
      mappings,
      showModal
    } = this.props;

    const { activeVendorID, search = ''} = this.state;

    const activeVendor = (result || []).find(({ ID }) => ID === activeVendorID);

    const handleSearchChange = value => {
      this.setState({ search: value });
    };

    const handleViewVendor = ID => {
      this.setState({ activeVendorID: ID });
      showModal('ViewVendorModal');
    };

    const columns = getColumns(handleViewVendor);

    const tableData = (result || []).filter((vendor) => {
      const { Name, Discount, FreightProgram, RepGroup, AdditionalNotes } = vendor;
      if(
        (Name || '').toString().toLowerCase().indexOf((search || '').toLowerCase()) >= 0 || (Discount || '').toString().toLowerCase().indexOf((search || '').toLowerCase()) >= 0 || 
        (FreightProgram || '').toString().toLowerCase().indexOf((search || '').toLowerCase()) >= 0 || (RepGroup || '').toString().toLowerCase().indexOf((search || '').toLowerCase()) >= 0 ||
        (AdditionalNotes || '').toString().toLowerCase().indexOf((search || '').toLowerCase()) >= 0
      ){
        return vendor;
      }
    })

    return (
      <div>

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

        {!inProgress &&
          <div>
            <ModalViewVendor
              name="ViewVendorModal"
              activeVendor={activeVendor}
            />

            <Table
              columns={columns}
              data={tableData || []}
              defaultPageSize={getDefaultPageSize(result)}
              defaultSorted={defaultSorted}
              noDataText="No Vendors exist"
              showSearch
              search={search}
              onSearchChange={handleSearchChange}
            />
          </div>}

      </div>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getVendors.toJS();
  const {
    result: mappings,
    inProgress: getVendorMappingsInProgress
  } = state.getVendorsMappings.toJS();
  const {
    result: manufacturers,
    inProgress: getManufacturersInProgress
  } = state.getManufacturers.toJS();

  return {
    result,
    mappings,
    manufacturers,
    inProgress: inProgress ||
      getVendorMappingsInProgress ||
      getManufacturersInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getVendors: fetchDux.getVendors.createAction,
      getManufacturers: fetchDux.getManufacturers.createAction,
      getVendorsMappings: fetchDux.getVendorsMappings.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(SageVenderViewer);
