import React, { useState, useRef } from 'react';
import moment from 'moment';
import Table from '../../../ui/Table';
import { Link } from 'react-router-dom';
import Icon from '../../../ui/Icon';
import ColumnPopover from '../../../ui/ColumnPopover';

const fields = {
  // shipmentId: { label: 'Shipment Detail' },
  PONum: { label: 'PO Number', type: 'link' },
  ShipmentDesc: { label: 'Shipment Description' },
  rank: { label: 'Shipment Group Number' },
  VendorName: { label: 'Vendor Name' },
  VendorOrderNum: { label: 'Vendor Order Number' },
  OrderDate: { label: 'Order Date', type: 'date' },
  ReqShipDate: { label: 'Req. Ship Date', type: 'date' },
  scheduledShippingDate: { label: 'Schd. Ship Date', type: 'date' },
  actualShippingDate: { label: 'Actual Ship Date', type: 'date' },
  scheduledDeliveryDate: { label: 'Schd. Delivery Date', type: 'date' },
  c_ShipViaId: { label: 'Carrier' },
  TrackingNum: { label: 'Tracking Number' },
  NumCartons: { label: 'Num Boxes' },
  ShipWeight: { label: 'Ship Weight' },
  FreightCharge: { label: 'Freight Charge' },
  CarrierNumCartons: { label: 'Carrier Num Boxes' },
  CarrierShipWeight: { label: 'Carrier Ship Weight' },
  CarrierFreightCharge: { label: 'Carrier Freight Charge' },
  carrier_ScheduledDeliveryDate: {
    label: 'Carrier Schd Delivery Date',
    type: 'date'
  },
  carrier_DeliveryDate: { label: 'Carrier Delivery Date', type: 'date' },
  ReceivingReportNum: { label: 'Receiving Report Number' },
  DeliveryStatus: { label: 'Status' },
  SignedForBy: { label: 'Signed For By' },
  Notes: { label: 'Notes', type: 'Notes' },
  whse_DeliveryDate: { label: 'Whse Receive Date', type: 'date' },
  CarrierDeliveryDate: { label: 'Actual Delivery Date', type: 'date' }
};

const shipmentsDict = {
  UNSHIPPED: "-1",
  NOTFOUND: 0,
  NOT_SHIPPED: 1,
  IN_TRANSIT: 2,
  CARRIER_DELIVERED: 3,
  WAREHOUSE_DELIVERED: 4,
  OUT_FOR_DELIVERY: 5,
  FAILED_ATTEMPT: 6,
  DELIVERED: 7,
  AVAILABLE_FOR_PICKUP: 8,
  EXCEPTION: 9,
  EXPIRED: 10,
  INFO_RECEIVED: 12
};

const getStatusLabel = (status) => {
  switch (status) {
    case shipmentsDict.NOTFOUND:
      return 'Not Found';
    case shipmentsDict.IN_TRANSIT:
      return 'In Transit';
    // case shipmentsDict.CARRIER_DELIVERED:
    //     return 'Carrier Says Delivered';
    case shipmentsDict.WAREHOUSE_DELIVERED:
      return 'Received By Warehouse';
    case shipmentsDict.OUT_FOR_DELIVERY:
      return 'Out for Delivery'
    case shipmentsDict.FAILED_ATTEMPT:
      return 'Failed Attempt'
    case shipmentsDict.DELIVERED:
      return 'Delivered'
    case shipmentsDict.AVAILABLE_FOR_PICKUP:
      return 'Available for Pickup'
    case shipmentsDict.EXCEPTION:
      return 'Exception'
    case shipmentsDict.EXPIRED:
      return 'Expired'
    case shipmentsDict.INFO_RECEIVED:
      return 'Info Received'
    case shipmentsDict.UNSHIPPED:
      return 'UnShipped'
    default:
      return '';
  }
}
const ReportsSearchResult = (
  {
    report = {},
    data = [],
    reportsActiveColumns
  }
) => {
  const [result, setResult] = useState(data);
  const [, setState] = useState(false);
  let tableRef = useRef(null);
  const handleSearch = search => {
    const res = data.filter(d =>
      Object.values(d).join(', ').toLowerCase().includes(search.toLowerCase()));
    setResult(res);
  };

  let columns = [
    ...[
      {
        Header: 'Shipment Detail',
        accessor: 'shipmentId',
        minWidth: 120,
        Cell: ({ original }) => {
          const { groupName, PoID, ID, TrackingNum, SchdShipDate, rank } = original;
          const formattedShipDate = moment.utc(SchdShipDate).format('YYYY-MM-DD');
          const misc = groupName.startsWith('misc');
          const data = groupName === 'tracking' ? TrackingNum
            : groupName === 'scheduled' ? formattedShipDate : 'na';
          const urlGroupName = groupName.startsWith('misc') ? 'unassigned' : groupName;
          const url = original.ShipmentId
            ? (original.ShipmentId === -1 || groupName.startsWith('misc')) ?
              `/shipments-groups/${PoID}/${urlGroupName}/${data}?misc=${misc}&rank=${rank}` :
              `/shipments/${original.ShipmentId}/${original.PoID}`
            : `/shipments/new/${original.PoID}`;

          return (
            <div className="text-center">
              <Link to={url} target="_blank">
                <Icon
                  style={{ fontSize: '25px', color: '#5EA2C9' }}
                  name="list-alt"
                />
              </Link>
            </div>
          );
        }
      }
    ],
    ...Object.keys(data[0] || {}).filter(f => fields[f]).map(f => {
      const { type, label } = fields[f];
      const res = { Header: label, accessor: f };
      if (type === 'date') {
        res.Cell = ({ value }) => (
          <span>{value ? moment(value).format('MM/DD/YYYY') : ''}</span>
        );
        res.sortMethod = (valueA, valueB) => {
          if (!moment(valueA).isValid()) {
            valueA = '01/01/1969'
          }
          if (!moment(valueB).isValid()) {
            valueB = '01/01/1969'
          }
          return moment(valueA).toDate() - moment(valueB).toDate();
        }
      }

      if (type === 'Notes') {
        res.Cell = ({ value }) => (
          <ColumnPopover header="Notes" data={value} />
        );
      }

      if (type === 'link')
        res.Cell = ({ value, original: row }) => {
          if (row.c_IsImported)
            return <ColumnPopover header="PO Number" data={value} />;
          return (
            <ColumnPopover
              header="PO Number"
              data={value}
              url={`/projects/${row.ProjectId}`}
            />
          );
        };

      else if (f === 'DeliveryStatus') {
        res.Cell = ({ value }) => (
          <span>{getStatusLabel(value)}</span>
        )
      }
      return res;
    })
  ];

  const subHeadingClass = report.description || report.instruction
    ? 'pb-3'
    : 'pb-2';
  const reportName =
    (report.name || report.label || '') ===
      'In Transit' ? 'Shipment Status Report: In Transit' :
      (report.name || report.label || '') ===
        'Shipping Exception' ? 'Shipment Status Report: Exceptions' :
        (report.name || report.label || '') ===
          'Unconfirmed Deliveries' ? 'Unconfirmed Warehouse Deliveries' :
          (report.name || report.label || '') ===
            'Out For Delivery' ? 'Shipment Status Report: Out for Delivery' :
            (report.name || report.label || '') ===
              'In Carrier Custody' ? 'Shipment Status Report: In Carrier Custody' :
              `${(report.name || report.label || '')} Report`;
  const pageSize = '100';
  let c = [];
  if (Object.keys(report).length) {
    switch (reportName) {
      case 'Acknowledgement Report':
        c = [
          'shipmentId',
          'OrderDate',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'VendorOrderNum',
          'ReqShipDate',
          'scheduledShippingDate',
          'Notes'
        ]
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      case 'Shipment Status Report: In Transit':
      case 'Shipment Status Report: Out for Delivery':
        // c = [
        //   'ShipWeight',
        //   'NumCartons',
        //   'ReqShipDate',
        //   'scheduledDeliveryDate',
        //   'scheduledShippingDate',
        //   'VendorOrderNum'
        // ];
        // columns = columns.map((column) => {
        //   const { accessor, ...rest } = column;
        //   if (c.includes(accessor)) {
        //     return {
        //       accessor,
        //       ...rest,
        //       show: false
        //     }
        //   } else {
        //     return {
        //       accessor,
        //       ...rest
        //     }
        //   }
        // })
        c = [
          'shipmentId',
          'PONum',
          'VendorName',
          'VendorOrderNum',
          'ShipmentDesc',
          'rank',
          'scheduledShippingDate',
          'actualShippingDate',
          'c_ShipViaId',
          'TrackingNum',
          'DeliveryStatus',
          'scheduledDeliveryDate',
          'NumCartons',
          'ShipWeight',
          'CarrierFreightCharge'
        ];
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      case 'Shipping Action Report':
        c = [
          'shipmentId',
          'OrderDate',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'VendorOrderNum',
          'ReqShipDate',
          'scheduledShippingDate',
          'actualShippingDate',
          'c_ShipViaId',
          'TrackingNum',
          'DeliveryStatus',
          'NumCartons',
          'ShipWeight',
          'CarrierFreightCharge'
        ]
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        c = [
          'c_ShipViaId',
          'NumCartons',
          'scheduledDeliveryDate',
          'TrackingNum',
          'ShipWeight',
          'FreightCharge'
        ];
        columns = columns.map((column) => {
          const { accessor, ...rest } = column;
          if (c.includes(accessor)) {
            return {
              accessor,
              ...rest,
              show: false
            }
          } else {
            return {
              accessor,
              ...rest
            }
          }
        })
        break;

      case 'Shipment Status Report: Exceptions':
        // c = [
        //   'shipmentId',
        //   'PONum',
        //   'VendorName',
        //   'ShipmentDesc',
        //   'actualShippingDate',
        //   'c_ShipViaId',
        //   'TrackingNum',
        //   'DeliveryStatus',
        //   'scheduledDeliveryDate',
        //   'rank'
        // ];
        // columns = columns.map((column) => {
        //   const { accessor, ...rest } = column;
        //   if (c.includes(accessor)) {
        //     return {
        //       accessor,
        //       ...rest,
        //       show: true
        //     }
        //   } else {
        //     return {
        //       accessor,
        //       show: false,
        //       ...rest
        //     }
        //   }
        // })
        c = [
          'shipmentId',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'scheduledShippingDate',
          'actualShippingDate',
          'c_ShipViaId',
          'TrackingNum',
          'DeliveryStatus',
          'scheduledDeliveryDate',
          'NumCartons',
          'ShipWeight',
          'CarrierFreightCharge'
        ]
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      case
        'Shipment Status Report: In Carrier Custody':
        // c = [
        //   'shipmentId',
        //   'PONum',
        //   'VendorName',
        //   'ShipmentDesc',
        //   'actualShippingDate',
        //   'c_ShipViaId',
        //   'TrackingNum',
        //   'DeliveryStatus',
        //   'scheduledDeliveryDate',
        //   'rank'
        // ];
        // columns = columns.map((column) => {
        //   const { accessor, ...rest } = column;
        //   if (c.includes(accessor)) {
        //     return {
        //       accessor,
        //       ...rest,
        //       show: true
        //     }
        //   } else {
        //     return {
        //       accessor,
        //       show: false,
        //       ...rest
        //     }
        //   }
        // })
        c = [
          'shipmentId',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'scheduledShippingDate',
          'actualShippingDate',
          'c_ShipViaId',
          'TrackingNum',
          'DeliveryStatus',
          'scheduledDeliveryDate',
          'NumCartons',
          'ShipWeight',
          'CarrierFreightCharge'
        ];
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      case 'Vendor Summary Report':
        c = [
          'shipmentId',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'actualShippingDate',
          'c_ShipViaId',
          'TrackingNum',
          'DeliveryStatus',
          'ShipWeight',
          'CarrierDeliveryDate'
        ];
        columns = columns.map((column) => {
          const { accessor, ...rest } = column;
          if (c.includes(accessor)) {
            return {
              accessor,
              ...rest,
              show: true
            }
          } else {
            return {
              accessor,
              show: false,
              ...rest
            }
          }
        })
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      case 'Unconfirmed Warehouse Deliveries Report':
        // c = ['NumCartons'];
        // columns = columns.map((column) => {
        //   const { accessor, ...rest } = column;
        //   if (c.includes(accessor)) {
        //     return {
        //       accessor,
        //       ...rest,
        //       show: false
        //     }
        //   } else {
        //     return {
        //       accessor,
        //       ...rest
        //     }
        //   }
        // })       
        c = [
          'shipmentId',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'VendorOrderNum',
          'c_ShipViaId',
          'TrackingNum',
          'CarrierDeliveryDate',
          'SignedForBy',
          'NumCartons',
          'ShipWeight'
        ]
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      case 'Project Manager Report':
        c = [
          'shipmentId',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'VendorOrderNum',
          'scheduledShippingDate',
          'actualShippingDate',
          'c_ShipViaId',
          'TrackingNum',
          'DeliveryStatus',
          'scheduledDeliveryDate',
          'CarrierDeliveryDate',
          'NumCartons',
          'ShipWeight',
          'CarrierFreightCharge'
        ];
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;

      case 'To Be Shipped Report':
        c = [
          'shipmentId',
          'OrderDate',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'ReqShipDate',
          'scheduledShippingDate',
          'actualShippingDate'
        ];
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      case 'Warehouse Report':
        c = [
          'shipmentId',
          'PONum',
          'VendorName',
          'ShipmentDesc',
          'rank',
          'VendorOrderNum',
          'scheduledShippingDate',
          'actualShippingDate',
          'c_ShipViaId',
          'TrackingNum',
          'DeliveryStatus',
          'CarrierDeliveryDate',
          'SignedForBy',
          'ReceivingReportNum'
        ];
        c.forEach((a, i) => {
          const index = columns.findIndex(({ accessor }, idx) => {
            if (accessor === a) return idx;
          })
          if (index >= 0) {
            const [element] = columns.splice(index, 1);
            columns.splice(i, 0, element);
          }
        })
        break;
      default:
    }
  }



  columns = columns.map((c) => {
    return {
      ...c,
      className: 'text-center'
    }
  })
  const currentReportActiveColumns = reportsActiveColumns[reportName] || [];
  if (currentReportActiveColumns.length !== 0) {
    columns.forEach(col => {
      col.show = currentReportActiveColumns.includes(col.accessor);
    });
  }
  columns = columns.sort((a, b) => Number(b.show) - Number(a.show));
  const listDraggableColumns = (columns || []).map(({ accessor }) => accessor);
  const reportColumnsOrder = JSON.parse(window.localStorage.getItem('reportColumnsOrder')) || {};
  const columnsOrder = reportColumnsOrder[reportName] || [];
  if (Object.keys(report).length) {
    columnsOrder.forEach((c_accessor, index) => {
      const currentIndex = columns.findIndex(({ accessor }) => c_accessor === accessor);
      if (currentIndex !== index) {
        const element = columns[currentIndex];
        columns.splice(currentIndex, 1);
        columns.splice(index, 0, element);
      }
    })
  }
  return (
    <div>

      <h5>
        <strong>
          {reportName}
        </strong>
        {
          ((data || []).length === 0) &&
          <span>
            <br />
            <stron>
              {'No Results'}
            </stron>
          </span>
        }

      </h5>


      {/* <div className={subHeadingClass}>
        {
          report.description &&
          ((data || []).length > 0) &&
          <div className="text-muted">
            {report.description}
          </div>
        }
        {
          report.instruction &&
          ((data || []).length > 0) &&
          <div>
            {report.instruction}
          </div>
        }
      </div> */}
      <Table
        style={{ height: 600 }}
        showColumnsSelector
        showSearch
        showExport
        onSearchChange={handleSearch}
        data={result}
        fullData={data}
        columns={columns}
        showCopy
        showNote={
          <p
            className="text-muted"
            style={{ display: 'inline', marginRight: '10px' }}
          >
            Note: To print results export as csv
          </p>
        }
        noDataText="No Reports Found"
        exportWithHeaderData={`Concept Services,${reportName}\r\n`}
        defaultPageSize={Number(pageSize)}
        draggableColumns={true}
        onDropComplete={(reorder) => {
          let reportColumnsOrder = JSON.parse(window.localStorage.getItem('reportColumnsOrder'));
          const reportsActiveColumns = JSON.parse(window.localStorage.getItem('reportsActiveColumns')) || {};
          if (!reportColumnsOrder) {
            reportColumnsOrder = {};
          }
          const activeColumns = reportsActiveColumns[reportName] || [];
          if (activeColumns.length !== 0) {
            columns.forEach(col => {
              col.show = activeColumns.includes(col.accessor);
            });
          }
          const visibleColumns = columns.filter(col => col.show === true || col.show === undefined);
          const hiddenColumns = columns.filter(col => col.show === false);
          const columnsOrder = [...visibleColumns, ...hiddenColumns].map(({ accessor }) => accessor);
          reorder.forEach(o => columnsOrder.splice(o.a, 0, columnsOrder.splice(o.b, 1)[0]));
          reportColumnsOrder[reportName] = columnsOrder;
          window.localStorage.setItem('reportColumnsOrder', JSON.stringify(reportColumnsOrder));
        }}
        onColumnChange={
          (activeColumns) => {
            if ((data || []).length) {
              (reportsActiveColumns || {})[reportName] = activeColumns;
              window.localStorage.setItem('reportsActiveColumns', JSON.stringify(reportsActiveColumns));
              setState((prev) => !prev);
            }
          }
        }
        listDraggableColumns={listDraggableColumns}
        getStatusLabel={getStatusLabel}
        tableRef={tableRef}
        getColumnsOrder={
          () => {
            const reportColumnsOrder = JSON.parse(window.localStorage.getItem('reportColumnsOrder')) || {};
            const columnsOrder = reportColumnsOrder[reportName] || [];
            return columnsOrder
          }
        }
        showPageJump={false}
        minRows={10}
      />
    </div>
  );
};

export default ReportsSearchResult;
