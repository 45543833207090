import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import SendAllConfirmationsForm from './SendAllConfirmationsForm';
import Notification from '../../../ui/Notification';
import { formValueSelector } from 'redux-form';

class ModalSendAllConfirmations extends Component {
  constructor(props) {
    super(props);
    this.state = { showPreview: false };
  }
  render() {
    const {
      selectedWarehouses,
      clearSelectedWarehouses,
      openModal,
      closeModal,
      sendAllConfirmationEmails,
      sendAllConfirmationEmailsError,
      inProgress,
      formSubject,
      formBody,
      formFooter,
      user
    } = this.props;
    const { showPreview } = this.state;

    const initialValues = {
      subject: 'Rates Confirmation',
      body: 'We are Concept Services a restuarant equipment supplier and ' +
        'installation company, we have used your services before and want to ' +
        'make sure that we maintain up to date information about your ' +
        'addresses, contacts and rates. This allows us to more easily use ' +
        'your services again in future.',
      footer: 'Please use the link below to review/update the information we have on ' +
        'file for addresses, contact information and rates.'
    };

    // Fix show preview bug on close
    const cancelModal = () => {
      this.setState({ showPreview: false });
      closeModal();
    };

    const handleSubmit = payload => {
      if (showPreview) {
        sendAllConfirmationEmails(
          { ...payload, selectedWarehouses },
          'send-all-confirmations-form',
          null,
          () => {
            clearSelectedWarehouses();
            cancelModal();
          }
        );
      } else {
        this.setState({ showPreview: true });
      }
    };

    const closePreview = () => {
      this.setState({ showPreview: false });
    };

    return (
      <Modal
        show={openModal === 'SendAllConfirmationsModal'}
        onHide={cancelModal}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send All Confirmations</Modal.Title>
        </Modal.Header>
        <SendAllConfirmationsForm
          onSubmit={handleSubmit}
          submitting={inProgress}
          closeModal={cancelModal}
          initialValues={initialValues}
          showPreview={showPreview}
          closePreview={closePreview}
          formValues={{ formSubject, formBody, formFooter }}
          user={user}
        />
        {sendAllConfirmationEmailsError &&
          <Notification
            key="send-all-confirmations-error"
            duration={5}
            closable={true}
            type="danger"
          >
            Error sending all confirmations, please try again.
          </Notification>}
      </Modal>
    );
  }
}

const selector = formValueSelector('send-all-confirmations-form');

const mapState = state => {
  const formSubject = selector(state, 'subject');
  const formBody = selector(state, 'body');
  const formFooter = selector(state, 'footer');
  const {
    inProgress,
    error: sendAllConfirmationEmailsError
  } = state.sendAllConfirmationEmails.toJS();
  const {
    result: user
  } = state.validateLogin.toJS();
  return {
    sendAllConfirmationEmailsError,
    inProgress,
    openModal: state.modals,
    formSubject,
    formBody,
    formFooter,
    user
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      sendAllConfirmationEmails: fetchDux.sendAllConfirmationEmails.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalSendAllConfirmations);
