import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { Row, Col, Button, Table } from 'react-bootstrap';

import PurchaseOrderNotifications from './PurchaseOrderNotifications';
import { authenticatedHref } from '../../../../api/fetch';
import Icon from '../../../ui/Icon';
import PurchaseOrderAddress from './PurchaseOrderAddress';
import DiffRender from '../projects-update/DiffRender';

const formatMoney = value => numeral(value).format('0,0.00');

const sumTotalPrice = lines =>
  (lines || []).reduce((prev, { TotalPrice }) => prev + TotalPrice, 0);

const getLineSpecialInstructions = (initialValues, LineItemId) =>
  ((initialValues || {}).ItemSpecial || {})[LineItemId] || null;

const getPDFUrl = (
  {
    directory,
    filename
  },
  fileUrl
) => authenticatedHref(fileUrl || `files/${directory}/${filename}`);

const getRowStyle = ({ __type, isDeleted }) => {
  if (isDeleted) return { backgroundColor: '#ffebe6' };
  switch (__type) {
    case 'add':
      return { backgroundColor: '#e3fcef' };
    case 'delete':
      return { backgroundColor: '#ffebe6' };
    default:
      return {};
  }
};

class PurchaseOrderBody extends Component {
  render() {
    const {
      LastVendorName,
      purchaseOrder,
      pdfDetails,
      fileUrl,
      showEdit,
      toggleEditing,
      inProgress,
      shippingAddress,
      billingAddress,
      lineItemGroups,
      initialValues,
      hidePDF,
      actions,
      isDeleted,
      showEditDenied
    } = this.props;

    const {
      VendorName,
      VendorNum,
      SumSellPrice,
      c_PurchaseOrderNumber,
      c_POSent,
      c_POSent_Date
    } = purchaseOrder || {};

    const {
      c_ShipDate,
      c_SpecialInstructions,
      CarrierName,
      c_ShipViaId
    } = shippingAddress || {};

    const isStillGenericCHeapestOptionText = c_ShipViaId === 'Use Cheapest Option Available';

    return (
      <div className="purchase-order">
        <div>
          <PurchaseOrderNotifications VendorName={LastVendorName} />
          {c_POSent &&
            !hidePDF &&
            <Row>
              <Col style={{ textAlign: 'right', marginBottom: '10px' }}>
                <a href={getPDFUrl(pdfDetails || {}, fileUrl)} target="_blank">
                  <Button variant="danger">
                    <Icon name="download" />
                    &nbsp;
                    CLICK HERE TO DOWNLOAD PDF
                  </Button>
                </a>
              </Col>
            </Row>}
          <Row>
            <Col sm="auto">
              <strong className="header">P.O. No.</strong>
              {c_PurchaseOrderNumber}
            </Col>
            <Col sm="auto">
              <strong className="header">Date Ordered</strong>
              {c_POSent_Date && moment(c_POSent_Date).isValid()
                ? moment(c_POSent_Date).format('MM/DD/YYYY')
                : <span className="text-muted">PENDING</span>}
            </Col>
            <Col sm="auto">
              <strong className="header">Vendor No.</strong>
              {VendorNum}
            </Col>
            <Col sm="auto">
              <strong className="header">Vendor</strong>
              {VendorName}
            </Col>
            {showEdit &&
              <Col className="text-right">
                <div>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    onClick={() => toggleEditing(true)}
                    disabled={inProgress}
                  >
                    Edit
                  </Button>
                </div>
              </Col>}
          </Row>
          <Row>
            <Col sm="auto" style={{ fontWeight: 'bold', marginTop: '20px' }}>
              Purchase Order Total
            </Col>
            <Col sm="auto" style={{ fontWeight: 'bold', marginTop: '20px' }}>
              ${formatMoney(SumSellPrice)}
            </Col>
          </Row>
          <Table responsive className="table-items">
            <thead>
              <tr>
                <th width="50%">Bill To</th>
                <th width="50%">Ship To</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <PurchaseOrderAddress {...billingAddress} />
                </td>
                <td>
                  <PurchaseOrderAddress
                    {...shippingAddress}
                    c_PurchaseOrderNumber={c_PurchaseOrderNumber}
                    shipping
                  />
                </td>
              </tr>
              <tr>
                <td style={{ borderTop: 'none' }}>
                  <div className="shipping-info">
                    {!isDeleted &&
                      <div>
                        <strong>Ship Date:</strong>
                        {' '}
                        {c_ShipDate && moment(c_ShipDate).isValid()
                          ? moment(c_ShipDate).format('MM/DD/YYYY')
                          : ''}
                      </div>}
                    {isDeleted &&
                      <div>
                        <strong>Ship Date:</strong>
                        {' '}
                        DELETED
                      </div>}
                  </div>
                </td>
                <td style={{ borderTop: 'none' }}>
                  <div className="shipping-info">
                    <div>
                      {isStillGenericCHeapestOptionText &&
                        <div style={{color:'red'}}>
                          *Possible Generic Ship Method*
                        </div>
                      }
                      <strong>Ship Via:</strong>
                      {' '}
                      {c_ShipViaId || 'Use Cheapest Option Available'}<br/>
                      <strong>Vendor:</strong> Please mark BOL with PO number
                    </div>
                  </div>
                </td>
              </tr>
              {c_SpecialInstructions &&
                <tr>
                  <td colSpan="2" className="special-instructions">
                    <h5>
                      <Icon prefix="far" name="hand-point-right" />
                      {' '}
                      SPECIAL INSTRUCTIONS:
                    </h5>
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                      {c_SpecialInstructions}
                    </span>
                  </td>
                </tr>}
              <tr>
                <th colSpan={2} style={{ textAlign: 'center' }}>
                  Order Items
                </th>
              </tr>
              <tr>
                <td colSpan={2}>

                  <Table>
                    <thead>
                      <tr>
                        <th>ItemNo</th>
                        <th>Qty</th>
                        <th>Model</th>
                        <th>Description</th>
                        <th className="text-right">Cost (ea)</th>
                        <th className="text-right">Cost (Ext.)</th>
                      </tr>
                    </thead>

                    {lineItemGroups.map(({ LineItemId, lines }, lidx) => {
                      const allActions = (lines || [])
                        .reduce(
                          (prev, { __actions }) => [
                            ...prev,
                            ...(__actions || [])
                          ],
                          []
                        );

                      const newSum = (lines || [])
                        .filter(({ __type }) => __type !== 'delete')
                        .reduce((prev, { TotalPrice }) => prev + TotalPrice, 0);

                      const prevSum = (lines || []).reduce(
                        (prev, { __actions, TotalPrice, ID }) => {
                          const action = (allActions || [])
                            .find(
                              ({ type, field, portalId }) =>
                                type === 'modify' &&
                                field === 'TotalPrice' &&
                                portalId === ID
                            );
                          if (!action) return prev + TotalPrice;
                          return prev + action.originalValue;
                        },
                        0
                      );

                      const sumActions = prevSum === newSum
                        ? []
                        : [
                            {
                              type: 'modify',
                              field: 'LineItemGroupSum',
                              value: newSum,
                              originalValue: prevSum
                            }
                          ];

                      const strikeStyle = isDeleted
                        ? { textDecoration: 'line-through' }
                        : {};
                      return (
                        <tbody key={lidx}>
                          {(lines || []).map(({
                            ItemNumber,
                            Quantity,
                            Model,
                            Spec,
                            NetPrice,
                            TotalPrice,
                            Taxable,
                            __actions,
                            __type,
                            locked,
                            c_Deleted,
                            c_UpdateRejected
                          }, index) => {
                            return (
                              <tr
                                key={index}
                                style={getRowStyle({ __type, isDeleted })}
                              >
                                <td>
                                  {index === 0 &&
                                    <DiffRender
                                      field="ItemNumber"
                                      value={ItemNumber}
                                      actions={__actions}
                                    />}
                                </td>
                                <td>
                                  <DiffRender
                                    field="Quantity"
                                    value={Quantity}
                                    actions={__actions}
                                    diffStyle={{ textAlign: 'right' }}
                                    render={value => `${value}`}
                                    renderDiff={value => `${value}`}
                                  />
                                </td>
                                <td>
                                  <DiffRender
                                    field="Model"
                                    value={Model}
                                    actions={__actions}
                                  />
                                </td>
                                <td>
                                  <DiffRender
                                    field="Spec"
                                    value={Spec}
                                    actions={__actions}
                                  />
                                </td>
                                <td className="text-right" style={strikeStyle}>
                                  <DiffRender
                                    field="NetPrice"
                                    value={NetPrice}
                                    placement="left"
                                    actions={__actions}
                                    renderDiff={value =>
                                      `$${formatMoney(value)}`}
                                    diffStyle={{ textAlign: 'right' }}
                                    render={value => `${formatMoney(value)}`}
                                  />
                                </td>
                                <td
                                  className="text-right"
                                  style={strikeStyle}
                                  width="125"
                                >
                                  <DiffRender
                                    field="TotalPrice"
                                    value={TotalPrice}
                                    placement="left"
                                    actions={__actions}
                                    renderDiff={value =>
                                      `$${formatMoney(value)}`}
                                    diffStyle={{ textAlign: 'right' }}
                                    render={value => `${formatMoney(value)}`}
                                  />
                                  {locked &&
                                    <span>
                                      <br />
                                      <span className="action modify">
                                        Cost Received
                                      </span>
                                    </span>}
                                  {c_Deleted &&
                                    <span>
                                      <br />
                                      <span className="action delete">
                                        Not on PO
                                      </span>
                                    </span>}
                                  {c_UpdateRejected &&
                                    showEditDenied &&
                                    <span>
                                      <br />
                                      <span className="action delete">
                                        Edit Denied
                                      </span>
                                    </span>}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td
                              className="text-right"
                              colSpan="5"
                              style={{ fontWeight: 'bold' }}
                            >
                              Item Total -
                            </td>
                            <td
                              className="text-right"
                              colSpan="1"
                              style={{ ...strikeStyle, fontWeight: 'bold' }}
                            >
                              <DiffRender
                                field="LineItemGroupSum"
                                value={newSum}
                                placement="left"
                                actions={sumActions}
                                renderDiff={value => `$${formatMoney(value)}`}
                                diffStyle={{ textAlign: 'right' }}
                                render={value => `${formatMoney(value)}`}
                              />
                              {/* ${formatMoney(sumTotalPrice(lines))} */}
                            </td>
                          </tr>
                          {getLineSpecialInstructions(
                            initialValues,
                            LineItemId
                          ) &&
                            <tr>
                              <td colSpan="6" className="special-instructions">
                                <h5>
                                  <Icon prefix="far" name="hand-point-right" />
                                  {' '}
                                  SPECIAL INSTRUCTIONS (For Items Above):
                                </h5>
                                <span style={{ whiteSpace: 'pre-wrap' }}>
                                  {getLineSpecialInstructions(
                                    initialValues,
                                    LineItemId
                                  )}
                                </span>
                              </td>
                            </tr>}
                        </tbody>
                      );
                    })}
                  </Table>

                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  Purchase Order Total
                </td>
                <td className="text-right">
                  <DiffRender
                    field="SumSellPrice"
                    value={SumSellPrice}
                    placement="left"
                    actions={actions}
                    renderDiff={value => `$${formatMoney(value)}`}
                    diffStyle={{ textAlign: 'right' }}
                    render={value => `${formatMoney(value)}`}
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
    );
  }
}

export default PurchaseOrderBody;
