import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../forms/FormField';
import { Row, Col, Form, InputToggle } from 'react-bootstrap';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import Icon from '../../ui/Icon';

const required = value => value ? undefined : 'Required';

const VendorForm = props => {
  const {
    handleSubmit,
    submitting,
    change,
    IsColoradoType,
    initialValues
  } = props;

  const {
    Name,
    VendorNo,
    DisplayName,
    PreferredEmail,
    PreferredEmailCC,
    FreightProgram,
    Discount,
    RepGroup,
    AdditionalNotes,
    CPG,
    IsColorado,
    FreightProgramFlatRate,
    FreightProgramPO,
    FreightProgramPieces,
    LBMX
  } = initialValues || {};

  const getColoradoType = value => {
    switch (value) {
      case '1':
        return 'Never Colorado';
      case '2':
        return 'Always Colorado';
      case '3':
        return 'Colorado Optional';
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col sm={6}>
          <label><b>Sage Vendor Name</b></label>
          <div>{Name || '-'}</div>
          <br />
        </Col>
        <Col sm={3} style={{ marginTop: '30px' }}>
          <div>{getColoradoType(IsColoradoType) || '-'}</div>
          <br />
        </Col>
        <Col sm={3} style={{ marginTop: '30px' }}>
          <label><b>Default to Colorado</b></label>
          {IsColorado &&
            <div>
              <Icon name="check" style={{ color: '#04844B' }} />
            </div>}
          {!IsColorado && <div>-</div>}
          <br />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <label><b>Sage Vendor No</b></label>
          <div>{VendorNo || '-'}</div>
          <br />
        </Col>
        <Col sm={6}>
          <label><b>Email</b></label>
          <div>{PreferredEmail || '-'}</div>
          <br />
        </Col>
      </Row>

      <Row>
        <Col sm={6}>
          <label><b>Display Name</b></label>
          <div>{DisplayName || '-'}</div>
          <br />
        </Col>
        <Col sm={6}>
          <label><b>Email CC</b></label>
          <div>{PreferredEmailCC || '-'}</div>
          <br />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <label><b>Discount</b></label>
          <div>{Discount || '-'}</div>
          <br />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <label><b>Rep Group</b></label>
          <div>{RepGroup || '-'}</div>
          <br />
        </Col>
        <Col sm={3} style={{ marginTop: '30px' }}>
          <label><b>CPG</b></label>
          {CPG &&
            <div>
              <Icon name="check" style={{ color: '#04844B' }} />
            </div>}
          {!CPG && <div>-</div>}
          <br />
        </Col>

        <Col sm={3} style={{ marginTop: '30px' }}>
          <label><b>LBMX</b></label>
          {LBMX &&
            <div>
              <Icon name="check" style={{ color: '#04844B' }} />
            </div>}
          {!LBMX && <div>-</div>}
          <br />
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <label><b>Additional Notes</b></label>
          <div>{AdditionalNotes || '-'}</div>
        </Col>
      </Row>

      <Row>
        <Col sm={3}>
          <label><b>Freight Program</b></label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {FreightProgramPieces
              ? <Icon fixedWidth name="check" className="text-success" />
              : <Icon fixedWidth name="times" className="text-danger" />}
            {'Number of pieces '}
            {' '}
            {FreightProgramPieces ? FreightProgramPieces : '-'}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {FreightProgramPO
              ? <Icon fixedWidth name="check" className="text-success" />
              : <Icon fixedWidth name="times" className="text-danger" />}
            {'Total PO'} {FreightProgramPO ? FreightProgramPO : '-'}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {FreightProgramFlatRate
              ? <Icon fixedWidth name="check" className="text-success" />
              : <Icon fixedWidth name="times" className="text-danger" />}
            {`Flat Rate`}
            {' '}
            {FreightProgramFlatRate ? '$' + FreightProgramFlatRate : '-'}
          </div>

          <div style={{ display: 'flex', alignItems: 'center' }}>
            {FreightProgram === 'A'
              ? <Icon fixedWidth name="check" className="text-success" />
              : <Icon fixedWidth name="times" className="text-danger" />}
            {`Always Free`}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

const formName = 'vendor-form';

const selector = formValueSelector(formName);

const mapState = state => {
  const IsColoradoType = selector(state, 'IsColoradoType');
  return {
    IsColoradoType
  };
};

const form = reduxForm({
  form: formName
})(VendorForm);

export default connect(mapState)(form);
