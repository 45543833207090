import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const VendorEditForm = (
  {
    handleSubmit,
    submitting,
    vendorOptions,
    toggleEdit,
    inProgress,
    vendors,
    sageIdValue,
    change
  }
) => {
  const vendor = (vendors || []).find(({ ID }) => ID === sageIdValue);
  const { IsColoradoType } = vendor || {};
  return (
    <form onSubmit={handleSubmit}>
      <Field
        name="c_SelectedPurchaseOrderSageVendorID"
        type="text"
        component={FormField.SelectReact}
        validate={[required]}
        options={vendorOptions}
        isDisabled={submitting}
        afterChange={value => {
          const vendor = (vendors || []).find(({ ID }) => ID === value);
          const { IsColorado } = vendor || {};
          change('c_IsColorado', IsColorado);
        }}
      />
      {IsColoradoType === 3 &&
        <Field
          name="c_IsColorado"
          component={FormField.InputToggle}
          label="Order through Colorado office"
          disabled={IsColoradoType !== 3}
        />}
      <Button type="button" onClick={toggleEdit}>
        Cancel
      </Button>
      <Button
        type="submit"
        variant="success"
        disabled={inProgress || submitting}
      >
        Submit
      </Button>
    </form>
  );
};

const Form = reduxForm({
  form: 'vendor-edit-form'
})(VendorEditForm);

const mapState = (state, props) => {
  const formName = props.form || 'vendor-edit-form';
  const selector = formValueSelector(formName);

  const sageIdValue = selector(state, 'c_SelectedPurchaseOrderSageVendorID');

  return {
    sageIdValue
  };
};

export default connect(mapState)(Form);
