import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';

class ResetUserPasswordModal extends Component {
  render() {
    const {
      openModal,
      closeModal,
      user,
      name,
      inProgress,
      handleSendWelcome
    } = this.props;
    const {
      id,
      nameFirst,
      nameLast
    } = user || {};
    return (
      <Modal show={openModal === name} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Send Welcome Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>
            Are you sure you wish to a send welcome email for user
            {' '}
            <b>{nameFirst} {nameLast}</b>
            ?
          </h5>
          <div className="text-muted">
            User will receive an email with link to create a password.
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            size="sm"
            variant="outline-secondary"
            onClick={closeModal}
            disabled={inProgress}
          >
            Cancel
          </Button>
          <Button
            size="sm"
            variant="primary"
            onClick={handleSendWelcome}
            disabled={inProgress}
          >
            Send Welcome
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
const mapState = state => {
  return {
    openModal: state.modals
  };
};
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );
export default connect(mapState, mapDispatch)(ResetUserPasswordModal);
