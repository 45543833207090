import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import ShipmentsSearch from './shipments-search/ShipmentsSearch';
import ShipmentsDetail from './shipments-detail/ShipmentsDetail';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={ShipmentsSearch} />
    <Route exact path={`${match.url}/new/:poId`} component={ShipmentsDetail} />
    <Route exact path={`${match.url}/:id/:poId`} component={ShipmentsDetail} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
