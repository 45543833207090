import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Container, Dropdown, Row, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import { actionCreators } from '../../../../state/modals-dux';

import QuoteUser from './QuoteUser';
import QuoteStatus from '../../../ui/QuoteStatus';
import ProposalSummary from './ProposalSummary';
import QuoteItems from './QuoteItems';
import ModalShareQuote from './ModalShareQuote';
import ModalQuoteLogs from './ModalQuoteLogs';
import ModalRevokeAccess from './ModalRevokeAccess';
import ModalAcceptQuote from './ModalAcceptQuote';
import ModalDeleteQuote from './ModalDeleteQuote';
import Loading from '../../../ui/Loading';
import ModalEditStatus from './ModalEditStatus';
import Notification from '../../../ui/Notification';

const formatTimeDiff = d =>
  !d || !moment(d).isValid() ? '' : moment(d).fromNow();
const formatDate = d => d ? moment(d).format('ddd, MMM Do') : '';

const DisplayControl = ({ label, value }) => (
  <div className="display-control">
    <Icon name={value ? 'check-square text-success' : 'square'} /> {label}
  </div>
);

class QuotesDetails extends Component {

  constructor(props) {
    super(props);
    this.state = {
      expandAll: false
    }
  }
  componentDidMount() {
    const {
      match,
      getQuoteById,
      getLocations
    } = this.props;
    const { id } = match.params || {};
    getQuoteById(id);
    getLocations();
  }

  handleQuoteShareSubmit = (values) => {
    const {
      shareQuote,
      result,
      closeModal,
      getQuoteById
    } = this.props;
    const { Id } = result || {};
    const { note, selectedUsers } = values;
    const payload = {
      customerNote: note,
      selectedUsers
    }
    closeModal('modalShareQuote')
    shareQuote({ id: Id, payload }, null, null, () => {
      getQuoteById(Id);
    })
  }

  handleQuoteRevokeSubmit = () => {
    const {
      revokeQuote,
      result,
      closeModal,
      getQuoteById
    } = this.props;
    const { Id } = result || {};

    closeModal('modalRevokeAccess');
    revokeQuote({ id: Id }, null, null, () => {
      getQuoteById(Id);
    })
  }

  handleQuoteAccept = (values) => {
    const {
      acceptQuote,
      result,
      closeModal,
      getQuoteById
    } = this.props;
    const { Id } = result || {};
    closeModal('modalAcceptQuote');
    acceptQuote({ id: Id, payload: { ...values } }, null, null, () => {
      getQuoteById(Id);
    });
  }

  render() {
    const {
      inProgress,
      error,
      result,
      showModal,
      closeModal,
      deleteQuote,
      history,
      match,
      locations,
      downloadQuoteById,
      editQuoteStatusError
    } = this.props;
    const { id } = match.params || {};
    const data = result || {};
    const { quoteLogs, users, Status, PDFDownloaded } = data || {};
    const { expandAll } = this.state

    const handleDelete = () => {
      closeModal('modalDeleteQuote');
      deleteQuote(id, null, null, () => {
        history.push('/quotes');
      });
    }
    const { LocationID, Purchaser, QuoteNumber, ExpirationDate, PlanDate, Deposit, SalesTax, SubTotal, taxableSubTotal, TaxToBeDetermined } = data;
    const location = (locations || []).find(({ locationID }) => locationID === LocationID)
    const enabledPDF = true;

    const validQuoteDetails = (Purchaser && QuoteNumber && ExpirationDate && PlanDate && LocationID && Deposit >= 0 && SalesTax >= 0 ? true : false);
    const validUsers = users && users.length > 0 ? true : false;
    const hasExpired = Status === 5 || moment(ExpirationDate).isBefore(moment());

    const tax = TaxToBeDetermined ? 0 : taxableSubTotal * (SalesTax / 100);
    const grandTotal = SubTotal + tax;
    const depositInvoice = grandTotal * (Deposit / 100);

    return (
      <Layout route="quotes-search" alt>

        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/quotes" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {data.Name || ''}
              </h2>
              <h3>
                Uploaded {formatTimeDiff(data.CreateDate)}
              </h3>
            </div>
            <Dropdown>
              <Dropdown.Toggle variant="outline-secondary" size="sm">
                <Icon fixedWidth name="ellipsis-h" />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {(validQuoteDetails && validUsers) &&
                  <a
                    className="dropdown-item"
                    style={hasExpired ? { color: 'lightgray' } : {}}
                    onClick={() => hasExpired ? '' : showModal('modalShareQuote')}
                  >
                    Share quote
                  </a>
                }
                <Link to={`/quotes/${id}/edit`} className="dropdown-item">
                  Edit quote
                </Link>
                {validQuoteDetails &&
                  <a
                    href
                    className="dropdown-item"
                    onClick={() => showModal('modalRevokeAccess')}
                  >
                    Revoke access
                  </a>
                }
                {validQuoteDetails &&
                  <a
                    href
                    className="dropdown-item"
                    style={hasExpired ? { color: 'lightgray' } : {}}
                    onClick={() => hasExpired ? '' : showModal('modalAcceptQuote')}
                    disabled={hasExpired}
                  >
                    Accept Quote
                  </a>
                }
                {validQuoteDetails &&
                  <a
                    href
                    className="dropdown-item"
                    onClick={
                      () => {
                        showModal('ModalEditStatus');
                      }
                    }
                  >
                    Change status
                  </a>
                }
                <a
                  href
                  className="dropdown-item"
                  onClick={() => showModal('modalQuoteLogs')}
                >
                  View logs
                </a>
                <a
                  href
                  className="dropdown-item"
                  style={{
                    pointerEvents: enabledPDF ? 'auto' : 'none',
                    opacity: enabledPDF ? '1' : '0.5'
                  }}
                  onClick={
                    () => downloadQuoteById({ id })
                  }
                >
                  Preview PDF
                </a>
                <Dropdown.Divider />
                <a
                  href
                  className="dropdown-item"
                  onClick={() => showModal('modalDeleteQuote')}
                >
                  Delete
                </a>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </div>

        <Container>
          {inProgress ?
            (
              <Loading />
            )
            :
            (
              <>
                <Row>
                  <Col sm={6}>

                    <div className="box-bordered">
                      <h4>Quote Details</h4>
                      <Row>
                        <Col>
                          <h5>Purchaser</h5>
                          <p>{data.Purchaser}</p>
                        </Col>
                        <Col>
                          <h5>Proposal Number</h5>
                          <p>{data.QuoteNumber}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h5>Proposal Expires</h5>
                          <p>
                            <Icon name="calendar" />
                            {' '}
                            {formatDate(data.ExpirationDate)}
                          </p>
                        </Col>
                        <Col>
                          <h5>Plans Dated</h5>
                          <p>
                            <Icon name="calendar" />
                            {' '}
                            {formatDate(data.PlanDate)}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <h5>Location</h5>
                          <p>
                            <Icon name="map-marker-alt" />
                            {' '}
                            {location ? location.locationName : ' - '}
                          </p>
                        </Col>
                      </Row>
                    </div>

                  </Col>
                  <Col sm={6}>

                    <div className="box-bordered">
                      <h4>Quote Status</h4>
                      <QuoteStatus status={data.Status} quote={result} />
                    </div>

                    <QuoteUser
                      customer={data.customer}
                      quoteStatus={data.Status}
                      quoteUsers={users}
                    />

                  </Col>

                  <Col sm={12}>

                    <div className="box-bordered">
                      <h4>Customer Only Display Controls</h4>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <DisplayControl
                          label="Show Comments"
                          value={data.ShowComments}
                        />
                        <DisplayControl
                          label="Show Optional/Alternative"
                          value={data.ShowAlternate}
                        />
                        <DisplayControl
                          label="Show Spare Number"
                          value={data.ShowSpareNumber}
                        />
                        <DisplayControl
                          label="Tax to be determined"
                          value={data.TaxToBeDetermined}
                        />
                      </div>
                    </div>

                  </Col>
                  <Col sm={12}>

                    <div className="box-bordered">
                      <h4>Proposal Summary</h4>
                      <ProposalSummary data={data} />
                    </div>

                    <div className="box-bordered">
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '1rem', height: '30px' }}>
                        <h4>Quote Items</h4>
                        <Button
                          size='sm'
                          variant="primary"
                          type='button'
                          onClick={() => this.setState({ expandAll: !expandAll })}
                        >
                          {
                            expandAll && 'Collapse All'
                          }
                          {
                            !expandAll && 'Expand All'
                          }
                        </Button>
                      </div>
                      <QuoteItems data={data} expandAll={expandAll} />
                    </div>

                    <div className="box-bordered">
                      <h4>Customer Note</h4>
                      <div>
                        {data.CustomerNote}
                      </div>
                    </div>

                  </Col>
                </Row>
              </>
            )
          }
        </Container>

        <ModalShareQuote name="modalShareQuote"
          data={data}
          handleSubmit={this.handleQuoteShareSubmit}
        />
        <ModalQuoteLogs
          logs={quoteLogs}
          PDFDownloaded={PDFDownloaded}
          name="modalQuoteLogs"
        />
        <ModalRevokeAccess
          name="modalRevokeAccess"
          PDFDownloaded={PDFDownloaded}
          handleRevokeClick={this.handleQuoteRevokeSubmit}
        />
        <ModalAcceptQuote
          name="modalAcceptQuote"
          depositPercentage={data ? data.Deposit : 0}
          depositInvoice={depositInvoice}
          handleSubmit={this.handleQuoteAccept}
        />
        <ModalDeleteQuote name="modalDeleteQuote" handleDelete={handleDelete} />

        <ModalEditStatus
          name='ModalEditStatus'
          data={data}
        />

        {
          editQuoteStatusError
          &&
          <Notification
            key="edit-quote-status-error"
            duration={5}
            closable={true}
            type="danger">
            Error updating quote status
          </Notification>
        }

      </Layout>
    );
  }
}

const mapState = state => {

  const {
    inProgress: quoteLoading,
    result
  } = state.getQuoteById.toJS();

  const {
    inProgress: acceptQuoteInProgress
  } = state.acceptQuote.toJS();

  const {
    inProgress: revokeQuoteInProgress
  } = state.revokeQuote.toJS();

  const {
    inProgress: shareQuoteInProgress
  } = state.shareQuote.toJS();

  const {
    inProgress: deleteQuoteInProgress
  } = state.deleteQuote.toJS();

  const {
    result: locations
  } = state.searchCustomersLocations.toJS();

  const {
    error: editQuoteStatusError
  } = state.editQuoteStatus.toJS();

  const inProgress = quoteLoading ||
    acceptQuoteInProgress ||
    revokeQuoteInProgress ||
    shareQuoteInProgress ||
    deleteQuoteInProgress

  return {
    inProgress,
    result,
    locations,
    editQuoteStatusError
  }
}

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getQuoteById: fetchDux.getQuoteById.createAction,
      deleteQuote: fetchDux.deleteQuote.createAction,
      shareQuote: fetchDux.shareQuote.createAction,
      revokeQuote: fetchDux.revokeQuote.createAction,
      acceptQuote: fetchDux.acceptQuote.createAction,
      getLocations: fetchDux.searchCustomersLocations.createAction,
      downloadQuoteById: fetchDux.downloadQuoteById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(QuotesDetails);
