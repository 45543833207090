import React, { Component } from 'react';
import { Row, Col, Table, Button } from 'react-bootstrap';

import PurchaseOrderCardFilter from './PurchaseOrderCardFilter';
import PurchaseOrderDetail from '../purchase-order-detail/PurchaseOrderDetail';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import fetchDux from '../../../../state/fetch-dux';
import ModalUpdateEmailRecipients from './ModalUpdateEmailRecipients';
import { actionCreators } from '../../../../state/modals-dux';

const sortPOs = (sort, desc) =>
  (a, b) => {
    const x = (a[sort] || '').toUpperCase();
    const y = (b[sort] || '').toUpperCase();
    if (desc) return y < x ? -1 : y > x ? 1 : 0;
    return x < y ? -1 : x > y ? 1 : 0;
  };

const filterPOs = poFilter =>
  ({ c_POSent }) =>
    poFilter === 2 ? true : poFilter === 1 ? c_POSent : !c_POSent;

class SendPurchaseOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePO: null,
      poFilter: 1,
      poFilter: 0,
      poSort: 'VendorName',
      poDesc: false,
      overwriteEmails: null
    };
    this.getNextPO = this.getNextPO.bind(this);
  }
  componentDidMount() {
    this.getNextPO(this.props);
  }
  componentWillReceiveProps(nextProps) {
    this.getNextPO(nextProps);
  }
  getNextPO(props) {
    const { result } = props || {};
    const { purchaseOrders } = result || {};
    const { activePO, poFilter, poSort, poDesc } = this.state;
    const filteredPOs = (purchaseOrders || [])
      .filter(filterPOs(poFilter))
      .sort(sortPOs(poSort, poDesc));

    const sortedPOs = (purchaseOrders || []).sort(sortPOs(poSort, poDesc));

    if (!filteredPOs || !filteredPOs.length) return;

    if (!activePO) {
      const next = (filteredPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }

    const isActive = filteredPOs.some(({ ID }) => ID === activePO);

    if (isActive) return;

    const lastIndex = sortedPOs.findIndex(({ ID }) => ID === activePO);

    if (lastIndex + 1 === sortedPOs.length) {
      const next = (sortedPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }

    const sliced = sortedPOs
      .slice(lastIndex + 1)
      .filter(({ c_POSent }) => !c_POSent);
    if (!sliced || !sliced.length) {
      const next = (filteredPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }
    const next = (sliced[0] || {}).ID;
    return this.setState({ activePO: next });
  }
  render() {
    const { result, postSendPurchaseOrder, lookups, review, openModal, showModal, closeModal } = this.props;
    const {
      purchaseOrders,
      lineItems,
      addresses,
      lineItemSpecialInstructions
    } = result || {};
    const { activePO, poFilter, poSort, poDesc } = this.state;
    const setActive = ID => this.setState({ activePO: ID });
    const po = (purchaseOrders || []).find(({ ID }) => ID === activePO);
    const poItems = (lineItems || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (po || {}).PurchaseOrderId
      );
    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(({ PurchaseOrderId }) => PurchaseOrderId === (po || {}).ID);

    const handleSendPurchaseOrder = (payload, callback) =>
      postSendPurchaseOrder(
        { id: activePO, payload },
        'send-purchase-order-form',
        null,
        result => {
          if (callback) {
            callback(result);
          } else {
            setTimeout(() => window.scrollTo(0, 0), 200);
          }
        }
      );

    const setPOSort = (sort, desc) =>
      this.setState({ poSort: sort, poDesc: desc });

    const setPOFilter = values => this.setState(values);

    const isApproved = ({ c_POSent }) => c_POSent;

    return (
      <>
        <Row style={{ justifyContent: 'flex-end' }}>
          <Col md="8">
            <Button
              type='button'
              size="sm"
              style={{ float: 'right', marginBottom: '1rem' }}
              onClick={() => showModal('ModalUpdateEmailRecipients')}
            >
              Add Additional Recipient(s) to all POs
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <PurchaseOrderCardFilter
              setPOFilter={setPOFilter}
              review={review}
              // poFilter={poFilter}
              setPOSort={setPOSort}
              poSort={poSort}
              poDesc={poDesc}
              purchaseOrders={purchaseOrders.filter(filterPOs(poFilter))}
              setActive={setActive}
              activePO={activePO}
              isApproved={isApproved}
              options={[
                { value: 0, label: 'Unsent Purchase Orders' },
                { value: 1, label: 'Sent Purchase Orders' },
                { value: 2, label: 'All' }
              ]}
            />
          </Col>
          <Col md="8">
            {po &&
              <PurchaseOrderDetail
                addresses={addresses}
                purchaseOrder={po}
                lineItems={poItems}
                lookups={lookups}
                lineItemSpecialInstructions={poItemsInstructions}
                sendPo
                handleSendPurchaseOrder={handleSendPurchaseOrder}
                pdfDetails={(po || {}).pdfDetails}
                overwriteEmails={this.state.overwriteEmails}
              />}
          </Col>
        </Row>
        <ModalUpdateEmailRecipients
          name='ModalUpdateEmailRecipients'
          openModal={openModal}
          closeModal={closeModal}
          handleSubmit={
            (values) => {
              const { ccEmailRecipients } = values;
              this.setState({ overwriteEmails: ccEmailRecipients })
              closeModal('ModalUpdateEmailRecipients')
            }
          }
        />
      </>

    );
  }
}

const mapState = state => {
  return {
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postSendPurchaseOrder: fetchDux.postSendPurchaseOrder.createAction,
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(SendPurchaseOrders);
