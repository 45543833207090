import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown } from 'react-bootstrap';
import numeral from 'numeral';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Icon from '../../../ui/Icon';
import Table from '../../../ui/Table';
import Loading from '../../../ui/Loading';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';

import ModalCustomersEditCompany from '../customers-companies/ModalEditCompany';
import ModalCustomersDeleteCompany
  from '../customers-companies/ModalDeleteCompany';
import ModalCustomersDeletePackage
  from '../customers-companies/ModalDeletePackage';
import ModalCustomersCompanyCreatePackage
  from './ModalCustomersCompanyCreatePackage';
import ModalCustomersCompanyImportPackage
  from './ModalCustomersCompanyImportPackage';
import ModalCustomersCompanyViewPackage
  from './ModalCustomersCompanyViewPackage';
import Notification from '../../../ui/Notification';

const getBoolValue = value => {
  if (value === false)
    return <Icon fixedWidth name="times" className="text-danger" />;
  if (value === true)
    return <Icon fixedWidth name="check" className="text-success" />;

  return 'N/A';
};

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '20',
  sortBy: sortBy || 'category',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};
const getColumns = (
  { showModal, getPackageById, setEditState, setDeletePackageState }
) => {
  return [
    {
      Header: 'Package Name',
      accessor: 'name'
    },
    {
      Header: 'Number Of Products',
      accessor: 'numProducts',
      style: { textAlign: 'center' }
    },
    {
      Header: 'Feature',
      accessor: 'featured',
      style: { textAlign: 'center' },
      Cell: ({ value }) => getBoolValue(value)
    },
    {
      Header: 'Hide Price',
      accessor: 'hidePrice',
      style: { textAlign: 'center' },
      Cell: ({ value }) => getBoolValue(value)
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Actions',
      accessor: 'id',
      style: { textAlign: 'center' },
      Cell: ({ value }) => {
        return (
          <div>
            <Button
              size="sm"
              variant="primary"
              style={{ width: '30px', height: '30px' }}
              onClick={() => {
                showModal('modalCustomersCompanyViewPackage');
                getPackageById(value);
              }}
            >
              <Icon name="info" />
            </Button>
            <Button
              size="sm"
              variant="primary"
              style={{ marginLeft: '10px', width: '30px', height: '30px' }}
              onClick={() => {
                getPackageById(value, null, false, data => {
                  //set state for initial values on edit form
                  setEditState(data);
                  //open modal
                  showModal('modalCustomersCompanyEditPackage');
                });
              }}
            >
              <Icon name="edit" />
            </Button>
            <Button
              size="sm"
              variant="danger"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setDeletePackageState(value);
                showModal('modalCustomersPackageDelete');
              }}
            >
              <Icon name="trash" />
            </Button>
          </div>
        );
      }
    }
  ];
};

class CustomersCompaniesProductCollections extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedProducts: [],
      nameForm: '',
      featured: '',
      hidePrice: false,
      description: '',
      deletePackageId: null
    };
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    const {
      match,
      getCompanyById,
      getCompanyLookups
    } = this.props;
    const { id } = match.params || {};
    getCompanyById(
      { id, query: { getUsers: 1, getPackages: 1, getProducts: 1 } },
      null,
      true
    );
    getCompanyLookups({ companyTypes: 1 });
  }
  componentWillUnmount() {
    const {
      clearPostPackage,
      clearGetCompanyById
    } = this.props;
    clearPostPackage();
    clearGetCompanyById();
  }
  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }

  getFilteredPackages = () => {
    const { packages, products } = this.props.result || {};
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const { search: packageSearch } = query;
    const filteredProducts = (products || []).filter((product) => {
      const { model, vendorName } = product;
      if ((model || '').search(packageSearch) >= 0 ||
        (vendorName || '').search(packageSearch) >= 0) return true;
      return false;
    })

    const filteredPackages = (packages || []).filter((pck) => {
      const { products } = pck;
      const product = filteredProducts.find(({ id }) => products.find(({ ProductId }) => ProductId === id));
      if (product) return true;
      return false;
    })
    return filteredPackages;
  }
  render() {
    const {
      inProgress,
      error,
      result,
      showModal,
      match,
      clearDeleteCompanyById,
      deleteCompanyByIdError,
      deleteCompanyByIdInProgress,
      putCompanyByIdInProgress,
      getCustomersCompaniesByIdError,
      getConceptsLookupsError,
      clearGetCompanyById,
      clearGetCompanyLookups,
      getCompanyById,
      putCompanyById,
      deleteCompanyById,
      getConceptsLookupsResult,
      putCompanyByIdError,
      clearPutCompanyById,
      history,
      location: { search: searchURL },
      selectedProductsForm,
      postPackage,
      postPackageResult,
      postPackageInProgress,
      postPackageError,
      clearPostPackage,
      nameForm,
      description,
      featured,
      hidePrice,
      getPackageById,
      putPackage,
      putPackageResult,
      putPackageInProgress,
      putPackageError,
      clearPutPackage,
      getPackageByIdResult,
      deletePackageById,
      clearDeletePackageById,
      deletePackageByIdResult,
      deletePackageByIdInProgress,
      deletePackageByIdError
    } = this.props;

    const {
      selectedProducts,
      nameForm: stateNameForm,
      featured: stateFeatured,
      hidePrice: stateHidePrice,
      description: stateDescription,
      deletePackageId
    } = this.state;

    const {
      id: packageId
    } = getPackageByIdResult || {};

    const { id: companyId } = match.params || {};

    const { products, company } = result || {};

    const packages = this.getFilteredPackages();

    const {
      name,
      companyTypeID,
      notes,
      created,
      id,
      jobRefNum,
      supportEmail,
      freightMarkup
    } = company || {};

    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const companyYears = moment().diff(moment(created), 'years');

    const initialValues = {
      name,
      companyTypeID,
      notes,
      jobRefNum,
      supportEmail,
      freightMarkup,
      id
    };

    const {
      companyTypes
    } = getConceptsLookupsResult || {};

    const handleSubmitUpdateCompany = values => {
      putCompanyById(
        { id, payload: values },
        'create-customers-company-form',
        null,
        () => {
          getCompanyById(
            { id, query: { getUsers: 1, getPackages: 1, getProducts: 1 } },
            null,
            true
          );
        }
      );
    };

    const handleDeleteCompany = () => {
      deleteCompanyById(id, null, null, () => {
        history.push(`/customers/concepts`);
      });
    };

    const handleAddRemoveProduct = (selectedProductId, add, model) => {
      if (add) {
        this.setState({
          selectedProducts: selectedProducts.concat({
            productId: selectedProductId,
            qty: 1,
            model
          })
        });
      } else {
        this.setState({
          selectedProducts: selectedProducts.filter(
            ({ productId }) => productId !== selectedProductId
          )
        });
      }
    };

    const handleChangeQty = (productId, qty) => {
      const results = (selectedProducts || []).map(({ productId }, index) => {
        return { productId, index };
      });
      const { index } = (results || [])
        .find(({ productId: itemProductId }) => itemProductId === productId);
      (selectedProducts[index] || {}).qty = qty;
      this.setState({ selectedProducts: selectedProducts });
    };

    const handleSubmitPackage = values => {
      values.selectedProducts = selectedProducts;
      postPackage(
        {
          payload: values,
          id
        },
        'create-package-form',
        null,
        () => {
          getCompanyById(
            { id, query: { getUsers: 1, getPackages: 1, getProducts: 1 } },
            null,
            true
          );
          this.setState({ selectedProducts: [] });
        }
      );
    };

    const setDeletePackageState = deletePackageId => {
      this.setState({ deletePackageId });
    };

    const handleDeletePackage = () => {
      if (!deletePackageId) return;
      deletePackageById(deletePackageId, null, null, () => {
        getCompanyById(
          { id, query: { getUsers: 1, getPackages: 1, getProducts: 1 } },
          null,
          true
        );
      });
    };

    const setEditState = data => {
      const {
        name,
        featured,
        hidePrice,
        description,
        products
      } = data || {};
      const formProducts = (products || []).map(({
        id,
        qty,
        model
      }) => {
        return { productId: id, qty, model };
      });
      this.setState({
        selectedProducts: formProducts,
        nameForm: name,
        featured,
        hidePrice,
        description
      });
    };

    const columns = getColumns({
      showModal,
      getPackageById,
      setEditState,
      setDeletePackageState
    });

    const handleClosePackageModal = closeModal => {
      this.setState({
        selectedProducts: [],
        nameForm: '',
        featured: '',
        hidePrice: false,
        description: ''
      });
      closeModal();
    };

    const handleSubmitEditPackage = values => {
      values.selectedProducts = selectedProducts;
      putPackage(
        {
          payload: values,
          id: packageId
        },
        'create-package-form',
        null,
        () => {
          getCompanyById(
            { id, query: { getUsers: 1, getPackages: 1, getProducts: 1 } },
            null,
            true
          );
          this.setState({ selectedProducts: [] });
        }
      );
    };

    return (
      <Layout route="customers-companies-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/concepts" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h2>
                {name || 'Loading...'}
              </h2>
              <h3>
                {!inProgress &&
                  <span>
                    Concept for
                    {' '}
                    {companyYears === undefined ? '-' : companyYears}
                    {' '}
                    years
                  </span>}
                &nbsp;
              </h3>
            </div>
            <div>
              {/* <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalCustomersCompanyImportPackage')}
              >
                Import Package
              </Button>
              {' '} */}
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalCustomersCompanyCreatePackage')}
              >
                Create Package
              </Button>
              {' '}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  <Icon fixedWidth name="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyEdit')}
                  >
                    Edit Concept
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersCompanyDelete')}
                  >
                    Delete Concept
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/concepts/${companyId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/users`}>Users</Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/products`}>
                  Products
                </Link>
              </li>
              <li className="active">
                <Link to={`/customers/concepts/${companyId}/packages`}>
                  Packages
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/locations`}>
                  Locations
                </Link>
              </li>
              <li>
                <Link to={`/customers/concepts/${companyId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {!inProgress &&
          <Container>
            <Table
              showSearch
              data={packages}
              noDataText="No packages found"
              columns={columns}
              search={search}
              onFetchData={this.handleTableChange}
              onSearchChange={this.handleSearchChange}
              defaultSorted={sorted}
              page={getCustomersCompaniesByIdError ? 0 : Number(page)}
              defaultPageSize={Number(pageSize)}
              inProgress={inProgress}
              filterDataFromSearch={false}
            />
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCustomersEditCompany
          name="modalCustomersCompanyEdit"
          initialValues={initialValues}
          companyTypes={companyTypes}
          handleSubmitUpdateCompany={handleSubmitUpdateCompany}
          putCompanyByIdError={putCompanyByIdError}
          clearPutCompanyById={clearPutCompanyById}
          submitting={putCompanyByIdInProgress}
        />

        <ModalCustomersDeleteCompany
          name="modalCustomersCompanyDelete"
          handleDeleteCompany={handleDeleteCompany}
          deleteCompanyByIdError={deleteCompanyByIdError}
          clearDeleteCompanyById={clearDeleteCompanyById}
          submitting={deleteCompanyByIdInProgress}
        />

        <ModalCustomersDeletePackage
          name="modalCustomersPackageDelete"
          handleDeletePackage={handleDeletePackage}
          deletePackageByIdError={deletePackageByIdError}
          clearDeletePackageById={clearDeletePackageById}
          submitting={deletePackageByIdInProgress}
        />

        <ModalCustomersCompanyCreatePackage
          name="modalCustomersCompanyCreatePackage"
          selectedProducts={selectedProducts}
          products={products}
          handleAddRemoveProduct={handleAddRemoveProduct}
          handleSubmitForm={handleSubmitPackage}
          handleCloseModal={handleClosePackageModal}
          nameForm={nameForm}
          description={description}
          hidePrice={hidePrice}
          featured={featured}
          submitting={postPackageInProgress}
          handleChangeQty={handleChangeQty}
        />

        {/* edit version */}
        <ModalCustomersCompanyCreatePackage
          name="modalCustomersCompanyEditPackage"
          selectedProducts={selectedProducts}
          products={products}
          handleAddRemoveProduct={handleAddRemoveProduct}
          handleSubmitForm={handleSubmitEditPackage}
          handleCloseModal={handleClosePackageModal}
          nameForm={stateNameForm}
          description={stateDescription}
          featured={stateFeatured}
          hidePrice={stateHidePrice}
          isEdit
          submitting={putPackageInProgress}
          handleChangeQty={handleChangeQty}
        />

        <ModalCustomersCompanyImportPackage
          name="modalCustomersCompanyImportPackage"
        />
        <ModalCustomersCompanyViewPackage
          name="modalCustomersCompanyViewPackage"
        />

        {getCustomersCompaniesByIdError &&
          <Notification
            key="products-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyById}
          >
            Products failed to load. Please refresh the page.
          </Notification>}
        {getConceptsLookupsError &&
          <Notification
            key="form-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetCompanyLookups}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
        {postPackageError &&
          <Notification
            key="form-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostPackage}
          >
            {(postPackageError || {}).message
              ? (postPackageError || {}).message
              : 'Failed to update Package. Please try again.'}
          </Notification>}
        {postPackageResult &&
          <Notification
            key="create-package-success"
            duration={5}
            closable={true}
            type="success"
            onClose={clearPostPackage}
          >
            Success! Package created.
          </Notification>}
        {putPackageError &&
          <Notification
            key="edit-package-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPutPackage}
          >
            {(putPackageError || {}).message
              ? (putPackageError || {}).message
              : 'Failed to update Package. Please try again.'}
          </Notification>}
        {putPackageResult &&
          <Notification
            key="edit-package-success"
            duration={5}
            closable={true}
            type="success"
            onClose={clearPutPackage}
          >
            Success! Package updated.
          </Notification>}
        {deletePackageByIdResult &&
          <Notification
            key="delete-package-success"
            duration={5}
            closable={true}
            type="success"
            onClose={clearDeleteCompanyById}
          >
            Success! Package deleted.
          </Notification>}

      </Layout>
    );
  }
}

const selector = formValueSelector('create-package-form');

const mapState = state => {
  const selectedProductsForm = selector(state, 'selectedProducts');
  const nameForm = selector(state, 'name');
  const description = selector(state, 'description');
  const featured = selector(state, 'featured');
  const hidePrice = selector(state, 'hidePrice');

  const {
    result,
    inProgress: getCustomersCompaniesByIdInProgress,
    error: getCustomersCompaniesByIdError
  } = state.getCompanyById.toJS();

  const {
    result: getConceptsLookupsResult,
    inProgress: getConceptsLookupsInProgress,
    error: getConceptsLookupsError
  } = state.getConceptsLookups.toJS();

  const {
    result: deleteCompanyByIdResult,
    inProgress: deleteCompanyByIdInProgress,
    error: deleteCompanyByIdError
  } = state.deleteCompanyById.toJS();

  const {
    result: deletePackageByIdResult,
    inProgress: deletePackageByIdInProgress,
    error: deletePackageByIdError
  } = state.deletePackageById.toJS();

  const {
    result: postPackageResult,
    inProgress: postPackageInProgress,
    error: postPackageError
  } = state.postPackage.toJS();

  const {
    result: getPackageByIdResult,
    inProgress: getPackageByIdInProgress,
    error: getPackageByIdError
  } = state.getPackageById.toJS();

  const {
    result: putPackageResult,
    inProgress: putPackageInProgress,
    error: putPackageError
  } = state.putPackage.toJS();

  return {
    result,
    inProgress: getCustomersCompaniesByIdInProgress,
    getCustomersCompaniesByIdError,
    getConceptsLookupsError,
    getConceptsLookupsResult,
    deleteCompanyByIdInProgress,
    deleteCompanyByIdError,
    selectedProductsForm,
    postPackageResult,
    postPackageInProgress,
    postPackageError,
    nameForm,
    description,
    featured,
    hidePrice,
    putPackageResult,
    putPackageInProgress,
    putPackageError,
    getPackageByIdResult,
    deletePackageByIdResult,
    deletePackageByIdInProgress,
    deletePackageByIdError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction,
      getCompanyLookups: fetchDux.getConceptsLookups.createAction,
      clearGetCompanyLookups: fetchDux.getConceptsLookups.clearAction,
      putCompanyById: fetchDux.putCompanyById.createAction,
      clearPutCompanyById: fetchDux.putCompanyById.clearAction,
      deleteCompanyById: fetchDux.deleteCompanyById.createAction,
      clearDeleteCompanyById: fetchDux.deleteCompanyById.clearAction,
      postPackage: fetchDux.postPackage.createAction,
      clearPostPackage: fetchDux.postPackage.clearAction,
      putPackage: fetchDux.putPackage.createAction,
      clearPutPackage: fetchDux.putPackage.clearAction,
      getPackageById: fetchDux.getPackageById.createAction,
      deletePackageById: fetchDux.deletePackageById.createAction,
      clearDeleteCompanyById: fetchDux.deleteCompanyById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(
  CustomersCompaniesProductCollections
);
