import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Button, Row, Col, Container } from 'react-bootstrap';

import Layout from '../../ui/Layout';
import Box from '../../ui/Box';
import VendorsViewer from './VendorsViewer';

export default ({ match, location, history }) => {
  const { pathname } = location || {};
  const navigate = r => () => history.push(r);

  return (
    <Layout route="vendors" alt>

      <div className="layout-app-header">
        <Container>
          <div>
            <h2>
              Vendor Details
            </h2>
          </div>
        </Container>
      </div>

      <Container>
        <Box className="box-table">
          <VendorsViewer />
        </Box>
      </Container>

    </Layout>
  );
};
