import React, { Component } from 'react';
import { Row, Col, Form, Dropdown } from 'react-bootstrap';
import PurchaseOrderDetail from '../purchase-order-detail/PurchaseOrderDetail';
import PurchaseOrderCard from './PurchaseOrderCard';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Icon from '../../../ui/Icon';
import fetchDux from '../../../../state/fetch-dux';
import PurchaseOrderCardFilter from './PurchaseOrderCardFilter';

const sortPOs = (sort, desc) =>
  (a, b) => {
    const x = (a[sort] || '').toUpperCase();
    const y = (b[sort] || '').toUpperCase();
    if (desc) return y < x ? -1 : y > x ? 1 : 0;
    return x < y ? -1 : x > y ? 1 : 0;
  };

const filterPOs = poFilter =>
  ({ c_Approved }) =>
    poFilter === 2 ? true : poFilter === 1 ? c_Approved : !c_Approved;

class ApprovePurchaseOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePO: null,
      editing: false,
      poFilter: 0,
      poSort: 'VendorName',
      poDesc: false
    };
    this.getNextPO = this.getNextPO.bind(this);
  }
  componentDidMount() {
    this.getNextPO(this.props);
    this.props.clearPostPurchaseOrderAddress();
    this.props.clearPostApprovePurchaseOrder();
  }
  componentWillUnmount() {
    this.props.clearPostPurchaseOrderAddress();
    this.props.clearPostApprovePurchaseOrder();
  }
  componentDidUpdate() {
    this.getNextPO(this.props);
  }
  getNextPO(props) {
    const { result } = props || {};
    const { purchaseOrders } = result || {};
    const { activePO, poFilter, poSort, poDesc } = this.state;
    const filteredPOs = (purchaseOrders || [])
      .filter(filterPOs(poFilter))
      .sort(sortPOs(poSort, poDesc));

    const sortedPOs = (purchaseOrders || []).sort(sortPOs(poSort, poDesc));

    if (!filteredPOs || !filteredPOs.length) return;

    if (!activePO) {
      const next = (filteredPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }

    const isActive = filteredPOs.some(({ ID }) => ID === activePO);

    if (isActive) return;

    const lastIndex = sortedPOs.findIndex(({ ID }) => ID === activePO);

    if (lastIndex + 1 === sortedPOs.length) {
      const next = (sortedPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }

    const sliced = sortedPOs
      .slice(lastIndex + 1)
      .filter(({ c_Approved }) => !c_Approved);
    if (!sliced || !sliced.length) {
      const next = (filteredPOs[0] || {}).ID;
      return this.setState({ activePO: next });
    }
    const next = (sliced[0] || {}).ID;
    return this.setState({ activePO: next });
  }
  render() {
    const {
      result,
      inProgress,
      postApprovePurchaseOrder,
      postPurchaseOrderAddress,
      postVendorJoin,
      postPOVendor,
      lookups,
      review
    } = this.props;
    const { activePO, editing, poFilter, poSort, poDesc } = this.state;
    const {
      purchaseOrders,
      lineItems,
      addresses,
      vendors: projectVendors,
      lineItemSpecialInstructions
    } = result || {};
    const setActive = ID => this.setState({ activePO: ID, editing: false });
    const po = (purchaseOrders || []).find(({ ID }) => ID === activePO);
    const poItems = (lineItems || [])
      .filter(
        ({ PurchaseOrderId }) => PurchaseOrderId === (po || {}).PurchaseOrderId
      );
    const poItemsInstructions = (lineItemSpecialInstructions || [])
      .filter(({ PurchaseOrderId }) => PurchaseOrderId === (po || {}).ID);

    const handleApprovePurchaseOrder = props => postApprovePurchaseOrder(props);
    const handePostVendorJoin = (...props) => postVendorJoin(...props);
    const handlePostPOVendor = (...props) => postPOVendor(...props);
    const handlePostPurchaseOrderAddress = values =>
      postPurchaseOrderAddress(values, 'edit-shipping-form', null, () => {
        this.setState({ editing: false });
        window.scrollTo(0, 0);
      });
    const toggleEditing = value => {
      this.setState({ editing: value });
      if (!value) window.scrollTo(0, 0);
    };

    const setPOSort = (sort, desc) =>
      this.setState({ poSort: sort, poDesc: desc });
    const setPOFilter = values => this.setState(values);
    const isApproved = ({ c_Approved }) => c_Approved;
    return (
      <Row>
        <Col md="4">
          <PurchaseOrderCardFilter
            setPOFilter={setPOFilter}
            review={review}
            poFilter={poFilter}
            setPOSort={setPOSort}
            poSort={poSort}
            poDesc={poDesc}
            purchaseOrders={purchaseOrders.filter(filterPOs(poFilter))}
            setActive={setActive}
            activePO={activePO}
            isApproved={isApproved}
            editing={editing}
            options={[
              { value: 0, label: 'Pending Approval' },
              { value: 1, label: 'Approved' },
              { value: 2, label: 'All' }
            ]}
          />
        </Col>
        <Col md="8">
          {po &&
            <PurchaseOrderDetail
              showEdit
              inProgress={inProgress}
              purchaseOrder={po}
              lineItems={poItems}
              lineItemSpecialInstructions={poItemsInstructions}
              onApprovePurchaseOrder={handleApprovePurchaseOrder}
              addresses={addresses}
              lookups={lookups}
              toggleEditing={toggleEditing}
              editing={editing}
              handlePostPurchaseOrderAddress={handlePostPurchaseOrderAddress}
              handlePostPOVendor={handlePostPOVendor}
              projectVendors={projectVendors}
              handePostVendorJoin={handePostVendorJoin}
            />}
        </Col>
      </Row>
    );
  }
}

const mapState = state => {
  const {
    error,
    inProgress
  } = state.postApprovePurchaseOrder.toJS();
  return {
    error,
    inProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      postApprovePurchaseOrder: fetchDux.postApprovePurchaseOrder.createAction,
      postPurchaseOrderAddress: fetchDux.postPurchaseOrderAddress.createAction,
      clearPostApprovePurchaseOrder: fetchDux.postApprovePurchaseOrder.clearAction,
      clearPostPurchaseOrderAddress: fetchDux.postPurchaseOrderAddress.clearAction,
      postPOVendor: fetchDux.postPOVendor.createAction,
      postVendorJoin: fetchDux.postVendorJoin.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ApprovePurchaseOrders);
