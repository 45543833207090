import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';

const columns = [
  {
    Header: 'First Name',
    accessor: 'nameFirst',
    Cell: ({ value, original }) => (
      <Link to={`/customers/users/${original.id}`}>{value}</Link>
    )
  },
  {
    Header: 'Last Name',
    accessor: 'nameLast'
  },
  {
    Header: 'Email Address',
    accessor: 'email'
  },
  {
    Header: 'Concepts',
    accessor: 'companies',
    Cell: ({ value }) => {
      return (value || []).map(({name}, index)=>{
        return(
        <span
          key={index}
          className="table-label"
        >
          {name} 
        </span>)
      })
    }
  }
];

const CustomersUsersSearchResults = (
  {
    error,
    result,
    onFetchData,
    paging,
    handleSearchChange,
    inProgress
  }
) => {

  const {
    sortBy,
    desc,
    page,
    pageSize,
    search
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];

  return (
    <Table
      showColumnsSelector
      showSearch
      data={result}
      columns={columns}
      search={search}
      noDataText="No Users Found"
      onFetchData={onFetchData}
      onSearchChange={handleSearchChange}
      defaultSorted={sorted}
      page={error ? 0 : Number(page)}
      defaultPageSize={Number(pageSize)}
      inProgress={inProgress}
    />
  );
};

export default CustomersUsersSearchResults;
