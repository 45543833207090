import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import fetchDux from '../../../state/fetch-dux';
import Table from '../../ui/Table';
import Icon from '../../ui/Icon';
import { actionCreators } from '../../../state/modals-dux';
import ModalConfirmDeleteVendor from './ModalConfirmDeleteVendor';
import ModalEditVendor from './ModalEditVendor';
import ModalAddVendor from './ModalAddVendor';
import ModalEditVendorMappings from './ModalEditVendorMappings';
import { freightPrograms } from './VendorForm';

const getColumns = (
  { handleEditVendor, confirmDeleteVendor, handleEditVendorMappings }
) => {
  return [
    {
      Header: 'Sage Vendor Name',
      accessor: 'Name'
    },
    {
      Header: 'Sage Vendor No',
      accessor: 'VendorNo'
    },
    {
      Header: 'Email',
      accessor: 'PreferredEmail'
    },
    {
      Header: 'Email CC',
      accessor: 'PreferredEmailCC'
    },
    {
      Header: 'Freight Program',
      accessor: 'FreightProgram'
    },
    {
      Header: 'Is Colorado',
      accessor: 'IsColorado',
      style: { textAlign: 'center' },
      width: 125,
      Cell: ({ value }) => {
        if (!value) return '';
        return <Icon name="check" style={{ color: '#04844B' }} />;
      }
    },
    {
      Header: 'AQ Manufacturers',
      accessor: 'NumMappings',
      Cell: ({ value }) => {
        if (!value) return '';
        return `${value}`;
      }
    },
    {
      Header: '',
      accessor: 'ID',
      style: { textAlign: 'right' },
      width: 150,
      Cell: ({ value, original }) => {
        const { NumMappings } = original || {};
        return (
          <div>
            <Button
              size="sm"
              variant={NumMappings > 0 ? 'success' : 'danger'}
              onClick={() => handleEditVendorMappings(value)}
            >
              <Icon name="link" />
            </Button>
            <Button
              size="sm"
              onClick={() => handleEditVendor(value)}
              style={{ marginLeft: '5px' }}
            >
              <Icon name="edit" />
            </Button>
            <Button
              size="sm"
              variant="danger"
              style={{ marginLeft: '5px' }}
              onClick={() => confirmDeleteVendor(value)}
            >
              <Icon name="trash" />
            </Button>
          </div>
        );
      }
    }
  ];
};

const defaultSorted = [
  {
    id: 'Name',
    desc: false
  }
];

const getDefaultPageSize = data => {
  const { length } = data || [];
  if (!length || length < 5) return 5;
  if (length > 10) return 10;
  return length;
};

class VendorsAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = { activeVendorID: null, search: '' };
  }
  componentDidMount() {
    this.props.getVendors();
    this.props.getVendorsMappings();
    this.props.getManufacturers();
  }
  render() {
    const {
      result,
      inProgress,
      showModal,
      delVendor,
      putVendor,
      postVendor,
      mappings,
      manufacturers,
      postVendorsMapping,
      getVendors
    } = this.props;

    const vendorResult = (result || []).map(d => {
      const freight = (d.FreightProgram || '')
        .split(',')
        .map(f => {
          let value = freightPrograms[f] || '';
          switch (f) {
            case 'P':
              return value + ' ' + d.FreightProgramPieces;
            case 'T':
              return value + ' ' + d.FreightProgramPO;
            case 'F':
              return value + ' ' + d.FreightProgramFlatRate;
            default:
              return value;
          }
        })
        .filter(d => !!d.trim());
      return Object.assign({}, d, { FreightProgram: freight.join(' or ') });
    });

    const { activeVendorID, search = '' } = this.state;

    if (inProgress) return false;

    const activeVendor = (result || []).find(({ ID }) => ID === activeVendorID);

    const activeMappings = (mappings || [])
      .filter(({ SageVendorId }) => SageVendorId === activeVendorID);

    const { ID } = activeVendor || {};

    const handleEditVendor = ID => {
      this.setState({ activeVendorID: ID });
      showModal('EditVendorModal');
    };

    const confirmDeleteVendor = ID => {
      this.setState({ activeVendorID: ID });
      showModal('DeleteVendorModal');
    };

    const handleShowAddVendor = () => showModal('AddVendorModal');
    const handleEditVendorMappings = ID => {
      this.setState({ activeVendorID: ID });
      showModal('EditVendorMappingsModal');
    };

    const handleDeleteVendor = () => delVendor(ID);
    const handlePutVendor = values => {
      if (values.FreightProgram.some(a => a === 'A')) {
        values = {
          ...values,
          FreightProgramFlatRate: null,
          FreightProgramPO: null,
          FreightProgramPieces: null
        }
      }
      putVendor({ id: ID, payload: values });
    }

    const handlePostVendor = values => postVendor({ payload: values });

    const handlePostVendorsMapping = values =>
      postVendorsMapping(
        {
          payload: { ...values, SageVendorId: activeVendorID }
        },
        null,
        null,
        getVendors
      );

    const handleSearchChange = value => {
      this.setState({ search: value });
    };

    const columns = getColumns({
      handleEditVendor,
      confirmDeleteVendor,
      handleEditVendorMappings
    });

    const tableData = (vendorResult || []).filter((vendor) => {
      const {
        Name,
        VendorNo,
        PreferredEmail,
        PreferredEmailCC,
        FreightProgram,
        AdditionalNotes,
        NumMappings } = vendor;
      if (
        (Name || '').toString().toLocaleLowerCase().indexOf((search || '').toLocaleLowerCase()) >= 0 ||
        (VendorNo || '').toString().toLocaleLowerCase().indexOf((search || '').toLocaleLowerCase()) >= 0 ||
        (PreferredEmail || '').toString().toLocaleLowerCase().indexOf((search || '').toLocaleLowerCase()) >= 0 ||
        (PreferredEmailCC || '').toString().toLocaleLowerCase().indexOf((search || '').toLocaleLowerCase()) >= 0 ||
        (FreightProgram || '').toString().toLocaleLowerCase().indexOf((search || '').toLocaleLowerCase()) >= 0 ||
        (AdditionalNotes || '').toString().toLocaleLowerCase().indexOf((search || '').toLocaleLowerCase()) >= 0 ||
        (NumMappings || '').toString().toLocaleLowerCase().indexOf((search || '').toLocaleLowerCase()) >= 0
      ) {
        return vendor;
      }
    })

    return (
      <div>

        <ModalConfirmDeleteVendor
          name="DeleteVendorModal"
          activeVendor={activeVendor}
          onDeleteVendor={handleDeleteVendor}
        />
        <ModalEditVendor
          name="EditVendorModal"
          activeVendor={activeVendor}
          onPutVendor={handlePutVendor}
        />
        <ModalEditVendorMappings
          name="EditVendorMappingsModal"
          activeVendor={activeVendor}
          activeMappings={activeMappings}
          manufacturers={manufacturers}
          handlePostVendorsMapping={handlePostVendorsMapping}
          getVendors={getVendors}
        // onPutVendor={handlePutVendor}
        />
        <ModalAddVendor name="AddVendorModal" onPostVendor={handlePostVendor} />
        <Row>
          <Col>
            <h2>
              Sage Vendors
            </h2>
          </Col>
          <Col sm="auto">
            <Button onClick={handleShowAddVendor}>Add Sage Vendor</Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          data={tableData}
          defaultPageSize={getDefaultPageSize(result)}
          defaultSorted={defaultSorted}
          noDataText="No Vendors exist"
          showSearch
          search={search}
          onSearchChange={handleSearchChange}
        />

      </div>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getVendors.toJS();
  const {
    result: mappings,
    inProgress: getVendorMappingsInProgress
  } = state.getVendorsMappings.toJS();
  const {
    result: manufacturers,
    inProgress: getManufacturersInProgress
  } = state.getManufacturers.toJS();

  return {
    result,
    mappings,
    manufacturers,
    inProgress: inProgress ||
      getVendorMappingsInProgress ||
      getManufacturersInProgress
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getVendors: fetchDux.getVendors.createAction,
      getManufacturers: fetchDux.getManufacturers.createAction,
      getVendorsMappings: fetchDux.getVendorsMappings.createAction,
      delVendor: fetchDux.delVendor.createAction,
      putVendor: fetchDux.putVendor.createAction,
      postVendor: fetchDux.postVendor.createAction,
      postVendorsMapping: fetchDux.postVendorsMapping.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(VendorsAdmin);
