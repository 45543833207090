import React from 'react'
import { Button, Col, Modal, Row } from "react-bootstrap"
import { Field, reduxForm } from "redux-form"
import FormField from "../../../forms/FormField";


const required = value => value ? undefined : 'Required';
const CouponForm = (
    {
        handleSubmit,
        submitting,
        closeHandler,
        edit
    }
) => {

    return (
        <form onSubmit={handleSubmit}>
            <Modal.Body>
                <Row>
                    <Col>
                        <Field
                            required
                            component={FormField.Input}
                            name='Code'
                            label='Code'
                            validate={[required]}
                        />
                    </Col>
                    <Col>
                        <Field
                            required
                            component={FormField.Input}
                            name='Amount'
                            label='Amount'
                            validate={[required]}
                            normalize={
                                (value, previous) => {
                                    if(value.trim().length === 0) return value;
                                    const exp = /^[0-9]+$/
                                    if (exp.test(value)) return value;
                                    return previous;
                                }
                            }
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={6}>
                        <Field
                            required
                            component={FormField.Datepicker}
                            name='Expires'
                            label='Expires'
                            validate={[required]}
                        />
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '1rem' }}>
                    <Button
                        size='sm'
                        disabled={submitting}
                        onClick={
                            () => closeHandler()
                        }
                        variant='secondary'
                        style={{ marginRight: '1rem' }}
                    >
                        Cancel
                    </Button>

                    <Button size='sm' type='submit' disabled={submitting}>
                        {edit ? 'Edit Coupon' : 'Create Coupon'}
                    </Button>
                </div>
            </Modal.Footer>
        </form>
    )
}

export default reduxForm({
    form: 'CuponForm'
})(CouponForm);