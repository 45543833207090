import React from 'react';
import classNames from 'classnames';

const setStatusColor = id => {
  switch (id) {
    case 1:
      return 'red';
    case 2:
      return 'green';
    case 3:
      return 'black';
    case 4:
      return 'blue';
    default:
      return 'black';
  }
};

const WarehouseStatus = ({ statusId, statusName, fullWidth }) => (
  <span
    className={classNames(
      'warehouse-status',
      setStatusColor(statusId),
      fullWidth ? 'block' : ''
    )}
  >
    {statusName || 'N/A'}
  </span>
);

export default WarehouseStatus;
