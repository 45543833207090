import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Loading from '../../../ui/Loading';
import Icon from '../../../ui/Icon';

const FileDetail = ({ input, valid }) => {
  const { value } = input || {};
  return (
    <div>
      <h5>Filename:</h5>
      <div>
        {value.name || ''}
        &nbsp;
        {' '}
        {valid && <Icon style={{ color: '#28a745' }} name="check" />}
      </div>
    </div>
  );
};

const validateFileType = value => {
  const { name } = value || {};
  if (!value || !name) return 'Required';
  const filetype = name.substring(name.lastIndexOf('.') + 1);
  if (filetype.toLowerCase() !== 'json')
    return 'Invalid file type. Only JSON files allowed.';
  return undefined;
};

const UploadForm = props => {
  const { handleSubmit, submitting, uploadError, valid } = props;
  return (
    <form onSubmit={handleSubmit}>
      <Row>
        {uploadError &&
          <Col sm={12}>
            <div className="project-info" style={{ marginBottom: '20px' }}>
              <div className="project-info-label red">
                <Icon name="exclamation-triangle" />
                <div>Failed to upload freight</div>
                <div>{uploadError.message || ''}</div>
              </div>
            </div>
          </Col>}
        <Col md={7}>
          {!submitting &&
            <Field
              disabled={submitting}
              name="uploadFile"
              component={FormField.InputFileDropzone}
            />}
          {submitting &&
            <Loading fullPage message="Uploading Freight, Please Wait..." />}
        </Col>
        <Col md={5}>
          <div className="file-info" style={{ fontSize: '30px' }}>
            <Field
              name="uploadFile"
              component={FileDetail}
              validate={[validateFileType]}
              valid={valid}
            />
            <Button
              variant="primary"
              type="submit"
              disabled={!valid || submitting}
            >
              Upload
            </Button>
          </div>
        </Col>
      </Row>
    </form>
  );
};

export default reduxForm({
  form: 'freight-upload-form'
})(UploadForm);
