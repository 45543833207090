import React, { Component, useState } from 'react';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { Modal, Button, Row, Col, Table } from 'react-bootstrap';
import AccordionCard from '../../shipments/shipments-detail/AccordionCard';
import FormField from '../../../forms/FormField';
import {
  commodityClassOptions
} from '../../freight/freight-calc/FreightCalcForm';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';

const getNameParts = name => {
  const [p1, p2] = (name || '').split('.') || [];
  const [a, x1] = (p1 || '').split('[');
  const [b, x2] = (p2 || '').split('[');

  return {
    vendorIndex: parseInt(x1),
    itemsIndex: parseInt(x2)
  };
};

const getExcludedValue = (
  vendors,
  {
    vendorIndex,
    itemsIndex
  }
) => {
  try {
    const vendor = vendors[vendorIndex] || {};
    const { items } = vendor || {};
    const item = items[itemsIndex] || {};
    return item.exclude || false;
  } catch (err) {
    return false;
  }
};

const optionalRequired = (value, formValues, form, name) => {
  const { vendors } = formValues || {};

  const {
    vendorIndex,
    itemsIndex
  } = getNameParts(name);

  const isExcluded = getExcludedValue(vendors, {
    vendorIndex,
    itemsIndex
  });

  if (isExcluded) return undefined;

  return value ? undefined : 'Required';
};

const validZip = (value, {manual}) => {
  const reg = new RegExp(/^\d+$/);
  const validChars = reg.test(value || '');
  const validLength = (value || '').length ===5;

  if(manual){
    return (validChars && validLength);
  }

  return (validChars && validLength) ? undefined : 'Invalid Zip';
}

const renderItems = ({ fields }) => (
  <tbody>
    {fields.map((item, index) => (
      <tr key={index}>
        <td>{fields.get(index).model}</td>
        <td>
          <Field
            name={`${item}.quantity`}
            component={FormField.Input}
            validate={[optionalRequired]}
            hardTouched
          />
        </td>
        <td>
          <Field
            name={`${item}.weight`}
            component={FormField.Input}
            validate={[optionalRequired]}
            hardTouched
          />
        </td>
        <td>
          <Field
            name={`${item}.commodityClass`}
            component={FormField.SelectHTML}
            validate={[optionalRequired]}
            hardTouched
          >
            {(commodityClassOptions || []).map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </Field>
        </td>
        <td>
          <Field
            name={`${item}.originZip`}
            component={FormField.Input}
            validate={[optionalRequired, validZip]}
            hardTouched
          />
        </td>
        <td>
          <Field
            name={`${item}.destinationZip`}
            component={FormField.Input}
            validate={[optionalRequired, validZip]}
            hardTouched
          />
        </td>
        <td className="nowrap">
          <Row>
            <Col sm={5}>
              <Field
                label="Liftgate"
                name={`${item}.deliveryLiftgate`}
                component={FormField.InlineCheckbox}
              />
            </Col>
            <Col sm={7}>
              <Field
                label="Inside Placement"
                name={`${item}.deliveryInside`}
                component={FormField.InlineCheckbox}
              />
            </Col>
          </Row>
        </td>
        <td className="text-center">
          <Field
            name={`${item}.exclude`}
            component={FormField.InlineCheckbox}
          />
        </td>
      </tr>
    ))}
  </tbody>
);

const renderVendors = ({ fields, change }) => (
  <div>
    {fields.map((vendor, idx) => {
      const { label, items, excludeAll } = fields.get(idx);
      const hasErr = items.filter(
        d =>
          !d.exclude &&
          (!d.weight ||
            !Number(d.commodityClass) ||
            !d.originZip || !validZip(d.originZip, {manual:true}) ||
            !d.destinationZip || !validZip(d.destinationZip, {manual:true}))
      );
      // event, newValue, prevValue, name
      const toggleChange = (event, value) => {
        for (const i in items) {
          change(`${vendor}.items[${i}].exclude`, value);
        }
      };
      const checked = items.length === items.filter(d => !!d.exclude).length;
      if (checked !== excludeAll) change(`${vendor}.excludeAll`, checked);
      return (
        <AccordionCard
          key={idx}
          title={`${label} (${items.length})`}
          isOpen={false}
          hasError={!!hasErr.length}
        >
          <Table bordered>
            <thead>
              <tr>
                <th>Items</th>
                <th width="100">Quantity</th>
                <th width="100">Weight</th>
                <th className="nowrap">Commodity Class</th>
                <th className="nowrap">Origin Zip</th>
                <th className="nowrap">Destination Zip</th>
                <th className="nowrap" width="280">Accessorial Options<br />Delivery</th>
                <th>
                  Exclude
                  <Field
                    name={`${vendor}.excludeAll`}
                    component={FormField.InlineCheckbox}
                    onChange={toggleChange}
                  />
                </th>
              </tr>
            </thead>
            <FieldArray name={`${vendor}.items`} component={renderItems} />
          </Table>
        </AccordionCard>
      );
    })}
  </div>
);

class ShippingInfoResultForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFreightForm: false,
      wasInvalid: false
    };
  }
  componentDidMount() {
    this.props.showModal('posModel');
  }
  componentWillReceiveProps(nextProps) {
    const{
      valid
    } = nextProps || {};

    const{
      wasInvalid
    } = this.state || {};

    if(!valid && !wasInvalid){
      this.setState({
        wasInvalid: true
      });
    }
  }
  render() {
    const {
      handleSubmit,
      confirmDeleteProject,
      submitting,
      change,
      valid,
      openModal,
      closeModal,
      inProgress,
      postProjectLineItemInProgress,
      postUpdateStatusInProgress
    } = this.props;

    const handleFreightForm = () => {
      this.setState({
        showFreightForm: true
      });
      closeModal();
    };

    const handleCloseModal = () => {
      closeModal();
      handleSubmit();
    };

    const{
      wasInvalid
    } = this.state || {};

    return (
      <div className="freight-upload-result">

        {(valid && !wasInvalid) &&
          <Modal show={openModal === 'posModel'} onHide={() => { }}>
            <Modal.Header>
              <Modal.Title>Freight Calculation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h5>
                Do any POs require liftgate, inside placement options or zip code alteration?
              </h5>
            </Modal.Body>
            <Modal.Footer>
              <Button
                size="sm"
                variant="outline-secondary"
                onClick={() => handleCloseModal()}
                disabled={inProgress}
              >
                No
              </Button>
              <Button
                size="sm"
                variant="primary"
                onClick={handleFreightForm}
                disabled={inProgress}
              >
                Yes
              </Button>
            </Modal.Footer>
          </Modal>}
        {!valid &&
          <div className="pb-3 info" style={{ fontWeight: 'bold', color:'#c82333' }}>
            <Icon name="exclamation-triangle" />
            {` `}
            Items in red need additional information
          </div>}
        {(!valid || this.state.showFreightForm || wasInvalid) &&
          <form onSubmit={handleSubmit}>
            <FieldArray
              name="vendors"
              component={renderVendors}
              props={{ change }}
            />
            <div className="py-3">
              <Button
                variant="primary"
                type="submit"
                disabled={!valid || submitting}
              >
                { (postProjectLineItemInProgress || postUpdateStatusInProgress) &&
                  <Icon name="spinner" spin style={{marginRight:'10px'}}/>
                }
                {(postProjectLineItemInProgress || postUpdateStatusInProgress) ? 'processing...' : 'Save/Update LineItems' }
              </Button>
              <Button
                type="button"
                size="sm"
                className="float-right btn btn-primary btn-sm"
                variant="danger"
                onClick={confirmDeleteProject}
              >
                Delete Project
              </Button>
            </div>
          </form>}
      </div>
    );
  }
}

const shippingInfoForm = reduxForm({
  form: 'shipping-info-result-form',
  enableReinitialize: true,
  validate: (values, ...props) => {
    const errors = {};
    const { vendors } = values || {};
    const hasErr = (vendors || []).some(({
      items
    }) => {
      // // if (excludeAll) return false;
      return (items || [])
        .some(
          d =>
            !d.exclude &&
            (!d.weight ||
              !Number(d.commodityClass) ||
              !d.originZip || 
              !validZip(d.originZip, {manual:true}) ||
              !d.destinationZip || 
              !validZip(d.destinationZip, {manual:true}))
        );
    });
    if (hasErr) {
      errors._error = 'Invalid';
    }

    return errors;
  }
})(ShippingInfoResultForm);

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(shippingInfoForm);
