import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { Field, reduxForm } from 'redux-form';
import FormField from '../../../forms/FormField';
import statusDict from '../statusDict';

const EditQuoteStatusForm = ({
    submitting,
    handleSubmit,
    closeModal
}) => {
    const options = Object.keys(statusDict).map((key) => {
        return {
            label: key,
            value: statusDict[key]
        }
    })
    return (
        <form onSubmit={handleSubmit}>
            <Modal.Body>
                <Row>
                    <Col>
                        <Field
                            name='status'
                            label='Current Satus'
                            component={FormField.SelectReact}
                            options={options}
                        />
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    size='sm'
                    variant="secondary"
                    type='button'
                    onClick={() => {
                        if (!submitting)
                            closeModal()
                    }}
                    disabled={submitting}
                >
                    Cancel
                </Button>

                <Button
                    size='sm'
                    type='submit'
                    disabled={submitting}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </form>
    )
}




const form = 'EditQuoteStatusForm';


export default reduxForm({
    form: form
})(EditQuoteStatusForm);
