import { actionNamespace } from './defaults';

const SAVE_SEARCH = `${actionNamespace}/SAVE_SEARCH`;
const CLEAR_SEARCH = `${actionNamespace}/CLEAR_SEARCH`;

const defaultState = null;

export default (state = defaultState, { type, payload }) => {
  switch (type) {
    case SAVE_SEARCH:
      return payload;
    case CLEAR_SEARCH:
      return defaultState;
    default:
      return state;
  }
};

export const searchActionCreators = {
  saveSearch: payload => ({ type: SAVE_SEARCH, payload }),
  clearSearch: () => ({ type: CLEAR_SEARCH })
};
