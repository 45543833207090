import React from 'react';
import { Link } from 'react-router-dom';
import Table from '../../../ui/Table';

const columns = [
  {
    Header: 'Company Name',
    accessor: 'name',
    Cell: ({ value, original }) => (
      <Link to={`/customers/concepts/${(original || {}).id}`}>{value}</Link>
    )
  },
  {
    Header: 'Locations',
    accessor: 'numLocations',
    style: { textAlign: 'center' },
    Cell: ({ value }) => (
      <span>
        {value} Locations
      </span>
    )
  },
  {
    Header: 'Users',
    accessor: 'numUsers',
    style: { textAlign: 'center' },
    Cell: ({ value }) => (
      <span>
        {value} Users
      </span>
    )
  },
  {
    Header: 'Products',
    accessor: 'numProducts',
    style: { textAlign: 'center' },
    Cell: ({ value }) => (
      <span>
        {value} Products
      </span>
    )
  },
  {
    Header: 'Type',
    accessor: 'companyType',
    Cell: ({ value }) => (
      <span>
        {value}
      </span>
    )
  }
];

const CustomersCompaniesSearchResults = (
  {
    error,
    result,
    onFetchData,
    paging,
    handleSearchChange,
    inProgress
  }
) => {
  const {
    sortBy,
    desc,
    page,
    pageSize,
    search
  } = paging || {};

  const sorted = [
    {
      id: sortBy,
      desc: desc === 'true'
    }
  ];
  return (
    <Table
      showColumnsSelector
      showSearch
      data={result || []}
      columns={columns}
      search={search}
      noDataText="No Concepts Found"
      onFetchData={onFetchData}
      onSearchChange={handleSearchChange}
      defaultSorted={sorted}
      page={error ? 0 : Number(page)}
      defaultPageSize={Number(pageSize)}
      inProgress={inProgress}
    />
  );
};

export default CustomersCompaniesSearchResults;
