import React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import AccordionCard from './AccordionCard';
const numeral = require('numeral');

const formatMoney = value => numeral(value).format('0,0.00');

export default (
  {
    lineItems,
    setOpen,
    openTabs
  }
) => (
  <AccordionCard
    title="PO Information"
    controlled
    open={openTabs['poForm']}
    setOpen={setOpen}
    name="poForm"
  >
    <div className="table-responsive-sm pt-2">
      <table className="table table-sm po-table">
        <thead>
          <tr>
            <th>Item</th>
            <th className="item-desc" style={{ maxWidth: '200px' }}>
              Description
            </th>
            <th style={{ maxWidth: '25px' }}>Quantity</th>
            <th style={{ maxWidth: '25px' }}>Unit Cost</th>
            <th>Model #</th>
          </tr>
        </thead>
        <tbody>
          {lineItems.map(l => (
            <tr key={l.ID}>
              <td className="nowrap">{l.ItemNumber}</td>
              <td style={{ maxWidth: '200px' }}>{l.Spec}</td>
              <td className="text-right" style={{ maxWidth: '25px' }}>
                {l.Quantity}
              </td>
              <td className="text-right" style={{ maxWidth: '25px' }}>
                ${formatMoney(l.NetPrice)}
              </td>
              <td className="nowrap text-center">
                {l.Model}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </AccordionCard>
);
