import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const ProcessEcommerceOrderForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal
  } = props;
  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">

        <Field
          required
          name="checkNumber"
          type="text"
          component={FormField.Input}
          label="Check Number"
          validate={[required]}
          insetLabel
        />

        <Field
          name="details"
          type="text"
          component={FormField.Input}
          label="Add Details"
          insetLabel
        />

      </div>

      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          Process
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'process-ecommerce-order-form'
})(ProcessEcommerceOrderForm);
