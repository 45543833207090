import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import CreateCustomersUserForm from './CreateCustomersUserForm';
import Notification from '../../../ui/Notification';

const getApprovers = (selectedCompanies, allUsers) => {
  return (allUsers || [])
    .filter(
      ({ companies, isApprover }) =>
        isApprover &&
        (companies || [])
          .some(({ id }) => (selectedCompanies || []).includes(id))
    );
};

const validEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

class ModalCreateCustomersUser extends Component {
  constructor(props) {
    super(props);
    this.state = { companyIds: [], roleType: 1, emailCC: [] };
  }
  componentDidMount() {
    const {
      isCompany,
      companyId
    } = this.props;

    if (isCompany) {
      this.setState({ companyIds: [companyId] });
    }
  }
  render() {
    const {
      openModal,
      closeModal,
      name,
      companies,
      postUser,
      searchCustomersUsers,
      searchCustomersUsersResult,
      purchasingApproval,
      paymentTerms,
      ccEmailInvoice,
      isCompany,
      companyId,
      getCompanyById,
      postUserInProgress,
      postUserError,
      clearPostUser
    } = this.props;

    const {
      companyIds,
      roleType,
      emailCC
    } = this.state;

    const handlePostUser = values => {
      values.companies = companyIds;
      values.roleTypeID = roleType;
      values.emailCC = emailCC;
      postUser(values, 'create-customers-user-form', null, () => {
        if (isCompany) {
          getCompanyById({ id: companyId, query: { getUsers: 1 } }, null, true);
          return;
        }
        searchCustomersUsers(null, null, true);
      });
    };

    const handleCompanyChange = newSelectedCompany => {
      if (!newSelectedCompany) return;
      this.setState({
        companyIds: (companyIds || []).concat(newSelectedCompany)
      });
    };

    const handleRemoveCompany = removeCompanyId => {
      if (!removeCompanyId) return;
      const {
        companyIds
      } = this.state;
      const newCompanyArray = (companyIds || [])
        .filter(companyId => removeCompanyId !== companyId);
      this.setState({ companyIds: newCompanyArray });
    };

    const handleAddEmailCC = () => {
      if (!ccEmailInvoice) return false;
      if (!validEmail(ccEmailInvoice)) return false;
      this.setState({ emailCC: (emailCC || []).concat([ccEmailInvoice]) });
      return true;
    };

    const handleRemoveEmailCC = removeEmailCC => {
      if (!removeEmailCC) return;
      const {
        emailCC
      } = this.state;
      const newEmailCCArray = (emailCC || [])
        .filter(emailName => removeEmailCC !== emailName);
      this.setState({ emailCC: newEmailCCArray });
    };

    const handleRoleChange = type => {
      this.setState({ roleType: type });
    };

    const companiesDisplay = (companies || [])
      .sort((a, b) => {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .filter(({ id }) => (companyIds || []).includes(id));

    const handleHideModal = () => {
      closeModal();
      this.setState({ companyIds: [], emailCC: [] });
    };

    const currentApprovers = getApprovers(
      companyIds,
      searchCustomersUsersResult
    );

    const {
      message
    } = postUserError || {};

    return (
      <Modal
        show={openModal === name}
        onHide={handleHideModal}
        className="modal-side"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <CreateCustomersUserForm
          closeModal={closeModal}
          onSubmit={handlePostUser}
          companies={companies}
          companiesDisplay={companiesDisplay}
          handleCompanyChange={handleCompanyChange}
          handleRemoveCompany={handleRemoveCompany}
          handleHideModal={handleHideModal}
          companyIds={companyIds}
          handleRoleChange={handleRoleChange}
          roleType={roleType}
          currentApprovers={currentApprovers}
          purchasingApproval={purchasingApproval}
          paymentTerms={paymentTerms}
          handleAddEmailCC={handleAddEmailCC}
          handleRemoveEmailCC={handleRemoveEmailCC}
          emailCC={emailCC}
          ccEmailInvoice={ccEmailInvoice}
          submitting={postUserInProgress}
        />
        {postUserError &&
          <Notification
            key="company-delete-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostUser}
          >
            {message ? message : 'Failed to create user.Please try again.'}
          </Notification>}
      </Modal>
    );
  }
}

const selector = formValueSelector('create-customers-user-form');

const mapState = state => {
  const purchasingApproval = selector(state, 'purchasingApproval');
  const paymentTerms = selector(state, 'paymentTerms');
  const ccEmailInvoice = selector(state, 'ccEmailInvoice');

  const {
    result: searchCustomersUsersResult,
    inProgress: searchCustomersUsersInProgress,
    error: searchCustomersUsersError
  } = state.searchCustomersUsers.toJS();

  const {
    inProgress: postUserInProgress,
    error: postUserError
  } = state.postUser.toJS();

  return {
    searchCustomersUsersResult,
    searchCustomersUsersInProgress,
    searchCustomersUsersError,
    purchasingApproval,
    paymentTerms,
    ccEmailInvoice,
    postUserInProgress,
    postUserError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postUser: fetchDux.postUser.createAction,
      clearPostUser: fetchDux.postUser.clearAction,
      searchCustomersUsers: fetchDux.searchCustomersUsers.createAction,
      getCompanyById: fetchDux.getCompanyById.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalCreateCustomersUser);
