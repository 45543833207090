import React from 'react';
import { reduxForm, Field, formValueSelector, change } from 'redux-form';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormField from '../../../forms/FormField';
import { bindActionCreators } from 'redux';

const required = value => value ? undefined : 'Required';
const poRequired = req => req ? required : () => { };
const checkNumDays = value =>
  value && !/^[0-9]$/.test(value) ? 'Value must be between 0 and 9' : undefined;

const ReportsSearchForm = (
  { handleSubmit, submitting, lookups, reportType, vendorName, changeHandler }
) => {
  const { reportTypes = [] } = lookups || {}
  const selectedReportType = reportTypes.find(({ value }) => value === reportType) || {};

  const { instruction } = selectedReportType;

  return (
    <div className="box-reports-search">
      <Form onSubmit={handleSubmit}>

        {/* <p className="text-muted">
        Note: The best way to print is to export the report to a PDF, and print form there.
      </p> */}

        <Row>
          <Col md={4}>
            <Field
              required
              multiple
              htmlSize={11}
              style={{ height: '225px' }}
              name="salesReps"
              label="Sales Groups"
              component={FormField.SelectHTML}
              validate={[required]}
            >
              {(lookups.salesReps || [])
                .filter(d => !!d)
                .map((d, i) => (
                  <option key={i} value={d.Code}>
                    {d.FirstName} {d.LastName}
                  </option>
                ))}
            </Field>
          </Col>
          <Col md={8}>

            <Row>
              <Col md={6}>

                <Field
                  required
                  type="text"
                  name="reportType"
                  label="Report Type"
                  component={FormField.SelectReact}
                  options={lookups.reportTypes}
                  validate={[required]}
                  onChange={() => {
                    changeHandler('reports-search-form', 'startDate', null);
                    changeHandler('reports-search-form', 'endDate', null);
                    changeHandler('reports-search-form', 'vendors', null);
                    changeHandler('reports-search-form', 'scheduledShipDateStart', null);
                    changeHandler('reports-search-form', 'scheduledShipDateEnd', null);
                  }}
                />

                <Field
                  required={reportType === 'project-manager'}
                  type="text"
                  name="poRange"
                  label={
                    (
                      reportType === 'vendor-summary'
                    ) ?
                      "Search by Account Code or Job Code (recommended)"
                      :
                      reportType === 'project-manager' ?
                        "Search by Job Code (required)"
                        :
                        reportType === 'warehouse' ?
                          'Search by Job Code (recommended)'
                          :
                          "Search by Account Code or Job Code (optional)"
                  }
                  component={FormField.Input}
                  validate={[poRequired(reportType === 'project-manager')]}
                />

                {
                  [
                    'acknowledgement',
                    'shipping-action',
                    'unconfirmed-warehouse-deliveries',
                    'vendor-summary'
                  ].includes(reportType) &&
                  <>
                    <Field
                      type="date"
                      component={FormField.Datepicker}
                      label='Search by Order Date – Start (optional)'
                      name='startDate'
                    />

                    <Field
                      type="date"
                      component={FormField.Datepicker}
                      label='Search by Order Date - End (optional)'
                      name='endDate'
                    />
                  </>
                }



              </Col>
              <Col md={6}>
                {reportType === 'vendor-summary' &&
                  <>
                    <Field
                      name='vendorName'
                      placeholder='Search Vendor'
                      type='text'
                      component={FormField.Input}
                    />
                    <Field
                      required
                      multiple
                      htmlSize={7}
                      name="vendors"
                      label="Vendors"
                      style={{ height: '225px' }}
                      component={FormField.SelectHTML}
                      validate={[required]}
                    >

                      {
                        (vendorName || '').trim().length > 0 ?
                          (lookups.vendors || [])
                            .filter(d => {
                              return ((d.Name || '').toString().toLowerCase().indexOf((vendorName || '').toString().toLowerCase()) >= 0)
                            })
                            .map((d, i) => (
                              <option key={i} value={d.ID}>
                                {d.Name}
                              </option>
                            ))
                          :
                          (lookups.vendors || []).map((d, i) => (
                            <option key={i} value={d.ID}>
                              {d.Name}
                            </option>
                          ))
                      }
                    </Field>
                  </>

                }
                {reportType === 'acknowledgement-fax' &&
                  <Field
                    required
                    type="number"
                    name="numDays"
                    label="How many days (1-9) do you want to select on?"
                    component={FormField.Input}
                    validate={[required, checkNumDays]}
                  />}
                {reportType === 'to-be-shipped' &&
                  <div>
                    <Field
                      required
                      name="scheduledShipDateStart"
                      label="Search by Scheduled Ship Date (Start)"
                      component={FormField.Datepicker}
                      validate={[required]}
                    />
                    <Field
                      required
                      name="scheduledShipDateEnd"
                      label="Search by Scheduled Ship Date (End)"
                      component={FormField.Datepicker}
                      validate={[required]}
                    />
                  </div>}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col md={2}>
            <Button type="submit" variant="success" disabled={submitting}>
              Generate Report
            </Button>
          </Col>
          <Col md={10}>
            <Row>
              {selectedReportType &&
                <div>
                  <strong>Description </strong> {selectedReportType.instruction}
                </div>
              }
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
}


const formName = 'reports-search-form';
const selector = formValueSelector(formName);

const form = reduxForm({
  form: formName,
  enableReinitialize: true
})(ReportsSearchForm);

const mapState = state => {
  const reportType = selector(state, 'reportType');
  const vendorName = selector(state, 'vendorName')
  return { reportType, vendorName };
};
const mapDispatch = dispatch =>
  bindActionCreators({
    changeHandler: change
  }, dispatch)

export default connect(mapState, mapDispatch)(form);
