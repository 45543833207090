import React, { Component } from 'react';
import { Form, Dropdown, InputGroup, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import PurchaseOrderCard from './PurchaseOrderCard';

const sortPOs = (sort, desc) =>
  (a, b) => {
    const x = (a[sort] || '').toUpperCase();
    const y = (b[sort] || '').toUpperCase();
    if (desc) return y < x ? -1 : y > x ? 1 : 0;
    return x < y ? -1 : x > y ? 1 : 0;
  };

class CustomToggle extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    return (
      <Button onClick={this.handleClick}>
        {this.props.children}
      </Button>
    );
  }
}

class PurchaseOrderCardFilter extends Component {
  shouldComponentUpdate(nextProps) {
    const { editing } = nextProps || {};
    const { editing: editing2 } = this.props;
    const different = editing !== editing2;
    return !editing || different;
  }
  render() {
    const {
      poFilter,
      setPOFilter,
      setPOSort,
      poSort,
      poDesc,
      purchaseOrders,
      setActive,
      activePO,
      isApproved,
      options
    } = this.props;

    const isActive = (sort, desc) => sort === poSort && desc === poDesc;

    return (
      <div>
        <InputGroup>
          <Form.Control
            as="select"
            value={poFilter}
            onChange={e =>
              setPOFilter({
                poFilter: Number(e.target.value),
                activePO: null,
                editing: false
              })}
          >
            {(options || [])
              .map(({ value, label }, idx) => (
                <option key={idx} value={value}>{label}</option>
              ))}
          </Form.Control>
          <InputGroup.Append>
            <Dropdown style={{ fontSize: '14px', marginLeft: '5px' }}>
              <Dropdown.Toggle
                as={CustomToggle}
                variant="primary"
                id="dropdown-sort-po"
                drop="right"
              >
                {poDesc
                  ? <Icon name="sort-alpha-down-alt" />
                  : <Icon name="sort-alpha-down" />}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  active={isActive('VendorName', false)}
                  onClick={() => setPOSort('VendorName', false)}
                >
                  <Icon name="sort-alpha-down" /> Vendor
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive('VendorName', true)}
                  onClick={() => setPOSort('VendorName', true)}
                >
                  <Icon name="sort-alpha-down-alt" /> Vendor
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive('c_PurchaseOrderNumber', false)}
                  onClick={() => setPOSort('c_PurchaseOrderNumber', false)}
                >
                  <Icon name="sort-alpha-down" /> PO #
                </Dropdown.Item>
                <Dropdown.Item
                  active={isActive('c_PurchaseOrderNumber', true)}
                  onClick={() => setPOSort('c_PurchaseOrderNumber', true)}
                >
                  <Icon name="sort-alpha-down-alt" /> PO #
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </InputGroup.Append>
        </InputGroup>

        {(purchaseOrders || [])
          .sort(sortPOs(poSort, poDesc))
          .map((purchaseOrder, index) => (
            <PurchaseOrderCard
              key={index}
              isApproved={isApproved(purchaseOrder)}
              {...purchaseOrder}
              setActive={setActive}
              activePO={activePO}
            />
          ))}
      </div>
    );
  }
}

export default PurchaseOrderCardFilter;
