import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import Icon from '../../../ui/Icon';
import { Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import { formatMoney } from './ProposalSummaryForm';

const renderLineItems = ({ fields }) => (
  <table>
    <tbody>
      {fields.map((row, index) => {
        const item = fields.get(index);
        return (
          <tr key={index}>
            <td width="34" />
            <td width="90"></td>
            <td width="60">{item.Quantity}</td>
            <td><b>Description:</b> {item.c_ItemDescription}</td>
            <td width="150" style={{ textAlign: 'center' }}>{formatMoney(item.SellPrice)}</td>
            <td width="60">{item.status}</td>
            <td width="150">
              <div style={{ marginBottom: '1rem' }}>
                <Field
                  name={`${row}.Show`}
                  label="Show on Web"
                  size="sm"
                  component={FormField.InputToggle}
                />
              </div>
              <div>
                <Field
                  name={`${row}.ShowOnPDF`}
                  label="Show on PDF"
                  size="sm"
                  component={FormField.InputToggle}
                />
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  </table>
);

const QuoteItemsRow = props => {
  const { row, item, expandAll } = props || {};
  const [isOpen, toggle] = useState(expandAll);
  useEffect(() => {
    toggle(expandAll)
  }, [expandAll])
  return (
    <tbody>
      <tr className={isOpen ? 'active' : ''}>
        <td className="toggle">
          {(row.lineItems || []).length > 0 &&
            <a href onClick={() => toggle(!isOpen)}>
              {!isOpen && <Icon fixedWidth name="chevron-right" />}
              {isOpen && <Icon fixedWidth name="chevron-down" />}
            </a>}
        </td>
        <td>{row.ItemNumber}</td>
        <td>{row.Quantity}</td>
        <td>{row.Manufacturer}</td>
        <td>{row.Model}</td>
        <td>{row.Category}</td>
        <td>{row.Spec}</td>
        <td>{formatMoney(row.UnitPrice)}</td>
        <td>{formatMoney(row.SellPrice)}</td>
        <td />
      </tr>
      {(isOpen) &&
        <tr className="items">
          <td colSpan={10}>
            <FieldArray
              name={`${item}.lineItems`}
              component={renderLineItems}
            />
          </td>
        </tr>}
    </tbody>
  );
};

const renderItems = ({ fields, handleFormFieldChange, items, expandAll }) => (
  <table>
    <thead>
      <tr>
        <th width="34" />
        <th width="90">Item No</th>
        <th width="60">Qty</th>
        <th>Manufacturer</th>
        <th>Model No</th>
        <th>Category</th>
        <th>Spec</th>
        <th width="110">Unit Price</th>
        <th width="110">Sell Price</th>
        <th width="110">Sub Line Items</th>
      </tr>
      <tr>
        <td colSpan={3} />
        <td>
          <Field
            name="ManufacturerAddToWeb"
            label="Add To Web"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td>
          <Field
            name="ModelNoAddToWeb"
            label="Add To Web"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td>
          <Field
            name="CategoryAddToWeb"
            label="Add To Web"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td>
          <Field
            name="SpecAddToWeb"
            label="Add To Web"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td colSpan={2} />
        <td width={110}>
          <Field
            name="SubLineItemShowOnWeb"
            label="Show On Web"
            size="sm"
            component={FormField.InputToggle}
            onChange={
              (e) => {
                items.map((item, index) => {
                  const { lineItems } = item;
                  lineItems.map((lineItem, i) => {
                    const fieldName = `items.${index}.lineItems.${i}.Show`;
                    handleFormFieldChange('edit-quote-form', fieldName, e.target.checked);
                  })
                })
              }
            }
          />
        </td>
      </tr>
      <tr>
        <td colSpan={3} />
        <td>
          <Field
            name="ManufacturerAddToPdf"
            label="Add To Pdf"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td>
          <Field
            name="ModelNoAddToPdf"
            label="Add To Pdf"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td>
          <Field
            name="CategoryAddToPdf"
            label="Add To Pdf"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td>
          <Field
            name="SpecAddToPdf"
            label="Add To Pdf"
            size="sm"
            component={FormField.InputToggle}
          />
        </td>
        <td colSpan={2} />
        <td>
          <Field
            name="SubLineItemShowOnPdf"
            label="Show On Pdf"
            size="sm"
            component={FormField.InputToggle}
            onChange={
              (e) => {
                items.map((item, index) => {
                  const { lineItems } = item;
                  lineItems.map((lineItem, i) => {
                    const fieldName = `items.${index}.lineItems.${i}.ShowOnPDF`;
                    handleFormFieldChange('edit-quote-form', fieldName, e.target.checked);
                  })
                })
              }
            }
          />
        </td>

      </tr>
    </thead>
    {fields.map((item, idx) => {
      const row = fields.get(idx);
      return <QuoteItemsRow row={row} item={item} key={idx} expandAll={expandAll} />;
    })}
  </table>
);

const QuoteItemsForm = props => {
  const { items, handleFormFieldChange, expandAll } = props || {};
  return (
    <div className="quote-items">
      <FieldArray
        name="items"
        component={renderItems}
        props={{ handleFormFieldChange, items, expandAll }}
      />
    </div>
  );
};

export default QuoteItemsForm;
