import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';
import { getStatusLabel } from '../statusDict';

const data = {
  viewed: 1,
  timeSpent: 45,
  lastViewed: 'Yesterday',
  download: true,
  ip: '123.123.123.123',
  location: {
    city: 'Denver',
    state: 'Colorado'
  },
  timeline: [
    {
      status: 'Draft',
      author: { firstName: 'Joe', lastName: 'Doe' },
      date: '1/30/2021'
    },
    {
      status: 'Sent',
      author: { firstName: 'Joe', lastName: 'Doe' },
      date: '1/30/2021'
    },
    {
      status: 'Viewed',
      author: { firstName: 'Joe', lastName: 'Doe' },
      date: '1/30/2021'
    }
  ]
};

class ModalQuoteLogs extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      logs,
      PDFDownloaded
    } = this.props;

    const { logs: timelineLogs } = logs || {};
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Quote Logs</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>Quote Activity</h5>

          <table className="table-data">
            <tbody>
              <tr>
                <th>Viewed</th>
                <td>{logs && logs.viewCount + `x`}</td>
              </tr>
              <tr>
                <th>Last viewed</th>
                <td>{logs && moment(logs.lastViewedDate).format('ddd, MMM Do')}</td>
              </tr>
              <tr>
                <th>PDF Download</th>
                <td>{PDFDownloaded ? `Yes` : `No`}</td>
              </tr>
              <tr>
                <th>IP Address</th>
                <td>{logs && logs.lastViewedIp}</td>
              </tr>
            </tbody>
          </table>

          <h5>Quote Timeline</h5>

          <div className="timeline">
            {(timelineLogs || []).map((i, index) => {
              const { FirstName, LastName, Status, Date } = i;
              return (
                <div className="event" key={index}>
                  <div className="status purple">{getStatusLabel(Status)}</div>
                  <div className="author">
                    {FirstName || ''} {LastName || ''}
                  </div>
                  <div className="date">
                    {moment(Date).format('MMM d, Y')}
                  </div>
                </div>
              );
            })}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            type="button"
            onClick={closeModal}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalQuoteLogs);
