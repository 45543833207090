import React from 'react';
import { Modal, Form, Row, Button, Col, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import FormField from '../../../forms/FormField';
import moment from 'moment';

const required = value => value ? undefined : 'Required';

const compareSchdShipDate = (newDate, oldDate) => {
  return moment(newDate).isSame(oldDate);
}

const EditLineItemsForm = (
    {
        handleSubmit,
        closeModal,
        submitting,
        saveButtonDisabled,
        initialValues
    }
) => {
    const { error } = initialValues;
    return (
        <Form onSubmit={handleSubmit}>
            <Modal.Body>
                {error && <Alert variant="danger">{error.message}</Alert>}
                <p>Use the boxes below to edit the information for the selected shipment line items.
                    This information determines if the shipment line item is grouped into a shipment
                </p>
                <Row>
                    <Col sm={8}>
                        <Field
                            name='SchdShipDate'
                            type='text'
                            component={FormField.Datepicker}
                            label='Scheduled Ship Date:'
                            required
                            isUTC
                            validate={[required]}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col sm={8}>
                        <Field
                            name='TrackingNum'
                            type='text'
                            component={FormField.Input}
                            label='Tracking Number:'
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Row className='editLineItemsFooter'>
                    <Col>
                        <Button
                            size='sm'
                            variant='outline-secondary'
                            onClick={
                                () => closeModal()
                            }
                            disabled={submitting}
                        >
                            Cancel
                        </Button>
                    </Col>

                    <Col>
                        <Button
                            size='sm'
                            variant='primary'
                            type='submit'
                            style={{ float: 'right' }}
                            disabled={submitting || saveButtonDisabled}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Form>
    )
}

const formName = 'EditLineItemsForm'

const selector = formValueSelector(formName)

const mapState = state => {

    const TrackingNum = selector(state, 'TrackingNum');
    const SchdShipDate = selector(state, 'SchdShipDate');

    const { form } = state;
    const formValues = form[formName] || {};
    const { initial } = formValues;
    const {
        TrackingNum: initialTrackingNum,
        SchdShipDate: initialSchdShipDate
    } = initial || {};

    if ((TrackingNum || '').trim() === (initialTrackingNum || '').trim() &&
        compareSchdShipDate(SchdShipDate, initialSchdShipDate))
        return { saveButtonDisabled: true }

    if (
        (TrackingNum || '').trim().length === 0
        &&
        (SchdShipDate || '').toString().trim().length === 0
    )
        return { saveButtonDisabled: true }

    return {
        saveButtonDisabled: false
    }

}

const form = reduxForm({
    form: formName,
    enableReinitialize: true
})(EditLineItemsForm);

export default connect(mapState)(form);