import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button } from 'react-bootstrap';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Loading from '../../../ui/Loading';
import CustomersLocationsSearchResults from './CustomersLocationsSearchResults';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCreateLocation from './ModalCreateLocation';
import ModalImportLocation from './ModalImportLocation';
import Notification from '../../../ui/Notification';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '25',
  sortBy: sortBy || 'locationName',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

class CustomersLocationsSearch extends Component {
  constructor(props) {
    super(props);
    const { history, location: { pathname, search } } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchCustomersLocations = this.handleSearchCustomersLocations.bind(
      this
    );
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }
  componentDidMount() {
    // const { location: { search } } = this.props;
    // const query = getQueryFromSearch(search);
    // const qs = queryString.stringify(query);
    this.props.searchCustomersLocations();
    this.props.searchCustomersCompanies();
  }
  // componentWillReceiveProps(nextProps) {
  //   const { location: { search } } = nextProps;
  //   const { location: { search: search2 } } = this.props;

  //   if (search === search2) return;

  //   const query = getQueryFromSearch(search);
  //   const qs = queryString.stringify(query);
  //   this.props.searchCustomersLocations(qs);
  // }
  handleSearchCustomersLocations(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchCustomersLocations({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchCustomersLocations(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }
  render() {
    const {
      inProgress,
      result,
      location: { search },
      showModal,
      postLocation,
      getCompanyResults,
      selectedCompany,
      getCompanyById,
      getCompanyByIdResult,
      searchCustomersLocationsError,
      searchCustomersCompaniesError,
      clearSearchCustomersCompanies,
      clearSearchCustomersLocations,
      uploadLocationsError,
      clearUploadLocations
    } = this.props;

    const paging = getQueryFromSearch(search);
    const { search: locationSearch = '' } = paging;

    const filteredResults = (result || []).filter((location) => {
      const {
        locationName,
        address1,
        address2,
        city,
        companyName,
        postalCode,
        stateProvince
      } = location;

      if (
        (locationName || '').indexOf(locationSearch) >= 0 ||
        (address1 || '').indexOf(locationSearch) >= 0 ||
        (address2 || '').indexOf(locationSearch) >= 0 ||
        ((city || '').indexOf(locationSearch) >= 0) ||
        ((companyName || '').indexOf(locationSearch) >= 0) ||
        ((postalCode || '').indexOf(locationSearch) >= 0) ||
        ((stateProvince || '').indexOf(locationSearch) >= 0)
      ) {
        return location;
      }
    })

    const {
      companyUsers
    } = getCompanyByIdResult || {};

    return (
      <Layout route="customers-locations-search" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <h2>
                Locations
              </h2>
              <h3>
                {!inProgress && <span>{(result || []).length} Locations</span>}
              </h3>
            </div>
            <div>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => showModal('modalImportCustomersLocation')}
              >
                Upload Locations
              </Button>
              {' '}
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalCreateCustomersLocation')}
              >
                Create Location
              </Button>
            </div>
          </Container>
        </div>
        {!inProgress &&
          <Container>
            <Box className="box-table">
              <CustomersLocationsSearchResults
                paging={paging}
                result={filteredResults}
                error={searchCustomersLocationsError}
                onFetchData={this.handleTableChange}
                handleSearchChange={this.handleSearchChange}
                inProgress={inProgress}
              />
            </Box>
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalCreateLocation
          name="modalCreateCustomersLocation"
          getCompanyResults={getCompanyResults}
        />
        <ModalImportLocation name="modalImportCustomersLocation" />
        {searchCustomersLocationsError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersLocations}
          >
            Locations failed to load. Please refresh the page.
          </Notification>}
        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}

        {uploadLocationsError &&
          <Notification
            key="upload-locations-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearUploadLocations}
          >
            Error uploading locations, Please try again.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result: getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    result,
    inProgress: searchCustomersLocationsInProgress,
    error: searchCustomersLocationsError
  } = state.searchCustomersLocations.toJS();

  const {
    error: uploadLocationsError
  } = state.uploadLocations.toJS();

  return {
    result,
    getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress ||
      searchCustomersLocationsInProgress,
    searchCustomersLocationsError,
    searchCustomersCompaniesError,
    uploadLocationsError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction,
      searchCustomersLocations: fetchDux.searchCustomersLocations.createAction,
      clearSearchCustomersLocations: fetchDux.searchCustomersLocations.clearAction,
      clearUploadLocations: fetchDux.uploadLocations.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersLocationsSearch);
