import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import VendorSelectForm from './VendorSelectForm';
import { connect } from 'react-redux';
import { isValid, isSubmitting } from 'redux-form';

const POHeader = (
  {
    editing,
    showEdit,
    c_Approved,
    inProgress,
    c_SelectedPurchaseOrderSageVendorID,
    handleApproveOrder,
    VendorName,
    VendorNum,
    vendorOptions,
    vendorSelectFormValid,
    vendorSelectFormSubmitting,
    submitVendorSelectForm,
    c_IsColorado,
    showVendor,
    formProps
  }
) => {
  if (!showEdit && !showVendor) return <div />;

  const { length: numOptions } = vendorOptions || [];

  const handleSubmit = values => handleApproveOrder(values);

  if (c_Approved && showEdit)
    return (
      <div className="project-info-label green">
        <Icon name="check-circle" />
        <div>
          {VendorNum} ({VendorName})
          {c_IsColorado &&
            <span
              style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px' }}
            >
              [ORIGIN IS COLORADO]
            </span>}
        </div>
      </div>
    );

  if (!c_SelectedPurchaseOrderSageVendorID && !numOptions)
    return (
      <div className="project-info-label red">
        <Icon name="check-circle" />
        <div>
          MISSING VENDOR
        </div>
        <div>
          Next Steps: Please contact an administrator
        </div>
      </div>
    );

  if (!c_SelectedPurchaseOrderSageVendorID && !!numOptions)
    return (
      <div
        className={
          `project-info-label ${vendorSelectFormValid ? 'green' : 'red'}`
        }
      >
        <Button
          variant="success"
          size="sm"
          onClick={submitVendorSelectForm}
          // disabled={
          //   inProgress ||
          //     c_Approved ||
          //     // !c_SelectedPurchaseOrderSageVendorID ||
          //     editing ||
          //     !vendorSelectFormValid ||
          //     vendorSelectFormSubmitting
          // }
        >
          Approve PO
        </Button>
        <Icon name="check-circle" />
        <div>
          {numOptions === 1 ? 'PLEASE ASSIGN VENDOR' : 'MULTIPLE VENDORS FOUND'}
        </div>
        <div>Next Steps: Select vendor and Approve</div>
        <VendorSelectForm
          vendorOptions={vendorOptions}
          onSubmit={handleSubmit}
          {...formProps || {}}
        />
      </div>
    );

  if (!showEdit) return <div />;
  return (
    <div className="project-info-label green">
      <ButtonGroup style={{ float: 'right' }}>
        {!c_Approved &&
          <Button
            variant="success"
            size="sm"
            onClick={() => handleApproveOrder({})}
            disabled={
              inProgress ||
                c_Approved ||
                !c_SelectedPurchaseOrderSageVendorID ||
                editing
            }
          >
            Approve PO
          </Button>}
      </ButtonGroup>
      <Icon name="check-circle" />
      <div>
        {VendorNum} ({VendorName})
        {c_IsColorado &&
          <span
            style={{ color: 'white', fontWeight: 'bold', marginLeft: '10px' }}
          >
            [ORIGIN IS COLORADO]
          </span>}
      </div>
      <div>Next Steps: Approve Purchase Order</div>
    </div>
  );
  // return <div />;
};

const mapState = (state, props) => {
  const formName = props.formProps && props.formProps.form
    ? props.formProps.form
    : 'vendor-select-form';
  const vendorSelectFormValid = isValid(formName)(state);
  const vendorSelectFormSubmitting = isSubmitting(formName)(state);

  return {
    vendorSelectFormValid,
    vendorSelectFormSubmitting
  };
};

export default connect(mapState)(POHeader);
