import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { submit } from 'redux-form';
import { actionCreators } from '../../../../../state/modals-dux';
import moment from 'moment';
import EditProjectShippingForm
  from './../../projects-detail/EditProjectShippingForm';

const formatShipDate = value => {
  if (!value || !moment(value).isValid()) return null;
  return moment(value).format('YYYY-MM-DD');
};

const getInitialValues = (
  {
    c_WarehouseID,
    c_ShipViaId,
    c_ShipDate,
    c_SpecialInstructions,
    Name,
    FirstName,
    LastName,
    PhoneContact,
    PhoneFax,
    Address1,
    Address2,
    City,
    State,
    Zip,
    c_Zip_4,
    c_AttnNameOnly
  }
) => ({
  c_WarehouseID: c_ShipViaId ? c_WarehouseID || 'other' : null,
  c_ShipViaId,
  c_ShipDate,
  c_SpecialInstructions,
  Name,
  FirstName,
  LastName,
  PhoneContact,
  PhoneFax,
  Address1,
  Address2,
  City,
  State,
  Zip,
  c_Zip_4,
  c_AttnNameOnly: `${!!c_AttnNameOnly}`
});

const SubmitButtonHtml = ({ dispatch }) => (
  <Button
    variant="success"
    style={{ float: 'right' }}
    onClick={() => dispatch(submit('shipping-edit-po-address'))}
  >
    Continue
  </Button>
);

const SubmitButton = connect()(SubmitButtonHtml);

class ModalEditShippingInfo extends Component {
  render() {
    const {
      openModal,
      name,
      closeModal,
      purchaseOrder,
      shippingAddress,
      lookups,
      handleSubmit
    } = this.props;

    const initialValues = getInitialValues(shippingAddress || {});

    const handleEditSubmit = values => {
      const { c_AttnNameOnly, c_ShipDate, ...rest } = values || {};
      handleSubmit({
        c_AttnNameOnly: c_AttnNameOnly === 'true',
        c_ShipDate: formatShipDate(c_ShipDate),
        ...rest
      });
      closeModal();
    };

    return (
      <Modal show={openModal === name} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            Edit Shipping Information
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <EditProjectShippingForm
            initialValues={initialValues}
            enableReinitialize
            lookups={lookups}
            hideButtons
            form="shipping-edit-po-address"
            onSubmit={handleEditSubmit}
          />

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <SubmitButton />
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalEditShippingInfo);
