import React from "react";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import fetchDux from "../../../../state/fetch-dux";
import { actionCreators } from "../../../../state/modals-dux";
import CouponForm from "./CouponForm";

const ModalCreateCoupon = ({ name, openModal, inProgress, closeModal, createCoupon }) => {
    const closeHandler = () => {
        if (!inProgress) closeModal();
    }
    return (
        <Modal
            show={name === openModal}
            onHide={closeHandler}
        >
            <Modal.Header>
                <Modal.Title>
                    Create Coupon
                </Modal.Title>
            </Modal.Header>
            <CouponForm
                closeHandler={closeHandler}
                onSubmit={
                    (values) => {
                        createCoupon(values, 'CuponForm', null, () => {
                            closeHandler();
                        })
                    }
                }
            />
        </Modal>
    )
}

const mapState = state => {

    return {
        openModal: state.modals
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        ...actionCreators,
        createCoupon: fetchDux.createCoupon.createAction
    }, dispatch)

export default connect(mapState, mapDispatch)(ModalCreateCoupon);