import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ShipmentsGroupDetail from './groups/ShipmentsGroupDetail';

export default ({ match }) => (
    <Switch>
        <Route exact path={`${match.url}`} component={ShipmentsGroupDetail} />
        <Route exact path={`${match.url}/:poId/:type/:data`} component={ShipmentsGroupDetail} />
        <Route exact path={`${match.url}/:poId`} component={ShipmentsGroupDetail} />
        <Redirect to={`${match.url}`} />
    </Switch>
);
