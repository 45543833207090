import React from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import statusDict from './statusDict';

const required = value => value ? undefined : 'Required';

const renderSteps = ({ fields, meta: { error, submitFailed } }) => {
  return (
    <div>
      {fields.map((step, index) => (
        <div key={index} className="step" style={{ borderColor: 'gray' }}>
          <Row key={index}>
            <Col className="step-number">
              <span>{index + 1}</span>
            </Col>
            <Col md={3}>
              <Field
                required
                name={`${step}.status`}
                type="text"
                component={FormField.SelectReact}
                label="Status"
                placeholder=""
                validate={[required]}
                insetLabel
                options={[
                  {
                    value: statusDict.PENDING,
                    label: statusDict.PENDING
                  },
                  {
                    value: statusDict.IN_PROGRESS,
                    label: statusDict.IN_PROGRESS
                  },
                  {
                    value: statusDict.COMPLETE,
                    label: statusDict.COMPLETE
                  },
                  {
                    value: statusDict.JEOPARDY,
                    label: statusDict.JEOPARDY
                  }
                ]}
              />
            </Col>
            <Col md={3}>
              <Field
                required
                name={`${step}.title`}
                type="text"
                component={FormField.Input}
                label="Title"
                insetLabel
                validate={[required]}
              />
            </Col>
            <Col md={4}>
              <Field
                name={`${step}.comment`}
                type="text"
                component={FormField.Input}
                insetLabel
                label="Comments"
              />
            </Col>
            <Col md={2} />
            <Col md={3} className="no-margin">
              <Field
                required
                name={`${step}.dateEst`}
                type="text"
                component={FormField.Datepicker}
                label="Date Est"
                insetLabel
              />
            </Col>
            <Col md={3} className="no-margin">
              <Field
                required
                name={`${step}.dateComplete`}
                type="text"
                insetLabel
                component={FormField.Datepicker}
                label="Date Complete"
              />
            </Col>
            <Col md={4} className="step-actions">

              <Button
                size="sm"
                type="button"
                onClick={() => fields.remove(index)}
                disabled={fields.length < 2}
              >
                <Icon name="trash" />
              </Button>

              <Button
                size="sm"
                type="button"
                disabled={fields.length < 2 || index === 0}
                onClick={() => fields.swap(index, index - 1)}
              >
                <Icon name="arrow-up" />
              </Button>

              <Button
                size="sm"
                type="button"
                disabled={fields.length < 2 || index === fields.length - 1}
                onClick={() => fields.swap(index, index + 1)}
              >
                <Icon name="arrow-down" />
              </Button>

            </Col>
          </Row>
        </div>
      ))}
      <div className="add-step" onClick={() => fields.push({})}>
        <Icon name="plus" /> Add Step
      </div>
    </div>
  );
};

const ConfigureTrackerForm = props => {
  const {
    submitting,
    handleSubmit,
    handleCancel
  } = props;

  return (
    <form onSubmit={handleSubmit} className="configure-status-tracker-form">

      <FieldArray name="statuses" component={renderSteps}/>

      <div className="actions">
        <Button
          variant="outline-secondary"
          type="button"
          onClick={handleCancel}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          Save
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'configure-status-tracker-location-form',
  enableReinitialize: true
})(ConfigureTrackerForm);
