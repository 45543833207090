import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import ImportPackageForm from './ImportPackageForm';

class ModalCustomersCompanyImportPackage extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmit
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-alt"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Import Package</Modal.Title>
        </Modal.Header>
        <ImportPackageForm />
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(
  ModalCustomersCompanyImportPackage
);
