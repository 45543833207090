import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import ShareQuoteForm from './ShareQuoteForm';
import { change } from 'redux-form';

class ModalShareQuote extends Component {
  getInitialValues = ({ customer = {}, items = [], Name, users, TaxToBeDetermined, taxableSubTotal, SalesTax, SubTotal }) => {
    const tax = TaxToBeDetermined ? 0 : taxableSubTotal * (SalesTax / 100);
    const grandTotal = SubTotal + tax;
    const getCount = items => {
      let itemsCount = 0;
      // let sellingPrice = 0;
      items.forEach(item => {
        itemsCount += item.Quantity;
        // sellingPrice += (sellingPrice += item.SellPrice);
      });
      return { itemsCount };
    };
    return {
      firstName: customer.NameFirst,
      lastName: customer.NameLast,
      customerId: customer.ID,
      quoteName: Name,
      users,
      grandTotal,
      ...getCount(items)
    };
  };

  
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmit,
      data,
      handleChange
    } = this.props;
    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Quote</Modal.Title>
        </Modal.Header>
        <ShareQuoteForm
          initialValues={this.getInitialValues(data)}
          onSubmit={handleSubmit}
          closeModal={closeModal}
          handleChange={handleChange}
        />
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      handleChange: change
    }
    , dispatch);

export default connect(mapState, mapDispatch)(ModalShareQuote);
