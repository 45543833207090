import React, { Component } from 'react';
import numeral from 'numeral';
import Table from '../../../ui/Table';

const formatMoney = value => numeral(value).format('0,0.00');

const columns = [
  {
    Header: 'Vendor',
    accessor: 'Name'
  },
  {
    Header: 'Notes',
    accessor: 'VendorNotes'
  },
  {
    Header: 'Net',
    accessor: 'SumSellPrice',
    className: 'text-right',
    width: 125,
    Cell: ({ value }) => <span>${formatMoney(value)}</span>
  },
  {
    Header: 'Rep Group',
    accessor: 'RepGroup'
  },
  {
    Header: 'Vendor No.',
    accessor: 'VendorNo',
    show: false
  },
  {
    Header: 'Discount',
    accessor: 'Discount',
    show: false
  },
  {
    Header: 'Email Address',
    accessor: 'PreferredEmail',
    show: false
  }
];

class VendorsTable extends Component {
  shouldComponentUpdate(nextProps) {
    const { show } = nextProps || {};
    const { show: show2 } = this.props;
    const different = show !== show2;
    return !!show || different;
  }
  render() {
    const {
      show,
      data,
      handleSearchChange,
      search
    } = this.props;

    return (
      <div
        className="project-items-grid"
        style={show ? {} : { display: 'none' }}
      >
        <Table
          showSearch
          showColumnsSelector
          search={search}
          onSearchChange={value => handleSearchChange('vendorsSearch', value)}
          data={data || []}
          columns={columns}
          defaultSorted={[
            {
              id: 'Name',
              desc: false
            }
          ]}
          noDataText="No vendors found"
        />
      </div>
    );
  }
}

export default VendorsTable;
