import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';

const ModalConfirmOrderInsert = (
  {
    openModal,
    name,
    closeModal,
    onSubmit
  }
) => {
  return (
    <Modal show={openModal === name} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>Insert Orders and Create POs</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '14px' }}>
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          Are you sure you want to import orders?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={closeModal}
        >
          Cancel
        </Button>
        <Button type="button" size="sm" variant="success" onClick={onSubmit}>
          Yes, import orders
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalConfirmOrderInsert);
