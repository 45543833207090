import React from 'react';
import { Modal, Button, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';

const ModalSageImportErrors = (
  {
    openModal,
    name,
    closeModal,
    result
  }
) => {
  const { importToSageErrors } = result || {};
  const {
    lineItemErrors,
    projectError,
    purchaseOrderErrors
  } = importToSageErrors || {};

  return (
    <Modal show={openModal === name} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          &nbsp;
          Sage Import Errors
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {projectError &&
          <div style={{ textAlign: 'center' }}>
            Unable to import project to Sage.
            <div style={{ color: '#C23934', marginTop: '10px' }}>
              <Icon name="exclamation-triangle" />
              &nbsp;
              &nbsp;
              Error - {projectError.c_Sage_Message} ({projectError.c_Sage_ID})
            </div>
          </div>}
        {
          !projectError
          &&
          purchaseOrderErrors
          &&
          <div style={{ textAlign: 'center' }}>
            Unable to import project to Sage.
            {
              purchaseOrderErrors.map((item, index) => {
                const { c_Sage_Message } = item
                return (
                  <div key={index} style={{ color: '#C23934', marginTop: '10px' }}>
                    <Icon name="exclamation-triangle" style={{ marginRight: '1rem' }} />
                    Error - {c_Sage_Message}
                  </div>
                )
              })
            }
          </div>
        }
        {/* <Table>
          <thead>
            <tr>
              <th>Field</th>
              <th>ID</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {(errors || []).map(({ field, id, message }) => (
              <tr>
                <td>{field}</td>
                <td>{id}</td>
                <td>{message}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={closeModal}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalSageImportErrors);
