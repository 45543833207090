import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { Container, Button, Dropdown, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';
import Table from '../../../ui/Table';
import { actionCreators } from '../../../../state/modals-dux';

import ModalCustomersEditLocation
  from '../customers-locations/ModalEditLocation';
import ModalCustomersDeleteLocation
  from '../customers-locations/ModalDeleteLocation';
import ModalLocationUploadDocuments
  from '../customers-locations-detail/ModalLocationUploadDocuments';
import ModalDeleteLocationDocument
  from '../customers-locations-detail/ModalDeleteLocationDocument';
import Notification from '../../../ui/Notification';
import { downloadLocationDocument } from '../../../../api/api';

const getDesc = desc => {
  if (desc === true || desc === 'true') return 'true';
  return 'false';
};

const formatQuery = (
  {
    page,
    pageSize,
    sortBy,
    desc,
    search
  }
) => ({
  page: page || '0',
  pageSize: pageSize || '20',
  sortBy: sortBy || 'Filename',
  desc: getDesc(desc),
  search: search || ''
});

const getQueryFromSearch = search => {
  const query = queryString.parse(search) || {};
  return formatQuery(query);
};

const getColumns = ({ getDownloadFileDetails, getCheckedCells }) => {
  return [
    {
      Header: '',
      width: 50,
      Cell: ({ original }) => (
        <input
          type="checkbox"
          defaultChecked={original.checked}
          onClick={event => getCheckedCells(original, event)}
        />
      ),
      resizable: false,
      id: 'header'
    },
    {
      Header: 'Name',
      Cell: ({ original }) => (
        <a
          href={original.metadata.name}
          target="_blank"
          onClick={e => {
            getDownloadFileDetails(original, e);
          }}
        >
          {original.metadata.name}
        </a>
      ),
      id: 'Name'
    },
    {
      Header: 'Created',
      accessor: 'uploadDate',
      Cell: ({ value }) => <div>{moment(value).format('MMMM Do YYYY')}</div>,
      id: 'Created'
    },
    {
      Header: 'Size',
      accessor: 'length', //file size
      Cell: ({ value }) => <div>{humanFileSize(value, true)}</div>,
      id: 'Size'
    },
    {
      Header: 'Kind',
      Cell: ({ value, original }) => <div>{original.metadata.kind}</div>, //file type
      id: 'Kind'
    }
  ];
};

const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

class CustomersLocationsDocuments extends Component {
  constructor(props) {
    super(props);
    const {
      history,
      deleteLocationDocuments,
      getLocationById,
      match,
      location: { pathname, search }
    } = this.props;
    const query = getQueryFromSearch(search);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
    this.handleSearchUrl = this.handleSearchUrl.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.state = {
      selectedDocuments: []
    };
  }

  componentDidMount() {
    const {
      match,
      getLocationById,
      searchCustomersCompanies
    } = this.props;
    const { id } = match.params || {};
    getLocationById({ id, query: { getLocationDocuments: 1 } }, null, true);
    searchCustomersCompanies();
  }
  handleSearchUrl(values) {
    const { history, location: { pathname } } = this.props;

    const query = formatQuery(values);
    const qs = queryString.stringify(query);

    history.push({ pathname, search: qs });
  }
  handleTableChange(state) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    const {
      page,
      pageSize,
      sorted
    } = state || {};

    const [sort] = sorted || [];

    const {
      id: sortBy,
      desc
    } = sort || {};

    this.handleSearchUrl({
      page,
      pageSize,
      sortBy,
      desc,
      search: query.search
    });
  }
  handleSearchChange(value) {
    const { location: { search } } = this.props;
    const query = getQueryFromSearch(search);
    this.handleSearchUrl(
      Object.assign({}, query, { search: value, page: '0' })
    );
  }

  uploadLocationDocuments = values => {
    const { csv } = values;
    const {
      uploadLocationDocument,
      match: { params: { id } },
      result,
      closeModal,
      searchCustomersCompanies,
      getLocationById
    } = this.props;

    const { company } = result || {};
    const { companyID } = company || {};

    uploadLocationDocument({ csv, locationId: id, companyID }, '', null, () => {
      closeModal('modalUploadLocationDocument');
      getLocationById({ id, query: { getLocationDocuments: 1 } }, null, true);
      searchCustomersCompanies();
    });
  };

  render() {
    const {
      inProgress,
      getLocationByIdError,
      clearGetLocationById,
      result,
      showModal,
      match,
      getCompanyResults,
      history,
      clearSearchCustomersCompanies,
      searchCustomersCompaniesError,
      deleteLocationDocuments,
      clearDeleteLocationDocuments,
      getLocationById,
      location: { search: searchURL }
    } = this.props;

    const {
      location,
      company,
      users,
      created,
      documents
    } = result || {};

    const {
      name,
      address1,
      address2,
      city,
      postalCode,
      stateProvince
    } = location || {};

    const {
      companyName,
      companyID
    } = company || {};

    const { id: locationId } = match.params || {};

    const fullAddressTop = `${address1 || ''} ${address2 || ''}`;
    const fullAddressBottom = `${city || ''}, ${stateProvince || ''} ${postalCode || ''}`;

    const formattedDate = moment(created).format('MMMM Do YYYY');

    const paging = getQueryFromSearch(searchURL);

    const {
      sortBy,
      desc,
      page,
      pageSize,
      search
    } = paging || {};

    const sorted = [
      {
        id: sortBy,
        desc: desc === 'true'
      }
    ];

    const getDownloadFileDetails = (original, e) => {
      e.preventDefault();
      const { _id } = original;
      downloadLocationDocument({ id: _id, payload: original });
    };

    const getCheckedCells = (original, event) => {
      const { _id } = original;
      if (event.target.checked) {
        let selectedDocuments = this.state.selectedDocuments;
        selectedDocuments.splice(0, 0, { id: _id });
        this.setState({ selectedDocuments: selectedDocuments });
      } else {
        let selectedDocuments = this.state.selectedDocuments;
        selectedDocuments = selectedDocuments.filter(doc => doc.id !== _id);
        this.setState({ selectedDocuments: selectedDocuments });
      }
    };

    const columns = getColumns({ getDownloadFileDetails, getCheckedCells });

    const handleDeleteLocationDoc = () => {
      let selectedDocuments = this.state.selectedDocuments;
      if (selectedDocuments.length === 0) return;

      const { id } = match.params || {};
      deleteLocationDocuments({ selectedDocuments }, null, null, () => {
        this.setState({
          selectedDocuments: []
        });
        getLocationById({ id, query: { getLocationDocuments: 1 } }, null, true);
      });
    };

    const renderDocuments = (documents || []).map(document => {
      const selectedDocuments = this.state.selectedDocuments;
      const selectedDoc = selectedDocuments.find(
        doc => doc.id === document._id
      );
      return selectedDoc ? { ...document, checked: true } : document;
    });

    return (
      <Layout route="customers-locations-detail" alt>
        <div className="layout-app-header">
          <Container>
            <div>
              <Link to="/customers/locations" className="back">
                <Icon name="arrow-left" />
              </Link>
              <h3>
                Location
              </h3>
              <h2>
                {name || 'N/A'}
              </h2>
            </div>
            <div>
              <Button
                variant="danger"
                size="sm"
                onClick={() => showModal('modalDeleteLocationDocument')}
              >
                Delete
              </Button>
              {' '}
              <Button
                variant="primary"
                size="sm"
                onClick={() => showModal('modalUploadLocationDocument')}
              >
                Upload
              </Button>
              {' '}
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary" size="sm">
                  <Icon fixedWidth name="ellipsis-h" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersLocationEdit')}
                  >
                    Edit Location
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => showModal('modalCustomersLocationDelete')}
                  >
                    Delete Location
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Container>
        </div>
        <div className="box-page-tabs">
          <Container>
            <ul>
              <li>
                <Link to={`/customers/locations/${locationId}/details`}>
                  Details
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/project-tracker`}>
                  Project Tracker
                </Link>
              </li>
              <li>
                <Link to={`/customers/locations/${locationId}/users`}>
                  Users
                </Link>
              </li>
              <li className="active">
                <Link to={`/customers/locations/${locationId}/documents`}>
                  Documents
                </Link>
              </li>
            </ul>
          </Container>
        </div>

        {!inProgress &&
          <Container>
            <Table
              showSearch
              data={renderDocuments}
              noDataText="No documents found"
              columns={columns}
              search={search}
              onFetchData={this.handleTableChange}
              onSearchChange={this.handleSearchChange}
              defaultSorted={sorted}
              page={getLocationByIdError ? 0 : Number(page)}
              defaultPageSize={Number(pageSize)}
              inProgress={inProgress}
            />
          </Container>}

        {inProgress &&
          <Loading
            fullPage
            color="#04844B"
            message="Loading, Please Wait..."
          />}

        <ModalLocationUploadDocuments
          name="modalUploadLocationDocument"
          locationId={locationId}
          companyID={companyID}
          uploadLocationDocuments={this.uploadLocationDocuments}
        />
        <ModalDeleteLocationDocument
          name="modalDeleteLocationDocument"
          handleDeleteLocationDoc={handleDeleteLocationDoc}
        />
        <ModalCustomersEditLocation
          name="modalCustomersLocationEdit"
          location={location}
          getCompanyResults={getCompanyResults}
          companyID={companyID}
        />
        <ModalCustomersDeleteLocation
          name="modalCustomersLocationDelete"
          location={location}
          history={history}
        />
        {getLocationByIdError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearGetLocationById}
          >
            Failed to load location details. Please refresh the page.
          </Notification>}
        {searchCustomersCompaniesError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearSearchCustomersCompanies}
          >
            Form options failed to load. Please refresh the page.
          </Notification>}
      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getLocationByIdInProgress,
    error: getLocationByIdError
  } = state.getLocationById.toJS();

  const {
    result: getCompanyResults,
    inProgress: searchCustomersCompaniesInProgress,
    error: searchCustomersCompaniesError
  } = state.searchCustomersCompanies.toJS();

  const {
    result: deleteLocationDocumentsResult,
    inProgress: deleteLocationDocumentsInProgress,
    error: deleteLocationDocumentsError
  } = state.deleteLocationDocuments.toJS();

  return {
    result,
    getCompanyResults,
    inProgress: getLocationByIdInProgress,
    getLocationByIdError,
    searchCustomersCompaniesError,
    deleteLocationDocumentsError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      getLocationById: fetchDux.getLocationById.createAction,
      clearGetLocationById: fetchDux.getLocationById.clearAction,
      searchCustomersCompanies: fetchDux.searchCustomersCompanies.createAction,
      clearSearchCustomersCompanies: fetchDux.searchCustomersCompanies.clearAction,
      deleteLocationDocuments: fetchDux.deleteLocationDocuments.createAction,
      clearDeleteLocationDocuments: fetchDux.deleteLocationDocuments.clearAction,
      uploadLocationDocument: fetchDux.uploadLocationDocument.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(CustomersLocationsDocuments);
