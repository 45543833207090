import React from 'react';
import { Field } from 'redux-form';
import { Row, Col, Button } from 'react-bootstrap';
import FormField from '../../../forms/FormField';
import AccordionCard from './AccordionCard';

export default (
  {
    handleUpdateNotes,
    setOpen,
    openTabs
  }
) => (
  <AccordionCard
    controlled
    title="Vendor Information"
    setOpen={setOpen}
    open={openTabs['vendorInformation']}
    name="vendorInformation"
  >
    {/* Vendor */}
    <Row>
      <Col md={6}>
        <Field
          type="text"
          name="VendorOrderNum"
          label="Vendor Order Number:"
          component={FormField.Input}
        />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field
          type="text"
          name="SchdShipDate"
          label="Scheduled Ship Date:"
          isUTC
          component={FormField.Datepicker}
        />
      </Col>
      <Col md={6}>
        <Field
          type="text"
          name="SchdShipDatePerson"
          label="Contact Name:"
          component={FormField.Input}
        />
        <Field
          type="text"
          name="SchdShipDatePersonDate"
          label="Contact Date:"
          isUTC
          component={FormField.Datepicker}
        />
      </Col>
    </Row>
    <Row>
      <Col md={6}>
        <Field
          type="text"
          name="ActualShipDate"
          label="Actual Ship Date:"
          isUTC
          component={FormField.Datepicker}
        />
      </Col>
      <Col md={6}>
        <Field
          type="text"
          name="ActualShipDatePerson"
          label="Contact Name:"
          component={FormField.Input}
        />
        <Field
          type="text"
          name="ActualShipDatePersonDate"
          label="Contact Date:"
          isUTC
          component={FormField.Datepicker}
        />
      </Col>
    </Row>

    <Button variant="secondary" size="sm" onClick={handleUpdateNotes}>
      Copy Vendor Contact Info into Notes
    </Button>
  </AccordionCard>
);
