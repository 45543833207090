import React, { useEffect, useState } from 'react';
import numeral from 'numeral';
import Icon from '../../../ui/Icon';

const QuoteItemsRow = props => {
  const { row, expandAll } = props || {};
  const [isOpen, toggle] = useState(false);
  const toggleIcon = isOpen ? 'chevron-down' : 'chevron-right';
  useEffect(() => {
    toggle(expandAll)
  }, [expandAll]);
  return (
    <tbody>
      <tr className={isOpen ? 'active' : ''}>
        <td className="toggle">
          {(row.lineItems || []).length > 0 &&
            <a onClick={() => toggle(!isOpen)}>
              <Icon fixedWidth name={toggleIcon} />
            </a>}
        </td>
        <td>{row.ItemNumber}</td>
        <td>{row.Quantity}</td>
        <td>{row.Manufacturer}</td>
        <td>{row.Model}</td>
        <td>{row.Category}</td>
        <td>{row.Spec}</td>
        <td>${numeral(row.UnitPrice).format('0,0.00')}</td>
        <td>${numeral(row.SellPrice).format('0,0.00')}</td>
        <td />
        <td />
      </tr>
      {isOpen &&
        <tr className="items">
          <td colSpan={11}>
            <table>
              <tbody>
                {(row.lineItems || []).map((item, index) => {
                  return (
                    <tr key={index}>
                      <td width="34" />
                      <td width="90"></td>
                      <td width="60">{item.Quantity}</td>
                      <td><b>Description:</b> {item.c_ItemDescription}</td>
                      <td width="110">
                        ${numeral(item.SellPrice).format('0,0.00')}
                      </td>
                      <td width="110">{item.status}</td>
                      <td width="80">
                        {item.Taxable &&
                          <span>
                            <Icon
                              fixedWidth
                              name="check"
                              className="text-success"
                            />
                            {' '}
                            Taxable
                          </span>}
                        {!item.Taxable &&
                          <span>
                            <Icon
                              fixedWidth
                              name="times"
                              className="text-danger"
                            />
                            {' '}
                            Taxable
                          </span>}
                      </td>
                      <td width="120">
                        {item.Show &&
                          <span>
                            <Icon
                              fixedWidth
                              name="check"
                              className="text-success"
                            />
                            {' '}
                            Show On Web
                          </span>}
                        {!item.Show &&
                          <span>
                            <Icon
                              fixedWidth
                              name="times"
                              className="text-danger"
                            />
                            {' '}
                            Show On Web
                          </span>}
                      </td>

                      <td width="120">
                        {item.ShowOnPDF &&
                          <span>
                            <Icon
                              fixedWidth
                              name="check"
                              className="text-success"
                            />
                            {' '}
                            Show On PDF
                          </span>}
                        {!item.ShowOnPDF &&
                          <span>
                            <Icon
                              fixedWidth
                              name="times"
                              className="text-danger"
                            />
                            {' '}
                            Show On PDF
                          </span>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </td>
        </tr>}
    </tbody>
  );
};

const displayAddToIcon = s => s ? 'check-circle' : 'times-circle';

const QuoteItems = ({ data = {}, expandAll }) => (
  <div className="quote-items">
    <table>
      <thead>
        <tr>
          <th width="34" />
          <th width="90">Item No</th>
          <th width="60">Qty</th>
          <th>Manufacturer</th>
          <th>Model No</th>
          <th>Category</th>
          <th>Spec</th>
          <th width="110">Unit Price</th>
          <th width="110">Extended Price</th>
          <th width="80">Taxable</th>
          <th width="70" />
        </tr>
        <tr>
          <td colSpan={3} />
          <td>
            <Icon name={displayAddToIcon(data.ManufacturerAddToWeb)} />
            {' '}
            Add To Web
          </td>
          <td>
            <Icon name={displayAddToIcon(data.ModelNoAddToWeb)} />
            {' '}
            Add To Web
          </td>
          <td>
            <Icon name={displayAddToIcon(data.CategoryAddToWeb)} />
            {' '}
            Add To Web
          </td>
          <td>
            <Icon name={displayAddToIcon(data.SpecAddToWeb)} />
            {' '}
            Add To Web
          </td>
          <td colSpan={4} />
        </tr>
        <tr>
          <td colSpan={3} />
          <td>
            <Icon name={displayAddToIcon(data.ManufacturerAddToPdf)} />
            {' '}
            Add To Pdf
          </td>
          <td>
            <Icon name={displayAddToIcon(data.ModelNoAddToPdf)} />
            {' '}
            Add To Pdf
          </td>
          <td>
            <Icon name={displayAddToIcon(data.CategoryAddToPdf)} />
            {' '}
            Add To Pdf
          </td>
          <td>
            <Icon name={displayAddToIcon(data.SpecAddToPdf)} />
            {' '}
            Add To Pdf
          </td>
          <td colSpan={4} />
        </tr>
      </thead>
      {(data.items || [])
        .map((item, index) => <QuoteItemsRow key={index} row={item} expandAll={expandAll} />)}
    </table>
  </div>
);

export default QuoteItems;
