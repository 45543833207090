import React, { useState } from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';

const ModalDeleteShipment = (
  {
    name,
    openModal,
    closeModal: onCloseModal,
    error,
    inProgress,
    onDeleteShipment
  }
) => {
  const [option, setOption] = useState(null);
  const closeModal = () => {
    setOption(null);
    onCloseModal();
  };
  return (
    <Modal
      show={openModal === name}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          &nbsp;
          Delete Shipment
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!option &&
          <div>
            <p style={{ textAlign: 'center' }}>
              Would you like to delete the current shipment, or remove the Purchase Order from SBT?
            </p>
            <div style={{ textAlign: 'center' }}>
              <Col sm="auto">
                <Button
                  type="button"
                  size="sm"
                  variant="danger"
                  onClick={() => setOption('shipment')}
                  disabled={inProgress}
                  style={{ marginLeft: '10px' }}
                >
                  Delete Shipment
                </Button>
                <Button
                  type="button"
                  size="sm"
                  variant="danger"
                  onClick={() => setOption('po')}
                  disabled={inProgress}
                  style={{ marginLeft: '10px' }}
                >
                  Delete Purchase Order
                </Button>
              </Col>
            </div>
          </div>}
        {option === 'po' &&
          <Col sm="12" style={{ color: '#C23934', textAlign: 'center' }}>
            <p>Are you sure you want to delete Purhcase order from SBT</p>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>}
        {option === 'shipment' &&
          <Col sm="12" style={{ color: '#C23934' }}>
            <p>Are you sure you want to delete Shipment from SBT</p>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>}
        {inProgress && <Loading />}
        {error &&
          <div
            style={{
              color: '#C23934',
              textAlign: 'center',
              padding: '20px 60px 20px 0'
            }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            {error.message || 'An error occurred'}
          </div>}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'normal' }}>
        {/* <Row>
          <Col sm="12" style={{ color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>
        </Row> */}
        <Row>
          <Col sm="auto">
            <Button
              type="button"
              size="sm"
              variant="secondary"
              onClick={closeModal}
              disabled={inProgress}
            >
              Cancel
            </Button>
            {option === 'shipment' &&
              <Button
                type="button"
                size="sm"
                variant="danger"
                onClick={() => onDeleteShipment(option)}
                disabled={inProgress}
                style={{ marginLeft: '10px' }}
              >
                Yes, Delete Shipment
              </Button>}

            {option === 'po' &&
              <Button
                type="button"
                size="sm"
                variant="danger"
                onClick={() => onDeleteShipment(option)}
                disabled={inProgress}
                style={{ marginLeft: '10px' }}
              >
                Yes, Delete Purchase Order
              </Button>}
          </Col>
        </Row>

      </Modal.Footer>
    </Modal>
  );
};
const mapState = state => {
  const result = state.deleteShipment.toJS();
  return { ...result, openModal: state.modals };
};

const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteShipment);
