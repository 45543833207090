import React, { useEffect, useRef, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import AccordionCard from '../../shipments/shipments-detail/AccordionCard';
import { isEqual } from 'lodash'
const numeral = require('numeral');
const formatMoney = value => numeral(value).format('0,0.00');

const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value])
    return ref.current;
}

const ShipmentBreakdown = ({ shipmentLineItems = [], openTabs, setOpen, showModal, poId, type }) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const previousShipmentLineItems = usePrevious(shipmentLineItems);
    useEffect(
        () => {
            if (!isEqual(shipmentLineItems, previousShipmentLineItems)) {
                setSelectedItems([]);
            }
        }
        , [shipmentLineItems]);
    const onEditModalWindowClosed = () => setSelectedItems([]);

    //dont let line item editing happen if no unassigned group
    const showSelectBoxes = !(type === 'scheduled' || type === 'tracking');
    return (
        <AccordionCard
            title="Shipment Breakdown"
            controlled
            open={openTabs['shipmentLineItemsForm']}
            setOpen={setOpen}
            name="shipmentLineItemsForm"
        >
            <Row style={{ marginBottom: '2rem', fontSize: '0.9rem' }}>
                <Col>
                    {showSelectBoxes &&
                        <div style={{ padding: '0.3rem 0.5rem' }}>
                            Use the selection boxes below to edit specific line items.
                        </div>
                    }
                </Col>
                <Col>
                    {
                        selectedItems.length > 0 &&
                        <Button
                            style={{ float: 'right' }}
                            type="button"
                            size="sm"
                            variant="primary"
                            onClick={
                                () => {
                                    showModal({
                                        name: 'EditLineItemsModal',
                                        payload: shipmentLineItems.filter(({ ID }) => (selectedItems.includes(ID))),
                                        poId,
                                        onModalWindowClosed: onEditModalWindowClosed
                                    })
                                }
                            }
                        >
                            {`Edit (${selectedItems.length}) Lines`}
                        </Button>
                    }
                </Col>
            </Row>
            <div className="table-responsive-sm pt-2">
                <table className="table table-sm po-table">
                    <thead>
                        <tr>
                            <th>
                            </th>
                            <th>Item</th>
                            <th className="item-desc" style={{ maxWidth: '200px' }}>
                                Description
                            </th>
                            <th style={{ maxWidth: '25px' }}>Quantity</th>
                            <th style={{ maxWidth: '25px' }}>Unit Cost</th>
                            <th>Model #</th>
                        </tr>
                    </thead>
                    <tbody>
                        {(shipmentLineItems || []).map((l, i) => (
                            <tr key={i}>
                                <td className='text-center'>
                                    {showSelectBoxes &&
                                        <input
                                            type='checkbox'
                                            className='po-checkbox'
                                            onChange={
                                                (e) => {
                                                    const value = e.target.checked;
                                                    if (value) {
                                                        setSelectedItems([...selectedItems, l.ID]);
                                                    } else {
                                                        setSelectedItems(selectedItems.filter(e => e !== l.ID));
                                                    }
                                                }
                                            }
                                            checked={(selectedItems || []).includes(l.ID)}
                                        />
                                    }
                                </td>
                                <td className="nowrap text-center">{l.LineNumber}</td>
                                <td style={{ maxWidth: '200px' }}>{l.Spec}</td>
                                <td className="text-right" style={{ maxWidth: '25px' }}>
                                    {l.Quantity}
                                </td>
                                <td className="text-right" style={{ maxWidth: '25px' }}>
                                    ${formatMoney(l.NetPrice)}
                                </td>
                                <td className="nowrap text-center">
                                    {l.Model}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

        </AccordionCard>
    )
}

export default ShipmentBreakdown;