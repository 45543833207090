import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import Loading from '../../../ui/Loading';

const ModalDeleteQuote = (
  {
    openModal,
    name,
    closeModal,
    handleDelete,
    inProgress,
    error
  }
) => {
  return (
    <Modal
      show={openModal === name}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          Delete Quote
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {inProgress && <Loading />}
        {!inProgress &&
          <p style={{ textAlign: 'center', marginTop: '10px' }}>
            Are you sure you want to delete quote?
          </p>}
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Row>
          <Col style={{ color: '#C23934', marginTop: '4px' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>
          <Col sm="auto">
            <Button
              type="button"
              size="sm"
              variant="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="sm"
              variant="danger"
              onClick={handleDelete}
              disabled={inProgress}
              style={{ marginLeft: '10px' }}
            >
              Yes, Delete Quote
            </Button>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => {
  const deleteQuote = state.deleteQuote.toJS();
  return {
    ...deleteQuote,
    openModal: state.modals
  };
};
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteQuote);
