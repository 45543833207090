import React, { Component } from "react";
import { Container } from "react-bootstrap";
import Box from "../../ui/Box";
import Layout from "../../ui/Layout";
import ShipmentsResult from "../shipments/shipments-search/ShipmentsResult";
import queryString from 'query-string';
import { connect } from "react-redux";
import fetchDux from "../../../state/fetch-dux";
import { bindActionCreators } from "redux";
import AcknowledgeModal from "./AcknowledgeModal";
import { actionCreators } from "../../../state/modals-dux";
import Notification from "../../ui/Notification";

const formatQuery = (
    {
        page,
        pageSize,
        ...rest
    }
) => ({
    page: page || '0',
    pageSize: pageSize || '20',
    ...rest
});

const formatValue = (value, label) => {
    const intValues = ['deliveryStatus'];
    if (intValues.includes(label)) return parseInt(value);
    return value;
};

class AlteredShipDateShipments extends Component {

    componentWillMount() {
        const { location: { search }, location: { pathname }, history } = this.props;
        const query = formatQuery(queryString.parse(search));
        const qs = queryString.stringify(query);
        history.push({ pathname, search: qs });
        this.callSearch(qs);
    }

    parseQuery(search) {
        const params = queryString.parse(search);
        const multiSelect = ['sortBy'];
        return Object.keys(params).reduce(
            (t, p) => {
                const value = formatValue(params[p], p);
                return !multiSelect.includes(p)
                    ? Object.assign({}, t, { [p]: value })
                    : Object.assign({}, t, {
                        [p]: Array.isArray(value) ? value : [value]
                    });
            },
            {}
        );
    }

    onResultColumnChange(activeColumns) {
      (activeColumns || []).push(undefined)
        this.props.setActiveColumns(activeColumns)
    }

    componentWillReceiveProps(nextProps) {
        const { location: { search } } = nextProps;
        if (this.props.location.search !== search) {
            this.callSearch(search);
        }
    }

    callSearch = (search) => {
        const { getHotlistAlteredShipments } = this.props;
        getHotlistAlteredShipments(this.parseQuery(search));
    }

    handleFetchData = (state) => {
        const {
            location: { search },
            history,
            location: { pathname }
        } = this.props;
        const {
            page,
            pageSize,
            sorted
        } = state || {};
        if (!search) return;
        const [sort] = sorted || [];
        const {
            id: sortBy,
            desc
        } = sort || {};

        const params = {
            ...queryString.parse(search),
            page,
            pageSize,
            sortBy,
            desc
        };
        const query = formatQuery(params);
        const qs = queryString.stringify(query);
        history.push({ pathname, search: qs });
    }


    render() {
        const {
            result,
            activeColumns,
            inProgress,
            error,
            location: { search },
            showModal,
            acknowledgeShipmentChangeError
        } = this.props;
        const paging = formatQuery(queryString.parse(search));

        return (
            <Layout
                route="hotlist"
                alt>

                <div className="layout-app-header">
                    <Container>
                        <div>
                            <h2>
                              LBMX Discrepancies
                            </h2>
                        </div>
                    </Container>
                </div>

                <Container>
                    <Box className="box-table">
                        <ShipmentsResult
                            result={result || {}}
                            paging={paging}
                            activeColumns={(activeColumns || [])}
                            onColumnChange={this.onResultColumnChange.bind(this)}
                            inProgress={inProgress}
                            error={error}
                            onFetchData={this.handleFetchData}
                            showActions={true}
                            onRowSelected={
                                (selectedRow) => {
                                    const payload = {
                                        name: 'acknowledgeModal',
                                        selectedRow
                                    }
                                    showModal(payload);
                                }
                            }
                        />
                    </Box>
                </Container>

                <AcknowledgeModal
                    name='acknowledgeModal'
                    onAckSuccess={
                        () => {
                            const {
                                location: { search },
                                history,
                                location: { pathname }
                            } = this.props;
                            const query = formatQuery(queryString.parse(search));
                            const qs = queryString.stringify({ ...query, t: Date.now() });
                            history.push({ pathname, search: qs });
                        }
                    }
                />

                {error &&
                    <Notification
                        key="altered-shipments"
                        duration={5}
                        closable={true}
                        type="danger"
                    >
                        Failed to get altered shipments.
                    </Notification>
                }
                {
                    acknowledgeShipmentChangeError &&
                    <Notification
                        key="altered-shipments"
                        duration={5}
                        closable={true}
                        type="danger"
                    >
                        Failed to get acknowledge shipment change.
                    </Notification>
                }
            </Layout>
        )
    }

}

const mapState = state => {
    const {
        result,
        inProgress,
        error
    } = state.getHotlistAlteredShipments.toJS();

    const {
        error: acknowledgeShipmentChangeError
    } = state.acknowledgeShipmentChange.toJS();

    const activeColumns = state.setActiveColumns.toJS();

    return {
        result,
        inProgress,
        error,
        activeColumns: activeColumns.result,
        acknowledgeShipmentChangeError
    }
}

const mapDispatch = dispatch =>
    bindActionCreators({
        getHotlistAlteredShipments: fetchDux.getHotlistAlteredShipments.createAction,
        setActiveColumns: fetchDux.setActiveColumns.createAction,
        ...actionCreators
    }, dispatch)




export default connect(mapState, mapDispatch)(AlteredShipDateShipments);