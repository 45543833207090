import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ProjectBreadcrumbs = (
  {
    project,
    current
  }
) => {
  const {
    ProjectName,
    ID
  } = project || {};

  return (
    <div style={{ marginTop: '15px' }}>
      <Breadcrumb>
        <Link to="/orders" className="breadcrumb-item">
          Orders
        </Link>
        <Link to={`/projects/${ID}`} className="breadcrumb-item">
          {ProjectName}
        </Link>
        <Breadcrumb.Item active>{current || ''}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default ProjectBreadcrumbs;
