import React from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Loading from '../../../../ui/Loading';
import { Link } from 'react-router-dom';
import Icon from '../../../../ui/Icon';
import {normalizeMoney} from '../helpers/helpers';


const required = value => value ? undefined : 'Required';

const renderUserContacts = ({ fields }) => {
  return (
    <div>
      <Row style={{ marginTop: '1rem' }}>
        <Col md={10}>
          <h5>
            Warehouse Contacts
          </h5>
        </Col>

        <Col sm={2}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => fields.push({})}
          >
            <Icon name="plus" /> Add User Contact
          </Button>
        </Col>
      </Row>
      {fields.map((contact, index) => {
        return (
          <Row key={index}>
            <Col sm={3}>
              <Field
                name={`${contact}.UserFirstName`}
                type="text"
                label="First Name"
                component={FormField.Input}
                validate={[required]}
                required
              />
            </Col>

            <Col sm={3}>
              <Field
                name={`${contact}.UserLastName`}
                type="text"
                label="Last Name"
                component={FormField.Input}
                validate={[required]}
                required
              />
            </Col>

            <Col sm={3}>
              <Field
                name={`${contact}.Email`}
                type="text"
                label="Email"
                component={FormField.Input}
                validate={[required]}
                required
              />
            </Col>

            <Col sm={2}>
              <Field
                name={`${contact}.Phone`}
                type="text"
                label="Phone"
                component={FormField.Input}
              />
            </Col>
            {index !== 0 &&
              <Col sm={1} style={{ paddingTop: '2.2rem' }}>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => fields.remove(index)}
                >
                  <Icon name="trash" className="danger" />
                </Button>
              </Col>}
          </Row>
        );
      })}
    </div>
  );
};

const getContactFields = () => {
  return (
    <div>
      <Row>
        <Col sm={12}>
          <h1>Contact</h1>
        </Col>
        <Col sm={6}>
          <Field
            name="name"
            type="text"
            label="Name"
            component={FormField.Input}
            validate={[required]}
            required
          />
        </Col>

        <Col sm={6}>
          <Field
            name="address1"
            type="text"
            label="Address 1"
            component={FormField.Input}
          />
        </Col>

        <Col sm={6}>
          <Field
            name="address2"
            type="text"
            label="Address 2"
            component={FormField.Input}
          />
        </Col>

        <Col sm={6}>
          <Field
            name="city"
            type="text"
            label="City"
            component={FormField.Input}
          />
        </Col>

        <Col sm={6}>
          <Field
            name="postalCode"
            type="text"
            label="Zip"
            component={FormField.Input}
          />
        </Col>

        <Col sm={6}>
          <Field
            name="stateProvince"
            type="text"
            label="State"
            component={FormField.Input}
          />
        </Col>

        <Col sm={6}>
          <Field
            name="tollFree"
            type="text"
            label="TollFree"
            component={FormField.Input}
          />
        </Col>

        <Col sm={6}>
          <Field
            name="phone"
            type="text"
            label="Phone"
            component={FormField.Input}
            validate={[required]}
            required
          />
        </Col>

        <Col sm={6}>
          <Field
            name="fax"
            type="text"
            label="Fax"
            component={FormField.Input}
          />
        </Col>

        <Col sm={6}>
          <Field
            name="emergencyPhone"
            type="text"
            label="Emergency Phone"
            component={FormField.Input}
          />
        </Col>
      </Row>
      <FieldArray name="userContacts" component={renderUserContacts} />
    </div>
  );
};

const getHandlingFields = () => {
  return (
    <Row>
      <Col sm={12} style={{ marginTop: '5vh' }}>
        <h1>Handling/Delivery</h1>
      </Col>

      <Col sm={6}>
        <Field
          name="minHandling"
          type="text"
          label="Min Handling Charge"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="minDelivery"
          type="text"
          label="Min Delivery Charge"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="handlingIn"
          type="text"
          label="Handling - In (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="handlingOut"
          type="text"
          label="Handling - Out (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="storage"
          type="text"
          label="Storage (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="delivery999"
          type="text"
          label="Delivery 0 - 999 (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="delivery1999"
          type="text"
          label="Delivery 1000 - 1999 (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="delivery3999"
          type="text"
          label="Delivery 2000 - 3999 (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="delivery7999"
          type="text"
          label="Delivery 4000 - 7999 (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="deliveryPlus"
          type="text"
          label="Delivery  8000 and up (cwt)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="driverHelper"
          type="text"
          label="Driver helper (per hour)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="manTruck"
          type="text"
          label="1 man and a truck (per hour)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="twoMenTruck"
          type="text"
          label="2 men and a truck (per hour)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="warehouseHelper"
          type="text"
          label="Warehouse helper (per hour)"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

    </Row>
  );
};

const getAdditionalInfoFields = () => {
  return (
    <Row>
      <Col sm={12} style={{ marginTop: '5vh' }}>
        <h1>Additional Info</h1>
      </Col>
      <Col sm={6}>
        <Field
          name="debrisRemoval"
          type="text"
          label="Debris Removal"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="protection"
          type="text"
          label="Full Value Protection"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="accessFee"
          type="text"
          label="Access Fee"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="notes"
          type="text"
          label="Notes"
          component={FormField.Input}
        />
      </Col>

      <Col sm={6}>
        <Field
          name="receivingHours"
          type="text"
          label="Receiving Hours"
          component={FormField.Input}
        />
      </Col>
      <Col sm={6}>
        <Field
          name="fuelSurcharge"
          type="text"
          label="Fuel Surcharge"
          component={FormField.Input}
          normalize={(value)=>normalizeMoney(value)}
        />
      </Col>
    </Row>
  );
};
const WarehouseNewForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>
      {!submitting && getContactFields()}
      {!submitting && getHandlingFields()}
      {!submitting && getAdditionalInfoFields()}
      {submitting &&
        <Loading fullPage message="Creating Warehouse, Please Wait..." />}
      <div style={{ textAlign: 'end' }}>
        <Link
          className="btn btn-secondary"
          style={{ marginTop: '5vh' }}
          to="/administration/warehouses"
        >
          Cancel
        </Link>
        {' '}
        <Button
          variant="primary"
          style={{ marginTop: '5vh', marginLeft: '2vh' }}
          type="submit"
          disabled={submitting}
        >
          Add Warehouse
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'warehouse-new-form'
})(WarehouseNewForm);
