import React from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col, Button, Alert } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Loading from '../../../ui/Loading';
import { getFormValues } from 'redux-form';
import { getStatusLabel } from '../shipments-dict';
import moment from 'moment';

const ModalWebTrack = (
  {
    handleShowCarrierPage,
    openModal,
    closeModal,
    name,
    formValues,
    result,
    inProgress,
    error,
    shipmentId
  }
) => {
  const webTrackStatus = result ? 'Web tracking complete' : '';
  const { TrackingNum } = formValues || {};
  const statusLabel = getStatusLabel((result || {}).deliveryStatus);
  const isOlder = moment((result || {}).CarrierDeliveryDate).isValid() &&
    moment().diff(moment((result || {}).CarrierDeliveryDate), 'days') > 60;
  const formatDate = date => {
    if (!date || !moment(date).isValid()) return <span>-</span>;
    return moment.utc(date).format('MM/DD/YYYY');
  };
  return (
    <Modal show={openModal === name} onHide={closeModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="shipment-webtrack-title">
          Web Tracking Results
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error.message}</Alert>}
        {inProgress && <Loading />}
        {result &&
          <div className="shipment-webtrack-body">
            {isOlder &&
              <Alert variant="danger">
                Tracking number
                {' '}
                <b>{TrackingNum || (result || {}).TrackingNum}</b>
                {' '}
                is over 60 days old, and it's possible that
                {' '}
                <b>{result.Carrier}</b>
                {' '}
                no longer retains records for this shipment.
              </Alert>}

            <Row>
              <Col sm={6}><label>Tracking Number:</label></Col>
              <Col sm={6}>{TrackingNum || (result || {}).TrackingNum}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Delivery Status:</label></Col>
              <Col sm={6}>{result.deliveryStatus >= 0 ? statusLabel : result.CarrierDeliveryStatus}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Carrier:</label></Col>
              <Col sm={6}>{result.Carrier}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Scheduled Delivery Date:</label></Col>
              <Col sm={6}>{formatDate(result.SchdDeliveryDate)}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Delivery Date:</label></Col>
              <Col sm={6}>{formatDate(result.CarrierDeliveryDate)}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Freight Charge:</label></Col>
              <Col sm={6}>{result.CarrierFreightCharge}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Number of Cartons:</label></Col>
              <Col sm={6}>{result.CarrierNumCartons}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Weight:</label></Col>
              <Col sm={6}>{result.CarrierShipWeight}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Signed for by:</label></Col>
              <Col sm={6}>{result.SignedForBy}</Col>
            </Row>
            <Row>
              <Col sm={6}><label>Damage:</label></Col>
              <Col sm={6}>{result.CarrierDamage}</Col>
            </Row>
          </div>}
      </Modal.Body>
      {result &&
        <Modal.Footer className="shipment-webtrack">
          <Button size="sm" onClick={() => handleShowCarrierPage(result)}>
            Show Carrier Page
          </Button>

          <Button size="sm" onClick={() => closeModal()}>
            Close
          </Button>
        </Modal.Footer>}
    </Modal>
  );
};

const mapState = state => ({
  openModal: state.modals,
  formValues: getFormValues('shipment-form')(state)
});
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalWebTrack);
