import React, { Component } from 'react';
import VendorsTable from './VendorsTable';
import ItemsTable from './ItemsTable';

const getTabClass = (idx, activeTab) =>
  idx === activeTab ? 'tab active' : 'tab';

class ItemsGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.POTable ? 2 : 0,
      itemsSearch: '',
      vendorsSearch: ''
    };
  }

  render() {
    const { result, lookups, POTable, tableProps } = this.props;
    const {
      activeTab,
      itemsSearch,
      vendorsSearch
    } = this.state;
    const { lineItems, vendors, purchaseOrders } = result || {};
    const { length: numItems } = lineItems || {};
    const { length: numVendors } = vendors || {};
    const { length: numPOs } = purchaseOrders || {};
    const setActiveTab = idx => () => this.setState({ activeTab: idx });
    const handleSearchChange = (key, value) => {
      this.setState({ [key]: value });
    };
    return (
      <div className="project-items">
        <div className="project-items-tabs">
          {POTable &&
            <div
              className={getTabClass(2, activeTab)}
              onClick={setActiveTab(2)}
            >
              Purchase Orders ({numPOs})
            </div>}
          <div className={getTabClass(0, activeTab)} onClick={setActiveTab(0)}>
            Items ({numItems})
          </div>
          <div className={getTabClass(1, activeTab)} onClick={setActiveTab(1)}>
            Vendors ({numVendors})
          </div>
        </div>
        <ItemsTable
          show={activeTab === 0}
          data={lineItems}
          search={itemsSearch}
          handleSearchChange={handleSearchChange}
        />
        <VendorsTable
          show={activeTab === 1}
          data={vendors}
          search={vendorsSearch}
          handleSearchChange={handleSearchChange}
        />
        <div
          className="project-items-grid"
          style={activeTab === 2 ? {} : { display: 'none' }}
        >
          {POTable &&
            <POTable {...{ result, lookups, ...(tableProps || {}) }} />}
        </div>
      </div>
    );
  }
}

export default ItemsGrid;
