import React from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Popover, Button } from 'react-bootstrap';

const WebTrackButtonColumnPopover = ({ header, handleWebTrack, updateInProgress, enabled, showModal, original, buttonStyle }) => {

    const popOverText = buttonStyle === 'primary' ?
        enabled ?
            'Shipment has been delivered or received by warehouse. System tracking has been completed.'
            :
            'Tracking information is empty OR is a private carrier and cannot be Web Tracked. Status needs to be updated manually for private carriers.'
        :
        buttonStyle === 'warning' ?
            'Shipment is being actively tracked by the system.'
            :
            buttonStyle === 'success' ?
                `Tracking information is provided by the carrier directly OR it has not been picked up by the carrier.`
                :
                buttonStyle === 'primary-grey' ?
                    'Shipment is either too old to be tracked or has just been created and no tracking information is available yet.'
                    :
                    buttonStyle === 'warning-secondary' ?
                        'This shipment has been marked for Manual Tracking in Shipment Details. The shipment status and carrier information data fields must be updated manually by the user.'
                        :
                        '';
    return (
        <div style={{ display: 'inline-block' }}>
            <OverlayTrigger
                trigger="hover"
                placement="bottom"
                overlay={
                    <Popover title={header || ''}>
                        {popOverText}
                    </Popover>
                }
            >
                <div style={{ display: 'inline-block' }}>
                    <Button
                        size="sm"
                        disabled={!enabled || updateInProgress}
                        onClick={() => {
                            showModal ?
                                handleWebTrack(original, () => {
                                    showModal('WebTrackModal');
                                })
                                :
                                handleWebTrack();
                        }}
                        variant={buttonStyle}
                        style={{
                            pointerEvents: (!enabled || updateInProgress) ? 'none' : 'auto'
                        }}
                    >
                        Track
                    </Button>
                </div>

            </OverlayTrigger>
        </div>
    );

}

export default WebTrackButtonColumnPopover;
