import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { Form, Row, Col, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import FormField from '../../../forms/FormField';
import shipmentsDict, { getStatusLabel } from '../shipments-dict';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';

const sortByOptions = [
  { value: 'carrier', label: 'Carrier' },
  { value: 'deliveryStatus', label: 'Delivery Status' },
  { value: 'poNum', label: 'PO' },
  { value: 'Id', label: 'Shipment' },
  { value: 'trackingNum', label: 'Tracking Number' },
  { value: 'vendorNum', label: 'Vendor' }
];

const CARRIERS = [
  {
    value: 'aaacooper',
    label: 'AAA Cooper Transportation'
  },
  {
    value: 'A DUIE PYLE',
    label: 'A Duie Pyle' //Not found
  },
  {
    value: 'averittexpress',
    label: 'AVERITT Express'
  },
  {
    value: 'abf',
    label: 'ABF Freight'
  },
  {
    value: 'estes',
    label: 'Estes'
  },
  {
    value: 'fedex',
    label: 'Fedex'
  },
  {
    value: 'old-dominion',
    label: 'Old Dominion Freight Line'
  },
  {
    value: 'rl-carriers',
    label: 'RL Carriers'
  },
  {
    value: 'saia-freight',
    label: 'Saia LTL Freight'
  },
  {
    value: 'se-freightlines',
    label: 'Southeastern Freightlines'
  },
  {
    value: 'ups',
    label: 'UPS'
  },
  {
    value: 'USF',
    label: 'USF Holland' //Not found
  },
  {
    value: 'usps',
    label: 'USPS'
  },
  {
    value: 'xpoweb',
    label: 'XPO Logistics'
  },
  {
    value: 'YRC',
    label: 'YRC' //Not found
  }
];


const deliveryStatusOptions = [
  {
    value: shipmentsDict.UNSHIPPED,
    // don't display this status on the gridview but show in the search
    // TODO:  clean up status dict and labels and this hack 
    label: 'Unshipped'
  },
  {
    value: shipmentsDict.INFO_RECEIVED,
    label: getStatusLabel(shipmentsDict.INFO_RECEIVED)
  },
  {
    value: shipmentsDict.IN_TRANSIT,
    label: getStatusLabel(shipmentsDict.IN_TRANSIT)
  },
  {
    value: shipmentsDict.AVAILABLE_FOR_PICKUP,
    label: getStatusLabel(shipmentsDict.AVAILABLE_FOR_PICKUP)
  },
  {
    value: shipmentsDict.OUT_FOR_DELIVERY,
    label: getStatusLabel(shipmentsDict.OUT_FOR_DELIVERY)
  },

  {
    value: shipmentsDict.FAILED_ATTEMPT,
    label: getStatusLabel(shipmentsDict.FAILED_ATTEMPT)
  },

  {
    value: shipmentsDict.DELIVERED,
    label: getStatusLabel(shipmentsDict.DELIVERED)
  },

  {
    value: shipmentsDict.WAREHOUSE_DELIVERED,
    label: getStatusLabel(shipmentsDict.WAREHOUSE_DELIVERED)
  },
  {
    value: shipmentsDict.NOTFOUND,
    label: getStatusLabel(shipmentsDict.NOTFOUND)
  },
  {
    value: shipmentsDict.EXCEPTION,
    label: getStatusLabel(shipmentsDict.EXCEPTION)
  },
  {
    value: shipmentsDict.EXPIRED,
    label: getStatusLabel(shipmentsDict.EXPIRED)
  },
  {
    value: shipmentsDict.NOT_SHIPPED,
    label: getStatusLabel(shipmentsDict.NOT_SHIPPED)
  }
];

const SearchForm = (
  {
    salesReps,
    handleSubmit,
    submitting,
    searchType,
    toggleEditMode,
    editMode,
    hasResults,
    getCarrierByName,
    clearGetCarrierByName,
    carriersSearchResults,
    initialValues
  }
) => {
  const carrierOptions =
    carriersSearchResults ?
      (carriersSearchResults || []).map((carrier) => {
        const { courier_name, courier_code } = carrier;
        return {
          label: courier_name,
          value: courier_code
        }
      })
      :
      (CARRIERS || []).sort(({ value: a }, { value: b }) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        return 0;
      });

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={6}>
            <Field
              type="text"
              name="search"
              label="Search by Account Code, Job Code, or Purchase Order"
              component={FormField.Input}
            />
            <Field
              type="text"
              name="salesRep"
              label="Search By Sales Rep"
              component={FormField.MultiSelect}
              placeholder="Search All"
              options={salesReps}
            />
          </Col>
          <Col md={6}>
            <div>
              <label>Search For</label>
            </div>
            <Field
              name="searchType"
              component={FormField.RadioGroup}
              getInputContainerStyle={({ value, label }, index) => {
                // if (index === 0) return { display: 'inline' };
                return { marginLeft: '15px' };
              }}
              options={[
                { value: 'new', label: 'New Shipments' },
                { value: 'current', label: 'Current Shipments' },
                { value: 'archived', label: 'Archived Shipments' },
                { value: 'all', label: 'All Shipments' }
              ]}
            />
          </Col>

        </Row>
        <Row>
          <Col md={6}>
            <Field
              type="text"
              name="trackingNumber"
              label="Search by Tracking Number"
              component={FormField.Input}
            />
          </Col>
          <Col md={6}>
            <Field
              type="text"
              name="sortBy"
              label="Sort By"
              component={FormField.MultiSelect}
              options={sortByOptions}
            />
          </Col>
        </Row>

        <Row>
          <Col md={6}>
            <Field
              type="text"
              name="vendorName"
              label="Search by Vendor"
              component={FormField.Input}
            />
          </Col>

          <Col md={6}>
            <Field
              type="text"
              name="shippingAddress"
              label="Search by Shipping Address"
              component={FormField.Input}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3}>
            <Field
              type="date"
              name="startDate"
              label="Search by Imported Date - Start"
              component={FormField.Datepicker}
            />
          </Col>

          <Col md={3}>
            <Field
              type="date"
              name="endDate"
              label="Search by Imported Date - End"
              component={FormField.Datepicker}
            />
          </Col>

          <Col md={6}>

            <Field
              type="text"
              name="carrierName"
              label="Search by Carrier"
              component={FormField.SelectWithSearch}
              options={carrierOptions}
              onSearchChange={
                (value) => {
                  if (value.trim().length >= 3) {
                    getCarrierByName({ carrierName: value });
                  } else {
                    clearGetCarrierByName();
                  }
                }
              }
            />
          </Col>
        </Row>
        {/* <div>
          <label>Search by Order Date</label>
        </div> */}
        <Row>
          {searchType === 'all' &&
            <Col md={6}>
              <Field
                isMulti
                type="text"
                name="deliveryStatus"
                label="Search by Shipment Status (multiple allowed)"
                component={FormField.MultiSelect}
                options={deliveryStatusOptions}
              />
            </Col>}
          <Col md={6}>
            <Field
              type="text"
              name="customerName"
              label="Search by Customer Number"
              component={FormField.Input}
            />
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Button
              type="submit"
              variant="success"
              disabled={submitting || editMode}
            >
              Search Shipments
            </Button>
          </Col>
          {!editMode &&
            hasResults &&
            <Col md={6} className="text-right">
              <Button
                type="primary"
                variant="success"
                disabled={submitting}
                onClick={toggleEditMode}
              >
                Edit Shipments
              </Button>
            </Col>}
        </Row>
      </Form>
    </div>
  );
};

const form = reduxForm({
  form: 'shipment-search-form',
  enableReinitialize: true
})(SearchForm);

const selector = formValueSelector('shipment-search-form');

const mapState = state => {
  const searchType = selector(state, 'searchType');
  const {
    result
  } = state.getCarrierByName.toJS();
  return {
    searchType,
    carriersSearchResults: result
  };
};

const mapDispatch = dispatch =>
  bindActionCreators({
    getCarrierByName: fetchDux.getCarrierByName.createAction,
    clearGetCarrierByName: fetchDux.getCarrierByName.clearAction,
  }, dispatch)

export default connect(mapState, mapDispatch)(form);
