import React, { Component } from 'react';
import Layout from '../../../ui/Layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Loading from '../../../ui/Loading';
import ProjectInfo from './../projects-detail/ProjectInfo';
import ModalProjectImportWarnDate
  from './../projects-new/ModalProjectImportWarnDate';

import { Table, Button } from 'react-bootstrap';

import DiffOrderDetail from './DiffOrderDetail';
import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import moment from 'moment';

import ProjectsUpdateLayout from './ProjectsUpdateLayout';
import ModalConfirmChanges from './shipping/ModalConfirmChanges';
import ProjectBreadcrumbs from './ProjectBreadcrumbs';
import Icon from '../../../ui/Icon';
import ModalProjectErrorSummary from '../projects-detail/ModalProjectErrorSummary';

const getShouldWarnDate = ({ lastModifiedDate }) => {
  if (!lastModifiedDate || !moment(lastModifiedDate).isValid()) return false;
  return moment().subtract(1, 'day').isAfter(moment(lastModifiedDate));
};

const warningStyle = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: '#dc3545',
  padding: '0 10px 15px 10px',
  textAlign: 'center'
};

const accordionStyle = {
  textTransform: 'uppercase',
  fontWeight: 'bold',
  backgroundColor: '#dc3545',
  color: 'white',
  padding: '0 5px',
  cursor: 'pointer'
};

class ProjectsUpdateImport extends Component {
  constructor(props) {
    super(props);
    this.state = { showRejectedSummary: true };
  }
  componentDidMount() {
    this.props.clearPostReimportProject();
    const {
      match,
      getProjectById,
      getReimportDiff,
      getLookups
    } = this.props;
    const { relatedProjectId, id } = match.params || {};
    getLookups();
    getProjectById(id);
    getReimportDiff({ relatedProjectId, id });
    this.reload = this.reload.bind(this);
  }
  reload() {
    const {
      match,
      getProjectById,
      getReimportDiff
    } = this.props;
    const { relatedProjectId, id } = match.params || {};
    getProjectById(id);
    getReimportDiff({ relatedProjectId, id });
  }
  render() {
    const {
      history,
      postReimportProject,
      postProjectError,
      showModal,
      closeModal,
      aqjson,
      result,
      lookups,
      inProgress,
      reimportDiff,
      postCompleteReimportProject,
      match
    } = this.props;
    const {
      showRejectedSummary
    } = this.state;
    const { project } = result || {};
    const { c_SageIntegrationStatus: status, ID } = project || {};
    const { projectActions, purchaseOrderActions } = reimportDiff || {};
    const { relatedProjectId } = match.params || {};

    const toggleRejectedSummary = () =>
      this.setState({ showRejectedSummary: !showRejectedSummary });

    const handleConfirmWarnDate = () => {
      closeModal();
      postReimportProject(
        { payload: { aqjson }, projectId: ID },
        'project-import-form',
        null,
        result => {
          // const { project } = result || {};
          // const { ID } = project || {};
          // if (!ID) return;
          // history.push(`/projects/${ID}`);
        }
      );
    };

    const allItems = (purchaseOrderActions || [])
      .reduce((prev, { lineItems }) => [...prev, ...(lineItems || [])], []);

    const handleUpdateProject = () => showModal('ConfirmImportChanges');
    const handleFinalSubmit = () =>
      postCompleteReimportProject(ID, null, null, () =>
        history.push(`/projects/${ID}`));

    const hasReimportError = (purchaseOrderActions || [])
      .some(({ purchaseOrder }) => !purchaseOrder.c_Approved);

    const hasReimportChanges = (purchaseOrderActions || [])
      .some(({ type }) => type !== 'none');

    const rejectedPOs = (purchaseOrderActions || [])
      .map(({ PurchaseOrderNumber, lineItems }) => {
        const rejectedItems = (lineItems || [])
          .filter(({ c_UpdateRejected }) => c_UpdateRejected);
        return { rejectedItems, PurchaseOrderNumber };
      })
      .filter(({ rejectedItems }) => rejectedItems.length > 0);

    const hasRejectedChanges = rejectedPOs.length > 0;
    const handleViewProjectErrors = () => showModal('ProjectErrorSummaryModal');

    return (
      <Layout route="projects-update-import">

        {!inProgress &&
          <div>
            <ProjectBreadcrumbs
              project={{
                ID: relatedProjectId,
                ProjectName: (project || {}).ProjectName
              }}
              current="Update Equipment"
            />
            <ProjectInfo
              status={status}
              result={result}
              projectId={relatedProjectId}
              backButton
              isReimport
              minimal
              actions={projectActions}
              handleUpdateProject={handleUpdateProject}
              reimportError={hasReimportError}
              reimportErrorMessage={hasReimportError ? 'Missing Vendors' : null}
              nextSteps={
                hasReimportError
                  ? 'Review and Assign Purchase Order Vendors Below'
                  : null
              }
              hasReimportChanges={hasReimportChanges}
              handleViewProjectErrors={handleViewProjectErrors}
            />
            <ModalProjectImportWarnDate
              name="ProjectImportWarnDateModal"
              onSubmit={handleConfirmWarnDate}
            />
            <ModalConfirmChanges
              name="ConfirmImportChanges"
              handleSubmit={handleFinalSubmit}
            />
            <ModalProjectErrorSummary
              name="ProjectErrorSummaryModal"
              result={result}
            />
            {hasRejectedChanges &&
              <div>
                <div style={warningStyle}>
                  Some requested edits were not able to be performed or resulted in creation of a new purchase order, as some items have already received costs.
                  <br />
                  Please verify your order vs AQ file or revert to previous state.
                </div>
                <div style={accordionStyle} onClick={toggleRejectedSummary}>
                  Rejected Edits
                  <span style={{ float: 'right' }}>
                    {showRejectedSummary && <Icon name="chevron-down" />}
                    {!showRejectedSummary && <Icon name="chevron-right" />}
                  </span>
                </div>
                {showRejectedSummary &&
                  <Table size="sm" striped>
                    <thead>
                      <tr>
                        <th>Purchase Order</th>
                        <th>Item Number</th>
                        <th>Model</th>
                      </tr>
                    </thead>
                    {(rejectedPOs || []).map(({
                      PurchaseOrderNumber,
                      rejectedItems
                    }) => (
                      <tbody>
                        {(rejectedItems || []).map(({
                          ItemNumber,
                          Model
                        }, i) => (
                          <tr>
                            <td>
                              {i === 0 && PurchaseOrderNumber}
                            </td>
                            <td>{ItemNumber}</td>
                            <td>{Model}</td>
                          </tr>
                        ))}
                      </tbody>
                    ))}
                  </Table>}
              </div>}
            <ProjectsUpdateLayout
              result={{
                ...result,
                lineItems: allItems
              }}
              reimportDiff={reimportDiff}
              Page1={
                <DiffOrderDetail
                  result={result}
                  lookups={lookups}
                  reimportDiff={reimportDiff}
                  reload={this.reload}
                />
              }
            />
          </div>}

        {inProgress && <Loading fullPage message="Loading, Please Wait..." />}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress: getProjectByIdInProgress,
    error: getProjectByIdError
  } = state.getProjectById.toJS();
  const {
    inProgress: postReimportProjectInProgress,
    error: postProjectError
  } = state.postReimportProject.toJS();

  const {
    result: lookups,
    inProgress: getLookupsInProgress,
    error: getLookupsError
  } = state.getLookups.toJS();

  const {
    result: reimportDiff,
    inProgress: getReimportDiffInProgress,
    error: getReimportDiffError
  } = state.getReimportDiff.toJS();

  const {
    inProgress: postCompleteReimportProjectInProgress
  } = state.postCompleteReimportProject.toJS();

  return {
    reimportDiff,
    postProjectError,
    result,
    lookups,
    inProgress: getProjectByIdInProgress ||
      getReimportDiffInProgress ||
      postReimportProjectInProgress ||
      postCompleteReimportProjectInProgress,
    error: getProjectByIdError
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postReimportProject: fetchDux.postReimportProject.createAction,
      clearPostReimportProject: fetchDux.postReimportProject.clearAction,
      getProjectById: fetchDux.getProjectById.createAction,
      getLookups: fetchDux.getLookups.createAction,
      getReimportDiff: fetchDux.getReimportDiff.createAction,
      postCompleteReimportProject: fetchDux.postCompleteReimportProject.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ProjectsUpdateImport);
