import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import CreateCustomersCompanyForm from './CreateCustomersCompanyForm';
import Notification from '../../../ui/Notification';

class ModalEditCustomersCompany extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name,
      handleSubmit,
      initialValues,
      companyTypes,
      handleSubmitUpdateCompany,
      putCompanyByIdError,
      clearPutCompanyById
    } = this.props;

    const {
      message
    } = putCompanyByIdError || {};

    return (
      <Modal
        show={openModal === name}
        onHide={closeModal}
        className="modal-side"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Concept</Modal.Title>
        </Modal.Header>
        <CreateCustomersCompanyForm
          isEdit
          initialValues={initialValues}
          companyTypes={companyTypes}
          onSubmit={handleSubmitUpdateCompany}
          closeModal={closeModal}
        />
        {putCompanyByIdError &&
          <Notification
            key="company-delete-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPutCompanyById}
          >
            {message ? message : 'Update Company Failed. Please try again.'}
          </Notification>}
      </Modal>
    );
  }
}

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalEditCustomersCompany);
