import React, { Component } from 'react';
import moment from 'moment';
import { submit } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SendPurchaseOrderForm
  from '../purchase-order-detail/SendPurchaseOrderForm';
import POHeader from '../purchase-order-detail/POHeader';

import { actionCreators } from '../../../../state/modals-dux';

import PurchaseOrderBody from '../purchase-order-detail/PurchaseOrderBody';
import PurchaseOrderBodyEdit
  from '../purchase-order-detail/PurchaseOrderBodyEdit';
import PurchaseOrderBodyPublic
  from '../purchase-order-detail/PurchaseOrderBodyPublic';

import {
  actionCreators as typingActionCreators
} from '../../../../state/typing-dux';

const formatShipDate = value => {
  if (!value || !moment(value).isValid()) return null;
  return moment(value).format('YYYY-MM-DD');
};

const getBillingAddress = c_IsColorado =>
  c_IsColorado
    ? {
      Name: 'Concept Services',
      Address1: '400 Inverness Parkway, 200',
      City: 'Englewood',
      State: 'CO',
      Zip: '80112',
      Country: 'United States',
      PhoneContact: '512-343-3100'
    }
    : {
      Name: 'Concept Services',
      Address1: '12521 Amherst Dr.',
      City: 'Austin',
      State: 'TX',
      Zip: '78727-4011',
      Country: 'United States',
      PhoneContact: '512-343-3100'
    };

const getInitialValues = (
  {
    c_ShipViaId,
    c_ShipDate,
    c_SpecialInstructions,
    Name,
    FirstName,
    LastName,
    PhoneContact,
    PhoneFax,
    Address1,
    Address2,
    City,
    State,
    Zip,
    c_Zip_4,
    c_WarehouseID,
    c_Attn,
    c_AttnNameOnly
  },
  lineItemSpecialInstructions,
  c_IsColorado
) => ({
  c_ShipViaId,
  c_ShipDate,
  c_SpecialInstructions,
  Name,
  FirstName,
  LastName,
  PhoneContact,
  PhoneFax,
  Address1,
  Address2,
  City,
  State,
  Zip,
  c_Zip_4,
  c_WarehouseID: c_WarehouseID || 'other',
  c_IsColorado: !!c_IsColorado,
  c_Attn,
  c_AttnNameOnly: `${!!c_AttnNameOnly}`,
  ItemSpecial: (lineItemSpecialInstructions || [])
    .reduce(
      (prev, next) =>
        Object.assign(
          prev,
          {},
          { [next.LineItemId]: next.SpecialInstructions }
        ),
      {}
    )
});

const getLineItemGroups = lineItems =>
  lineItems.reduce(
    (prev, next) => {
      const { LineItemId } = next || {};
      const existing = prev.some(p => p.LineItemId === LineItemId);
      if (existing) {
        return prev.map(row => {
          if (row.LineItemId !== LineItemId) return row;
          const lines = [...row.lines, next];
          return Object.assign({}, row, { lines });
        });
      }

      return [...prev, { LineItemId, lines: [next] }];
    },
    []
  );

const getPayload = (values, lineItemGroups) => {
  const { ItemSpecial, c_AttnNameOnly, c_ShipDate, ...payload } = values || {};
  const LineItemSpecialInstructions = lineItemGroups.map(({ LineItemId }) => ({
    LineItemId,
    SpecialInstructions: (ItemSpecial || {})[LineItemId] || ''
  }));
  return Object.assign({}, payload, {
    LineItemSpecialInstructions,
    c_AttnNameOnly: c_AttnNameOnly === 'true',
    c_ShipDate: formatShipDate(c_ShipDate)
  });
};

const comparePOID = (a, b) => a.ID !== b.ID;

const getDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY');
};

const getInitialEmailRecipients = (
  {
    email,
    VendorEmail,
    VendorEmailCC
  }
) => {
  return [
    {
      EmailRecipient: VendorEmail,
      Type: 'to',
      Track: true
    },
    {
      EmailRecipient: VendorEmailCC,
      Type: 'cc',
      Track: false
    },
    {
      EmailRecipient: email,
      Type: 'cc',
      Track: false
    }
  ].filter(({ EmailRecipient }) => !!EmailRecipient);
};

const getInitialValuesSendPo = (
  {
    VendorEmail,
    VendorEmailCC,
    c_PurchaseOrderNumber,
    user,
    VendorName,
    c_ShipDate
  }
) => {
  const { email } = user || {};

  const to = getInitialEmailRecipients({ VendorEmail, VendorEmailCC, email });

  return {
    to,
    sub: {
      EmailRecipient: '',
      Type: 'to',
      Track: true
    },
    subject: `${VendorName} P.O. #${c_PurchaseOrderNumber} to SHIP ON ${getDate(c_ShipDate)}`,
    body: `Please process this order to ship on ${getDate(c_ShipDate)}, not before or after.  If you have any issues meeting this date, please notify ${email}.

Please mark ALL shipments for P.O. # ${c_PurchaseOrderNumber}

Send acknowledgements and/or tracking information ASAP directly to ----> tracking@conceptserv.com and cc ${email}
`
  };
};

const getVendorOptions = ({ vendors, manufacturer }) => {
  const {
    CatalogVendorId
  } = manufacturer || {};

  return (vendors || [])
    .filter(({ AQVendorId }) => AQVendorId === CatalogVendorId);
};

class PurchaseOrderDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { LastVendorName: null };
    this.timeout = null;
    this.onKeyDown = this.onKeyDown.bind(this);
  }
  componentDidMount() {
    window.addEventListener('keydown', this.onKeyDown);
  }
  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeyDown);
  }
  componentWillReceiveProps(nextProps) {
    const poChanged = comparePOID(
      nextProps.purchaseOrder || {},
      this.props.purchaseOrder || {}
    );
    if (!poChanged) return;
    this.setState({ vendorConfirmed: false });
  }
  shouldComponentUpdate(nextProps) {
    const { typing, editing } = nextProps;
    const { typing: typing2, editing: editing2 } = this.props;
    return !editing || !typing || typing !== typing2 || editing !== editing2;
  }
  onKeyDown(e) {
    const { setTyping } = this.props;
    clearTimeout(this.timeout);
    setTyping(true);
    this.timeout = setTimeout(() => setTyping(false), 750);
  }
  render() {
    const {
      publicView,
      showEdit,
      purchaseOrder,
      lineItems,
      lineItemSpecialInstructions,
      onApprovePurchaseOrder,
      handlePostPurchaseOrderAddress,
      inProgress,
      addresses,
      lookups,
      toggleEditing,
      editing,
      projectVendors,
      handleSendPurchaseOrder,
      sendPo,
      pdfDetails,
      fileUrl,
      user,
      submitVendorSelectForm,
      hidePDF,
      actions
    } = this.props;

    const {
      ID,
      VendorName,
      VendorNum,
      VendorEmail,
      VendorEmailCC,
      c_PurchaseOrderAddressId,
      c_Approved,
      c_SelectedPurchaseOrderSageVendorID,
      VendorId,
      c_PurchaseOrderNumber,
      c_IsColorado
    } = purchaseOrder || {};

    const { LastVendorName } = this.state;

    const setLastVendor = value => this.setState({ LastVendorName: value });

    const lineItemGroups = getLineItemGroups(lineItems);

    const shippingAddress = (addresses || [])
      .find(({ ID }) => ID === c_PurchaseOrderAddressId) || {};

    const billingAddress = getBillingAddress(c_IsColorado);

    const { vendors } = lookups || {};

    const {
      c_ShipDate
    } = shippingAddress || {};

    const initialValues = getInitialValues(
      shippingAddress || {},
      lineItemSpecialInstructions,
      c_IsColorado
    );

    const manufacturer = (projectVendors || [])
      .find(({ VendorId: manID }) => manID === VendorId) || {};

    const onPostPurchaseOrderAddress = values => {
      setLastVendor(VendorName);
      const payload = getPayload(values, lineItemGroups);
      handlePostPurchaseOrderAddress({ id: ID, payload });
    };

    const handleApproveOrder = payload => {
      setLastVendor(VendorName);
      onApprovePurchaseOrder({ id: ID, payload: payload || {} });
    };

    const onSendPurchaseOrder = data => {
      const { sub, ...payload } = data || {};
      setLastVendor(VendorName);
      handleSendPurchaseOrder(payload);
    };

    const vendorOptions = getVendorOptions({ manufacturer, vendors });

    return (
      <div>
        {sendPo &&
          <div className="send-purchase-orders">
            <SendPurchaseOrderForm
              onSendPurchaseOrder={onSendPurchaseOrder}
              pdfDetails={pdfDetails}
              initialValues={getInitialValuesSendPo({
                VendorEmail,
                VendorEmailCC,
                c_PurchaseOrderNumber,
                user,
                VendorName,
                c_ShipDate
              })}
            />
          </div>}

        <POHeader
          showEdit={showEdit}
          editing={editing}
          inProgress={inProgress}
          c_Approved={c_Approved}
          c_SelectedPurchaseOrderSageVendorID={
            c_SelectedPurchaseOrderSageVendorID
          }
          submitVendorSelectForm={submitVendorSelectForm}
          VendorName={VendorName}
          VendorNum={VendorNum}
          toggleEditing={toggleEditing}
          handleApproveOrder={handleApproveOrder}
          vendorOptions={vendorOptions}
          c_IsColorado={c_IsColorado}
        />

        {!editing &&
          !publicView &&
          <PurchaseOrderBody
            purchaseOrder={purchaseOrder}
            LastVendorName={LastVendorName}
            pdfDetails={pdfDetails}
            fileUrl={fileUrl}
            showEdit={showEdit}
            toggleEditing={toggleEditing}
            inProgress={inProgress}
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            lineItemGroups={lineItemGroups}
            initialValues={initialValues}
            hidePDF={hidePDF}
            actions={actions}
          />}
        {editing &&
          !publicView &&
          <PurchaseOrderBodyEdit
            lookups={lookups}
            onPostPurchaseOrderAddress={onPostPurchaseOrderAddress}
            purchaseOrder={purchaseOrder}
            LastVendorName={LastVendorName}
            pdfDetails={pdfDetails}
            fileUrl={fileUrl}
            showEdit={showEdit}
            toggleEditing={toggleEditing}
            inProgress={inProgress}
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            lineItemGroups={lineItemGroups}
            initialValues={initialValues}
            hidePDF={hidePDF}
          />}

        {publicView &&
          <PurchaseOrderBodyPublic
            purchaseOrder={purchaseOrder}
            pdfDetails={pdfDetails}
            fileUrl={fileUrl}
            shippingAddress={shippingAddress}
            billingAddress={billingAddress}
            lineItemGroups={lineItemGroups}
            initialValues={initialValues}
          />}
      </div>
    );
  }
}

const mapState = state => {
  const typing = state.typing;

  const {
    result: user
  } = state.login.toJS();

  return {
    user,
    typing
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      ...typingActionCreators,
      submitVendorSelectForm: () => submit('vendor-select-form')
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(PurchaseOrderDetail);
