import React from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/modals-dux';
import Icon from '../../ui/Icon';
import VendorForm from './VendorForm';
import { submit, isValid, isSubmitting } from 'redux-form';

const ModalEditVendor = (
  {
    openModal,
    name,
    closeModal,
    onPutVendor,
    inProgress,
    error,
    activeVendor,
    isFormValid,
    isFormSubmitting,
    handleSubmit
  }
) => {
  const show = openModal === name;
  const {
    Name,
    VendorNo,
    PreferredEmail,
    PreferredEmailCC,
    FreightProgram,
    FreightProgramPieces,
    FreightProgramPO,
    FreightProgramFlatRate,
    IsColorado,
    IsColoradoType,
    CPG,
    Discount,
    RepGroup,
    AdditionalNotes,
    DisplayName,
    LBMX
  } = activeVendor || {};

  const initialValues = {
    Name,
    VendorNo,
    PreferredEmail,
    PreferredEmailCC,
    FreightProgram: (FreightProgram || '').split(','),
    FreightProgramPieces,
    FreightProgramPO,
    FreightProgramFlatRate,
    IsColorado,
    IsColoradoType: `${IsColoradoType || ''}`,
    CPG,
    Discount,
    RepGroup,
    AdditionalNotes,
    DisplayName,
    LBMX
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-1100"
    >
      <Modal.Header>
        <Modal.Title>
          Edit Sage Vendor
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VendorForm initialValues={initialValues} onSubmit={onPutVendor} />
        {error &&
          <div
            style={{
              color: '#C23934',
              textAlign: 'center',
              padding: '20px 60px 20px 0'
            }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            {error.message || 'An error occurred'}
          </div>}
      </Modal.Body>
      <Modal.Footer>
        <Col sm="auto">
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={closeModal}
            disabled={inProgress || isFormSubmitting}
          >
            Cancel
          </Button>
          <Button
            type="button"
            size="sm"
            variant="success"
            onClick={handleSubmit}
            disabled={inProgress || !isFormValid || isFormSubmitting}
            style={{ marginLeft: '10px' }}
          >
            Submit
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const formName = 'vendor-form';

const mapState = state => {
  const isFormValid = isValid(formName)(state);
  const isFormSubmitting = isSubmitting(formName)(state);

  const {
    inProgress,
    error
  } = state.putVendor.toJS();

  return {
    isFormValid,
    isFormSubmitting,
    inProgress,
    error,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      handleSubmit: () => submit(formName)
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalEditVendor);
