import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';

import ModalInvoiceHistoryForm from './ModalInvoiceHistoryForm';

class ModalInvoiceHistory extends Component {
  render() {
    const {
      modalName,
      openModal,
      closeModal
    } = this.props;
    return (
      <Modal
        show={openModal === modalName}
        onHide={closeModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send Invoice</Modal.Title>
        </Modal.Header>
        <ModalInvoiceHistoryForm />
      </Modal>
    );
  }
}

const mapState = state => {
  return {};
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalInvoiceHistory);
