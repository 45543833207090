import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import EcommerceOrdersSearch
  from './ecommerce-orders-search/EcommerceOrdersSearch';
import EcommerceOrdersDetail
  from './ecommerce-orders-detail/EcommerceOrdersDetail';

export default ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}`} component={EcommerceOrdersSearch} />
    <Route exact path={`${match.url}/:id`} component={EcommerceOrdersDetail} />
    <Redirect to={`${match.url}`} />
  </Switch>
);
