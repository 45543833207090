import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';
import Icon from '../../../ui/Icon';
import Notification from '../../../ui/Notification';

const ModalDeleteCustomersCompany = (
  {
    openModal,
    name,
    closeModal,
    inProgress,
    error,
    handleDeleteCompany,
    deleteCompanyByIdError,
    clearDeleteCompanyById
  }
) => {
  const {
    message
  } = deleteCompanyByIdError || {};

  return (
    <Modal
      show={openModal === name}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-600"
    >
      <Modal.Header>
        <Modal.Title style={{ color: '#C23934' }}>
          <Icon name="exclamation-triangle" />
          &nbsp;
          Delete Concept
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ textAlign: 'center', marginTop: '10px' }}>
          Are you sure you want to delete concept?
        </p>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: 'center' }}>
        <Row>
          <Col style={{ color: '#C23934' }}>
            <Icon name="exclamation-triangle" />
            &nbsp;
            This action cannot be undone!
          </Col>
          <Col sm="auto">
            <Button
              type="button"
              size="sm"
              variant="secondary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="sm"
              variant="danger"
              onClick={handleDeleteCompany}
              disabled={inProgress}
              style={{ marginLeft: '10px' }}
            >
              Yes, Delete Concept
            </Button>
          </Col>
        </Row>
      </Modal.Footer>

      {deleteCompanyByIdError &&
        <Notification
          key="company-delete-fail"
          duration={3}
          closable={true}
          type="danger"
          onClose={clearDeleteCompanyById}
        >
          {message ? message : 'Delete Company Failed. Please try again.'}
        </Notification>}
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalDeleteCustomersCompany);
