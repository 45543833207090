import React from 'react';
import { Modal, Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../state/modals-dux';
import Icon from '../../ui/Icon';
import VendorView from './VendorView';
import { submit, isValid, isSubmitting } from 'redux-form';

const ViewVendorModal = (
  {
    openModal,
    name,
    closeModal,
    error,
    activeVendor
  }
) => {
  const show = openModal === name;
  const {
    Name,
    VendorNo,
    PreferredEmail,
    PreferredEmailCC,
    FreightProgram,
    IsColorado,
    IsColoradoType,
    CPG,
    Discount,
    RepGroup,
    AdditionalNotes,
    DisplayName,
    FreightProgramFlatRate,
    FreightProgramPO,
    FreightProgramPieces,
    LBMX
  } = activeVendor || {};

  const initialValues = {
    Name,
    VendorNo,
    PreferredEmail,
    PreferredEmailCC,
    FreightProgram,
    IsColorado,
    IsColoradoType: `${IsColoradoType || ''}`,
    CPG,
    Discount,
    RepGroup,
    AdditionalNotes,
    DisplayName,
    FreightProgramFlatRate,
    FreightProgramPO,
    FreightProgramPieces,
    LBMX
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      size="md"
      dialogClassName="modal-1100"
    >
      <Modal.Header>
        <Modal.Title>
          {Name} - Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <VendorView initialValues={initialValues} />
        {error &&
          <div
            style={{
              color: '#C23934',
              textAlign: 'center',
              padding: '20px 60px 20px 0'
            }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            {error.message || 'An error occurred'}
          </div>}
      </Modal.Body>
      <Modal.Footer>
        <Col sm="auto">
          <Button
            type="button"
            size="sm"
            variant="secondary"
            onClick={closeModal}
          >
            Close
          </Button>
        </Col>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => {
  return {
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ViewVendorModal);
