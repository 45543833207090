const statusDict = {
  INVALID: 0,
  VALIDATED: 1,
  SHIPPING_CONFIRMED: 2,
  APPROVE_INPROGRESS: 3,
  APPROVE_COMPLETE: 4,
  INSERT_INPROGRESS: 5,
  INSERT_COMPLETE: 6,
  SEND_INPROGRESS: 7,
  SEND_COMPLETE: 8,
  TAX_INPROGRESS: 9,
  COMPLETE: 10,
  ARCHIVED: 11,
  REIMPORT: 12,
  SHIPPING_VALIDATED: 13,
};

export const getStatusLabel = status => {
  switch (status) {
    case statusDict.INVALID:
    case statusDict.VALIDATED:
      return 'Pending Shipping Confirmation';
    case statusDict.SHIPPING_CONFIRMED:
      return 'Project Validated';
    case statusDict.APPROVE_INPROGRESS:
      return 'Pending Items Approval';
    case statusDict.APPROVE_COMPLETE:
    case statusDict.INSERT_INPROGRESS:
      return 'Items Approved';
    case statusDict.INSERT_COMPLETE:
    case statusDict.SEND_INPROGRESS:
      return 'POs Created';
    case statusDict.SEND_COMPLETE:
      return 'POs Sent';
    case statusDict.TAX_INPROGRESS:
      return 'Submitting Tax';
    case statusDict.COMPLETE:
      return 'Complete';
    case statusDict.ARCHIVED:
      return 'Deleted';
    default:
      return '';
  }
};

export default statusDict;
