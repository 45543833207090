import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Loading from '../../../ui/Loading';

import UploadForm from './UploadForm';
import UploadResult from './UploadResult';
import FreightResult from './FreightResult';
import { Button } from 'react-bootstrap';

class FreightUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { showUpload: true };
  }
  componentDidMount() {
    this.props.clearUploadFreight();
  }
  render() {
    const { uploadResult, freightResult } = this.props;
    const { result, inProgress, error } = uploadResult || {};
    const { showUpload } = this.state;

    const showUploadForm = (showUpload = true) => this.setState({ showUpload });

    const handleUpload = values => {
      this.props.clearFreightBatch();
      this.props.clearUploadFreight();
      this.props.uploadFreight(values);
      showUploadForm(false);
    };

    const handleSubmit = ({ vendors }) => {
      const values = vendors
        .reduce((t, p) => [...t, ...p.items], [])
        .filter(d => !d.exclude);
      this.props.postFreightBatch(values);
    };

    const getInitialValues = () => {
      const vendors = result.map(d =>
        Object.assign({}, d, {
          excludeAll: false
        }));
      return { vendors };
    };

    return (
      <Layout route="freight-upload">
        <h2>
          Freight Upload
          {!showUpload &&
            <span style={{ float: 'right' }}>
              <Button
                variant="primary"
                type="button"
                size="sm"
                onClick={showUploadForm}
              >
                Back to upload
              </Button>
            </span>}
        </h2>
        {showUpload &&
          <Box className="freight-upload-form">
            <UploadForm onSubmit={handleUpload} />
          </Box>}
        {inProgress && <Loading message="Uploading, Please Wait" />}
        {result &&
          <UploadResult
            onSubmit={handleSubmit}
            initialValues={getInitialValues()}
            showDownload={(freightResult || {}).result}
          />}
        <FreightResult {...freightResult} />
      </Layout>
    );
  }
}

const mapState = state => {
  const uploadResult = state.uploadFreight.toJS();
  const freightResult = state.postFreightBatch.toJS();
  return { uploadResult, freightResult };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      uploadFreight: fetchDux.uploadFreight.createAction,
      clearUploadFreight: fetchDux.uploadFreight.clearAction,
      postFreightBatch: fetchDux.postFreightBatch.createAction,
      clearFreightBatch: fetchDux.postFreightBatch.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(FreightUpload);
