import React from 'react';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button, Form, Table } from 'react-bootstrap';
import Icon from '../../../ui/Icon';
import { connect } from 'react-redux';

const required = value => value ? undefined : 'Required';

const MiscLineItemsForm = props => {
  const {
    handleSubmit,
    submitting,
    disabled
  } = props;

  return (
    <Form onSubmit={handleSubmit} noValidate>
      <Col sm={12} style={{ padding: '20px 0' }}>
        <h3>Add additional Misc. Line Items</h3>
      </Col>
      <Row>
        <Col sm={4}>
          <Field
            required
            name="FieldName"
            component={FormField.Input}
            label="Misc. Line Item Type"
            disabled={submitting || disabled}
            validate={[required]}
          />
        </Col>
        <Col sm="auto">
          <Form.Label>Taxable</Form.Label>
          <Field
            name="IsTaxable"
            component={FormField.InputToggle}
            disabled={submitting || disabled}
          />
        </Col>
        <Col style={{ paddingRight: '5px' }}>
          <Field
            required
            name="Sell"
            type="number"
            component={FormField.Input}
            label="Sell Amount"
            disabled={submitting || disabled}
            validate={[required]}
          />
        </Col>
        <Col xs="auto" style={{ paddingLeft: '0px' }}>
          <div style={{ paddingTop: '38px' }}>
            USD
          </div>
        </Col>
        <Col>
          <Button
            type="submit"
            style={{ marginTop: '30px' }}
            disabled={submitting || disabled}
          >
            Add Item{' '}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const formName = 'misc-line-items-form';

const form = reduxForm({
  form: formName
})(MiscLineItemsForm);

const selector = formValueSelector(formName);

const mapState = state => {
  return {};
};

export default connect(mapState)(form);
