import React from 'react';
import { Button, DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { authenticatedHref } from '../../../../api/fetch';
import Icon from '../../../ui/Icon';
import moment from 'moment';

const getPDFUrl = (
  {
    FilePath,
    FileName
  }
) => authenticatedHref(`files/${FilePath}/${FileName}`);

const formatDate = d => {
  if (!d || !moment(d).isValid()) return '';
  return moment(d).format('MM/DD/YYYY');
};

const PDFLink = (
  {
    // pdfDetails,
    pdfLogs
  }
) => {
  const pdfDetails = (pdfLogs || [])[0] || {};
  const showMore = (pdfLogs || []).length > 1;
  if (!pdfLogs || !pdfLogs.length) return false;
  return (
    <ButtonGroup>
      <a href={getPDFUrl(pdfDetails || {})} target="_blank">
        <Button variant="danger">
          <Icon name="download" />
          &nbsp;
          CLICK HERE TO OPEN PO PDF
        </Button>
      </a>
      {showMore &&
        <DropdownButton
          variant="danger"
          as={ButtonGroup}
          id="bg-nested-dropdown"
        >
          {(pdfLogs || []).map((p, idx) => (
            <Dropdown.Item
              eventKey={idx}
              as="a"
              href={getPDFUrl(p || {})}
              target="_blank"
            >
              {p.FileName}

              <span class="small" style={{ marginLeft: '15px' }}>
                {formatDate(p.DateCreated)}
              </span>
              {idx === 0 &&
                <div className="action add" style={{ display: 'inline' }}>
                  CURRENT
                </div>
              // <span style={{ color: '#C23934', fontWeight: 'bold', textTransform: 'uppercase' }}>Deleted</span>
              }
            </Dropdown.Item>
          ))}
        </DropdownButton>}
    </ButtonGroup>
  );
};

export default PDFLink;
