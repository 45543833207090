import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Container } from 'react-bootstrap';
import qs from 'query-string';

import fetchDux from '../../../../state/fetch-dux';
import Layout from '../../../ui/Layout';
import Box from '../../../ui/Box';
import Loading from '../../../ui/Loading';

import ReportsSearchForm from './ReportsSearchForm';
import ReportsSearchResult from './ReportsSearchResult';
import moment from 'moment';
import { formValueSelector } from 'redux-form';

class ReportsSearch extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  callSearch(search) {
    const { clearSearch, submitSearch } = this.props;

    if (!search) {
      clearSearch();
      return;
    }
    const params = qs.parse(search);
    submitSearch(this.cleanFields(params));
  }
  componentDidMount() {
    const { getLookups, location: { search } } = this.props;
    getLookups();
    this.callSearch(search);
  }
  componentWillReceiveProps(nextProps) {
    const { location: { search: prevSearch } } = this.props;
    const { location: { search } } = nextProps;
    if (search !== prevSearch) this.callSearch(search);
  }
  handleSubmit(values) {
    const { history, location: { pathname } } = this.props;
    const params = this.cleanFields(values);
    const search = qs.stringify(
      Object.assign({}, params, { t: new Date().getTime() })
    );
    history.push({ pathname, search });
  }
  getInitialValues() {
    const { location: { search } } = this.props;
    const params = qs.parse(search);
    return this.cleanFields(params, false);
  }
  cleanFields(params, submit = true) {
    const {
      reportType,
      numDays,
      orderDateStart,
      orderDateEnd,
      startDate,
      endDate,
      scheduledShipDateStart,
      scheduledShipDateEnd
    } = params;
    // format multi select
    for (const k of ['vendors', 'salesReps']) {
      const value = params[k];
      if (value) params[k] = Array.isArray(value) ? value : [value];
    }
    // default order date
    const start = orderDateStart
      ? moment(orderDateStart)
      : moment().subtract(90, 'days');
    const end = orderDateEnd ? moment(orderDateEnd) : moment();
    params.orderDateStart = start.format('MM/DD/YYYY');
    params.orderDateEnd = end.format('MM/DD/YYYY');

    // default numDays to 0
    params.numDays = numDays || 0;

    // remove unneeded fields before submit
    if (submit) {
      if (reportType !== 'acknowledgement-fax') delete params.numDays;
      if (reportType !== 'vendor-summary') delete params.vendors;
      if (reportType === 'to-be-shipped') {
        if (scheduledShipDateStart){
          params.scheduledShipDateStart = moment(scheduledShipDateStart).format('MM/DD/YYYY');
        }
        if (scheduledShipDateEnd){
          params.scheduledShipDateEnd = moment(scheduledShipDateEnd).format('MM/DD/YYYY');
        } 
      }
      else
      {
        params.startDate = moment(startDate).format('MM/DD/YYYY');
        params.endDate = moment(endDate).format('MM/DD/YYYY');
        delete params.scheduledShipDateStart;
        delete params.scheduledShipDateEnd;
      } 
      
      delete params.orderDateStart;
      delete params.orderDateEnd;   
    }
    if (!!!startDate) delete params.startDate;
    if (!!!endDate) delete params.endDate;
    return params;

  }
  render() {
    const { inProgress, error, reports, lookups } = this.props;
    const reportsActiveColumns = JSON.parse(window.localStorage.getItem('reportsActiveColumns')) || {};
    return (
      <Layout route="reports-search" alt>
        <div className="layout-app-header">
          <Container>
            <h2>Reports</h2>
          </Container>
        </div>
        <Container>

          <ReportsSearchForm
            lookups={lookups}
            onSubmit={this.handleSubmit}
            initialValues={this.getInitialValues()}
          />

          {inProgress &&
            <Loading
              fullPage
              color="#04844B"
              message="Loading, Please Wait..."
            />}
        </Container>

        {!inProgress &&
          <Container fluid>
            <Box className="box-table">
              <ReportsSearchResult
                {...reports}
                reportsActiveColumns={reportsActiveColumns}
              />
            </Box>
          </Container>
        }
      </Layout>
    );
  }
}

const mapState = state => {
  const lookups = state.getReportsLookups.toJS();
  const reports = state.getReports.toJS();
  return {
    lookups: lookups.result || {},
    reports: reports.result || [],
    inProgress: reports.inProgress || lookups.inProgress,
    error: reports.error || lookups.error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getLookups: fetchDux.getReportsLookups.createAction,
      submitSearch: fetchDux.getReports.createAction,
      clearSearch: fetchDux.getReports.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ReportsSearch);
