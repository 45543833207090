import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PurchaseOrderDetail
  from '../projects/purchase-order-detail/PurchaseOrderDetail';
import fetchDux from '../../../state/fetch-dux';
import Loading from '../../ui/Loading';
import Layout from '../../ui/Layout';
import Icon from '../../ui/Icon';

class VendorPo extends Component {
  componentDidMount() {
    const { match: { params }, getPurchaseOrderPublic } = this.props;
    const { id } = params || {};
    getPurchaseOrderPublic(id);
  }
  render() {
    const {
      result,
      inProgress,
      error
    } = this.props;

    const {
      purchaseOrder,
      shippingAddress,
      billingAddress,
      lineItems,
      lineItemSpecialInstructions,
      pdfDetails,
      isDeleted,
      isRevised,
      isProjectDeleted
    } = result || {};

    const addresses = [shippingAddress, billingAddress];

    const { match: { params } } = this.props;
    const { id } = params || {};

    return (
      <Layout hideNav minimal route="vendor-purchase-order">
        {inProgress && <Loading fullPage />}
        {
          !inProgress
          && isProjectDeleted
          &&
          <div
            style={{ color: '#C23934', textAlign: 'center', padding: '80px 0' }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            <span className='text-danger'>The Purchase Order is currently not active.</span>
          </div>

        }
        {!inProgress &&
          !error &&
          result &&
          !isDeleted &&
          !isProjectDeleted &&
          <PurchaseOrderDetail
            publicView
            addresses={addresses || []}
            purchaseOrder={purchaseOrder || {}}
            lineItems={lineItems || []}
            lineItemSpecialInstructions={lineItemSpecialInstructions || []}
            pdfDetails={pdfDetails}
            fileUrl={`public/files/${id}`}
            isDeleted={isDeleted}
            isRevised={isRevised}
          />}
        {!inProgress &&
          error &&
          error.status === 401 &&
          <div
            style={{ color: '#C23934', textAlign: 'center', padding: '80px 0' }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            You are not authorized to view this purchase order
          </div>}
        {!inProgress &&
          error &&
          error.status !== 401 &&
          <div
            style={{ color: '#C23934', textAlign: 'center', padding: '80px 0' }}
          >
            <Icon name="exclamation-triangle" />
            &nbsp;
            An error occurred loading purchase order
          </div>}

      </Layout>
    );
  }
}

const mapState = state => {
  const {
    error,
    result,
    inProgress
  } = state.getPurchaseOrderPublic.toJS();

  return {
    result,
    inProgress,
    error
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      getPurchaseOrderPublic: fetchDux.getPurchaseOrderPublic.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(VendorPo);
