import React from 'react';
import { reduxForm, Field } from 'redux-form';
import FormField from '../../../forms/FormField';

const DisplayProgressForm = props => {
  const {
    handlePutProgressDisplayById,
    putProgressDisplayByIdInProgress,
    progressDisplay
  } = props;

  return (
    <div>
      <form className="single-form-control">

        <Field
          size="sm"
          name="progressDisplay"
          component={FormField.InputToggle}
          label="Display progress tracker on customer portal"
          disabled={putProgressDisplayByIdInProgress}
          onChange={e => handlePutProgressDisplayById(e.target.checked)}
          checked={progressDisplay}
        />

      </form>
      {putProgressDisplayByIdInProgress &&
        <div style={{ fontStyle: 'bold' }}>
          Updating...
        </div>}
      {!putProgressDisplayByIdInProgress && <br />}
    </div>
  );
};

export default reduxForm({
  form: 'display-progress-location-form'
})(DisplayProgressForm);
