import React from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Button, Row, Col, Table } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

export const required = value => value ? undefined : 'Required';

export const commodityClassOptions = [
  null,
  50,
  55,
  60,
  65,
  70,
  77.5,
  85,
  92.5,
  100,
  110,
  125,
  150,
  175,
  200,
  250,
  300,
  400,
  500
];

const renderItems = ({ fields, meta: { error, submitFailed } }) => (
  <Table bordered>
    <thead>
      <tr>
        <th>Items</th>
        <th width="250">Weight</th>
        <th width="250">Commodity Class</th>
        <th width="55" />
      </tr>
    </thead>
    <tbody>

      {fields.map((item, index) => (
        <tr key={index}>
          <td>
            Item {index + 1}
          </td>
          <td>
            <Field
              name={`${item}.weight`}
              type="text"
              component={FormField.Input}
              validate={[required]}
            />
          </td>
          <td>
            <Field
              name={`${item}.commodityClass`}
              type="text"
              component={FormField.SelectHTML}
              validate={[required]}
            >
              {(commodityClassOptions || []).map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </Field>
          </td>
          <td className="text-right">
            <Button
              size="sm"
              variant="danger"
              onClick={() => fields.remove(index)}
            >
              <Icon name="trash" />
            </Button>
          </td>
        </tr>
      ))}

    </tbody>
    <tfoot>
      <tr>
        <td colSpan={4}>

          <Button
            variant="success"
            size="sm"
            className="float-right"
            onClick={() => fields.push({})}
          >
            <Icon name="plus" /> Add Item
          </Button>

          <span>Enter total cubic feet, as integer, if required by ABF</span>

          <Field
            name="volume"
            type="text"
            component={FormField.Input}
            placeholder="Total Volume"
          />

        </td>
      </tr>
    </tfoot>
  </Table>
);

const FreightCalcForm = props => {
  const { handleSubmit, submitting } = props;
  return (
    <form onSubmit={handleSubmit}>

      <Row>
        <Col sm={6} md={4} lg={3}>

          <Field
            name="originZip"
            type="text"
            component={FormField.Input}
            label="Origin Zip Code"
            validate={[required]}
          />

        </Col>
        <Col sm={6} md={4} lg={3}>

          <Field
            name="destinationZip"
            type="text"
            component={FormField.Input}
            label="Destination Zip Code"
            validate={[required]}
          />

        </Col>
      </Row>

      <Row>
        <Col sm={12} md={4} lg={3}>

          Accessorial Options - Pickup

        </Col>
        <Col sm={6} md={4} lg={3}>

          <Field
            name="pickupLiftgate"
            type="checkbox"
            component={FormField.InlineCheckbox}
            label="Liftgate"
          />

        </Col>
        <Col sm={6} md={4} lg={3}>

          <Field
            name="pickupInside"
            type="checkbox"
            component={FormField.InlineCheckbox}
            label="Inside Pickup"
          />

        </Col>
      </Row>

      <Row>
        <Col sm={12} md={4} lg={3}>

          Accessorial Options - Delivery

        </Col>
        <Col sm={6} md={4} lg={3}>

          <Field
            name="deliveryLiftgate"
            type="checkbox"
            component={FormField.InlineCheckbox}
            label="Liftgate"
          />

        </Col>
        <Col sm={6} md={4} lg={3}>

          <Field
            name="deliveryInside"
            type="checkbox"
            component={FormField.InlineCheckbox}
            label="Inside Placement"
          />

        </Col>
      </Row>

      <h5>Items to ship - Fill in weight and class</h5>

      <FieldArray name="items" component={renderItems} />

      <Button variant="primary" type="submit" disabled={submitting}>
        Get Rate Quotes and Service Days
      </Button>

    </form>
  );
};

export default reduxForm({
  form: 'freight-quote-form'
})(FreightCalcForm);
