import React, { useState, useEffect } from 'react';
import { reduxForm, Field, FieldArray } from 'redux-form';
import FormField from '../../../forms/FormField';
import { Row, Col, Button } from 'react-bootstrap';
import Icon from '../../../ui/Icon';

const required = value => value ? undefined : 'Required';

const ModalInvoiceHistoryForm = props => {
  const {
    handleSubmit,
    submitting,
    closeModal
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <div className="modal-body">

        <Field
          name="invoiceType"
          type="text"
          component={FormField.SelectReact}
          label="Invoice Type"
        />

        <Field
          name="recipients"
          type="text"
          component={FormField.SelectReact}
          label="Recipients"
        />

        <div className="pill-group-items">
          <div className="pill">
            Joe Doe
          </div>
        </div>

      </div>

      <div className="modal-footer">
        <Button variant="outline-secondary" type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          Send
        </Button>
      </div>

    </form>
  );
};

export default reduxForm({
  form: 'send-invoice-form'
})(ModalInvoiceHistoryForm);
