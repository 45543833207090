import React from 'react';
import { Modal } from 'react-bootstrap';
import UpdateEmailRecipientsForm from './UpdateEmailRecipientsForm';






const ModalUpdateEmailRecipients = (
    {
        openModal,
        name,
        closeModal,
        handleSubmit
    }
) => {

    return (
        <Modal
            show={openModal === name}
            onHide={closeModal}
            size="lg"
        >
            <Modal.Header closeButton>
                <Modal.Title>{'Add Additional Recipient(s) to all POs'}</Modal.Title>
            </Modal.Header>

            <UpdateEmailRecipientsForm onSubmit={handleSubmit} />
        </Modal>
    )
}


export default ModalUpdateEmailRecipients;