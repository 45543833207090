import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { formValueSelector } from 'redux-form';
import { connect } from 'react-redux';

import fetchDux from '../../../../state/fetch-dux';
import { actionCreators } from '../../../../state/modals-dux';
import CreateCustomersLocationsForm from './CreateCustomersLocationsForm';
import Notification from '../../../ui/Notification';

class ModalCreateCustomersLocation extends Component {
  constructor(props) {
    super(props);
    this.state = { userIds: [] };
  }
  render() {
    const {
      openModal,
      closeModal,
      name,
      getCompanyResults,
      selectedCompany,
      getCompanyById,
      getCompanyByIdResult,
      getCompanyByIdInProgress,
      getCompanyByIdError,
      clearGetCompanyById,
      searchCustomersLocations,
      postLocation,
      isFromConcepts,
      companyId,
      postLocationInProgress,
      postLocationError,
      clearPostLocation
    } = this.props;

    const {
      userIds
    } = this.state;

    const {
      companyUsers
    } = getCompanyByIdResult || {};

    const handleCompanyChange = newCompanySelection => {
      this.setState({ userIds: [] });
      if (!newCompanySelection) return;
      getCompanyById(
        { id: newCompanySelection, query: { getUsers: 1, getLocations: 1 } },
        null,
        isFromConcepts ? true : false
      );
    };

    const handleUserChange = newSelectedUser => {
      if (!newSelectedUser) return;
      this.setState({ userIds: (userIds || []).concat(newSelectedUser) });
    };

    const handleRemoveUser = removeUserId => {
      if (!removeUserId) return;
      const {
        userIds
      } = this.state;
      const newUserArray = (userIds || [])
        .filter(userId => removeUserId !== userId);
      this.setState({ userIds: newUserArray });
    };

    const handlePostLocation = values => {
      values.userIDs = userIds || [];
      postLocation(values, 'create-customers-location-form', null, () => {
        if (isFromConcepts) {
          getCompanyById(
            { id: companyId, query: { getUsers: 1, getLocations: 1 } },
            null,
            true
          );
        } else {
          this.props.searchCustomersLocations();
        }
        this.setState({ userIds: [] });
      });
    };

    const usersDisplay = (companyUsers || [])
      .sort((a, b) => {
        var nameA = a.nameFirst.toUpperCase(); // ignore upper and lowercase
        var nameB = b.nameFirst.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
      .filter(({ id }) => (userIds || []).includes(id));

    const handleHideModal = () => {
      closeModal();
      if (!isFromConcepts) {
        clearGetCompanyById();
      }
      this.setState({ userIds: [] });
    };

    const {
      message
    } = postLocationError || {};

    return (
      <Modal
        show={openModal === name}
        onHide={handleHideModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Location</Modal.Title>
        </Modal.Header>
        <CreateCustomersLocationsForm
          getCompanyResults={getCompanyResults}
          companyUsers={companyUsers}
          selectedCompany={selectedCompany}
          closeModal={closeModal}
          handleCompanyChange={handleCompanyChange}
          getCompanyByIdInProgress={getCompanyByIdInProgress}
          handleUserChange={handleUserChange}
          usersDisplay={usersDisplay}
          userIds={userIds}
          handleRemoveUser={handleRemoveUser}
          onSubmit={handlePostLocation}
          isFromConcepts={isFromConcepts}
          initialValues={{ companyID: parseInt(companyId) }}
          submitting={postLocationInProgress}
        />
        {postLocationError &&
          <Notification
            key="location-create-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={clearPostLocation}
          >
            {message ? message : 'Failed to create location. Please try again.'}
          </Notification>}
        {getCompanyByIdError &&
          <Notification
            key="company-load-fail"
            duration={3}
            closable={true}
            type="danger"
            onClose={isFromConcepts ? null : clearGetCompanyById}
          >
            Failed to load selected concept users. Please try again.
          </Notification>}
      </Modal>
    );
  }
}

const selector = formValueSelector('create-customers-location-form');

const mapState = state => {
  const selectedCompany = selector(state, 'companyID');

  const {
    result: getCompanyByIdResult,
    inProgress: getCompanyByIdInProgress,
    error: getCompanyByIdError
  } = state.getCompanyById.toJS();

  const {
    inProgress: postLocationInProgress,
    error: postLocationError
  } = state.postLocation.toJS();

  return {
    selectedCompany,
    getCompanyByIdResult,
    getCompanyByIdInProgress,
    getCompanyByIdError,
    postLocationInProgress,
    postLocationError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postLocation: fetchDux.postLocation.createAction,
      clearPostLocation: fetchDux.postLocation.clearAction,
      getCompanyById: fetchDux.getCompanyById.createAction,
      clearGetCompanyById: fetchDux.getCompanyById.clearAction,
      searchCustomersLocations: fetchDux.searchCustomersLocations.createAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalCreateCustomersLocation);
