import React, { Component } from 'react';
import { GoogleMap, Marker, MarkerClusterer } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const isEqual = (g1, g2) => {
  if (g1.length !== g2.length) return false;
  return g1.every((g, i) => {
    const c = g2[i];
    return g.lat === c.lat && g.lng === c.lng;
  });
};

class MapComponent extends Component {
  constructor(props) {
    super(props);
    this.map = null;
    this.updateMap = this.updateMap.bind(this);
    this.onBoundsChange = this.onBoundsChange.bind(this);
  }
  componentDidMount() {
    this.updateMap();
  }
  componentDidUpdate() {
    this.updateMap();
  }
  onBoundsChange() {
    const { map } = this;
    if (!map) return;
    const zoom = map.getZoom();
    if (zoom <= 10) return;
    map.setZoom(10);
  }

  updateMap() {
    const { geocodes } = this.props;
    const { map } = this;
    if (!map) return;
    const bounds = new window.google.maps.LatLngBounds();
    const deduped = (geocodes || []).reduce((prev, next) => {
      const existing = prev.find(
        ({ lat, lng }) => lat === next.lat && lng === next.lng
      );
      if (existing) return prev;
      return [...prev, { lat: next.lat, lng: next.lng }];
    }, []);
    (deduped || []).forEach(g => bounds.extend(g));
    window.google.maps.event.addListenerOnce(
      map,
      'bounds_changed',
      this.onBoundsChange
    );
    map.fitBounds(bounds);
  }
  setBounds(geocodes) {
    const { map } = this;
    if (!map) return;
    const bounds = new window.google.maps.LatLngBounds();
    const deduped = (geocodes || []).reduce((prev, next) => {
      const existing = prev.find(
        ({ lat, lng }) => lat === next.lat && lng === next.lng
      );
      if (existing) return prev;
      return [...prev, { lat: next.lat, lng: next.lng }];
    }, []);
    (deduped || []).forEach(g => bounds.extend(g));
    window.google.maps.event.addListenerOnce(
      map,
      'bounds_changed',
      this.onBoundsChange
    );
    map.fitBounds(bounds);
  }
  render() {
    const { geocodes } = this.props;
    const handleLoad = map => {
      this.map = map;
      this.updateMap();
    };

    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        onLoad={handleLoad}
        ref="googlemap"
        loadingElement={<div style={{ height: `100%`, width: '100%' }} />}
        containerElement={<div style={{ height: `100%`, width: '100%' }} />}
        defaultOptions={{
          disableDefaultUI: false,
          draggable: true,
          scrollwheel: false,
          keyboardShortcuts: false
        }}
      >
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}
          zoomOnClick={false}
          onClick={cluster => {
            this.map.setCenter(cluster.center);
          }}
        >
          {clusterer =>
            (geocodes || [])
              .map(({ lat, lng }, i) => (
                <Marker
                  key={i}
                  position={{ lat, lng }}
                  clusterer={clusterer}
                  myProp
                />
              ))}
        </MarkerClusterer>
      </GoogleMap>
    );
  }
}

class ViewShippingSummaryMap extends Component {
  constructor(props, context) {
    super(props, context);
  }
  shouldComponentUpdate(nextProps) {
    const { geocodes: g1, getGeocodesInProgress: i1 } = this.props;
    const { geocodes: g2, getGeocodesInProgress: i2 } = nextProps;
    if (i1 !== i2) return true;
    const equal = isEqual(g1 || [], g2 || []);
    return !equal;
  }
  render() {
    const { geocodes, getGeocodesInProgress } = this.props;
    const filteredGeocodes = (geocodes || []).filter(({ lat, lng }) => {
      const validLat = !!lat || lat === 0;
      const validLng = !!lng || lng === 0;
      return validLat && validLng;
    });
    if (getGeocodesInProgress) return <div>Loading</div>;
    return <MapComponent geocodes={filteredGeocodes} ref="map" />;
  }
}
export default ViewShippingSummaryMap;
