import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';
import AddWarehouseCommentForm from './AddWarehouseCommentForm';
import Notification from '../../../ui/Notification';

class ModalAddWarehouseComment extends Component {
  constructor(props) {
    super(props);
    this.state = { rating: 0 };
  }
  componentWillUnmount(){
    const{
      clearPostWarehouseComment
    } = this.props

    clearPostWarehouseComment();
  }
  render() {
    const {
      openModal,
      closeModal,
      WarehouseId,
      postWarehouseComment,
      postWarehouseCommentError,
      clearPostWarehouseComment,
      postError
    } = this.props;

    const { rating } = this.state;

    const ratingChanged = newRating => {
      this.setState({ rating: newRating });
    };

    const handleSubmit = payload => {
      postWarehouseComment(
        { id: WarehouseId, payload: { ...payload, rating } },
        'add-warehouse-comment-form',
        null,
        () => {
          closeModal();
        }
      );
    };

    return (
      <Modal
        show={openModal === 'addWarehouseCommentModal'}
        onHide={closeModal}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Warehouse Rating</Modal.Title>
        </Modal.Header>
        <AddWarehouseCommentForm
          {...{
            closeModal,
            WarehouseId,
            ratingChanged,
            rating,
            postWarehouseCommentError
          }}
          onSubmit={handleSubmit}
        />
        {postError &&
          <Notification
            key="warehouse-get-detail-error"
            duration={5}
            closable={true}
            type="danger"
            onClose={clearPostWarehouseComment}
          >
            Error saving warehouse rating, please try again.
          </Notification>
        }
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    error: postWarehouseCommentError
  } = state.postWarehouseComment.toJS();
  return {
    postError: postWarehouseCommentError,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      postWarehouseComment: fetchDux.postWarehouseComment.createAction,
      clearPostWarehouseComment: fetchDux.postWarehouseComment.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalAddWarehouseComment);
