import React, { Component } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../../../state/modals-dux';
import fetchDux from '../../../../state/fetch-dux';

class ModalCustomersCompanyViewPackage extends Component {
  render() {
    const {
      openModal,
      closeModal,
      name: modalName,
      result,
      clearGetPackageById,
      inProgress
    } = this.props;

    const hideModal = () => {
      closeModal();
      clearGetPackageById();
    };

    const {
      name,
      description,
      featured,
      hidePrice,
      products
    } = result || {};

    return (
      <Modal
        show={openModal === modalName}
        onHide={hideModal}
        className="modal-side"
      >
        <Modal.Header closeButton>
          <Modal.Title>View Package Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <h5>Package Name</h5>
          <div style={{ marginLeft: '10px' }}>
            {name || '-'}
          </div>

          <h5>Included Products</h5>
          <div style={{ marginLeft: '10px' }}>
            {(products || []).map(({ qty, model, category }) => {
              return (
                <div>
                  <Row>
                    <Col md={10} style={{ color: 'grey', fontSize: 'small' }}>
                      <b style={{ color: 'black', fontSize: 'initial' }}>
                        {model ? model : 'Unknown Model'}
                      </b>
                      {' '}
                      -
                      {' '}
                      {category}
                    </Col>
                    <Col md={2}>
                      x{qty}{'   '}
                    </Col>
                  </Row>
                  {(products || []).length > 1 ? <hr /> : ''}
                </div>
              );
            })}
          </div>

          <h5>Description</h5>
          <div
            style={{
              marginLeft: '10px'
            }}
          >
            {description || '-'}
          </div>

          <h5>Feature Package</h5>
          <div style={{ marginLeft: '10px' }}>
            {inProgress ? '-' : featured ? 'Yes' : 'No'}
          </div>

          <h5>Hide price on store</h5>
          <div style={{ marginLeft: '10px' }}>
            {inProgress ? '-' : hidePrice ? 'Yes' : 'No'}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" type="button" onClick={hideModal}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapState = state => {
  const {
    result,
    inProgress
  } = state.getPackageById.toJS();

  return {
    result,
    openModal: state.modals
  };
};

const mapDispatch = dispatch =>
  bindActionCreators(
    {
      ...actionCreators,
      clearGetPackageById: fetchDux.getPackageById.clearAction
    },
    dispatch
  );

export default connect(mapState, mapDispatch)(ModalCustomersCompanyViewPackage);
