import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../../../state/modals-dux';

const ModalConfirmSendPO = (
  {
    openModal,
    name,
    closeModal,
    onSubmit,
    submitting,
    confirmText
  }
) => {
  return (
    <Modal
      show={openModal === name}
      onHide={!submitting ? closeModal : () => {}}
    >
      <Modal.Header closeButton>
        <Modal.Title>{confirmText}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ fontSize: '14px' }}>
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          Are you sure you want to {confirmText || 'Send Email and Save PDF'}?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          type="button"
          size="sm"
          variant="secondary"
          onClick={closeModal}
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          type="button"
          size="sm"
          variant="success"
          onClick={onSubmit}
          disabled={submitting}
        >
          Yes, {confirmText || 'Send Email and Save PDF'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapState = state => ({ openModal: state.modals });
const mapDispatch = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapState, mapDispatch)(ModalConfirmSendPO);
