import React from 'react';
import { reduxForm } from 'redux-form';

const ConnectedField = ({ children }) => {
  return (
    <div>
      {children}
    </div>
  );
};

export default reduxForm()(ConnectedField);
